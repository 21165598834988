import styled from "styled-components";

export const Editor = styled.div`
    height: ${props => props.line * 34 - 4}px;
    overflow: hidden;

    & > .ql-toolbar {
        display: none;
    }
`;

export const EditorContents = styled.div`
    border-radius: 3px;
    border: none !important;
    min-width: 320px;

    & > .ql-editor {
        padding: 3px;
        font-size: ${props => props.fontSize}px;
        max-width: 320px;
        height: auto;

        > p {
            word-break: break-all;
            font-weight: ${props => props.bold ? 700 : 400};
        }

        &::before {
            font-weight: ${props => props.bold ? 700 : 400};
            left: 0px !important;
        }
    }
`;

export const EditorToolBar = styled.div`
    border: none !important;
`;