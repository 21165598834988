import React, { useContext } from 'react';
import context from 'boards/DetailBoardWrite/provider/product';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';

export default (({
    cropperTag,
    image,
    setImage,
    croppedImageBlob,
    setCroppedImageBlob,
    croppedImageDataUrl,
    setCroppedImageDataUrl,
    disabled,
    isShow,
    isComplete,
    setComplete,
    setCallback,
    setCompleteCallback,
    setFileCallback,
    showMessage
}) => {
    // 파일 개수 제한 
    const limit = 1;
    // 확장자들 
    const extensions = ['image/jpg', 'image/png', 'image/jpeg'];
    // 파일 크기 
    const fileSize = [1755, 900];

    const { state, dispatch } = useContext(context);

    return (
        <ImageUpload
            showMessage={showMessage}
            setCallback={setCallback}
            cropperTag={cropperTag}
            image={image}
            setImage={setImage}

            croppedImageBlob={croppedImageBlob}
            setCroppedImageBlob={setCroppedImageBlob}
            
            croppedImageDataUrl={croppedImageDataUrl}
            setCroppedImageDataUrl={setCroppedImageDataUrl}

            isShow={isShow}
            disabled={disabled}
            isComplete={isComplete}
            setComplete={setComplete}
            maxWidth={fileSize[0]}
            maxHeight={fileSize[1]}
            extensions={extensions}
            sizeLimit={10}
            targetWidth={585}
            targetHeight={300}
            boxWidth={585}
            boxHeight={300}
            limit={limit}
            cropRatio={1.95}
            setCompleteCallback={setCompleteCallback}
            left={state.mainImageCropInfo?.left}
            top={state.mainImageCropInfo?.top}
            width={state.mainImageCropInfo?.width}
            height={state.mainImageCropInfo?.height}
            url={state.mainImageUrl}
            setCropInfo={value => {
                dispatch({
                    key: 'mainImageCropInfo',
                    type: 'input',
                    value
                })
            }}
            setImageUrl={(key, name) => {
                dispatch({
                    key: 'mainImageKey',
                    type: 'input',
                    value: key
                });
                dispatch({
                    key: 'mainImageName',
                    type: 'input',
                    value: name
                });
            }}
            setFileCallback={setFileCallback}
        />
    );
});