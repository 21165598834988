import React, { useState, useContext } from 'react';
import parse from 'html-react-parser';
import { useMutation } from '@apollo/client';
import { FIX_NOTICE, FIX_FAQ } from '../api/mutations';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import './BoardsInfo.scss';
import kebab from '../img/kebab.svg';
import triangle_up from '../img/triangle_up.svg';
import triangle_down from '../img/triangle_down.svg';
import { AppContext } from 'app';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';

const KebabButton = (props) => {
  const { t, match, itemData } = props;
  const [isButtonActive, setIsButtonActive] = useState(false);
  const menuClassName = isButtonActive ? 'kebab kebab-active' : 'kebab';

  let boardName = match.params.boardName || 'notice';

  const onMenuClick = () => {
    setIsButtonActive(true);
  };
  const onMenuLeave = () => {
    setIsButtonActive(false);
  };

  const [fixNoticeMutation] = useMutation(FIX_NOTICE);
  const [fixFaqMutation] = useMutation(FIX_FAQ);

  // 게시판 이름에 따라 쿼리문 조립하는 로직
  let fixMutation, requestFunctionName;
  if (boardName === 'notice') {
    fixMutation = fixNoticeMutation;
    requestFunctionName = 'updateNotice';
  } else if (boardName === 'faq') {
    fixMutation = fixFaqMutation;
    requestFunctionName = 'updateFaq';
  }

  // 고정글로 옮기기 함수
  const updateFixedValue = async (id, fixingValue?) => {
    try {
      const { data } = await fixMutation({
        variables: {
          id,
          fixed: fixingValue,
        },
      });

      const isSucceed = data && data[requestFunctionName];
      if (!isSucceed) throw new Error('graphql query failed');
    } catch (err) {
      // (TODO: 에러 로깅)
      console.log(err);
    }
    props.refetch();
  };

  const isFixed = !!itemData.fixed;

  // 고정글인지 판단하여 고정하기 또는 고정해제 버튼 동작시킴
  const onClickFix = () => {
    if (isFixed) {
      updateFixedValue(itemData.id);
    } else {
      updateFixedValue(itemData.id);
    }
  };

  // 고정글 순서 올리기 버튼 클릭할 때
  const onClickUp = () => {
    // 고정 글 index 업데이트
    updateFixedValue(itemData.id, itemData.fixed - 1);
  };

  // 고정글 순서 내리기 버튼 클릭할 때
  const onClickDown = () => {
    // 고정 글 index 업데이트
    updateFixedValue(itemData.id, itemData.fixed + 1);
  };

  return (
    <div className={menuClassName} onClick={onMenuClick} onMouseLeave={onMenuLeave}>
      <img src={kebab} />
      <div className="button-container">
        <button onClick={onClickFix}>{isFixed ? t('Boards.unFix') : t('Boards.fix')}</button>
        {isFixed ? (
          <>
            <button onClick={onClickUp}>
              <img src={triangle_up} width="10px" />
            </button>
            <button onClick={onClickDown}>
              <img src={triangle_down} width="10px" />
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

const NoticeInfo = (props) => {
  const { t, match, items, type } = props;
  const { userInfo } = useContext(AppContext);
  const { isAdmin } = userInfo;
  // boardName 가져오기:
  // 1. props에 들어있을 경우(홈화면), 2. url에서 가져오는 경우(게시판 관리 화면), 3. 그 외 default값은 "notice"
  const boardName = props.boardName || match.params.boardName || 'notice';

  let page = 1;
  if (match.params.page) {
    page = +match.params.page;
  }

  // db에서 가져온 게시판 정보 object를 DOM으로 렌더해주는 함수
  const renderBoardItems = (e) => {
    const titleTrClassName = e.fixed || e.fixed === 0 ? 'fixed' : null;
    let content;
    let titleHref = `/board/${boardName}`;
    const isMainPage = type === 'short' && ['notice', 'faq'].includes(boardName);
    if (page > 1) titleHref += '/' + page;
    const query = queryString.parse(props.history.location.search);
    if (query.q && query.type) titleHref += `?q=${query.q}&type=${query.type}`;
    // 게시물이 클릭된 상태일 경우 url 쿼리에 게시물 id를 넣습니다.
    // 이 게시물 id를 체크하여 게시물이 클릭된 상태인지 판단하고 클릭되었을 경우 게시물 body를 렌더합니다.
    if (props.openedItemId === e.id) {
      content = (
        <tr className="content">
          {boardName === 'popup' ? null : <td></td>}
          <td>
            <div className="Editor">
              <div className="body ql-editor">{parse(e.body)}</div>
            </div>
          </td>
          <td></td>
        </tr>
      );
    }

    // 게시물 등록 날짜가 현재로부터 7일 이내일 경우 NEW 배지를 표시합니다.
    const NewBadge = () => {
      if (new Date().valueOf() - new Date(e.created).valueOf() > 1000 * 60 * 60 * 24 * 7) {
        return null;
      } else {
        return <div className="newBadge">NEW</div>;
      }
    };

    // 게시물 등록 날짜 또는 팝업 기간을 표시할 때 날짜를 formatting해주는 옵션입니다.
    const localeDateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

    // 팝업 종료일이 설정되지 않은 경우 2099년 12월 31일이 입력되어 있습니다.
    // 이에 해당하는 경우에는 날짜를 숫자로 표시하지 않고 '종료하지 않음'이라고 표시합니다.
    let endDateText;
    if (new Date(e.endDate).valueOf() > 4000000000000) {
      endDateText = t('Boards.popupNeverEnd');
    } else {
      endDateText = new Date(e.endDate).toLocaleDateString('ko-KR', localeDateOptions);
    }

    // 팝업 종료일이 현재보다 나중일 경우 날짜를 표시하고 이미 종료되었을 경우 '종료'라고 표시합니다.
    const popupPeriod =
      new Date(e.endDate) > new Date() ? (
        <div className="popup_period">
          <div>{new Date(e.startDate).toLocaleDateString('ko-KR', localeDateOptions)}</div>
          <div>~</div>
          <div>{endDateText}</div>
        </div>
      ) : (
        <div className="popup_period">
          <div>{t('Boards.popupFinished')}</div>
        </div>
      );

    // 위의 로직에 따른 결과를 조립하여 최종적으로 게시물 item을 DOM으로 내보내기합니다.
    return (
      <table key={e.id} className={`${type}${isMainPage ? '_' + boardName : ''}`}>
        <colgroup>
          {
            // 팝업 게시판의 경우에는 '유형' 필드를 표시하지 않습니다.
            boardName === 'popup' ? null : <col width="80px" />
          }
          <col />
          {
            // 축약형 게시판이거나 팝업 게시판인 경우에는 '등록일' 필드를 표시하지 않습니다.
            type === 'short' || boardName === 'popup' || isMobile ? null : <col width="110px" />
          }
          {
            // 메인 페이지 상단 축약형 보드인 경우 date를 표시합니다.
            !isMobile && isMainPage && <col width="110px" />
          }
          {
            // 팝업 게시판의 경우에만 '표시기간' 필드를 표시합니다.
            boardName !== 'popup' ? null : <col width="250px" />
          }
        </colgroup>
        <tbody>
          <tr className={titleTrClassName}>
            {
              // 팝업 게시판의 경우에는 '유형' 필드를 표시하지 않습니다.
              boardName === 'popup' ? null : (
                <td>
                  <div className="category-container">
                    <div className={`category ${e.type?.code}`}>{e.type?.code && t(`NoticeCategory.${e.type?.code}`)}</div>
                  </div>
                </td>
              )
            }
            <td>
              <div className="title">
                <div className={`title__text__container${isMobile && !isMainPage ? ' mobile' : ''}`}>
                  <NavLink className={`title__text${isMobile && !isMainPage ? ' mobile' : ''}`} to={`/board/${boardName}/detail/${e.id}`}>
                    {e.title}
                  </NavLink>
                  <NewBadge />
                </div>
                {
                  // 축약형 게시판이거나 관리자 권한이 없는 경우에는 수정, 삭제 버튼을 표시하지 않습니다.
                  type === 'short' || !isAdmin || isMobile ? null : <NavLink to={`/board/${boardName}/write/${e.id}`}>{t('common.edit')}</NavLink>
                }
              </div>
              {isMobile && type !== 'short' && boardName !== 'popup' && (
                <Box className="date_text" mt="4px">
                  {new Date(e.updated).toLocaleDateString('ko-KR', localeDateOptions)}
                </Box>
              )}
            </td>

            {
              // 축약형 게시판이거나 팝업 게시판인 경우에는 '등록일' 필드를 표시하지 않습니다.
              // 메인 페이지 상단 축약형 보드인 경우 date를 표시합니다.
              !isMobile && (isMainPage || (type !== 'short' && boardName !== 'popup')) && (
                <td>
                  <div className="date_text">{new Date(e.updated).toLocaleDateString('ko-KR', localeDateOptions)}</div>
                </td>
              )
            }
            {
              // 축약형 게시판이거나 팝업 게시판인 경우, 관리자 권한이 없는 경우에는
              // 고정글 등록/해제/순서바꾸기 버튼을 표시하지 않습니다.
              type === 'short' || boardName === 'popup' || !isAdmin ? null : (
                <td className="boardItemMenu">
                  <KebabButton {...props} itemData={e} />
                </td>
              )
            }
            {
              // 팝업 게시판의 경우에만 '표시기간' 필드를 표시합니다.
              boardName !== 'popup' ? null : <td className="popup_period">{popupPeriod}</td>
            }
          </tr>
        </tbody>
      </table>
    );
  };

  const itemTags = items?.map(renderBoardItems);

  // 내보내기
  return <div className="notice-board">{itemTags}</div>;
};

export default NoticeInfo;
