import styled from 'styled-components';
import { alpha, keyframes } from '@mui/material/styles';

export const BrandPickModalLoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 216px;
`;

const pulse = keyframes({
  from: { opacity: 1, transform: `scale(1)` },
  to: { opacity: 0.25, transform: `scale(0.75)` },
});

export { alpha, pulse };
