import React, { useState } from 'react';
import styled from 'styled-components';
import { useUrl } from 'aconfaq/hook/useUrl';
import { useTranslation } from 'react-i18next';

const Styled = {
    Div: styled.div`
        display: flex;
        justify-content: center;

        select {
            width:80px;
            height:36px;
            margin-right:5px;
        }
        input {
            width:215px;
            height:36px;
            margin-right:5px;
            padding:5px 10px;
        }
        button {
            margin-right:2.5px;
        }
    `
}

export default ((props) => {
    const { t } = useTranslation();
    const { push } = useUrl();
    // 검색 유형 
    const [searchType, setSearchType] = useState('');
    // 검색어 
    const [keyword, setKeyword] = useState('');

    // 검색유형 select 태그 변경 이벤트 처리기 메소드 
    const onChangeSearchTypeSelectTag = (e) => {
        setSearchType(e.target.value);
    };

    // 검색어 input 태그 변경 이벤트 처리기 메소드 
    const onChangeKeywordInputTag = (e) => {
        setKeyword(e.target.value);
    };

    // 검색 버튼 클릭 이벤트 처리기 메소드 
    const onClickSearchButton = () => {
        // 검색어가 존재하지 않을경우 종료
        if (!keyword)
            // 종료 
            return;

        push({ searchType, keyword });
    };
    return (
        <Styled.Div>
            <select
                onChange={onChangeSearchTypeSelectTag}
                defaultValue={searchType}
            >
                <option value="title">{t("Title.label")}</option>
                <option value="body">{t("Contents.label")}</option>
            </select>
            <input
                onChange={onChangeKeywordInputTag}
                value={keyword}
                className="line"
                type="text"
            />
            <button
                onClick={onClickSearchButton}
                className="min-btn black-button"
                type="button">
                {t("Search.label")}
            </button>
        </Styled.Div>
    );

});