import React, { useState, useContext, useEffect } from 'react';
import ACON from '../../lib/global';
import Editor from '../../common/editor';
import { AppContext } from '../../app'
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './form.scss';

export const Form = ({ titlePlaceholder, subtitlePlaceholder, item, types, save, remove }) => {
    const { userInfo } = useContext(AppContext);
    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    const history = useHistory();
    const { docId } = useParams();

    // 번역기 불러오기
    const { t } = useTranslation();
    // 모달 표시 메소드 불러오기
    const { showAlertMessage, setBackgroundColor } = useContext(AppContext)

    // 제목
    const [title, setTitle] = useState('')
    // 질문형 제목
    const [subtitle, setSubtitle] = useState('')
    // 내용
    const [body, setBody] = useState('')
    // 유형
    const [typeId, setTypeId] = useState(0)
    // 언어
    const [lang, setLang] = useState('');

    const [isDiabled, setIsDisabled] = useState(false)

    useEffect(() => {
        // 배경화면 흰색으로 변경 
        setBackgroundColor('white');
        // 배경화면 색 초기화
        return () => { setBackgroundColor('none') }
    }, [])

    const queryVariables = {
        title,
        subtitle,
        body,
        lang,
        typeId: Number(typeId)
    }
    if (docId) queryVariables.id = Number(docId);

    useEffect(() => {
        if (item) {
            setBody(item.body);
            setTitle(item.title);
            setSubtitle(item.subtitle);
            setTypeId(item.type.id);
            setLang(item.lang ? item.lang : item.language.code);
        }
    }, [item])

    // 제목 input에 적용할 change 이벤트
    const onChangeTitle = (e) => {
        setTitle(e.target.value)
    }

    // 질문형 제목 input에 적용할 change 이벤트
    const onChangeSubtitle = (e) => {
        setSubtitle(e.target.value)
    }

    // 저장 버튼 클릭 이벤트
    const onClickSaveButton = async () => {
        setIsDisabled(true)
        // 폼 유효성이 통과된 경우 저장 액션 수행 
        if (isValidCheckForm()) {
            await save({ variables: queryVariables });

            showAlertMessage(t('saved'), t('saved'));
            history.goBack();
        }

        setIsDisabled(false)
    }

    // 태그의 ref 속성을 통해 변수에 객체를 지정해줌
    let typeSelect, langSelect, titleInput, subtitleInput
    // 폼유효성검사하기 메소드 
    const isValidCheckForm = () => {
        // 포커스 대상 
        let focusTarget;
        try {
            // 제목이 입력되지 않은경우
            if (!title) {
                // 포커스 타겟 지정
                focusTarget = titleInput;
                // 에러 호출
                throw new ACON.ValidationError(t("PleaseEnterATitle.label"));
            }
            // 질문형 제목이 입력되지 않은경우
            if (!subtitle) {
                focusTarget = subtitleInput;
                throw new ACON.ValidationError(t("PleaseEnterASubTitle.label"));
            }
            // 글 유형이 선택되지 않은경우
            if (!typeId) {
                focusTarget = typeSelect;
                throw new ACON.ValidationError(t("PleaseSelectCategory.label"));
            }
            // 몰이 선택되지 않은경우
            if (!lang) {
                focusTarget = langSelect;
                throw new ACON.ValidationError(t("PleaseSelectLanguage.label"));
            }
            return true;
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                showAlertMessage(
                    t("UnableToCompleteSave.label"),
                    errObj.message
                );
                // 포커스 타겟 포커싱
                focusTarget && focusTarget.focus();
                // 종료
                return;
            }
        }
        return false;
    }

    // 게시글 삭제하는 함수
    const deleteDoc = async () => {
        if (docId) {
            await remove({ variables: { id: Number(docId) } })

            showAlertMessage(t("Boards.delete.title"), t("Boards.delete.content"))
            history.goBack()
        }
    }

    // 버튼 그룹 (미리보기, 저장, 삭제) 
    const btnGroup = (
        <div className="aconfaq-write__buttons">
            <button
                className="white-button"
                type="button"
                disabled={isDiabled}
                onClick={deleteDoc}
            >
                {t("Remove.label")}
            </button>
            <button
                className="black-button"
                type="button"
                disabled={isDiabled}
                onClick={onClickSaveButton}
            >
                {t("Save.label")}
            </button>
        </div>
    );

    // 유형(typeId) 설정 변경시 작동하는 로직
    const onChangeType = (e) => {
        setTypeId(e.target.value)
    }

    const TypeSelectTag = () => {
        const options = types?.map((e, i) => {
            return (
                <option key={i} value={e.id}>{e.i18n[0].name}</option>
            )
        })
        return (
            <select
                onChange={onChangeType}
                value={typeId}
                ref={(target) => { typeSelect = target; }}
            >
                <option value={0}>
                    {t("Aconfaq.type.label")}
                </option>
                {options}
            </select>
        )
    }

    // 몰(lang) 설정 변경시 작동하는 로직
    const onChangeLang = (e) => {
        setLang(e.target.value)
    }

    const LanguageSelectTag = () => {
        return (
            <select
                onChange={onChangeLang}
                value={lang}
                ref={(target) => { typeSelect = target; }}
            >
                <option value="">
                    {t("Aconfaq.language.label")}
                </option>
                <option value="ko">KO</option>
                <option value="en">EN</option>
                <option value="cn">CN</option>
                <option value="jp">JP</option>
            </select>
        )
    }

    if (!isAdmin) {
        history.push("/");
        return <></>
    }

    // 하위 컴포넌트 조립 후 최종으로 내보내는 컴포넌트
    return (
        <div className={"aconfaq-write"}>

            <div className="aconfaq-write__flex">
                <LanguageSelectTag />
                <TypeSelectTag />

                {/* 버튼 그룹 */}
                {btnGroup}
            </div>

            {/* 공지사항 - 제목 input */}
            <div className="aconfaq-write__title">
                <div>{t("Aconfaq.write.title.label")}</div>
                <input type="text"
                    name="title"
                    placeholder={titlePlaceholder}
                    value={title}
                    onChange={onChangeTitle}
                    ref={(target) => { titleInput = target; }} />
            </div>

            {/* 공지사항 - 질문형 제목 input */}
            <div className="aconfaq-write__title">
                <div>{t("Aconfaq.write.subtitle.label")}</div>
                <input type="text"
                    name="subtitle"
                    placeholder={subtitlePlaceholder}
                    value={subtitle}
                    onChange={onChangeSubtitle}
                    ref={(target) => { subtitleInput = target; }} />
            </div>

            {/* 본문 에디터 */}
            {(!docId || item) && <Editor
                key={'ko'}
                isAdmin={isAdmin}
                contents={body}
                onChange={setBody}
                docId={Number(docId)}
                showAlertMessage={showAlertMessage}
            />}
        </div>
    );
};