import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Tab, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Box, Tabs } from '@mui/material';
import { TABS } from '../../policy.constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingScreen from 'components/LoadingScreen';
import '../../policy.scss';

export default function Privacy() {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const theme = useDesignSystemTheme();
  const [htmlContent, setHtmlContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const tabs = useMemo(() => TABS(t), [i18n.language]);
  useEffect(() => {
    fetch(`/assets/policy/privacy/privacy_20240801_${i18n.language === 'ko' ? 'ko' : 'en'}.html`)
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html);
        setIsLoading(true);
      });
  }, []);

  if (!isLoading) return <LoadingScreen />;
  if (!htmlContent) return <Typography variant="typography/body/medium/black">Not Found</Typography>;
  return (
    <Box
      sx={{
        padding: '35px 15px 40px',
        width: '100%',
        [theme.breakpoints.down('largeTablet')]: {
          padding: '35px 1px 40px',
        },
      }}
    >
      {' '}
      <Typography
        variant={'typography/title/medium/bold'}
        sx={{
          typography: {
            largeTablet: 'typography/headline/h4/bold',
          },
        }}
      >
        {t('signupPage.policy.agreementWithPrivacy')}
      </Typography>
      <Tabs
        value={pathname}
        sx={{
          mt: 5,
          '& .MuiTabs-flexContainer': { gap: '24px' },
        }}
      >
        {tabs.map(({ label, href }) => (
          <Tab
            key={label}
            size={'L'}
            label={label}
            value={href}
            component={Link}
            /*
                  // @ts-ignore */
            to={href}
          />
        ))}
      </Tabs>
      <Box mt={2}>
        <div className="policy_wrapper" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box>
    </Box>
  );
}
