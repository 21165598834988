import React from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import './index.scss';
import dayjs from 'dayjs';
import { CloseButton } from '../../../../components/button';

// 가입인사 메세지 모달
const modal = ({ modalId, title, contents, isShow, onHide, isFooter }) => {
  const storageKey = `todayNotShow_${modalId}`;

  const onClickConfirmButtonTag = () => {
    onHide();
  };
  const onClickNotShowToday = () => {
    // 오늘일자
    let todayDate = new Date();
    let todayDateStr = dayjs(todayDate).format('YYYY-MM-DD') + 'T23:59:59';

    window.localStorage.setItem(storageKey, todayDateStr);
    onHide();
  };
  let storageItem = window.localStorage.getItem(storageKey);
  // 표시가 참인데, 스토리지 키값이 표시 false일 경우
  if (isShow && storageItem && new Date() < new Date(storageItem)) {
    isShow = false;
  }
  return (
    <Modal className="popupModal" show={isShow} onHide={onHide}>
      <Modal.Header>
        {title}
        <CloseButton
          onClick={onHide}
          style={{
            position: 'absolute',
            top: '26px',
            right: '30px',
            color: 'black',
          }}
        />
      </Modal.Header>
      <Modal.Body>{parse(contents)}</Modal.Body>
      {isFooter && (
        <Modal.Footer>
          <button onClick={onClickNotShowToday} className="notShowToday">
            오늘 하루 보이지 않음
          </button>
          <button onClick={onClickConfirmButtonTag} className="confirmBtn">
            확인
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export const NoticePopup = (props) => {
  let title = '정산확정 일자';
  let contents = `
    한국소비자원의 권고사항에 따라<br />
    2021년 2월 1일 주문건부터 정산 대상기준이 변경되었습니다.<br/>
    <br />
    2021년 2월 이전의 주문건은 실제 정산이 이루어진 기준월과 <br />
    본 화면상의 기준월이 다를 수 있습니다. <br />
    2021년 2월 이전 달의 정산 건에 대해서 궁금한 점이 있으시다면 <br />
    cs@acon3d.com으로 문의 부탁드립니다.
    `;
  let propObj = {
    ...props,
    title,
    contents,
  };
  return modal(propObj);
};
export const SettlePopup = (props) => {
  let title = props.t('settleTable.settlementDate');
  let contents = props.t('settleTable.settlementNote');
  let propObj = {
    ...props,
    title,
    contents,
  };
  return modal(propObj);
};
export const ProductPopup = (props) => {
  let title = props.t('settleTable.appliedWork');
  let contents = props.t('settleTable.appliedWorkNote');
  let propObj = {
    ...props,
    title,
    contents,
  };

  return modal(propObj);
};
