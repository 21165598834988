import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import clock from '../../img/picker_icon_clock.svg';

const KeyboardDatePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker {...props} />
    </MuiPickersUtilsProvider>
  );
};

const ClockIcon = <img width="15px" src={clock} />;

const KeyboardTimePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker keyboardIcon={ClockIcon} {...props} />
    </MuiPickersUtilsProvider>
  );
};

export { KeyboardDatePickerInput, KeyboardTimePickerInput };
