import React, { useEffect, useMemo, useState } from 'react';
import { BrandSaveWrap, BrandTitleWrap, Title } from '../../index.styles';
import { Button } from 'acon-mui/components';
import { useTranslation } from 'react-i18next';
import { BrandIntroduceContainer } from './BrandIntroduce.styles';
import { TextField } from '@mui/material';
import { LanguageCodeEnum, useMyBrandQuery, useStoreBrandIntroductionMutation } from 'generated/graphql';

const BrandIntroduce = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [introduction, setIntroduction] = useState(null);
  const lang = useMemo(() => {
    let lang = localStorage.getItem('i18nextLng');
    if (!lang) lang = i18n.language;
    return lang;
  }, [i18n.language]);
  const { data, loading, refetch } = useMyBrandQuery();
  const brand = data?.myBrand;

  const [storeBrandIntroduction] = useStoreBrandIntroductionMutation({
    onError: (error) => alert(error.message),
  });

  const handleClickSave = async () => {
    try {
      let lang = localStorage.getItem('i18nextLng');
      if (!lang) lang = i18n.language;
      await storeBrandIntroduction({
        variables: {
          lang: lang as LanguageCodeEnum,
          introduction: introduction,
        },
      });
      refetch();
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (brand) {
      setIntroduction(brand?.i18ns?.find((x) => x.languageCode === lang)?.introduction);
    }
  }, [brand, lang]);

  if (loading) return <>loading...</>;

  return (
    <BrandIntroduceContainer>
      <BrandTitleWrap>
        <Title>{t('BrandPage.introduce.title', { lng: lang })}</Title>
      </BrandTitleWrap>
      <TextField value={introduction} onChange={(e) => setIntroduction(e.target.value)} multiline rows={5} placeholder={t('BrandPage.introduce.placeholder')} />
      <BrandSaveWrap>
        <Button colorTheme="primary" onClick={handleClickSave} minWidth="71px" width="fit-content" marginLeft="12px" borderRadius="4px">
          {t('BrandPage.brandPick.save', { lng: lang })}
        </Button>
      </BrandSaveWrap>
    </BrandIntroduceContainer>
  );
};

export default BrandIntroduce;
