import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CustomText } from 'components/style';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    z-index: 2;
    position: fixed;
`
const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    z-index: 3;
`;
const Modal = styled.div`
    opacity: 1;
    position: absolute;
    width: 320px;
    height: 160px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    background: #FFFFFF;
    border-radius: 4px;
    z-index: 3;
`;
const TextContainer = styled.div`
    position: absolute;
    width: 180px;
    height: 38px;
    left: 70px;
    top: 34px;
`;
const BlackButton = styled.div`
    opacity: 1; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px 9px;

    position: absolute;
    width: 280px;
    height: 40px;
    left: calc(50% - 280px/2);
    top: calc(50% - 40px/2 + 36px);
    
    background: #333333;
    border-radius: 4px;

    cursor: pointer;
`;

export const ModalComponent = ({ message, onConfirm }) => {
    // 번역 도구 
    const { t, i18n } = useTranslation();

    return ReactDOM.createPortal(
        <>
            <ModalContainer>
                <Modal>
                    <TextContainer>
                        <CustomText bold textAlign={'center'} size={15} lineHeight={'130%'} style={{width: 180}}>{message}</CustomText>
                    </TextContainer>
                    <BlackButton onClick={onConfirm}>
                        <CustomText color={'#ffffff'} bold>{t('signupPage.confirm')}</CustomText>
                    </BlackButton>
                </Modal>
                <Background onClick={onConfirm} />
            </ModalContainer>
        </>,
        document.body
    );
};