import React from 'react';
import { ModalButton, ModalContainer, ModalContent } from './styled';
import { CustomText } from 'components/style';
import { CloseIcon } from 'components/icon';
import ModalImage from './ModalImage';
import { FlexColumn, FlexRowAlignCenter } from 'components/wrapper';
import { useTranslation } from 'react-i18next';

export default function Modal({ hide, modalType, onClickAllowHide, onClickHide }) {
  const { t } = useTranslation();
  const isModalBottomWrapper = modalType.buttonText || modalType.connectedUrl || modalType.text || modalType.subText;

  return (
    <ModalContainer
      style={hide ? { display: 'none' } : undefined}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {modalType.image && (
        <ModalImage borderRadius={!isModalBottomWrapper ? 'all' : 'top'} src={modalType.image.azureStoragePath} />
      )}
      {isModalBottomWrapper && (
        <ModalContent isImage={!!modalType.image}>
          <FlexColumn style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '24px',
            paddingRight: '24px',
          }}>
            <div style={modalType.buttonText && modalType.connectedUrl ? { marginBottom: '12px' } : undefined}>
              {modalType.text && (
                <CustomText
                  {...{
                    size: 12,
                    letter: -0.01,
                    lineHeight: '150%',
                    color: '#000',
                    weight: 400,
                    style: modalType.subText ? { marginBottom: '4px' } : undefined,
                  }}
                >
                  {modalType.text}
                </CustomText>
              )}
              {modalType.subText && (
                <CustomText
                  {...{
                    size: 10,
                    letter: -0.01,
                    lineHeight: '150%',
                    color: '#000',
                    weight: 400,
                  }}
                >
                  {modalType.subText}
                </CustomText>
              )}
            </div>
            {modalType.buttonText && modalType.connectedUrl && (
              <ModalButton onClick={() => window.open(modalType.connectedUrl)} type="button">
                <CustomText numLines={1} color={'#333'} size={12} weight={500}>
                  {modalType.buttonText}
                </CustomText>
              </ModalButton>
            )}
          </FlexColumn>
        </ModalContent>
      )}
      <FlexRowAlignCenter style={{ justifyContent: 'space-between', paddingTop: '8px', paddingLeft: '8px', paddingRight: '8px' }}>
        <button onClick={onClickAllowHide} style={{ background: 'none' }}>
          <CustomText size={14} weight={500} color={'#fff'}>
            {t('DontShowAgain')}
          </CustomText>
        </button>
        <button onClick={onClickHide} style={{ background: 'none', display: 'flex', alignItems: 'center' }}>
          <CustomText style={{ marginRight: '4px' }} size={14} weight={500} color={'#fff'}>
            {t('close')}
          </CustomText>
          <CloseIcon customStroke={1.333} color={'#fff'} size={16} />
        </button>
      </FlexRowAlignCenter>
    </ModalContainer >
  );
}
