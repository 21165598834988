import React from 'react';
import { Box } from '@mui/material';
import {
  Button,
  DateRangePicker,
  DateSelect,
  IconButton,
  Menu,
  MenuItem,
  RemoveIcon,
  SearchIcon,
  Select,
  TextField,
  Typography,
  useDesignSystemTheme,
} from 'carpenstreet-designsystem';
import { GetOrderLicenseNameField, GetOrderProductPeriodField, OrderLicenseType } from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { DesktopSalesRecordsFilterProps } from './SalesRecordsFilter.types';
import parse from 'html-react-parser';

export default function DesktopSalesRecordsFilter(props: DesktopSalesRecordsFilterProps) {
  const {
    // ref
    keywordInputRef,
    // state
    keyword,
    asset,
    currentAssetIdx,
    keywordAnchor,
    periodField,
    selectedPeriodButton,
    startDay,
    endDay,
    showDatePicker,
    isRefunded,
    isFree,
    licenseType,
    condition,
    conditionKeyword,
    loading,
    // query result
    myAssets,
    // event handler
    onKeywordChange,
    onKeydown,
    onKeywordReset,
    onAssetMenuClose,
    onPeriodTypeChange,
    onToggleDatePicker,
    onSelectStartDay,
    onSelectEndDay,
    onConditionKeywordChange,
    onConditionKeywordReset,
    onApplyButtonClick,
    onResetButtonClick,
    // event handler factory
    makeAssetClickHandler,
    makePeriodButtonClickHandler,
    makeSellingStatusButtonClickHandler,
    makePaymentTypeButtonClickHandler,
    makeLicenseTypeButtonClickHandler,
    makeConditionButtonClickHandler,
  } = props;

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const datePickerLocale = language !== 'ko' ? 'en' : 'ko';

  const theme = useDesignSystemTheme();

  const dateSelectRef = React.useRef(null);

  return (
    <Box
      sx={{
        width: '438px',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        [theme.breakpoints.down('largeTablet')]: {
          display: 'none',
        },
      }}
    >
      {/*  filter  */}
      <Box
        sx={{
          borderRadius: '16px',
          backgroundColor: theme.palette['color/white'],
          padding: '24px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {/*  상품 검색  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            position: 'relative',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.keyword.title')}</Typography>
          <TextField
            variant={'outlined'}
            size={'M'}
            placeholder={t('salesRecordsPage.filter.keyword.placeholder')}
            autoComplete={'off'}
            value={keyword}
            onChange={onKeywordChange}
            onKeyDown={onKeydown}
            InputProps={{
              sx: {
                '&.Mui-disabled': {
                  color: theme.palette['color/gray/800'],
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette['color/gray/600'],
                  },

                  '& path:not([fill="white"]), & circle:not([fill="white"])': {
                    fill: theme.palette['color/gray/800'],
                  },
                  '& rect:not([fill="white"])': {
                    stroke: theme.palette['color/gray/800'],
                  },
                },
              },
              startAdornment: <SearchIcon />,
              ...(asset && {
                endAdornment: (
                  <IconButton size={'S'} onClick={onKeywordReset}>
                    <RemoveIcon color={'color/gray/600'} />
                  </IconButton>
                ),
              }),
            }}
            ref={keywordInputRef}
            disabled={Boolean(asset)}
          />
          <Menu
            open={Boolean(keywordAnchor)}
            onClose={onAssetMenuClose}
            anchorEl={keywordAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoFocus={false}
            disableAutoFocus={true}
            disableEnforceFocus={true}
            sx={{
              top: '8px',
              [theme.breakpoints.down('largeTablet')]: {
                display: 'none',
              },
            }}
            paperSx={{
              width: '390px',
              height: 'fit-content',
            }}
          >
            {myAssets.length === 0 ? (
              <Box sx={{ padding: '6px 16px 38px 16px' }}>
                <Typography variant={'typography/body/small/regular'}>{t('salesRecordsPage.filter.keyword.noItemMessage')}</Typography>
              </Box>
            ) : (
              <>
                {myAssets.slice(0, 5).map((asset, idx) => (
                  <MenuItem key={asset.id} onClick={makeAssetClickHandler(asset)} selected={idx === currentAssetIdx}>
                    {asset.title}
                  </MenuItem>
                ))}
                {myAssets.length === 1 && <Box sx={{ width: '100%', height: '32px' }} />}
              </>
            )}
          </Menu>
        </Box>
        {/*  기간  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.period.title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Select size={'M'} value={periodField} onChange={onPeriodTypeChange}>
              <MenuItem value={GetOrderProductPeriodField.CreatedAt}>{t('salesRecordsPage.filter.period.periodType.payment')}</MenuItem>
              <MenuItem value={GetOrderProductPeriodField.SettledAt}>{t('salesRecordsPage.filter.period.periodType.settle')}</MenuItem>
            </Select>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button
                variant={'outlined'}
                color={selectedPeriodButton === 'manual' ? 'primary' : 'default'}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
                onClick={makePeriodButtonClickHandler('manual')}
              >
                {t('salesRecordsPage.filter.period.periodButton.manual')}
              </Button>
              <Button
                variant={'outlined'}
                color={selectedPeriodButton === 'one-month' ? 'primary' : 'default'}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
                onClick={makePeriodButtonClickHandler('one-month')}
              >
                {t('salesRecordsPage.filter.period.periodButton.oneMonth')}
              </Button>
              <Button
                variant={'outlined'}
                color={selectedPeriodButton === 'three-months' ? 'primary' : 'default'}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
                onClick={makePeriodButtonClickHandler('three-months')}
              >
                {t('salesRecordsPage.filter.period.periodButton.threeMonth')}
              </Button>
              <Button
                variant={'outlined'}
                color={selectedPeriodButton === 'six-months' ? 'primary' : 'default'}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
                onClick={makePeriodButtonClickHandler('six-months')}
              >
                {t('salesRecordsPage.filter.period.periodButton.sixMonth')}
              </Button>
              <Button
                variant={'outlined'}
                color={selectedPeriodButton === 'entire' ? 'primary' : 'default'}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
                onClick={makePeriodButtonClickHandler('entire')}
              >
                {t('salesRecordsPage.filter.period.periodButton.entire')}
              </Button>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
              }}
            >
              <DateSelect
                locale={datePickerLocale}
                value={{ startDay, endDay }}
                isRange={true}
                disabled={selectedPeriodButton !== 'manual'}
                onClick={onToggleDatePicker}
                ref={dateSelectRef}
                focused={showDatePicker}
                sx={{
                  width: '100%',
                }}
              />
              {showDatePicker && (
                <DateRangePicker
                  startDay={startDay}
                  onSelectStartDay={onSelectStartDay}
                  endDay={endDay}
                  onSelectEndDay={onSelectEndDay}
                  locale={datePickerLocale}
                  anchorRef={dateSelectRef}
                  onClose={onToggleDatePicker}
                  showController={true}
                  sx={{
                    position: 'absolute',
                    top: '64px',
                    width: '100%',
                    backgroundColor: theme.palette['color/white'],
                    zIndex: 100,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        {/*  판매 상태  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.status.title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              color={isRefunded === null ? 'primary' : 'default'}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
              onClick={makeSellingStatusButtonClickHandler(null)}
            >
              {t('salesRecordsPage.filter.status.all')}
            </Button>
            <Button
              variant={'outlined'}
              color={isRefunded === false ? 'primary' : 'default'}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
              onClick={makeSellingStatusButtonClickHandler(false)}
            >
              {t('salesRecordsPage.filter.status.sold')}
            </Button>
            <Button
              variant={'outlined'}
              color={isRefunded === true ? 'primary' : 'default'}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
              onClick={makeSellingStatusButtonClickHandler(true)}
            >
              {t('salesRecordsPage.filter.status.cancelled')}
            </Button>
          </Box>
        </Box>
        {/*  유/무료 상품  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.payment.title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              color={isFree === null ? 'primary' : 'default'}
              onClick={makePaymentTypeButtonClickHandler(null)}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
            >
              {t('salesRecordsPage.filter.payment.all')}
            </Button>
            <Button
              variant={'outlined'}
              color={isFree === false ? 'primary' : 'default'}
              onClick={makePaymentTypeButtonClickHandler(false)}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
            >
              {t('salesRecordsPage.filter.payment.paid')}
            </Button>
            <Button
              variant={'outlined'}
              color={isFree === true ? 'primary' : 'default'}
              onClick={makePaymentTypeButtonClickHandler(true)}
              size={'L'}
              sx={{ flex: '1 1 0px', padding: 0 }}
            >
              {t('salesRecordsPage.filter.payment.free')}
            </Button>
          </Box>
        </Box>
        {/*  사용권 유형  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.license.title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button
                variant={'outlined'}
                color={!licenseType ? 'primary' : 'default'}
                onClick={makeLicenseTypeButtonClickHandler(null)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
              >
                {t('salesRecordsPage.filter.license.all')}
              </Button>
              <Button
                variant={'outlined'}
                color={licenseType === OrderLicenseType.Personal ? 'primary' : 'default'}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Personal)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
              >
                {t('salesRecordsPage.filter.license.personal')}
              </Button>
              <Button
                variant={'outlined'}
                color={licenseType === OrderLicenseType.Company ? 'primary' : 'default'}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Company)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
              >
                {t('salesRecordsPage.filter.license.company')}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button
                variant={'outlined'}
                color={licenseType === OrderLicenseType.Enterprise_5 ? 'primary' : 'default'}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Enterprise_5)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
              >
                {t('salesRecordsPage.filter.license.enterprise_5')}
              </Button>
              <Button
                variant={'outlined'}
                color={licenseType === OrderLicenseType.Enterprise_0 ? 'primary' : 'default'}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Enterprise_0)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0 }}
              >
                {t('salesRecordsPage.filter.license.enterprise_0')}
              </Button>
            </Box>
          </Box>
        </Box>
        {/*  조건 검색  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.filter.condition.title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button
                variant={'outlined'}
                color={condition === GetOrderLicenseNameField.Author ? 'primary' : 'default'}
                onClick={makeConditionButtonClickHandler(GetOrderLicenseNameField.Author)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: '12px 0', height: 'fit-content' }}
              >
                {parse(t('salesRecordsPage.filter.condition.type.creator'))}
              </Button>
              <Button
                variant={'outlined'}
                color={condition === GetOrderLicenseNameField.Project ? 'primary' : 'default'}
                onClick={makeConditionButtonClickHandler(GetOrderLicenseNameField.Project)}
                size={'L'}
                sx={{ flex: '1 1 0px', padding: 0, height: '100%' }}
              >
                {t('salesRecordsPage.filter.condition.type.creation')}
              </Button>
            </Box>
            <TextField
              variant={'outlined'}
              size={'M'}
              placeholder={t('salesRecordsPage.filter.condition.placeholder')}
              InputProps={{
                startAdornment: <SearchIcon />,
                ...(conditionKeyword && {
                  endAdornment: (
                    <IconButton size={'S'} onClick={onConditionKeywordReset}>
                      <RemoveIcon color={'color/gray/600'} />
                    </IconButton>
                  ),
                }),
              }}
              value={conditionKeyword}
              onChange={onConditionKeywordChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Button variant={'contained'} color={'primary'} size={'L'} fullWidth={true} onClick={onApplyButtonClick} disabled={loading || !startDay || !endDay}>
            {t('salesRecordsPage.filter.apply')}
          </Button>
          <Button variant={'outlined'} size={'L'} fullWidth={true} disabled={loading} onClick={onResetButtonClick}>
            {t('salesRecordsPage.filter.reset')}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '24px',
          borderRadius: '16px',
          backgroundColor: theme.palette['color/grayishViolet/200'],
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'} color={'color/primary/600'}>
            •
          </Typography>
          <Typography variant={'typography/label/large/bold'} color={'color/primary/600'}>
            {t('salesRecordsPage.footer.notice.line1')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line2')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
