import React from 'react';
import { List } from './style';
import Item from './item';

export default (({ items, setItem, onClick }) => {
    if(!items) {
        return <div></div>
    }
    if(items.length === 0)
        return <div></div>
    return (
        items && items.length > 0 && (
            <List>
                {items && items.map(x =>
                    <Item
                        item={x}
                        onClick={onClick}
                        setItem={setItem}
                    />
                )}
            </List >
        )
    );
});