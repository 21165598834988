import { useEffect, useState } from 'react';
import axios from 'axios';
import { AbortController } from '@azure/abort-controller';
import { BlockBlobClient } from '@azure/storage-blob';
import { UploadBlobMutation, useGetSasUrlQuery, useUploadBlobMutation } from 'generated/graphql';

type UploadFileType = UploadBlobMutation['uploadBlobStorage'];

type FileUploadOption = {
  contentType: string;
  ext: string;
  isPublic: boolean;
};

const AUTH_ORIGIN = 'https://' + (process.env.NETWORK_HOST_AUTH || 'staging-api.acon3d.com/auth');

const getFileCredentials = (option: FileUploadOption) => axios.post(`${AUTH_ORIGIN}/file`, option);

export default function useUpload(directory: string) {
  const [setAzureImage] = useUploadBlobMutation();
  const { refetch } = useGetSasUrlQuery({
    skip: true,
  });
  const [controller, setController] = useState<AbortController | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    setController(controller);
  }, []);

  const cancel = (): void => {
    if (!controller) return;
    controller.abort();
  };

  const getDataUrl: (uploadFileId: number) => Promise<string> = async (uploadFileId: number) => {
    const { data } = await refetch({
      inputs: {
        id: uploadFileId,
      },
    });

    const client = new BlockBlobClient(data.sasUrl);
    const response = await client.download();

    const blob = await response.blobBody;

    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob as Blob);
    });
  };

  const getFileSize: (uploadFileId: number) => Promise<number> = async (uploadFileId: number) => {
    const { data } = await refetch({
      inputs: {
        id: uploadFileId,
      },
    });

    const client = new BlockBlobClient(data.sasUrl);
    const response = await client.download();

    const blob = await response.blobBody;

    return blob ? blob.size : 0;
  };

  const download = async (uploadFileId: number, fileName: string) => {
    const dataUrl = await getDataUrl(uploadFileId);
    const anker = document.createElement('a');
    anker.href = dataUrl;

    anker.download = fileName;
    anker.click();
  };

  const privateUpload: (file: File, arg?: any, progressCallback?: (count: number) => void) => Promise<string> = async (
    file: File,
    arg?: any,
    progressCallback?: (count: number) => void,
  ) => {
    const config = progressCallback && {
      onUploadProgress: function (progressEvent: any) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(percentCompleted);
      },
    };
    const options: any = {};

    if (file.type) {
      options.contentType = file.type;
    }

    if (file.name) {
      options.ext = file.name.split('.').pop();
    }

    // second argument is isPublic
    if (typeof arg === 'boolean') {
      options.isPublic = arg;
    }

    // second argument is directory
    if (typeof arg === 'string') {
      options.fileName = arg;
    }

    if (typeof arg === 'object') {
      for (const key in arg) {
        options[key] = arg[key];
      }
    }

    const { data: url } = await getFileCredentials(options);

    return new Promise(async (resolve, reject) => {
      const client = new BlockBlobClient(url);
      const result = await client.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });
      resolve(`${client.containerName}/${client.name}`);
    });
  };

  const upload = async (file: File, progressCallback: (progress: number) => void): Promise<UploadFileType | null> => {
    // 파일 옵션
    const options: FileUploadOption = {
      // 파일 유형
      contentType: file.type,
      // 확장자
      ext: file.name.split('.').pop() || '',
      // 공용 여부
      isPublic: true,
    };

    // 파일 URL
    const { data: url } = await getFileCredentials(options);

    const client = new BlockBlobClient(url);

    await client.uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
      onProgress: (ev) => {
        const progress = Math.round((100 * ev.loadedBytes) / file.size);
        progressCallback && progressCallback(progress);
      },
    });

    const temporaryFilePath = client.containerName + '/' + client.name;

    const { data } = await setAzureImage({
      variables: {
        targetDirectory: directory,
        temporaryFilePath,
        fileName: file.name,
      },
    });

    if (!data) return null;
    return data.uploadBlobStorage;
  };

  return { privateUpload, upload, cancel, download, getDataUrl, getFileSize };
}
