import React, { useState, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Row, Option, Left, Right, Select, Input, CheckboxItem, TreeBtn } from '../style'
import Tree from './tree'
import { useTranslation } from 'react-i18next'
import { CategoryDataContext } from '../categoryData'
import Event from './event'
import { MAIN_CATEGORY } from '../constants';
const TreeView = styled.div`width: 100%;`


export default (props) => {
	const { categories } = props
	const { categoryStateList, categoryTypeList, langList, setIsLoading, setCategoryData } = useContext(CategoryDataContext)
	const { t, i18n } = useTranslation()
	const [selectCategoryType, setSelectCategoryType] = useState(MAIN_CATEGORY.TOON_STYLE)
	const [selectLang, setSelectLang] = useState(i18n.language)

	// 카테고리 상태 배열
	const [categoryState, setCategoryState] = useState(['standby', 'liveon'])
	// 검색 키워드
	const [searchKeyword, setSearchKeyword] = useState('')
	let {
		onChangeCategoryType,
		onChangeLang,
		onChangeState,
		onChangeSearchInputTag,
		onClickSyncBtn,
		onAddRootCategory
	} = Event(t, setSelectCategoryType, setSelectLang,
		setCategoryState, setSearchKeyword, setIsLoading)

	var categoryStateEl = useMemo(() => {
		return categoryStateList.map(d => {
			var temp = {
				key: d.id,
				name: "categoryState",
				text: d.text,
				value: d.id,
				checked: categoryState.includes(d.id),
				onChange: onChangeState
			}
			return <CheckboxItem  {...temp} />
		})
	}, [categoryStateList, categoryState])

	return (
		<TreeView>
			<Row>
				<Left>
					<Select onChange={onChangeCategoryType}
						value={selectCategoryType}>
						{categoryTypeList.map((x) => (
							<Option key={x.id} value={x.id}>
								{x.lang[selectLang]}
							</Option>
						))}
					</Select>
					<Select value={selectLang} onChange={onChangeLang}>
						{langList.map((x) => (
							<Option key={x.id} value={x.id}>
								{x.lang[selectLang]}
							</Option>
						))}
					</Select>
				</Left>
			</Row>
			<Row>
				{categoryStateEl}
			</Row>
			<Left>
				<TreeBtn onClick={onClickSyncBtn}>{t('sync')}</TreeBtn>
			</Left>
			<Right>
				<TreeBtn onClick={onAddRootCategory(null, setCategoryData, selectCategoryType)}>{t('addRootcCategory')}</TreeBtn>
			</Right>
			<Input type="text" onChange={onChangeSearchInputTag} value={searchKeyword} placeholder={t('inputSearchKeyword')} />
			<Tree
				{...{
					categories,
					selectCategoryType,
					searchKeyword,
					selectLang,
					categoryState,
					onAddRootCategory
				}}
			/>
		</TreeView>
	)
}
