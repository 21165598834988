import { atom, DefaultValue, selector } from "recoil";
import { isEmail } from "utils/validation";
import { UploadFileType } from "./type";

export const bankIdState = atom({
  key: "settleBankId",
  default: 0,
});

export const bankAccountOwnerState = atom({
  key: "settleBankAccountOwner",
  default: "",
});

export const bankNameState = atom({
  key: "settleBankName",
  default: "",
});

export const bankAccountNumberState = atom({
  key: "settleBankAccountNumber",
  default: "",
});

export const idCardState = atom({
  key: "settleIdCard",
  default: {
    key: "",
    name: "",
    size: 0,
  },
});

export const bankBookState = atom({
  key: "settleBankBook",
  default: {
    key: "",
    name: "",
    size: 0,
  },
});

export const limitedTaxAplState = atom({
  key: "settleLimitedTaxApl",
  default: {
    key: "",
    name: "",
    size: 0,
  },
});

export const isEmailPaymentAccountSelector = selector<boolean>({
  key: "isEmailPaymentAccount",
  get: ({ get }) => {
    const account = get(bankAccountNumberState);

    return isEmail(account);
  },
});

export const userSettleModifyState = selector<{
  bankId?: number;
  bankAccountOwner?: string;
  bankName?: string;
  bankAccountNumber?: string;
  idCard?: UploadFileType;
  bankBook?: UploadFileType;
  limitedTaxApl?: UploadFileType;
}>({
  key: "userSettleModifyForm",
  get: ({ get }) => {
    return {
      bankId: get(bankIdState),
      bankAccountOwner: get(bankAccountOwnerState),
      bankName: get(bankNameState),
      bankAccountNumber: get(bankAccountNumberState),
      idCard: get(idCardState),
      bankBook: get(bankBookState),
      limitedTaxApl: get(limitedTaxAplState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(bankIdState);
      reset(bankAccountOwnerState);
      reset(bankNameState);
      reset(bankAccountNumberState);
      reset(idCardState);
      reset(bankBookState);
      reset(limitedTaxAplState);
    } else {
      set(bankIdState, newValue.bankId);
      set(bankAccountOwnerState, newValue.bankAccountOwner);
      set(bankNameState, newValue.bankName);
      set(bankAccountNumberState, newValue.bankAccountNumber);
      set(idCardState, newValue.idCard);
      set(bankBookState, newValue.bankBook);
      set(limitedTaxAplState, newValue.limitedTaxApl);
    }
  },
});
