import ACON from 'lib/global';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import context from '../../provider/product';
import { Item, Title, Value, Input } from '../../style';
import { Wrap, CheckboxItem, TitleWrapper } from './style';

export default (({ isAdmin }) => {
    // 번역도구 
    const { t } = useTranslation();
    // URL 파생 정보 
    const { lang } = useParams();
    const { state, dispatch } = useContext(context);

    // 단가 변경 이벤트 처리기 메소드 
    const onChangeSalePrice = e => {
        // 금액
        let salePrice = e.target.value === '' ? '' : ACON.OnlyNumber(e.target.value);
        // 금액이 0일 경우 
        if (salePrice === 0) {
            dispatch({ key: 'isFree', type: 'input', value: true });
        }
        dispatch({
            key: 'salePrice',
            type: 'blur.salesPrice',
            value: salePrice
        })
    };
    const onChangeCheckboxItem = (value, checked) => {
        dispatch({ key: 'isFree', type: 'input', value: checked });
        let price = 0;

        if (!checked)
            price = '';

        dispatch({ key: 'salePrice', type: 'input', value: price });
    };
    return useMemo(() => {
        return (
            <Wrap isDisp={state.isDisp}>
                <Item>
                    <TitleWrapper>
                        <Title>
                            {isAdmin && t('goods.price')}
                            {!isAdmin && `${t('desiredPrice')} ${lang === 'en' ? '(USD)' : lang === 'cn' ? '(美元)' : lang === 'jp' ? '(ドル)' : ''}`}
                        </Title>
                        <CheckboxItem
                            isAdmin={isAdmin}
                            checked={state.isFree}
                            onChange={onChangeCheckboxItem}
                        >{t('freeSource')}
                        </CheckboxItem>
                    </TitleWrapper>
                    <Value>
                        <Input
                            maxLength={13}
                            disabled={state.isDisp || state.isFree}
                            placeholder={(() => {
                                switch (lang) {
                                    case 'ko':
                                        return 'KRW';
                                    case 'en':
                                        return 'USD'
                                    case 'cn':
                                        return '美元';
                                    case 'jp':
                                        return 'ドル';
                                    default:
                                        return 'KRW';
                                }
                            })()}
                            value={state.salePrice}
                            onChange={onChangeSalePrice}
                        />
                    </Value>
                </Item>
            </Wrap >
        );
    }, [state.salePrice, state.isDisp, state.isFree, lang]);
});