import React, { useCallback } from 'react';
import parse from 'html-react-parser';
import { styled, Typography, Box } from '@mui/material';
import UploadIcon from 'acon-mui/icons/icon-upload.svg';
import { color } from 'acon-mui/style';
import { useTranslation } from 'react-i18next';

interface Props {
  description?: string;
  accept?: string;
  onChange: (e?) => void;
  onClick?: () => void;
}

const Input = styled('input')({
  display: 'none',
});

const FileUpload = React.forwardRef(
  (
    { description, accept = 'zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed', onChange, onClick }: Props,
    ref?: React.MutableRefObject<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDrop = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files;
        if (files && ref && ref.current) {
          ref.current.files = files;
          onChange();
        }
      },
      [ref, onChange],
    );
    return (
      <Box>
        <Box
          onClick={onClick}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          border="1px dashed rgba(0, 0, 0, 0.12)"
          borderRadius="4px"
          p={3}
          sx={{ cursor: 'pointer' }}
        >
          <Input ref={ref} type="file" draggable onChange={onChange} accept={accept} />
          <img alt="upload icon" src={UploadIcon} width={40} height={40} />
          <Typography
            mt={1}
            fontWeight="400"
            fontSize="16px"
            lineHeight="28px"
            letterSpacing="0.15px"
            color="rgba(0, 0, 0, 0.87)"
            sx={{
              '& span': {
                color: color.primary,
                textDecoration: 'underline',
              },
            }}
          >
            {parse(t('fileUpload.clickOrDrag'))}
          </Typography>
          {description && (
            <Typography mt={1} fontWeight="400" fontSize="14px" lineHeight="20px" letterSpacing="0.17px" color="rgba(0, 0, 0, 0.6)" textAlign="center">
              {parse(description)}
            </Typography>
          )}
        </Box>
      </Box>
    );
  },
);

export default FileUpload;
