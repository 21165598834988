import React, { useState, useId, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ImageDialog.scss';

// 요청하기 위한 모달입니다.
export default function (props) {
  const { close, isShow, uploadImages, loading } = props;
  const [url, setUrl] = useState('');
  const [files, setFiles] = useState(null);
  const { t } = useTranslation();
  const id = useId();

  const onFileChange = (e) => {
    const fileList = e.target.files;
    if (!fileList || !fileList.length) return;
    setFiles(fileList);
  };

  const onClickConfirmButton = async (e) => {
    // const result = insertImage(url);
    handleOnClose();
    loading(true);
    await uploadImages(files);
  };
  const handleOnClose = () => {
    setFiles(null);
    close();
  };

  return (
    <Modal className="requestDialog" show={isShow} onHide={handleOnClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('imageDialog.title')}</Modal.Title>
        <div className="modal-sub-title">{t('imageDialog.description')}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="input-file-group">
          <input type="file" id={id} onChange={onFileChange} multiple accept="image/*" />
          <label for={id}>
            <div className="custom-file-tag">{t('imageDialog.file')}</div>
          </label>

          <span>{files ? (files.length > 1 ? t('imageDialog.fileLength', { length: files.length }) : files[0].name) : t('imageDialog.imgNotfound')}</span>
        </div>
        {props.showUrlInput && (
          <>
            <div className="requestDialog__description">{t('imageDialog.url')}</div>
            <input type="text" onChange={(e) => setUrl(e.target.value)} placeholder={t('imageDialogDescription')} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="requestBtn" onClick={onClickConfirmButton}>
          {t('imageDialog.button')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
