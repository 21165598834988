import styled from 'styled-components';
import React from 'react';
import { CustomText } from 'components/style';

const getBorderColor = ({ isValid, value }) => {
    if (value === '') {
        return '#CDCDCD';
    }
    if (isValid) {
        return '#333333';
    } else {
        return '#F300BA';
    }
};

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 16px;

        width: ${props => props.width ? `${props.width}px` : '100%'};
        border: 1px solid ${props => getBorderColor(props)};
        border-radius: 4px;

        &:focus {
            outline: none;
            border: 1px solid #333333;
        }
    `,
    SimpleInput: styled.input`
        width: 100%;
        border: 0;
        box-sizing: border-box;
        font-size: 14px;

        &::placeholder {
            font-size: 14px;
            color: #DFDFDF;
        }

        &:focus {
            outline: none;
            border: 0;
        }
    `,
};

const LabelTextInput = (props) => {
    return (
        <Styled.Container width={props.width} isValid={props.isValid} value={props.value}>
            <CustomText color={'#7c7c7c'} size={14} style={{ marginBottom: 10 }}>{props.labelText}</CustomText>
            <Styled.SimpleInput 
                placeholder={props.placeholder} 
                onChange={e => props.onChange(e)}
                onBlur={e => props.onBlur(e)}
                value={props.value}
            />
        </Styled.Container>
    );
};

export default LabelTextInput;