import React, { ReactNode } from "react";
import { DialogType } from "./type";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

type Props = DialogType & {
  title: string;
  contents: string;
  children?: ReactNode;
};

export default function CommonDialog({
  title,
  contents,
  isOpen,
  onClose,
  children,
}: Props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      <Stack>
        <Stack
          sx={{ pl: 3, pr: 3, pt: 3, textAlign: "center" }}
          maxWidth={"400px"}
          spacing={2}
        >
          <Typography variant="h6">{title}</Typography>
          {contents && <Typography>{parse(contents)}</Typography>}
          {children}
        </Stack>
        <Stack p={3}>
          <Button
            onClick={onClose}
            variant={"contained"}
            size="large"
            sx={{ flex: 1 }}
          >
            {t("dialog.confirm")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
