import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as UploadIcon } from 'acon-mui/icons/icon-upload.svg';
import { ReactComponent as UploadFailIcon } from 'acon-mui/icons/icon-upload-fail.svg';
import { ReactComponent as XIcon } from 'acon-mui/icons/icon-x.svg';
import { Typography } from '@mui/material';
import { color } from 'acon-mui/style';
import { useTranslation } from 'react-i18next';
import { FileStatus } from 'boards/DetailBoardWrite/stores/document.types';

type Props = {
  status?: FileStatus;
  fileName?: string;
  fileSize?: number;
  progress?: number;
  onDelete?: () => void;
} & React.CSSProperties;

export default function File({ status = null, fileName, fileSize, progress = 0, onDelete, ...rest }: Props) {
  const { i18n, t } = useTranslation();
  const isKorean = i18n.language === 'ko';

  return (
    <Box display="flex" px={2} sx={rest}>
      {status !== 'fail' ? <UploadIcon /> : <UploadFailIcon />}
      <Box ml={2} flex={1} justifyContent={'center'} flexDirection={'column'} display={'flex'}>
        <Typography color={status !== 'fail' ? '#000000DE' : '#D32F2F'} lineHeight="28px">
          {fileName}
        </Typography>
        <Typography color={status !== 'fail' ? '#00000099' : '#D32F2F'} fontSize="14px" lineHeight="20px">
          {fileSize ? `${Math.round(fileSize / 1024)}kb` : null}
          {fileSize ? status && <>&nbsp;•&nbsp;</> : null}
          {status === 'inprogress' && (isKorean ? '업로드 중' : 'Uploading')}
          {status === 'success' && (isKorean ? '완료' : 'Complete')}
          {status === 'fail' && t('document.file.status.fail')}
        </Typography>
        {status !== null && (
          <Box mt={2} width="100%" height="4px" borderRadius="99px" overflow="hidden" sx={{ background: status !== 'fail' ? 'rgb(233,233,253)' : 'rgb(227, 175, 173)' }}>
            {status !== 'fail' && <Box width={`${progress}%`} height="4px" sx={{ background: color.primary }} />}
          </Box>
        )}
      </Box>
      <Box ml={4} onClick={onDelete}>
        <XIcon />
      </Box>
    </Box>
  );
}
