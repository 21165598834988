import React from 'react';
import { Box } from '@mui/material';
import { AconLogo, Divider, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import mixpanel from '../../lib/mixpanel/mixpanel';
import { EVENT_NAME } from '../../lib/mixpanel/mixpanel.constants';
export default function Footer() {
  const theme = useDesignSystemTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function makeClickFooterLogger(value: string) {
    return () => {
      mixpanel.event(EVENT_NAME.CLICK_FOOTER, { menu: value });
    };
  }

  function handleBizInfoClick() {
    // 사업자 등록번호 코드
    const code = 3648701374;
    // window open
    const win = window.open(
      `http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=${code}`,
      'communicationViewPopup',
      'width=750,height=700,resizable=no,scrollbars=no',
    );
    win.focus();
    // window 반환
  }

  return (
    <Box
      sx={{
        padding: '48px 40px',
        width: '100%',
        backgroundColor: theme.palette['color/white'],
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        borderTop: `1px solid ${theme.palette['color/gray/dim/200']}`,
        [theme.breakpoints.down('largeTablet')]: {
          padding: '32px 16px',
          gap: '24px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& path': {
            fill: theme.palette['color/gray/600'],
          },
          [theme.breakpoints.down('largeTablet')]: {
            justifyContent: 'start',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'start',
          },
        }}
      >
        <AconLogo variant={'black'} width={60} height={18} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('largeTablet')]: {
              gap: '8px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              [theme.breakpoints.down('largeTablet')]: {
                gap: '8px',
              },
            }}
          >
            <Link to={'/policy/agreement'} target={'_blank'} onClick={makeClickFooterLogger('TC')}>
              <Typography
                variant={'typography/label/large/regular'}
                color={'color/gray/400'}
                sx={{
                  typography: {
                    largeTablet: 'typography/body/small/regular',
                  },
                }}
              >
                {t('footer.menu.text1')}
              </Typography>
            </Link>

            <Divider
              orientation={'vertical'}
              sx={{
                height: '20px',
                [theme.breakpoints.down('largeTablet')]: {
                  height: '16px',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              [theme.breakpoints.down('largeTablet')]: {
                gap: '8px',
              },
            }}
          >
            <Link to={'/policy/privacy'} target={'_blank'} onClick={makeClickFooterLogger('PIPP')}>
              <Typography
                variant={'typography/label/large/bold'}
                color={'color/gray/400'}
                sx={{
                  typography: {
                    largeTablet: 'typography/body/small/bold',
                  },
                }}
              >
                {t('footer.menu.text2')}
              </Typography>
            </Link>
            <Divider
              orientation={'vertical'}
              sx={{
                height: '20px',
                [theme.breakpoints.down('largeTablet')]: {
                  height: '16px',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              [theme.breakpoints.down('largeTablet')]: {
                gap: '8px',
              },
            }}
          >
            {language === 'ko' ? (
              <a href={'https://partnerguide.acon3d.com/'} target={'_blank'} onClick={makeClickFooterLogger('Guide')}>
                <Typography
                  variant={'typography/label/large/regular'}
                  color={'color/gray/400'}
                  sx={{
                    typography: {
                      largeTablet: 'typography/body/small/regular',
                    },
                  }}
                >
                  {t('footer.menu.text3')}
                </Typography>
              </a>
            ) : (
              <a href={'https://partnerguide.acon3d.com/en'} target={'_blank'} onClick={makeClickFooterLogger('Guide')}>
                <Typography
                  variant={'typography/label/large/regular'}
                  color={'color/gray/400'}
                  sx={{
                    typography: {
                      largeTablet: 'typography/body/small/regular',
                    },
                  }}
                >
                  {t('footer.menu.text3')}
                </Typography>
              </a>
            )}
          </Box>
          <Divider
            orientation={'vertical'}
            sx={{
              height: '20px',
              [theme.breakpoints.down('largeTablet')]: {
                height: '16px',
              },
            }}
          />
          <a
            href={
              language === 'ko'
                ? 'https://partnerguide.acon3d.com/bdab03e7-5477-47b5-bdbf-156fb84df0ec#bdab03e7-5477-47b5-bdbf-156fb84df0ec'
                : 'https://partnerguide.acon3d.com/en/safetransactionscenter'
            }
            target={'_blank'}
            onClick={makeClickFooterLogger('Center')}
          >
            <Typography
              variant={'typography/label/large/regular'}
              color={'color/gray/400'}
              sx={{
                typography: {
                  largeTablet: 'typography/body/small/regular',
                },
              }}
            >
              {t('footer.menu.text4')}
            </Typography>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('largeTablet')]: {
              gap: '8px',
            },
          }}
        >
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line1.text1')}
          </Typography>
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line1.text2')}
          </Typography>
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line1.text3')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('largeTablet')]: {
              gap: '8px',
            },
          }}
        >
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line2.text1')}
          </Typography>
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line2.text2')}
          </Typography>
          <Typography
            variant={'typography/label/large/bold'}
            color={'color/gray/400'}
            onClick={handleBizInfoClick}
            sx={{
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
          >
            {t('footer.line2.text3')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('largeTablet')]: {
              gap: '8px',
            },
          }}
        >
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line3.text1')}
          </Typography>
          {language === 'ko' && (
            <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
              {t('footer.line3.text2')}
            </Typography>
          )}
          <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
            {t('footer.line3.text3')}
          </Typography>
        </Box>
        <Typography variant={'typography/label/large/regular'} color={'color/gray/600'}>
          {t('footer.line4.text1')}
        </Typography>
      </Box>
    </Box>
  );
}
