import React, { Component, Fragment, useContext, useRef, useState } from 'react';
import Message from '../common/Message';
import Spinner from '../spinner/Spinner';
import axios from 'axios';
import {  useTranslation, withTranslation } from 'react-i18next'
import './ChangePw.scss';
import { AppContext } from 'app';
import et from 'date-fns/esm/locale/et/index.js';
import { useMutation } from '@apollo/client';
import { PASSWORD_CHANGE } from 'api/mutations';

export default (props) => {
    const { t } = useTranslation();

    const passwordInput = useRef();
    const rePasswordInput = useRef();

    const [ password, setPassword ] = useState('');
    const [ rePassword, setRePassword ] = useState('');
    const [ isRequest, setIsRequest ] = useState(false);
    const { showAlertMessage } = useContext(AppContext);
    const [ passwordChange ] = useMutation(PASSWORD_CHANGE);

    let key, _id;

    if (props.match.params && props.match.params.key) {
        key = props.match.params.key
    } else if (props.location._id) {
        _id = props.location._id
    } else {
        props.history.goBack()
    }

    // 버튼 활성화 여부 
    const isEnabled = (password === rePassword)
        && password.length > 0 && !isRequest;

    // 요청버튼 값  
    const requestBtnVal = isRequest ? (
        <Spinner color="#FFFFFF" size="24" /> 
    ) : t("ChangePassword.label");

    async function mutation() {
        try {
            await passwordChange({ variables: { key, password } });

            showAlertMessage(' ', t('changePassword.success'));
        } catch (err) {
            if (err.message === 'not found') {
                showAlertMessage(' ', t('searchPassword.wrongInput'));
                return;
            }

            showAlertMessage(' ', t('changePassword.fail'));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;

        if (name === 'password') {
            setPassword(value);
        }

        if (name === 'rePassword') {
            setRePassword(value);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setIsRequest(true);

        if (key || _id) {
            await mutation();
        }
        
        setIsRequest(false);
    }

    /**
     * 패스워드 양식 클릭 이벤트 처리기 메소드 
     * @memberof ChangePw
     */
    function onClickPasswordForm(e) {
        // 패스워드 input 태그 포커싱 
        passwordInput.current.focus();
    }

    /**
     * 패스워드 확인 양식 클릭 이벤트 처리기 메소드 
     * @memberof ChangePw
     */
    function onClickRePasswordForm(e) {
        // 패스워드 확인 input 태그 포커싱 
        rePasswordInput.current.focus();
    }

    // 패스워드 양식 
    const passwordForm = (
        <>
            <div className="changePw__line" onClick={onClickPasswordForm}>
                {/* 패스워드 input 을 감싸고있는 div 태그 */}
                <div className="changePw__input">
                    {/* 상태값을 통한 placeholder 표시 */}
                    {!password ? (<div className="placeholder">{t("NewPassword.label")}</div>) : <></>}

                    {/* 패스워드 input */}
                    <input type="password"
                        name="password"
                        // onBlur={onBlurPassword}
                        onChange={handleChange}
                        ref={passwordInput}
                    />

                    {/* 패스워드 입력에 따른 유효성 메세지 */}
                    <span className="changePw__validation-text">
                        {password.trim() ? t("Confirmed.label") : ""}
                    </span>
                </div>
            </div>
        </>
    );
    // 패스워드 확인 양식 
    const rePasswordForm = (
        <>
            <div className="changePw__line rePw" onClick={onClickRePasswordForm}>
                {/* 패스워드 확인 input 을 감싸고있는 div 태그 */}
                <div className="changePw__input">
                    {/* 상태값을 통한 placeholder 표시 */}
                    {!rePassword ? (<div className="placeholder">{t("ConfirmPassword.label")}</div>) : <></>}

                    {/* 패스워드 확인 input */}
                    <input type="password"
                        name="rePassword"
                        // onBlur={onBlurRePassword}
                        onChange={handleChange}
                        ref={rePasswordInput}
                    />

                    {/* 패스워드 확인 입력에 따른 유효성 메세지 */}
                    <span className="changePw__validation-text">
                        {rePassword.trim() ? t("Confirmed.label") : ""}
                    </span>
                </div>
            </div>
        </>
    );
    return (
        <Fragment>
            {/* 비밀번호 변경 레이아웃 */}
            <div className="changePw block-container">
                <form onSubmit={handleSubmit}>
                    <div className="changePw__line">
                        <label className="title">{t("ChangePassword.label")}</label>
                    </div>
                    <div className="changePw__line">
                        <label className="description">{t("EnterPw.label")}</label>
                    </div>

                    {/* 마진 */}
                    <div className="margin"></div>

                    {/* 패스워드 양식 영역 */}
                    {passwordForm}

                    {/* 패스워드확인 양식 영역 */}
                    {rePasswordForm}

                    {/* 변경하기 버튼 영역 */}
                    <div className="changePw__line btn-line">
                        <button
                            type="submit"
                            className="vivid-button"
                            disabled={!isEnabled}>
                            {requestBtnVal}
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}