import React, { useEffect, useMemo, useState } from 'react';
import { TwinkleSaleWriteContainer, TwinkleSaleWriteFooter } from './index.styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Progress } from 'carpenstreet-designsystem';

import palette from 'theme/palette';
import Calendar from 'acon-mui/components/Calendar/Calendar';
import TwinkleDiscountList from '../components/TwinkleDiscountList/TwinkleDiscountList';
import { Whisper } from 'rsuite';
import { ListTooltip } from '../components/TwinkleDiscountList/TwinkleDiscountList.styles';
import TwinkleProductModal from '../components/TwinkleProductModal/TwinkleProductModal';
import { useTranslation } from 'react-i18next';
import TwinkleModal from '../components/TwinkleModal/TwinkleModal';
import { MODAL_REFUSE_STATUS } from '../components/TwinkleModal/TwinkleModal.types';
import _ from 'lodash';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';
import { useHistory } from 'react-router-dom';
import { EXCHANGE_RATE, getValidationSchema } from '../components/TwinkleDiscountList/TwinkleDiscountList.constants';
import {
  EPromotionDiscountType,
  EPromotionStatus,
  ESearchPromotionKey,
  LanguageCodeEnum,
  useFetchPromotionsQuery,
  useHaveRegisteredPromotionQuery,
  useRegisterBrandPromotionMutation,
} from 'generated/graphql';
import { IProduct } from '../components/TwinkleDiscountList/TwinkleDiscountList.types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import { Nullable } from 'types/common';
import Spinner from 'spinner/Spinner';
import { TwinklePromotionErrors, handleTwinklePromotionError } from './index.constans';

const getTwinkleSaleState = (state) => ({ id: state.id, since: state.since, until: state.until, availability: state.availability });
export const getTwinkleSaleSetState = (state) => ({
  setId: state.setId,
  setStatus: state.setStatus,
  setSince: state.setSince,
  setUntil: state.setUntil,
  setAvailability: state.setAvailability,
  setToastStatus: state.setToastStatus,
});

export default function (props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { id, since, until, availability } = useTwinkleSaleStore(getTwinkleSaleState);
  const { setSince, setUntil, setAvailability, setToastStatus } = useTwinkleSaleStore(getTwinkleSaleSetState);

  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);

  const [statusTwinkleModal, setStatusTwinkleModal] = useState<MODAL_REFUSE_STATUS | null>(null);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [isShowProductListModal, setIsShowProductListModal] = useState(false);
  const [isShowTwinkleModal, setIsShowTwinkleModal] = useState(false);
  const [isShowChangeQuantityTooltip, setIsShowChangeQuantityTooltip] = useState(false);
  const [isShowApplicationTimeTooltip, setIsShowApplicationTimeTooltip] = useState(false);
  const [discountAssetsList, setDiscountAssetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = useMemo(() => getValidationSchema(t, language), [t, language]);
  const { refetch: fetchPromotionsRefetch } = useFetchPromotionsQuery({
    variables: {
      search: {
        conceptId: 2,
        since: null,
        until: null,
        key: ESearchPromotionKey.PromotionTitle,
        status: null,
      },
      page: 1,
      limit: 100,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: haveRegisteredPromotionData } = useHaveRegisteredPromotionQuery({
    variables: {
      haveRegisteredPromotionId: id,
    },
    fetchPolicy: 'no-cache',
  });

  const isHaveRegistered = haveRegisteredPromotionData?.haveRegisteredPromotion || false;

  const [registerBrandPromotion] = useRegisterBrandPromotionMutation({
    onCompleted: (data) => {
      if (data?.registerBrandPromotion?.createdAt) setToastStatus('success');
      setIsLoading(false);
      history.push('/manager/promotion');
    },
    onError: async (error) => {
      setIsLoading(false);
      const {
        data: {
          fetchPromotions: { data: newFetchPromotionsData },
        },
      } = await fetchPromotionsRefetch();

      // 1. 업데이트된 프로모션들 중에서 (newFetchPromotion)
      // newFetchPromotionsData 배열을 since 필드를 기준으로 정렬.
      // 정렬된 배열에서 promotion.status === 'DRAFT'이면서 현재 시간(dayjs())이 promotion.since보다 이전인 항목들을 필터링.
      // 즉, 아직 시작 전인 프로모션 중에서 가장 최신의 프로모션 정보를 가져온다.

      // 왜 id로 비교하는 대신 최신 프로모션을 가져오는 필터링을 하는가?
      // 예를 들어, 전날 11시 55분에 작성 페이지에 진입한 후 12시 05분에 할인 신청을 한다면
      // 작성 페이지 진입 시점의 프로모션은 종료되었을 수 있다.
      // 기획 요구사항에 따라 페이지 리로드 대신 최신 프로모션을 가져와서 매칭시켜 준다.
      // (유저가 작성 페이지에서 반짝할인 상품 세팅을 다 해놨는데, 페이지를 리로드하면 세팅한 값이 사라져 버릴 수 있기 때문에 유저 편의성을 위해)
      const newFetchPromotion =
        filter(sortBy(newFetchPromotionsData, ['since']), (promotion) => {
          return promotion.status === 'DRAFT' && dayjs().isBefore(promotion.since);
        })[0] || null;

      // 2. 페이지 진입 시점의 id 값으로 조회된 프로모션 정보 (prevFetchPromotion)
      const prevFetchPromotion = newFetchPromotionsData.find((promotion) => promotion.id === id);

      // 3. 반짝할인 생성 페이지에 들어온 시점의 since, until과 할인 신청 시점의 since, until이 다를 경우
      // 즉, 프로모션 자체가 변경된 경우 (예: 전날 11시 55분에 작성 페이지에 진입 후 12시 05분에 할인 신청)
      // '신청 가능 시간이 지났습니다'라는 모달을 띄운다.
      if (newFetchPromotion.since !== since || newFetchPromotion.until !== until) {
        setStatusTwinkleModal(MODAL_REFUSE_STATUS.APPLICATION_TIME);
        setIsShowTwinkleModal(true);
        setSince(newFetchPromotion.since);
        setUntil(newFetchPromotion.until);
        return;
      }

      // 4. 이전 프로모션 정보와 현재 프로모션 정보의 재고가 다를 경우
      if (prevFetchPromotion.availability.available !== availability.available) {
        // 이전 프로모션 정보의 재고가 0이라면
        // '신청 가능 수량이 모두 마감되었습니다'라는 모달을 띄운다.
        if (prevFetchPromotion.availability.available === 0) {
          setStatusTwinkleModal(MODAL_REFUSE_STATUS.RUNNING_OUT_QUANTITY);
          setIsShowTwinkleModal(true);
        } else if (prevFetchPromotion.availability.available < availability.available) {
          // 이전 프로모션 정보의 재고가 신청한 수량보다 적을 경우
          // '잔여 수량에 맞춰 신청 수량을 변경한 후 다시 신청해 주세요'라는 모달을 띄운다.
          setStatusTwinkleModal(MODAL_REFUSE_STATUS.CHANGE_QUANTITY);
          setIsShowTwinkleModal(true);
          setAvailability({ ...availability, available: prevFetchPromotion.availability.available });
          setValue('quantity', prevFetchPromotion.availability.available);
          trigger();
        }
        return;
      }
      // 5. 그 외의 경우 서버에서 주는 애러 message를 파싱하여 모달을 띄운다.
      const errorMessage = handleTwinklePromotionError(error);
      if (errorMessage) {
        // 파싱된 에러메시지가 타 프로모션때문에 등록이 불가하다는 내용이라면 PROMOTION_CONFLICT 모달을 띄운다.
        const isConfilct = errorMessage === TwinklePromotionErrors.CANNOT_REGISTER_PROMOTED_ASSET || TwinklePromotionErrors.DUPLICATED_PROMOTION_FOR;
        setStatusTwinkleModal(isConfilct ? MODAL_REFUSE_STATUS.PROMOTION_CONFLICT : MODAL_REFUSE_STATUS.ETC);
        setModalMessage(errorMessage);
        setIsShowTwinkleModal(true);
      }
    },
  });

  const methods = useForm<{ products: IProduct[]; quantity: Nullable<number> }>({
    defaultValues: { products: [], quantity: null },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid },
  } = methods;

  const products = watch('products');

  const handleOnClickSuccessModal = () => {
    if (statusTwinkleModal === MODAL_REFUSE_STATUS.APPLICATION_TIME) setIsShowApplicationTimeTooltip(true);
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.CHANGE_QUANTITY) setIsShowChangeQuantityTooltip(true);
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.ALREADY_APPLIED) history.push('/manager/promotion');
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.RUNNING_OUT_QUANTITY) history.push('/manager/promotion');
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.ETC) history.push('/manager/promotion');
  };

  const handleOnSuccess = async (data: any) => {
    if (isLoading) return;
    setIsLoading(true);
    setDiscountAssetsList(data.products);

    await registerBrandPromotion({
      variables: {
        input: {
          promotionId: id,
          items: data.products.map((product) => ({
            assetId: product.id,
            discountType: product.discountType,
            discountValue:
              language === LanguageCodeEnum.Ko
                ? product.discountValue
                : product.discountType === EPromotionDiscountType.Fixed
                ? product.discountValue * EXCHANGE_RATE
                : product.discountValue,
          })),
        },
      },
    });
  };

  useEffect(() => {
    if (discountAssetsList && availability?.available) {
      const result = discountAssetsList.map((product) => ({
        ...product,
        discountValue: product.discountValue ? product.discountValue : 0,
        discountType: product.discountType ? product.discountType : EPromotionDiscountType.Percent,
      }));
      reset({ products: result, quantity: availability.available });
    }
  }, [availability, discountAssetsList, reset]);

  useEffect(() => {
    const TOOLTIP_TIME_OUT = 5000;
    if (isShowChangeQuantityTooltip) setTimeout(() => setIsShowChangeQuantityTooltip(false), TOOLTIP_TIME_OUT);
    if (isShowApplicationTimeTooltip) setTimeout(() => setIsShowApplicationTimeTooltip(false), TOOLTIP_TIME_OUT);
  }, [isShowChangeQuantityTooltip, isShowApplicationTimeTooltip]);

  // 접근 방어 코드 start ------------------------------------------------
  // 이미 등록된 프로모션인데, 프로모션 등록화면에 접근할 경우
  useEffect(() => {
    if (isHaveRegistered) {
      setStatusTwinkleModal(MODAL_REFUSE_STATUS.ALREADY_APPLIED);
      setIsShowTwinkleModal(true);
    }
  }, [isHaveRegistered]);

  if (!id) {
    alert(t('twinkleSalePage.unusualApproach'));
    history.push('/manager/promotion');
    return;
  }
  // 접근 방어 코드 end ------------------------------------------------

  return (
    <FormProvider {...methods}>
      <TwinkleModal
        status={statusTwinkleModal}
        isShow={isShowTwinkleModal}
        message={modalMessage}
        onClose={() => setIsShowTwinkleModal(false)}
        onSuccess={handleOnClickSuccessModal}
      />
      <TwinkleProductModal
        isShow={isShowProductListModal}
        discountAssetsList={products}
        onClose={() => setIsShowProductListModal(false)}
        setDiscountAssetsList={setDiscountAssetsList}
        remainingQuantity={availability.available}
      />
      <TwinkleSaleWriteContainer fullWidth>
        <Typography variant="h4" color={palette.light.text.primary} pt={1} pb={2}>
          {t('twinkleSalePage.titleApply')}
        </Typography>
        <Calendar since={since} until={until} status="ready" isShowTooltip={isShowApplicationTimeTooltip} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={8} pb={2} gap={2}>
          <Typography variant="h5" color={palette.light.text.primary}>
            {t('twinkleSalePage.discountedGoods')}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color={palette.light.text.primary} fontWeight="400" mr={1}>
              <b>
                {t('twinkleSalePage.selectedQuantity')} {products.length} {t('twinkleSalePage.quantity')}
              </b>
            </Typography>
            <Whisper placement="bottom" open={isShowChangeQuantityTooltip} speaker={<ListTooltip>{t('twinkleSalePage.remainingQuantityDescription')}</ListTooltip>}>
              <Typography variant="subtitle2" color={palette.light.text.primary} fontWeight="400">
                ({t('twinkleSalePage.remainingQuantity')} : {availability?.available})
              </Typography>
            </Whisper>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              sx={{ marginLeft: 2, padding: '6px 16px' }}
              onClick={() => setIsShowProductListModal(!isShowProductListModal)}
            >
              {t('twinkleSalePage.productSelection')}
            </Button>
          </Stack>
        </Stack>
        <TwinkleDiscountList type="edit" />
        <TwinkleSaleWriteFooter>
          <Box display="flex" justifyContent="flex-end" alignItems="center" p="12px" maxWidth="1060px" m="0 auto" pr="0">
            {isLoading ? (
              <Button color="primary" disabled size="medium" variant="contained" sx={{ minWidth: '129px', whiteSpace: 'pre' }}>
                <Spinner color="#3366FF" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
              </Button>
            ) : (
              <Button
                color="primary"
                disabled={!isValid || !products.length}
                size="medium"
                variant="contained"
                sx={{ minWidth: '129px', whiteSpace: 'pre' }}
                onClick={handleSubmit(handleOnSuccess)}
              >
                {t('twinkleSalePage.applyFlashSale')}
              </Button>
            )}
          </Box>
        </TwinkleSaleWriteFooter>
      </TwinkleSaleWriteContainer>
    </FormProvider>
  );
}
