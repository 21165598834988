import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Field, InformationCard, Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { Industries_Input_Sort_Criterion, Industries_Input_Sort_Key, LanguageCodeEnum, Language_Code, useDocumentIndustriesQuery } from 'generated/graphql';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function Industries() {
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum }>();
  const { isDisp, status, industries, setIndustries } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    industries: state.industries,
    setIndustries: state.setIndustries,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    industries: state.industries,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const { data, loading, error } = useDocumentIndustriesQuery({
    variables: {
      language: lang.toLocaleUpperCase() as Language_Code,
      pagination: { limit: 100, page: 1 },
      condition: {},
      sort: {
        criterion: Industries_Input_Sort_Criterion.Asc,
        key: Industries_Input_Sort_Key.Id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setIndustries(originalData?.industries);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  return (
    <Field className="hubWrite_field_industries" mt={6} informationCards={<InformationCard>{t('document.industries.information')}</InformationCard>}>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.industries.title'))}>
        {t('document.industries.setIndustries')}
      </Title>
      <Box display="flex" alignItems="center" color="#000000DE" letterSpacing="0.15px" gap={3}>
        {data && data.industries.data && !loading && !error
          ? data.industries.data.map((item) => (
              <FormGroup key={`industry_${item.name}`} sx={{ ml: -1, '& label': { margin: '0 !important' } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean((industries || [])?.find((x) => x.id === item.id))}
                      disabled={disabled}
                      onChange={() => {
                        if ((industries || [])?.find((x) => x.id === item.id)) {
                          setIndustries(industries?.filter((x) => x.id !== item.id) || []);
                        } else {
                          setIndustries([...(industries || []), { id: item.id, name: item.name }]);
                        }
                      }}
                    />
                  }
                  label={item.name}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '16px',
                      lineHeight: '24px',
                    },
                  }}
                />
              </FormGroup>
            ))
          : null}
      </Box>
    </Field>
  );
}
