import styled from "styled-components";

export const SmallButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;

  font-size: 24px;
`;
