import React, { useEffect, useRef, useState } from "react";
import { Icon } from "rsuite";
import { ListItem, NameField, Button } from "../style";
import { NameInputField, ButtonWrapper } from "./style";

export default ({ item, onDelete, onUpdate }) => {
  const nameTag = useRef(null);

  // 업데이트 내역
  const [name, setName] = useState({
    ko: "",
    en: "",
    zh: "",
    ja: "",
  });

  // 편집모드 여부
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setName({
        ko: koItem?.name,
        en: enItem?.name,
        zh: cnItem?.name,
        ja: jpItem?.name,
      });

      // 이름 태그 포커싱
      nameTag && nameTag.current && nameTag.current.focus();
    }
  }, [isEdit]);

  // 수정 버튼 클릭 이벤트 처리기 메소드
  const onClickEdit = () => {
    // 편집모드 on
    setIsEdit(true);
  };
  // 저장 버튼 클릭 이벤트 처리기 메소드
  const onClickSave = async () => {
    await onUpdate(item.id, name.ko, name.en, name.zh, name.ja);
    // 편집모드 on
    setIsEdit(false);
  };

  const koItem = item.i18n.find((x) => x.language.code === "ko");
  const enItem = item.i18n.find((x) => x.language.code === "en");
  const cnItem = item.i18n.find((x) => x.language.code === "cn");
  const jpItem = item.i18n.find((x) => x.language.code === "jp");

  return (
    <ListItem key={item.id}>
      {!isEdit && (
        <>
          <NameField>{koItem?.name}</NameField>
          <NameField>{enItem?.name}</NameField>
          <NameField>{cnItem?.name}</NameField>
          <NameField>{jpItem?.name}</NameField>
        </>
      )}

      {isEdit && (
        <>
          <NameInputField
            ref={nameTag}
            value={name.ko}
            onChange={(e) => setName({ ...name, ko: e.target.value })}
          />
          <NameInputField
            value={name.en}
            onChange={(e) => setName({ ...name, en: e.target.value })}
          />
          <NameInputField
            value={name.zh}
            onChange={(e) => setName({ ...name, zh: e.target.value })}
          />
          <NameInputField
            value={name.ja}
            onChange={(e) => setName({ ...name, ja: e.target.value })}
          />
        </>
      )}

      <ButtonWrapper>
        {!isEdit && (
          <Button onClick={onClickEdit}>
            <Icon icon="edit" />
          </Button>
        )}

        {isEdit && (
          <Button disabled={!name} onClick={onClickSave}>
            <Icon icon="save" />
          </Button>
        )}

      </ButtonWrapper>
    </ListItem>
  );
};
