import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwinkleModalBody, TwinkleModalContainer } from './TwinkleModal.styles';
import { ITwinkleModalProps, MODAL_REFUSE_STATUS } from './TwinkleModal.types';

const TwinkleModal = ({ status, isShow, message, onClose, onSuccess }: ITwinkleModalProps): React.ReactElement => {
  const { t } = useTranslation();

  const handleOnClickOk = async () => {
    try {
      if (status === MODAL_REFUSE_STATUS.APPLICATION_TIME) {
        // 변경 날짜 체크 (신청 가능 시간이 지났을 때)
        onSuccess();
        onClose();
        return;
      } else if (status === MODAL_REFUSE_STATUS.CHANGE_QUANTITY) {
        // 변경 수량 체크 (남은 수량이 변경되었을 때)
        onSuccess();
        onClose();
        return;
      } else if (status === MODAL_REFUSE_STATUS.ALREADY_APPLIED) {
        onSuccess();
        return;
      } else if (status === MODAL_REFUSE_STATUS.RUNNING_OUT_QUANTITY) {
        onSuccess();
        return;
      } else if (status === MODAL_REFUSE_STATUS.PROMOTION_CONFLICT) {
        onSuccess();
        onClose();
        return;
      } else if (status === MODAL_REFUSE_STATUS.ETC) {
        onSuccess();
        onClose();
        return;
      } else if (status === 'cancel') {
        onSuccess();
        onClose();
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TwinkleModalContainer centered show={isShow}>
      <TwinkleModalBody>
        <Typography variant="body2" fontWeight={700} textAlign="center">
          {t(`twinkleSalePage.modal.title.${status}`)}
        </Typography>
        <Typography variant="body2" maxWidth="250px" textAlign="center" sx={{ wordBreak: 'keep-all' }}>
          {status === MODAL_REFUSE_STATUS.ETC ? t(`twinkleSalePage.modal.contents.${status}`, { message }) : t(`twinkleSalePage.modal.contents.${status}`)}
        </Typography>
        {status === 'cancel' ? (
          <Stack direction="row" gap="12px" width="100%">
            <Button color="normal" size="large" variant="outlined" fullWidth sx={{ mt: 1 }} onClick={onClose}>
              {t(`twinkleSalePage.modal.button.${status}.left`)}
            </Button>
            <Button color="error" size="large" variant="contained" fullWidth sx={{ mt: 1 }} onClick={handleOnClickOk}>
              {t(`twinkleSalePage.modal.button.${status}.right`)}
            </Button>
          </Stack>
        ) : (
          <Button color="primary" size="large" variant="contained" fullWidth sx={{ mt: 1 }} onClick={handleOnClickOk}>
            {t(`twinkleSalePage.modal.button.${status}`)}
          </Button>
        )}
      </TwinkleModalBody>
    </TwinkleModalContainer>
  );
};

export default TwinkleModal;
