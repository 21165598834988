import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { AppContext } from '../../../../app';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { GET_VALIDATION_GOODS_NO } from '../../../../api/quries';
import { 
    InputContainer
} from '../../style';

export default (({ onChangeApplyValue, applySelectedValue, lang, disabled = false }) => {
    // 번역도구
    const { t } = useTranslation();
    const [productsValueInput, setProductsValueInput] = useState('');
    // 고도몰 상품 번호 체크 진행 중 여부
    const [isSkipQuery, setIsSkipQuery] = useState(true);
    const { showAlertMessage } = useContext(AppContext);

    // 고도몰 상품 번호 유효성 체크
    const { data, loading, error } = useQuery(GET_VALIDATION_GOODS_NO, {
        variables: { godoGoodsNo: Number(productsValueInput), lang: lang },
        skip: isSkipQuery
    });

    // 표시위치 입력시 리턴되는 array 값을 productsValue 변수에 지정해줍니다.
    // products 변수는 <CreatableSelect />의 prop으로 사용됩니다.
    const onChangeProducts = (value) => {
        onChangeApplyValue(value ? value.map(x => x.value) : []);
    };

    // 표시위치 입력시 리턴되는 string 값을 productsValueInput 변수에 지정해줍니다.
    // productsValueInput 변수는 <CreatableSelect />의 prop으로 사용됩니다.
    const onChangeProductsInput = (inputValue) => {
        setProductsValueInput(inputValue ? inputValue.trim() : '');
    };

    // 표시위치 입력 중 Enter, Tab 키를 누를 때 작동하는 함수입니다.
    // productsValueInput(string)으로부터 products(array)에 들어갈 객체를 오브젝트 포맷으로 만들어 입력합니다.
    // products 값 입력 후에는 productsValueInput 값을 빈 string으로 바꿔줍니다.
    // input DOM 객체의 값이 사라지고 기존에 입력했던 값이 태그 모양으로 바뀌어 추가되는 로직에 해당합니다.
    const onKeyDownProducts = (event) => {
        if (!productsValueInput) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            setIsSkipQuery(false);
            event.preventDefault();
        }
    };

    useEffect(() => {
        if(! loading && ! error && data) {
            // 상품 번호 유효 여부
            const checkGoodsNo = data?.getValidationGoodsNo;

            if(checkGoodsNo) {
                if (applySelectedValue) {
                    onChangeApplyValue([...applySelectedValue, productsValueInput]);
                } else {
                    onChangeApplyValue([productsValueInput]);
                }
            } else {
                // 상품 번호가 존재 하지 않을 경우 알림창 표시
                showAlertMessage(t('burden.point.goodsNo.validation'), t('burden.point.goodsNo.validation'));
            }

            // 상품 번호 값 초기화
            setProductsValueInput('');
            setIsSkipQuery(true);
        } else if(! loading && error) {
            // 상품 번호가 존재 하지 않을 경우 알림창 표시
            showAlertMessage(t('burden.point.goodsNo.validation'), t('burden.point.goodsNo.validation'));

            // 상품 번호 값 초기화
            setProductsValueInput('');
            setIsSkipQuery(true);
        } else if(! loading) {
            setProductsValueInput('');
            setIsSkipQuery(true);
        }
    }, [data, loading, error]);

    return (
        <InputContainer>
            <CreatableSelect
                components={{ DropdownIndicator: null }}
                inputValue={productsValueInput}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={onChangeProducts}
                onInputChange={onChangeProductsInput}
                onKeyDown={onKeyDownProducts}
                placeholder={t('Banner.writer.productsPlaceholder')}
                value={applySelectedValue.map(label => { return { label, value: label } })}
                isDisabled={disabled}
            />
        </InputContainer>
    );
});