import styled from 'styled-components';

export const Message = styled.div`
  position: absolute;
  ${(props: { type?: boolean }) => (props.type ? `background: rgba(25, 180, 0, 0.7);` : `background-color: rgba(255, 190, 190, 0.3);`)}
  top: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  padding: 10px;
  width: calc(100% - 5px);
  margin: 0 auto;
  border-radius: 5px;
  z-index: 1;
  color: #333333;
`;
