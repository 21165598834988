import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../../provider/product';
import {Item, Title, DivInput, Description, Value, InputLimitText} from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChangeInput = e => {
        if (e.target.value.indexOf('\n') > -1)
            return false;

        dispatch({
            key: 'keywords',
            type: 'input',
            value: e.target.value
        });
    }
    const onKeyDown = e => {
        if (e.key === 'Enter') {
            return false;
        }
    };

    return useMemo(() => {
        return (
            <Item>
                <Title>{t("preferredSearchKeyword")}</Title>
                <Value>
                    <DivInput
                        maxLength={255}
                        onKeyDown={onKeyDown}
                        disabled={state.isDisp}
                        placeholder={t('PleaseEnterSearchKeyword.label')}
                        onChange={onChangeInput}
                        value={state.keywords}
                    />
                </Value>
                <Description>
                    {t("preferredSearchKeywordDescription")}< br/>{t("preferredSearchKeywordDescriptionExample")}
                    <InputLimitText>{state.keywords?.length} / 255</InputLimitText>
                </Description>
                
            </Item>
        );
    }, [state.keywords, state.isDisp]);
});