import React, {
    // useContext, useState 
} from 'react';
// import { TemplateDataContext } from '../../templateData'
const enumAuth = ['write', 'writeAll', 'read', 'readAll', 'delete', 'deleteAll']
function event(loading, name, setData) {
    var result = {}
    result.onClick = (e) => {
        if (loading) {
            return
        } else {
            setData(prev => {
                console.log(prev)
                delete prev._auth[name]
                return { ...prev }
            })
        }
    }
    result.onChange = (auth) => {
        return (e) => {
            if (loading) {
                return
            } else {
                var el = e.currentTarget
                var checked = el.checked
                setData(prev => {
                    if (checked) {
                        if (!prev._auth[name].includes(auth)) {
                            prev._auth[name].push(auth)
                        }
                    } else {
                        if (prev._auth[name].includes(auth)) {
                            prev._auth[name] = prev._auth[name].filter(d => d !== auth)
                        }
                    }
                    return { ...prev }
                })
            }
        }
    }
    return result
}
export default (function (props) {
    let render = []
    const { name, defaultAuth, auth, setData, loading } = props
    const tempEvent = event(loading, name, setData)
    const onClick = tempEvent.onClick
    // console.log(auth)
    enumAuth.forEach(d => {
        if (defaultAuth.includes(d)) {
            const onChange = tempEvent.onChange(d)
            var opt = {
                onChange,
                checked: false
            }
            if (auth.includes(d)) {
                opt.checked = true
            }

            render.push(
                <div key={name + '_' + d} className="auth">
                    <label>{d}
                        <input type="checkbox" {...opt}></input>
                    </label>
                </div>
            )
        }
    })

    // key: d, defaultAuth: authList[d], auth: auth[d]
    //     const { authList, loading } = useContext(TemplateDataContext)
    //     const [data, setData] = useState()
    //     const {auth, setD}
    return (
        <div className="authList">
            <button onClick={onClick}>delete</button>
            <label>{name} : </label>
            <div>{render}</div>
        </div>
    )
    //     return (
    //         { render }
    //     )
});

