import React from 'react';
import { Box, Collapse } from '@mui/material';
import { ItemImage } from './SalesRecordsItem.styles';
import { ChevronDownIcon, ChevronUpIcon, IconButton, InformationOutlineIcon, Label, Tooltip, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { OrderLicenseType } from '../../../generated/graphql';
import { SalesRecordsItemProps } from './SalesRecordsItem.types';
import { useTranslation } from 'react-i18next';
import { asiaSeoulDayjs, exchangeCurrency } from 'utils/timezone';

export default function DesktopSalesRecordsItem({ item, onItemExpand, calcSalePrice, calcBurdenPrice, renderSubtext, isExpanded, renderLicenseType }: SalesRecordsItemProps) {
  const theme = useDesignSystemTheme();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      sx={{
        [theme.breakpoints.down('largeTablet')]: {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '4px 0',
          width: '100%',
          height: '68px',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden' }}>
          <Box
            sx={{
              borderRadius: '6px',
              overflow: 'hidden',
              flexShrink: 0,
              position: 'relative',
            }}
          >
            <ItemImage src={item.product.imageInfo.main.url} alt={item.product.title} />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                boxShadow: `0 0 0 1px ${theme.palette['color/gray/dim/200']} inset`,
                borderRadius: '6px',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: '2px 4px',
              marginLeft: '12px',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant={'typography/title/small/bold'}
              color={item.isRefunded ? 'color/gray/400' : 'color/gray/800'}
              sx={{
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.product.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography variant={'typography/label/large/regular'} color={item.isRefunded ? 'color/gray/400' : 'color/gray/800'}>
                {renderSubtext(item.order.license)}
              </Typography>
              <Label variant={'soft'} color={item.isRefunded ? 'error' : 'success'}>
                {t(`salesRecordsPage.item.label.${item.isRefunded ? 'cancelled' : 'sold'}`)}
              </Label>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            flexShrink: 0,
            marginLeft: '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              paddingTop: '7px',
              gap: '2px',
            }}
          >
            <Typography
              variant={'typography/title/small/bold'}
              color={item.isRefunded ? 'color/gray/400' : 'color/gray/800'}
              sx={{ alignSelf: 'end', ...(item.isRefunded && { textDecoration: 'line-through' }) }}
            >
              {t('salesRecordsPage.item.amount', { amount: exchangeCurrency(calcSalePrice(item), language).toLocaleString() })}
            </Typography>
            <Typography variant={'typography/label/large/regular'} color={item.isRefunded ? 'color/gray/400' : 'color/gray/800'} sx={{ alignSelf: 'end' }}>
              {asiaSeoulDayjs(item.order.histories[0].createdAt).format('YYYY-MM-DD HH:mm')}
            </Typography>
          </Box>
          <IconButton size={'M'} onClick={onItemExpand}>
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isExpanded}>
        <Box
          sx={{
            borderRadius: '6px',
            padding: '24px',
            backgroundColor: theme.palette['color/gray/50'],
            display: 'flex',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              flexGrow: 1,
            }}
          >
            <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.item.detail.orderInformation.title')}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.orderInformation.license')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{renderLicenseType(item.order.license.type)}</Typography>
              </Box>
              {item.order.country && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.orderInformation.country')}</Typography>
                  <Typography variant={'typography/label/large/bold'}>{item.order.country}</Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.orderInformation.creator')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{item.order.name[0] + '*'.repeat(item.order.name.length - 1)}</Typography>
              </Box>
              {item.order.license.type === OrderLicenseType.Personal ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography
                    variant={'typography/label/large/regular'}
                    sx={{
                      alignSelf: 'start',
                    }}
                  >
                    {t('salesRecordsPage.item.detail.orderInformation.creatorName')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      gap: '4px',
                    }}
                  >
                    {item.order.license.authors.map((author) => (
                      <Typography key={author.id} variant={'typography/label/large/bold'}>
                        {author.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.orderInformation.team')}</Typography>
                    <Typography variant={'typography/label/large/bold'}>{item.order.license.authors[0].name}</Typography>
                  </Box>
                  {(item.order.license.type === OrderLicenseType.Company || item.order.license.type === OrderLicenseType.Enterprise_5) &&
                    (item.order.license.project.length === 0 ? (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.orderInformation.creation')}</Typography>
                        <Typography variant={'typography/label/large/bold'}>{t('salesRecordsPage.item.project.untitled')}</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                          variant={'typography/label/large/regular'}
                          sx={{
                            alignSelf: 'start',
                          }}
                        >
                          {t('salesRecordsPage.item.detail.orderInformation.creation')}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end',
                            gap: '4px',
                          }}
                        >
                          {item.order.license.project.map((project) => (
                            <Typography key={project.id} variant={'typography/label/large/bold'}>
                              {project.name}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ alignSelf: 'stretch', width: '1px', backgroundColor: theme.palette['color/gray/dim/200'] }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              flexGrow: 1,
            }}
          >
            <Typography variant={'typography/body/small/bold'}>{t('salesRecordsPage.item.detail.salesInformation.title')}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.status.title')}</Typography>
                <Typography variant={'typography/label/large/bold'}>
                  {t(`salesRecordsPage.item.detail.salesInformation.status.${item.isRefunded ? 'cancelled' : 'sold'}`)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.retailPrice.title')}</Typography>
                <Typography variant={'typography/label/large/bold'}>
                  {t('salesRecordsPage.item.detail.salesInformation.retailPrice.amount', { amount: exchangeCurrency(item.salePrice, language).toLocaleString() })}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.partnerDiscount.title')}</Typography>
                <Typography variant={'typography/label/large/bold'}>
                  {t('salesRecordsPage.item.detail.salesInformation.partnerDiscount.amount', { amount: exchangeCurrency(calcBurdenPrice(item), language).toLocaleString() })}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.sellingPrice.title')}</Typography>
                <Typography variant={'typography/label/large/bold'}>
                  {t('salesRecordsPage.item.detail.salesInformation.sellingPrice.amount', { amount: exchangeCurrency(calcSalePrice(item), language).toLocaleString() })}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.sellingDateAndTime')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{asiaSeoulDayjs(item.order.histories[0].createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
              </Box>
              {item.isRefunded ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.cancellationDate')}</Typography>
                  <Typography variant={'typography/label/large/bold'}>{asiaSeoulDayjs(item.refundedAt).format('YYYY-MM-DD')}</Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.item.detail.salesInformation.settlementDate.title')}</Typography>
                    <Tooltip title={t('salesRecordsPage.item.detail.salesInformation.settlementDate.tooltip')} direction={'bottom'}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InformationOutlineIcon width={12} height={12} />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Typography variant={'typography/label/large/bold'}>{asiaSeoulDayjs(item.order.histories[0].createdAt).add(7, 'day').format('YYYY-MM-DD')}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
