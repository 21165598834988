import React from 'react';
import { Stack, Typography } from '@mui/material';
import palette from 'theme/palette';
import { useTranslation } from 'react-i18next';
import { OpenRunContainer, OpenRunStatusCard } from './index.styles';
import OpenrunList from './components/OpenrunList/OpenrunList';
import { LanguageCodeEnum, OpenrunStatusEnum, useOpenrunPromotionsQuery } from 'generated/graphql';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

const order = {
  IN_REVIEW: 1,
  COMPLETED: 2,
  OPENED: 3,
  REJECTED: 4,
};
const OpenRun = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const urlParams = QueryString.parse(history?.location?.search);
  const { page = 1, limit = 10 } = urlParams;

  const { data: openrunPromotionsData, loading: openrunPromotionsLoading } = useOpenrunPromotionsQuery({
    variables: { page: Number(page), limit: Number(limit), languageCode: i18n.language as LanguageCodeEnum },
    fetchPolicy: 'no-cache',
  });

  const openrunPromotions = openrunPromotionsData?.openrunPromotions?.data || [];
  const openrunPromotionsStateSpecificTotal = orderBy(openrunPromotionsData?.openrunPromotions?.stateSpecificTotal || [], [(item) => order[item.status]], ['asc']);
  const openrunPromotionsTotalCount = openrunPromotionsData?.openrunPromotions?.meta?.totalCount || 0;

  if (openrunPromotionsLoading) return <></>;
  return (
    <OpenRunContainer fullWidth>
      <Stack direction="row" gap={3}>
        {openrunPromotionsStateSpecificTotal.map((status) => {
          return (
            <OpenRunStatusCard key={`operun-status-${status.status}`}>
              <Typography variant="body1" color="#637381" textAlign="center">
                {t(`openrunPage.statusCard.${status.status as OpenrunStatusEnum}`)}
              </Typography>
              <Typography variant="h3" color="#4E4EFF">
                {status.total}
                {t('openrunPage.unit')}
              </Typography>
            </OpenRunStatusCard>
          );
        })}
      </Stack>
      <Typography variant="h5" color={palette.light.text.primary} pt={8} pb={2}>
        {t('twinkleSalePage.applicationDetails')}
      </Typography>
      <OpenrunList promotions={openrunPromotions} total={openrunPromotionsTotalCount} />
    </OpenRunContainer>
  );
};
export default OpenRun;
