import { useQuery as useApolloQuery } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Button, Container, DateRangePicker, PageNavigation, SelectBox } from 'acon-mui/components';
import { ReactComponent as ArrowIcon } from 'acon-mui/icons/icon-arrow-down.svg';
import { ReactComponent as ChevronIcon } from 'acon-mui/icons/icon-chevron.svg';
import { ReactComponent as DownloadIcon } from 'acon-mui/icons/icon-download.svg';
import { color } from 'acon-mui/style';
import { GET_BRANDS } from 'api/quries';
import Loading from 'common/editor/Loading';
import dayjs from 'dayjs';
import { LanguageCodeEnum } from 'generated/graphql';
import QueryString from 'query-string';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import palette from 'theme/palette';
import { Field } from '../../../components/table';
import { displayMoneyByLang, isKorean } from '../../../lib/multiLocale';
import Filter from '../components/filter';
import { NoticePopup, ProductPopup, SettlePopup } from '../components/popup';
import Search from '../components/search';
import { useSearch } from '../hook';
import { endDateObj, ENTERPRISE_QUERY_KEYS, getSettleEnterPriseExcelDownload, getSettleEnterPriseList, INITIAL_SEARCH_PERIOD_LIST, startDateObj, viewCountList } from './constans';
import { FlexColWrap, Title, Wrap } from '../style';
import { IEnterpriseListInput, IEnterpriseListOutput, ISortingOrder } from './types';
import { AppContext } from 'app';
import { asiaSeoulDayjs } from 'utils/timezone';

const Enterprise = (): React.ReactElement => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { userInfo } = useContext(AppContext);
  // 시작일자
  const [startDate, setStartDate] = useState(sessionStorage.getItem('startdate') || `${dayjs(startDateObj).format('YYYY-MM-DD')} 00:00:00`);
  // 종료일자
  const [endDate, setEndDate] = useState(sessionStorage.getItem('enddate') || `${dayjs(endDateObj).format('YYYY-MM-DD')} 23:59:59`);
  // 일자 검색 유형
  const [dateSearchType, setDateSearchType] = useState('payment');

  const { urlParamObj, isReadAll } = useSearch(startDate, endDate, dateSearchType);

  // 정산 모달 표시여부
  const [isShowSettleModal, setIsShowSettleModal] = useState(false);
  // 상품 모달 표시여부
  const [isShowProductModal, setIsShowProductModal] = useState(false);
  // 공지 모달 표시여부
  const [isShowNoticeModal, setIsShowNoticeModal] = useState(false);

  // 결제일지 / 주문일시 필터 표시 여부
  const [isShowFilter, setIsShowFilter] = useState(false);
  // 선택된 필터 값
  const [selectedFilterValue, setSelectedFilterValue] = useState(localStorage.getItem('settle_filter_payment') || 'payment');
  // 테이블 소팅 메소드
  const [sortingOrder, setSortingOrder] = useState<ISortingOrder>({ normalPrice: null, discount: null, settlePrice: null });

  const searchPeriodList = useMemo(() => INITIAL_SEARCH_PERIOD_LIST(t), [t]);

  const listPayload: IEnterpriseListInput = useMemo(
    () => ({
      langCode: i18n.language as LanguageCodeEnum,
      ...(isReadAll && { brandId: Number(urlParamObj.brandId) }),
      page: urlParamObj.page,
      limit: urlParamObj.limit,
      goodsNm: decodeURI(urlParamObj.goodsNm as string),
      penNm: decodeURI(urlParamObj.penNm as string),
      paymentSinceDate: urlParamObj?.paymentStartDate ? dayjs(urlParamObj.paymentStartDate as string).format('YYYY-MM-DD') : '',
      paymentUntilDate: urlParamObj?.paymentEndDate ? dayjs(urlParamObj.paymentEndDate as string).format('YYYY-MM-DD') : '',
      settleSinceDate: urlParamObj?.settleStartDate ? dayjs(urlParamObj.settleStartDate as string).format('YYYY-MM-DD') : '',
      settleUntilDate: urlParamObj?.settleEndDate ? dayjs(urlParamObj.settleEndDate as string).format('YYYY-MM-DD') : '',
      orderSinceDate: urlParamObj?.startDate ? dayjs(urlParamObj.startDate as string).format('YYYY-MM-DD') : '',
      orderUntilDate: urlParamObj?.endDate ? dayjs(urlParamObj.endDate as string).format('YYYY-MM-DD') : '',
    }),
    [i18n.language, isReadAll, urlParamObj],
  );

  const excelDownloadPayload: IEnterpriseListInput = useMemo(
    () => ({
      langCode: i18n.language as LanguageCodeEnum,
      ...(isReadAll && { brandId: Number(urlParamObj.brandId) }),
      goodsNm: decodeURI(urlParamObj.goodsNm as string),
      penNm: decodeURI(urlParamObj.penNm as string),
      paymentSinceDate: urlParamObj?.paymentStartDate ? dayjs(urlParamObj.paymentStartDate as string).format('YYYY-MM-DD') : '',
      paymentUntilDate: urlParamObj?.paymentEndDate ? dayjs(urlParamObj.paymentEndDate as string).format('YYYY-MM-DD') : '',
      settleSinceDate: urlParamObj?.settleStartDate ? dayjs(urlParamObj.settleStartDate as string).format('YYYY-MM-DD') : '',
      settleUtilDate: urlParamObj?.settleEndDate ? dayjs(urlParamObj.settleEndDate as string).format('YYYY-MM-DD') : '',
      orderSinceDate: urlParamObj?.startDate ? dayjs(urlParamObj.startDate as string).format('YYYY-MM-DD') : '',
      orderUntilDate: urlParamObj?.endDate ? dayjs(urlParamObj.endDate as string).format('YYYY-MM-DD') : '',
    }),
    [i18n.language, isReadAll, urlParamObj],
  );

  const listQuery = useQuery<IEnterpriseListOutput>([ENTERPRISE_QUERY_KEYS.ENTERPRISE_LIST, listPayload, userInfo.id], () => getSettleEnterPriseList(listPayload));

  const excelQuery = useQuery<Blob>(
    [ENTERPRISE_QUERY_KEYS.ENTERPRISE_EXCEL_DOWNLOAD, excelDownloadPayload, userInfo.id],
    () => getSettleEnterPriseExcelDownload(excelDownloadPayload),
    {
      onSuccess: (data: Blob) => downloadExcel(null, data),
      enabled: false,
    },
  );

  // 브랜드 가져오기
  const getBrandResultObj = useApolloQuery(GET_BRANDS, {
    variables: {
      lang: 'ko',
    },
  });

  // 엑셀 다운로드하기 메소드
  const downloadExcel = (name, data) => {
    // 브랜드 명
    let fileName = '판매내역페이지.xls';
    // 이름이 존재할 경우, 지정된 이름으로 파일 다운
    fileName = name ? `${name}.xls` : fileName;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onChangeBrandSelectTag = (_, val) => {
    const obj = {
      ...urlParamObj,
      brandId: val.id,
    };
    obj.page = 1;
    obj.limit = 20;
    obj.goodsNm = '';
    obj.penNm = '';
    // 검색 쿼리
    const query = QueryString.stringify(obj);
    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };

  // 일자 검색 유형 select 태그 변경 이벤트 처리기 메소드
  const onChangeDateTypeSelectTag = (e) => {
    if (!searchPeriodList.find((x) => x.id === e.target.value)) return;
    setDateSearchType(searchPeriodList.find((x) => x.id === e.target.value)?.value);
  };

  // 검색 버튼 클릭 이벤트 처리기 메소드
  const onClickSearchButtonTag = () => {
    if (new Date(startDate) < new Date('2021-02-01T00:00:00')) {
      setIsShowNoticeModal(true);
    }
    // url 객체
    let searchObj = { ...urlParamObj };
    delete searchObj.settleStartDate;
    delete searchObj.settleEndDate;
    delete searchObj.paymentStartDate;
    delete searchObj.paymentEndDate;
    delete searchObj.startDate;
    delete searchObj.endDate;
    searchObj.page = 1;

    switch (dateSearchType) {
      case 'settle':
        searchObj.settleStartDate = startDate;
        searchObj.settleEndDate = endDate;
        break;
      case 'payment':
        searchObj.paymentStartDate = startDate;
        searchObj.paymentEndDate = endDate;
        break;
      case 'order':
        searchObj.startDate = startDate;
        searchObj.endDate = endDate;
        break;
    }

    // 검색 쿼리
    const query = QueryString.stringify(searchObj);

    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };

  // 검색조건 초기화 버튼 클릭 이벤트 처리기 메소드
  const onClickResetButtonTag = () => {
    sessionStorage.removeItem('settleview');
    delete urlParamObj.goodsNm;
    delete urlParamObj.penNm;

    const queryStr = QueryString.stringify(urlParamObj);
    // 검색페이지로 이동
    history.push(`?${queryStr}`);
  };

  // 항목 보기 개수 select 태그 변경 이벤트 처리기 메소드
  const onChangeViewCountSelectTag = (e) => {
    const value = viewCountList.find((x) => x.id === e.target.value).label;
    // 검색 쿼리
    const query = QueryString.stringify({
      ...urlParamObj,
      page: 1,
      limit: value,
    });
    sessionStorage.setItem('settleview', value);
    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };

  const clickColumn = (type) => {
    const order = { normalPrice: null, discount: null, settlePrice: null };
    const getVal = () => {
      switch (sortingOrder[type]) {
        case 'asc':
          return 'desc';
        case 'desc':
          return null;
        case null:
          return 'asc';
      }
    };
    setSortingOrder({
      ...order,
      [type]: getVal(),
    });
  };

  const SortArrowIcon = ({ type }: { type: 'normalPrice' | 'discount' | 'settlePrice' }) => {
    return sortingOrder[type] ? (
      <Box
        sx={{
          position: 'absolute',
          right: '4px',
          top: '50%',
          transform: sortingOrder[type] === 'asc' ? 'translateY(-50%)' : 'translateY(-50%) rotate(180deg)',
        }}
      >
        <ArrowIcon />
      </Box>
    ) : (
      <Fragment />
    );
  };

  const sortList = (() => {
    let arr = listQuery?.data && [...listQuery.data.data];
    if (!arr || arr.length === 0) return [];
    const normal = sortingOrder['normalPrice'];
    const discount = sortingOrder['discount'];
    const settle = sortingOrder['settlePrice'];

    let result = arr;
    let sortType = '';

    if (!normal && !discount && !settle) return result;
    if (normal) sortType = 'normalPrice';
    if (discount) sortType = 'discount';
    if (settle) sortType = 'settlePrice';

    arr.sort(function (a, b) {
      let newA = 0;
      let newB = 0;

      if (sortType === 'normalPrice') {
        newA = Number(a.salePrice);
        newB = Number(b.salePrice);
      }
      if (sortType === 'discount') {
        newA = Number(a.userBurdenDcPrice);
        newB = Number(b.userBurdenDcPrice);
      }
      if (sortType === 'settlePrice') {
        newA = Number(a.settlementPrice);
        newB = Number(b.settlementPrice);
      }
      if ((sortingOrder[sortType] === 'asc' && newB > newA) || (sortingOrder[sortType] === 'desc' && newB < newA)) return -1;
      if (newA === newB) return 0;
      return 1;
    });

    return arr;
  })();

  const isLoading = Boolean(listQuery.isFetching || listQuery.isLoading || excelQuery.isLoading);
  const isError = Boolean(listQuery.isError || excelQuery.isError);

  if (isError) return <div>error...</div>;
  return (
    <>
      {isLoading && <Loading />}
      <SettlePopup
        modalId={'settlePopup'}
        isShow={isShowSettleModal}
        onHide={() => {
          setIsShowSettleModal(false);
        }}
        t={t}
      />
      <ProductPopup
        modalId={'prodPopup'}
        isShow={isShowProductModal}
        onHide={() => {
          setIsShowProductModal(false);
        }}
        t={t}
      />
      <NoticePopup
        modalId={'noticePopup'}
        isShow={isShowNoticeModal}
        onHide={() => {
          setIsShowNoticeModal(false);
        }}
        isFooter={true}
      />
      {isReadAll && getBrandResultObj.data && (
        <Box my="40px">
          <Title>{t('brand')}</Title>
          <SelectBox
            defaultValue={parseInt(urlParamObj.brandId, 10)}
            options={getBrandResultObj.data.getBrands.map((x) => ({ id: x.id, label: x.name }))}
            placeholder="선택"
            onSelect={onChangeBrandSelectTag}
            {...(!isMobile && {
              width: '336px',
            })}
            isKeyboardAvailable
            inputLabelVisible={false}
          />
        </Box>
      )}
      <Title>{t('settle.search_period')}</Title>
      <Box mb="40px" {...(!isMobile && { display: 'flex', alignItems: 'center' })}>
        <SelectBox
          defaultValue={Number(searchPeriodList.find((x) => x.value === dateSearchType)?.id || 1)}
          options={searchPeriodList}
          onSelect={onChangeDateTypeSelectTag}
          inputLabelVisible={false}
          {...(isMobile
            ? {
                marginTop: '16px',
                marginBottom: '16px',
              }
            : {
                marginRight: '16px',
                width: '160px',
                '& fieldset': {
                  marginTop: 'auto',
                  height: '56px',
                },
              })}
        />
        <DateRangePicker
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          setStartDate={(val) => {
            const startDateStr = dayjs(val).format('YYYY-MM-DD') + 'T00:00:00';
            setStartDate(startDateStr);
            sessionStorage.setItem('startdate', startDateStr);
          }}
          setEndDate={(val) => {
            const endDateStr = dayjs(val).format('YYYY-MM-DD') + 'T23:59:59';
            setEndDate(endDateStr);
            sessionStorage.setItem('enddate', endDateStr);
          }}
          isMobile={isMobile}
        />
        <Button
          onClick={onClickSearchButtonTag}
          {...(isMobile
            ? {
                marginTop: '16px',
              }
            : {
                marginLeft: '16px',
                width: '120px',
              })}
          padding="15px"
          variant="contained"
          colorTheme="primary"
        >
          {t('search')}
        </Button>
      </Box>

      <Wrap>
        <FlexColWrap className="settle_searchResult">
          <Typography fontSize="14px" lineHeight="22px" color={color.text}>
            {t('settle.search_result')}&nbsp;
            <span style={{ fontWeight: '700' }}>{listQuery.data && listQuery.data.data.length}</span>
          </Typography>
        </FlexColWrap>

        <Container marginTop="12px" fontSize="14px" lineHeight="22px">
          <Typography sx={{ '& span': { fontWeight: '700' } }}>
            {t('settle.search.totalAmount')}&nbsp;
            <span>{listQuery.data && listQuery.data.data.length}</span>
            &nbsp;{t('settle.search.unit')}
          </Typography>
          <Typography mt="4px" sx={{ '& span': { fontWeight: '700' } }}>
            {t('settle.search.totalPrice')}&nbsp;
            {!isKorean(i18n.language) && '$'}
            <span>{listQuery.data && displayMoneyByLang(listQuery.data.totalSettlementPrice, i18n.language).replace('$', '').replace('원', '')}</span>
            {isKorean(i18n.language) && <>&nbsp;원</>}
          </Typography>
        </Container>

        <Container marginTop="12px" wordBreak="keep-all">
          <Typography mb="4px" fontSize="14px" lineHeight="22px" fontWeight="700" color={color.primary}>
            {t('settle.information.title')}
          </Typography>
          <>
            {(dateSearchType !== 'settle'
              ? [t('settle.information.content1'), t('settle.information.content2'), t('settle.information.content3')]
              : [t('settle.information.content2'), t('settle.information.content3')]
            ).map((text) => (
              <Box
                key={text}
                display="flex"
                color={color.text}
                sx={{
                  '& p': {
                    fontSize: '14px',
                    lineHeight: '22px',
                    fontWeight: '400',
                  },
                }}
              >
                <Typography>•&nbsp;</Typography>
                <Typography>{text}</Typography>
              </Box>
            ))}
          </>
        </Container>
      </Wrap>

      <Box mt="40px" px="12px" {...(!isMobile && { display: 'flex', alignItems: 'center' })}>
        <Typography mr="auto" fontSize="14px" lineHeight="22px" fontWeight="400" color={color.text} sx={{ wordBreak: 'keep-all' }}>
          {t('settleExcelWarning')}
        </Typography>
        <Button
          onClick={() => excelQuery.refetch()}
          startIcon={<DownloadIcon style={{ marginRight: '8px' }} />}
          {...(isMobile
            ? {
                marginTop: '8px',
              }
            : {
                width: 'fit-content',
              })}
          minHeight="36px"
          wordBreak="keep-all"
        >
          <Typography fontSize="14px" lineHeight="22px" fontWeight="700">
            {t('excelDownload')}
          </Typography>
        </Button>
      </Box>
      <Container isPadding={false} marginTop="12px">
        <Box py="24px" display="flex" flexDirection="column">
          <Box {...(isMobile ? { px: 3 } : { display: 'flex', alignItems: 'center' })}>
            <Search history={history} />
            <Button
              onClick={onClickResetButtonTag}
              colorTheme="primary"
              fontWeight="700"
              wordBreak="keep-all"
              {...(isMobile
                ? {
                    mt: 2,
                    height: '40px',
                  }
                : {
                    ml: 2,
                    width: '160px',
                    height: '56px',
                    flexShrink: '0',
                  })}
            >
              <>{t('reset_search')}</>
            </Button>
          </Box>
        </Box>
        <Box sx={{ overflowX: 'scroll', '&::-webkit-scrollbar': { display: 'none' } }}>
          <Table sx={{ width: '1060px' }}>
            <TableHead sx={{ '& th': { p: '16px !important', fontSize: '13px !important', lineHeight: '24px', boxShadow: 'none !important', borderRadius: '0 !important' } }}>
              <TableRow>
                <TableCell variant="head" width="222px">
                  {t('settleTable.projectTitle')}
                </TableCell>
                <TableCell variant="head" width="92px">
                  {t('settleTable.penName')}
                </TableCell>
                <TableCell variant="head" width="92px">
                  <span>{t('settleTable.appliedWork')}</span>
                </TableCell>
                <TableCell variant="head" width="142px">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>{selectedFilterValue === 'payment' ? t('settleTable.paymentDate') : t('settleTable.orderDate')}</span>
                    <Button
                      onClick={() => {
                        setIsShowFilter(!isShowFilter);
                      }}
                      variant="text"
                      colorTheme="normal"
                      width="12px"
                      minWidth="12px"
                      boxSizing="content-box"
                      marginLeft="auto"
                    >
                      <ChevronIcon />
                    </Button>
                  </Box>
                  <Filter isShow={isShowFilter} setIsShow={setIsShowFilter} setFilterValue={setSelectedFilterValue} filterValue={selectedFilterValue} />
                </TableCell>
                <TableCell variant="head" width="122px">
                  <span>{t('settleTable.settlementDate')}</span>
                </TableCell>
                <TableCell
                  variant="head"
                  width="130px"
                  sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                  onClick={() => clickColumn('normalPrice')}
                >
                  <span>{t('settleTable.normalPrice')} &#40;A&#41;</span>
                  <SortArrowIcon type="normalPrice" />
                </TableCell>
                <TableCell
                  variant="head"
                  width="130px"
                  sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                  onClick={() => clickColumn('discount')}
                >
                  <span>{t('settleTable.creatorDiscount')} &#40;B&#41;</span>
                  <SortArrowIcon type="discount" />
                </TableCell>
                <TableCell
                  variant="head"
                  width="136px"
                  sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                  onClick={() => clickColumn('settlePrice')}
                >
                  <span>{t('settleTable.settlePrice')} &#40;A-B&#41;</span>
                  <SortArrowIcon type="settlePrice" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ '& td': { fontSize: '14px !important', lineHeight: '22px', p: '16px !important', textAlign: 'left !important' } }}>
              {listQuery?.data &&
                sortList.map((item, i) => {
                  const { goodsNm, orderId, penNm, licenseType, paymentDate, orderDate, settleDate, salePrice, settlementPrice, userBurdenDcPrice } = item;
                  return (
                    <TableRow
                      key={`settle table ${i}`}
                      sx={{
                        position: 'relative',
                        transition: '0.5s',
                        '&:hover': {
                          bgcolor: palette.light.grey[100],
                          cursor: 'pointer',
                        },
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                      }}
                      onClick={() => {
                        localStorage.setItem('settle_url_params', JSON.stringify(urlParamObj));
                        history.push(`/manager/settle/${orderId}`);
                      }}
                    >
                      <TableCell>
                        <Field>
                          <span>{goodsNm}</span>
                        </Field>
                      </TableCell>
                      <TableCell>
                        <Field width="60">
                          <span>{penNm}</span>
                        </Field>
                      </TableCell>

                      <TableCell className="projectNameValue">
                        <Field width="64">{licenseType}</Field>
                      </TableCell>
                      <TableCell>
                        <Field>{asiaSeoulDayjs(selectedFilterValue === 'payment' ? paymentDate : orderDate).format('YY-MM-DD\u00a0\u00a0HH:mm')}</Field>
                      </TableCell>
                      <TableCell>
                        <Field width="84">
                          <span
                            style={{
                              color: new Date(dayjs(settleDate).format('YYYY-MM-DD') + ' 00:00:00') > new Date() ? '#c4c4c4' : '',
                            }}
                          >
                            {asiaSeoulDayjs(settleDate).format('YY-MM-DD')}
                          </span>
                        </Field>
                      </TableCell>
                      <TableCell>
                        <Field width="98">{displayMoneyByLang(Number(salePrice), i18n.language)}</Field>
                      </TableCell>
                      <TableCell>
                        <Field width="98">{displayMoneyByLang(Number(userBurdenDcPrice), i18n.language)}</Field>
                      </TableCell>
                      <TableCell>
                        <Field width="104">{displayMoneyByLang(Number(settlementPrice), i18n.language)}</Field>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        <Box display="flex" alignItems="center" px="24px">
          <Typography ml="auto" mr="20px">
            {t('settle.perPage')}:
          </Typography>
          <SelectBox defaultValue={1} options={viewCountList} onSelect={onChangeViewCountSelectTag} isBorder={false} width="80px" padding="0" marginRight="16px" />
          {listQuery?.data?.pagination && (
            <PageNavigation totalCnt={listQuery?.data?.pagination?.totalCount} limit={urlParamObj.limit} pathName={'/manager/settle'} history={history} />
          )}
        </Box>
      </Container>
      <Box pt="100px" />
    </>
  );
};

export default Enterprise;
