import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOTICE_DATA, GET_FAQ_DATA, GET_POPUP_DATA } from '../api/quries';
import BoardsInfo from './BoardsInfo';
import queryString from 'query-string'
import { AppContext } from '../app'
import './Boards.scss';
import { Box, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { Button, SelectBox } from 'acon-mui/components';
import { ReactComponent as SearchIcon } from 'acon-mui/icons/icon-search.svg';
import PageNavigation from './PageNavigation';

const BoardsInfoMemo = React.memo(BoardsInfo)
const PageNaviMemo = React.memo(PageNavigation)

function Boards(props) {
    const { setBackgroundColor, userInfo } = useContext(AppContext)
    const { t, match } = props;
    const lang = props.i18n.language
    const { isAdmin } = userInfo

    let boardName = match.params.boardName || "notice"
    const options = [
        { id: 0, label: t("Title.label"), value: 'title' },
        { id: 1, label: t("Contents.label"), value: 'body' },
    ];

    let query: {
        type?: string;
        q?: string;
        id?: number;
    } = { type: 'title', q: '' }
    if (props.history.location.search) {
        query = queryString.parse(props.history.location.search)
    }

    let page = 1
    if (match.params.page) {
        page = +match.params.page
    }

    const defaultLimit = 10

    const [searchParams, setSearchParams] = useState<{ type?: string; keyword?: string; page?: number; limit?: number; lang?: string; } | null>(null);
    const searchInput = {
        type: query.type,
        keyword: query.q
    }

    useEffect(() => {
        setBackgroundColor('white')
    }, [])

    useEffect(() => {
        setSearchParams({
            type: query.type,
            keyword: query.q,
            page,
            limit: 10,
            lang
        })
        searchInput.type = query.type || 'title'
        searchInput.keyword = query.q || ''
    }, [boardName, page, query.type, query.q, lang])

    function onClickWriteBoard(e) {
        props.history.push(`/board/${boardName}/write`);
    }

    function _handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }
    function handleSearch() {
        let query = ''
        if (searchInput.keyword?.trim()) {
            let keyword = searchInput.keyword || ''
            let type = searchInput.type || ''
            query = queryString.stringify({ q: keyword, type })
        }
        props.history.push({
            pathname: `/board/${boardName}/1`, search: query
        })
    }

    function handleSearchChange(e, type) {
        if (type === 'type') {
            searchInput[type] = options.find(opt => opt.id === e.target.value)?.value;
        } else {
            searchInput[type] = e.target.value;
        }
    }

    // 검색 양식 
    const SearchForm = () => {
        return (
            <Box mt={3} display="flex">
                <SelectBox
                    defaultValue={options.find(opt => opt.value === searchParams?.type)?.id || 0}
                    options={options}
                    onSelect={(e) => handleSearchChange(e, 'type')}
                    width="160px"
                    {...{
                        '& .MuiSelect-select': {
                            paddingTop: '8.5px',
                            paddingBottom: '8.5px'
                        },
                    }}
                />
                <TextField
                    defaultValue={searchParams?.keyword || null}
                    placeholder={t('settle.inputSearchKeyword')}
                    onChange={(e) => handleSearchChange(e, 'keyword')}
                    onKeyUp={_handleKeyDown}
                    fullWidth
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    sx={{
                        ml: 1,
                        '& input': {
                            p: '8.5px 14px'
                        }
                    }}
                />
                <Button 
                    variant='contained'
                    onClick={handleSearch}
                    marginLeft="8px"
                    width='57px'
                >
                    {t('detailBoard.search')}
                </Button>
            </Box>

        );
    }

    // 게시판 셀렉터 관련 로직 (boardName = [ 'notice', 'faq', 'popup' ][x])
    const boardTitleText = t(`BoardTitles.${boardName}`)

    // 게시판 셀렉터 버튼 클릭시 이벤트 함수
    const onClickBoardSelector = (e) => {
        const boardName = e.target.dataset.name;
        props.history.push(`/board/${boardName}`);
    };

    let boardsQuery
    if (boardName === "notice") {
        boardsQuery = GET_NOTICE_DATA
    } else if (boardName === "faq") {
        boardsQuery = GET_FAQ_DATA
    } else if (boardName === "popup") {
        boardsQuery = GET_POPUP_DATA
    }

    const { loading, error, data, refetch } = useQuery(boardsQuery, {
        fetchPolicy: "no-cache",
        variables: searchParams || {}
    })

    if (error) console.log(error)

    let items, totalPage
    if (data) {
        if (boardName === "notice") {
            items = data.getNotices
            totalPage = Math.ceil(+data.getNoticesTotalCount / defaultLimit)
        } else if (boardName === "faq") {
            items = data.getFaqs
            totalPage = Math.ceil(+data.getFaqsTotalCount / defaultLimit)
        } else if (boardName === "popup") {
            items = data.getPopups
            totalPage = Math.ceil(+data.getPopupsTotalCount / defaultLimit)
        }
    }

    if (totalPage < 1 || totalPage === Infinity) totalPage = 1

    return (
        <Box 
            py={5} 
            display="flex" 
            flexDirection="column"
            {...isAdmin && {
                sx: { '@media only screen and (max-width: 480px)': { pt: 11 } }
            }}
        >
            {isAdmin ? (
                <Box
                    display="flex"
                    alignItems="center"
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="36px"
                >
                    <Box
                        onClick={onClickBoardSelector}
                        data-name="notice"
                        px={2}
                        color={boardName === "notice" ? "#212B36" : "#212B361E"}
                        sx={{ cursor: 'pointer' }}
                    >
                        {t("BoardTitles.notice")}
                    </Box>
                    <Box height="24px" borderRight="4px solid #212B361E"/>
                    <Box
                        onClick={onClickBoardSelector}
                        data-name="faq"
                        px={2}
                        color={boardName === "faq" ? "#212B36" : "#212B361E"}
                        sx={{ cursor: 'pointer' }}
                    >
                        {t("BoardTitles.faq")}
                    </Box>
                    <Button
                        onClick={onClickWriteBoard}
                        variant="contained"
                        colorTheme='primary'
                        marginLeft="auto"
                        width='72px'
                        padding="6px 16px"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="24px"
                        boxShadow="none"
                    >
                        {t("Boards.write")}
                    </Button>
                </Box>
            ) : (
                <Typography
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="36px"
                    color="#212B36"
                >
                    {boardTitleText}
                </Typography>
            )}
            {isDesktop && (
                <SearchForm />
            )}
            <Box mt={3} width="100%">
                <BoardsInfoMemo
                    {...props}
                    init={false}
                    items={items}
                    refetch={refetch}
                    type="full"
                    openedItemId={+query.id}
                />
            </Box>
            <Box ml="auto" py={2}>
                <PageNaviMemo
                    totalCnt={boardName === 'notice' ?  data?.getNoticesTotalCount : data?.getFaqsTotalCount}
                    limit={10}
                    pathName={`/board/${boardName}`}
                    buttonVisible
                    match={props.match}
                />
            </Box>
        </Box>
    );
}

export default Boards;
