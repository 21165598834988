import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import BannerInfo from './BannerInfo';
import PageNavi from './PageNavi';
import queryString from 'query-string';
import { AppContext } from '../app';
import './Banner.scss';
import { GET_DETAIL_PAGE_BANNERS_DATA, GET_EVENT_PAGE_BANNERS_DATA } from '../api/quries';

const BannerInfoMemo = React.memo(BannerInfo);
const PageNaviMemo = React.memo(PageNavi);
function Banner(props) {
  const { setBackgroundColor, userInfo } = useContext(AppContext);
  const { t, match } = props;
  const { isAdmin } = userInfo;

  // 현재 페이지 타입을 가져옵니다.
  const bannerDisplayLocation = match.params.displayLocation || 'detailPage';
  // 페이지 별 달라지는 기본 번역 문구를 저장
  const pageI18nMessage = {
    write: t('Banners.write'),
  };

  let query = {};
  if (props.history.location.search) {
    query = queryString.parse(props.history.location.search);
  }

  // 현재 몰에 대한 정보
  const langCode = query?.lang || 'ko';

  let page = 1;
  if (match.params.page) {
    page = +match.params.page;
  }

  // 전역 으로 설정되는 배경 색을 변경합니다.
  useEffect(() => {
    setBackgroundColor('white');
  }, []);

  // 배너생성 버튼 클릭 시 이동하는 메소드 입니다.
  function onClickWriteBanner(e) {
    props.history.push({
      pathname: `/banner/${bannerDisplayLocation}/write`,
      search: queryString.stringify({ lang: langCode }),
    });
  }

  // 게시판 셀렉터 관련 로직 (bannerDisplayLocation = [ 'detailPage', 'mainPage' ][x])
  // 게시판 셀렉터 버튼 클릭시 이벤트 함수
  const onClickBannerSelector = (e) => {
    const bannerDisplayLocation = e.target.dataset.name;

    // 메인페이지 배너관리 기능은 기획 준비중입니다.
    if (bannerDisplayLocation === 'mainPage') return;

    props.history.push(`/banner/${bannerDisplayLocation}`);
  };

  // 페이지 탭
  const BoardTab = (props) => {
    let pageName;
    if ('detailPage' === props.type) {
      pageName = t('BannerSelectors.detailPage');
    } else if ('eventPage' === props.type) {
      pageName = t('BannerSelectors.eventPage');
    }

    return (
      <button className={bannerDisplayLocation === props.type ? 'selected' : ''} onClick={onClickBannerSelector} data-name={props.type}>
        {pageName}
      </button>
    );
  };

  // 페이지 별 쿼리 및 기본 설정
  let bannersQuery;
  if (bannerDisplayLocation === 'detailPage') {
    bannersQuery = GET_DETAIL_PAGE_BANNERS_DATA;
  } else if (bannerDisplayLocation === 'eventPage') {
    bannersQuery = GET_EVENT_PAGE_BANNERS_DATA;
  }

  // 기본 limit 설정
  const defaultLimit = 10;
  // 진행 중인 쿠폰만 보기 여부
  const [isInprogressCoupon, setIsInprogressCoupon] = useState(false);
  // 배너 데이터를 조회 합니다.
  const { data, refetch } = useQuery(bannersQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      page,
      limit: defaultLimit,
      viewOnlyActiveCoupons: isInprogressCoupon,
      lang: langCode,
    },
  });

  let items, totalPage;
  if (data) {
    if (bannerDisplayLocation === 'detailPage') {
      // 데이터를 받고 배너 정보를 세팅합니다.
      items = data.getBanners;
      // 배너의 총 개수와 limit을 나눈 값으로 전체 페이지를 구합니다.
      totalPage = Math.ceil(+data.getBannersTotalCount / defaultLimit);
    } else if (bannerDisplayLocation === 'eventPage') {
      // 데이터를 받고 배너 정보를 세팅합니다.
      items = data.getEventBannerItems;
      // 배너의 총 개수와 limit을 나눈 값으로 전체 페이지를 구합니다.
      totalPage = Math.ceil(+data.getEventBannerTotalCount / defaultLimit);
    }
  }

  // lang 설정 UI
  const LanguageSelectTag = () => {
    const options = data?.getLanguages?.map((e, i) => {
      return (
        <option key={i} value={e.code}>
          {e.code.toUpperCase()}
        </option>
      );
    });
    return (
      <select style={{ width: '150px', marginRight: '20px' }} className={'commonSelectBox'} onChange={onChangeLanguage} value={langCode}>
        {options}
      </select>
    );
  };
  // lang 설정 변경시 작동하는 로직
  const onChangeLanguage = (e) => {
    props.history.push({
      pathname: `/banner/${bannerDisplayLocation}`,
      search: queryString.stringify({ lang: e.target.value }),
    });
  };

  // 검색 조건 컴포넌트
  // 진행 중인 항목 보기 및 언어별 항목 보기
  const SearchOption = () => {
    return (
      <>
        <div className="inprogressCoupon">
          <input
            type="checkbox"
            onChange={(e) => {
              setIsInprogressCoupon(e.target.checked);
            }}
            id="inprogressCouponCheckBox"
          />
          <label className="inprogressLabel" for="inprogressCouponCheckBox">
            {bannerDisplayLocation === 'eventPage' && <span>진행중 이벤트만 보기</span>}
          </label>
        </div>
        <LanguageSelectTag />
      </>
    );
  };

  // 전체 페이지가 1보다 작거나 무한이면 전체 페이지 수를 1로 설정
  if (totalPage < 1 || totalPage === Infinity) totalPage = 1;

  if (!isAdmin) {
    props.history.goBack();
    return <></>;
  } else {
    return (
      <div className="notice">
        <div className="board-selector">
          <BoardTab type="detailPage" />
          <BoardTab type="eventPage" />
        </div>
        <div className="notice__flex">
          <div className="notice__title"></div>
          <div className="write-notice">
            {bannerDisplayLocation === 'eventPage' && <SearchOption />}
            <button type="button" className="max-btn black-button" onClick={onClickWriteBanner}>
              {pageI18nMessage.write}
            </button>
          </div>
        </div>
        <BannerInfoMemo {...props} init={false} items={items} refetch={refetch} type="full" openedItemId={query.id} />
        <div className="notice__line center">
          <PageNaviMemo {...props} totalPage={totalPage} pathName={`/banner/${bannerDisplayLocation}`} />
        </div>
      </div>
    );
  }
}

export default Banner;
