import { atom, DefaultValue, selector } from 'recoil';
import { BusinessTypeEnum, SignupChapterEnum, SignupChapterValid, PartnerTypeEnum } from './type';

export const statusState = atom({
  key: 'status',
  default: SignupChapterEnum.UserType,
});

export const companyNameState = atom({
  key: 'companyName',
  default: '',
});

export const signLangState = atom({
  key: 'signLang',
  default: '',
});

export const brandNamesState = atom({
  key: 'brandNames',
  default: {
    koBrandName: '',
    enBrandName: '',
    zhBrandName: '',
    jaBrandName: '',
  },
});

export const nameState = atom({
  key: 'name',
  default: '',
});

export const userEmailState = atom({
  key: 'userEmail',
  default: '',
});

const passwordState = atom({
  key: 'password',
  default: '',
});

export const businessTypeState = atom<BusinessTypeEnum>({
  key: 'businessType',
  default: undefined,
});

export const contactState = atom({
  key: 'contact',
  default: '',
});

const snsUrlState = atom({
  key: 'snsUrl',
  default: '',
});

const documentsState = atom({
  key: 'documents',
  default: [] as string[],
});

const contactAdditionalState = atom({
  key: 'contactAdditional',
  default: '',
});

export const idCardState = atom<{ key: string; name: string }>({
  key: 'idCard',
  default: null,
});

export const bankBookState = atom<{ key: string; name: string }>({
  key: 'bankBook',
  default: null,
});

export const rrnState = atom({
  key: 'rrn',
  default: '',
});

export const signDataUrlState = atom({
  key: 'signDataUrl',
  default: '',
});

export const signLocaleState = atom({
  key: 'signLocale',
  default: '',
});

export const signState = atom({
  key: 'sign',
  default: '',
});

export const chapterState = atom({
  key: 'chapterValid',
  default: {
    [SignupChapterEnum.Account]: false,
    // [SignupChapterEnum.Contract]: false,
    [SignupChapterEnum.Partner]: false,
    [SignupChapterEnum.Payment]: false,
    [SignupChapterEnum.UserType]: false,
    [SignupChapterEnum.Confirm]: true,
  } as SignupChapterValid,
});

export const userTypeState = atom<PartnerTypeEnum>({
  key: 'type',
  default: PartnerTypeEnum.Personal,
});

export const countryState = atom({
  key: 'country',
  default: '',
});

export const taxPayerIdState = atom({
  key: 'taxPayerId',
  default: '',
});

export const promptModalVisibleState = atom<boolean>({
  key: 'promptModalVisible',
  default: true,
});

export const userTypeFormState = selector<{
  userType: PartnerTypeEnum;
  businessType: BusinessTypeEnum;
}>({
  key: 'userTypeInformation',
  get: ({ get }) => {
    return {
      userType: get(userTypeState),
      businessType: get(businessTypeState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(userTypeState);
      reset(businessTypeState);
    } else {
      set(userTypeState, newValue.userType);
      set(businessTypeState, newValue.businessType);
    }
  },
});

export const accountFormState = selector<{
  userEmail: string;
  password: string;
}>({
  key: 'acountForm',
  get: ({ get }) => {
    return {
      userEmail: get(userEmailState),
      password: get(passwordState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(userEmailState);
      reset(passwordState);
    } else {
      set(userEmailState, newValue.userEmail);
      set(passwordState, newValue.password);
    }
  },
});

export const contractFormState = selector<{
  name: string;
  companyName?: string;
  contact: string;
  rrn?: string;
  country?: string;
  taxPayerId?: string;
}>({
  key: 'contractForm',
  get: ({ get }) => {
    return {
      companyName: get(companyNameState),
      name: get(nameState),
      rrn: get(rrnState),
      contact: get(contactState),
      country: get(countryState),
      taxPayerId: get(taxPayerIdState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(companyNameState);
      reset(nameState);
      reset(contactState);
      reset(rrnState);
      reset(countryState);
      reset(taxPayerIdState);
    } else {
      set(companyNameState, newValue.companyName);
      set(nameState, newValue.name);
      set(contactState, newValue.contact);
      set(rrnState, newValue.rrn);
      set(countryState, newValue.country);
      set(taxPayerIdState, newValue.taxPayerId);
    }
  },
});

export const partnerFormState = selector<{
  brandNames: {
    koBrandName?: string;
    enBrandName?: string;
    zhBrandName?: string;
    jaBrandName?: string;
  };
  documents: string[];
  contactAdditional?: string;
  snsUrl?: string;
}>({
  key: 'partnerForm',
  get: ({ get }) => {
    return {
      brandNames: get(brandNamesState),
      documents: get(documentsState),
      contactAdditional: get(contactAdditionalState),
      snsUrl: get(snsUrlState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(brandNamesState);
      reset(contactState);
      reset(documentsState);
      reset(contactAdditionalState);
      reset(snsUrlState);
    } else {
      set(brandNamesState, newValue.brandNames);
      set(documentsState, newValue.documents);
      set(contactAdditionalState, newValue.contactAdditional);
      set(snsUrlState, newValue.snsUrl);
    }
  },
});

export const signinState = selector({
  key: 'signin',
  get: ({ get }) => {
    return {
      ...get(userTypeFormState),
      ...get(accountFormState),
      ...get(contractFormState),
      ...get(partnerFormState),
      idCard: get(idCardState),
      bankBook: get(bankBookState),
      signLang: get(signLangState),
      signDataUrl: get(signDataUrlState),
      locale: get(signLocaleState),
    };
  },
});
