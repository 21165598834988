import React from 'react';

import { IUnableAlertModalProps } from './UnableAlertModal.types';
import { Button } from 'acon-mui/components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UnableAlertModalContainer, UnableAlertModalBody, UnableAlertModalConfirmWrap } from './UnableAlertModal.styles';
import { Typography } from '@mui/material';
import { color } from 'acon-mui/style';

const UnableAlertModal = ({ isShow, onClose }: IUnableAlertModalProps): React.ReactElement => {
  const { t, i18n } = useTranslation();

  return (
    <UnableAlertModalContainer centered show={isShow} onHide={onClose}>
      <UnableAlertModalBody>
        <Typography align="center" fontSize={18} fontWeight={700} color={color.text.primary}>
          새로운 계약서 관련 확인이 필요합니다.
        </Typography>
        <Typography align="center" fontSize={14} fontWeight={400} color={color.text.primary} sx={{ wordBreak: 'keep-all' }}>
          에이콘 파트너 작가 계약서가 변경되어 확인이 필요하며 이후 서비스 이용이 가능합니다. contact@acon3d.com 으로 연락 부탁드립니다.
        </Typography>
        <UnableAlertModalConfirmWrap>
          <Button colorTheme="primary" onClick={onClose} disabled={false} minWidth="132px" width="fit-content" marginLeft="12px" borderRadius="4px">
            확인
          </Button>
        </UnableAlertModalConfirmWrap>
      </UnableAlertModalBody>
    </UnableAlertModalContainer>
  );
};

export default UnableAlertModal;
