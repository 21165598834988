import styled from 'styled-components';

export const Menu = styled.div`
`;
export const MenuItem = styled.div`
    padding: 10px 25px;
    cursor: pointer;
    &: hover {
        background-color: #ccc;
    }
    border-bottom: 1px solid #ccc;

    &:nth-last-of-type(1) {
        border-bottom: 0;
    }
`;
export const Tooltip = styled.div`
    position: absolute;
    font-size: 14px;
    z-index:999;
    border: 1px solid #ccc;
    background-color: white; 
`;