import { Box, Stack, Typography } from '@mui/material';
import { is } from 'date-fns/locale';
import React, { Fragment } from 'react';

const getStyle = (color) => {
  switch (color) {
    case 'inprogress':
      return {
        color: 'rgba(0, 123, 85, 1)',
        background: 'rgba(0, 171, 85, .16)',
        border: '1px solid rgba(0, 171, 85, .24)',
      };
    case 'cancel':
      return {
        color: '#B71D18',
        background: 'rgba(255, 86, 48, .16)',
        border: '1px solid rgba(255, 86, 48, .24)',
      };
    case 'ready':
      return {
        color: 'rgba(25, 57, 183, 1)',
        background: 'rgba(51, 102, 255, .24)',
        border: '1px solid rgba(51, 102, 255, .32)',
      };
    case 'ended':
      return {
        color: '#212B36',
        background: 'rgba(145, 158, 171, 0.16)',
      };
  }
};

export default function CalendarLabel({
  isStart,
  isEnd,
  color,
  text,
  ...rest
}: {
  isStart;
  isEnd;
  color: 'green' | 'yellow' | 'red' | 'blue' | 'sky-blue' | 'black';
  text: string[];
  [key: string]: unknown;
}) {
  const isOneRow = Boolean(isStart && isEnd);
  return (
    <Box p="2px 8px" width={'100%'} borderRadius="6px" sx={getStyle(color)} {...rest}>
      {isOneRow ? (
        <Stack direction="row" justifyContent="space-between">
          {text.map((t) => (
            <Typography fontWeight="700" fontSize="12px" lineHeight="20px" sx={{ wordBreak: 'keep-all', textAlign: 'left' }}>
              {t}
            </Typography>
          ))}
        </Stack>
      ) : (
        <Typography fontWeight="700" fontSize="12px" lineHeight="20px" sx={{ wordBreak: 'keep-all', textAlign: isStart ? 'left' : 'right' }}>
          {text.join()}
        </Typography>
      )}
    </Box>
  );
}
