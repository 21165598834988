import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Question from './Question';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import QuestionInput from './QuestionInput';
import { Container, Modal } from 'acon-mui/components';
import { Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';

interface Props {
  inputs?: {
    warehouseInput: any;
    externalFreeSourceInput: any;
    externalPaySourceInput: any;
    agreeInput: any;
  };
}

export default ({ inputs }: Props) => {
  const { t } = useTranslation();
  const { isDisp, status, copyright, setCopyright } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    copyright: state.copyright,
    setCopyright: state.setCopyright,
  }));
  const originalData = useOriginalDocumentStore((state) => ({ copyright: state.copyright }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [trademarkModalVisible, setTrademarkModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setCopyright(originalData?.copyright);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };
  useEffect(() => {
    if (copyright.commercialSources === null && copyright.warehouseSources === null && copyright.hasRealLogo === 'NO') {
      setCopyright({
        ...copyright,
        isOriginal: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyright.commercialSources, copyright.warehouseSources, copyright.hasRealLogo]);

  return (
    <>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('goods.copyright'))}>
        {t('goods.copyright')}
      </Title>
      <Container
        marginTop="12px"
        display="flex"
        flexDirection="column"
        width="100%"
        {...(disabled && {
          pointerEvents: 'none',
        })}
        {...(isDisp && {
          sx: { pointerEvents: 'none' },
        })}
      >
        <ul>
          <li>
            {/* 본 모델의 소스는 직접 제작 하였습니다. ( 질문지 1번항목 )*/}
            <Question
              text={parse(t('document.copyright.isOriginal'))}
              buttons={[
                { text: t('document.copyright.yes'), value: true },
                { text: t('document.copyright.no'), value: false },
              ].map((x) => ({
                text: x.text,
                isSelected: copyright.isOriginal === x.value,
                disabled: isDisp,
                onClick: () => {
                  setCopyright({
                    isOriginal: x.value,
                    commercialSources: undefined,
                    warehouseSources: undefined,
                    hasRealLogo: undefined,
                  });
                },
              }))}
            />
          </li>

          {!copyright.isOriginal && (
            <li className="mt-4">
              {/* 3D웨어하우스 소스를 사용하였습니다.(질문지 1-1번 항목) */}
              <Question
                text={parse(t('document.copyright.warehouse.question'))}
                buttons={[
                  { text: t('document.copyright.yes'), value: '' },
                  { text: t('document.copyright.no'), value: null },
                ].map((x) => ({
                  text: x.text,
                  isSelected: x.value === null ? copyright.warehouseSources === null : typeof copyright.warehouseSources === 'string',
                  disabled: isDisp,
                  onClick: () => {
                    setCopyright({
                      ...copyright,
                      warehouseSources: x.value,
                    });
                  },
                }))}
              />
            </li>
          )}
          {typeof copyright.warehouseSources === 'string' && (
            <li className="mt-4">
              {/* 질문지 1-1-1번 항목 - 답변 */}
              <QuestionInput
                ref={inputs?.warehouseInput}
                text={parse(t('document.copyright.warehouse.additional'))}
                defaultValue={copyright.warehouseSources}
                placeholder={t('document.copyright.warehouse.example')}
                disabled={isDisp}
              />
              {copyright.isErrorWarehouseCopyright && (
                <InformationCard marginTop="24px" type="warning">
                  {t('pleaseEnterWarehouseSource')}
                </InformationCard>
              )}
              <InformationCard marginTop="24px" type="notice">
                {parse(t('document.copyright.warehouse.notice'))}
              </InformationCard>
            </li>
          )}
          {!copyright.isOriginal && typeof copyright.warehouseSources !== 'undefined' && (
            <li className="mt-4">
              {/* 상업적으로 이용이 가능한 타 무료 외부 소스를 사용하였습니다. (질문지 1-2번 항목) */}
              <Question
                text={parse(t('document.copyright.commercialSource.question'))}
                buttons={[
                  { text: t('document.copyright.yes'), value: '' },
                  { text: t('document.copyright.no'), value: null },
                ].map((x) => ({
                  text: x.text,
                  isSelected: x.value === null ? copyright.commercialSources === null : typeof copyright.commercialSources === 'string',
                  disabled: isDisp,
                  onClick: () => {
                    setCopyright({
                      ...copyright,
                      commercialSources: x.value,
                    });
                  },
                }))}
              />
            </li>
          )}
          {typeof copyright.commercialSources === 'string' && (
            <li className="mt-4">
              {/* 질문지 1-2-1번 항목 - 답변 */}
              <QuestionInput
                ref={inputs?.externalFreeSourceInput}
                text={parse(t('document.copyright.commercialSource.additional'))}
                defaultValue={copyright.commercialSources}
                placeholder={t('document.copyright.commercialSource.example')}
              />
              {copyright.isErrorFreeCopyright && (
                <InformationCard marginTop="24px" type="warning">
                  {t('pleaseEnterOtherSource')}
                </InformationCard>
              )}
              <InformationCard marginTop="24px" type="notice">
                {parse(t('document.copyright.commercialSource.notice'))}
              </InformationCard>
            </li>
          )}
          {!copyright.isOriginal && typeof copyright.commercialSources !== 'undefined' && (
            <li className="mt-4">
              {/* 실존하는 로고 또는 상표를 사용하였습니다 */}
              <Question
                text={parse(t('document.copyright.realLogo.question'))}
                buttons={[
                  { text: t('document.copyright.yes'), value: 'YES' },
                  { text: t('document.copyright.modify'), value: 'MODIFIED' },
                  { text: t('document.copyright.no'), value: 'NO' },
                ].map((x) => ({
                  text: x.text,
                  isSelected: copyright.hasRealLogo === x.value,
                  disabled: false,
                  onClick: () => {
                    if (x.value === 'YES') {
                      setTrademarkModalVisible(true);
                    }
                    setCopyright({
                      ...copyright,
                      hasRealLogo: x.value,
                    });
                  },
                }))}
              />
              {copyright.hasRealLogo === 'MODIFIED' && (
                <InformationCard marginTop="24px" type="notice">
                  {parse(t('document.copyright.realLogo.notice'))}
                </InformationCard>
              )}
            </li>
          )}
        </ul>

        <InformationCard marginTop="24px" type="notice">
          {parse(t('document.copyright.warning'))}
        </InformationCard>

        {copyright.isErrorAgree && (
          <InformationCard marginTop="24px" type="warning">
            {t('checkCopyright')}
          </InformationCard>
        )}
        <Modal
          isOpen={trademarkModalVisible}
          onClose={() => setTrademarkModalVisible(false)}
          title={t('ExternalTrademark.title')}
          description={t('ExternalTrademark.description')}
          rightButtonText={t('ExternalTrademark.confirm')}
          onClickRightButton={() => setTrademarkModalVisible(false)}
        />
      </Container>
    </>
  );
};
