import React, {useContext, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import queryString from 'query-string'
import {AppContext} from '../../../app'
import PageNavigation from 'components/pageNavigation';
import {CommonButton, CommonSelectBox, NaviWrapper, PointHeader, Table, Td, Th, Thead, Tr} from '../style';
import {GET_POINT_BENEFIT_DATA} from '../../../api/quries';
import {convertPrice} from "../../../lib/multiLocale";

export default ((props) => {
    const {userInfo} = useContext(AppContext)
    const {match, history} = props;
    const {isAdmin} = userInfo
    const [languageValue, setLanguageValue] = useState(0);
    // 번역도구
    const {t} = useTranslation();
    // 현재 페이지 타입을 가져옵니다.
    const burdenDisplayLocation = match.params.displayLocation || "brandBurden";
    // 혜택 생성 페이지 이동
    const onClickWritePage = () => {
        history.push(`/manager/benefit/${burdenDisplayLocation}/write`);
    };

    let query = {}
    if (props.history.location.search) {
        query = queryString.parse(props.history.location.search)
    }

    let page = 1 
    if (query.page) {
        page = Number(query.page);
    }

    // 기본 limit 설정
    const defaultLimit = 8
    // 포인트 혜택 데이터를 조회 합니다.
    let queryVariables = { page, limit: defaultLimit }
    queryVariables.langId = languageValue !== 0 ? languageValue : null;
    const { loading, error, data, refetch } = useQuery(GET_POINT_BENEFIT_DATA, {
        fetchPolicy: "no-cache",
        variables: queryVariables
    });

    
    console.log(`data`, data);
    
    let items, totalPage, languages;
    if (data) {
        items = data.getPointBenefits;
        languages = data.getLanguages;
        // 배너의 총 개수와 limit을 나눈 값으로 전체 페이지를 구합니다.
        totalPage = Math.ceil(+data.getPointBenefitsTotalCount / defaultLimit)
    }

    // 전체 페이지가 1보다 작거나 무한이면 전체 페이지 수를 1로 설정
    if (totalPage < 1 || totalPage === Infinity ) totalPage = 1;

    // 기간이 설정되지 않은 경우 종료하지 않음 표시하는 메소드
    const replaceDate = (startDate, endDate) => {
        // 기간을 표시할 때 날짜를 formatting해주는 옵션입니다.
        const localeDateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
        let endDateText;

        if(new Date(endDate) > 4000000000000) {
            endDateText = t('Boards.popupNeverEnd');
        } else {
            endDateText = new Date(endDate).toLocaleDateString('ko-KR', localeDateOptions)
        }

        return `${new Date(startDate).toLocaleDateString('ko-KR', localeDateOptions)} ~ ${endDateText}`;
    };

    // lang 설정 UI
    const LanguageSelectTag = () => {
        const options = languages?.map((e, i) => {
            return (
                <option key={i + 1} value={e.id}>{e.code.toUpperCase()}</option>
            );
        });
        return (
            <CommonSelectBox textAlign={'center'} padding={'0px 40px'} onChange={onChangeLanguage} value={languageValue}>
                <option key={0} value={0}>전체 몰</option>
                {options}
            </CommonSelectBox>
        );
    };
    // lang 설정 변경시 작동하는 로직
    const onChangeLanguage = (e) => {
        setLanguageValue(Number(e.target.value));
    };

    const TableRow = ({ items, idx }) => {
        // 행 클릭 시 이동 링크 설정
        const titleHref = `/manager/benefit/${burdenDisplayLocation}/write/` + items.id;
        // 포인트 3자리 마다 콤마 추가
        const formatPointText = items.pointUnit === 'fixed' ? convertPrice(items.payPoint, 'ko', items.language.code).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : items.payPoint + "%";
        // 기간 포맷 변경
        const dateText = replaceDate(items.startDate, items.endDate);
        // 설정 값 텍스트 변경
        const applyValueText = items.applyList.length > 0 ? items.applyList.length > 1 ? `${items.applyList[0].value} 외 ${items.applyList.length - 1}개` : items.applyList[0].value : '';
        // 수량이 없을 경우 - 표시
        const quantityText = items.quantity ? `${items.useCount}/${items.quantity}` : '-';
        // 기본 설정 여부
        const isDefaultApply = items.applyType.code === 'default';
        // 언어 설정 표시
        const languageText = (() => {
            // 기본 설정은 언어 표시 안함.
            // 주문 관련 혜택일 경우 언어 표시 안함
            // 주문 관련 헤택은 v2 이전하기 전 언어 설정이 불가하므로 무조건 조회되도록 설정
            // 나중 주문 v2가 완성되면 해당 코드를 빼야 합니다.
            if(isDefaultApply || ['complete', 'settle'].includes(items.type.code)) {
                return '';
            }

            return items.language.code;
        })();

        return (
            <tr key={idx}>
                <Td>
                    {
                        // 기본 설정이 아닌 경우만 언어 설정 표시
                        languageText
                    }
                </Td>
                <Td>
                    <NavLink style={{color: '#575757'}} to={titleHref}>
                        {items.type.name}
                    </NavLink>
                </Td>
                <Td>
                    <NavLink style={{color: '#575757'}} to={titleHref}>
                        {
                            // 기본 설정이 아닌 경우만 지급 포인트 표시
                            ! isDefaultApply ? formatPointText : ''
                        }
                    </NavLink>
                </Td>
                <Td>
                    <NavLink style={{color: '#575757'}} to={titleHref}>
                        {items.applyType.name}
                    </NavLink>
                </Td>
                <Td>
                    <NavLink style={{color: '#575757'}} to={titleHref}>
                        {applyValueText}
                    </NavLink>
                </Td>
                <Td>{quantityText}</Td>
                <Td>
                    {
                        // 기본 설정이 아닌 경우만 기간 표시
                        ! isDefaultApply ? dateText : ''
                    }
                </Td>
            </tr>
        );
    };

    if (!isAdmin) {
        props.history.goBack()
        return <></>
    } else {
        return (
            <>
                <PointHeader>
                    <LanguageSelectTag />
                    <CommonButton onClick={onClickWritePage}>{t('burden.point.register')}</CommonButton>
                </PointHeader>
                <Table>
                    <colgroup>
                        <col width="10%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="20%" />
                    </colgroup>
                    <Thead>
                        <Tr>
                            <Th>{t('burden.point.table.lang')}</Th>
                            <Th>{t('burden.point.table.unit')}</Th>
                            <Th>{t('burden.point.table.point')}</Th>
                            <Th>{t('burden.point.table.apply')}</Th>
                            <Th>{t('burden.point.table.applyValue')}</Th>
                            <Th>{t('burden.point.table.quantity')}</Th>
                            <Th>{t('burden.point.table.date')}</Th>
                        </Tr>
                    </Thead>
                    <tbody>
                        {(items || [])?.map((x, i) => (
                            <TableRow items={x} key={i} />
                        ))}
                    </tbody>
                </Table>
                <NaviWrapper>
                    {
                        !loading && data && (
                            <PageNavigation
                                match={match}
                                totalPage={totalPage}
                                pathName={`/manager/benefit/${burdenDisplayLocation}`}
                                history={history}
                                secPathName={"/"}
                            />
                        )
                    }
                </NaviWrapper>
            </>
        );
    }
});