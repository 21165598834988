import axios from 'axios';

export function paymentInstance() {
  const instance = axios.create({
    baseURL: process.env.NETWORK_HOST_PAYMENT,
    withCredentials: true,
  });
  instance.interceptors.request.use((config) => {
    return config;
  });

  return instance;
}
