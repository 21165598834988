import styled from 'styled-components';

// 이미지 유형 
export const ImageType = styled.div`
    font-size: 14px;
    color: #333;
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    cursor: pointer;

    i {
        margin-left: 3px;
        ${props => props.completed ? 'color: #19B400;' : 'color: #C7C7C7;'}
    }
`;
export const TypeDivision = styled.div`
    width: 1px;
    height: 14px;
    background-color: #333;
    margin-left: 16px;
    margin-right: 16px;
`;

export const Message = styled.div`
    position: absolute;
    ${props => props.type ? `background: rgba(25, 180, 0, 0.7);` : `background-color: rgba(255, 190, 190, 0.3);`}
    top: 0;
    left: 0;
    right: 0;
    margin-top: 5px;
    font-size: 14px;
    padding: 10px;
    width: calc(100% - 5px);
    margin: 0 auto;
    border-radius: 5px;
    z-index: 1;
    color: #333333;
`;