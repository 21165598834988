

export default (loading) => {

    function onClick(setSelectId, setSelectData, selectId) {
        return (e) => {
            if (loading) {
                return
            } else {
                setSelectId((prev) => {
                    if (prev !== selectId) {
                        return selectId
                    } else {
                        setSelectData(null)
                        return null
                    }
                })

            }
        }
    }

    var result = { onClick }
    return result
}