import React from 'react';
import { DialogType } from './type';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function RequestCheckedDialog({ isOpen, onClose }: DialogType) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <Stack>
        <Stack sx={{ pl: 3, pr: 3, pt: 5, textAlign: 'center' }} maxWidth={'400px'} spacing={3}>
          <Typography variant="body1">
            {parse(t('user.dialog.requestChecked'))}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} p={3}>
          <Button
            onClick={onClose}
            variant={'contained'}
            size="large"
            sx={{ flex: 1 }}
          >
            {t('dialog.confirm')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}