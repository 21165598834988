import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Field, InformationCard, Title } from 'acon-mui/components/Board';
import { Container } from 'acon-mui/components';
import { STATUS_DRAFT_ON_OPEN } from '../constants';
import Contents from './contents';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from '../stores';

export default function ProductDetails() {
  const { t } = useTranslation();
  const { isDisp, status, setContentHead, setContentBody, setCategories } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    setContentHead: state.setContentHead,
    setContentBody: state.setContentBody,
    setCategories: state.setCategories,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    contentHead: state.contentHead,
    contentBody: state.contentBody,
    categories: state.categories,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setContentHead(originalData?.contentHead);
      setContentBody(originalData?.contentBody);
      setCategories(originalData?.categories);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };
  return (
    <Field
      className="hubWrite_field_content"
      informationCards={
        <>
          <InformationCard type="notice">{parse(t('document.content.notice'))}</InformationCard>
          <InformationCard marginTop="20px">{t('document.content.information1')}</InformationCard>
          <InformationCard type="notice" marginTop="20px">
            {t('document.content.information2')}
          </InformationCard>
        </>
      }
    >
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.productDetails'))}>
        {t('document.productDetails')}
      </Title>
      <Container marginTop="12px" {...(disabled && { pointerEvents: 'none' })}>
        {/* 본문 */}
        <Contents isChecked={!isCheckboxVisible || isChecked} />
      </Container>
    </Field>
  );
}
