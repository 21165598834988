import React, { useState, useContext, useRef, useEffect } from 'react';
import { Flex, Field, Title, BalloonDescription, Item, Value } from '../../style';
import { useTranslation } from 'react-i18next';
import { Icon } from 'rsuite';
import { ImageType, TypeDivision, Message } from './style';
import DetailImage from './detail';
import ThumbnailImage from './thumbnail';
import context from '../../provider/product';
import { WhiteButton } from 'boards/DetailBoardWrite/component/form';
import { AppContext } from 'app';

export default (() => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const { state, dispatch } = useContext(context);

    // 메세지 
    const [message, setMessage] = useState({
        type: false,
        txt: ''
    });
    // 이미지 유형, true 인 경우 상세페이지 / false 인 경우 썸네일 
    const [imageType, setImageType] = useState(true);

    // 이미지 
    const [image, setImage] = useState('');

    // 상세페이지 크로퍼
    const mainCropperTag = useRef(null);
    // 썸네일 크로퍼
    const thumbnailCropperTag = useRef(null);

    // 상세페이지 완료여부 
    const [isCompleteDetail, setIsCompleteDetail] = useState(false);
    // 썸네일 완료여부
    const [isCompleteThumbnail, setIsCompleteThumbnail] = useState(false);

    useEffect(() => {
        if (!isCompleteDetail && state.mainImageCropInfo)
            setIsCompleteDetail(true);

        if (!isCompleteThumbnail && state.thumbnailImageCropInfo)
            setIsCompleteThumbnail(true);

    }, [state.mainImageCropInfo, state.thumbnailImageCropInfo]);

    // 메세지 표시하기 메소드
    const showMessage = (txt, type) => {
        // type이 정의되지 않았을 경우 
        if (type === undefined)
            type = false;

        // 메세지 표시 
        setMessage({
            type,
            txt
        });

        setTimeout(() => {
            // 2초 뒤 메세지 초기화 
            setMessage({
                ...message,
                txt: ''
            });
        }, 2000);
    };

    // 이미지 다운로드 버튼 클릭 이벤트 처리기 메소드 
    const onClickImageDownload = async () => {
        const { croppedMainImageDataUrl, croppedThumbnailImageDataUrl } = state;

        let a = document.createElement('a');
        a.href = croppedMainImageDataUrl;
        a.download = '메인 이미지';
        a.click();

        a.href = croppedThumbnailImageDataUrl;
        a.download = '썸네일 이미지';
        a.click();
    };

    const setCompleteCallback = () => {
        // 이미지 타입이 메인 이미지이고, 썸네일 이미지가 완료되지 않은경우
        if (imageType && !isCompleteThumbnail)
            setImageType(false);

        // 이미지 타입이 썸네일 이미지이고, 메인 이미지가 완료되지 않은경우
        if (!imageType && !isCompleteDetail)
            setImageType(true);
    };
    const setCallback = () => {
        mainCropperTag && mainCropperTag.current && mainCropperTag.current.cropper && mainCropperTag.current.cropper.enable && mainCropperTag.current.cropper.enable();
        thumbnailCropperTag && thumbnailCropperTag.current && thumbnailCropperTag.current.cropper && thumbnailCropperTag.current.cropper.enable && thumbnailCropperTag.current.cropper.enable();
    };
    const setFileCallback = () => {
        mainCropperTag && mainCropperTag.current && mainCropperTag.current.cropper && mainCropperTag.current.cropper.enable && mainCropperTag.current.cropper.enable();
        thumbnailCropperTag && thumbnailCropperTag.current && thumbnailCropperTag.current.cropper && thumbnailCropperTag.current.cropper.enable && thumbnailCropperTag.current.cropper.enable();

        // 크롭된 메인 이미지 블롭 초기화
        dispatch({
            key: 'croppedMainImage',
            type: 'input',
            value: ''
        });
        // 크롭된 메인 이미지 데이터 url 초기화
        dispatch({
            key: 'croppedMainImageDataUrl',
            type: 'input',
            value: ''
        });

        // 크롭된 썸네일 이미지 블롭 초기화
        dispatch({
            key: 'croppedThumbnailImage',
            type: 'input',
            value: ''
        });
        // 크롭된 썸네일 이미지 데이터 url 초기화
        dispatch({
            key: 'croppedThumbnailImageDataUrl',
            type: 'input',
            value: ''
        });

        // 메인 이미지 크롭정보 초기화 
        dispatch({
            key: 'mainImageCropInfo',
            type: 'input',
            value: ''
        });
        // 크롭 정보 초기화 
        dispatch({
            key: 'thumbnailImageCropInfo',
            type: 'input',
            value: ''
        });

        // 디테일 완료 취소 
        setIsCompleteDetail(false);
        // 썸네일 완료 취소 
        setIsCompleteThumbnail(false);
    };
    const { croppedMainImage, croppedThumbnailImage } = state;

    return (
        <Field>
            <Title>
                <span>{t("representativeImage")}</span>
                {<BalloonDescription>{t('goods.mainImageBallonDescription')}</BalloonDescription>}
            </Title>
            <Flex align="center" type="space-between">
                <Flex align="center" >
                    <ImageType active={imageType} completed={isCompleteDetail} onClick={() => { setImageType(true); }}>
                        {t('goods.detailPageMainImage')}
                        {!state.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                    <TypeDivision />
                    <ImageType active={!imageType} completed={isCompleteThumbnail} onClick={() => { setImageType(false); }}>
                        {t('thumbnail')}
                        {!state.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                </Flex>
                <Flex>
                    {userInfo.isAdmin &&
                        <WhiteButton height="28"
                            onClick={onClickImageDownload}
                            disabled={!croppedMainImage && !croppedThumbnailImage}
                        >
                            {t('downloadImage')}
                        </WhiteButton>
                    }
                </Flex>
            </Flex>
            <Item>
                {<Value>
                    {message.txt && <Message type={message.type}>{message.txt}</Message>}
                    {<DetailImage
                        setCallback={setCallback}
                        croppedImageBlob={state.croppedMainImage}
                        setCroppedImageBlob={value => {
                            dispatch({
                                key: 'croppedMainImage',
                                type: 'input',
                                value
                            })
                        }}
                        croppedImageDataUrl={state.croppedMainImageDataUrl}
                        setCroppedImageDataUrl={value => {
                            dispatch({
                                key: 'croppedMainImageDataUrl',
                                type: 'input',
                                value
                            })
                        }}
                        cropperTag={mainCropperTag}
                        disabled={state.isDisp}
                        image={image}
                        setImage={setImage}
                        isComplete={isCompleteDetail}
                        setComplete={setIsCompleteDetail}
                        isShow={imageType}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                        message={message}
                        showMessage={showMessage}
                    />}
                    {<ThumbnailImage
                        setCallback={setCallback}
                        croppedImageBlob={state.croppedThumbnailImage}
                        setCroppedImageBlob={value => {
                            dispatch({
                                key: 'croppedThumbnailImage',
                                type: 'input',
                                value
                            })
                        }}
                        croppedImageDataUrl={state.croppedThumbnailImageDataUrl}
                        setCroppedImageDataUrl={value => {
                            dispatch({
                                key: 'croppedThumbnailImageDataUrl',
                                type: 'input',
                                value
                            })
                        }}
                        cropperTag={thumbnailCropperTag}
                        disabled={state.isDisp}
                        image={image}
                        setImage={setImage}
                        isComplete={isCompleteThumbnail}
                        setComplete={setIsCompleteThumbnail}
                        isShow={!imageType}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                        showMessage={showMessage}
                    />}
                </Value>}
            </Item>
        </Field>
    );
});