export default (loading, setSelect, setSaveData) => {
    var onChange = function (e) {
        if (loading) {
            return
        } else {
            let value = e.target.value
            setSaveData(prevState => {
                return {
                    templateId: value,
                    templateAuthList: prevState.templateAuthList
                }
            })
            return setSelect(e.target.value)
        }
    }
    return { onChange }
}

