import React, { useState } from 'react';
import { Typography, Box, Stack, Skeleton, Tabs, tabsClasses } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import OriginalForm from './OriginatorForm';
import SettleForm from './SettleForm';
import StoreForm from './StoreForm';
import { UserFormBox } from './styled';
import { ChangePasswordDialog, EditRequestDialog, RequestCheckedDialog, RequestDialog } from './Dialog';
import { useTranslation } from 'react-i18next';
import { useGetPartnerPlanQuery } from 'generated/graphql';

enum InfoTypeEnum {
  Originiator = 'originator',
  Store = 'store',
  Settle = 'settle',
}

type Props = {
  userId?: number;
};

export default function UserForm({ userId }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useGetPartnerPlanQuery();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false);
  const [openRequestCheckedDialog, setOpenRequestCheckedDialog] = useState<boolean>(false);

  const [value, setValue] = useState<InfoTypeEnum>(InfoTypeEnum.Originiator);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    if (newValue === InfoTypeEnum.Store && !data.partnerPlan) {
      setOpenRequestCheckedDialog(true);
      return;
    }
    setValue(newValue);
  };

  const handleShowEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <>
      <RequestDialog
        isOpen={openRequestDialog}
        onClose={() => {
          setOpenRequestDialog(false);
        }}
      />
      <RequestCheckedDialog
        isOpen={openRequestCheckedDialog}
        onClose={() => {
          setOpenRequestCheckedDialog(false);
        }}
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Originiator && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.creatorEditRequest')}
        link="https://forms.gle/RLBfvXjjsn1pGrULA"
        linkText="작가 정보 수정 요청 폼"
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Store && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.storeEditRequest')}
        link="https://forms.gle/RLBfvXjjsn1pGrULA"
        linkText="입점 정보 수정 요청 폼"
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Settle && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.settleEditRequest')}
        link="https://docs.google.com/forms/d/e/1FAIpQLScMAPGdU3yp3SMKh4RUMQNRIHf3KetixcGG7y6AIM_PTOzMOg/viewform?fbzx=6279660984734325168"
        linkText="정산 정보 수정 요청 폼"
      />
      <ChangePasswordDialog isOpen={openChangePasswordDialog} onClose={handleCloseChangePasswordDialog} />
      <Box maxWidth={'576px'} width={'100%'}>
        <Stack alignItems={'center'}>
          <Box mb={5}>
            <Typography variant={'h4'} fontSize={24} fontWeight={700} lineHeight={'36px'} color={'#212B36'}>
              {t('user.mypageTitle')}
            </Typography>
          </Box>
          <TabContext value={value}>
            <Box mb={5}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile={true}
                scrollButtons={true}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    display: 'none',
                  },
                }}
              >
                <Tab label={t('user.creatorInfo')} value={InfoTypeEnum.Originiator} />
                <Tab label={t('user.sellerInfo')} value={InfoTypeEnum.Store} />
                <Tab label={t('user.settleInfo')} value={InfoTypeEnum.Settle} />
              </Tabs>
            </Box>
            <Box width={'100%'}>
              <UserFormBox>
                <TabPanel value={InfoTypeEnum.Originiator}>
                  <OriginalForm
                    userId={userId}
                    onShowChangePasswordDialog={() => {
                      setOpenChangePasswordDialog(true);
                    }}
                    onClickShowDialog={handleShowEditDialog}
                  />
                </TabPanel>
                <TabPanel value={InfoTypeEnum.Store}>
                  <StoreForm userId={userId} onClickShowDialog={handleShowEditDialog} />
                </TabPanel>
                <TabPanel value={InfoTypeEnum.Settle}>
                  <SettleForm userId={userId} onClickShowDialog={handleShowEditDialog} />
                </TabPanel>
              </UserFormBox>
            </Box>
          </TabContext>
        </Stack>
      </Box>
    </>
  );
}
