import styled from 'styled-components';

export const GuideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    bottom: 20px;
    right: 20px;
`;
export const Circle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px;

    width: 56px;
    height: 56px;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
    border-radius: 1000px;

    cursor: pointer;
`;

export const Tooltip = styled.div`
    display: ${({show}) => show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 4px;

    top: -60px;
    right: 15px;
    
    z-index: 1;

    &::after { 
        border-color: #ffffff transparent; 
        border-style: solid; 
        border-width: 8px 6px 0px 6.5px;
        content: ''; 
        display: block; 
        left: 75px; 
        position: absolute; 
        top: 38px; 
        width: 0; 
        z-index: 1; 
    } 
    &::before { 
        border-color: #CDCDCD transparent;
        border-style: solid; 
        border-width: 8px 6px 0px 6.5px;
        content: ''; 
        display: block; 
        left: 75px; 
        position: absolute; 
        top: 39px; 
        width: 0; 
        z-index: 0; 
    }

`;