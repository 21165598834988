import React from 'react';
import { FAQ_ACON_PATHNAME, FAQ_CREATOR_PATHNAME } from 'aconfaq/constants';
import { useParams } from 'react-router-dom';
import { FaqAconWrite } from './faqAcon';
import { FaqCreatorWrite } from './faqCreator';

export const AconFaqWrite = () => {
    const { name } = useParams();

    return (
        <>
            {name === FAQ_ACON_PATHNAME && <FaqAconWrite />}
            {name === FAQ_CREATOR_PATHNAME && <FaqCreatorWrite />}
        </>
    )
};
