import { Button, Checkbox, Stack, TextField } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  TwinkleProductModalBody,
  TwinkleProductModalContainer,
  TwinkleProductModalDescription,
  TwinkleProductModalList,
  TwinkleProductModalListItem,
  TwinkleProductModalListItemWrapper,
} from './TwinkleProductModal.styles';
import { ReactComponent as SearchIcon } from 'acon-mui/icons/icon-search.svg';
import { Typography } from '@mui/material';
import palette from 'theme/palette';
import AconHelper from '../../../../../lib/global';
import debounce from 'lodash/debounce';
import { LanguageCodeEnum, Language_Code, useMyAssetsQuery } from 'generated/graphql';
import _ from 'lodash';
import { ExtendedSellerPromotionAssets, ITwinkleProductModalProps } from './TwinkleProductModal.types';
import { useTranslation } from 'react-i18next';
import { IProduct } from '../TwinkleDiscountList/TwinkleDiscountList.types';
import { EXCHANGE_RATE } from '../TwinkleDiscountList/TwinkleDiscountList.constants';
import { Link } from 'react-router-dom';

const TwinkleProductModal = ({ isShow, remainingQuantity, discountAssetsList, setDiscountAssetsList, onClose }: ITwinkleProductModalProps): React.ReactElement => {
  const { t, i18n } = useTranslation();

  const [searchTitle, setSearchTitle] = useState('');
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [isKeyDown, setIsKeyDown] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [selectedAssetList, setSelectedAssetList] = useState<ExtendedSellerPromotionAssets[]>([]);

  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);

  const { data, loading } = useMyAssetsQuery({
    variables: { language: language.toUpperCase() as Language_Code, isOnPromotion: false, daysAfterReleased: 180, title: !searchTitle.length ? null : searchTitle },
    skip: !isShow,
  });

  const handleOnSearchTextFiled = debounce((e) => {
    setSearchTitle(e.target.value);
    setIsKeyDown(false);
  }, 500);

  const handleOnToggleCheckbox = (index: number) => {
    const result = _.cloneDeep(_.update(assetList, [index, 'isChecked'], (isChecked) => !isChecked));
    setIsIndeterminate(_.some(result, { isChecked: true }));
    setSelectedAssetList(_.filter(result, { isChecked: true }));
  };
  const handleOnToggleAllCheckbox = () => {
    const checked = selectedAssetList.length;
    const result = _.cloneDeep(_.map(assetList, (asset) => ({ ...asset, isChecked: !checked })));
    setIsCheckedAll(!checked);
    setIsIndeterminate(false);
    setSelectedAssetList(_.filter(result, { isChecked: true }));
  };

  const handleOnClose = () => {
    setSelectedAssetList([]);
    setSearchTitle('');
    onClose();
  };
  const handleOnClickSuccess = () => {
    const selectedAssets = assetList.filter((asset) => asset.isChecked).map((asset) => _.omit(asset, 'isChecked'));
    if (remainingQuantity < selectedAssets.length) return alert(t('twinkleSalePage.remainingQuantityAlert'));
    setDiscountAssetsList(selectedAssets);
    handleOnClose();
  };

  useEffect(() => {
    // data가 패칭되었을때 모달이 열려있다면
    if (data?.myAssets && isShow) {
      // lodash chain을 사용,
      const sortedAssets = _.chain(data?.myAssets)
        .map((asset) => {
          // discountAssetsList 배열 내부에 현재 asset과 동일한 아이디를 가진 게 있는지 체크
          const isChecked = _.some(selectedAssetList, { id: asset.id });
          // discountAssetsList 배열 내부에서 현재 asset과 동일한 아이디를 가진 게 있다면 해당 asset을 가져옴
          const matchedSelectedAsset = _.find(selectedAssetList, { id: asset.id });
          // 현재 asset과 동일한 아이디를 가진 게 있다면  asset과 matchedSelectedAsset을 merge하고 isChecked를 추가
          return matchedSelectedAsset ? _.merge({}, asset, matchedSelectedAsset, { isChecked }) : { ...asset, isChecked };
        })
        // merge된 asset들을 id를 기준으로 중복을 제거
        .unionBy(selectedAssetList as IProduct[], 'id')
        // isChecked를 기준으로 내림차순 정렬
        .orderBy(['isChecked', 'title'], ['desc'])
        .value();

      setAssetList(sortedAssets);
    }
  }, [data, isShow, selectedAssetList]);

  useEffect(() => {
    setSelectedAssetList(discountAssetsList.map((asset) => ({ ...asset, isChecked: true })));
  }, [discountAssetsList, isShow]);

  const isKeyUpLoading = loading || isKeyDown;

  return (
    <TwinkleProductModalContainer centered show={isShow} onHide={handleOnClose}>
      <TwinkleProductModalBody>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color={palette.light.text.primary}>
            {t('twinkleSalePage.productSelection')}
          </Typography>
          <Typography variant="subtitle2" color={palette.light.text.primary} fontWeight="400" ml="auto">
            <b>
              {t('twinkleSalePage.selectedQuantity')} {selectedAssetList.length} {t('twinkleSalePage.quantity')}
            </b>
            ({t('twinkleSalePage.remainingQuantity')} : {remainingQuantity})
          </Typography>
        </Stack>
        <TextField
          onKeyUp={handleOnSearchTextFiled}
          onKeyDown={() => setIsKeyDown(true)}
          disabled={false}
          InputProps={{ startAdornment: <SearchIcon style={{ marginRight: '8px' }} /> }}
          placeholder={t('twinkleSalePage.searchProducts')}
          fullWidth
          inputProps={{ maxLength: 30 }}
          size="small"
        />
        <TwinkleProductModalList>
          <TwinkleProductModalListItem isHeader>
            <Checkbox sx={{ padding: 0 }} indeterminate={isIndeterminate} checked={isCheckedAll} onChange={handleOnToggleAllCheckbox} />
            <Typography variant="body2" color={palette.light.text.primary} mr="auto" fontWeight={600}>
              {t('twinkleSalePage.productName')}
            </Typography>
            <Typography variant="body2" color={palette.light.text.primary} fontWeight={600} width="100px" textAlign="left">
              {t('twinkleSalePage.regularPrices')}
            </Typography>
          </TwinkleProductModalListItem>

          <TwinkleProductModalListItemWrapper>
            {isKeyUpLoading ? (
              <TwinkleProductModalListItem>Search ...</TwinkleProductModalListItem>
            ) : (
              <Fragment>
                {!assetList.length ? (
                  <TwinkleProductModalListItem isNotFound>
                    <Typography variant="body2" color={palette.light.grey[800]} textAlign="center" maxWidth="320px">
                      {searchTitle.length ? t('twinkleSalePage.searchNotfound') : t('twinkleSalePage.productNotfound')}
                    </Typography>
                  </TwinkleProductModalListItem>
                ) : (
                  <Fragment>
                    {assetList.map((asset, index) => {
                      return (
                        <TwinkleProductModalListItem key={asset.id}>
                          <Checkbox sx={{ padding: 0 }} onChange={() => handleOnToggleCheckbox(index)} checked={asset.isChecked} />
                          <Typography
                            variant="body2"
                            color={palette.light.text.primary}
                            sx={{
                              mr: 'auto',
                              whiteSpace: 'pre',
                              maxWidth: '330px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {asset.title}
                          </Typography>
                          <Typography variant="body2" color={palette.light.text.primary} width="100px" textAlign="left">
                            {AconHelper.AddCommas(language === LanguageCodeEnum.Ko ? asset.price : asset.price / EXCHANGE_RATE)}
                            {t('twinkleSalePage.priceUnit')}
                          </Typography>
                        </TwinkleProductModalListItem>
                      );
                    })}
                  </Fragment>
                )}
              </Fragment>
            )}
          </TwinkleProductModalListItemWrapper>
        </TwinkleProductModalList>
        <TwinkleProductModalDescription>
          <Typography variant="caption" mb={1}>
            {t('twinkleSalePage.modal.description.title')}
          </Typography>
          <Typography variant="caption" mb={'2px'}>
            {t('twinkleSalePage.modal.description.list1')}
          </Typography>
          {i18n.language === LanguageCodeEnum.Ko && (
            <Typography variant="caption" mb={'2px'}>
              {t('twinkleSalePage.modal.description.list5')}
            </Typography>
          )}
          <Typography variant="caption" mb={'2px'}>
            {t('twinkleSalePage.modal.description.list2')}
          </Typography>
          <Typography variant="caption">
            {t('twinkleSalePage.modal.description.list3')}
            <br />
            &nbsp;&nbsp;&nbsp;{t('twinkleSalePage.modal.description.list4')}
            {language === LanguageCodeEnum.Ko ? (
              ''
            ) : (
              <Fragment>
                <br />
                &nbsp;&nbsp;&nbsp;
              </Fragment>
            )}
            <Link to="/" target="_blank">
              {t('twinkleSalePage.modal.description.link')}
            </Link>
          </Typography>
        </TwinkleProductModalDescription>
        <Button color="primary" size="large" variant="contained" onClick={handleOnClickSuccess}>
          {t('twinkleSalePage.selectedComplete')}
        </Button>
      </TwinkleProductModalBody>
    </TwinkleProductModalContainer>
  );
};

export default TwinkleProductModal;
