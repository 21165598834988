import React, { useContext, useEffect } from 'react';
import Write from './write';
import Diff from './diff';
import { useQuery } from '@apollo/client';
import RcSelect from 'react-select';
import { AppContext } from 'app';
import queryString from 'query-string';
import { FlexWrap } from './style';
import { useParams } from 'react-router-dom';
import { GET_VERSION_LANGUAGE } from 'api/quries';
import { Flex } from 'components/style';
import { ProductProvider } from './provider/product';

export default ({ history }) => {
  const { setBackgroundColor } = useContext(AppContext);
  const { lang, docId } = useParams<{ docId?: string; lang?: string }>();

  useEffect(() => {
    setBackgroundColor('white');
  }, [setBackgroundColor]);

  let major: string | string[] | null = null;
  let minor: string | string[] | null = null;

  // 프로퍼티가 정상적으로 넘어오지 않은경우
  if (history?.location?.search) {
    // url 파라메터 객체
    const urlParamObj = queryString.parse(history.location.search);
    major = urlParamObj.major;
    minor = urlParamObj.minor;
  }
  const isDiff = major !== null && minor !== null;

  const { data } = useQuery(GET_VERSION_LANGUAGE, {
    variables: {
      docId,
      major: Number(major),
      minor: Number(minor),
    },
    skip: !isDiff,
  });

  const onChange = (itemObj) => {
    // 이동 될 경로
    const toPushUrl = `/goods/write/${itemObj.value}/${docId}?major=${major}&minor=${minor}`;
    // 경로 변경
    history.push(toPushUrl);
  };

  const langItems =
    data && data.versionLanguage
      ? [
          { value: 'ko', label: '한국어' },
          { value: 'en', label: 'English' },
          { value: 'cn', label: '中文' },
          { value: 'jp', label: '日本語' },
        ].map((x) => {
          return {
            ...x,
            isDisabled: !data.versionLanguage.includes(x.value),
          };
        })
      : [];

  return (
    <>
      {isDiff && (
        <>
          <Flex style={{ width: `128px`, flexDirection: 'column' }}>
            <RcSelect
              {...{
                value: langItems.find((x) => x.value === lang),
                isMulti: false,
                options: langItems,
                onChange,
              }}
            />
          </Flex>
          <FlexWrap>
            <ProductProvider>
              <Diff major={major} minor={minor} origin={false} />
            </ProductProvider>
            <ProductProvider>
              <Diff major={major} minor={minor} origin={true} />
            </ProductProvider>
          </FlexWrap>
        </>
      )}

      {!isDiff && <Write />}
    </>
  );
};
