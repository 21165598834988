import React, { useState } from 'react';
import { LanguageCodeEnum, PartnerSettleStatusEnum, useGetPartnerQuery } from 'generated/graphql';
import { Skeleton, Stack, Typography, Button, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldsBox, FieldName, FieldValue, FieldGroup } from './styled';
import { UserFormType } from './type';
import { downloadFile } from 'api/index';
import parse from 'html-react-parser';
import { CommonDialog } from 'components/dialog';
import { PATH_USER } from 'routes/paths';
import { useHistory } from 'react-router-dom';

type Props = UserFormType & {
  onClickShowDialog: () => void;
};
export default function SettleForm({ userId, onClickShowDialog }: Props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const { data, loading } = useGetPartnerQuery();

  if (loading || !data) {
    return <Skeleton />;
  }
  const handleTaxAppDownload = () => {
    downloadFile(data.me.settle.limitedTaxApl.id, data.me.settle.limitedTaxApl.fileName);
  };
  const handleBankBookDownload = () => {
    downloadFile(data.me.settle.bankBook.id, data.me.settle.bankBook.fileName);
  };

  const handleIdentificationCardDownload = () => {
    downloadFile(data.me.settle.idCard.id, data.me.settle.idCard.fileName);
  };

  const handleClickLink = () => {
    setIsShowDialog(true);
  };

  const handleClickEditSettle = () => {
    history.push(PATH_USER.settle);
  };

  return (
    <>
      <FieldsBox>
        {data.me.settle.status === PartnerSettleStatusEnum.None && (
          <Stack spacing={3} textAlign={'center'}>
            <Stack spacing={1.5}>
              <Typography variant="body2" fontWeight={500}>
                {parse(t('user.settle.pleaseInputInfo'))}
              </Typography>
              <Link onClick={handleClickLink} variant="body2" sx={{ textDecoration: 'underline' }}>
                {parse(t('user.settle.addDocument'))}
              </Link>
            </Stack>
            <Stack>
              <Button size="large" variant="contained" onClick={handleClickEditSettle}>
                {t('user.settle.inputInfo')}
              </Button>
            </Stack>
          </Stack>
        )}
        {[PartnerSettleStatusEnum.Request, PartnerSettleStatusEnum.Rerequest].includes(data.me.settle.status) && (
          <Stack textAlign={'center'}>
            <Typography variant="body2" fontWeight={500}>
              {parse(t('user.settle.inprogress'))}
            </Typography>
          </Stack>
        )}

        {data.me.settle.status === PartnerSettleStatusEnum.Reject && (
          <Stack spacing={3} textAlign={'center'}>
            <Stack spacing={1.5}>
              <Typography variant="body2" fontWeight={500}>
                {parse(t('user.settle.editInfo'))}
              </Typography>

              <Link onClick={handleClickLink} variant="body2" sx={{ textDecoration: 'underline' }}>
                {parse(t('user.settle.addDocument'))}
              </Link>
            </Stack>
            <Stack>
              <Button size="large" variant="contained" onClick={handleClickEditSettle}>
                {t('user.settle.edit')}
              </Button>
            </Stack>
          </Stack>
        )}

        {data.me.settle.status === PartnerSettleStatusEnum.Approve && (
          <>
            {i18n.language === LanguageCodeEnum.Ko && (
              <>
                <FieldGroup>
                  <FieldName>신분증 사본</FieldName>
                  <Button variant="outlined" size="medium" fullWidth={false} onClick={handleIdentificationCardDownload} sx={{ width: 'fit-content' }}>
                    {t('download')}
                  </Button>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>{t('user.paymentAccount')}</FieldName>
                  <FieldValue>{data.me.settle.bankAccountOwner}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>{t('user.bank')}</FieldName>
                  <FieldValue>{data.me.settle.bank.name}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>{t('user.paymentAccountNumber')}</FieldName>
                  <FieldValue>{data.me.settle.bankAccountNumber}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>통장사본</FieldName>
                  <Button variant="outlined" size="medium" onClick={handleBankBookDownload} sx={{ width: 'fit-content' }}>
                    {t('download')}
                  </Button>
                </FieldGroup>
              </>
            )}
            {i18n.language !== LanguageCodeEnum.Ko && (
              <>
                <FieldGroup>
                  <FieldName>Name(English)</FieldName>
                  <FieldValue>{data.me.name}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Settlement Method</FieldName>
                  <FieldValue>{data.me.settle.bankName.length > 0 && !data.me.settle.bankName.includes('Paypal') ? data.me.settle.bankName : 'Paypal'}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Settlement account</FieldName>
                  <FieldValue>{data.me.settle.bankAccountNumber}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Application of Limited Tax Rate</FieldName>
                  <FieldValue>
                    <Button variant="outlined" size="medium" onClick={handleTaxAppDownload}>
                      {t('download')}
                    </Button>
                  </FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Personal Identification Document</FieldName>
                  <FieldValue>
                    <Button variant="outlined" size="medium" onClick={handleIdentificationCardDownload}>
                      {t('download')}
                    </Button>
                  </FieldValue>
                </FieldGroup>
              </>
            )}
          </>
        )}
      </FieldsBox>
      {data.me.settle.status === PartnerSettleStatusEnum.Approve && (
        <Stack alignItems={'end'} mt={1.5}>
          <Button variant="text" onClick={onClickShowDialog}>
            {t('user.editInfo')}
          </Button>
        </Stack>
      )}
      <CommonDialog
        title={t('user.settle.dialogTitle')}
        contents={i18n.language === LanguageCodeEnum.Ko && '개인 : 신분증 사본, 통장 사본<br />사업자 : 사업자등록증 사본, 통장 사본'}
        isOpen={isShowDialog}
        onClose={() => setIsShowDialog(false)}
      >
        {i18n.language !== LanguageCodeEnum.Ko && (
          <Stack textAlign={'left'}>
            <ol style={{ marginBottom: '0', paddingLeft: '15px' }}>
              <li
                style={{
                  marginBottom: '24px',
                }}
              >
                <Typography variant="body2">
                  Application of Limited Tax Rate&nbsp;
                  <a
                    href="https://acon3d.notion.site/ACON-FWT-Form-Guide-0fffee8b20274b19a99ace79fee01122"
                    target="blank"
                    style={{ color: '#4065F6DE', textDecoration: 'underline' }}
                  >
                    (View Guide &gt;)
                  </a>
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Copy of your ID, passport or TIN document
                  <br /> (Your full name and ID number must be included)
                </Typography>
              </li>
            </ol>
          </Stack>
        )}
      </CommonDialog>
    </>
  );
}
