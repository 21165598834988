import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const exchangeCurrency = (amount: number, lang: string): number => {
  if (!['ko', 'en', 'zh', 'ja'].includes(lang)) {
    console.error("Invalid language, exchange didn't executed");
    return amount;
  }
  if (lang !== 'ko') return amount / 1000;
  return amount;
};
export const asiaSeoulDayjs = (day: string | number | Date | Dayjs): Dayjs => dayjs(day).tz('Asia/Seoul');
