import { AxiosError, AxiosResponse } from 'axios';
import { QueryClient } from 'react-query';
import { IContext, IMutationProps, IVariables } from 'types/common';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
    mutations: {
      onMutate: (data: IMutationProps) => data,
      onSuccess: async (data: AxiosResponse, variables: IVariables, context: IContext) => {
        if (context?.onSuccess) context.onSuccess(data, variables);
        if (context?.key) {
          if (Array.isArray(context.key)) {
            await context.key.forEach((item) => {
              queryClient.invalidateQueries(item, { exact: true });
            });
          } else {
            await queryClient.invalidateQueries(context.key, { exact: true });
          }
        }
      },
      onError: (error: AxiosError, variables: IVariables, context: IContext) => {
        const { onError } = context;
        if (onError) onError(error, variables);
      },
    },
  },
});
export default queryClient;
