import React, { useState, useMemo, useContext } from 'react';
import Event from './event'
import Tree from 'rc-tree';
import { CateTreeWrap } from './style';
import CateTooltip from './tooltip';
import 'rc-tree/assets/index.css';
import './index.scss';
import { useTranslation } from 'react-i18next'
import { CategoryDataContext } from '../../categoryData'

// 카테고리 트리 
export default ((props) => {
    // 툴팁 객체 
    const [tooltipObj, setTooltipObj] = useState({
        left: '0', right: '0', isShow: false
    })
    const [expandedKeys, setExpandedKeys] = useState([])
    const {
        categoryData,
        setCategoryData,
        setIsLoading,
    } = useContext(CategoryDataContext)
    const {
        categories,
        selectCategoryType,
        searchKeyword,
        selectLang,
        categoryState,
        onAddRootCategory
    } = props

    const { t } = useTranslation()
    // - 카테고리 트리 구조에 대하여 표시해줌
    const resultData = useMemo(() => {
        return filterData(selectCategoryType,
            searchKeyword,
            selectLang,
            categoryState,
            categories,
            setExpandedKeys)
    }, [
        selectCategoryType,
        searchKeyword,
        selectLang,
        categoryState,
        categories
    ])

    let {
        onExpand,
        onSelect,
        onRightClick,
        onDragEnter,
        onDrop,
        onDelete,
        onAddCategory
    } = Event(t, resultData, categoryData, setCategoryData, setExpandedKeys, setTooltipObj, setIsLoading)
    var selectId = useMemo(() => {
        if (categoryData) {
            return categoryData.id
        } else {
            return ''
        }
    }, [categoryData]);
    onAddCategory = onAddCategory(onAddRootCategory, selectCategoryType)
    return (
        <>
            <CateTooltip
                onAdd={onAddCategory}
                onDelete={onDelete}
                tooltipObj={tooltipObj}
                setTooltipObj={setTooltipObj}
            ></CateTooltip>
            <CateTreeWrap>
                <Tree
                    treeData={resultData.data}
                    expandedKeys={expandedKeys}
                    selectedKeys={[selectId]}
                    autoExpandParent={false}
                    draggable
                    onExpand={onExpand}
                    onSelect={onSelect}
                    onRightClick={onRightClick}
                    onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    showIcon={false}
                >
                </Tree>
            </CateTreeWrap>
        </>
    )
})

function filterData(selectCategoryType,
    searchKeyword,
    selectLang,
    categoryState,
    categories,
    setExpandedKeys) {

    var _categories = JSON.parse(JSON.stringify(categories));
    var keyMap = {};
    var expandedKeys = [];
    let resultCategories = [];
    
    if (typeof _categories === 'object' && _categories.length > 0) {
        // 검색 및 필터 적용
        // - 대분류 / 언어 / 상태 별로 카테고리를 볼 수 있음
        // - 자식노드의 경우, 부모가 해당 노드에 속해있더라도 자기 자신이 해당 대분류가 없다면 표시되지 않음.
        // - 언어 - 해당 언어에 해당하는 국가별명칭으로 노드가 표시됌
        // - 상태 - 해당 상태에 속해있는 노드만 표시됌.
        // - 자식노드의 경우, 부모가 해당 노드에 속해있더라도 자기 자신이 해당 상태가 없다면 표시되지 않음.

        // 데이터 삽입
        for (const category of _categories) {
            keyMap[category.id] = category;
            category.name = '-';
            // 현재 언어로 표시
            if (category.i18n) {
                var currentI18n = category.i18n.filter(i18n => {
                    return i18n?.language?.code === selectLang;
                });
                if (currentI18n[0]) {
                    category.name = currentI18n[0].name;
                }
            }

            resultCategories.push(category);
        }

        // 대분류 필터
        resultCategories = resultCategories.filter(category => {
            // 선택한 대분류를 포함하는 카테고리만 반환
            return category.type === selectCategoryType;
        });

        // 상태 필터
        resultCategories = resultCategories.filter(category => {
            for (let i = 0; i < categoryState.length; i++) {
                if (category.state.includes(categoryState[i])) {
                    return true;
                }
            }
        });

        // 검색어
        if (searchKeyword) {
            resultCategories = Object.values(keyMap).filter(category => {
                return category.name.includes(searchKeyword);
            });

            const getParents = itemObj => {
                const parents = [];
                // 루프
                const loop = parent => {
                    // 카테고리 항목
                    const item = Object.values(keyMap).find(x => x.id === parent);
                    // 부모 항목이 존재할 경우
                    if (item) {
                        // 부모 개체 조립
                        parents.push(item);
                        // 부모 항목이 존재할 경우
                        if (item.parent)
                            // 부모 루프
                            loop(item.parent);
                    }
                    else
                        // 종료
                        return;
                };
                loop(itemObj.parent);
                // 부모 항목들 반환
                return parents;
            }

            for (var i = 0; i < resultCategories.length; i++) {
                var parents = getParents(resultCategories[i]);
                resultCategories = resultCategories.concat(parents);
            }

            resultCategories = resultCategories.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            expandedKeys.push(...resultCategories.map(category => category.id));
        }

        // 부모 자식 관계 생성
        resultCategories = resultCategories.filter(category => {
            category.key = category.id;
            category.title = category.name;

            if (category.parent) {
                // 부모 항목 객체 
                let parentCategory = resultCategories.find(x => x.id === category.parent);
                // 자식으로 추가
                if (parentCategory.children) {
                    parentCategory.children.push(category);
                } else {
                    parentCategory.children = [category];
                }
            }

            return !category.parent;
        });

        if (expandedKeys.length) {
            setExpandedKeys(expandedKeys);
        }
    }
    return {
        data: resultCategories,
        keyMap: keyMap
    }
}
