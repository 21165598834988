import React from 'react';
import { useUrl } from '../hook/useUrl';
import { useTranslation } from 'react-i18next';

export const TypeCombo = ({ types, value }) => {
    const { push } = useUrl();
    const { t } = useTranslation();

    if (!types) {
        return <></>
    }
    const options = types.map((e, i) => {
        return (
            <option key={i} value={e.id}>
                {e.i18n[0].name}
            </option>
        )
    })
    return (
        <select onChange={(e) => { push({ typeId: e.target.value }) }} value={value}>
            <option value={0}>
                {t("Aconfaq.type.all")}
            </option>
            {options}
        </select>
    )
};