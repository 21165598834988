import React, { useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { AuthDataContext } from '../../authData'
import AuthList from './authList'

export default (function (props) {
    const { select, event, setSaveData } = props
    const { t } = useTranslation()
    const { templateList } = useContext(AuthDataContext)
    const onChange = event.onChange
    const options = useMemo(() => {
        var init = []
        if (!templateList.includes('')) {
            init = [{ _id: '', templateName: t('authManager.allTemplate') }, ...templateList]
        }

        return init.map(d => {
            var opt = {}
            return <option key={d._id} {...opt} value={d._id}>{d.templateName}</option>
        })
    }, [templateList,t])
    return <div>
        <select value={select} onChange={onChange}>
            {options}
        </select>
        <AuthList {...{ setSaveData }}>
        </AuthList>
    </div>
})

