import styled from 'styled-components';

export const List = styled.div`
  max-height: 236px;
  overflow-y: auto;
  border-top: 1px solid #e0e0e0;
  margin-top: 16px;
  font-weight: 400;
`;

export const ListItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #e0e0e0;
  min-height: 40px;
  padding-top: 13px;
  padding-bottom: 13px;
`;

export const NameField = styled.div`
  font-size: 12px;
  width: 60%;
  margin-top: 5px;
  line-height: 100%;
  padding-right: 10px;
  word-break: break-word;
`;

export const Button = styled.div`
  background-color: none;
`;
