import { Page, VividButton } from 'common/style';
import { TextCenter } from 'components/style';
import { FlexColumn, FlexRow } from 'signup/style';
import { CustomText } from 'components/style';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Contents = styled.p`
  font-size: 18px;
  margin: 50px 0;
`;

const BlackButton = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 53px;
  color: #ffffff;

  width: 192px;
  height: 40px;

  background: #333333;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  cursor: pointer;
`;

const Component = (props) => {
  // 번역 도구
  const { t, i18n } = useTranslation();

  return (
    <Page>
      {i18n.language === 'ko' && (
        <>
          <h1>에이콘의 파트너 작가가 되신 것을 진심으로 축하합니다!</h1>
          <Contents>
            가입 승인 검토는 최대 영업일 2일이 소요됩니다.
            <br />
            <br />
            가입시 입력한 이메일로 검토 결과가 발송되며,
            <br />
            파트너 허브의 마이페이지 화면에서도 확인할 수 있습니다.
            <br />
            검토 중 내용 수정, 문의 사항은 <strong>contact@acon3d.com</strong>
            <br />
            으로 메일 부탁드립니다.
            <br />
            <br />
            가입 승인 전에도 작품 글을 작성할 수 있어요!
            <br />
            바로 글을 작성하러 가볼까요?
          </Contents>
          <TextCenter>
            <NavLink to="/">
              <VividButton>글 목록</VividButton>
            </NavLink>
          </TextCenter>
        </>
      )}
      {i18n.language !== 'ko' && (
        <FlexColumn align="center">
          <FlexColumn align="center" marginBottom="80">
            <CustomText size={24} bold>
              Your ACON Partner Creator Application Has Been Received!
            </CustomText>
          </FlexColumn>

          <FlexColumn align="center" marginBottom="80" rowGap="10">
            <CustomText size={16}>{'The review is completed within 2 business days.'}</CustomText>
            <br />
            <br />
            <CustomText size={16}>{'You will receive an email of approval or disapproval to the address you have registered.'}</CustomText>
            <CustomText size={16}>{'You can also check out your status on [My Page].'}</CustomText>
            <CustomText size={16}>{'Please send an email to contact@acon3d.com'}</CustomText>
            <CustomText size={16}>{'for inquiries or revising personal information during review.'}</CustomText>
            <br />
            <br />
            <CustomText size={16}>{'If you are ready, you can start creating your project page before approval.'}</CustomText>
            <CustomText size={16}>{'Shall we take a look around?'}</CustomText>
          </FlexColumn>

          <NavLink to="/goods/write/en">
            <BlackButton>Upload</BlackButton>
          </NavLink>
        </FlexColumn>
      )}
    </Page>
  );
};

export default Component;
