import styled from 'styled-components';
import { Button } from '../style';
export const StyledNormalButton = styled.button.attrs(props => ({
    type: 'button'
}))`
    background-color: inherit; 
    border: 0;
    padding: 0;
    width: 13px;
    height: 10px;
`;
export const StyledWhiteButton = styled(Button)`
    ${props => props.max ? 'width: 100%;' : ''}
    ${props => props.margin ? `margin: ${props.margin};` : ''}
    ${props => props?.disabled ? `opacity: 0.5; pointer-events: none;` : ''}
    color: black;
    background-color: white;
    border: 1px solid #000;
`;
export const StyledVividButton = styled(Button)`
    ${props => props.max ? 'width: 100%;' : ''}
`;
export const StyledBlackButton = styled(Button)`
    ${props => props.max ? 'width: 100%;' : ''}
    color: white;
    background-color: black;
`;
export const StyledFilterButton = styled(StyledNormalButton)`
    ${props => props.max ? 'width: 100%;' : ''}
    background-size: 13px 10px;
    float: right;
    position: absolute;
    ${props => props.isActive ? 'color: rgba(255, 255, 255, 1);' : 'color: rgba(255, 255, 255, 0.5);'}
`;