import { makeStyles } from '@material-ui/core';
import { LanguageCodeEnum } from 'generated/graphql';
import { TFunction } from 'i18next';
import palette from 'theme/palette';
import * as yup from 'yup';

export const TABLE_STYLE = {
  table: {
    '& tr': {
      transition: '0.5s',
      borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      '&:last-of-type': {
        border: 'none',
      },
    },
    '& tr:hover': { background: palette.light.grey[100] },
  },
  tableHead: {
    '& th': {
      p: '0 !important',
      boxShadow: 'none !important',
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
  },
  tableRow: {
    '& td': {
      p: '0 !important',
      boxShadow: 'none !important',
      cursor: 'pointer',
    },
  },
};

export const getTextFieldStyles = makeStyles({
  root: {
    width: '106px',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
  helperText: {
    position: 'absolute',
    top: '100%',
    whiteSpace: 'pre',
    marginLeft: 0,
  },
});
export const getSaleTypeOptions = (t: TFunction) => {
  return [
    { id: 1, label: t('twinkleSalePage.currency') },
    { id: 2, label: '%' },
  ];
};

const decimals = (num) => {
  const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
};

export const EXCHANGE_RATE = 1000;

export const getValidationSchema = (t: TFunction, language) => {
  return yup.object().shape({
    quantity: yup.number(),
    products: yup
      .array()
      .of(
        yup.object().shape({
          assetId: yup.number(),
          currency: yup.string(),
          price: yup.number(),
          discountType: yup.string(),
          discountValue: yup
            .number()
            .test('defaultUnit', function (value) {
              const { price, discountType, discountValue } = this.parent;
              const decimalLength = language === LanguageCodeEnum.Ko ? 0 : 2;
              const minDiscountValue =
                discountType === 'FIXED' ? Number(Number(language === LanguageCodeEnum.Ko ? price : price / EXCHANGE_RATE) * 0.1).toFixed(decimalLength) : 10;
              const maxDiscountValue =
                discountType === 'FIXED' ? Number(Number(language === LanguageCodeEnum.Ko ? price : price / EXCHANGE_RATE) * 0.3).toFixed(decimalLength) : 30;
              const minDiscountMessage = discountType === 'FIXED' ? 'minPrice' : 'minPercent';
              const maxDiscountMessage = discountType === 'FIXED' ? 'maxPrice' : 'maxPercent';
              if (discountValue < minDiscountValue) {
                return this.createError({ message: t(`twinkleSalePage.validation.${minDiscountMessage}`, { price: minDiscountValue }) });
              }
              if (discountValue > maxDiscountValue) {
                return this.createError({ message: t(`twinkleSalePage.validation.${maxDiscountMessage}`, { price: maxDiscountValue }) });
              }
              if (decimals(value) > decimalLength) {
                const msg = decimalLength === 0 ? t('twinkleSalePage.validation.decimal') : t('twinkleSalePage.validation.only2Decimal');
                return this.createError({ message: msg });
              }
              return true;
            })
            .typeError(t('twinkleSalePage.validation.number'))
            .required(t('twinkleSalePage.validation.required')),
        }),
      )
      .when('quantity', (quantity, schema) => {
        return schema.test('quantityProducts', function (value) {
          if (value.length > quantity) {
            return this.createError({ message: 'quantity' });
          }
          return true;
        });
      })
      .required('products is a required field'),
  });
};
