import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  maxLength?: number;
  height?: number;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  maxLength,
  height,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputProps={{
            maxLength,
            ...other.inputProps,
            style: {
              height,
            },
          }}
          {...other}
        />
      )}
    />
  );
}
