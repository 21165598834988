import palette from 'theme/palette';

export const VIEW_COUNT_LIST = [
  { id: 1, label: '10', value: 10 },
  { id: 2, label: '20', value: 20 },
  { id: 3, label: '50', value: 50 },
  { id: 4, label: '100', value: 100 },
];

export const TABLE_STYLE = {
  table: {
    '& tr': {
      transition: '0.5s',
      borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
    },
    '& tr:hover': { background: palette.light.grey[100] },
  },
  tableHead: {
    '& th': {
      p: '0 !important',
      boxShadow: 'none !important',
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
  },
  tableRow: {
    '& td': {
      p: '0 !important',
      boxShadow: 'none !important',
      cursor: 'pointer',
    },
  },
};
export const getTwinkleSaleSetState = (state) => ({ setStatus: state.setStatus });
