import React, { useState, useEffect, ComponentProps } from "react";
import styled from "styled-components";

const TimerContainer = styled.div({
  position: `absolute`,
  right: 12,
  top: 14,
});

interface Props extends ComponentProps<typeof TimerContainer> {
  range: number;
  trigger: number;
  onEnd: () => void;
}
let intervalTimer;

export const SignupTimer: React.FC<Props> = ({ range, trigger, onEnd }) => {
  // 타이머 정의
  const [timer, setTimer] = useState(`00:00`);
  // 타이머 초
  const [timerSeconds, setTimerSeconds] = useState(range);

  useEffect(() => {
    // 지정 되지 않은경우
    if (!range) return;

    // 타이머가 존재하면 타이머를 제거한다.
    intervalTimer && clearInterval(intervalTimer);

    let displaySeconds = timerSeconds;

    intervalTimer = setInterval(() => {
      const seconds = displaySeconds % 60;
      const minute = (displaySeconds - seconds) / 60;

      setTimer(
        `${String(minute).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
      );
      displaySeconds--;

      // 타이머가 종료된 경우
      if (displaySeconds === -1) {
        // 타이머 제거
        clearInterval(intervalTimer);
        // 종료 함수 호출
        onEnd();
      }

      return () => clearInterval(intervalTimer);
    }, 1000);
  }, [trigger]);

  return <div>{timer}</div>;
};
