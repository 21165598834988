import React from 'react';
import { NavLink } from 'react-router-dom';
import { Wrap, TitleWrap, Title, ItemWrap, SkeletonTitle, SkeletonContents } from './style';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_NOTICE_DATA, GET_FAQ_DATA } from '../../api/quries';
import BoardsInfo from '../../boards/BoardsInfo';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Button } from 'acon-mui/components';

const NoticeBoard = (props) => {
    const { loading, error, data } = useQuery(GET_NOTICE_DATA, {
        fetchPolicy: "no-cache",
        variables: { page: 1, limit: 5 }
    });

    const items = data && [ ...data.getNotices ].splice(0, 5);
    
    // 로딩중이거나, 에러가 발생한 경우 
    if (loading || error)
        return (
            <>
                <Wrap>
                    <SkeletonTitle />
                    <SkeletonContents />
                    <SkeletonContents />
                </Wrap>
            </>
        );

    return (
        <BoardsInfo
            {...props}
            items={items}
            type="short"
            boardName="notice"
        />
    );
}

const FaqBoard = (props) => {
    const { loading, error, data } = useQuery(GET_FAQ_DATA, {
        fetchPolicy: "no-cache",
        variables: { page: 1, limit: 5 }
    });

    const items = data && [ ...data.getFaqs ].splice(0, 5);
    
    // 로딩중이거나, 에러가 발생한 경우 
    if (loading || error)
        return (
            <>
                <Wrap>
                    <SkeletonTitle />
                    <SkeletonContents />
                    <SkeletonContents />
                </Wrap>
            </>
        );

    return (
        <BoardsInfo
            {...props}
            items={items}
            type="short"
            boardName="faq"
        />
    );
}

export default (props) => {
    const { t } = useTranslation();

    return (
        <Box 
            width="100%"
            py="10px"
            mt="5px"
            mb="20px"
            display="flex"
            justifyContent="space-between"
            {...isMobile && { flexDirection: 'column' }}
        >
            <Box py="10px" {...!isMobile && { width: '48%' }}>
                <TitleWrap>
                    <Title>{t("BoardTitles.notice")}</Title>
                    {!isMobile && (<NavLink to="/board/notice">{t("seeMore")}</NavLink>)}
                </TitleWrap>
                <NoticeBoard {...props}/>
                {isMobile && (
                    <NavLink to="/board/notice">
                        <Button variant='contained' fontWeight="700" marginTop="24px">
                            {t("seeMore")}
                        </Button>
                    </NavLink>
                )}
            </Box>
            <Box py="10px" {...!isMobile && { width: '48%' }}>
                <TitleWrap>
                    <Title>{t("BoardTitles.faq")}</Title>
                    {!isMobile && (<NavLink to="/board/faq">{t("seeMore")}</NavLink>)}
                </TitleWrap>
                <FaqBoard {...props}/>
                {isMobile && (
                    <NavLink to="/board/faq">
                        <Button variant='contained' fontWeight="700" marginTop="24px">
                            {t("seeMore")}
                        </Button>
                    </NavLink>
                )}
            </Box>
        </Box>
    );
};