import React, { useState, useContext } from 'react';

// import { useTranslation } from 'react-i18next';
import TemplateList from './templateList'
import { AuthDataContext } from '../authData'
import Event from './event'
import TemplateInput from './templateInput'

export default (function (props) {
    // const { t } = useTranslation()
    const [select, setSelect] = useState('')
    const [saveData, setSaveData] = useState({
        templateId: '',
        templateAuthList: {}
    })
    const { loading, addList } = useContext(AuthDataContext)
    const event = Event(loading, setSelect, setSaveData)
    let render
    if (addList && addList.length > 0) {
        render = <>
            <TemplateList {...{ select, event, setSaveData }}></TemplateList>
            <TemplateInput {...{ select, saveData }}></TemplateInput>
        </>
    }
    return <div>
        {render}
    </div>
})

