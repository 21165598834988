import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BrandSaveWrap, BrandTitleWrap, Title } from '../../index.styles';
import { BrandProfileContainer } from './BrandProfile.styles';
import { Button } from 'acon-mui/components';
import { useTranslation } from 'react-i18next';
import ImageUpload from './components/ImageUpload/ImageUpload';
import { useMyBrandQuery, useStoreBrandPhotoMutation } from 'generated/graphql';
import useUpload from 'hooks/useUpload';

const BrandProfile = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const imageUploadRef = useRef<HTMLInputElement>(null);
  const lang = useMemo(() => {
    let lang = localStorage.getItem('i18nextLng');
    if (!lang) lang = i18n.language;
    return lang;
  }, [i18n.language]);
  const { upload } = useUpload('brand');
  const { data, loading, refetch } = useMyBrandQuery();

  const brand = data?.myBrand;

  const [storeBrandPhoto] = useStoreBrandPhotoMutation({
    onError: (error) => alert(error.message),
  });

  const handleOnClickImageUpload = () => imageUploadRef.current && imageUploadRef.current.click();
  const handleOnDeleteImageUpload = (e) => {
    e.stopPropagation();
    setFile(null);
    setPreviewURL(null);
  };
  const handleOnChangeImageUpload = async (e) => {
    e.preventDefault();
    if (imageUploadRef.current && imageUploadRef.current.files) {
      const newFile = imageUploadRef.current.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFile(newFile);
        setPreviewURL(reader.result);
      };
      if (newFile) reader.readAsDataURL(newFile);
    }
  };

  const handleClickSave = async () => {
    try {
      if (!file) return;

      const uploadFile = await upload(file, () => {});
      if (!uploadFile) return;
      await storeBrandPhoto({
        variables: {
          photo: {
            key: uploadFile.azureStoragePath,
            name: uploadFile.fileName,
          },
        },
      });
    } catch (error) {
      alert(error);
    } finally {
      refetch();
    }
  };

  useEffect(() => {
    if (brand && brand.photo?.azureStoragePath) {
      setPreviewURL(brand.photo?.azureStoragePath);
    }
  }, [brand]);

  if (loading && !brand) return <>...loading</>;

  return (
    <BrandProfileContainer>
      <BrandTitleWrap>
        <Title>{t('BrandPage.profile.title', { lng: lang })}</Title>
      </BrandTitleWrap>
      <ImageUpload
        fileTag={imageUploadRef}
        previewUrl={previewURL}
        onClick={handleOnClickImageUpload}
        onChange={handleOnChangeImageUpload}
        onDelete={handleOnDeleteImageUpload}
        accept="image/jpg,impge/png,image/jpeg,.png,.jpg,.jpeg"
      />
      <BrandSaveWrap>
        <Button colorTheme="primary" onClick={handleClickSave} minWidth="71px" width="fit-content" marginLeft="12px" borderRadius="4px">
          {t('BrandPage.brandPick.save', { lng: lang })}
        </Button>
      </BrandSaveWrap>
    </BrandProfileContainer>
  );
};

export default BrandProfile;
