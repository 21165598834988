import { FAQ_ACON_PATHNAME, FAQ_CREATOR_PATHNAME } from 'aconfaq/constants';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Styled from './styled';

export default (() => {
    const history = useHistory();

    const links = [FAQ_ACON_PATHNAME, FAQ_CREATOR_PATHNAME];
    const { name } = useParams();

    const onClick = (pathname) => {
        return () => {
            history.push({
                pathname: `/aconfaq/${pathname}`
            })
        };
    };
    return (
        <Styled.Wrapper>
            <Styled.Item onClick={onClick(links[0])} active={name === links[0]}>일반 문의</Styled.Item>
            <Styled.Item onClick={onClick(links[1])} active={name === links[1]}>입점 문의</Styled.Item>
        </Styled.Wrapper>
    );
});