import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import RcSelect from 'react-select';

// 노드 정보 
export default props => {
    const { i18n } = useTranslation()
    const { type, categoryTypeList, onChangeType } = props;
    const userLang = i18n.language;

    const categoryTypeEl = useMemo(() => {
        var tempList = categoryTypeList.map(d => {
            var value = d.id
            var label = '-'
            if (d.lang[userLang]) {
                label = d.lang[userLang]
            }

            return { value, label }
        })

        // TODO noOptionsMessage 변경 필요.
        return <RcSelect
            {...{
                placeholder: '분류를 선택하시오.',
                value: tempList.find(x => x.value === type),
                isMulti: false,
                options: tempList,
                onChange: onChangeType, 
                isClearable: false,
                noOptionsMessage: () => {
                    return 'test'
                }
            }}
        />
    }, [categoryTypeList, type, onChangeType, userLang]);

    return (
        <>
            { categoryTypeEl}
        </>
    );
}