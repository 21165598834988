import React from 'react';
import View from './view';
import CategoryData from './categoryData'
import { useTranslation } from 'react-i18next'

export default props => {
    const { i18n } = useTranslation()
    // 사용자 언어 
    const userLang = i18n.language
    // useEffect(() => {
    //     // 배경색 흰색 
    //     setBackgroundColor('white');
    //     // 초기화 
    //     // init();
    // }, [setBackgroundColor]);
    return (
        <CategoryData userLang={userLang}>
            <View />
        </CategoryData>
    )
}