import { Box, Typography } from '@mui/material';
import React from 'react';

const getStyle = (color) => {
  switch (color) {
    case 'green':
      return {
        color: '#1B806A',
        background: '#36B37E29',
      };
    case 'yellow':
      return {
        color: '#B76E00',
        background: '#FFAB0029',
      };
    case 'red':
      return {
        color: '#B71D18',
        background: '#FF563029',
      };
    case 'blue':
      return {
        color: '#1939B7',
        background: '#3366FF29',
      };
    case 'sky-blue':
      return {
        color: '#006C9C',
        background: '#00B8D929',
      };
    case 'black':
      return {
        color: '#212B36',
        background: 'rgba(145, 158, 171, 0.16)',
      };
  }
};
export type TLabelColor = 'green' | 'yellow' | 'red' | 'blue' | 'sky-blue' | 'black';

export default function Label({ color, text, ...rest }: { color: TLabelColor; text: string; [key: string]: unknown }) {
  return (
    <Box p="2px 8px" width="fit-content" borderRadius="6px" sx={getStyle(color)} {...rest}>
      <Typography fontWeight="700" fontSize="12px" lineHeight="20px" sx={{ wordBreak: 'keep-all' }}>
        {text}
      </Typography>
    </Box>
  );
}
