import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { TwinkleTicketContainer } from './TwinkleTicket.styles';
import palette from 'theme/palette';
import dayjs from 'dayjs';
import { ITwinkleTicketProps } from './TwinkleTicket.types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageCodeEnum, useHaveRegisteredPromotionQuery } from 'generated/graphql';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';
import { getTwinkleSaleSetState } from './TwinkleTicket.constants';

const TwinkleTicket = ({ promotion }: ITwinkleTicketProps): React.ReactElement => {
  const { id, status, since, until, availability } = promotion;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);
  const { setId, setStatus, setSince, setUntil, setAvailability } = useTwinkleSaleStore(getTwinkleSaleSetState);

  const { data: haveRegisteredPromotionData, loading: haveRegisteredPromotionLoading } = useHaveRegisteredPromotionQuery({
    variables: {
      haveRegisteredPromotionId: id,
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  // 오픈날짜로부터 1주일 전 날짜 계산
  const applyStartDate = dayjs(since).subtract(7, 'day');
  // 신청 이력 여부
  const isHaveRegistered = haveRegisteredPromotionData?.haveRegisteredPromotion || false;
  // 신청 잔여수량이 0보다 큰지여부
  const isPossibleQuantity = useMemo<boolean>(() => availability.available > 0, [availability.available]);
  // 신청 가능 날짜(활성화 된 오픈일로부터 1주일 이내)인지 여부
  const isPossibleDate = useMemo<boolean>(() => (dayjs().isAfter(applyStartDate) || dayjs().isSame(applyStartDate)) && dayjs().isBefore(dayjs(since)), [applyStartDate, since]);
  // false 신청이력 없다 // false 신청 잔여수량이 0이 아니다 // true 신청 가능 날짜다 --> 해당할 때 신청 가능하다.
  const isPossible = Boolean(!isHaveRegistered && isPossibleQuantity && isPossibleDate);

  const handleOnClickApplication = () => {
    if (!isPossible) return;
    setId(id);
    setStatus(status);
    setSince(since);
    setUntil(until);
    setAvailability(availability);
    history.push(`/manager/promotion/twinkle-sale/write/${id}`);
  };

  const getButtonTextBasedOnStatus = useCallback(() => {
    if (isHaveRegistered) {
      return (
        <Typography variant="subtitle1" fontWeight={600}>
          {t('twinkleSalePage.completedApplication')}
        </Typography>
      );
    } else if (isPossibleQuantity) {
      return (
        <Fragment>
          {/* 준비중 */}
          <Typography variant="subtitle1" fontWeight={600}>
            {t('twinkleSalePage.preparing')}
          </Typography>
          <Typography variant="caption" fontWeight={600} sx={{ wordBreak: 'keep-all' }}>
            {dayjs(since).subtract(1, 'week').locale(language).format('M/DD(ddd) A h:mm')} {t('twinkleSalePage.open')}
          </Typography>
        </Fragment>
      );
    } else {
      return (
        <Typography variant="subtitle1" fontWeight={600}>
          {t('twinkleSalePage.applicationClosed')}
        </Typography>
      );
    }
  }, [isHaveRegistered, isPossibleQuantity, language, since, t]);

  if (haveRegisteredPromotionLoading) return <></>;

  return (
    <TwinkleTicketContainer>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Stack direction="column" gap={1} flexShrink="0" mr={7}>
          <Typography variant="subtitle2" color={palette.light.text.secondary}>
            {t('twinkleSalePage.discountPeriod')}
          </Typography>
          <Typography variant="h4" color={palette.light.text.primary} whiteSpace="pre" maxWidth="465px">
            {dayjs(since).locale(language).format('M/DD(ddd) A h:mm')} - {dayjs(until).locale(language).format('M/DD(ddd) A h:mm')}
          </Typography>
        </Stack>
        <Stack direction="column" gap={1} mr="auto">
          <Typography variant="subtitle2" color={palette.light.text.secondary} whiteSpace="pre">
            {t('twinkleSalePage.productsAvailable')}
          </Typography>
          <Typography variant="h4" color={palette.light.text.disabled} fontWeight={400}>
            <Box component="span" color={isPossible ? palette.light.primary.main : isPossibleQuantity ? palette.light.grey[800] : palette.light.orange[0]} fontWeight={700}>
              {availability.available}
            </Box>
            /{availability.total}
          </Typography>
        </Stack>
      </Stack>
      <Button
        color="primary"
        variant={isPossible ? 'contained' : isPossibleQuantity ? 'outlined' : 'contained'}
        sx={{ width: '153px', minHeight: '70px', borderWidth: isPossible ? 1 : 1.5 }}
        disabled={isHaveRegistered || !isPossibleQuantity}
        onClick={handleOnClickApplication}
      >
        <Stack direction="column">
          {isPossible ? (
            <Typography variant="subtitle1" fontWeight={600}>
              {/* 신청 가능 */}
              {t('twinkleSalePage.apply')}
            </Typography>
          ) : (
            getButtonTextBasedOnStatus()
          )}
        </Stack>
      </Button>
    </TwinkleTicketContainer>
  );
};

export default TwinkleTicket;
