import { GET_DOWNLOAD_LOGS } from '../api/quries';
import { INCREASE_DOWNLOAD_COUNT } from '../api/mutations';
import { format } from 'date-fns';
import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../app';
import { Select, MenuItem, TextField, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Backdrop, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './download.scss';
import { Flex, MenuBar } from './style';

export default (props) => {
    const { t } = props;
    const { showAlertMessage, showConfirmMessage } = useContext(AppContext);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [type, setType] = useState('orderNo');
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [increaseDownloadCount] = useMutation(INCREASE_DOWNLOAD_COUNT);

    const getRemainCount = (row) => {
        const givenCount = row.givenCount;
        const logCount = row.logs.filter(x => x.isDeduction === true).length;

        return givenCount - logCount;
    };
    const getLastDownloadedDate = (row) => {
        if (row.created.includes('1970-01-01')) return '-';
        if (row.logs.length === 0) return format(new Date(row.created), 'yyyy-MM-dd HH:mm:ss');
        return format(new Date(row.logs[row.logs.length - 1].created), 'yyyy-MM-dd HH:mm:ss');
    }
    const columns = [
        { id: 'userId', label: '유저 ID', minWidth: 200 },
        { id: 'userType', label: '유저 타입', minWidth: 100 },
        { id: 'orderNo', label: '주문번호', minWidth: 150 },
        { id: 'goodsNo', label: '상품번호', minWidth: 100 },
        { id: 'givenCount', label: '남은 횟수', minWidth: 100, format: (row) => getRemainCount(row), align: 'center' },
        { id: 'created', label: '마지막 다운로드', minWidth: 100, format: (row) => getLastDownloadedDate(row), align: 'center' },
        { id: 'actions', label: '메뉴', minWidth: 150, align: 'center' },
    ];
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));
    const classes = useStyles();

    const handleChange = (event) => {
        setType(event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSearchInput = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };
    const getSearchValue = () => {
        if (type === 'goodsNo') {
            return Number(searchText);
        }
        return searchText;
    };
    const onClickDownloadReset = async (row) => {
        try {
            const id = row.id;
            if (!id) {
                throw new Error('id를 찾을 수 없음');
            }

            setIsLoading(true);
            await increaseDownloadCount({
                variables: { id }
            });
            await refetch();

            showAlertMessage('안내', '완료되었습니다.');
        } catch (err) {
            showAlertMessage('오류', `내부 오류가 발생했습니다(${err.message}) 문의 바랍니다.`);
        } finally {
            setIsLoading(false);
        }

    };

    const query = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
    };
    if (searchText) {
        query[type] = getSearchValue();
    }
    const { data, loading, error, refetch } = useQuery(GET_DOWNLOAD_LOGS, {
        variables: query,
        fetchPolicy: 'cache-and-network'
    });

    return (
        <>
            <Flex className="download-log-page" direction="column">
                <Backdrop className={classes.backdrop} open={loading || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h4>로그 검색</h4>
                <MenuBar direction="row">
                    <Select
                        id="search-type-select"
                        value={type}
                        onChange={handleChange}
                    >
                        <MenuItem value={'orderNo'}>{'주문번호'}</MenuItem>
                        <MenuItem value={'goodsNo'}>{'상품번호'}</MenuItem>
                        <MenuItem value={'userId'}>{'유저 ID'}</MenuItem>
                    </Select>
                    <TextField id="standard-basic" label="검색어 입력" onChange={handleSearchInput} />
                </MenuBar>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && !error && data.getDownloadLogs.downloadCounts.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        let value = row[column.id];
                                        if (column.id === 'actions') {
                                            value = (
                                                <Flex direction="row">
                                                    <Button variant="outlined" onClick={() => { onClickDownloadReset(row) }}>{'다운로드 횟수 초기화'}</Button>
                                                </Flex>
                                            );
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format ? column.format(row) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {!loading && <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={data.getDownloadLogs.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Flex>
        </>
    );
};
