export default function downloadImg(src: string, fileName: string) {
  let image = new Image();
  image.crossOrigin = "anonymous";
  image.src = src;
  image.onload = function() {
    let canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext('2d').drawImage(image, 0, 0);
    
    let a = document.createElement('a');
    a.href = canvas.toDataURL();
    a.download = fileName;
    a.click();
  };
};