import React from 'react';
import { ImageDeleteButton, ImageUploadContainer, Input } from './ImageUpload.styles';
import CameraIcon from 'assets/icon_camera';
import WastebasketIcon from 'assets/icon_wastebasket';

interface IImageUploadProps {
  accept?: string;
  previewUrl?: string;
  onChange: (e?) => void;
  onClick?: () => void;
  onDelete?: (e?) => void;
  fileTag: React.MutableRefObject<HTMLInputElement>;
}
const ImageUpload = React.forwardRef(
  ({ accept = 'image/jpg,impge/png,image/jpeg,.png,.jpg,.jpeg', previewUrl, onChange, onClick, onDelete, fileTag }: IImageUploadProps, ref): React.ReactElement => {
    return (
      <ImageUploadContainer previewUrl={previewUrl} onClick={onClick}>
        <CameraIcon className="icon-camera" size="large" color="#919EAB" sx={{ zIndex: 1, display: previewUrl ? 'none' : 'block' }} />
        <Input ref={fileTag} type="file" onChange={onChange} accept={accept} />
        {Boolean(previewUrl) && (
          <ImageDeleteButton onClick={onDelete}>
            <WastebasketIcon className="icon-wastebasket" size="medium" color="#ffffff" />
          </ImageDeleteButton>
        )}
      </ImageUploadContainer>
    );
  },
);

export default ImageUpload;
