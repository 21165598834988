import React, { useContext } from 'react'
import { AlertContext } from '../../../../../common/AlertModal2'
import { ConfirmContext } from '../../../../../common/ConfirmModal2'
import { TemplateDataContext } from '../../templateData'
import { useTranslation } from 'react-i18next';
import Ajax from '../../ajax'
export default (function (props) {
    const { t } = useTranslation()
    const { data } = props
    const { selectData, setSelectData, setTemplateList, setSelectId } = useContext(TemplateDataContext)
    const openConfirm = useContext(ConfirmContext)
    const openAlert = useContext(AlertContext)
    var render = (<div></div>)
    if (data) {
        const ajax = Ajax(t, openAlert)
        var saveOpt = {
            disabled: false,
            onClick: function (e) {
                var temp = setTemplateList
                openConfirm({
                    title: '저장', body: "저장할꺼냥?", confirmCallback: () => {
                        temp(null)
                        openAlert("저장됨.")
                    }
                })
            }
        }
        var modOpt = {
            disabled: false,
            onClick: function (e) {
                openConfirm({
                    title: '수정', body: "수정할꺼냥?", confirmCallback: async () => {
                        console.log(data)
                        var result = await ajax.updateTemplate(data)
                        if (result && result.resultCode === 1) {
                            setSelectData(null)
                            setTemplateList(null)
                            setSelectId(result.data)
                            openAlert("수정됨.")
                        }
                        // setTemplateList(null)

                    }
                })
            }
        }
        var copyOpt = {
            disabled: false,
            onClick: function (e) {
                openConfirm({
                    title: '복사', body: "복사할꺼냥?", confirmCallback: async () => {
                        var result = await ajax.copyTemplate(data._id)
                        if (result && result.resultCode === 1) {
                            setSelectId(result.data)
                            setSelectData(null)
                            setTemplateList(null)
                            openAlert("복사됨.")
                        }

                    }
                })
            }
        }
        var deleteOpt = {
            disabled: false,
            onClick: function (e) {
                openConfirm({
                    title: '삭제', body: "삭제할꺼냥?", confirmCallback: async () => {
                        var result = await ajax.deleteTemplate(data._id)
                        if (result && result.resultCode === 1) {
                            setSelectId(null)
                            setSelectData(null)
                            setTemplateList(null)
                            openAlert("삭제됨.")
                        }
                    }
                })
            }
        }
        var applyOpt = {
            disabled: true,
            onClick: function (e) {
                openConfirm({
                    title: '권한일괄적용', body: "적용할꺼냥?", confirmCallback: async () => {
                        var result = await ajax.applyTemplate(data._id)
                        if (result && result.resultCode === 1) {
                            // setSelectId(null)
                            // setSelectData(null)
                            // setTemplateList(null)
                            openAlert("적용됨")
                        }
                    }
                })
            }

        }
        if (JSON.stringify(selectData) === JSON.stringify(data)) {
            modOpt.disabled = saveOpt.disabled = true
            applyOpt.disabled = false
        }
        if (selectData && selectData._id) {
            saveOpt.className = 'hide'
            modOpt.className = ''
            copyOpt.className = ''
        } else {
            saveOpt.className = ''
            modOpt.className = 'hide'
            copyOpt.className = 'hide'
        }
        render = (<div>
            <button {...saveOpt}>저장</button>
            <button {...copyOpt}>복사</button>
            <button {...modOpt}>수정</button>

            <button {...applyOpt}>사용자 권한 적용</button>
            <button {...deleteOpt}>삭제</button>
        </div>)
    }
    return render
});

