import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Label, PageNavigation, SelectBox } from 'acon-mui/components';
import React, { useMemo } from 'react';
import { TwinkleListContainer } from './TwinkleList.styles';
import { getTwinkleSaleSetState, TABLE_STYLE, VIEW_COUNT_LIST } from './TwinkleList.constants';
import { LanguageCodeEnum, useBrandPromotionsQuery } from 'generated/graphql';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';

const TwinkleList = (): React.ReactElement => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const urlParams = QueryString.parse(history?.location?.search);
  const { page = 1, limit = 10 } = urlParams;
  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);
  const { setStatus } = useTwinkleSaleStore(getTwinkleSaleSetState);

  const { data: brandPromotionsData, loading: brandPromotionsLoading } = useBrandPromotionsQuery({
    variables: { page: Number(page), limit: Number(limit) },
    fetchPolicy: 'no-cache',
  });

  const brandPromotions = brandPromotionsData?.brandPromotions?.data || [];
  const brandPromotionsTotalCount = brandPromotionsData?.brandPromotions?.pagination?.total || 0;

  const handleOnViewCountChange = (e: React.ChangeEvent<{ value: number }>) => {
    const value = VIEW_COUNT_LIST.find((x) => x.id === e.target.value).label;
    const query = QueryString.stringify({ ...urlParams, limit: value });
    history.push({ pathname: history.location.pathname, search: query });
  };

  const handleOnViewDetail = (id, status) => {
    setStatus(status);
    history.push(`/manager/promotion/twinkle-sale/detail/${id}`);
  };

  return (
    <TwinkleListContainer>
      <Table sx={TABLE_STYLE.table}>
        <TableHead sx={TABLE_STYLE.tableHead}>
          <TableRow>
            <TableCell variant="head">
              <Box sx={{ pl: 7, py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre">
                  {t('twinkleSalePage.discountPeriod')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="134px">
              <Box sx={{ py: 2, mr: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre">
                  {t('twinkleSalePage.productRequested')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="100px">
              <Box sx={{ py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre" textAlign="center">
                  {t('twinkleSalePage.status')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="40px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {brandPromotions.map((brandPromotion) => {
            const { id, promotion, items, createdAt, canceledAt } = brandPromotion;
            let promotionStatus = null;

            if (createdAt && canceledAt) promotionStatus = 'cancel';
            else if (createdAt && !canceledAt && dayjs().isBefore(dayjs(promotion.since))) promotionStatus = 'ready';
            else if (createdAt && dayjs().isAfter(promotion.since) && dayjs().isBefore(promotion.until)) promotionStatus = 'inPrograss';
            else if (dayjs().isAfter(promotion.until)) promotionStatus = 'ended';
            return (
              <TableRow key={id} sx={TABLE_STYLE.tableRow} onClick={() => handleOnViewDetail(id, promotionStatus)}>
                <TableCell>
                  <Box sx={{ pl: 7, py: 6 }}>
                    <Typography variant="subtitle2">
                      {dayjs(promotion.since).locale(language).format('M/DD(ddd) A h:mm')} - {dayjs(promotion.until).locale(language).format('M/DD(ddd) A h:mm')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell width="134px">
                  <Box sx={{ py: 6 }}>
                    <Typography variant="subtitle2">
                      {items.length}
                      {t('twinkleSalePage.quantity')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell width="100px">
                  <Box display="flex" justifyContent="center">
                    {promotionStatus === 'inPrograss' && <Label color="green" text={t('twinkleSalePage.label.inProgress')} />}
                    {promotionStatus === 'ready' && <Label color="blue" text={t('twinkleSalePage.label.ready')} />}
                    {promotionStatus === 'cancel' && <Label color="red" text={t('twinkleSalePage.label.cancel')} />}
                    {promotionStatus === 'ended' && <Label color="black" text={t('twinkleSalePage.label.end')} />}
                  </Box>
                </TableCell>
                <TableCell width="40px" />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!brandPromotions.length && (
        <Box p={2} borderBottom="1px solid #ddd">
          <Typography variant="subtitle2" textAlign="center">
            {t('twinkleSalePage.noApplicationDetails')}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" px="24px">
        <Typography ml="auto" mr="20px">
          {t('twinkleSalePage.countPerPage')}
        </Typography>
        <SelectBox
          defaultValue={VIEW_COUNT_LIST.find((x) => x.label === limit)?.id || 1}
          options={VIEW_COUNT_LIST}
          onSelect={handleOnViewCountChange}
          isBorder={false}
          width="80px"
          padding="0"
          marginRight="16px"
        />
        {!brandPromotionsLoading && brandPromotions.length ? (
          <PageNavigation totalCnt={brandPromotionsTotalCount} limit={limit} pathName={'/manager/promotion/twinkle-sale'} history={history} />
        ) : null}
      </Box>
    </TwinkleListContainer>
  );
};

export default TwinkleList;
