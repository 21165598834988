import React from 'react'
import BenefitItem from './benefitItem';
import CouponItem from './couponItem';
import PageNavigation from '../../../components/pageNavigation';
import { Table, TBody, Th, THead, Tr } from '../../../components/table';
import { Page, Wrap, Title, Half, TabContainer, Tab } from '../style';
import QueryString from 'query-string';
import { GET_BURDEN } from '../../../api/quries';
import { useQuery } from '@apollo/client';

export default ((props) => {
    // 현재 페이지 타입을 가져옵니다.
    const burdenDisplayLocation = props.match.params.displayLocation || "brandBurden";
    // url 매개변수 객체 
    let urlParamObj = (() => {
        let obj = {
            benefitPage: 1,
            couponPage: 1,
            benefitLimit: 30,
            couponLimit: 30
        };

        // search 값이 존재할 경우 
        if (props.history?.location?.search) {
            // url 파라메터 객체 
            const urlParamObj = QueryString.parse(props.history.location.search);

            obj.couponPage = urlParamObj.couponPage ? Number(urlParamObj.couponPage) : obj.couponPage;
            obj.benefitPage = urlParamObj.benefitPage ? Number(urlParamObj.benefitPage) : obj.benefitPage;
            obj.benefitLimit = urlParamObj.benefitLimit ? Number(urlParamObj.benefitLimit) : obj.benefitLimit;
            obj.couponLimit = urlParamObj.couponLimit ? Number(urlParamObj.couponLimit) : obj.couponLimit;
        }
        return obj;
    })();

    const { loading, data, refetch } = useQuery(GET_BURDEN, {
        variables: urlParamObj
    });

    return (
        <>
            <Half>
                <Title>혜택관리</Title>
                <Table>
                    <THead>
                        <Tr>
                            <Th>GD#</Th>
                            <Th>혜택명</Th>
                            <Th>브랜드 부담도</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {data && data.getBenefitBurdenList && data.getBenefitBurdenList.map((x, i) =>
                            <BenefitItem key={i} {...{ ...x, refetch }} />
                        )}
                    </TBody>
                </Table>
                <Wrap style={{width: "100%", textAlign: "center", float: "left", marginTop: "20px", marginBottom: "50px"}}>
                    {data && data.getBenefitBurdenCount &&
                        <PageNavigation
                            match={props.match}
                            totalPage={Math.ceil(data.getBenefitBurdenCount / urlParamObj.benefitLimit)}
                            pathName={`/manager/benefit/${burdenDisplayLocation}`}
                            history={props.history}
                            pageName={"benefitPage"}
                            secPathName={`/manager/benefit/${burdenDisplayLocation}`}
                        />
                    }
                </Wrap>
            </Half>
            <Half>
                <Title>쿠폰관리</Title>
                <Table>
                    <THead>
                        <Tr>
                            <Th>#</Th>
                            <Th>쿠폰명</Th>
                            <Th>브랜드 부담도</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {data && data.getCouponBurdenList && data.getCouponBurdenList.map((x, i) =>
                            <CouponItem key={i} {...{ ...x, refetch }} />
                        )}
                    </TBody>
                </Table>
                <Wrap style={{width: "100%", textAlign: "center", float: "left", marginTop: "20px", marginBottom: "50px"}}>
                    {data && data.getCouponBurdenCount &&
                        <PageNavigation
                            match={props.match}
                            totalPage={Math.ceil(data.getCouponBurdenCount / urlParamObj.couponLimit)}
                            pathName={`/manager/benefit/${burdenDisplayLocation}`}
                            history={props.history}
                            pageName={"couponPage"}
                            secPathName={`/manager/benefit/${burdenDisplayLocation}`}
                        />
                    }
                </Wrap>
            </Half>
        </>
    )
});