import React from 'react';
import styled from 'styled-components';
import { IImageUploadContainerProps } from './ImageUpload.types';

export const ImageUploadContainer = styled.div<IImageUploadContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  position: relative;
  border-radius: 50%;
  border: 2px dashed #f4f6f8;
  cursor: pointer;
  &::after {
    content: ' ';
    position: absolute;
    top: calc(50% - 64px);
    left: calc(50% - 64px);
    border-radius: 50%;
    width: 128px;
    height: 128px;
    background: ${(props) => (props.previewUrl ? `url(${props.previewUrl})` : '#f4f6f8')};
    background-size: cover;
    background-position: center center;
  }
`;

export const Input = styled('input')({
  display: 'none',
});

export const ImageDeleteButton = styled.div<React.CSSProperties>`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  background: #00b8d9;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
