import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import context from '../../provider/product';
import { Wrap, CheckboxText, Div, RequireText, Question, Ul, Li } from './style';
import { Radio, RadioGroup, Checkbox } from 'rsuite';
import { Input } from '../../style';
import styled from 'styled-components';

const Styled = {
    ValidationText: styled.div`
        font-size: 12px; 
        margin-top: 8px;
        color: rgb(243, 0, 186); 
        font-weight: 400; 
        line-height: 100%;
    `
}

export default (({ inputs }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);

    useEffect(() => {
        if (state.copyright['3dWarehouse'] === 'off' &&
            state.copyright['freeExtraSource'] === 'off' &&
            state.copyright['payExtraSource'] === 'off') {
            dispatch({ key: 'diy', type: 'copyright', value: 'on' });
        }
    }, [state.copyright['3dWarehouse'], state.copyright['freeExtraSource'], state.copyright['payExtraSource']]);

    return useMemo(() => {
        return (
            <Wrap isDisp={state.isDisp}>
                {/* 본 모델의 소스는 직접 제작 하였습니다. ( 질문지 1번항목 )*/}
                <Ul>
                    {/* 질문지 1번항목 - 제목 */}
                    <Question>
                        {parse(t('diyText'))}
                    </Question>
                    {/* 질문지 1번항목 - 답변 */}
                    <Question>
                        <RadioGroup
                            inline
                            name="diy"
                            value={state.copyright.diy}
                            onChange={value => {
                                dispatch({ key: 'diy', type: 'copyright', value });
                                if (value === 'off') {
                                    dispatch({ key: '3dWarehouse', type: 'copyright', value: 'on' });
                                    dispatch({ key: '3dWarehouseList', type: 'copyright', value: '' });
                                    dispatch({ key: 'freeExtraSource', type: 'copyright', value: 'on' });
                                    dispatch({ key: 'freeExtraSourceList', type: 'copyright', value: '' });
                                    dispatch({ key: 'payExtraSource', type: 'copyright', value: 'on' });
                                    dispatch({ key: 'payExtraSourceList', type: 'copyright', value: '' });
                                }
                            }}
                        >
                            <Radio value="on">{t("Yes.label")}</Radio>
                            <Radio value="off">{t("No.label")}</Radio>
                        </RadioGroup>
                    </Question>

                    <Li className={state.copyright.diy !== 'on' ? '' : 'hide'}>
                        {/* 3D웨어하우스 소스를 사용하였습니다.(질문지 1-1번 항목) */}
                        <Ul>
                            {/* 질문지 1-1번 항목 - 질문 */}
                            <Question>
                                {t("IUsed3DWarehouseSource.label")}
                            </Question>
                            {/* 질문지 1-1번 항목 - 답변 */}
                            <Question>
                                <RadioGroup
                                    inline
                                    name="3dWarehouse"
                                    value={state.copyright['3dWarehouse']}
                                    onChange={value => {
                                        dispatch({ key: '3dWarehouse', type: 'copyright', value })
                                    }}
                                >
                                    <Radio value="on">{t("Yes.label")}</Radio>
                                    <Radio value="off">{t("No.label")}</Radio>
                                </RadioGroup>
                            </Question>
                            <Li className={(state.copyright['3dWarehouse'] === 'on' ? '' : 'hide')}>
                                {/* 질문지 1-1-1번 항목 - 답변 */}
                                <Input
                                    ref={inputs?.warehouseInput}
                                    onChange={e => {
                                        const isError = e.target.value ? false : true;
                                        dispatch({ key: 'isErrorWarehouseCopyright', type: 'input', value: isError });
                                        dispatch({ key: '3dWarehouseList', type: 'copyright', value: e.target.value })
                                    }}
                                    value={state.copyright['3dWarehouseList']}
                                    placeholder={t("3dwarehouseListPlaceholder")}
                                />
                                {state.isErrorWarehouseCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                            </Li>
                        </Ul>

                        {/* 상업적으로 이용이 가능한 타 무료 외부 소스를 사용하였습니다. (질문지 1-2번 항목) */}
                        <Ul>
                            {/* 질문지 1-2번 항목 - 질문 */}
                            <Question>{parse(t('FreeExtraSourceText'))}</Question>
                            {/* 질문지 1-2번 항목 - 답변 */}
                            <Question>
                                <RadioGroup
                                    inline
                                    name="freeExtraSource"
                                    value={state.copyright.freeExtraSource}
                                    onChange={value => {
                                        dispatch({ key: 'freeExtraSource', type: 'copyright', value })
                                    }}
                                >
                                    <Radio value="on">{t("Yes.label")}</Radio>
                                    <Radio value="off">{t("No.label")}</Radio>
                                </RadioGroup>
                            </Question>
                            <Li className={(state.copyright['freeExtraSource'] === 'on' ? '' : 'hide')}>
                                {/* 질문지 1-2-1번 항목 */}
                                <Ul>
                                    {/* 질문지 1-2-1번 항목 - 질문 */}
                                    <Question>{parse(t('ExternalSourceList.label'))}</Question>
                                    {/* 질문지 1-2-1번 항목 - 답변 */}
                                    <Question>
                                        <Input
                                            ref={inputs?.externalFreeSourceInput}
                                            name="freeExtraSourceList"
                                            onChange={e => {
                                                const isError = e.target.value ? false : true;
                                                dispatch({ key: 'isErrorFreeCopyright', type: 'input', value: isError });
                                                dispatch({ key: 'freeExtraSourceList', type: 'copyright', value: e.target.value })
                                            }}
                                            value={state.copyright['freeExtraSourceList']}
                                            placeholder={t("freeExtraSourcePlaceholder")}
                                        />
                                        {state.isErrorFreeCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                                    </Question>
                                </Ul>
                            </Li>
                        </Ul>

                        {/* 상업적으로 이용이 가능한 타 무료 외부 소스를 사용하였습니다. (질문지 1-3번 항목) */}
                        <Ul>
                            {/* 질문지 1-3번 항목 - 질문 */}
                            <Question>{parse(t('PayExtraSourceText'))}</Question>
                            {/* 질문지 1-3번 항목 - 답변 */}
                            <Question>
                                <RadioGroup
                                    inline
                                    name="payExtraSource"
                                    value={state.copyright.payExtraSource}
                                    onChange={value => {
                                        dispatch({ key: 'payExtraSource', type: 'copyright', value })
                                    }}
                                >
                                    <Radio value="on">{t("Yes.label")}</Radio>
                                    <Radio value="off">{t("No.label")}</Radio>
                                </RadioGroup>
                            </Question>
                            <Li className={(state.copyright['payExtraSource'] === 'on' ? '' : 'hide')}>
                                {/* 질문지 1-3-1번 항목 */}
                                <Ul>
                                    {/* 질문지 1-3-1번 항목 - 질문 */}
                                    <Question>
                                        {parse(t('ExternalSourceList.label'))}
                                    </Question>
                                    {/* 질문지 1-3-1번 항목 - 답변 */}
                                    <Question>
                                        <Input
                                            ref={inputs?.externalPaySourceInput}
                                            onChange={e => {
                                                const isError = e.target.value ? false : true;
                                                dispatch({ key: 'isErrorPayCopyright', type: 'input', value: isError });
                                                dispatch({ key: 'payExtraSourceList', type: 'copyright', value: e.target.value })
                                            }}
                                            value={state.copyright['payExtraSourceList']}
                                            placeholder={t("payExtraSourcePlaceholder")}
                                        />
                                        {state.isErrorPayCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                                    </Question>
                                </Ul>
                            </Li>
                        </Ul>
                    </Li>
                </Ul>
                <Div>
                    {/* 기존에 on / off로 되어있어 true false로 하기위해서는 마이그레이션 필요. TODO*/}
                    <Checkbox
                        inputRef={inputs?.agreeInput}
                        checked={state.copyright.agreement === 'on'}
                        onChange={(value, checked) => {
                            dispatch({ key: 'isErrorAgree', type: 'input', value: !checked });
                            dispatch({ key: 'agreement', type: 'copyright', value: checked ? 'on' : 'off' })
                        }}
                    >
                        <div>
                            <RequireText>
                                ({t('require')})
                            </RequireText>
                            <CheckboxText>
                                {t("agreeAllCopyright")}
                            </CheckboxText>
                        </div>
                    </Checkbox>
                    {state.isErrorAgree && <Styled.ValidationText>{t('checkCopyright')}</Styled.ValidationText>}
                </Div>
            </Wrap>
        );
    }, [
        state.copyright.diy,
        state.copyright['3dWarehouse'],
        state.copyright['3dWarehouseList'],
        state.copyright.freeExtraSource,
        state.copyright.freeExtraSourceList,
        state.copyright.payExtraSource,
        state.copyright.payExtraSourceList,
        state.copyright.dynamicElement,
        state.copyright.agreement,
        state.isErrorWarehouseCopyright,
        state.isErrorFreeCopyright,
        state.isErrorPayCopyright,
        state.isErrorAgree,
        dispatch,
        state.copyright,
        state.isDisp,
        t
    ]);
});