import React from 'react';
import { Icon } from 'rsuite';
import { CategoryItem, Title, RemoveButton } from './style';

export default (({ id, title, onChangeApplyValue, applySelectedValue }) => {
    // 항목 삭제 클릭 이벤트 처리기 메소드 
    const onClickRemoveItem = () => {
        // 삭제 이후의 카테고리 정보 
        const categories = applySelectedValue.filter(x => x !== id);
        // 카테고리 저장 
        onChangeApplyValue(categories);
    };
    return (
        <CategoryItem key={id}>
            <Title>
                {title}
            </Title>
            <RemoveButton onClick={onClickRemoveItem}>
                <Icon icon="close" />
            </RemoveButton>
        </CategoryItem>
    );
});
