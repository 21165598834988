import React, { useContext, useEffect } from 'react'
import { AppContext } from '../app'

function DetailChange() {
    const { setBackgroundColor } = useContext(AppContext)
    useEffect(() => {
        setBackgroundColor('')
        
    }, [setBackgroundColor])
    return (
        <iframe src="/detailService/detailChange" title="detailChange" style={{ width: '100%', height: '1000px' }}></iframe>
    )
}
export default DetailChange