import React, { useMemo } from 'react';
import { SelectBox } from 'acon-mui/components';
import { useTranslation } from 'react-i18next';

export default function ScrollSelector() {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { id: 1, label: t('document.categories.title'), value: 'hubWrite_field_categories' },
      { id: 2, label: t('document.tags.title'), value: 'hubWrite_field_tags' },
      { id: 3, label: t('document.industries.title'), value: 'hubWrite_field_industries' },
      { id: 4, label: t('document.title.label'), value: 'hubWrite_field_title' },
      { id: 5, label: t('goods.price'), value: 'hubWrite_field_price' },
      { id: 6, label: t('goods.openrun'), value: 'hubWrite_field_openrun' },
      { id: 7, label: t('goods.model'), value: 'hubWrite_field_file' },
      { id: 8, label: t('goods.copyright'), value: 'hubWrite_field_copyright' },
      { id: 9, label: t('thumbnail'), value: 'hubWrite_field_image' },
      { id: 10, label: t('document.productDetails'), value: 'hubWrite_field_content' },
    ],
    [t],
  );

  const handleSelect = (value) => {
    const currentClassName = options.find((x) => x.id === value)?.value;
    const ele = (document.getElementsByClassName(currentClassName) as HTMLCollectionOf<HTMLElement>)[0];
    if (document && ele !== null) {
      window.scrollTo(0, ele.offsetTop - 150);
    }
  };

  return (
    <SelectBox
      placeholder={t('document.shortcut')}
      inputLabelVisible={false}
      options={options}
      onSelect={(e, val) => {
        handleSelect(val.id);
      }}
      size="small"
      width="220px"
      marginRight="auto"
    />
  );
}
