

export default (loading) => {

    function onChagne(setFilter) {
        return (e) => {
            if (loading) {
                return
            } else {
                var el = e.currentTarget
                var data = el.value.trim()
                setFilter(data)
            }
        }
    }

    var result = { onChagne }
    return result
}