export default (loading, setSelectId, setSelect) => {
    function onClick(data, selected) {
        return (e) => {
            if (loading) {
                return
            } else {
                try {
                    if (selected) {
                        setSelectId('')
                        setSelect({
                            _id: '', describe: '', auth: [], defaultAuth: false,
                            defaultValue: [], isUpdate: undefined, isLoad: undefined
                        })
                    } else {
                        setSelectId(data._id)
                        setSelect({ ...data, isUpdate: true, isLoad: false })
                    }
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }

    function onChange(setFilter) {
        return (e) => {
            if (loading) {
                return
            } else {
                setFilter(e.target.value)
            }
        }
    }
    var result = { onClick, onChange }
    return result
}