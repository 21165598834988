import React, { useState } from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { DialogType } from "components/dialog/type";
import { LoadingButton } from "@mui/lab";

type Props = DialogType & {
  onConfirm: () => Promise<void>;
};

export default function RequestDialog({ isOpen, onClose, onConfirm }: Props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      <Stack>
        <Stack
          sx={{ pl: 3, pr: 3, pt: 5, textAlign: "center" }}
          maxWidth={"400px"}
          spacing={3}
        >
          <Typography variant="body1">
            {parse(t("user.requestEdit"))}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} p={3}>
          <Button
            variant={"outlined"}
            size="large"
            onClick={onClose}
            sx={{ flex: 1 }}
          >
            {t("user.no")}
          </Button>
          <LoadingButton
            type="submit"
            variant={"contained"}
            size="large"
            sx={{ flex: 1 }}
            loading={isLoading}
            onClick={handleConfirm}
          >
            {t("user.yes")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
