export enum TwinklePromotionErrors {
  INVALID_INPUT = 'invalid input',
  INVALID_ITEM_INPUT = 'invalid item input',
  DUPLICATED_ASSETS = 'duplicated assets',
  ASSET_NOT_FOUND = 'asset not found',
  CANNOT_SELECT_FUNDING_ASSET = 'cannot select funding asset',
  RECENT_ASSET = 'cannot register recent asset',
  UNRELEASED_ASSET = 'cannot select unreleased asset',
  FREE_ASSET = 'cannot select free asset',
  INVALID_DISCOUNT_VALUE = 'invalid discount value',
  DISCOUNT_MUST_BE_INTEGER = 'discount value must be integer',
  PRICE_CANNOT_BE_DECIMAL = 'price cannot be decimal',
  PRICE_CANNOT_BE_NEGATIVE = 'price cannot be negative',
  INVALID_PROMOTION = 'invalid promotion',
  REGISTERED_PROMOTION = 'cannot register to registered promotion',
  SUSPENDED_PROMOTION = 'cannot register to suspended promotion',
  ALREADY_STARTED_PROMOTION = 'cannot register to already started promotion',
  INVALID_PROMOTION_PERIOD = 'invalid promotion period',
  MAX_COUNT_EXCEEDED = 'max count exceeded',
  CANNOT_REGISTER_PROMOTED_ASSET = 'cannot register currently promoted asset',
  DUPLICATED_PROMOTION_FOR = 'duplicated promotion for',
}
export function handleTwinklePromotionError(error: Error) {
  const errorMessage = (error?.message || '').split('reg error:')[1]?.trim();

  // TwinklePromotionErrors enum의 키와 값에 대해 반복
  for (const key of Object.keys(TwinklePromotionErrors)) {
    const errorValue = TwinklePromotionErrors[key as keyof typeof TwinklePromotionErrors];
    if (errorMessage?.includes(errorValue)) {
      return errorValue;
    }
  }

  return 'Error: An unknown error occurred.';
}
