import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { alpha, keyframes, styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const pulse = keyframes({
  from: { opacity: 1, transform: `scale(1)` },
  to: { opacity: 0.25, transform: `scale(0.75)` },
});
// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <RootStyle>
      <Box
        sx={{
          width: 120,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "absolute",
        }}
      >
        <Box
          component={motion.div}
          sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: (theme) =>
              `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease 0s infinite alternate`,
          }}
        />
        <Box
          component={motion.div}
          animate={{
            scale: [1, 0.75],
            opacity: [1, 0.25],
          }}
          transition={{
            duration: 0.4,
            repeatDelay: 0.2,
            repeat: Infinity,
          }}
          sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: (theme) =>
              `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease .2s infinite alternate`,
          }}
        />
        <Box
          component={motion.div}
          animate={{
            scale: [1, 0.75],
            opacity: [1, 0.25],
          }}
          transition={{
            duration: 0.4,
            repeatDelay: 0.4,
            repeat: Infinity,
          }}
          sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: (theme) =>
              `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease .4s infinite alternate`,
          }}
        />
      </Box>
    </RootStyle>
  );
}
