import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { color } from 'acon-mui/style';

export const BrandPickContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BrandPickList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 12px;
  gap: 8px;
`;

export const BrandPickListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
export const BrandPickListItemText = styled(Typography)`
  font-size: 14px;
  color: ${color.text.primary};
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 90px;
`;
export const BrandPickListItemCloseButton = styled.button`
  margin-left: auto;
  background: transparent;
  &:disabled {
    opacity: 0.5;
  }
`;
