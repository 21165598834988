import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import context from '../../provider/product';
import { useHistory, useParams } from "react-router-dom";
import { BlackButton, WhiteButton } from '../../component/form';
import { Empty, Loading } from '../../style';
import { Display } from '../status';
import { useMutation, useQuery } from '@apollo/client';
import {
    CANCEL_REQUEST_STATUS,
    CHANGE_EDIT_PRODUCT,
    CHANGE_PROD_STATUS_V2,
    REQUEST_PROD_ITEM,
    SAVE_PRODUCT_V3,
} from 'api/mutations';
import { AppContext } from 'app';
import ACON from 'lib/global';
import {
    STATUS_APPROVED,
    STATUS_DRAFT,
    STATUS_INPROGRESS,
    STATUS_OPEN,
    STATUS_REJECT
} from 'boards/DetailBoardWrite/constants';
import { GET_IS_VALID_GODO_GOODS_NO } from 'api/quries';
import { CommentModal } from 'boards/DetailBoardWrite/modal';
import { EditButton, ItemWrap, Wrap } from './style';

export default (({ refetch, isInProgress, inputs }) => {
    // 로딩 여부 
    const [isLoading, setIsLoading] = useState(false);
    // 메뉴 고정여부 
    const [isFixed, setIsFixed] = useState(false);
    // 사용자 정보 
    const { showAlertMessage, showConfirmMessage, userInfo } = useContext(AppContext);
    // 상태 
    const { state, dispatch } = useContext(context);

    // 의견 모달 속성 
    const [commentModalProperty, setCommentModalProperty] = useState({
        isShow: false,
        onConfirmCallback: null
    });

    // 저장하기 액션
    const [saveMutation] = useMutation(SAVE_PRODUCT_V3);
    // 검토요청 액션 
    const [requestMutation] = useMutation(REQUEST_PROD_ITEM);
    // 상태변경 액션
    const [changeStatusMutationV2] = useMutation(CHANGE_PROD_STATUS_V2);
    // 상품 수정하기 액션
    const [changeEditProduct] = useMutation(CHANGE_EDIT_PRODUCT);
    // 요청상태 취소하기 액션 
    const [cancelRequestStatus] = useMutation(CANCEL_REQUEST_STATUS);

    // URL 파생 정보 
    const { docId, lang, type } = useParams();

    // 번역도구
    const { t } = useTranslation()
    // history 
    const history = useHistory();

    // 오픈 여부 
    const isOpen = type ? true : false;
    // 관리자 여부 
    const { isAdmin } = userInfo;

    // 브랜드 가져오기 
    const getIsValidGodoGoodsNo = useQuery(GET_IS_VALID_GODO_GOODS_NO, {
        skip: true
    });

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const isTopMenu = document.getElementsByClassName('menu').length !== 0;
            const standard = isTopMenu ? 175 : 160;
            // 메뉴 고정여부 설정 
            setIsFixed(document.documentElement.scrollTop >= standard);
        });
    });

    // 검토요청 버튼 클릭 이벤트 메소드 (비동기)
    const onClickRequestReviewButton = e => {
        // 검토요청 메세지 표시 
        showConfirmMessage(
            t('changeStatusConfirmTitle'),
            state.isEdit ? t("CheckEditRequestMessage") : t("CheckRequestMessage"),
            async () => {
                // 상태 변경을 할 수 없는경우 
                if (!isValidRequest())
                    // 종료             
                    return;
                // 저장
                const savedDocId = await save();

                // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
                if (!docId && savedDocId) {
                    // 이동 될 경로 
                    const toPushUrl = `/goods/write/${lang}/${savedDocId}`;
                    // 경로 변경 
                    history.push(toPushUrl);
                }
                else {
                    await refetch();
                }

                // 상태 변경을 할 수 없는경우 
                if (!isValidRequest())
                    // 종료             
                    return;

                // 변경하기 
                const isRequest = await requestMutation({
                    variables: {
                        docId: savedDocId,
                        lang
                    }
                });

                // 변경이 완료되지 못한경우 
                if (!isRequest) {
                    // 에러 표시 
                    throw new Error('검토요청을 할 수 없습니다.');
                }

                setIsLoading(false);
                // 사용자 안내 메세지 표시
                showAlertMessage(
                    t("completedRequestModal.Title"),
                    state.isEdit ? t('completedRequestModal.contents2') : t('completedRequestModal.contents'),
                    {
                        callback: () => {
                            // 홈으로 이동 
                            history.push('/');
                        }
                    }
                );
            },
            {
                confirmText: t('requestSubmit')
            }
        );
    }

    // 저장하기 유효성검사 메소드 
    const isValidSave = async (targetStatus) => {
        const { title, godoGoodsNo, copyright, salePrice, status, related } = state;

        try {
            // 제목이 입력되지 않은경우
            if (!title) {
                // 에러 호출
                throw new ACON.ValidationError(t("PleaseEnterATitle.label"));
            }
            // 대상 상태가 존재할 경우, 
            if (targetStatus && targetStatus === STATUS_APPROVED || status === STATUS_OPEN || status === STATUS_APPROVED) {
                // 판매가가 입력되지 않았을 경우 
                if (salePrice === '') {
                    // 에러 호출
                    throw new ACON.ValidationError(
                        isAdmin ? t('isNotValidAdminSalesPrice') : t('isNotValidSalesPrice'));
                }

                if (copyright['diy'] === 'off' && copyright['3dWarehouse'] === 'on' && copyright['3dWarehouseList'].trim() === '') {
                    inputs.warehouseInput && inputs.warehouseInput.current && inputs.warehouseInput.current.focus();
                    dispatch({ key: 'isErrorWarehouseCopyright', type: 'input', value: true });
                    throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
                }
                if (copyright['diy'] === 'off' && copyright['freeExtraSource'] === 'on' && copyright['freeExtraSourceList'].trim() === '') {
                    inputs.externalFreeSourceInput && inputs.externalFreeSourceInput.current && inputs.externalFreeSourceInput.current.focus();
                    dispatch({ key: 'isErrorFreeCopyright', type: 'input', value: true });
                    throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
                }
                if (copyright['diy'] === 'off' && copyright['payExtraSource'] === 'on' && copyright['payExtraSourceList'].trim() === '') {
                    inputs.externalPaySourceInput && inputs.externalPaySourceInput.current && inputs.externalPaySourceInput.current.focus();
                    dispatch({ key: 'isErrorPayCopyright', type: 'input', value: true });
                    throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
                }
                if (copyright.agreement !== 'on') {
                    inputs.agreeInput && inputs.agreeInput.current && inputs.agreeInput.current.focus();
                    dispatch({ key: 'isErrorAgree', type: 'input', value: true });
                    throw new ACON.ValidationError(t('checkCopyright'));
                }

                if (!godoGoodsNo && related?.length) {
                    throw new ACON.ValidationError('고도몰 상품번호를 입력 후 연관상품을 입력해주세요.');
                }
            }
            // 관리자 유효성 검사 
            if (isAdmin) {
                // 고도몰 상품번호가 존재하지 않는경우 
                if (!godoGoodsNo && targetStatus && targetStatus === STATUS_APPROVED) {
                    // 에러 호출
                    throw new ACON.ValidationError(t("pleaseEnterGodoGoodsNo"));
                }
                if (godoGoodsNo) {
                    // int type을 벗어난 경우 
                    if (Number(godoGoodsNo) > 2147483647) {
                        throw new ACON.ValidationError('고도몰 상품번호가 올바르지 않습니다.');
                    }
                    // 고도몰 상품번호가 존재할 경우 
                    // 고도몰 번호 여부 체크 
                    const isValidGodoGoodsNo = await getIsValidGodoGoodsNo.refetch({
                        docId,
                        godoGoodsNo
                    });
                    // 고도몰 번호가 존재할 경우, 메세지 표시 
                    if (isValidGodoGoodsNo.data.isValidGodoGoodsNo) {
                        // 에러 호출
                        throw new ACON.ValidationError(t("overlapGoodsNo"));
                    }
                }
            }
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                setIsLoading(false);
                showAlertMessage(
                    t("UnableToCompleteSave.label"),
                    errObj.message
                );
                // 종료
                return;
            }
            // 에러 로그 기록 (TO DO)
        }
        return true;
    };

    // 상태 변경 유효성검사
    const isValidRequest = () => {
        // 고도몰 상품번호 
        const { title, mainImageCropInfo, copyright, thumbnailImageCropInfo, subImage1CropInfo, subImage2CropInfo, subImage3CropInfo, modelFileId, modelKey, modelName, salePrice } = state;
        try {
            // 제목이 입력되지 않은경우
            if (!title) {
                // 에러 호출
                throw new ACON.ValidationError(t("PleaseEnterATitle.label"));
            }
            // 대표 이미지가 설정되지 않은경우 
            if (!mainImageCropInfo) {
                // 에러 호출
                throw new ACON.ValidationError(t('pleaseEnterMainImage'));
            }
            // 썸네일 이미지가 설정되지 않은경우 
            if (!thumbnailImageCropInfo) {
                // 에러 호출
                throw new ACON.ValidationError(t('pleaseEnterThumbnailImage'));
            }
            // 서브 이미지가 설정되지 않은경우 
            if (!subImage1CropInfo || !subImage2CropInfo || !subImage3CropInfo) {
                // 에러 호출
                throw new ACON.ValidationError(t('pleaseEnterSubImage'));
            }
            // 모델 url 도 없고, key name 도 없는경우 
            if (!modelFileId && !modelKey && !modelName) {
                // 에러 호출
                throw new ACON.ValidationError(t('pleaseEnterModel'));
            }
            // 사용자의 경우, 희망 판매가가 비어있을 경우 
            if (!isAdmin && salePrice === '') {
                // 에러 호출
                throw new ACON.ValidationError(t('pleaseEnterSalesPrice'));
            }
            if (copyright['diy'] === 'off' && copyright['3dWarehouse'] === 'on' && copyright['3dWarehouseList'].trim() === '') {
                inputs.warehouseInput && inputs.warehouseInput.current && inputs.warehouseInput.current.focus();
                dispatch({ key: 'isErrorWarehouseCopyright', type: 'input', value: true });
                throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
            }
            if (copyright['diy'] === 'off' && copyright['freeExtraSource'] === 'on' && copyright['freeExtraSourceList'].trim() === '') {
                inputs.externalFreeSourceInput && inputs.externalFreeSourceInput.current && inputs.externalFreeSourceInput.current.focus();
                dispatch({ key: 'isErrorFreeCopyright', type: 'input', value: true });
                throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
            }
            if (copyright['diy'] === 'off' && copyright['payExtraSource'] === 'on' && copyright['payExtraSourceList'].trim() === '') {
                inputs.externalPaySourceInput && inputs.externalPaySourceInput.current && inputs.externalPaySourceInput.current.focus();
                dispatch({ key: 'isErrorPayCopyright', type: 'input', value: true });
                throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
            }
            if (copyright.agreement !== 'on') {
                inputs.agreeInput && inputs.agreeInput.current && inputs.agreeInput.current.focus();
                dispatch({ key: 'isErrorAgree', type: 'input', value: true });
                throw new ACON.ValidationError(t('checkCopyright'));
            }

            return true;
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                setIsLoading(false);
                showAlertMessage(
                    t("goods.unableComplteRequest"),
                    errObj.message
                );
                // 종료
                return false;
            }
            // 에러 로그 기록(TODO)
        }
        return true;
    };

    // 저장하기 메소드 
    const save = async (targetStatus) => {
        // 저장 유효성검사 실패 시 
        if (!(await isValidSave(targetStatus)))
            // 작업 종료 
            return false;

        const {
            docId,
            title,
            contents,
            brandId,
            description,

            rejectComment,
            editMessage,
            editComment,

            mainImageKey,
            mainImageName,
            mainImageCropInfo,

            thumbnailImageKey,
            thumbnailImageName,
            thumbnailImageCropInfo,

            subImage1Key,
            subImage1Name,
            subImage1CropInfo,

            subImage2Key,
            subImage2Name,
            subImage2CropInfo,

            subImage3Key,
            subImage3Name,
            subImage3CropInfo,

            modelFileName,
            modelFileId,

            modelKey,
            modelName,
            godoGoodsNo,
            price,
            salePrice,
            commission,
            category,
            modelConfig,
            dynamicItem,
            copyright,
            extension,
            applications,
            fileConfig,
            updateHistory,
            keywords,
            related
        } = state;

        const variables = {
            docId,
            lang: lang,
            isOpen: type === 'open',
            godoGoodsNo,
            title,
            contents,
            author: brandId,
            description,

            mainImageKey,
            mainImageName,
            mainImageCropInfo,

            rejectComment,
            editMessage,
            editComment,

            thumbnailImageKey,
            thumbnailImageName,
            thumbnailImageCropInfo,

            subImage1Key,
            subImage1Name,
            subImage1CropInfo,

            subImage2Key,
            subImage2Name,
            subImage2CropInfo,

            subImage3Key,
            subImage3Name,
            subImage3CropInfo,

            modelFileName,
            modelFileId,
            modelKey,
            modelName,

            godoGoodsNo,
            price: ACON.OnlyNumber(price),
            salePrice: ACON.OnlyNumber(salePrice),
            commission,
            categories: category,
            modelConfig,
            dynamicItem,
            copyright: JSON.stringify(copyright),
            extension,
            applications: applications?.map((app) => app.id), // 응용프로그램 ID의 배열로 변환([Int])
            fileConfig,
            updateHistory,
            keywords,
            rejectComment,
            editMessage,
            editComment,
            related: related?.map(x => parseInt(x)),
        };
        // 빈 값 제거 
        for (let key in variables) {
            if (variables[key] === null || variables[key] === undefined || variables[key] === '')
                delete variables[key];
        }

        // 저장하기
        let result = await saveMutation({ variables });
        const { data } = result;

        return data.saveProduct.docId;
    };

    // 상태 콤보박스 변경 이벤트 처리기 메소드 
    const onChangeStatusCombo = async (itemObj) => {
        try {
            // 문서 번호가 존재하지 않는경우 
            if (!docId) {
                throw new ACON.ValidationError(t('goods.pleaseSaveDoc'));
            }
            // 문서 상태가 오픈되어 있는 경우 
            if (state.status === STATUS_OPEN) {
                throw new ACON.ValidationError(t('goods.doNotChangeAtOpen'));
            }

            const onClickConfirmMessage = async () => {
                // 상태 변경하기 메소드 
                const changeStatus = async (message) => {
                    setIsLoading(true);
                    // 저장
                    const savedDocId = await save(itemObj.value);
                    // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
                    if (!docId && savedDocId) {
                        // 이동 될 경로 
                        const toPushUrl = `/goods/write/${lang}/${savedDocId}`;
                        // 경로 변경 
                        history.push(toPushUrl);
                    } else {
                        await refetch();
                    }

                    // 상품 상태 변경이 오픈인 경우 
                    if (itemObj.value === STATUS_APPROVED) {

                        // 고도몰 번호 여부 
                        const isValidGodoGoodsNo = await getIsValidGodoGoodsNo.refetch({
                            docId,
                            godoGoodsNo: state.godoGoodsNo
                        });
                        // 고도몰 번호가 존재할 경우, 메세지 표시 
                        if (isValidGodoGoodsNo.data.isValidGodoGoodsNo) {
                            setIsLoading(false);
                            showAlertMessage(t('doNotChangeStatus'), t('alreadyExistGodoGoodsNo'));
                            // 종료 
                            return;
                        }
                    }
                    // 완료 여부 
                    const isComplete = await changeStatusMutationV2({
                        variables: {
                            docId: savedDocId,
                            lang,
                            message,
                            isOpen: false,
                            status: itemObj.value,
                        }
                    });
                    // 상태가 변경이 완료된 경우
                    if (isComplete) {
                        // 상태 변경된 뒤, 항목 재확보 
                        await refetch();
                        // 안내 메세지 표시 
                        showAlertMessage(
                            t('completeChangeTitle'),
                            t('completeChangeContents')
                        );
                    }
                    setIsLoading(false);
                };
                // 반려일 경우에는 modal 에 이벤트를 넘겨준다 
                if (itemObj.value === STATUS_REJECT) {
                    setCommentModalProperty({
                        onConfirmCallback: changeStatus,
                        isShow: true
                    });
                    return;
                }
                await changeStatus();
            };

            // 상태를 변경하겠습니까 메세지 표시 
            showConfirmMessage(
                t('changeStatusConfirmTitle'),
                t('changeStatusConfirmData'),
                onClickConfirmMessage
            );
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                setIsLoading(false);
                showAlertMessage(
                    t("goods.notChangeStatus"),
                    errObj.message
                );
                // 종료
                return;
            }
            // 사용자 안내 메세지 표시 
            showAlertMessage(t("anInternalErrorHasOccurred"), t("pleaseContactAdministrator"));
        }
    };

    // 저장 버튼 클릭 이벤트 메소드
    const onClickSaveButton = async (e) => {
        try {
            setIsLoading(true);
            // 저장된 문서 ID 
            const savedDocId = await save();

            if (savedDocId === false) {
                setIsLoading(false);
                // 종료 
                return;
            }

            // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
            if (!docId && savedDocId) {
                // 이동 될 경로 
                const toPushUrl = `/goods/write/${lang}/${savedDocId}`;
                // 경로 변경 
                history.push(toPushUrl);
            }
            else {
                await refetch();
            }

            setIsLoading(false);
            // 사용자 안내 메세지 표시
            showAlertMessage(
                t("saved"),
                t('completedSaveContents')
            );
        }
        catch (errObj) {
            setIsLoading(false);
            // 사용자 안내 메세지 표시
            showAlertMessage(
                t("contactAdmin"),
                errObj.message
            );
        }
    };
    // 상품 수정하기 버튼 클릭 이벤트 처리기 메소드 
    const onClickEditBtn = async () => {
        // 상품 상태 변경 
        await changeEditProduct({
            variables: {
                docId
            }
        });
        // 상품 데이터 재확보 
        await refetch();
    };

    // 상품 요청취소 버튼 클릭 이벤트 처리기 메소드 
    const onClickCancelRequestStatusButton = async () => {
        // 상태를 변경하겠습니까 메세지 표시 
        showConfirmMessage(
            t('changeStatusConfirmTitle'),
            state.isEdit ? t('cancelEditRequestMessage') :
                t('cancelRequestMessage'),
            async () => {
                // 로딩창 표시 
                setIsLoading(true);

                // 상태 요청 취소하기 
                await cancelRequestStatus({
                    variables: {
                        docId
                    }
                });
                // 상품 데이터 재확보 
                await refetch();

                // 로딩창 종료 
                setIsLoading(false);
            },
            {
                cancelText: t('cancelRequestCancelText'),
                confirmText: t('cancelRequest'),
            }
        );
    };

    // 저장하기 버튼 
    const saveBtn = isAdmin ?
        <BlackButton onClick={onClickSaveButton} disabled={isInProgress} style={{ width: isAdmin && state.status === STATUS_OPEN ? `100%` : `calc(100% - 225px)` }}>{t("Save.label")}</BlackButton> :
        <WhiteButton onClick={onClickSaveButton} disabled={isInProgress} style={{ width: `216px` }}>{t("goods.userSave")}</WhiteButton>;

    // 요청 버튼
    const requestBtn = <BlackButton
        onClick={onClickRequestReviewButton}
        disabled={isInProgress}
        style={{ width: `calc(100% - 225px)` }}
    >{isOpen ? t("RequestEdit") : t("RequestReview.label")}</BlackButton>

    return (
        <>
            <Loading show={isLoading} />
            {/* 의견 보기 */}
            <CommentModal isShow={commentModalProperty.isShow} onClick={commentModalProperty.onConfirmCallback} onClose={() => { setCommentModalProperty({ ...commentModalProperty, isShow: false }) }} />
            <Empty height="50"
                ishide={!isFixed ? 'on' : ''}
                isadmin={isAdmin ? 'on' : ''}
            />
            {(isAdmin || (!isAdmin && state.status === STATUS_DRAFT)) && (<Wrap isFixed={isFixed} isadmin={isAdmin ? 'on' : ''}>
                <ItemWrap>
                    {isAdmin && state.status !== STATUS_OPEN && <Display disabled={isInProgress} onChange={onChangeStatusCombo} />}
                    {saveBtn}
                    {!isAdmin && requestBtn}
                </ItemWrap>
            </Wrap>)}

            {/* 초안 문서에서 완료되었거나, 수정상태에서 완료된 경우에만 해당 버튼 표시*/}
            {!isAdmin && ([STATUS_APPROVED, STATUS_REJECT].includes(state.status)) && <EditButton onClick={onClickEditBtn}>{t('project.updateProject')}</EditButton>}
            {!isAdmin && state.isEdit && ![STATUS_APPROVED, STATUS_REJECT, STATUS_DRAFT, STATUS_INPROGRESS].includes(state.status) && <EditButton onClick={onClickCancelRequestStatusButton}>{t('project.cancelEditRequest')}</EditButton>}
            {!isAdmin && !state.isEdit && ![STATUS_INPROGRESS, STATUS_APPROVED, STATUS_REJECT, STATUS_DRAFT].includes(state.status) && <EditButton onClick={onClickCancelRequestStatusButton}>{t('project.cancelReviewRequest')}</EditButton>}
        </>
    );
});