import { Box, Dialog, Typography } from '@mui/material';
import { Button } from 'acon-mui/components';
import { color } from 'acon-mui/style';
import { STATUS_REJECTED, STATUS_REJECTED_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

export default function Reject() {
  const { t } = useTranslation();
  const history = useHistory();
  const { changeHistories, status } = useDocumentStore((state) => ({ changeHistories: state.changeHistories, status: state.status }));
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickReturn = () => {
    handleClose();
    history.push('/');
  };
  const message = useMemo(() => {
    const histories = changeHistories?.filter((item) => [STATUS_REJECTED, STATUS_REJECTED_ON_OPEN].includes(item.status));
    if (histories?.length > 0) {
      return histories?.[histories.length - 1]?.message || '';
    }
    return '';
  }, [changeHistories]);
  if (![STATUS_REJECTED, STATUS_REJECTED_ON_OPEN].includes(status)) return <></>;
  return (
    <Dialog
      open={open}
      componentsProps={{
        backdrop: {
          style: {
            background: '#00000011',
          },
          onClick: handleClose,
        },
      }}
    >
      <Box p={3} minWidth="400px" bgcolor="#fff" borderRadius="16px" boxShadow="-40px 40px 80px -8px rgba(145, 158, 171, 0.24)" textAlign="center" color={color.text.primary}>
        <Typography fontWeight="700" fontSize="18px" lineHeight="28px">
          {t('product.rejectCommentTitle')}
        </Typography>
        <Box mt={3} p={2} minHeight="100px" border={`1px solid ${color.gray.border}`} borderRadius="8px">
          <Typography textAlign="left">{parse(message)}</Typography>
        </Box>
        <Box mt={3} display="grid" gap={1} gridTemplateColumns="repeat(2, 1fr)">
          <Button onClick={handleClose} height="48px">
            {t('document.viewProduct')}
          </Button>
          <Button colorTheme="primary" variant="contained" onClick={handleClickReturn} height="48px">
            {t('backToList')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
