import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../../provider/product';
import { Field, Title, DivInput } from '../../style';

export default (() => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChangeInput = e => {
        if (e.target.value.indexOf('\n') > -1)
            return false;

        dispatch({
            key: 'title',
            type: 'input',
            value: e.target.value
        });
    }
    const onKeyDown = e => {
        if (e.key === 'Enter') {
            return false;
        }
    };
    return useMemo(() => {
        return (
            <Field>
                <Title>{t("Title.label")}</Title>
                <DivInput
                    maxLength={255}
                    onKeyDown={onKeyDown}
                    disabled={state.isDisp}
                    placeholder={t('subject.placeholder')}
                    onChange={onChangeInput}
                    value={state.title}
                />
            </Field>
        );
    }, [state.title, state.isDisp])
});