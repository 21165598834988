import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { AuthDataContext } from '../../authData'
import { ConfirmContext } from '../../../../../common/ConfirmModal2'
import { AlertContext } from '../../../../../common/AlertModal2'
import Ajax from '../ajax'
import Event from './event'

export default (function (props) {
    const { t } = useTranslation()
    const alert = useContext(AlertContext)
    const ajax = Ajax(t, alert)
    const { initAddList, loading } = useContext(AuthDataContext)
    const openConfirm = useContext(ConfirmContext)
    const { saveData } = props
    const event = Event(t, loading, initAddList, openConfirm, saveData, ajax)
    const onClick = event.onClick('save')
    const onCancleClick = event.onClick('cancle')
    return <div>
        <button onClick={onClick}>{t('authManager.save')}</button>
        <button onClick={onCancleClick}>{t('authManager.cancle')}</button>
    </div>
})

