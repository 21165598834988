import React, { useMemo, createContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import './AlertModal.scss';

export const AlertContext = createContext()
export default function (props) {
    const { alertModalStatus } = props
    const [status, setStatus] = useState(alertModalStatus)
    function openAlert(contents, callback) {
        setStatus({
            // alert 모달 콜백 함수 
            alertModalCallback: callback,
            // alert modal 표시 여부
            isShow: true,
            // alert modal 내용 
            alertModalContents: contents
        })
    }

    const { t } = useTranslation()
    var result
    result = useMemo(() => {

        function onConfirm() {
            if (status.confirmCallback && typeof status.confirmCallback === 'function') {
                status.confirmCallback()
            }
            onClose()
        }
        function onClose() {
            setStatus(prevStatus => ({
                ...prevStatus,
                isShow: false
            }))
        }

        let contents = status && status.alertModalContents ? status.alertModalContents : ''
        let show = status && status.isShow ? status.isShow : false
        console.log('alertModal')
        console.log(status)
        return (
            <AlertContext.Provider value={openAlert}>
                <Modal className="alertModal" show={show} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {parse(contents)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="requestBtn" onClick={onConfirm}>
                            {t('confirm')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {props.children}
            </AlertContext.Provider>
        )
    }, [status, setStatus, t, props.children])
    return result
}