import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { initialTwinkleSaleState } from './useTwinkleSaleStore.constants';
import { ITwinkleSaleSetState, ITwinkleSaleState, TSetterKeys } from './useTwinkleSaleStore.types';

export const useTwinkleSaleStore = createWithEqualityFn<ITwinkleSaleState & ITwinkleSaleSetState>((set) => {
  const initialTwinkleSaleSetState = {} as ITwinkleSaleSetState;

  for (const key in initialTwinkleSaleState) {
    const keyName = `set${key[0].toUpperCase()}${key.slice(1)}` as TSetterKeys;
    initialTwinkleSaleSetState[keyName] = (state) => set(() => ({ [key]: state }));
  }

  return {
    ...initialTwinkleSaleState,
    ...initialTwinkleSaleSetState,
  };
}, shallow);
