export enum PartnerTypeEnum {
  Business = 'Business',
  Personal = 'Personal',
}

export enum BusinessTypeEnum {
  // 과세 사업자
  Taxable = '0',
  // 면세 사업자
  DutyFree = '1',
  // 과면세 사업자
  TaxExempt = '2',
  // 간이 사업자
  SimpleBusiness = '3',
}

export enum SignupChapterEnum {
  // 가입 유형
  UserType = 0,
  // 계정 정보
  Account = 1,
  // 파트너 정보
  Partner = 2,
  // 정산 정보
  Payment = 3,
  // 계약서 서명
  // Contract = 4,
  // Confirm
  Confirm = 5,
}

export const SignupChapters = [
  SignupChapterEnum.UserType,
  SignupChapterEnum.Account,
  SignupChapterEnum.Partner,
  SignupChapterEnum.Payment,
  // SignupChapterEnum.Contract,
  SignupChapterEnum.Confirm,
];

export type SignupChapterValid = {
  [key in SignupChapterEnum]: boolean;
};
