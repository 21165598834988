import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import UploadField from './UploadField';
import { useRecoilState } from 'recoil';
import { idCardState } from 'user/User.atom';
import AttachFileIcon from 'assets/icon_attach_file';

type Props = {
  isCompany: boolean;
};
export default function IdCardField({ isCompany }: Props) {
  const [idCard, setIdCard] = useRecoilState(idCardState);

  const handleUploadFile = (fileName: string, key: string) => {
    setIdCard({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveUploadFile = () => {
    setIdCard(null);
  };

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body2'} color={'grey.800'} fontWeight={600}>
        {isCompany ? '사업자등록증' : '신분증'} 사본
      </Typography>
      <Typography marginTop="2px !important" fontSize={12} lineHeight={'16px'} color={'grey.600'}>
        {!isCompany && '마스킹 하지 않은 신분증 사본을 입력해주세요. / '}최대 업로드 파일 크기 : 10MB
      </Typography>

      {!idCard && <UploadField onChange={handleUploadFile} />}

      {idCard && (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={1} maxWidth="80%">
            <AttachFileIcon sx={{ flexShrink: 0 }} />
            <Typography fontSize={14} lineHeight={'22px'} textOverflow="ellipsis" overflow="hidden" flexShrink={1}>
              {idCard.name}
            </Typography>
          </Stack>

          <Button variant={'outlined'} onClick={handleRemoveUploadFile}>
            삭제하기
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
