import React from 'react';
import { Box } from '@mui/material';
import { Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Expansion, SalesRecordsListProps } from './SalesRecordsList.types';
import { OrderLicenseAuthorOutput, OrderLicenseOutput, OrderLicenseProjectOutput, OrderLicenseType, OrderProductItemOutput } from '../../../generated/graphql';
import DesktopSalesRecordsItem from '../SalesRecordsItem/DesktopSalesRecordsItem';
import MobileSalesRecordsItem from '../SalesRecordsItem/MobileSalesRecordsItem';
import { NoItemImage } from './SalesRecordsList.styles';
import { useTranslation } from 'react-i18next';

export default function SalesRecordsList({ productItems }: SalesRecordsListProps) {
  const theme = useDesignSystemTheme();

  const { t } = useTranslation();

  const [expansion, setExpansion] = React.useState<Expansion>({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false });

  function renderSubtext(license: OrderLicenseOutput) {
    const licenseType = renderLicenseType(license.type);
    const authors = renderAuthors(license.authors);
    if (license.type === OrderLicenseType.Company || license.type === OrderLicenseType.Enterprise_5) {
      const project = renderProject(license.project);
      return `${licenseType} | ${authors} | ${project}`;
    }
    return `${licenseType} | ${authors}`;
  }

  function renderLicenseType(licenseType: OrderLicenseType) {
    switch (licenseType) {
      case OrderLicenseType.Personal:
        return t('salesRecordsPage.item.licenseType.personal');
      case OrderLicenseType.Company:
        return t('salesRecordsPage.item.licenseType.company');
      case OrderLicenseType.Enterprise_5:
        return t('salesRecordsPage.item.licenseType.enterprise_5');
      case OrderLicenseType.Enterprise_0:
        return t('salesRecordsPage.item.licenseType.enterprise_0');
    }
  }

  function renderAuthors(authors: OrderLicenseAuthorOutput[]) {
    const firstAuthor = authors[0].name;
    return authors.length > 1 ? t('salesRecordsPage.item.author.authors', { firstAuthor, rest: authors.length - 1 }) : firstAuthor;
  }

  function renderProject(project: OrderLicenseProjectOutput[]) {
    if (project.length === 0) return t('salesRecordsPage.item.project.untitled');
    const firstProject = project[0].name;
    return project.length > 1 ? t('salesRecordsPage.item.project.projects', { firstProject, rest: project.length - 1 }) : firstProject;
  }

  function makeItemExpandHandler(idx: number) {
    return () => {
      if (expansion[idx]) setExpansion({ ...expansion, [idx]: false });
      else setExpansion({ ...expansion, [idx]: true });
    };
  }

  function calcSalePrice(item: OrderProductItemOutput) {
    return item.salePrice - calcBurdenPrice(item);
  }

  function calcBurdenPrice(item: OrderProductItemOutput) {
    return item.benefitBurdenPrice + item.couponGoodsBurdenPrice;
  }

  // 조회내역 없을 때 보여주는 이미지 preload
  React.useEffect(() => {
    const preloadedImg = new Image();
    preloadedImg.src = 'https://acon3d.blob.core.windows.net/public-images/partnerhub-sales-records-no-item.png';
  }, []);

  return (
    <Box
      sx={{
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        minHeight: '896px',
        [theme.breakpoints.down('largeTablet')]: {
          minHeight: 'unset',
          backgroundColor: theme.palette['color/white'],
          marginTop: '8px',
          gap: 0,
          padding: '16px 0',
        },
      }}
    >
      {productItems.length === 0 ? (
        <Box
          sx={{
            width: '100%',
            height: '1038px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            [theme.breakpoints.down('largeTablet')]: {
              height: '193px',
              gap: '16px',
            },
          }}
        >
          <NoItemImage src={'https://acon3d.blob.core.windows.net/public-images/partnerhub-sales-records-no-item.png'} alt={'partnerhub-sales-records-no-item'} loading={'eager'} />
          <Typography
            variant={'typography/body/small/regular'}
            sx={{
              [theme.breakpoints.down('largeTablet')]: {
                fontWeight: 400,
              },
            }}
          >
            {t('salesRecordsPage.item.noItemMessage')}
          </Typography>
        </Box>
      ) : (
        productItems.map((item, idx, array) => (
          <React.Fragment key={item.id}>
            <DesktopSalesRecordsItem
              item={item}
              onItemExpand={makeItemExpandHandler(idx)}
              calcSalePrice={calcSalePrice}
              calcBurdenPrice={calcBurdenPrice}
              renderSubtext={renderSubtext}
              isExpanded={expansion[idx]}
              renderLicenseType={renderLicenseType}
            />
            <MobileSalesRecordsItem
              item={item}
              isLastItem={idx === array.length - 1}
              onItemExpand={makeItemExpandHandler(idx)}
              calcSalePrice={calcSalePrice}
              calcBurdenPrice={calcBurdenPrice}
              renderSubtext={renderSubtext}
              isExpanded={expansion[idx]}
              renderLicenseType={renderLicenseType}
            />
          </React.Fragment>
        ))
      )}
    </Box>
  );
}
