import React from 'react';
import QueryString from 'query-string';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

export const useUrl = () => {
    const history = useHistory();
    const { page } = useParams();

    const data = (() => {
        const { search } = history.location;
        const urlParamObj = QueryString.parse(search);

        if (page)
            urlParamObj.page = Number(page);
        if (!page)
            urlParamObj.page = 1;

        if (!urlParamObj.limit)
            urlParamObj.limit = 20;

        if (!urlParamObj.searchType)
            urlParamObj.searchType = 'title';

        if (!urlParamObj.keyword)
            urlParamObj.keyword = '';

        if (urlParamObj.typeId)
            urlParamObj.typeId = Number(urlParamObj.typeId);

        urlParamObj.page = Number(urlParamObj.page);
        urlParamObj.limit = Number(urlParamObj.limit);
        return urlParamObj;
    })();

    const push = ({ page, limit, searchType, keyword, lang, typeId }) => {
        const { pathname, search } = history.location;

        const urlParamObj = QueryString.parse(search);

        if (lang !== null && lang !== undefined)
            urlParamObj.lang = lang;

        if (page)
            urlParamObj.page = Number(page);

        if (limit)
            urlParamObj.limit = Number(limit);

        if (searchType)
            urlParamObj.searchType = searchType;

        if (keyword)
            urlParamObj.keyword = keyword;

        if (typeId)
            urlParamObj.typeId = Number(typeId);

        // 검색 쿼리 
        const query = QueryString.stringify(urlParamObj);

        history.push({
            pathname, search: query
        })
    };

    return { data, push };
};
