import React, { useMemo } from "react";
import * as diff from "diff";
import styled from "styled-components";
import parse from "html-react-parser";

const HtmlView = styled.div`
  height: 800px;
  padding: 12px 15px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);

  .news {
    background-color: tomato !important;

    img {
      border: 1px solid tomato;
    }
  }
  img.news {
    border: 1px solid tomato;
  }

  .olds {
    background-color: skyblue !important;
    img {
      border: 1px solid skyblue;
    }
  }
  img.olds {
    border: 1px solid skyblue;
  }
`;

const htmlTags = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "strong",
  "p",
  "ul",
  "iframe",
  "ol",
  "li",
  "pre",
  "blockquote",
  "em",
  "u",
  "img",
];
const htmlTagRegExpTuple = htmlTags.map((tag) => [
  tag,
  new RegExp(`<${tag}`, "gi"),
]);

export default ({ oldStr, newStr, origin }) => {
  return useMemo(() => {
    const contents = JSON.parse(JSON.stringify(diff.diffWords(oldStr, newStr)));

    const replace = (
      t: { count: number; added?: boolean; removed?: boolean; value: string },
      index: number,
      array,
      isNew: boolean
    ) => {
      if (isNew ? t.added : t.removed) {
        if (index > 0) {
          const previousStr = array[index - 1].value;
          if (previousStr.at(-1) === "<" && htmlTags.includes(t.value)) {
            t.value = `${t.value} ${isNew ? 'class="news"' : 'class="olds"'} `;
            return void 0;
          }
        }

        htmlTagRegExpTuple.forEach(([tag, tagRegExp]) => {
          t.value = t.value.replace(
            tagRegExp,
            `<${tag} ${isNew ? 'class="news"' : 'class="olds"'} `
          );
        });
      }
    };

    const news: { count: number; added: boolean; value: string }[] =
      contents.filter((t) => !t.removed);
    const olds: { count: number; removed: boolean; value: string }[] =
      contents.filter((t) => !t.added);

    news.forEach((left, index, arr) => {
      replace(left, index, arr, true);
    });

    olds.forEach((right, index, arr) => {
      replace(right, index, arr, false);
    });

    const aggregatedNews = news.map((left) => left.value).join("");
    const aggregatedOlds = olds.map((right) => right.value).join("");

    return (
      <HtmlView>{parse(origin ? aggregatedOlds : aggregatedNews)}</HtmlView>
    );
  }, [oldStr, newStr, origin]);
};
