/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { PreviewAconProductModalContainer } from './PreviewAconProductModal.styles';
import { IPreviewAconProductModalProps } from './PreviewAconProductModal.types';
import { Modal } from 'react-bootstrap';

const PreviewAconProduct = ({ docId, lang, show, close }: IPreviewAconProductModalProps): React.ReactElement => {
  return (
    <PreviewAconProductModalContainer show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title />
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={`${process.env.NETWORK_PROTOCOL_GRAPHQL}://${process.env.NETWORK_HOST_PREVIEW}/${lang}/toon/product-preview/${docId}`}
          title="acon-preview"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Modal.Body>
    </PreviewAconProductModalContainer>
  );
};

export default PreviewAconProduct;
