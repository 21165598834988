import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'rsuite';
import palette from 'theme/palette';

export const CalendarContainer = styled.div<React.CSSProperties>`
  //header-cell
  .fc-col-header-cell {
    height: 48px;
    border-right: 1px solid transparent;
    a {
      color: #212b36;
      line-height: 48px;
    }
  }
  .fc-toolbar-title {
    font-size: 20px;
  }
  .fc-h-event {
    background-color: transparent !important;
    border: transparent !important;
  }
  //today-cell
  .fc-day-today {
    background-color: transparent !important;
  }
  th,
  td {
    border-right: none;
  }
  .fc-scrollgrid {
    border-left: none;
  }
`;

export const CalendarTooltip = styled(Tooltip)`
  font-weight: bold;
  z-index: 1030;
  & .rs-tooltip-inner {
    background-color: ${palette.light.orange[0]};
  }
  & .rs-tooltip-arrow {
    border-top-color: ${palette.light.orange[0]} !important;
    border-width: 7px 6px 0 !important;
  }
`;
