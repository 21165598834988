import React from 'react';
import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { LabelFieldType } from 'user/type';

type Props = TextFieldProps & LabelFieldType;

export default function LabelInputField({ label, value, onChange, placeholder }: Props) {
  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body2'} color={'grey.800'} fontWeight={600}>
        {label}
      </Typography>

      <TextField placeholder={placeholder} value={value} onChange={onChange} size="small" />
    </Stack>
  );
}
