import { styled, Typography } from "@mui/material";
import { Box, Dialog } from "@mui/material";
import { color } from "acon-mui/style";
import React from "react";
import Button from "./Button";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  leftButtonText?: React.ReactNode;
  onClickLeftButton?: () => void;
  rightButtonText: React.ReactNode;
  onClickRightButton: () => void;
}

const Description = styled('p')`
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
`;

export default function Modal({
  isOpen,
  onClose,
  title,
  description,
  leftButtonText,
  onClickLeftButton,
  rightButtonText,
  onClickRightButton
}: Props) {
  return (
    <Dialog
      open={isOpen} 
      onClose={onClose}
      transitionDuration={0}
      componentsProps={{
        backdrop: {
          style: {
            background: '#00000011'
          },
          onClick: onClose
        }
      }}
    >
      <Box
        p={3}
        minWidth="400px"
        bgcolor="#fff"
        borderRadius="16px"
        boxShadow="-40px 40px 80px -8px rgba(145, 158, 171, 0.24)"
        textAlign="center"
        color={color.text.primary}
      >
        <Typography fontWeight="700" fontSize="18px" lineHeight="28px">
          {title}
        </Typography>
        <Description
          dangerouslySetInnerHTML={{
            __html: description.replaceAll('\n', '<br/>')
          }}
        />
        <Box mt={3} display="flex" width={leftButtonText && onClickLeftButton ? "fit-content" : "100%"}>
          {leftButtonText && onClickLeftButton && (
            <Button onClick={onClickLeftButton} width="170px" height="48px" marginRight="12px">
              {leftButtonText}
            </Button>
          )}
          <Button 
            colorTheme="primary"
            variant="contained"
            onClick={onClickRightButton} 
            width={leftButtonText && onClickLeftButton ? "170px" : "100%"}
            height="48px"
            padding="6px 8px"
          >
            {rightButtonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
