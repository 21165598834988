import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { uploadFile } from 'api/index';
import { AppContext } from 'app';

type Props = {
  onChange: (fileName: string, key: string) => void;
};

export default function UploadField({ onChange }: Props) {
  const { t } = useTranslation();
  const { showAlertMessage } = useContext(AppContext);
  const fileRef = useRef(null);
  const handleChange = async (e) => {
    const file = e.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      showAlertMessage(t('fileSizeLimit.title'), t('fileSizeLimit.content'));
      return;
    }
    const fileResult = await uploadFile(file);
    onChange(file.name, fileResult.key);
  };

  const handleUploadButtonClick = () => {
    fileRef.current.value = null;
    fileRef.current.click();
  };

  return (
    <Box>
      <input ref={fileRef} hidden type="file" onChange={handleChange} />
      <Button variant="outlined" size={'medium'} onClick={handleUploadButtonClick}>
        {t('user.settle.modify.upload.button')}
      </Button>
    </Box>
  );
}
