import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../style';
import { useQuery } from '@apollo/client';
import { AppContext } from 'app';
import ACON from 'lib/global';
import {
  GET_GOODS_VIEW_PREVIEW_PAGE_URL,
  STATUS_COMPLETE_ON_OPEN,
  NEW_STATUS_DRAFT,
  STATUS_DRAFT_ON_OPEN,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_COMPLETE,
  STATUS_REJECTED,
  STATUS_IN_REVIEW,
  STATUS_DRAFT,
  STATUS_REJECTED_ON_OPEN,
  SKETCHUP_EXTENSION_CODE,
} from 'boards/DetailBoardWrite/constants';
import { GET_IS_VALID_GODO_GOODS_NO } from 'api/quries';
import { CommentModal } from 'boards/DetailBoardWrite/modal';
import {
  Language_Code,
  useDeleteOpenrunPromotionMutation,
  useGetPartnerQuery,
  useModifyDocumentMutation,
  useRegisterOpenrunPromotionMutation,
  useRequestDocumentReviewMutation,
  useRetryMutation,
  useSaveDocumentMutation,
} from 'generated/graphql';
import { Box } from '@mui/material';
import { Button } from 'acon-mui/components';
import ScrollSelector from 'boards/DetailBoardWrite/component/ScrollSelector';
import uuidv4 from 'utils/uuidv4';
import Bubble from 'boards/DetailBoardWrite/component/Bubble';
import parse from 'html-react-parser';
import { useDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { EOpenrunOptions } from 'boards/DetailBoardWrite/stores/document.types';
import { removeQuotes } from 'utils/removeQuotes';
import { getStringSizeInBytes } from 'utils/getStringSizeInBytes';

export default ({ refetch, isInProgress, translateModalType, inputs, showPreviewModal }) => {
  // 로딩 여부
  const [isLoading, setIsLoading] = useState(false);
  // 메뉴 고정여부
  const [isFixed, setIsFixed] = useState(false);

  // 오픈런 프로모션 모달 관련 처리
  // const [isShowRegistedModal, setIsShowRegistedModal] = useState(false);

  // 사용자 정보
  const { showAlertMessage, showConfirmMessage, userInfo } = useContext(AppContext);
  // 상태
  const { documentData } = useDocumentStore((state) => ({
    documentData: {
      status: state.status,
      isEdit: state.isEdit,
      title: state.title,
      id: state.id,
      assetId: state.assetId,
      contentHead: state.contentHead,
      contentBody: state.contentBody,
      mainImage: state.mainImage,
      subImage: state.subImage,
      file: state.file,
      categories: state.categories,
      price: state.price,
      openrun: state.openrun,
      language: state.language,
      updateRequest: state.updateRequest,
      tags: state.tags,
      industries: state.industries,
      isAdultOnly: state.isAdultOnly,
      brand: state.brand,
      copyright: state.copyright,
      licenseOptions: state.licenseOptions,
      licenseAgree: state.licenseAgree,
      applications: state.applications,
      extensions: state.extensions,
      fileComponents: state.fileComponents,
      book: state.book,
    },
  }));
  const { setCopyright } = useDocumentStore((state) => ({ setCopyright: state.setCopyright }));
  const { selectedFields } = useSelectedFieldsStore((state) => ({ selectedFields: state.selectedFields }));

  // 의견 모달 속성
  const [commentModalProperty, setCommentModalProperty] = useState({
    isShow: false,
    onConfirmCallback: null,
  });

  // 저장하기 액션
  const [saveDocument] = useSaveDocumentMutation();

  // 오픈런 프로모션 등록 액션
  const [registerOpenrun] = useRegisterOpenrunPromotionMutation();
  // 오픈런 프로모션 삭제 액션
  const [deleteOpenrun] = useDeleteOpenrunPromotionMutation();

  // 검토요청 액션
  const [requestReview] = useRequestDocumentReviewMutation({
    onError: (error) => {
      showAlertMessage(t('contactAdmin'), error.message);
    },
  });
  // 상품 수정하기 액션
  const [modifyDocumentMutation] = useModifyDocumentMutation({
    onError: (error) => {
      showAlertMessage(t('contactAdmin'), error.message);
    },
  });
  // 상품 수정하기 액션
  const [retryMutation] = useRetryMutation({
    onError: (error) => {
      showAlertMessage(t('contactAdmin'), error.message);
    },
  });
  // URL 파생 정보
  const { docId, lang, type } = useParams<{ type?: string; docId?: string; lang?: string }>();

  // 번역도구
  const { t, i18n } = useTranslation();
  // history
  const history = useHistory();

  // 오픈 여부
  const isOpen = type ? true : false;

  // 관리자 여부
  const { isAdmin } = userInfo;
  const { data: partnerData } = useGetPartnerQuery();

  // 브랜드 가져오기
  const getIsValidGodoGoodsNo = useQuery(GET_IS_VALID_GODO_GOODS_NO, {
    skip: true,
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const isTopMenu = document.getElementsByClassName('menu').length !== 0;
      const standard = isTopMenu ? 175 : 160;
      // 메뉴 고정여부 설정
      setIsFixed(document.documentElement.scrollTop >= standard);
    });
  });

  // 오픈런 등록
  const onRegisterOpenrun = async (savedDocId: string, isTemporarySave?: boolean) => {
    try {
      if (documentData.openrun === EOpenrunOptions.APPLICATION) {
        await registerOpenrun({
          variables: {
            input: {
              id: savedDocId,
              brandId: documentData.brand,
            },
          },
        });
      } else if (documentData.openrun === EOpenrunOptions.NON_APPLICATION && isTemporarySave) {
        await deleteOpenrun({
          variables: {
            input: {
              id: savedDocId,
              brandId: documentData.brand,
            },
          },
        });
      }
    } catch (e) {
      throw new Error('오픈런 등록을 할 수 없습니다.');
    }
  };

  // 검토요청 버튼 클릭 이벤트 메소드 (비동기)
  const onClickRequestReviewButton = () => {
    // 상태 변경을 할 수 없는경우
    if (documentData.status === STATUS_DRAFT && !isValidRequest())
      // 종료
      return;
    if (documentData.isEdit && selectedFields.length < 1) {
      return;
    }

    // 각 head,body 컨텐츠의 크기는 1MB 이상일 수 없다.
    const checkSizeContentHead = Number((getStringSizeInBytes(documentData.contentHead) / (1024 * 1024)).toFixed(2)) > 1;
    const checkSizeContentBody = Number((getStringSizeInBytes(documentData.contentBody) / (1024 * 1024)).toFixed(2)) > 1;

    // 두개중에 하나라도 1MB 이상이면 경고창 띄우기
    if (checkSizeContentHead || checkSizeContentBody) {
      showAlertMessage(t('detailBoard.modal.imageUploadFailed'), t('detailBoard.modal.imageUploadFailedDesc'));
    } else {
      // 검토요청 메세지 표시
      showConfirmMessage(
        documentData.isEdit ? t('request.update.title') : t('request.registration.title'),
        documentData.isEdit
          ? t('request.update.description', {
              selected: selectedFields.length > 0 ? selectedFields.join(', ') : 'NaN',
            })
          : documentData.openrun === EOpenrunOptions.APPLICATION
          ? t('request.registration.descriptionWithopenrun')
          : t('request.registration.description'),
        async (val?: string) => {
          try {
            // 상태 변경을 할 수 없는경우
            if (!isValidRequest(val)) return;

            setIsLoading(true);

            // 저장
            const savedDocId = await save();

            // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
            if (!docId && savedDocId) {
              // 이동 될 경로
              const toPushUrl = `/goods/write/${lang}/${savedDocId}`;
              // 경로 변경
              history.push(toPushUrl);
            } else {
              await refetch();
            }

            // 상태 변경을 할 수 없는경우
            if (!isValidRequest(val))
              // 종료
              return;

            // 저장이 안 된 경우
            if (!savedDocId) {
              // 에러 표시
              throw new Error('검토요청을 할 수 없습니다.');
            }

            // 변경하기
            const isRequest = await requestReview({
              variables: {
                input: {
                  id: savedDocId,
                  ...(documentData.isEdit && {
                    message: val,
                  }),
                },
              },
            });

            // 변경이 완료되지 못한경우
            if (!isRequest) throw new Error('검토요청을 할 수 없습니다.');
            // 오픈런 등록
            await onRegisterOpenrun(savedDocId);

            setIsLoading(false);
            // 사용자 안내 메세지 표시
            showAlertMessage(t('completedRequestModal.Title'), documentData.isEdit ? t('completedRequestModal.contents2') : t('completedRequestModal.contents'), {
              callback: () => {
                // 홈으로 이동
                history.push('/');
                window.location.reload();
              },
            });
          } catch (errObj) {
            setIsLoading(false);
            // 사용자 안내 메세지 표시
            showAlertMessage(t('contactAdmin'), errObj.message);
          }
        },
        {
          cancelText: t('document.back'),
          confirmText: documentData.isEdit ? t('document.UpdateRequest') : t('document.request'),
          ...(documentData.isEdit && {
            showTextField: true,
            textFieldPlaceholder: t('request.update.placeholder'),
            textFieldRows: 2,
            textFieldRequired: true,
          }),
        },
      );
    }
  };

  const checkSketchupMessage = () => {
    if (documentData?.extensions?.includes(SKETCHUP_EXTENSION_CODE)) {
      showConfirmMessage(t('document.checkSketchUpTitle'), t('document.checkSketchUpContent'), onClickRequestReviewButton, {
        cancelText: t('document.back'),
        confirmText: t('ExternalTrademark.confirm'),
      });
      return;
    }
    onClickRequestReviewButton();
  };

  // 저장하기 유효성검사 메소드
  const isValidSave = async (targetStatus) => {
    const { title, assetId, copyright, price, status } = documentData;

    try {
      // 제목이 입력되지 않은경우
      if (!title) {
        // 에러 호출
        throw new ACON.ValidationError(t('PleaseEnterATitle.label'));
      }
      // 대상 상태가 존재할 경우,
      if (targetStatus && (targetStatus === STATUS_COMPLETE || status === STATUS_COMPLETE || status === STATUS_COMPLETE_ON_OPEN)) {
        // 판매가가 입력되지 않았을 경우
        if (typeof price !== 'number') {
          // 에러 호출
          throw new ACON.ValidationError(isAdmin ? t('isNotValidAdminSalesPrice') : t('isNotValidSalesPrice'));
        }
        if (
          !copyright.isOriginal &&
          copyright.warehouseSources === '' &&
          inputs.warehouseInput &&
          inputs.warehouseInput.current &&
          (!inputs.warehouseInput.current.value || inputs.warehouseInput.current.value === '')
        ) {
          inputs.warehouseInput && inputs.warehouseInput.current && inputs.warehouseInput.current.focus();
          setCopyright({ ...copyright, isErrorWarehouseCopyright: true });

          throw new ACON.ValidationError(t('pleaseEnterWarehouseSource'));
        }
        if (
          !copyright.isOriginal &&
          copyright.commercialSources === '' &&
          inputs.externalFreeSourceInput &&
          inputs.externalFreeSourceInput.current &&
          (!inputs.externalFreeSourceInput.current.value || inputs.externalFreeSourceInput.current.value === '')
        ) {
          inputs.externalFreeSourceInput && inputs.externalFreeSourceInput.current && inputs.externalFreeSourceInput.current.focus();
          setCopyright({ ...copyright, isErrorFreeCopyright: true });
          throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
        }
        if (!copyright.isOriginal && typeof copyright.hasRealLogo === 'undefined') {
          throw new ACON.ValidationError(t('pleaseEnterRealLogo'));
        }
      }
      // 관리자 유효성 검사
      if (isAdmin) {
        // 고도몰 상품번호가 존재하지 않는경우
        if (!assetId && targetStatus && (targetStatus === STATUS_COMPLETE || targetStatus === STATUS_COMPLETE_ON_OPEN)) {
          // 에러 호출
          throw new ACON.ValidationError(t('pleaseEnterGodoGoodsNo'));
        }
        if (assetId) {
          // int type을 벗어난 경우
          if (Number(assetId) > 2147483647) {
            throw new ACON.ValidationError('고도몰 상품번호가 올바르지 않습니다.');
          }
          // 고도몰 상품번호가 존재할 경우
          // 고도몰 번호 여부 체크
          const isValidGodoGoodsNo = await getIsValidGodoGoodsNo.refetch({
            docId,
            assetId,
          });
          // 고도몰 번호가 존재할 경우, 메세지 표시
          if (isValidGodoGoodsNo.data.isValidGodoGoodsNo) {
            // 에러 호출
            throw new ACON.ValidationError(t('overlapGoodsNo'));
          }
        }
      }
    } catch (errObj) {
      // 에러가 유효성검사 실패 에러일 경우
      if (errObj instanceof ACON.ValidationError) {
        setIsLoading(false);
        showAlertMessage(t('UnableToCompleteSave.label'), errObj.message);
        // 종료
        return;
      }
      // 에러 로그 기록 (TO DO)
    }
    return true;
  };

  // 상태 변경 유효성검사
  const isValidRequest = (val?: string) => {
    const {
      title,
      contentHead,
      contentBody,
      mainImage: { path: mainImagePath, isComplete: isMainImageComplete },
      subImage: { path: subImagePath, isComplete: isSubImageComplete },
      copyright,
      file: { filePath, fileName },
      categories,
      price,
      industries,
      tags,
    } = documentData;

    try {
      // 카테고리가 선택되지 않거나 leaf node가 아닌 경우
      if (!categories || categories.length === 0 || (categories[0]?.children && categories[0]?.children?.length > 0)) {
        throw new ACON.ValidationError(t('pleaseSelectCategories'));
      }
      // 태그가 선택되지 않은 경우
      if (!tags || tags.length === 0) {
        throw new ACON.ValidationError(t('document.tags.pleaseSelectTags'));
      }
      // 사용 분야가 선택되지 않은 경우
      if (!industries || industries.length === 0) {
        throw new ACON.ValidationError(t('document.pleaseSelectIndustries'));
      }
      if (!industries || industries.length === 0) {
        throw new ACON.ValidationError(t('document.pleaseSelectIndustries'));
      }
      // 제목이 입력되지 않은경우
      if (!title) {
        // 에러 호출
        throw new ACON.ValidationError(t('PleaseEnterATitle.label'));
      }
      // 모델 url 도 없고, key name 도 없는경우
      if (!filePath || !fileName) {
        // 에러 호출
        throw new ACON.ValidationError(t('pleaseEnterModel'));
      }
      // 사용자의 경우, 희망 판매가가 비어있을 경우
      if (!isAdmin && typeof price !== 'number') {
        // 에러 호출
        throw new ACON.ValidationError(t('pleaseEnterSalesPrice'));
      }
      if (
        !copyright.isOriginal &&
        copyright.warehouseSources === '' &&
        (!inputs.warehouseInput || (inputs.warehouseInput && inputs.warehouseInput.current && (!inputs.warehouseInput.current.value || inputs.warehouseInput.current.value === '')))
      ) {
        inputs.warehouseInput && inputs.warehouseInput.current && inputs.warehouseInput.current.focus();
        setCopyright({ ...copyright, isErrorWarehouseCopyright: true });

        throw new ACON.ValidationError(t('pleaseEnterWarehouseSource'));
      }
      if (
        !copyright.isOriginal &&
        copyright.commercialSources === '' &&
        (!inputs.externalFreeSourceInput ||
          (inputs.externalFreeSourceInput &&
            inputs.externalFreeSourceInput.current &&
            (!inputs.externalFreeSourceInput.current.value || inputs.externalFreeSourceInput.current.value === '')))
      ) {
        inputs.externalFreeSourceInput && inputs.externalFreeSourceInput.current && inputs.externalFreeSourceInput.current.focus();
        setCopyright({ ...copyright, isErrorFreeCopyright: true });
        throw new ACON.ValidationError(t('pleaseEnterOtherSource'));
      }
      if (!copyright.isOriginal && typeof copyright.hasRealLogo === 'undefined') {
        throw new ACON.ValidationError(t('pleaseEnterRealLogo'));
      }
      if (!copyright.isOriginal && copyright.hasRealLogo === 'YES') {
        throw new ACON.ValidationError(t('ExternalTrademark.description'));
      }

      // 대표 이미지가 설정되지 않은경우
      if (!mainImagePath || !isMainImageComplete) {
        // 에러 호출
        throw new ACON.ValidationError(t('pleaseEnterMainImage'));
      }
      // 서브 이미지가 설정되지 않은경우
      if (!subImagePath || !isSubImageComplete) {
        // 에러 호출
        throw new ACON.ValidationError(t('pleaseEnterSubImage'));
      }
      const head = contentHead.replace(/(<([^>]+)>)/gi, '');
      const body = contentBody.replace(/(<([^>]+)>)/gi, '');
      if (!head || head?.length === 0) {
        throw new ACON.ValidationError(t('pleaseEnterContentHead'));
      }
      if (!body || body.length === 0) {
        throw new ACON.ValidationError(t('pleaseEnterContentBody'));
      }

      if (documentData.isEdit && !isAdmin && documentData.status === STATUS_DRAFT_ON_OPEN && (!val || val.length === 0)) {
        throw new ACON.ValidationError(t('checkEditMessage'));
      }

      return true;
    } catch (errObj) {
      // 에러가 유효성검사 실패 에러일 경우
      if (errObj instanceof ACON.ValidationError) {
        setIsLoading(false);
        showAlertMessage(t('goods.unableComplteRequest'), errObj.message);
        // 종료
        return false;
      }
      // 에러 로그 기록(TODO)
    }
    return true;
  };

  // 저장하기 메소드
  const save = async (targetStatus?: string) => {
    // 저장 유효성검사 실패 시
    if (!(await isValidSave(targetStatus)))
      // 작업 종료
      return false;
    const {
      id,
      brand,
      title,
      contentHead,
      contentBody,
      assetId,
      status,
      language,
      mainImage,
      subImage,
      file,
      price,
      categories,
      copyright,
      tags,
      industries,
      isAdultOnly,
      licenseOptions,
      applications,
      extensions,
      fileComponents,
      book,
    } = documentData;

    let data: any = {};

    const documentId = !id && status === NEW_STATUS_DRAFT ? uuidv4().replace(/-/g, '') : id;

    let warehouseSources = '';
    let commercialSources = '';
    if (inputs.warehouseInput && inputs.warehouseInput.current && inputs.warehouseInput.current.value !== '') {
      warehouseSources = removeQuotes(inputs.warehouseInput.current.value || '');
    }
    if (inputs.externalFreeSourceInput && inputs.externalFreeSourceInput.current && inputs.externalFreeSourceInput.current.value !== '') {
      commercialSources = removeQuotes(inputs.externalFreeSourceInput.current.value || '');
    }
    data = {
      id: documentId,
      brandId: brand,
      title,
      contentHead,
      contentBody,
      ...(assetId && { brandId: assetId }),
      status,
      book,
      mainImage: mainImage.path,
      mainOrigin: mainImage?.origin,
      ...(typeof mainImage?.cropInfo?.imageOriginX === 'number' &&
        typeof mainImage?.cropInfo?.imageOriginY === 'number' &&
        typeof mainImage?.cropInfo?.imageWidth === 'number' &&
        typeof mainImage?.cropInfo?.imageHeight === 'number' && {
          mainCoordination: {
            x: mainImage?.cropInfo?.imageOriginX,
            y: mainImage?.cropInfo?.imageOriginY,
            width: mainImage?.cropInfo?.imageWidth,
            height: mainImage?.cropInfo?.imageHeight,
          },
        }),
      subImage: subImage.path,
      subOrigin: subImage?.origin,
      ...(typeof subImage?.cropInfo?.imageOriginX === 'number' &&
        typeof subImage?.cropInfo?.imageOriginY === 'number' &&
        typeof subImage?.cropInfo?.imageWidth === 'number' &&
        typeof subImage?.cropInfo?.imageHeight === 'number' && {
          subCoordination: {
            x: subImage?.cropInfo?.imageOriginX,
            y: subImage?.cropInfo?.imageOriginY,
            width: subImage?.cropInfo?.imageWidth,
            height: subImage?.cropInfo?.imageHeight,
          },
        }),
      fileName: file.fileName,
      filePath: file.filePath,
      price,
      language: language as unknown as Language_Code,
      categories: categories.map((category) => ({ code: category.code })),
      copyright: {
        isOriginal: copyright.isOriginal,
        ...(copyright.isOriginal
          ? {
              warehouseSources: null,
              commercialSources: null,
              hasRealLogo: 'NO',
            }
          : {
              warehouseSources: copyright.warehouseSources === null ? copyright.warehouseSources : warehouseSources,
              commercialSources: copyright.commercialSources === null ? copyright.commercialSources : commercialSources,
              hasRealLogo: copyright.hasRealLogo,
            }),
      },
      tags: tags.map((tag) => ({ id: tag.id })),
      industries: industries.map((industry) => ({ id: industry.id })),
      isAdultOnly,
      licenseOptions,
      applications,
      extensions,
      fileComponents,
    };

    const result = await saveDocument({
      variables: {
        input: data,
      },
    });

    if (result.data.save) {
      return documentId;
    }
  };

  // 저장 버튼 클릭 이벤트 메소드
  const onClickSaveButton = (e) => {
    showConfirmMessage(
      t('goods.userSave'),
      t('document.TemporarySaveDescription'),
      async (e) => {
        try {
          setIsLoading(true);
          // 저장된 문서 ID

          const savedDocId = await save();

          if (savedDocId === false) {
            setIsLoading(false);
            // 종료
            return;
          }

          // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
          if (!docId && savedDocId) {
            // 이동 될 경로
            const toPushUrl = `/goods/write/${lang}/${savedDocId}`;
            // 경로 변경
            history.push(toPushUrl);
          }
          // 오픈런 등록
          await onRegisterOpenrun(savedDocId, true);
          await refetch();

          setIsLoading(false);
          // 사용자 안내 메세지 표시
          showAlertMessage(t('saved'), t('completedSaveContents'), {
            // 문서 ID가 존재하지 않았으나, 저장함으로 써 문서ID가 발행된 경우 (초안 저장)
            // 초안인 경우, 새로고침 해줌
            // 관련 이슈: https://carpenstreet.atlassian.net/browse/ACON-1802
            ...(!docId &&
              savedDocId && {
                callback: () => {
                  window.location.reload();
                },
              }),
          });
        } catch (errObj) {
          setIsLoading(false);
          // 사용자 안내 메세지 표시
          showAlertMessage(t('contactAdmin'), errObj.message);
        }
      },
      {
        cancelText: t('document.back'),
        confirmText: t('document.save'),
      },
    );
  };
  // 상품 수정하기 버튼 클릭 이벤트 처리기 메소드
  const onClickEditBtn = async () => {
    // 상품 상태 변경
    const variables = { input: { id: docId } };
    if ([STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN].includes(documentData.status)) {
      await modifyDocumentMutation({ variables });
    } else {
      await retryMutation({ variables });
    }

    // 상품 데이터 재확보
    await refetch();
  };

  // 미리보기 버튼 클릭 이벤트 처리기 메소드
  const onClickPreviewButton = () => {
    // 사용자는 무조건 모달형태의 미리보기
    // 관리자는 진행중일 경우에 고도몰 화면표시

    const { status, assetId } = documentData;
    // 현재 사용자가 관리자 그리고 진행중일 경우
    if (userInfo.isAdmin && (status === STATUS_IN_REVIEW || status === STATUS_IN_REVIEW_ON_OPEN)) {
      // 고도몰 상품번호를 입력하지 않은경우
      if (!assetId) {
        showAlertMessage(t('noGodoId'));
        // 종료
        return;
      }
      // 고도몰 화면 표시
      let godoUrl = GET_GOODS_VIEW_PREVIEW_PAGE_URL(assetId, lang);
      // 새창으로 표시
      window.open(godoUrl);
      // 종료
      return;
    }

    // 미리보기 보달 표시
    showPreviewModal();
    // 종료
    return;
  };

  // 요청 버튼
  const RequestButton = ({
    onClick,
    variant = 'contained',
    disabled = false,
    children,
  }: {
    onClick: (e?) => void;
    variant?: 'outlined' | 'contained';
    disabled?: boolean;
    children: React.ReactNode;
  }) => {
    const country = partnerData?.me?.settle.country;
    const hasNoPlan = (country === 'KR' && userInfo.hasntPlan && !userInfo.isAdmin) || (country !== 'KR' && !userInfo.isAccept && !userInfo.isAdmin);
    return (
      <Box ml="12px" position="relative">
        {hasNoPlan && (
          <Bubble bottom="60px" width="190px">
            {parse(t('document.requestBubble'))}
          </Bubble>
        )}
        {documentData.isAdultOnly && (
          <Box position="absolute" top="-28px" right="0">
            <img alt="adult-badge" src="/assets/icon/adult.svg" width={24} height={24} />
          </Box>
        )}
        <Button colorTheme="primary" variant={variant} onClick={onClick} disabled={disabled || hasNoPlan} minWidth="133px" width="fit-content" borderRadius="4px">
          {children}
        </Button>
      </Box>
    );
  };

  const requestBtn = (
    <RequestButton
      onClick={checkSketchupMessage}
      disabled={isInProgress || isLoading || (documentData.isEdit && selectedFields.length < 1) || (documentData.licenseOptions.length > 0 && !documentData.licenseAgree)}
    >
      {isOpen || documentData.status === STATUS_DRAFT_ON_OPEN ? t('document.UpdateRequest') : t('document.RegistrationRequest')}
    </RequestButton>
  );

  return (
    <>
      {/* <OpenRunRegistedModal isShow={isShowRegistedModal} onClose={() => setIsShowRegistedModal(false)} onSuccess={() => {}} /> */}
      <Loading show={isLoading} />
      {/* 의견 보기 */}
      <CommentModal
        isShow={commentModalProperty.isShow}
        onClick={commentModalProperty.onConfirmCallback}
        onClose={() => {
          setCommentModalProperty({ ...commentModalProperty, isShow: false });
        }}
      />
      {
        <Box
          position="fixed"
          bottom="0"
          left="0"
          padding="12px 20px"
          width="100%"
          zIndex="100"
          bgcolor="#fff"
          boxShadow="0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)"
          borderRadius="4px 4px 0px 0px"
        >
          <Box mx="auto" display="flex" maxWidth="1060px">
            <ScrollSelector />
            {(documentData.status === NEW_STATUS_DRAFT || documentData.status === STATUS_DRAFT_ON_OPEN) && documentData.id && (
              <Button colorTheme="primary" onClick={onClickPreviewButton} minWidth="100px" width="fit-content" marginLeft="12px" borderRadius="4px">
                {t('document.preview')}
              </Button>
            )}
            {/* {isAdmin && productData.status !== STATUS_OPEN && <Display disabled={isInProgress} godoGoodsNo={productData?.godoGoodsNo} onChange={onChangeStatusCombo} />} */}
            {(documentData.status === NEW_STATUS_DRAFT || documentData.status === STATUS_DRAFT_ON_OPEN) && (
              <Button colorTheme="primary" onClick={onClickSaveButton} disabled={isInProgress} minWidth="100px" width="fit-content" marginLeft="12px" borderRadius="4px">
                {t('document.TemporarySave')}
              </Button>
            )}
            {!isAdmin && [NEW_STATUS_DRAFT, STATUS_DRAFT_ON_OPEN].includes(documentData.status) && requestBtn}
            {/* 초안 문서에서 완료되었거나, 수정상태에서 완료된 경우에만 해당 버튼 표시*/}
            {!isAdmin && [STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN, STATUS_REJECTED, STATUS_REJECTED_ON_OPEN].includes(documentData.status) && (
              <RequestButton variant="outlined" onClick={onClickEditBtn}>
                {t('project.updateProject')}
              </RequestButton>
            )}
          </Box>
        </Box>
      }
    </>
  );
};
