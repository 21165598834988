import React from 'react';
import { Box } from '@mui/material';
import { Pagination, useDesignSystemTheme } from 'carpenstreet-designsystem';
import SettlementNotice from '../SettlementNotice/SettlementNotice';

export default function SettlementFooter({
  page,
  onPageChange,
  lastPage,
}: {
  page: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  lastPage: number;
}) {
  const theme = useDesignSystemTheme();

  return (
    <>
      <Box
        sx={{
          marginTop: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.down('largeTablet')]: {
            backgroundColor: theme.palette['color/white'],
            padding: '16px 0',
          },
        }}
      >
        <Pagination
          size={'M'}
          count={lastPage}
          page={page}
          onChange={onPageChange}
          sx={{
            [theme.breakpoints.down('largeTablet')]: {
              display: 'none',
            },
          }}
        />
        <Pagination
          size={'L'}
          count={lastPage}
          page={page}
          siblingCount={0}
          onChange={onPageChange}
          sx={{
            display: 'none',
            [theme.breakpoints.down('largeTablet')]: {
              display: 'block',
            },
          }}
        />
      </Box>
      <SettlementNotice
        containerSx={{
          display: 'none',
          marginTop: '8px',
          padding: '16px',
          flexDirection: 'column',
          gap: '8px',
          [theme.breakpoints.down('largeTablet')]: {
            display: 'flex',
          },
        }}
      />
    </>
  );
}
