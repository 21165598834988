import React, { useEffect, useState, useContext } from 'react';
import { TemplateDataContext } from '../../templateData'
import Event from './event'
export default (function (props) {
    const { data, filter } = props
    const { selectId, setSelectId, setSelectData, loading } = useContext(TemplateDataContext)
    const [render, setRender] = useState(<></>)

    var isSelected = false
    if (!data) {
        throw new Error('param error')
    }
    if (selectId === data._id) {
        isSelected = true
    }
    useEffect(() => {
        const onClick = Event(loading).onClick(setSelectId, setSelectData, data._id)
        var rowClass = ['row']
        var opt = {}
        if (!filterFunction(data, filter)) {
            opt = { style: { display: "none" } }
        }
        if (isSelected) {
            rowClass.push('select')
            setSelectData(data)
        }
        setRender(
            <div className={rowClass.join(' ')} {...opt}
                onClick={onClick}
            >{data.templateName}</div>
        )
    }, [data, filter, isSelected, setSelectId, loading, setSelectData])
    return render
});
function filterFunction(data, filter) {
    var result = true
    if (filter.trim() !== '') {
        if (typeof data.templateName === 'string' && data.templateName.indexOf(filter) >= 0) {
            result = true
        } else {
            result = false
        }
    }
    return result
}

