import { graphqlUrl } from 'api'
import axios from 'axios'
export async function createCategory(data) {
    var query = `
    mutation createCategory($name: String!, $memo: String!, $state: String!, $type: String!, $parentCategoryId: Float, $creator: Float, $updater: Float) {
      createCategory(name: $name, memo: $memo, state: $state, type: $type, parentCategoryId: $parentCategoryId, creator: $creator, updater: $updater) {
        id,
        i18n {
          id,
          categoryId,
          language {
            code
          },
          name,
          file {
            id,
            categoryI18nId,
            uploadFileId,
            uploadFile {
              id,
              fullName,
              path
            }
          },
          isShowResultCount,
          anchor,
          map,
          color,
          isBlankAnchor,
          creator,
          updater
        }
        type,
        sortNo,
        memo,
        parent,
        godoSno,
        godoCateCd,
        state,
        creator,
        updater
      }
    }
`
    var resultData
    try {
        resultData = await axios.post(graphqlUrl, {
            query,
            variables: data
        })
        resultData = resultData.data.data.createCategory
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}
export async function getCategoryInfo() {
    var query = `
    {
      getCategories {
        id,
        i18n {
          id,
          categoryId,
          language {
            code
          },
          name,
          file {
            id,
            categoryI18nId,
            uploadFileId,
            uploadFile {
              id,
              fullName,
              path
            }
          },
          isShowResultCount,
          anchor,
          map,
          color,
          isBlankAnchor,
          creator,
          updater
        }
        sortNo,
        memo,
        parent,
        godoSno,
        godoCateCd,
        state,
        type,
        creator,
        updater
      }
    }
`
    var resultData;
    let commonData = {
      "langCode": [
        {
          "id": "ko",
          "lang": {
            "jp": "韓国語",
            "cn": "韩语",
            "en": "Korean",
            "ko": "한글"
          },
          "sort": 1
        },
        {
          "id": "en",
          "lang": {
            "jp": "英語",
            "cn": "英語",
            "en": "English",
            "ko": "영어"
          },
          "sort": 2
        },
        {
          "id": "cn",
          "lang": {
            "jp": "中文",
            "cn": "中文",
            "en": "chinese",
            "ko": "중문"
          },
          "sort": 3
        },
        {
          "id": "jp",
          "lang": {
            "jp": "日本語",
            "cn": "日本語",
            "en": "japanese",
            "ko": "일본어"
          },
          "sort": 4
        }
      ],
      "categoryType": [
        {
          "id": "toonstyle",
          "lang": {
            "jp": "Toon-style",
            "cn": "卡通渲染",
            "en": "Toon-style",
            "ko": "웹툰"
          },
          "sort": 1
        },
        {
          "id": "realistic",
          "lang": {
            "jp": "Realistic",
            "cn": "真实渲染",
            "en": "Realistic",
            "ko": "게임"
          },
          "sort": 2
        }
      ]
    };

    try {
        resultData = await axios.post(graphqlUrl, {
            query,
        })
        resultData = resultData.data.data
        resultData = {
            getCategoryList: resultData.getCategories,
            langCode: commonData.langCode,
            categoryType: commonData.categoryType
        }
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}
export async function updateCategory(data) {
    var query = `
    mutation updateCategory($id: Float!, $parent: Float, $type: CategoryTypeEnum, $memo: String!, $state: String!, $i18n: [SetCategoryI18nInput!]!, $creator: Float, $updater: Float) {
      updateCategory(id: $id, parent: $parent, type: $type, memo: $memo, state: $state, i18n: $i18n, creator: $creator, updater: $updater) 
    }
  `
    var resultData
    try {
        resultData = await axios.post(graphqlUrl, {
            query,
            variables: data
        })
        resultData = resultData.data.data.updateCategory
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}
export async function deleteCategory(deleteId) {
    var query = `
  mutation deleteCategory($categoryId: Float!) {
    deleteCategory(categoryId: $categoryId)
  }
`
    var resultData
    try {
        resultData = await axios.post(graphqlUrl, {
            query,
            variables: {
              categoryId: deleteId
            }
        })
        resultData = resultData.data.data.deleteCategory
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}
export async function moveCategory(moveId, parentId, sortNo) {
    var query = `      
    mutation ($parentCategoryId: Float, $categoryId: Float!, $sortNo: Float!) {
      moveCategory(parentCategoryId: $parentCategoryId, categoryId: $categoryId, sortNo: $sortNo) {
        id,
        type {
          id,
          categoryId,
          name
        },
        i18n {
          id,
          categoryId,
          language {
            code
          },
          name,
          file {
            id,
            categoryI18nId,
            uploadFileId,
            uploadFile {
              id,
              fullName,
              path
            }
          },
          isShowResultCount,
          anchor,
          map,
          color,
          isBlankAnchor,
          creator,
          updater
        }
        sortNo,
        memo,
        parent,
        godoSno,
        godoCateCd,
        state,
        creator,
        updater
      }
    }
  `
    var resultData
    try {
        resultData = await axios.post(graphqlUrl, {
            query,
            variables: {
              parentCategoryId: parentId,
              categoryId: moveId,
              sortNo: sortNo
            }
        })
        resultData = resultData.data.data.moveCategory
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}
export async function syncCategory() {
    var query = `      
    mutation {
        syncCategory {
          id,
          type,
          i18n {
            id,
            categoryId,
            language {
              code
            },
            name,
            file {
              id,
              categoryI18nId,
              uploadFileId,
              uploadFile {
                id,
                fullName,
                path
              }
            },
            isShowResultCount,
            anchor,
            map,
            color,
            isBlankAnchor,
            creator,
            updater
          }
          sortNo,
          memo,
          parent,
          godoSno,
          godoCateCd,
          state,
          creator,
          updater
        }
   } 
  `
    var resultData
    try {
        resultData = await axios.post(graphqlUrl, {
            query,
        })
        resultData = resultData.data.data.syncCategory
    } catch (err) {
        console.log(err)
        console.log(resultData)
    }
    return resultData
}

export default {
    createCategory,
    updateCategory,
    deleteCategory,
    moveCategory,
    syncCategory,
    getCategoryInfo
}