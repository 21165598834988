import { LANG_CODE } from '../constants';
import { EOpenrunOptions, IDocumentState } from './document.types';

export const languageList = [LANG_CODE.KO, LANG_CODE.EN, LANG_CODE.ZH, LANG_CODE.JA];

export const initialDocumentState: IDocumentState = {
  isEdit: false,
  isDisp: false,
  id: undefined,
  assetId: undefined,
  brand: undefined,
  language: LANG_CODE.KO,
  originalLanguage: LANG_CODE.KO,
  status: undefined,
  title: undefined,
  contentHead: undefined,
  contentBody: undefined,
  file: {
    filePath: undefined,
    fileName: undefined,
    fileComponents: undefined,
    fileUpdates: undefined,
  },
  fileComponents: [],
  extensions: [],
  applications: [],
  mainImage: {
    path: undefined,
    name: undefined,
    cropInfo: {
      imageOriginX: undefined,
      imageOriginY: undefined,
      imageWidth: undefined,
      imageHeight: undefined,
    },
    isComplete: false,
  },
  subImage: {
    path: undefined,
    name: undefined,
    cropInfo: {
      imageOriginX: undefined,
      imageOriginY: undefined,
      imageWidth: undefined,
      imageHeight: undefined,
    },
    isComplete: false,
  },
  categories: [],
  price: undefined,
  openrun: EOpenrunOptions.NON_APPLICATION,
  tags: [],
  industries: [],
  memo: [],
  copyright: {
    isOriginal: true,
    commercialSources: undefined,
    warehouseSources: undefined,
    hasRealLogo: undefined,
    isErrorWarehouseCopyright: false,
    isErrorFreeCopyright: false,
    isErrorAgree: false,
  },
  created: undefined,
  requested: undefined,
  completed: undefined,
  version: undefined,
  updateRequest: undefined,
  priceChanged: undefined,
  isAdultOnly: false,
  message: undefined,
  changeHistories: [],
  licenseOptions: [],
  licenseScales: [],
  licenseAgree: false,
  book: null,
};
