import React, { useState, useContext } from 'react';
import List from './list'
import Event from './event'
import { AuthDataContext } from '../authData'
export default (function (props) {
    const { setSelect, loading, setSelectId } = useContext(AuthDataContext)
    const [filter, setFilter] = useState('')
    const event = Event(loading, setSelectId, setSelect)
    const onChange = event.onChange(setFilter)
    var filterOpt = {
        onChange
    }
    if (loading) {
        filterOpt.readOnly = loading
        delete filterOpt.onChange
    }
    console.log('authlist')
    return (
        <div>
            <div>
                <input name='filter' type='text' {...filterOpt}></input>
            </div>
            <List {...{ event, filter }}></List>
        </div>
    )
});

