import { Button } from "@mui/material";
import { color } from "acon-mui/style";
import React from "react";

const Icon = ({ hue }) => (
  <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.66668 5.99999C6.66668 6.46023 7.03977 6.83333 7.50001 6.83333L12.5 6.83333C12.9602 6.83333 13.3333 6.46023 13.3333 5.99999C13.3333 5.53976 12.9602 5.16666 12.5 5.16666L7.50001 5.16666C7.03977 5.16666 6.66668 5.53976 6.66668 5.99999Z" fill={hue}/>
  <path d="M7.50001 9.33333H6.00835C4.23376 9.3807 2.7143 8.07056 2.50001 6.30833C2.41304 5.37219 2.72544 4.44285 3.36024 3.74933C3.99503 3.05582 4.89318 2.66263 5.83334 2.66666L7.50001 2.66666C7.96025 2.66666 8.33334 2.29356 8.33334 1.83333C8.33334 1.37309 7.96025 0.999994 7.50001 0.999994H6.00835C3.45441 0.965865 1.26518 2.81747 0.875011 5.34166C0.685255 6.7703 1.12118 8.21135 2.071 9.29526C3.02082 10.3792 4.39215 11.0005 5.83334 11H7.50001C7.96025 11 8.33334 10.6269 8.33334 10.1667C8.33334 9.70642 7.96025 9.33333 7.50001 9.33333Z" fill={hue}/>
  <path d="M19.1667 5.36666C18.7811 2.80885 16.5526 0.937503 13.9667 0.999994L12.7083 0.999994C12.0333 0.999994 11.6667 1.37499 11.6667 1.83333C11.6667 2.29356 12.0398 2.66666 12.5 2.66666H13.9917C15.7663 2.61929 17.2857 3.92943 17.5 5.69166C17.587 6.6278 17.2746 7.55714 16.6398 8.25066C16.005 8.94417 15.1068 9.33736 14.1667 9.33333H12.5C12.0398 9.33333 11.6667 9.70642 11.6667 10.1667C11.6667 10.6269 12.0398 11 12.5 11H14.1667C15.6102 11.0116 16.9882 10.3988 17.9465 9.31923C18.9047 8.23962 19.3496 6.79855 19.1667 5.36666Z" fill={hue}/>
  </svg>
);

export const LinkIcon = ({ disabled=false, href, ...rest }: 
  { disabled?: boolean; href?: string; }
) => {
  const hue = disabled ? 'rgba(145, 158, 171, 0.8)' : color.text.secondary;

  return (
    <Button 
      disabled={disabled} 
      color="normal"
      sx={{ 
        minWidth: 'fit-content',
        width: '36px',
        height: '36px',
        borderRadius: '99px', 
        ...rest 
      }}
    >
      {disabled ? (
        <Icon hue={hue} />
      ) : (
        <a href={href} rel="noopener noreferrer" target="_blank">
          <Icon hue={hue} />
        </a>
      )}
    </Button>
  );
};