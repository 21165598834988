import styled from 'styled-components';

// 이미지 유형

export const ImageBox = styled.div`
  width: 273px;
  height: 178px;
  display: none;
  justify-content: space-between;

  & img {
    width: 100%;
    height: 100%;
  }
  & > div:nth-of-type(1) {
    width: 177px;
    height: 178px;
    margin-right: 2px;
  }
  & > div:nth-of-type(2) {
    & > div:nth-of-type(1) {
      width: 94px;
      height: 87px;
      border-left: 1px solid RGB(97, 97, 97);
      margin-bottom: 2px;
    }
    & > div:nth-of-type(2) {
      width: 93px;
      border-left: 1px solid RGB(97, 97, 97);
      border-top: 1px solid RGB(97, 97, 97);
      height: 88px;
    }
  }
`;

export const Message = styled.div<{ type?: boolean }>`
  position: absolute;
  ${(props) => (props.type ? `background: rgba(25, 180, 0, 0.7);` : `background-color: rgba(255, 190, 190, 0.3);`)}
  top: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  padding: 10px;
  width: calc(100% - 5px);
  margin: 0 auto;
  border-radius: 5px;
  z-index: 1;
  color: #333333;
`;
