import React, { useState, useEffect } from 'react'
import RcSelect from 'react-select';
import { useParams } from 'react-router';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { GET_PROD_VERSION } from 'api/quries';
import { useQuery } from '@apollo/client';
import { Flex } from 'components/style';
import { BlackButton } from 'boards/DetailBoardWrite/component/form';

export default (() => {
    const { t } = useTranslation();
    const { docId, lang } = useParams();

    // 선택된 버전
    const [selectedVersion, setSelectedVersion] = useState('');

    const { data } = useQuery(GET_PROD_VERSION, {
        fetchPolicy: "no-cache",
        variables: {
            docId,
            lang
        }
    })

    const options = data && data.productVersions.filter((x, i) => i !== 0).map((x, i) => {
        const label = x.major === 1 ? `Original` : `Ver.${x.major - 1}`;

        return {
            value: x.id,
            label: `${label} : ${dateFormat(x.created, 'yyyy-mm-dd')}`,
            major: x.major,
            minor: x.minor,
        }
    });

    useEffect(() => {
        if (data) {
            setSelectedVersion(options[0]);
        }
    }, [data]);

    // select 콤보박스 변경 이벤트 처리기 메소드 
    const onChange = (itemObj) => {
        setSelectedVersion(itemObj);
    };

    // 확인 버튼 클릭 이벤트 처리기 메소드
    const onClick = () => {
        const { major, minor } = selectedVersion;
        // 경로 오픈 
        window.open(`/goods/write/${lang}/${docId}?major=${major}&minor=${minor}`);
    };
    
    return (
        <Flex align="center" style={{ justifyContent: 'space-between' }}>
            <Flex>
                <span style={{ color: '#333', fontWeight: 700, marginRight: '7px' }}>
                    {t('diffVersion')}
                </span>
                <div style={{ width: `400px` }}>
                    <RcSelect
                        {...{
                            value: selectedVersion,
                            isMulti: false,
                            placeholder: t('select'),
                            options,
                            onChange
                        }}
                    />
                </div>
            </Flex>
            <BlackButton onClick={onClick} disabled={
                data && selectedVersion && (data.productVersions[0].major === selectedVersion.major && data.productVersions[0].minor === selectedVersion.minor)
            }>{t('confirm')}</BlackButton>
        </Flex>
    );
});