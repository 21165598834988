import React from 'react';
import { Box } from '@mui/material';
import { Typography } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import { SettlementNoticeProps } from './SettlementNotice.types';

export default function SettlementNotice({ containerSx }: SettlementNoticeProps) {
  const { t } = useTranslation();

  return (
    <Box sx={containerSx}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '4px',
        }}
      >
        <Typography variant={'typography/label/large/regular'} color={'color/primary/600'}>
          •
        </Typography>
        <Typography variant={'typography/label/large/bold'} color={'color/primary/600'}>
          {t('settlementPage.notice.line1')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '4px',
        }}
      >
        <Typography variant={'typography/label/large/regular'}>•</Typography>
        <Typography variant={'typography/label/large/regular'}>{t('settlementPage.notice.line2')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '4px',
        }}
      >
        <Typography variant={'typography/label/large/regular'}>•</Typography>
        <Typography variant={'typography/label/large/regular'}>{t('settlementPage.notice.line3')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '4px',
        }}
      >
        <Typography variant={'typography/label/large/regular'}>•</Typography>
        <Typography variant={'typography/label/large/regular'}>{t('settlementPage.notice.line4')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '4px',
        }}
      >
        <Typography variant={'typography/label/large/regular'}>•</Typography>
        <Typography variant={'typography/label/large/regular'}>{t('settlementPage.notice.line5')}</Typography>
      </Box>
    </Box>
  );
}
