import React from 'react';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface SvgProps extends BoxProps {
  src?: string;
  color?: string;
  size?: 'large' | 'medium' | 'small';
  customSize?: number;
  onClick?: VoidFunction;
}

export default function SvgIconStyle({ src, color, size, customSize, onClick, sx }: SvgProps) {
  let iconSize = 24;
  if (size === 'large') iconSize = 30;
  if (size === 'small') iconSize = 18;
  if (customSize) iconSize = customSize;

  return (
    <Box
      component="span"
      sx={{
        width: iconSize,
        height: iconSize,
        display: 'inline-block',
        bgcolor: color || 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
      onClick={onClick}
    />
  );
}
