import React, { createContext, useState } from 'react'

const initSelect = { _id: '', describe: '', auth: [], defaultAuth: false, defaultValue: [], isUpdate: undefined, isLoad: undefined }
export const AuthDataContext = createContext()
export default (props) => {
    const [addList, setAddList] = useState([])
    const [selectId, setSelectId] = useState('')
    const [authList, setAuthList] = useState([])
    const [templateList, setTemplateList] = useState([])
    const [loading, setLoading] = useState(false)
    const [listUpdate, setListUpdate] = useState(false)
    const [select, setSelect] = useState(initSelect)
    function initAddList() {
        if (addList.length > 0) {
            setAddList([])
        }
    }
    function setAdd(data, del) {
        setAddList((prevState) => {
            if (prevState.filter) {
                if (!del) {
                    if (!prevState.includes(data)) {
                        return [...prevState, data]
                    } else {
                        return prevState
                    }
                } else {
                    return prevState.filter((d) => {
                        return d !== data
                    })
                }
            } else {
                return []
            }
        })
    }
    function customSetAuthList(authList) {
        setAuthList(authList)
        if (authList && authList.length > 0) {
            if (selectId) {
                if (!select || select._id !== selectId) {
                    var temp = authList.filter(d => {
                        return d._id === selectId ? true : false
                    })
                    if (temp) {
                        setSelect({ ...temp[0], isUpdate: true, isLoad: false })
                    } else {
                        setSelect(initSelect)
                    }
                }
            }
        }
    }

    return < AuthDataContext.Provider value={{
        addList,
        setAddList: setAdd,
        initAddList,
        selectId,
        setSelectId,
        authList,
        setAuthList: customSetAuthList,
        loading,
        setLoading,
        listUpdate,
        setListUpdate,
        select,
        setSelect,
        templateList,
        setTemplateList
    }} >
        {props.children}
    </AuthDataContext.Provider >
}