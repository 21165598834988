import styled from 'styled-components';
import check from '../../assets/check.svg';
import warn from '../../assets/warning.svg';

export const Progress = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    ::before,
    ::after {
        content: '';
        position: absolute;
        display: block;
        height: 5px;
        margin-left: 24px;
        margin-top: 12px;
    }

    ::before {
        width: calc(100% - 9px - 15px - 15px - 15px);
        background: #c4c4c4;
    }

    ::after {
        width: calc(100% - 12px - 15px - 15px - 15px - ${props => (3 - props.status) * 30}%);
        background: #ff007b;
        transition: 1s;
    }
`;

export const Column = styled.div`
    position: relative;
    font-size: 12px;
    line-height: 18px;

    ::before,
    ::after {
        content: '';
        position: relative;
        display: block;
        margin: 0 auto;
        border-radius: 50%;
    }

    ::before {
        width: 30px;
        height: 30px;
        margin-bottom: 12px;
        background: #c4c4c4;
    }

    ::after {
        position: absolute;
        z-index: 2;
        width: 0;
        height: 0;
        top: 15px;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: width .5s, height .5s;
        background: #ff007b;
    }
    
    &.active::after {
        width: 20px;
        height: 20px;
    }

    &.done::after {
        width: 30px;
        height: 30px;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-position: center;
    }
    &.warn::after {
        width: 30px;
        height: 30px;
        background-image: url(${warn});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 22px 22px;
    }
`;