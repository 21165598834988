import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export const endDateObj = new Date();
export const startDateObj = dayjs(endDateObj).subtract(1, 'month').add(1, 'day');

export const viewCountList = [
  { id: 1, label: '20' },
  { id: 2, label: '50' },
  { id: 3, label: '100' },
  { id: 4, label: '200' },
];

export const INITIAL_SEARCH_PERIOD_LIST = (t: TFunction) => [
  { id: 1, label: t('paymentDate'), value: 'payment' },
  { id: 2, label: t('orderDate'), value: 'order' },
  { id: 3, label: t('settle.fix_date'), value: 'settle' },
];
export const INITIAL_PRODUCT_PRICE_TYPE_LIST = (t: TFunction) => [
  { id: 1, label: t('allProject') },
  { id: 2, label: t('generalProject') },
  { id: 3, label: t('freeProject') },
];
