import styled, { keyframes } from "styled-components";
import { CustomText } from "components/style";

export const LoginForm = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
`;

export const LoginFormContainer = styled.div`
  width: 432px;
  border-radius: 4px;
  border: 1px #e0e0e0 solid;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  padding: 67px 77px 50px 77px;
  margin: 0px auto;
  margin-bottom: 64px;
`;

export const TitleText = styled(CustomText).attrs({
  size: 16,
  weight: 500,
})`
  text-align: center;
  margin-bottom: 50px;
  display: block;
`;

export const LoginInputContainer = styled.div`
  margin-bottom: 48px;
`;

export const LoginIdInput = styled.div`
  margin-bottom: 12px;
`;

export const LoginPasswordInput = styled.div`
  margin-bottom: 12px;
`;

export const ValidationMessage = styled(CustomText).attrs({
  size: 12,
  color: "#f300ba",
})`
  margin-top: 12px;
`;

export const LoginInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 10.5px 16px;
  border-radius: 4px;
  border: 1px #cdcdcd solid;
  box-sizing: border-box;
  font-size: 14px;
`;

export const IdSaveContainer = styled.div`
  display: flex;
`;

export const IdSaveCheckbox = styled.div`
  width: 16px;
  height: 16px;
  border: ${(props) =>
    props.isChecked ? "1px solid #333333" : "1px solid #CDCDCD"};
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  cursor: pointer;

  &:before {
    ${(props) =>
      props.isChecked &&
      `
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 2px;
        width: 10px;
        height: 9px;
        background-image: url('/assets/icon/checked.svg');
      `}
  }
`;

export const IdSaveText = styled.div`
  margin-left: 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: 150%;
  color: #333;
`;

export const LoginButton = styled.div`
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  padding: 17px 0px;
  text-align: center;
  border-radius: 4px;
  min-height: 48px;
  position: relative;

  ${(props) =>
    props.isLoadingLogin
      ? `
      background-color: #606060;
    `
      : `
      background: #333333;
      cursor: pointer;
    `}

  &:hover {
    background-color: #606060;
  }
`;

export const LoginSearch = styled.div`
  margin-top: 12px;
  margin-bottom: 48px;
  display: flex;
`;

export const LoginDescription = styled(CustomText).attrs({
  color: "#7c7c7c",
})`
  text-align: center;
`;

export const JoinContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`;

export const JoinText = styled(CustomText).attrs({
  color: "#ff00c3",
  weight: 500,
  lineHeight: "80%",
})`
  margin-right: 5px;
  display: inline-block;
`;

export const Flex = styled.span`
  display: flex;
`;

const LoginLoderKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoginLoader = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #ff007b;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: ${LoginLoderKeyframes} 1s linear infinite;
  position: absolute;
  left: calc(50% - 9px);
  top: calc(50% - 9px);
`;
