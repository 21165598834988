import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'app';
import { NEW_STATUS_DRAFT, STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { MenuItem, Select } from '@mui/material';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';

const langItems = [
  { id: 1, value: 'ko', label: '한국어' },
  { id: 2, value: 'en', label: 'English' },
  { id: 3, value: 'zh', label: '中文' },
  { id: 4, value: 'ja', label: '日本語' },
];

export default ({ isAdmin }) => {
  const { showConfirmMessage } = useContext(AppContext);
  const { status } = useDocumentStore((state) => ({ status: state.status }));

  // URL 파생 정보
  const { lang, docId } = useParams<{ docId?: string; lang?: string }>();

  // history
  const history = useHistory();
  // 번역도구
  const { t, i18n } = useTranslation();

  // select 태그 변경 이벤트 처리기 메소드
  const onChange = (e) => {
    const targetLang = e.target.value;
    // 경로를 변경하는 메소드입니다.
    const changeUrl = () => {
      // 이동 될 경로
      const toPushUrl = `/goods/write/${targetLang}${docId ? `/${docId}` : ''}`;
      // 경로 변경
      history.push(toPushUrl);
    };

    // 작가가 상태가 완료된 경우
    if (!isAdmin && (status === STATUS_COMPLETE || status === STATUS_COMPLETE_ON_OPEN)) {
      // 경로 변경
      changeUrl();
      // 종료
      return;
    }

    // 외에 경우에는 내용이 변경될 여지가 있어 팝업을 표시해준다.
    showConfirmMessage(t('toPageMove'), t('ifNotSaveRemoveContents'), changeUrl);
  };

  const isUseUser = status === NEW_STATUS_DRAFT || !docId;

  if (!isUseUser) return <></>;
  return (
    <Select
      value={langItems.find((x) => (lang ? x.value === lang : x.value === i18n.language))?.value || null}
      onChange={onChange}
      size="small"
      sx={{ width: '120px', borderRadius: 0.5 }}
    >
      <MenuItem value={null} disabled sx={{ display: 'none' }} />
      {langItems.map((x) => (
        <MenuItem key={x.id} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </Select>
  );
};
