import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { TwinkleDiscountListContainer } from './TwinkleDiscountList.styles';
import { EXCHANGE_RATE, getSaleTypeOptions, getTextFieldStyles, TABLE_STYLE } from './TwinkleDiscountList.constants';
import palette from 'theme/palette';
import SelectBox from 'acon-mui/components/SelectBox';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ITwinkleDiscountListProps } from './TwinkleDiscountList.types';
import AconHelper from '../../../../../lib/global';
import { EPromotionDiscountType, LanguageCodeEnum } from 'generated/graphql';
import { ReactComponent as XIcon } from 'acon-mui/icons/icon-x.svg';
import _ from 'lodash';

const TwinkleDiscountList = ({ type }: ITwinkleDiscountListProps): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const textFieldClass = getTextFieldStyles();
  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);

  const saleTypeOptions = useMemo(() => getSaleTypeOptions(t), [t]);

  const isFixedDiscount = (index) => getValues(`products.${index}.discountType`) === EPromotionDiscountType.Fixed;

  const getDiscountedPriceFixed = (product): number => {
    return language === LanguageCodeEnum.Ko
      ? Number(Number(Number(product.price - product.discountValue)).toFixed(0))
      : Number(Number(Number(product.price - product.discountValue * EXCHANGE_RATE) / EXCHANGE_RATE).toFixed(2));
  };

  const getDiscountedPricePercentage = (product): number => {
    return language === LanguageCodeEnum.Ko
      ? Number((product.price * Number(1 - product.discountValue / 100)).toFixed(0))
      : Number(((product.price / EXCHANGE_RATE) * Number(1 - product.discountValue / 100)).toFixed(2));
  };

  const getDiscountedPriceText = (product, index) => {
    if (errors.products?.[index]?.discountValue?.message) {
      return t('twinkleSalePage.notApplicable');
    }

    if (!getValues(`products.${index}.discountValue`)) {
      return '-';
    }
    if (isFixedDiscount(index)) {
      return getDiscountedPriceFixed(product) + t('twinkleSalePage.priceUnit');
    } else {
      return getDiscountedPricePercentage(product) + t('twinkleSalePage.priceUnit');
    }
  };

  const {
    register,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const handleOnChangeUnit = (index, event) => {
    const value = event.target.value === 1 ? EPromotionDiscountType.Fixed : EPromotionDiscountType.Percent;
    setValue(`products.${index}.discountType`, value);
    setValue(`products.${index}.discountValue`, 0);
    clearErrors(`products.${index}.discountValue`);
  };

  const handleOnDelete = (index) => {
    const newProducts = _.cloneDeep(getValues().products);
    newProducts.splice(index, 1);
    setValue('products', newProducts);
    trigger();
  };

  const handleOnDisableWheel = (e) => e.target.blur();

  return (
    <TwinkleDiscountListContainer>
      <Table sx={TABLE_STYLE.table}>
        <TableHead sx={TABLE_STYLE.tableHead}>
          <TableRow>
            <TableCell variant="head" width="auto">
              <Box sx={{ pl: 7, py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600}>
                  {t('twinkleSalePage.productName')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="121px">
              <Box sx={{ py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600}>
                  {t('twinkleSalePage.regularPrices')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width={type === 'view' ? '131px' : '214px'}>
              <Box sx={{ py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600}>
                  {t('twinkleSalePage.discount')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="140px">
              <Box sx={{ py: 2, pr: type === 'view' ? 8 : 0 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre">
                  {t('twinkleSalePage.discountAppliedPrices')}
                </Typography>
              </Box>
            </TableCell>
            {type === 'edit' ? <TableCell variant="head" width="68px" /> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {type === 'edit' ? (
            <Fragment>
              {getValues('products').length ? (
                <Fragment>
                  {getValues('products').map((product, index) => {
                    const { discountType, price, title, id } = product;
                    const minPrice = discountType === 'FIXED' ? Number(price * 0.1).toFixed(language === LanguageCodeEnum.Ko ? 0 : 2) : 10;
                    const maxPrice = discountType === 'FIXED' ? Number(price * 0.3).toFixed(language === LanguageCodeEnum.Ko ? 0 : 2) : 30;

                    return (
                      <TableRow sx={TABLE_STYLE.tableRow} key={`${id}_${index}`}>
                        <TableCell>
                          <Box sx={{ pl: 7, py: 6 }}>
                            <Typography variant="subtitle2">{title}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {language === LanguageCodeEnum.Ko ? AconHelper.AddCommas(price) : Number(price / EXCHANGE_RATE).toFixed(2)}
                            {t('twinkleSalePage.priceUnit')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" gap="6px" justifyContent="flex-start" alignItems="flex-start">
                            <TextField
                              {...register(`products.${index}.discountValue`)}
                              type="number"
                              size="small"
                              error={Boolean(errors.products?.[index]?.discountValue)}
                              className={textFieldClass.root}
                              helperText={
                                getValues(`products.${index}.discountType`) === 'FIXED'
                                  ? errors.products?.[index]?.discountValue?.message ||
                                    t('twinkleSalePage.validation.minMaxPrice', {
                                      minPrice: AconHelper.AddCommas(language === LanguageCodeEnum.Ko ? minPrice : Number(Number(minPrice) / EXCHANGE_RATE).toFixed(2)),
                                      maxPrice: AconHelper.AddCommas(language === LanguageCodeEnum.Ko ? maxPrice : Number(Number(maxPrice) / EXCHANGE_RATE).toFixed(2)),
                                    })
                                  : errors.products?.[index]?.discountValue?.message || t('twinkleSalePage.validation.minMaxPercent')
                              }
                              FormHelperTextProps={{ classes: { root: textFieldClass.helperText } }}
                              placeholder={discountType === 'FIXED' ? t('twinkleSalePage.inputDescriptionFixed') : t('twinkleSalePage.inputDescriptionPercent')}
                              onWheel={handleOnDisableWheel}
                            />
                            <SelectBox
                              defaultValue={discountType === 'FIXED' ? 1 : 2}
                              options={saleTypeOptions}
                              onSelect={(e) => handleOnChangeUnit(index, e)}
                              isBorder
                              width="62px"
                              size="small"
                              inputLabelVisible={false}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" color={errors.products?.[index]?.discountValue?.message ? palette.light.orange[0] : palette.light.primary.main}>
                            {AconHelper.AddCommas(getDiscountedPriceText(product, index))}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <XIcon onClick={() => handleOnDelete(index)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              ) : (
                <TableRow sx={TABLE_STYLE.tableRow}>
                  <TableCell colSpan={4}>
                    <Box py={6}>
                      <Typography variant="subtitle2" textAlign="center">
                        {t('twinkleSalePage.noProductSelected')}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {getValues('products').length ? (
                <Fragment>
                  {getValues('products').map((product, index) => {
                    const { id, price, title, discountValue, discountType } = product;
                    return (
                      <TableRow key={id} sx={TABLE_STYLE.tableRow}>
                        <TableCell>
                          <Box sx={{ pl: 7, py: 6 }}>
                            <Typography variant="subtitle2">{title}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {language === LanguageCodeEnum.Ko ? AconHelper.AddCommas(price) : Number(price / EXCHANGE_RATE).toFixed(2)}
                            {t('twinkleSalePage.priceUnit')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {language === LanguageCodeEnum.Ko
                              ? AconHelper.AddCommas(Number(discountValue).toFixed(0))
                              : discountType === EPromotionDiscountType.Fixed
                              ? Number(discountValue / EXCHANGE_RATE).toFixed(2)
                              : Number(discountValue).toFixed(2)}
                            {discountType === 'FIXED' ? t('twinkleSalePage.priceUnit') : '%'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" color={palette.light.primary.main}>
                            {language === LanguageCodeEnum.Ko
                              ? discountType === EPromotionDiscountType.Fixed
                                ? AconHelper.AddCommas(Number(price - discountValue).toFixed(0)) + t('twinkleSalePage.priceUnit')
                                : AconHelper.AddCommas(getDiscountedPriceText(product, index))
                              : discountType === EPromotionDiscountType.Fixed
                              ? AconHelper.AddCommas(Number(Number(price - discountValue) / EXCHANGE_RATE).toFixed(2)) + t('twinkleSalePage.priceUnit')
                              : AconHelper.AddCommas(getDiscountedPriceText(product, index))}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TwinkleDiscountListContainer>
  );
};

export default TwinkleDiscountList;
