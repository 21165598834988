import styled from 'styled-components';

const Select = styled.select`
    display: block;
    width: 100%;
    height: 36px;
    color: black;
    border: 0;
    border-bottom: 1px solid;
    text-align-last: left;

    ${props=> props.disableClick && 'pointer-events: none;'}
`;

export default Select;