import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import Wrapper from './styled';
import IconClose from './close.svg';

/**
 * 크롬 호환성 안내 컴포넌트
 * 30일간 보지 않기 상태일 경우 렌더링 하지 않음
 * @returns React Component || null
 */
function Recommend() {
	const { t } = useTranslation();

	const [isShow, setIsShow] = useState(false);
	const [cookie, setCookie] = useCookies();
	// 30일간 보지 않기 상태 여부 (렌더링 비용을 줄이기 위해 먼저 true로 두고 렌더하지 않음)
	const [hasCookie, setHasCookie] = useState(true);

	let autoCloseTimer = useRef(null);

	function show() {
		setIsShow(true);
	}

	/**
	 * 띠배너 페이드아웃
	 */
	function hide() {
		setIsShow(false);
	}

	/**
	 * 띠배너 30일간 보지 않음
	 */
	function dontShowLongTime() {
		// 한달뒤 날짜를 가져옴
		const date = new Date();
		date.setMonth(date.getMonth() + 1);

		// 한달 동안 유지되는 쿠키 생성
		setCookie('skipOutdatedBrowserNotification', 1, { path: '/', expires: date });
	}

	function setTimeoutAutoClose() {
		// 페이드인 애니메이션 1초 + 보여주는 시간 5초 후 페이드아웃
		autoCloseTimer.current = setTimeout(() => {
			dontShowLongTime();
			hide();
		}, 6000);
	}

	function clearTimeoutAutoClose() {
		clearTimeout(autoCloseTimer.current);
		autoCloseTimer.current = null;
	}

	function onClickClose() {
		dontShowLongTime();
		hide();
	}

	function onMouseEnter() {
		// 안내창에 마우스를 올렸을 시 자동 숨김처리 하지 않기
		clearTimeoutAutoClose();
	}

	function onMouseLeave() {
		// 안내창에 마우스 이탈 시 자동 숨김처리 타이머 시작
		setTimeoutAutoClose();
	}

	useEffect(() => {
		if (!cookie.skipOutdatedBrowserNotification) {
			// 30일간 보지 않기가 아님
			setHasCookie(false);
			
			setTimeout(() => {
				// 렌더되는 1틱을 기다림
				show();
				// 자동숨김처리 타이머 시작
				setTimeoutAutoClose();
			}, 0);
		}
	}, []);

	if (hasCookie) {
		// 30일간 보지 않기 예외처리 (혹은 아직 렌더타임이 아님)
		return null;
	}

	return (
		<Wrapper fixed show={isShow} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{t('unsupported.recommend')}
			<a href="https://www.google.com/chrome/" target="_blank" title="새 창">{t('unsupported.openChrome')}</a>
			<div>
				{t('unsupported.dontShowLongTime')}
				<img src={IconClose} onClick={onClickClose} />
			</div>
		</Wrapper>
	);
}

export default Recommend;
