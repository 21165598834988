import { Button } from "@mui/material";
import React from "react";
import { color } from "acon-mui/style";

const Icon = ({ hue, type }) => {
  if (type === 'delete') return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 4L12.3333 4V2.60834C12.3138 2.07485 12.0835 1.57088 11.693 1.20691C11.3024 0.84294 10.7835 0.648663 10.25 0.666668L7.74999 0.666668C7.21645 0.648663 6.69754 0.84294 6.30701 1.20691C5.91649 1.57088 5.68621 2.07485 5.66666 2.60834V4H1.49999C1.27898 4 1.06701 4.0878 0.910734 4.24408C0.754454 4.40036 0.666656 4.61232 0.666656 4.83334C0.666656 5.05435 0.754454 5.26631 0.910734 5.42259C1.06701 5.57887 1.27898 5.66667 1.49999 5.66667H2.33332L2.33332 14.8333C2.33332 15.4964 2.59672 16.1323 3.06556 16.6011C3.5344 17.0699 4.17028 17.3333 4.83332 17.3333H13.1667C13.8297 17.3333 14.4656 17.0699 14.9344 16.6011C15.4033 16.1323 15.6667 15.4964 15.6667 14.8333V5.66667H16.5C16.721 5.66667 16.933 5.57887 17.0892 5.42259C17.2455 5.26631 17.3333 5.05435 17.3333 4.83334C17.3333 4.61232 17.2455 4.40036 17.0892 4.24408C16.933 4.0878 16.721 4 16.5 4ZM7.33332 2.60834C7.33332 2.475 7.50832 2.33334 7.74999 2.33334H10.25C10.4917 2.33334 10.6667 2.475 10.6667 2.60834V4H7.33332V2.60834ZM14 14.8333C14 15.0543 13.9122 15.2663 13.7559 15.4226C13.5996 15.5789 13.3877 15.6667 13.1667 15.6667H4.83332C4.61231 15.6667 4.40035 15.5789 4.24407 15.4226C4.08779 15.2663 3.99999 15.0543 3.99999 14.8333L3.99999 5.66667L14 5.66667V14.8333Z" fill={hue}/>
    <path d="M6.49999 13.1667C6.721 13.1667 6.93297 13.0789 7.08925 12.9226C7.24553 12.7663 7.33332 12.5543 7.33332 12.3333V9C7.33332 8.77899 7.24553 8.56703 7.08925 8.41075C6.93297 8.25447 6.721 8.16667 6.49999 8.16667C6.27898 8.16667 6.06701 8.25447 5.91073 8.41075C5.75445 8.56703 5.66666 8.77899 5.66666 9V12.3333C5.66666 12.5543 5.75445 12.7663 5.91073 12.9226C6.06701 13.0789 6.27898 13.1667 6.49999 13.1667Z" fill={hue}/>
    <path d="M11.5 13.1667C11.721 13.1667 11.933 13.0789 12.0892 12.9226C12.2455 12.7663 12.3333 12.5543 12.3333 12.3333V9C12.3333 8.77899 12.2455 8.56703 12.0892 8.41075C11.933 8.25447 11.721 8.16667 11.5 8.16667C11.279 8.16667 11.067 8.25447 10.9107 8.41075C10.7545 8.56703 10.6667 8.77899 10.6667 9V12.3333C10.6667 12.5543 10.7545 12.7663 10.9107 12.9226C11.067 13.0789 11.279 13.1667 11.5 13.1667Z" fill={hue}/>
    </svg>
  );
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.16935 5.99517L10.748 2.42459C11.0743 2.09822 11.0743 1.56908 10.748 1.24271C10.4217 0.916349 9.89256 0.916349 9.56622 1.24271L5.99588 4.82162L2.42555 1.24271C2.09921 0.916349 1.57011 0.916349 1.24377 1.24271C0.917424 1.56908 0.917424 2.09822 1.24377 2.42459L4.82242 5.99517L1.24377 9.56575C1.08621 9.72203 0.997589 9.93477 0.997589 10.1567C0.997589 10.3786 1.08621 10.5913 1.24377 10.7476C1.40003 10.9052 1.61275 10.9938 1.83466 10.9938C2.05657 10.9938 2.26929 10.9052 2.42555 10.7476L5.99588 7.16872L9.56622 10.7476C9.72248 10.9052 9.9352 10.9938 10.1571 10.9938C10.379 10.9938 10.5917 10.9052 10.748 10.7476C10.9056 10.5913 10.9942 10.3786 10.9942 10.1567C10.9942 9.93477 10.9056 9.72203 10.748 9.56575L7.16935 5.99517Z" fill={hue}/>
    </svg>
  );
};

export const DeleteButton = (
  { type="delete", onClick, disabled=false }: 
  { type?: 'delete'|'cancel', onClick: () => void, disabled?: boolean, }
) => {
  const hue = disabled ? 'rgba(145, 158, 171, 0.8)' : color.text.secondary;
  return (
    <Button 
      onClick={onClick} 
      color="normal"
      disabled={disabled}
      sx={{ 
        minWidth: 'fit-content',
        width: '36px',
        height: '36px',
        borderRadius: '99px' 
      }}
    >
      <Icon hue={hue} type={type} />
    </Button>
  );
};