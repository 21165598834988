import React, { useEffect, useRef } from 'react';
import { Tooltip, Menu, MenuItem } from './style';
import './index.scss';

export default (({ onAdd, onDelete, tooltipObj, setTooltipObj }) => {
    // 툴팁 스타일 
    const overlayStyle = {
        "display": (tooltipObj.isShow ? "block" : "none"),
        "position": "absolute",
        "top": tooltipObj.top,
        "left": tooltipObj.left
    };

    // 툴팁 레퍼런스 
    const tooltipRef = useRef();
    // 툴팁 이외의 영역 클릭 이벤트 처리기 메소드 
    const handleClickTooltipOutside = (event) => {
        if (!tooltipRef.current.contains(event.target))
            setTooltipObj({ ...tooltipObj, isShow: false });
    };

    useEffect(() => {
        // document 클릭 이벤트 
        document.addEventListener('click', handleClickTooltipOutside, true);
        return () => {
            document.removeEventListener('click', handleClickTooltipOutside, true);
        };
    });

    return (
        <Tooltip
            ref={tooltipRef}
            style={overlayStyle}
        >
            <Menu>
                <MenuItem onClick={onAdd}>추가</MenuItem>
                <MenuItem onClick={onDelete}>삭제</MenuItem>
            </Menu>
        </Tooltip>
    );
});