import React from 'react';
import Spinner from '../../spinner/Spinner';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

const LoadingWrap = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    ${props => !props.empty ? `background-color: rgba(0, 0, 0, 0.6);` : ``}
`;

const SpinnerWrap = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Loading = (props) => {
    return createPortal(
        <LoadingWrap empty={props.empty}>
            <SpinnerWrap>
                <Spinner color="#ff007b" size="48" />
            </SpinnerWrap>
        </LoadingWrap>
    , document.body);
}

export default Loading;