import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BRANDS } from 'api/quries';
import { useTranslation } from 'react-i18next';
import { Icon } from 'rsuite';
import Tree from 'rc-tree';
import { Item, Text, Input, Value, EditorContainer, SelectBox, SearchBox, ListBox, DropdownBox, CategoryItems, Trigger } from '../../style';
import CategoryItem from '../item';

export default ({ onChangeApplyValue, applySelectedValue, disabled = false }) => {
    // 번역도구 
    const { t } = useTranslation();
    // 검색 키워드
    const [searchKeyword, setSearchKeyword] = useState('');
    // 리스트 표현 여부 
    const [isShowList, setIsShowList] = useState(false);
    // 확장된 ID들
    const [expandedIds, setExpandedIds] = useState([]);

    // 브랜드 정보 조회
    const { data, loading } = useQuery(GET_BRANDS, {
        variables: {
            lang: "ko"
        }
    });

    // 브랜드 로딩 여부
    const isLoading = !(!loading && data && data.getBrands);

    // 검색키워드 input 태그 변경 이벤트 처리기 메소드
    const onChangeSearchKeywordInputTag = (e) => {
        // 검색 키워드 설정 
        setSearchKeyword(e.target.value);
    };
    // select box 클릭 이벤트 처리기 메소드
    const onClickSelectBox = () => {
        setIsShowList(!isShowList);
    };

    // 브랜드 저장
    const onSelect = (selectedKeys) => {
        onChangeApplyValue(selectedKeys);
    };

    const onExpand = (expandedKeys) => {
        setExpandedIds([...expandedKeys])
    };

    return (
        <Item style={{ marginTop: '10px' }}>
            <Value>
                {!isLoading && (() => {
                    let renderBrands = data.getBrands.map(x => {
                        return { key: x.id, parent: null, title: x.name, children: [] }
                    });

                    // 브랜드 명 검색
                    if(searchKeyword !== '') {
                        renderBrands = renderBrands.filter(x => {
                            return x.title.indexOf(searchKeyword) > -1;
                        });
                    }

                    return (
                        <>
                            <EditorContainer>
                                <SelectBox active={isShowList} onClick={onClickSelectBox}>
                                    <CategoryItems>
                                        {/* 카테고리 항목이 존재하지 않을 경우 placeholder / 존재할 경우 항목 리스트 표시  */}
                                        {applySelectedValue.length === 0 ?
                                            <Text>{t('brand')}</Text> :
                                            (
                                                applySelectedValue.map((x, i) =>
                                                    <CategoryItem
                                                        key={i}
                                                        id={x}
                                                        title={data.getBrands.find(y => y.id === Number(x)).name}
                                                        onChangeApplyValue={onChangeApplyValue}
                                                        applySelectedValue={applySelectedValue}
                                                    />
                                                )
                                            )
                                        }
                                    </CategoryItems>
                                    {/* <Arrow active={isShowList} /> */}
                                </SelectBox>
                                {(isShowList && !disabled) && (
                                    <DropdownBox>
                                        <SearchBox>
                                            <Input
                                                onChange={onChangeSearchKeywordInputTag}
                                                value={searchKeyword}
                                                placeholder={t('inputSearchKeyword')}
                                            />
                                        </SearchBox>
                                        <ListBox>
                                            {/* {(
                                                renderBrands.map((x, i) => {
                                                    const isChecked = applySelectedValue.includes(x.key);

                                                    return (
                                                        <ListRow isChecked={isChecked} data-value={x.key} key={i} onClick={onSelect}>
                                                            {data.getBrands.find(y => y.id === x.key).name}
                                                        </ListRow>
                                                    );
                                                })
                                            )} */}
                                            <Tree
                                                multiple={true}
                                                treeData={renderBrands}
                                                showIcon={false}
                                                switcherIcon={(obj) => {
                                                    if (!obj.isLeaf) {
                                                        return <Trigger>
                                                            <Icon icon={obj.expanded ? "minus" : "plus"} />
                                                        </Trigger>
                                                    }
                                                }}
                                                onSelect={onSelect}
                                                onExpand={onExpand}
                                                autoExpandParent={false}
                                                expandedKeys={expandedIds}
                                                selectedKeys={applySelectedValue}
                                            />
                                        </ListBox>
                                    </DropdownBox>
                                )}
                            </EditorContainer>
                        </>
                    )
                })()}
            </Value>
        </Item >
    );    
};