const REGEX_ONLY_NUMBER = /\D/g;

export function onlyNumber(str: string) {
  const number = Number(onlyNumberStr(str));

  if (isNaN(number)) throw new Error("숫자로 치환할 수 없습니다.");

  return number;
}

export function isEmail(email: string) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
}

export function onlyNumberStr(str: string) {
  return str.replaceAll(REGEX_ONLY_NUMBER, "");
}
