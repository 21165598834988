import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Container } from 'acon-mui/components';
import { color } from 'acon-mui/style';
import { displayMoneyByLang } from 'lib/multiLocale';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../style';
import Chevron from 'acon-mui/icons/icon-chevron.svg';
import { LanguageCodeEnum } from 'generated/graphql';
import queryString from 'query-string';
import { AppContext } from 'app';
import { useQuery } from 'react-query';
import { ENTERPRISE_QUERY_KEYS, getBuyerChannel, getSettleEnterPriseDetail } from './constans';
import Loading from 'common/editor/Loading';
import { IEnterpriseDetailOutput } from './types';
import { asiaSeoulDayjs } from 'utils/timezone';

export default function (props) {
  const {
    history,
    match: { params },
  } = props;
  const { userInfo } = useContext(AppContext);
  const { t, i18n } = useTranslation('common');

  const detailPayload = useMemo(
    () => ({
      langCode: i18n.language as LanguageCodeEnum,
      orderId: params.orderNo,
      ...(userInfo.isAdmin && { brandId: Number(params.brandId) }),
    }),
    [i18n.language, params, userInfo],
  );

  const detailQuery = useQuery<IEnterpriseDetailOutput>([ENTERPRISE_QUERY_KEYS.ENTERPRISE_DETAIL, detailPayload], () => getSettleEnterPriseDetail(detailPayload));

  const handleClickReturnButton = () => {
    const params = localStorage.getItem('settle_url_params');

    if (params) {
      const obj = JSON.parse(params);
      for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === undefined) delete obj[key];
      }
      localStorage.removeItem('settle_url_params');
      history.push({
        pathname: '/manager/settle',
        search: queryString.stringify(obj),
      });
      return;
    }
    window.location.href = '/manager/settle';
    return;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {detailQuery.isLoading && <Loading />}
      <Page className="settle_page" isAdmin={userInfo.isAdmin}>
        <Typography fontSize="24px" lineHeight="1.5" fontWeight="700" color={color.text.primary}>
          {t('settleList')}
        </Typography>

        <Button
          variant="text"
          colorTheme="normal"
          startIcon={<img alt="chevron" src={Chevron} style={{ transform: 'rotate(90deg)', marginRight: '10px', width: '14px', height: 'auto' }} />}
          width="fit-content"
          marginTop="40px"
          onClick={handleClickReturnButton}
        >
          <>{t('backToList')}</>
        </Button>

        {detailQuery?.data && (
          <Container marginTop="24px" fontSize="14px" lineHeight="22px" fontWeight="400">
            <Typography color={color.text.secondary}>{t('settleTable.projectTitle')}</Typography>
            <Typography color={color.text.primary} mt="8px">
              {detailQuery?.data?.goodsNm}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.buyername')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {detailQuery?.data?.buyerNm}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.penName')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {detailQuery?.data?.penNm || '-'}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.channel')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {getBuyerChannel(detailQuery?.data?.mallCode) || '-'}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.appliedWork')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {detailQuery?.data?.licenseType}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.workUsedThisProject')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {detailQuery?.data?.projectNm.join(',') || '-'}
            </Typography>
            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.paymentDate')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {asiaSeoulDayjs(detailQuery?.data?.paymentDate).format('YY-MM-DD\u00a0\u00a0HH:mm')}
            </Typography>
            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.settlementDate')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {asiaSeoulDayjs(detailQuery?.data?.settleDate).format('YY-MM-DD')}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.normalPrice')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {displayMoneyByLang(Number(detailQuery?.data?.salePrice), i18n.language)}
            </Typography>
            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.creatorDiscount')}
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              · {t('settleTable.benefitDiscount')}:&nbsp;
              {displayMoneyByLang(Number(detailQuery?.data?.benefitDcPrice), i18n.language)}
            </Typography>

            <Typography color={color.text.primary}>
              · {t('settleTable.couponDiscount')}:&nbsp;
              {displayMoneyByLang(Number(detailQuery?.data?.couponDcPrice), i18n.language)}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.settlePrice')} &#40;{t('settleTable.normalPrice')} - {t('settleTable.creatorDiscount')}&#41;
            </Typography>
            <Typography color={color.text.primary} mt="8px">
              {displayMoneyByLang(Number(detailQuery?.data?.settlementPrice), i18n.language)}
            </Typography>
          </Container>
        )}
        <Box pt="100px" />
      </Page>
    </LocalizationProvider>
  );
}
