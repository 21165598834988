import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LanguageCodeEnum } from 'generated/graphql';
import { Box, Stack } from '@mui/material';
import CategoriesRow from './CategoriesRow';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { ISBN_CategoryCode } from './index.constans';
import { Checkbox, Tooltip, Typography } from 'carpenstreet-designsystem';

export default () => {
  // 번역도구
  const { t, i18n } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum }>();
  const { isDisp, status, book, categories, setCategories, setBook } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    book: state.book,
    categories: state.categories,
    setCategories: state.setCategories,
    setBook: state.setBook,
  }));

  const isVisibleISBNCheck = useMemo(() => Boolean(categories[0]?.code === ISBN_CategoryCode && i18n.language === LanguageCodeEnum.Ko), [categories]);

  const originalData = useOriginalDocumentStore((state) => ({
    categories: state.categories,
  }));

  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setCategories(originalData?.categories);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };
  const handleOnCheckedISBN = () => {
    const isIsbn = Boolean(book?.isbn);
    setBook(isIsbn ? null : { isbn: 'book_isbn' });
  };

  useEffect(() => {
    if (!isVisibleISBNCheck) setBook(null);
  }, [isVisibleISBNCheck]);

  return (
    <Box>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.categories.title'))}>
        {t('document.categories.setCategories')}
      </Title>
      <CategoriesRow disabled={disabled} />
      {isVisibleISBNCheck && (
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="6px" mt="12px">
          <Checkbox size="M" color="primary" checked={Boolean(book?.isbn)} onChange={handleOnCheckedISBN} />
          <Tooltip
            open
            direction="right"
            title={
              <Typography variant="typography/label/small/regular" color="color/white" whiteSpace="pre">
                출판 전자책은 할인 및 추가 포인트 지급이 어려우며, 프로모션에 참여할 수 없습니다.
                <a href="https://partnerguide.acon3d.com/03172a83-d8db-4f00-97fe-920e76fa862b" target="_blank">
                  <Typography variant="typography/label/small/regular" color="color/white" fontWeight="bold" ml="8px" sx={{ textDecoration: 'underline' }}>
                    자세히보기 {'>'}
                  </Typography>
                </a>
              </Typography>
            }
            color="primary"
            slotProps={{
              tooltip: { sx: { maxWidth: '100%', padding: '4px 8px' } },
              popper: { sx: { zIndex: 1300 } },
            }}
          >
            <Typography variant="typography/body/small/regular" color="color/gray/800">
              출판 전자책(ISBN)
            </Typography>
          </Tooltip>
        </Stack>
      )}
    </Box>
  );
};
