import React from 'react';
import './Spinner.scss';

interface Props {
    style?: React.CSSProperties;
    size?: string;
    color?: string;
}

export default React.memo(function ({ style={}, size, color }: Props) {
    // 지정된 사이즈가 있을경우 
    if (size)
        // 스타일 정의  
        style = {
            ...style,
            width: size + 'px',
            height: size + 'px'
        };

    // 색상 
    let colorObj: { border?: string; borderColor?: string; } = {};
    // 지정된 색상이 있을경우 
    if (color) {
        // 색상정의 
        colorObj.border = '2px solid ' + color;
        colorObj.borderColor = color + ' transparent transparent transparent';
    }

    // 스피너 반환 
    return (
        <div className="spinner" style={style}>
            <div style={colorObj}></div>
            <div style={colorObj}></div>
            <div style={colorObj}></div>
            <div style={colorObj}></div>
        </div>
    );
});