import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_FAQ_CREATOR_TYPES } from 'api/quries';
import { TypeCombo } from './type';

export const FaqCreatorTypes = ({ value }) => {
    const { data } = useQuery(GET_FAQ_CREATOR_TYPES);

    return (
        <TypeCombo types={data && data.faqCreatorTypes} value={value} />
    );
};