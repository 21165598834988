import React, { useContext, useMemo, useState } from 'react';
import { Box, TextField, Typography, alpha } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Title } from 'acon-mui/components/Board';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { numberWithCommas } from 'lib/formatNumber';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AppContext } from 'app';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import Enterprise from '../enterprise';
import { Container } from 'acon-mui/components';
import { Modal } from 'react-bootstrap';
import ImageModal from 'boards/DetailBoardWrite/component/ImageModal/ImageModal';
import { LanguageCodeEnum } from 'generated/graphql';
import { set } from 'date-fns';

export default ({ isAdmin }) => {
  const { showAlertMessage } = useContext(AppContext);
  // 번역도구
  const { t, i18n } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: string }>();
  const { isDisp, status, price, setPrice, priceChanged, licenseScales, setLicenseScales } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    price: state.price,
    setPrice: state.setPrice,
    priceChanged: state.priceChanged,
    licenseScales: state.licenseScales,
    setLicenseScales: state.setLicenseScales,
  }));

  const originalData = useOriginalDocumentStore((state) => ({
    price: state.price,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const [isShowOpenrunModal, setIsShowOpenrunModal] = useState(false);
  const [isPriceFloorWarning, setIsPriceFloorWarning] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  // 단가 변경 이벤트 처리기 메소드
  const onChangeSalePrice = (e) => {
    if (e.target.value) {
      const onlyNumberText = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNumberText.length === 0) {
        setPrice(null);
        e.target.value = null;
      } else {
        const onlyNumber = Number(onlyNumberText);
        let val = lang === 'ko' ? onlyNumber : onlyNumber * 1000;
        setPrice(val);
        e.target.value = numberWithCommas(onlyNumber);
      }
    } else {
      setPrice(null);
    }
  };
  const onChangeCheckboxItem = (value, checked) => {
    setPrice(checked ? 0 : null);
  };

  const handleChecked = (checked, val) => {
    if (checked) {
      if (priceChanged && new Date().getTime() - new Date(priceChanged)?.getTime() < 1000 * 60 * 60 * 24 * 30) {
        showAlertMessage(t('document.priceChange.warning.title'), t('document.priceChange.warning.description'), { callback: () => setIsChecked(false) });
        return;
      } else {
        showAlertMessage(t('document.priceChange.notice.title'), t('document.priceChange.notice.description'), {
          callback: () => {
            setIsChecked(true);
            setSelectedFields([...selectedFields, val]);
          },
        });
        return;
      }
    }
    if (!checked) {
      setIsChecked(checked);
      setPrice(originalData?.price);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  const handleFocus = (e) => {
    setIsPriceFloorWarning(false);
  };

  const handleBlur = (e) => {
    if (e.target.value) {
      const onlyNumberText = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNumberText.length > 0) {
        const onlyNumber = Number(onlyNumberText);
        let val = lang === 'ko' ? onlyNumber : onlyNumber * 1000;

        if (val > 0 && val < 1000) {
          const callback = () => {
            setPrice(null);
            e.target.value = null;
          };
          showAlertMessage(t('document.price.warning.limit.title'), t('document.price.warning.limit.description'), {
            callback,
            closeCallback: callback,
          });
          setIsPriceFloorWarning(false);
          return;
        } else if (val % 100 !== 0) {
          setIsPriceFloorWarning(true);
          val = val - (val % 100);
          setPrice(val);
          e.target.value = numberWithCommas(val);
          return;
        } else {
          setPrice(val);
          setIsPriceFloorWarning(false);
        }
      } else {
        setPrice(null);
        e.target.value = null;
      }
    } else {
      setPrice(null);
    }
    if (licenseScales?.length > 0) {
      setLicenseScales(licenseScales);
    }
  };

  const freeSourceCheck = (
    <FormGroup sx={{ ml: 'auto', '& label': { margin: '0 !important' }, mt: 2, mb: 4.5 }}>
      <FormControlLabel
        control={<Checkbox checked={price === 0} onChange={onChangeCheckboxItem} disabled={disabled} sx={{ ml: -1 }} />}
        label={t('freeSource')}
        sx={{ fontSize: 2, color: alpha('#000000', 0.87) }}
      />
    </FormGroup>
  );

  const infoCards = (
    <Box marginTop={'12px'}>
      {isPriceFloorWarning && <InformationCard type="warning">{t('document.price.warning.floor')}</InformationCard>}
      {price === 0 && (
        <InformationCard type="notice" marginTop="24px">
          {t('document.price.notice')}
        </InformationCard>
      )}
    </Box>
  );

  return (
    <Box>
      {/* 오픈런 자세히 보기 툴팁 모달 */}
      <ImageModal
        show={isShowOpenrunModal}
        close={() => setIsShowOpenrunModal(false)}
        src={`/assets/banner/openrun-popup-20240430-${i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En}.png`}
        maxWidth={480}
      />
      <Title
        checkboxVisible={isCheckboxVisible}
        isChecked={isChecked}
        onClick={(value, checked) => handleChecked(checked, t('goods.price'))}
        tooltip={
          <Typography color="#ffffff" fontSize={10} fontWeight={500} display="flex" gap={1} justifyContent="center" alignItems="center">
            {t('openrunPage.applyOpenrun.tooltip')}
            <Typography
              color="#ffffff"
              fontSize={10}
              fontWeight={700}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                '& > a': { color: 'white' },
              }}
              onClick={() => setIsShowOpenrunModal(true)}
            >
              {t('openrunPage.applyOpenrun.tooltipDetail')}
            </Typography>
          </Typography>
        }
      >
        {t('goods.price')} {lang !== 'ko' && '(USD)'}
      </Title>
      <Box mt={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
        <Container>
          <Box mb={7} pb={1} borderBottom="1px solid #919EAB3D">
            <Typography color={alpha('#000000', 0.87)}>{t('document.price.general')}</Typography>
          </Box>
          <TextField
            type="text"
            disabled={disabled || price === 0}
            {...(typeof price === 'number' && {
              value: lang === 'ko' ? numberWithCommas(price) : numberWithCommas(price / 1000),
            })}
            onFocus={handleFocus}
            onChange={onChangeSalePrice}
            onBlur={handleBlur}
            fullWidth
            inputProps={{ maxLength: 13 }}
            InputProps={{
              endAdornment: (() => {
                switch (lang) {
                  case 'ko':
                    return '원';
                  default:
                    return 'USD';
                }
              })(),
            }}
          />
          {freeSourceCheck}
        </Container>
        <Enterprise disabled={disabled || price === 0} />
      </Box>
      {infoCards}
    </Box>
  );
};
