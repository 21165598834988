import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const BrandPickModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 662px !important;
  }
`;
export const BrandPickModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  min-height: 389px;
  gap: 24px;
  padding: 24px;
`;
export const BrandPickModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 216px;
  overflow-y: auto;
`;
export const BrandPickModalListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const BrandPickModalSaveWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
