import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteButton } from '../../component/form';
import { Margin } from './style';
import Language from '../language';

import { Flex } from 'boards/DetailBoardWrite/style';
import { useHistory, useParams, useLocation } from 'react-router';
import {
  STATUS_COMPLETE,
  STATUS_COMPLETE_ON_OPEN,
  STATUS_DRAFT_ON_OPEN,
  STATUS_IN_REVIEW,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_REJECTED,
  STATUS_REQUESTED,
  STATUS_REQUESTED_ON_OPEN,
} from 'boards/DetailBoardWrite/constants';
import { AppContext } from 'app';
import { GET_GOODS_VIEW_PREVIEW_PAGE_URL } from '../../constants';
import { useMutation } from '@apollo/client';
import { CANCEL_EDIT_STATUS, CREATE_TRANSLATE, SEND_MAIL_PRODUCT, UPLOAD_GODO_GOODS_IMAGE } from 'api/mutations';
import { uploadFile } from 'api';
import html2canvas from 'html2canvas';
import { Loading } from '../../style';
import { SendMailModal } from 'boards/DetailBoardWrite/modal';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';

export default ({ refetch, isAdmin, showPreviewModal }) => {
  const { t } = useTranslation();
  const { isEdit, status, assetId, file, mainImage, subImage } = useDocumentStore((state) => ({
    isEdit: state.isEdit,
    status: state.status,
    assetId: state.assetId,
    file: state.file,
    mainImage: state.mainImage,
    subImage: state.subImage,
  }));
  const { showAlertMessage, showConfirmMessage, userInfo } = useContext(AppContext);

  // 메일 모달 표시여부
  const [isShowSendMailModal, setIsShowSendMailModal] = useState(false);
  // 번역 생성 모달 표시여부
  const [isShowTranslateModal, setIsShowTranslateModal] = useState(false);
  // 로딩여부
  const [isLoading, setIsLoading] = useState(false);
  // 고도 이미지 업로드하기
  const [uploadGodoImage] = useMutation(UPLOAD_GODO_GOODS_IMAGE);
  // 수정상태 취소하기
  const [cancelEditStatus] = useMutation(CANCEL_EDIT_STATUS);
  // 번역항목 생성하기
  const [createTranslate] = useMutation(CREATE_TRANSLATE);
  // 메일 발송하기
  const [sendMail] = useMutation(SEND_MAIL_PRODUCT);

  // URL 파생 정보
  const { lang, docId } = useParams<{ lang?: string; docId?: string }>();
  const history = useHistory();
  const currentUrl = useLocation().pathname;

  // 목록 보기 버튼 클릭 이벤트 처리기 메소드
  const onClickListButton = () => {
    // 루트 페이지로 이동
    history.push('/');
  };

  // 미리보기 버튼 클릭 이벤트 처리기 메소드
  const onClickPreviewButton = () => {
    // 사용자는 무조건 모달형태의 미리보기
    // 관리자는 진행중일 경우에 고도몰 화면표시
    // 현재 사용자가 관리자 그리고 진행중일 경우
    if (userInfo.isAdmin && (status === STATUS_IN_REVIEW || status === STATUS_IN_REVIEW_ON_OPEN)) {
      // 고도몰 상품번호를 입력하지 않은경우
      if (!assetId) {
        showAlertMessage(t('noGodoId'));
        // 종료
        return;
      }
      // 고도몰 화면 표시
      let godoUrl = GET_GOODS_VIEW_PREVIEW_PAGE_URL(assetId, lang);
      // 새창으로 표시
      window.open(godoUrl);
      // 종료
      return;
    }

    // 미리보기 보달 표시
    showPreviewModal();
    // 종료
    return;
  };

  // 메일 발송 버튼 동작 함수
  const onSendMail = async (toLang) => {
    // 로딩창 표시
    setIsLoading(true);

    // 메일 발송하기 mutation
    await sendMail({ variables: { docId, lang: toLang } });

    // 로딩창 제거
    setIsLoading(false);
    // 안내 메세지 표시
    showAlertMessage('작업이 완료되었습니다.', '메일이 발송되었습니다.');
  };

  // 번역생성 버튼 클릭 이벤트 처리기 메소드
  const onClickCreateTransButton = async () => {
    const fileConfig = file.fileComponents;
    if (fileConfig.length > 50) {
      alert(`상품 파일이 50개가 넘습니다. 수동으로 입력해주세요`);
      return;
    }

    // 번역 생성 모달 표시
    setIsShowTranslateModal(true);
  };

  // 오픈된 상품으로 이동하기 버튼 클릭 메소드
  const onClickOpenButton = async () => {
    // 오픈된 상품으로 이동
    window.location.href = `${currentUrl}/open`;
  };

  // 고도몰 어드민 이미지 반영하기 버튼태그 클릭 이벤트 처리기 메소드
  const onClickAppendGodoImageButton = async () => {
    // 고도몰 상품번호가 존재하지 않을경우 (저장된 값으로 지정)
    if (!assetId) {
      // 안내 메세지 표시
      showAlertMessage('작업을 완료할 수 없습니다.', '고도몰 상품번호가 입력되지 않았습니다.');
      // 종료
      return;
    }

    showConfirmMessage(`서비스에 바로 반영됩니다. 계속하시겠습니까?`, `고도몰 상품(${assetId})에 이미지를 반영합니다.`, async () => {
      if (!mainImage.imageBlob && !subImage.imageBlob) return;

      const subImageWrap = document.getElementById('thumbnailImage');
      subImageWrap.style.display = 'flex';

      const subCroppedImage = await html2canvas(subImageWrap, {
        scale: 2,
      });
      subCroppedImage.toBlob(async (blob) => {
        const mainImageObj = await uploadFile(mainImage.imageBlob, true);
        const thumbnailImageObj = await uploadFile(subImage.imageBlob, true);
        const subImageObj = await uploadFile(blob, true);

        await uploadGodoImage({
          variables: {
            goodsNo: assetId,
            detailKey: mainImageObj.key,
            listKey: thumbnailImageObj.key,
            mainKey: subImageObj.key,
          },
        });

        subImageWrap.style.display = 'none';
        // 안내 메세지 표시
        showAlertMessage('고도몰 상품 이미지가 반영되었습니다.', '관리자 페이지에서 변경 된 이미지를 확인하실 수 있습니다.');
      });
    });
  };

  // 수정 취소 버튼 클릭 이벤트 처리기 메소드
  const onClickCancelEditButton = async () => {
    // 로딩창 표시
    setIsLoading(true);

    // 수정상태 취소
    await cancelEditStatus({
      variables: {
        docId,
      },
    });

    // 데이터 재확보
    await refetch();
    // 로딩창 제거
    setIsLoading(false);
  };

  // 수정 취소 버튼
  const cancelEditButton = <WhiteButton onClick={onClickCancelEditButton}>{t('project.cancelEdit')}</WhiteButton>;
  // 메일 발송 버튼
  const sendMailBtn = (
    <WhiteButton
      onClick={() => {
        setIsShowSendMailModal(true);
      }}
    >
      {t('product.sendMail')}
    </WhiteButton>
  );
  // 고도몰 어드민 이미지 반영하기 버튼
  const appendGodoImageBtn = (
    <WhiteButton disabled={!assetId || !mainImage.imageBlob || !subImage.imageBlob} onClick={onClickAppendGodoImageButton}>
      {t('appendGodo')}
    </WhiteButton>
  );
  // 목록보기 버튼
  const listBtn = <WhiteButton onClick={onClickListButton}>{t('List.label')}</WhiteButton>;
  // 미리보기 버튼
  const previewBtn = <WhiteButton onClick={onClickPreviewButton}>{t('Preview.label')}</WhiteButton>;

  return (
    <>
      <Loading show={isLoading} />
      <SendMailModal
        isShow={isShowSendMailModal}
        onClose={() => {
          setIsShowSendMailModal(false);
        }}
        sendMail={onSendMail}
      />
      <Flex type="space-between">
        <Flex align="center">
          {!isAdmin && <span style={{ color: '#333', fontWeight: 700, marginRight: '7px' }}>{t('productLanguage')}</span>}
          <Language isAdmin={isAdmin} />
        </Flex>
        <div>
          {isAdmin && [STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN, STATUS_REJECTED].includes(status) && sendMailBtn}
          {isAdmin && [STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN, STATUS_IN_REVIEW, STATUS_IN_REVIEW_ON_OPEN].includes(status) && appendGodoImageBtn}
          {listBtn}
          {![STATUS_REQUESTED, STATUS_IN_REVIEW, STATUS_REQUESTED_ON_OPEN, STATUS_IN_REVIEW_ON_OPEN].includes(status) && previewBtn}
          {isEdit && status === STATUS_DRAFT_ON_OPEN && cancelEditButton}
        </div>
      </Flex>
      <Margin />
    </>
  );
};
