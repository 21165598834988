import React, { Component, useEffect, useState } from 'react';
import LocaleSettingsStyle, { LocaleSettingStyle } from './LocaleSettingsStyle';
import { uploadFile } from '../../../api';
import { TreeBtn, Flex, FlexChild, FieldTitle, FieldInput, FieldTextarea } from '../style';

const defaultColor = '#000000';

const LocaleSettings = (props) => {
  const { i18n: i18n, setCategoryData, godoCateCd } = props;
  const langNameList = props.langList.map((val) => val.id);
  const [locale, setLocale] = useState(i18n[0]);

  useEffect(() => {
    setLocale((prev) => {
      let lang = prev?.lang ?? 'ko';
      return i18n.find((data) => data.language.code === lang);
    });
  }, [i18n]);

  function onSelect(e) {
    const { language } = e;

    if (locale?.language.code !== language) {
      setLocale(i18n.find((data) => data.language.code === language));
    }
  }

  function onChange(e, property) {
    e.persist();

    setCategoryData((prev) => {
      let value = e.target.value;
      let target = prev.i18n.find((i18n) => i18n.language.code === locale.language.code);

      if (e.target.type === 'radio' || e.target.type === 'checkbox') {
        value = value === 'false' || value === '' ? 0 : 1;
      }
      if (property === 'color') {
        if (value === 1) {
          value = defaultColor;
        }
      }

      target[property] = value;

      return { ...prev };
    });
  }

  function changePath(property) {
    const properties = ['anchor', 'map', 'none'];
    if (!properties.includes(property)) {
      throw new Error(`해당 프로퍼티가 존재하지 않습니다. (property: ${property})`);
    }

    setCategoryData((prev) => {
      let target = prev.i18n.find((i18n) => i18n.language.code === locale.language.code);

      // map과 anchor는 둘 중 하나만 존재해야 함.
      if (property === 'anchor') {
        target['map'] = null;
      } else if (property === 'map') {
        target['anchor'] = null;
      } else {
        target['map'] = null;
        target['anchor'] = null;
      }

      return { ...prev };
    });
  }

  function changeColor(color) {
    setCategoryData((prev) => {
      let target = prev.i18n.find((i18n) => i18n.language.code === locale.language.code);

      target['color'] = color;

      return { ...prev };
    });
  }

  function onChangeImage(key, previewImage) {
    setCategoryData((prev) => {
      let target = prev.i18n.find((i18n) => i18n.language.code === locale.language.code);

      target.file = {
        key: key,
      };

      target.previewImage = previewImage;

      return { ...prev };
    });
  }

  async function deleteImage(e) {
    const image = locale?.file;

    if (!image) {
      throw 'has not image';
    }

    onChangeImage('delete');
  }

  async function uploadImage(e) {
    const files = e.target.files || e.target.value;
    const file = files && files[0];

    try {
      const response = await uploadFile(file, true);

      onChangeImage(response.key, URL.createObjectURL(file));
    } catch (err) {
      // TODO: error log
    }
  }

  return (
    <LocaleSettingsStyle>
      <ul>
        {langNameList.map((name) => (
          <LangButton key={name} name={name} onClick={onSelect} selected={locale?.language.code === name} />
        ))}
      </ul>
      {locale && (
        <LocaleSetting
          locale={locale}
          godoCateCd={godoCateCd}
          deleteImage={deleteImage}
          uploadImage={uploadImage}
          onChange={onChange}
          changePath={changePath}
          changeColor={changeColor}
          onChangeImage={onChangeImage}
        />
      )}
    </LocaleSettingsStyle>
  );
};

const LocaleSetting = (props) => {
  const [path, setPath] = useState('none');
  const [fileKey, setFileKey] = useState(new Date().getTime());

  let colorTimer = null;

  useEffect(() => {
    setPath(props.locale.anchor ? 'anchor' : props.locale.map ? 'map' : 'none');
  }, [props.locale.anchor, props.locale.map]);

  const changePath = (e) => {
    e.persist();
    const path = e.target.value;
    setPath(path);
    props.changePath(path);
  };
  const changeColor = (e) => {
    e.persist();
    clearTimeout(colorTimer);
    let color = e.target.value;

    colorTimer = setTimeout(() => {
      colorTimer = null;
      props.changeColor(color);
    }, 100);
  };
  const deleteImage = (e) => {
    props.deleteImage(e);
    setFileKey(new Date().getTime());
  };

  let locale = props.locale;
  let image = locale && locale.file;
  let name = locale && locale.name;
  let isShowResultCount = locale && Boolean(locale.isShowResultCount);
  let color = locale && locale.color;
  let anchor = locale && locale.anchor;
  let isBlankAnchor = locale && Boolean(locale.isBlankAnchor);
  let map = locale && locale.map;

  return (
    <LocaleSettingStyle>
      <Flex justify="space-between" align="center">
        <FieldTitle required>카테고리명</FieldTitle>
        <FlexChild flex="1" maxWidth="270px">
          <FieldInput value={name} onChange={(e) => props.onChange(e, 'name')} />
        </FlexChild>
      </Flex>
      <Flex justify="space-between" align="center">
        <FieldTitle>검색결과바</FieldTitle>
        <FlexChild flex="1" maxWidth="270px">
          <label>
            <input type="radio" name="isShowResultCount" value={true} checked={isShowResultCount} onChange={(e) => props.onChange(e, 'isShowResultCount')} />
            노출함
          </label>
          <label>
            <input type="radio" name="isShowResultCount" value={false} checked={!isShowResultCount} onChange={(e) => props.onChange(e, 'isShowResultCount')} />
            노출안함
          </label>
        </FlexChild>
      </Flex>
      <Flex>
        <FieldTitle>배너설정</FieldTitle>
      </Flex>
      <div className="child-box">
        <Flex justify="space-between">
          <FieldTitle>이미지</FieldTitle>
          <FlexChild flex="1" maxWidth="270px">
            <input id="fileSelector" type="file" key={fileKey || ''} onChange={props.uploadImage} accept="image/*" />
            <label htmlFor="fileSelector">
              <span>찾아보기</span>
            </label>
            <input
              type="text"
              disabled
              readOnly
              value={locale.previewImage ? locale.previewImage : image?.uploadFile?.fullName ? `https://storage.acon3d.com/${image?.uploadFile?.fullName}` : ''}
            />
            {image && (
              <>
                <button onClick={deleteImage}>-</button>
                <div>
                  <img
                    src={locale.previewImage ? locale.previewImage : image?.uploadFile?.fullName ? `https://storage.acon3d.com/${image?.uploadFile?.fullName}` : ''}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            )}
          </FlexChild>
        </Flex>
        <Flex justify="space-between" align="center">
          <FieldTitle>배경</FieldTitle>
          <FlexChild flex="1" maxWidth="270px">
            <label>
              <input type="radio" name="color" value="" checked={!color} onChange={(e) => props.onChange(e, 'color')} />
              없음
            </label>
            <label>
              <input type="radio" name="color" value={defaultColor} checked={!!color} onChange={(e) => props.onChange(e, 'color')} />
              색상칩
              <input type="color" value={color || defaultColor} onChange={changeColor} />
            </label>
          </FlexChild>
        </Flex>
        <Flex justify="space-between" align="center">
          <FieldTitle>경로</FieldTitle>
          <FlexChild flex="1" maxWidth="270px">
            <label>
              <input type="radio" name="none" value="none" checked={path === 'none'} onChange={(e) => changePath(e)} />
              없음
            </label>
            <label>
              <input type="radio" name="anchor" value="anchor" checked={path === 'anchor'} onChange={(e) => changePath(e)} />
              링크
            </label>
            <label>
              <input type="radio" name="map" value="map" checked={path === 'map'} onChange={(e) => changePath(e)} />
              이미지맵
            </label>
          </FlexChild>
        </Flex>
        {path === 'anchor' && (
          <Flex justify="flex-end">
            <FlexChild flex="1" maxWidth="270px">
              <FieldTextarea type="text" placeholder="https://" value={anchor} onChange={(e) => props.onChange(e, 'anchor')} />
              <div>
                <label>
                  <input type="radio" name="isBlankAnchor" value={false} checked={!isBlankAnchor} onChange={(e) => props.onChange(e, 'isBlankAnchor')} />
                  <span>페이지 이동</span>
                </label>
                <label>
                  <input type="radio" name="isBlankAnchor" value={true} checked={isBlankAnchor} onChange={(e) => props.onChange(e, 'isBlankAnchor')} />
                  <span>새 창 이동</span>
                </label>
              </div>
            </FlexChild>
          </Flex>
        )}
        {path === 'map' && (
          <Flex justify="flex-end">
            <FlexChild flex="1" maxWidth="270px">
              <FieldTextarea type="text" placeholder="<map>...</map>" value={map} onChange={(e) => props.onChange(e, 'map')} />
            </FlexChild>
          </Flex>
        )}
        <Flex justify="flex-end">
          <a
            target="_blank"
            href={`https://www.acon3d.com/${locale.language.code === 'cn' ? 'zh' : locale.language.code === 'jp' ? 'ja' : locale.language.code}/category/${props.godoCateCd}`}
          >
            <TreeBtn>해당 카테고리 보기</TreeBtn>
          </a>
        </Flex>
      </div>
    </LocaleSettingStyle>
  );
};

const LangButton = ({ selected, name, onClick }) => {
  const clickHandler = (e) => {
    e.language = name;
    onClick(e);
  };
  return (
    <li className={selected ? 'selected' : ''} onClick={clickHandler}>
      {name.toUpperCase()}
    </li>
  );
};

export default LocaleSettings;
