import React, { useContext, useEffect } from 'react';
import { Copyright, Subject, MainImage, Keywords, SalePrice, SubImage, Model, Dynamic, FileStructure } from './oldValue';
import { Field, Title, Wrap, Box, VersionContainer } from './style';
import { AppContext } from 'app';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { GET_PROD_DIFF_ITEM } from 'api/quries';
import { useQuery } from '@apollo/client';
import { GET_PROD_VERSION } from 'api/quries';
import Loading from '../../common/editor/Loading';
import ContentsDiff from './contentsDiff';
import dateFormat from 'dateformat';
import context from './provider/product';

interface Props {
  major: string|string[];
  minor: string|string[];
  origin: boolean;
}

export default (({ major, minor, origin }: Props) => {
    const { userInfo } = useContext(AppContext);
    const { state, dispatch } = useContext(context);

    // 관리자 여부 
    const { isAdmin, isAccept } = userInfo;
    // URL 파생 정보 
    const { docId, lang } = useParams<{ docId?: string; lang?: string;  }>();
    // history 
    const history = useHistory();

    const { data: versionData } = useQuery(GET_PROD_VERSION, {
        fetchPolicy: "no-cache",
        variables: {
            docId,
            lang
        }
    });

    // 현재 항목 데이터 
    const { data, loading } = useQuery(GET_PROD_DIFF_ITEM, {
        variables: (() => {
            const obj: {
                docId: string;
                lang: string;
                isOpen: boolean;
                major?: number;
                minor?: number;
            } = {
                docId,
                lang,
                isOpen: false
            };

            if (major)
                obj.major = Number(major);
            if (minor)
                obj.minor = Number(minor);

            return obj;
        })()
    });

    // 번역도구 
    const { t } = useTranslation();

    const parseStateObj = (obj) => {
        const {
            brandId,
            isEdit,
            status,
            godoGoodsNo,
            title,
            contents,
            comments,
            description,
            imageList,
            dynamicItem,
            price,
            salePrice,
            commission,
            copyright,
            categories,
            keywords,
            modelFileName,
            model,
            modelConfigList,
            fileExtensionList,
            fileConfigList,
            fileUpdateList,
            related,
        } = obj;


        // 메인 이미지 항목 
        const mainImageItem = imageList.find(x => x.type === 'main');
        // 썸네일 이미지 항목 
        const thumbnailImageItem = imageList.find(x => x.type === 'thumbnail');
        // 서브 이미지1 항목 
        const subImage1Item = imageList.find(x => x.type === 'subImage1');
        // 서브 이미지2 항목 
        const subImage2Item = imageList.find(x => x.type === 'subImage2');
        // 서브 이미지3 항목 
        const subImage3Item = imageList.find(x => x.type === 'subImage3');

        let fileConfig = fileConfigList;
        if (fileConfig && fileConfig.length > 0) {
            fileConfig = fileConfig.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    size: x.size
                }
            })
        }
        let updateHistory = fileUpdateList;
        if (updateHistory && updateHistory.length > 0) {
            updateHistory = updateHistory.map(x => {
                return {
                    id: x.id.toString(),
                    name: x.name,
                    date: x.date
                }
            })
        }

        return {
            isDisp: true,
            docId,
            title,
            isEdit,
            contents,
            status,
            copyright: JSON.parse(copyright),
            brandId,
            description,
            dynamicItem,
            modelFileId: model?.id,
            modelFileName,
            mainImageUrl: mainImageItem?.item?.fullName ? mainImageItem?.item?.fullName : thumbnailImageItem?.item?.fullName,
            mainImageCropInfo: (mainImageItem && mainImageItem.cropX !== null) ? {
                left: mainImageItem.cropX,
                top: mainImageItem.cropY,
                width: mainImageItem.cropWidth,
                height: mainImageItem.cropHeight
            } : null,

            thumbnailImageUrl: thumbnailImageItem?.item?.fullName,
            thumbnailImageCropInfo: (thumbnailImageItem && thumbnailImageItem.cropX !== null) ? {
                left: thumbnailImageItem.cropX,
                top: thumbnailImageItem.cropY,
                width: thumbnailImageItem.cropWidth,
                height: thumbnailImageItem.cropHeight
            } : null,

            subImage1Url: subImage1Item?.item?.fullName,
            subImage1CropInfo: (subImage1Item && subImage1Item.cropX !== null) ? {
                left: subImage1Item.cropX,
                top: subImage1Item.cropY,
                width: subImage1Item.cropWidth,
                height: subImage1Item.cropHeight
            } : null,

            subImage2Url: subImage2Item?.item?.fullName,
            subImage2CropInfo: (subImage2Item && subImage2Item.cropX !== null) ? {
                left: subImage2Item.cropX,
                top: subImage2Item.cropY,
                width: subImage2Item.cropWidth,
                height: subImage2Item.cropHeight
            } : null,

            subImage3Url: subImage3Item?.item?.fullName,
            subImage3CropInfo: (subImage3Item && subImage3Item.cropX !== null) ? {
                left: subImage3Item.cropX,
                top: subImage3Item.cropY,
                width: subImage3Item.cropWidth,
                height: subImage3Item.cropHeight
            } : null,

            // 이미지 종료 
            godoGoodsNo,
            // 반려 사유 
            rejectComment: (comments ?? []).find(x => x.type === 'reject')?.message,
            // 수정 사항
            editMessage: (comments ?? []).find(x => x.type === 'editMessage')?.message,
            // 수정 의견
            editComment: (comments ?? []).find(x => x.type === 'editComment')?.message,
            originGodoGoodsNo: godoGoodsNo,
            price,
            salePrice,
            isFree: salePrice === 0,
            commission,
            category: Array.isArray(categories) ? categories.map(x => x.id) : [],
            modelConfig: modelConfigList.map(x => { return { value: x.id, label: x.name } }),
            extension: fileExtensionList && fileExtensionList.map(x => x.name),
            fileConfig,
            updateHistory,
            keywords,
            related: (related ?? []).map((x) => x.to.toString())
        };
    };

    useEffect(() => {
        // 데이터가 존재하지 않을경우 
        if (!data) {
            // 종료한다 
            return;
        }

        // 데이터가 존재하지 않음. 권한없음 
        if (!data.getProduct) {
            history.push('/');
            // 메인 홈으로 이동 
            // 종료
            return;
        }

        
        // 확보된 데이터 state에 저장 
        dispatch({
            type: 'set',
            value: parseStateObj(!origin ? data.getProduct : JSON.parse(data.getProduct.versions[0].contents))
        });
    }, [isAdmin, data]);

    // 로딩중인 경우
    if (loading && !data)
        return (<Loading empty={true} />);

    // 버전 객체 
    const versionObj = data && data.getProduct && parseStateObj(JSON.parse(data.getProduct.versions[0].contents));
    // 원복 객체
    const originObj = data && data.getProduct && parseStateObj(data.getProduct);
    
    const originVersion = origin && versionData && versionData.productVersions.find(x => x.major === Number(major));
    const targetVersion = !origin && versionData && versionData.productVersions[0];
    return (
        <>
            {(originVersion || targetVersion) &&
                <Wrap isDisp={state.isDisp}>
                    <div style={{ fontWeight: 700, marginBottom: `30px` }}>{origin ? `BEFORE` : 'AFTER'}</div>
                    <VersionContainer>
                        {!origin && <span>{`Ver. ${targetVersion.major - 1} : ${dateFormat(targetVersion.created, 'yyyy-mm-dd')}`}</span>}
                        {origin && <span>{(originVersion.major === 1 ? `Original` : `Ver. ${originVersion.major - 1}`) + ` : ${dateFormat(originVersion.created, 'yyyy-mm-dd')}`}</span>}
                    </VersionContainer>
                    <div style={{ marginTop: `30px`, height: `1px`, backgroundColor: `#000` }} />

                    {/* 제목 필드 */}
                    {versionObj.title !== originObj.title && <Subject />}

                    {/* 메인 이미지 */}
                    {(versionObj.mainImageUrl !== originObj.mainImageUrl
                        || JSON.stringify(versionObj.mainImageCropInfo) !== JSON.stringify(originObj.mainImageCropInfo)
                        || JSON.stringify(versionObj.thumbnailImageCropInfo) !== JSON.stringify(originObj.thumbnailImageCropInfo)
                    ) && <MainImage />}

                    {/* 서브 이미지 */}
                    {(versionObj.subImage1Url !== originObj.subImage1Url ||
                        versionObj.subImage2Url !== originObj.subImage2Url ||
                        versionObj.subImage3Url !== originObj.subImage3Url ||
                        JSON.stringify(versionObj.subImage1CropInfo) !== JSON.stringify(originObj.subImage1CropInfo) ||
                        JSON.stringify(versionObj.subImage2CropInfo) !== JSON.stringify(originObj.subImage2CropInfo) ||
                        JSON.stringify(versionObj.subImage3CropInfo) !== JSON.stringify(originObj.subImage3CropInfo)
                    ) && <SubImage />}

                    {/* 본문 비교 */}
                    {originObj.contents && versionObj.contents && versionObj.contents !== originObj.contents &&
                        <ContentsDiff origin={origin} oldStr={versionObj.contents} newStr={originObj.contents}
                        />}

                    {/* 가격 비교 */}
                    {Number(versionObj.salePrice) !== Number(originObj.salePrice) &&
                        <Field>
                            <Title>{t('goods.price')}</Title>
                            <Box>
                                <SalePrice isAdmin={false} />
                            </Box>
                        </Field>
                    }

                    {/* 동적요소 비교 */}
                    {versionObj.dynamicItem !== originObj.dynamicItem &&
                        <Field>
                            <Title>{t('goods.modelInfo')}</Title>
                            <Box>
                                <Dynamic />
                            </Box>
                        </Field>
                    }

                    {/* 저작권 비교 */}
                    {JSON.stringify(versionObj.copyright) !== JSON.stringify(originObj.copyright) &&
                        <Field>
                            <Title>{t('goods.copyright')}</Title>
                            <Box>
                                <Copyright inputs={null} />
                            </Box>
                        </Field>
                    }

                    {/* 모델파일 비교 */}
                    {(versionObj.modelFileId !== originObj.modelFileId || 
                        JSON.stringify(versionObj.fileConfig) !== JSON.stringify(originObj.fileConfig)) &&
                        <Field isDisp={state.isDisp}>
                            <Title>{t('goods.fileMng')}</Title>
                            <Box>
                                {/* 작품 파일 */}
                                {versionObj.modelFileId !== originObj.modelFileId && <Model isAccept={isAccept} setIsInProgress={null} isAdmin={false} />}
                                {/* 파일 구성 및 용량 */}
                                {JSON.stringify(versionObj.fileConfig) !== JSON.stringify(originObj.fileConfig) && <FileStructure />}
                            </Box>
                        </Field>
                    }

                    {/* 검색키워드 비교 */}
                    {versionObj.keywords !== originObj.keywords &&
                        <Field>
                            <Title>{t('goods.searchMng')}</Title>
                            <Box>
                                <Keywords />
                            </Box>
                        </Field>
                    }
                </Wrap>}
        </>
    );
});