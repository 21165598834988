import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const Footer = styled.div`
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const EmptyButton = styled.button`
  background-color: initial;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
`;

export const Title = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
  justify-content: center;
`;

export const Contents = styled.div`
  display: flex;
  flex-grow: 1;
  margin-bottom: 40px;
  flex-direction: column;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding-left: 28px;
  padding-right: 28px;
  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    background-color: white;
    border: 1px solid black;
  }
  .rs-radio-wrapper::before {
    border: 1px solid black;
  }
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 8px;
    height: 8px;
    background: black;
    margin-top: 4px;
    margin-left: 4px;
  }
`;

export const SelectLanguageModal = styled(Modal)`
  .modal-dialog {
    padding: 12px;
  }
  .modal-content {
    width: 396px;
    height: 248px;
    margin: 0 auto;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 5px;
  }
`;
