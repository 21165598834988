import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

/**
 * 특정 월의 정산 내역이 정산 예정 내역인지 확인하는 함수
 * @param country 작가의 국가 정보
 * @param today 현재 날짜
 * @param year 정산 내역의 연도
 * @param month 정산 내역의 월
 */
export const checkIsExpectation = (country: string, today: dayjs.Dayjs, year: number, month: number): boolean => {
  const currentYear = today.get('year');
  const currentMonth = today.get('month') + 1;
  const currentDate = today.get('date');

  if (country === 'KR') {
    // 국내 작가
    if (year > currentYear || (year === currentYear && month >= currentMonth)) return true; // 현재 연월과 같거나 더 이후인 경우 무조건 예정 뱃지를 붙임
    else if (year === currentYear && month === currentMonth - 1 && currentDate < 10) return true; // 현재 연월의 전월인 경우, 현재 날짜가 10일보다 전인 경우만 예정 뱃지를 붙임
    return false;
  } else {
    // 해외 작가
    if (month === 1 || month === 2 || month === 3) {
      return today.isSameOrAfter(dayjs(`${year - 1}-12-1`, 'YYYY-M-D'), 'month') && today.isBefore(dayjs(`${year}-4-9`, 'YYYY-M-D'), 'date'); // 현재 날짜가, 정산기준 전년도 12월 (포함) ~ 정산기준 년 4월 9일 (미포함)인 경우엔 예정 뱃지를 붙임
    } else if (month === 4 || month === 5 || month === 6) {
      return today.isSameOrAfter(dayjs(`${year}-3-1`, 'YYYY-M-D'), 'month') && today.isBefore(dayjs(`${year}-7-9`, 'YYYY-M-D'), 'date'); // 현재 날짜가, 정산기준 년 3월 (포함) ~ 정산기준 년 7월 9일 (미포함)인 경우엔 예정 뱃지를 붙임
    } else if (month === 7 || month === 8 || month === 9) {
      return today.isSameOrAfter(dayjs(`${year}-6-1`, 'YYYY-M-D'), 'month') && today.isBefore(dayjs(`${year}-10-9`, 'YYYY-M-D'), 'date'); // 현재 날짜가, 정산기준 년 6월 (포함) ~ 정산기준 년 10월 9일 (미포함)인 경우엔 예정 뱃지를 붙임
    } else {
      return today.isSameOrAfter(dayjs(`${year}-9-1`, 'YYYY-M-D'), 'month') && today.isBefore(dayjs(`${year + 1}-1-9`, 'YYYY-M-D'), 'date'); // 현재 날짜가, 정산기준 년 9월 (포함) ~ 정산기준 내년 1월 9일 (미포함)인 경우엔 예정 뱃지를 붙임
    }
  }
};
