import React, { useEffect, useState } from 'react';

export default (function (props) {
    const { data, filterFunction, filter } = props
    const [render, setRender] = useState(<></>)
    if (!data) {
        throw new Error('param error')
    }
    useEffect(() => {
        var opt = {}
        if (filterFunction && !filterFunction(data, filter)) {
            opt = { style: { display: "none" } }
        }
        setRender(
            <div className='row' {...opt}>{data}</div>
        )
    }, [data, filterFunction, filter])
    return (
        <>
            {render}
        </>
    )
});