import React, { useContext, useEffect } from 'react';
import { AppContext } from '../app';
import CryConi from '../img/cry_coni.png';
import './NoMatch.scss';

const NoMatch = (props) => {
    const { setBackgroundColor } = useContext(AppContext);

    useEffect(() => {
        setBackgroundColor('white');
    }, [setBackgroundColor]);

    return (
        <div className="noMatch">
            <div className="noMatch__wrap">
                <div className="noMatch__logo">
                    <img src={CryConi} alt="ACON3d" />
                </div>
                <div className="msg">
                    <div className="num">404</div>
                    <div className="NotFound">NOT FOUND</div>
                </div>
            </div>
        </div>
    );
}

export default NoMatch;