import { Typography, Stack } from '@mui/material';
import { BlockContainer } from 'components/style';
import { styled as muiStyle } from '@mui/material/styles';
import styled from 'styled-components';
import { SimpleInput } from './input';

export const PageTitle = muiStyle(Typography)(() => ({
  color: '#333',
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '100%',
}));
export const FormGroup = muiStyle(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const FormInput = styled(SimpleInput)`
  border: 1px solid ${(props) => (props.isValid ? '#cdcdcd' : '#F300BA')};
  height: 48px;
  &:focus {
    outline: none;
    border: 1px solid #333333;
  }

  &:placeholder-shown {
    border: 1px solid #cdcdcd;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FlexRow = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${({ height }) => (height ? `height: ${height}px` : '')};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px` : '')};
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};

  margin-top: ${({ marginTop }) => marginTop || '0'}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
`;
export const FlexColumn = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'flex-start'};

  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
  row-gap: ${({ rowGap }) => rowGap || '0'}px;
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const TextBox = styled.pre`
  height: ${({ height }) => height || '300'}px;
  overflow: scroll;
`;

export const TermBox = styled.pre`
  width: 100%;
  height: ${({ height }) => height || '300'}px;
  overflow-y: scroll;

  border-radius: 6px;
  border: 1px solid #dfdfdf;
`;

export const Contents = styled.section`
  width: 100%;
  margin-bottom: 80px;
`;

export const Summary = styled.div`
  margin-top: 8px;
  margin-left: 4px;
  min-height: 20px;
  line-height: 100%;
  font-size: 12px;
  ${(props) => (props.disabled ? 'color: #A4A4A4;' : props.success ? 'color: #3A3E94;' : props.fail ? 'color: #F300BA;' : 'color: #FF007B;')}
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 170%;
  color: #4a4a4a;
`;

export const SectionTitle = styled.h2`
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 60px;
`;

export const InputTitle = styled.div`
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 14px;
`;

export const Section = styled.section`
  .mt-30 {
    margin-top: 30px;
  }
`;

export const Signup = styled(BlockContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 624px;
  margin: 0 auto;
  padding-top: 56px;
  padding-bottom: 100px;
  width: 100%;
  color: #4a4a4a;
  word-break: keep-all;

  &[data-status='0'] ${Section} {
    margin-top: 30px;
  }
`;

export const FormTitle = styled.div`
  align-items: center;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;

  &:before {
    color: #f300ba;
    content: '•';
    margin-right: 10px;
  }
`;

export const Label = styled.div`
  align-items: center;
  color: #333333;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 14px;

  &:before {
    color: #f300ba;
    content: '•';
    margin-right: 10px;
  }
`;

export const InfoLabel = styled.div`
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : '#7C7C7C')};
  margin-top: 8px;
`;

export const Button = styled.button`
  display: flex;
  height: 100%;
  width: 126px;
  min-width: 126px;
  justify-content: center;
  padding: 13px;
  margin-left: 10px;

  font-size: 14px;
  background: #ffffff;
  border: 1px solid #7c7c7c;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  cursor: pointer;
`;

export const ArrowAnchor = styled.a`
  color: #7c7c7c;
  font-size: 12px;
  font-weight: 400;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  width: ${({ width }) => `${width}px` || '100%'};
  ${({ width }) => (width ? `min-width: ${width}px` : '')};
  color: ${({ color }) => color || '#333333'};
  font-size: ${({ fontSize }) => fontSize || '14'}px;
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ color }) => color || '#F0F0F0'};

  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
`;

export const CreatorDivider = styled.div`
  position: relative;
  height: 12px;
  top: 13px;
  border: 1px solid #cdcdcd;
  transform: rotate(180deg);
  margin: 0 20px;
`;
