import { LanguageCodeEnum, useCreatePartnerMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { signinState, signState } from './SignUp.atoms';
import { PartnerTypeEnum } from './type';

export default function useTerm() {
  const { i18n } = useTranslation();
  const [createPartner] = useCreatePartnerMutation();
  const state = useRecoilValue(signinState);

  const completeCreateUser = async (signatureAzureStoragePath?: string) => {
    await createPartner({
      variables: {
        user: {
          type: state.userType,
          subType: state.businessType ? state.businessType : '',
          joinLanguage: i18n.language as LanguageCodeEnum,
          account: state.userEmail,
          companyName: state.userType === PartnerTypeEnum.Personal ? null : state.companyName,
          password: state.password,
          name: state.name,
          contact: state.contact,
          contactAdditional: state.contactAdditional,
          settle: {
            bank: 0,
            nationalIdNumber: i18n.language === 'ko' ? state.rrn : state.taxPayerId,
            country: i18n.language === 'ko' ? 'KR' : state.country,
            signLang: i18n.language as LanguageCodeEnum,
            bankAccountNumber: '',
            bankName: '',
            bankAccountOwner: '',
          },
          reference: state.documents,
          snsUrl: i18n.language === 'ko' ? null : state.snsUrl,
        },
        brand: {
          koName: state.brandNames.koBrandName,
          enName: state.brandNames.enBrandName,
          cnName: state.brandNames.zhBrandName,
          jpName: state.brandNames.jaBrandName,
        },
        file: {
          idCard: null,
          bankBook: null,
          sign: null,
        },
      },
    });
  };

  return { completeCreateUser };
}
