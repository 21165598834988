import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Divider, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import SettlementInfo from './SettlementInfo';
import SettlementList from './SettlementList/SettlementList';
import SettlementFooter from './SettlementFooter/SettlementFooter';
import { SettlementOutput, useGetBrandSettlementsQuery, useMeQuery } from '../../generated/graphql';
import SettlementLoading from './SettlementLoading/SettlementLoading';
import useAuth from '../../hooks/useAuth';
import SettlementRecordsSnackbar from './SettlementRecordsSnackbar';

export default function SettlementPage() {
  const theme = useDesignSystemTheme();

  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { isAuthenticated } = useAuth();

  const { data: meData, loading: meLoading } = useMeQuery({
    skip: !isAuthenticated,
    onError: (e) => {
      console.error(e, '작가 정보를 조회하는 도중 오류가 발생하였습니다');
    },
  });

  const country = meData?.me?.settle?.country || '';

  const { data: settlementData, loading: settlementLoading } = useGetBrandSettlementsQuery({
    variables: {
      pagination: {
        page,
        limit: 10,
      },
    },
    onError: (e) => {
      console.error(e, '정산 정보를 조회하는 도중 오류가 발생하였습니다');
    },
    fetchPolicy: 'no-cache',
  });

  const settlementList: SettlementOutput[] = settlementData?.getBrandSettlements?.data || [];

  const lastPage = settlementData?.getBrandSettlements?.pagination?.lastPage || 0;

  function handlePageChange(_event: React.ChangeEvent<unknown>, page: number) {
    setPage(page);
  }

  const loading = meLoading || settlementLoading;

  return (
    <>
      <Box
        sx={{
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          [theme.breakpoints.down('largeTablet')]: {
            padding: 0,
            gap: 0,
          },
        }}
      >
        {/*  title  */}
        <Box
          sx={{
            [theme.breakpoints.down('largeTablet')]: {
              backgroundColor: theme.palette['color/white'],
              padding: '40px 16px 16px 16px',
            },
          }}
        >
          <Typography
            variant={'typography/title/medium/bold'}
            sx={{
              [theme.breakpoints.down('largeTablet')]: {
                fontSize: '20px',
                lineHeight: '28px',
              },
            }}
          >
            {t('settlementPage.title')}
          </Typography>
        </Box>
        {/* contents */}
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            width: '100%',
            maxWidth: 'calc(100vw - 80px)',
            [theme.breakpoints.down('largeTablet')]: {
              flexDirection: 'column',
              maxWidth: '100vw',
              gap: '8px',
              marginTop: '8px',
            },
          }}
        >
          {/*  left  */}
          <SettlementInfo />
          {/*  right  */}
          <Box
            sx={{
              borderRadius: '16px',
              backgroundColor: theme.palette['color/white'],
              padding: '24px',
              width: '100%',
              height: 'fit-content',
              [theme.breakpoints.down('largeTablet')]: {
                borderRadius: 0,
                padding: 0,
                backgroundColor: 'unset',
              },
            }}
          >
            {loading && <SettlementLoading />}
            {!loading && (
              <>
                <SettlementList list={settlementList} country={country} />
                <Divider sx={{ marginTop: '24px', [theme.breakpoints.down('largeTablet')]: { display: 'none' } }} />
                <SettlementFooter page={page} onPageChange={handlePageChange} lastPage={lastPage} />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <SettlementRecordsSnackbar />
    </>
  );
}
