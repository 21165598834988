import { Box, TextField } from '@mui/material';
import { CalendarPicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Chevron } from '../icons/icon-chevron.svg';
import { ReactComponent as CalendarIcon } from '../icons/icon-calendar.svg';
import { color } from 'acon-mui/style';

function LeftChevron() { return <Chevron style={{ transform: 'rotate(-90deg)' }} /> };
function RightChevron() { return <Chevron style={{ transform: 'rotate(90deg)' }} /> };

const defaultEnd = new Date();
const defaultStart = new Date(dayjs(defaultEnd).subtract(1, "month").add(1, "day").format('YYYY-MM-DD'));
interface Props {
  isMobile?: boolean;
  startDate?: Date|null;
  endDate?: Date|null;
  setStartDate: (val: Date) => void;
  setEndDate: (val: Date) => void;
}

enum CalendarTypeEnum {
  Start="start",
  End="end"
}

export default function DateRangePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isMobile,
}: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<{ start: Date|null, end: Date|null }>({ start: null, end: null });
  const [CalendarType, setCalendarType] = useState<CalendarTypeEnum|null>(null);

  const handleClickTextField = (type: CalendarTypeEnum) => {
    if (!startDate && !endDate) {
      setCalendarType(CalendarTypeEnum.Start);
    }
    setCalendarType(type);
  };

  const handleClickPicker = (val: string, type: CalendarTypeEnum) => {
    const current = new Date(val);
    const start = selected.start || startDate|| defaultStart;
    const end = selected.end || endDate || defaultEnd;

    let newStart = type === CalendarTypeEnum.Start? current : start;
    let newEnd = type === CalendarTypeEnum.End? current : end;
    
    if (newStart > newEnd) {
      const x = newStart;
      newStart = newEnd;
      newEnd = x;

      setSelected({ 
        ...selected, 
        ...(type === CalendarTypeEnum.Start ? {
          end: newEnd
        } : {
          start: newStart
        })
      });
      setCalendarType(type);

      if (
        (type === CalendarTypeEnum.Start && selected.start)
        || (type === CalendarTypeEnum.End && selected.end)
        || (startDate && endDate)
      ) {
        setStartDate(newStart);
        setEndDate(newEnd);
        setSelected({ start: null, end: null });
        setCalendarType(null);
      }
    } else {
      setSelected({ 
        ...selected, 
        ...(type === CalendarTypeEnum.Start ? {
          start: newStart
        } : {
          end: newEnd
        })
      });
      setCalendarType(type === CalendarTypeEnum.Start ? CalendarTypeEnum.End : CalendarTypeEnum.Start);

      if (
        (type === CalendarTypeEnum.Start && selected.end)
        || (type === CalendarTypeEnum.End && selected.start)
        || (startDate && endDate)
      ) {
        setStartDate(newStart);
        setEndDate(newEnd);
        setSelected({ start: null, end: null });
        setCalendarType(null);
      }
    }
  };

  const renderPickerDay = (
    day: Date, 
    selectedDays: Date[], 
    pickersDayProps: PickersDayProps<Date>
  ) => {
    let style: React.CSSProperties & { [key: string]: unknown } = {};
    const start = selected.start|| startDate || defaultStart;
    const end = selected.end || endDate || defaultEnd;

    const commonStyle: React.CSSProperties & { [key: string]: unknown }  = {
      margin: '0',
      padding: '0 2px',
      ...(pickersDayProps.today ? {
        width: '40px'
      } : {
        boxSizing: 'content-box',
        border: 'none'
      }),
      '&:hover': {
        color: 'inherit',
      },
    }
    if (day.toLocaleDateString() === start.toLocaleDateString()) {
      style = {
        ...commonStyle,
        fontWeight: '600',
        color: '#fff',
        backgroundColor: color.primary,
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
      };
    }
    if (day > start && day < end) {
      style = {
        ...commonStyle,
        backgroundColor: '#3366FF29',
        borderRadius: '0',
      };
    }
    if (day.toLocaleDateString() === end.toLocaleDateString()) {
      style = {
        ...commonStyle,
        fontWeight: '600',
        color: '#fff',
        backgroundColor: color.primary,
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
      };
    }
    return(
      <PickersDay 
        {...pickersDayProps} 
        sx={style}
      />
    );
  };

  return (
    <Box>
      <TextField
        label={t('settle.startDate')}
        value={dayjs(startDate ||defaultStart).format("YYYY-MM-DD")}
        inputProps={{ readOnly: true }}
        InputProps={{ endAdornment: <Box sx={{ display: 'flex' }}><CalendarIcon /></Box> }}
        sx={isMobile ? { width: '100%', '& input': { py: '8.5px' } } : { mr: 1, width: '160px' }}
        onClick={() => handleClickTextField(CalendarTypeEnum.Start)}
      />
      <TextField
        label={t('settle.endDate')}
        value={dayjs(endDate || defaultEnd).format("YYYY-MM-DD")}
        inputProps={{ readOnly: true }}
        InputProps={{ endAdornment: <Box sx={{ display: 'flex' }}><CalendarIcon /></Box> }}
        sx={isMobile ? { mt: 1, width: '100%', '& input': { py: '8.5px' } } : { width: '160px' }}
        onClick={() => handleClickTextField(CalendarTypeEnum.End)}
      />

      {CalendarType && (
        <>
          <Box 
            display="flex" 
            zIndex="2"
            position="absolute"
            bgcolor="white"
            borderRadius="6px"
            boxShadow="rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px, rgb(145 158 171 / 12%) 0px 3px 14px 2px;"
          >
            <CalendarPicker
              date={CalendarType === CalendarTypeEnum.Start ? 
                new Date((selected.start|| startDate || defaultStart).toString())
                : new Date((selected.end || endDate || defaultEnd).toString())
              }
              onChange={(val) => {
                handleClickPicker(dayjs(val).format('YYYY-MM-DD'), CalendarType);
              }}
              renderDay={renderPickerDay}
              components={{
                LeftArrowIcon: LeftChevron,
                RightArrowIcon: RightChevron
              }}
            />
          </Box>
          <Box
            zIndex="1"
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            onClick={() => { setCalendarType(null); }}
          />
        </>
      )}
    
    </Box>
  );
};
