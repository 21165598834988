import { Document_Status } from 'generated/graphql';
import { LANG_CODE } from '../constants';

export type TSetterKeys = `set${Capitalize<string & keyof IDocumentState>}`;

export interface FileConfig {
  filePath?: string | null;
  fileName?: string | null;
  fileComponents?: Array<{ name?: string; size?: number }>;
  fileUpdates?: Array<{ name?: string; date?: Date }>;
  type?: string;
}

export type CropInfo = {
  imageOriginX?: number;
  imageOriginY?: number;
  imageWidth?: number;
  imageHeight?: number;
};

export type ImageType = {
  imageBlob?: Blob | null;
  croppedImageDataUrl?: string;
  path?: string | null;
  origin?: string | null;
  name?: string | null;
  cropInfo?: CropInfo | null;
  isComplete?: boolean;
};

export interface MemoType {
  content?: string | null | undefined;
  created?: any;
  authorId?: number | null | undefined;
  authorName?: string | null | undefined;
}

export type CopyrightType = {
  isOriginal: boolean;
  commercialSources?: string | null;
  warehouseSources?: string | null;
  hasRealLogo?: string | null;
  isErrorWarehouseCopyright?: boolean;
  isErrorFreeCopyright?: boolean;
  isErrorAgree?: boolean;
};

export type FileStatus = 'inprogress' | 'success' | 'fail' | null;

export type DisplayType = {
  isEdit: boolean;
  isDisp: boolean;
};

export type Book = {
  isbn: string;
};
export enum LanguageEnum {
  En = 'en',
  Ja = 'jp',
  Ko = 'ko',
  Zh = 'cn',
}

export type TDocumentTag = { id: number; name?: string };
export type TDocumentCategory = { code: string; children?: TDocumentCategory[] };
export type TDocumentIndustries = { id: number; name: string };
export enum EOpenrunOptions {
  APPLICATION = 'application',
  NON_APPLICATION = 'non-application',
}
export type TChangeHistory = {
  message?: string;
  status?: Document_Status;
};
export type IDocumentState = {
  id?: string | null;
  assetId?: number | null;
  brand?: number | null;
  language?: LANG_CODE;
  originalLanguage?: LANG_CODE;
  status?: Document_Status;
  title?: string | null;
  contentHead?: string | null;
  contentBody?: string | null;
  file?: FileConfig;
  fileComponents?: Array<{ name: string; size: number }>;
  extensions?: Array<number>;
  applications?: Array<number>;
  mainImage?: ImageType;
  subImage?: ImageType;
  categories?: TDocumentCategory[];
  price?: number | null;
  openrun?: EOpenrunOptions;
  tags?: TDocumentTag[];
  industries?: TDocumentIndustries[];
  copyright?: CopyrightType;
  memo?: MemoType[];
  created?: Date;
  requested?: Date;
  completed?: Date;
  version?: number;
  isEdit?: boolean;
  isDisp?: boolean;
  updateRequest?: string;
  priceChanged?: Date;
  isAdultOnly?: boolean;
  message?: string;
  changeHistories?: TChangeHistory[];
  licenseOptions?: Array<{ licenseTypeId: number; price: number }>;
  licenseScales?: Array<{ licenseTypeId: number; scale: number }>;
  licenseAgree?: boolean;
  book?: null | Book;
};

export interface IDocumentSetState {
  setId: (id: string | null) => void;
  setAssetId: (assetId: number | null) => void;
  setBrand: (brand: number | null) => void;
  setLanguage: (language: LANG_CODE) => void;
  setOriginalLanguage: (originalLanguage: LANG_CODE) => void;
  setStatus: (status: Document_Status) => void;
  setTitle: (title: string | null) => void;
  setContentHead: (contentHead: string | null) => void;
  setContentBody: (contentBody: string | null) => void;
  setFile: (file: FileConfig) => void;
  setFileComponents: (fileComponents: Array<{ name: string; size: number }>) => void;
  setExtensions: (extensions: Array<number>) => void;
  setApplications: (applications: Array<number>) => void;
  setMainImage: (mainImage: ImageType) => void;
  setSubImage: (subImage: ImageType) => void;
  setCategories: (categories: TDocumentCategory[]) => void;
  setPrice: (price: number | null) => void;
  setOpenrun: (openrun: EOpenrunOptions) => void;
  setTags: (tags: TDocumentTag[]) => void;
  setIndustries: (industries: TDocumentIndustries[]) => void;
  setCopyright: (copyright: CopyrightType) => void;
  setMemo: (memo: MemoType[]) => void;
  setCreated: (created: Date) => void;
  setRequested: (requested: Date) => void;
  setCompleted: (completed: Date) => void;
  setVersion: (version: number) => void;
  setIsEdit: (isEdit: boolean) => void;
  setIsDisp: (isDisp: boolean) => void;
  setUpdateRequest: (updateRequest: string) => void;
  setPriceChanged: (priceChanged: Date) => void;
  setIsAdultOnly: (isAdultOnly: boolean) => void;
  setMessage: (message: string) => void;
  setBook: (book?: Book) => void;
  setChangeHistories: (changeHistories: TChangeHistory[]) => void;
  setLicenseOptions: (licenseOptions: Array<{ licenseTypeId: number; price: number }>) => void;
  setLicenseScales: (licenseScales: Array<{ licenseTypeId: number; scale: number }>) => void;
  setLicenseAgree: (licenseAgree: boolean) => void;
  setDocumentData: (documentData: IDocumentState) => void;
  resetDocumentData: () => void;
}
