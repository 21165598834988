import React, { useContext } from 'react';
import { Box, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { Avatar, Button, ChevronDownIcon, ChevronUpIcon, Menu, MenuItem, PartnerhubLogo, Tab, useDesignSystemTheme, NewIcon } from 'carpenstreet-designsystem';
import { TABS } from '../Header.constants';
import { PATH_USER } from '../../routes/paths';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DesktopGNBProps } from './DesktopGNB.types';
import mixpanel from '../../lib/mixpanel/mixpanel';
import { EVENT_NAME } from '../../lib/mixpanel/mixpanel.constants';
import { AppContext } from 'app';

export default function DesktopGNB(props: DesktopGNBProps) {
  const { showUserMenu, onToggleUserMenu, avatarRef, profileImg, brandName, onLogoutClick } = props;
  const { userInfo } = useContext(AppContext);

  const theme = useDesignSystemTheme();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const brandNameToRender = language === 'ko' ? `${brandName} 파트너님` : `Partner ${brandName}`;

  const isVisibleGnb = !userInfo?.hasntPlan || !userInfo?.isAccept;

  function makeClickGNBLogger(value: string) {
    return () => {
      mixpanel.event(EVENT_NAME.CLICK_GNB, { menu: value });
    };
  }

  function handleMypageClick() {
    makeClickGNBLogger('My Page');
    onToggleUserMenu();
  }

  function handleLogoutClick() {
    mixpanel.event(EVENT_NAME.SIGN_OUT);
    onLogoutClick();
  }

  function handleProductUploadButtonClick() {
    makeClickGNBLogger('Product Upload');
    window.location.href = `/goods/write/${language}`;
  }

  return (
    <Box
      component={'nav'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '64px',
        padding: '0 40px',
        backgroundColor: theme.palette['color/white'],
        borderBottom: `1px solid ${theme.palette['color/gray/dim/200']}`,
        zIndex: 1000,
        [theme.breakpoints.down('largeTablet')]: {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link to={'/'}>
          <PartnerhubLogo width={58} height={28} />
        </Link>
        {isAuthenticated && isVisibleGnb && (
          <>
            <Tabs
              value={pathname}
              sx={{
                marginLeft: '32px',
              }}
            >
              {TABS.filter(({ label }) => (language === 'ko' ? true : label !== '공지사항')).map(({ label, href, eventValue }) => (
                <Tab
                  key={label}
                  size={'L'}
                  label={label === '공지사항' ? label : t(label)}
                  value={href}
                  component={Link}
                  sx={{ gap: '4px' }}
                  icon={eventValue === 'Settlement Records' ? <NewIcon /> : null}
                  iconPosition={'end'}
                  /*
                        // @ts-ignore */
                  to={href}
                  {...(label !== '공지사항' && {
                    onClick: makeClickGNBLogger(eventValue),
                  })}
                />
              ))}
            </Tabs>
            <Button
              variant={'contained'}
              size={'M'}
              color={'primary'}
              sx={{
                margin: '0 16px',
              }}
              onClick={handleProductUploadButtonClick}
            >
              {t('productUpload')}
            </Button>
          </>
        )}
      </Box>
      {isAuthenticated && (
        <>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              background: 'unset',
            }}
          >
            <Box
              onClick={onToggleUserMenu}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                cursor: 'pointer',
              }}
              ref={avatarRef}
            >
              <Avatar
                {...(profileImg &&
                  brandName && {
                    src: profileImg,
                    alt: brandNameToRender,
                  })}
              />
              {brandName && (
                <Button
                  variant={'text'}
                  size={'M'}
                  endIcon={showUserMenu ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  sx={{
                    '& p': { fontSize: '14px' },
                  }}
                >
                  {brandNameToRender}
                </Button>
              )}
            </Box>
          </Box>
          <Menu
            open={showUserMenu}
            onClose={onToggleUserMenu}
            anchorEl={avatarRef.current}
            paperSx={{ width: '97px', height: '64px', marginTop: '4px' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/*@ts-ignore*/}
            <MenuItem component={Link} to={PATH_USER.root} onClick={handleMypageClick}>
              {t('mypage')}
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}
