import React from "react";
import { Box } from "@mui/material";

interface Props {
  type: 'notice'|'event'|'guide'|'knowhow'|'normal'|'placeholder';
  children: React.ReactNode;
};

function labelStyle(type) {
  switch(type) {
    case 'notice':
      return {
        color: '#00B8D9',
        border: '1px solid #00B8D9'
      };
    case 'event':
        return {
          color: '#4e4eff',
          border: '1px solid #4e4eff'
        };
    case 'guide':
      return {
        color: '#212B36',
        border: '1px solid rgba(145, 158, 171, 0.32)'
      };
    case 'knowhow':
      return {
        color: '#FFAB00',
        border: '1px solid #FFAB00'
      };
    case 'normal':
      return {
        bgColor: '#666'
      };
    default:
      return {};
  };
};

export default function BoardLabel({
  type,
  children,
}: Props) {
  return (
    <Box
      padding="2px 8px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontWeight="700"
      fontSize="12px"
      borderRadius="6px"
      height="24px"
      textAlign="center"
      color="white"
      sx={labelStyle(type)}
    >
      {children}
    </Box>
  );
};
