import React, { useContext } from 'react';
import Row from '../row/'
import { AuthDataContext } from '../../authData'

export default (props) => {

    const { event, filter } = props
    const { authList, selectId } = useContext(AuthDataContext)
    // debugger
    // return useMemo(() => {
    // console.log(authList)
    // console.log('list')
    var result = []
    // var tempSelect = null
    console.log('listMemo')
    if (authList && authList.length > 0) {
        result = authList.map((data) => {
            var selected = false
            if (selectId && selectId === data._id) {
                selected = true
            }
            return <Row key={data._id} {...{ data, selected, event, filter }}></Row>
        })
    }

    return (<div className='list'>
        {result}
    </div>)
    // }, [authList, filter, selectId, loading, select])
}
