import React from 'react';
import { Button, Snackbar, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

export default function MypageSnackbar() {
  const {
    i18n: { language },
  } = useTranslation();

  const theme = useDesignSystemTheme();

  const [showSnackbar, setShowSnackbar] = useLocalStorage('show-mypage-snackbar', 'y');

  function handleCloseIconClick() {
    setShowSnackbar('n');
  }

  return (
    <>
      {/* Desktop Snackbar */}
      <Snackbar
        variant={'default'}
        message={'The commission fee notation has been changed '}
        open={language !== 'ko' && showSnackbar === 'y'}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        showCloseIcon={true}
        onCloseIconClick={handleCloseIconClick}
        action={
          <a
            href={'https://acon3d.notion.site/The-commission-fee-notation-has-been-changed-4f65cbf662e44b01912c3b326d72b73b?pvs=4'}
            target={'_blank'}
            onClick={handleCloseIconClick}
          >
            <Button variant={'text'} size={'S'} weight={'bold'} sx={{ '& span': { color: `${theme.palette['color/white']} !important` } }}>
              Learn more
            </Button>
          </a>
        }
        sx={{
          [theme.breakpoints.down('largeTablet')]: {
            display: 'none',
          },
          top: '103px',
          left: '40px',
        }}
      />
      {/* Mobile Snackbar */}
      <Snackbar
        variant={'default'}
        message={'The commission fee notation has been changed '}
        open={language !== 'ko' && showSnackbar === 'y'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        align={'vertical'}
        showCloseIcon={true}
        onCloseIconClick={handleCloseIconClick}
        action={
          <a
            href={'https://acon3d.notion.site/The-commission-fee-notation-has-been-changed-4f65cbf662e44b01912c3b326d72b73b?pvs=4'}
            target={'_blank'}
            onClick={handleCloseIconClick}
          >
            <Button variant={'text'} size={'S'} weight={'bold'} sx={{ '& span': { color: `${theme.palette['color/white']} !important` } }}>
              Learn more
            </Button>
          </a>
        }
        sx={{
          [theme.breakpoints.up('largeTablet')]: {
            display: 'none',
          },
          width: 'calc(100vw - 32px)',
        }}
      />
    </>
  );
}
