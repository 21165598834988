import React, { useContext, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isAgreeState, pageState, userStoreState } from './atom';
import { useTranslation } from 'react-i18next';
import { useRequestPartnerRevisionMutation } from 'generated/graphql';
import RequestDialog from './RequestDialog';
import useAuth from 'hooks/useAuth';

export default function Navigator() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { refresh } = useAuth();

  const state = useRecoilValue(userStoreState);
  const isAgree = useRecoilValue(isAgreeState);

  const [requestPartnerRevision] = useRequestPartnerRevisionMutation();

  const handleConfirm = async () => {
    // 사용자 업데이트
    await requestPartnerRevision({
      variables: {
        user: {
          ...(state.name && { name: state.name }),
          ...(state.companyName && { companyName: state.companyName }),
          brand: {
            ...(state.brandNames.ko && { koName: state.brandNames.ko }),
            ...(state.brandNames.en && { enName: state.brandNames.en }),
            ...(state.brandNames.zh && { cnName: state.brandNames.zh }),
            ...(state.brandNames.ja && { jpName: state.brandNames.ja }),
          },
          ...(state.contact && { contact: state.contact }),
          reference: state.documents.map((x) => {
            return {
              url: x,
            };
          }),
          ...(state.country && { country: state.country }),
          ...(state.nationalIdNumber && {
            nationalIdNumber: state.nationalIdNumber,
          }),
        },
      },
    });

    await refresh();
    setIsOpen(false);
  };

  return (
    <>
      <Stack direction="row" spacing={1.5} justifyContent={'center'}>
        <Button size="large" variant={'contained'} sx={{ width: '100%' }} onClick={() => setIsOpen(true)} disabled={!isAgree}>
          {t('confirmButtonText')}
        </Button>
      </Stack>
      <RequestDialog isOpen={isOpen} onClose={() => setIsOpen(false)} onConfirm={handleConfirm} />
    </>
  );
}
