import React from 'react';
import { Icon } from 'rsuite';
import { Flex, Wrap, Inner, Button } from './style';

export default (({ onClick }) => {
    return (<>
        <Flex id="goods-write-progress">
            <Wrap>
                <Inner id="goods-write-progress-value" />
            </Wrap>
            <Button onClick={onClick}>
                <Icon icon="close" />
            </Button>
        </Flex>
    </>);
});