import styled from 'styled-components';
import { Input } from '../../style';
export const File = styled.input.attrs(props => ({
    type: 'file'
}))`
    display: none !important;
`;

export const Progress = styled.div`
    width: 100%;
    border-radius: 3px;
`;

export const ModelInput = styled(Input)`
    color: #7C7C7C;
`;
