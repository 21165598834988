import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PartnerTypeEnum } from 'signup/type';
import { onlyNumberStr } from 'utils/validation';
import { nationalIdNumberState, rrnBackState, rrnFrontState, userTypeState } from './atom';
import { useTranslation } from 'react-i18next';

export default function NationalNumberIdField() {
  const { i18n } = useTranslation();
  const rrnFrontRef = useRef<HTMLInputElement>(null);
  const rrnBackRef = useRef<HTMLInputElement>(null);

  const userType = useRecoilValue(userTypeState);

  const [nationalIdNumber, setNationalIdNumber] = useRecoilState(nationalIdNumberState);
  const [rrnFront, setRRNFront] = useRecoilState(rrnFrontState);
  const [rrnBack, setRRNBack] = useRecoilState(rrnBackState);

  const handleChangeNationalIdNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9-]/g, '');

    setNationalIdNumber(value);
  };

  const handleRRNFrontNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onlyNumberStr(e.target.value);

    setRRNFront(value);

    if (value.toString().length === 6) {
      rrnBackRef.current.focus();
    }
  };

  const handleRRNBackNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onlyNumberStr(e.target.value);

    setRRNBack(value);
  };

  if (i18n.language !== 'ko') {
    return (
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {'Taxpayer Identification Numbers (TIN) '}
          <Typography component="span" fontSize="14px" fontWeight={400} color="#7c7c7c">
            {'(Optional)'}
          </Typography>
        </Typography>
        <Stack direction="row" spacing={1.5} alignItems={'center'}>
          <TextField
            value={nationalIdNumber}
            placeholder="Taxpayer Identification Numbers (TIN), Identification Number, Passport Number, etc."
            onChange={handleChangeNationalIdNumber}
            size="small"
            fullWidth
            sx={{ maxWidth: '240px' }}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{userType === PartnerTypeEnum.Personal ? '주민등록번호' : '사업자등록번호'}</Typography>
      <Stack direction="row" spacing={1.5} alignItems={'center'}>
        {userType === PartnerTypeEnum.Personal && (
          <>
            <TextField
              value={rrnFront ? rrnFront : ''}
              size="small"
              inputProps={{ maxLength: 6 }}
              inputRef={rrnFrontRef}
              onChange={handleRRNFrontNumber}
              fullWidth
              sx={{ maxWidth: '240px' }}
            />
            <Box
              sx={{
                backgroundColor: 'rgba(145, 158, 171, 0.32)',
                width: '12px',
                height: '2px',
              }}
            />
            <TextField
              value={rrnBack ? rrnBack : ''}
              size="small"
              inputProps={{ maxLength: 7 }}
              type="password"
              inputRef={rrnBackRef}
              onChange={handleRRNBackNumber}
              fullWidth
              sx={{ maxWidth: '240px' }}
            />
          </>
        )}
        {userType === PartnerTypeEnum.Business && <TextField value={nationalIdNumber} size="small" onChange={handleChangeNationalIdNumber} fullWidth sx={{ maxWidth: '240px' }} />}
      </Stack>
    </Stack>
  );
}
