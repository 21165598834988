import { Quill } from '../constants';
import React, { useEffect, useState, useRef } from 'react';
import { 
    Editor,
    EditorContents, 
    EditorToolBar,
} from './style';

export default ((props) => {
    const [quill, setQuill] = useState(null);
    // 콘텐츠 영역
    const contentsRef = useRef(null);

    const preventDefault = (e) => e?.dataTransfer.files.length && e.preventDefault();

    const onPaste = (e) => {
        const clipboardData = ((e.originalEvent || e).clipboardData || window.clipboardData);
        const clipboardText = (clipboardData?.getData('text/html') || clipboardData?.getData('text/plain'))?.trim();

        if (!clipboardText) return false;

        const tempDom = document.createElement('div');
        tempDom.innerHTML = clipboardText;
    };

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange(quill.root.innerHTML);
        }

        // 만약 지정된 라인보다 초과될경우 한글자씩 제거
        const loopText = () => {
            const contentsHeight = contentsRef.current.querySelector('.ql-editor').offsetHeight;
            const contetnsSelection = quill.getSelection()?.index || 1;
            const maxTextLength = 44 * props.line;

            if(maxTextLength < quill.getLength()) {
                quill.deleteText(maxTextLength - 1, quill.getLength());
            } else
            if(contentsHeight > (props.line * 34)) {
                let isChangeText = false;
                const replaceContents = quill.getContents().ops.reverse().map(x => {
                    const contentsText = x.insert;
                    if(contentsText !== '\n' && ! isChangeText) {
                        x.insert = contentsText.substring(0, contentsText.length - 2);
                        isChangeText = true;
                    }
    
                    return x;
                });
        
                quill.setContents(replaceContents.reverse());
                quill.setSelection(contetnsSelection);
            }
        };

        setTimeout(() => {
            loopText();
        }, 5);
    };

    // Editor에 focus 할경우
    const onFocusEditor = (e) => {
        const targetQlEditor = e.target.closest('.Editor');
        const targetEditorName = targetQlEditor?.dataset.name;

        props.setTargetEditorName(targetEditorName);
    }

    // componentDidMount
    useEffect(() => {
        const wrap = document.getElementById(`Editor-content-${props.name}`);
        setQuill(new Quill(wrap, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: '#Editor-toolbar',
                    handlers: {
                        header(val) {
                            const { index } = this.quill.getSelection(true);
                            const { baseNode, baseOffset } = window.getSelection();
                            const { length } = baseNode;
                            const startOffset = index - baseOffset;
                            
                            this.quill.format('header', val);
                            this.quill.formatText(startOffset, length, 'bold', true);
                        }
                    }
                },
                keyboard: {
                    bindings: {
                        'list autofill': {
                            shiftKey: true
                        },
                        tab: false,
                    },
                }
            },
            placeholder: props.placeholder
        }));

        contentsRef.current.querySelector('.ql-editor').addEventListener('focus', onFocusEditor);
    }, []);

    // quill initialization
    useEffect(() => {
        if (!quill) return;
        const vdom = document.createElement('div');
        vdom.innerHTML = props.contents;

        const headers = [...vdom.querySelectorAll('span[style]')].filter(span => span.style.fontSize === '18px');
        headers.forEach(header => {
            const h4 = document.createElement('h4');
            h4.innerHTML = header.innerHTML;
            header.parentElement.replaceChild(h4, header);
        });

        const delta = quill.clipboard.convert(vdom.innerHTML);
        quill.setContents(delta);

        quill.history.clear();

        // clear text formatting on paste
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            delta.ops = delta.ops.map((op) => {
                // 줄바꿈 제거
                if (!(typeof op.insert === 'undefined')) {
                    op.insert = op.insert.replace(/(\r\n|\n|\r)/gm, ' ');
                }

                op.attributes = { color: '#545454' };
                return op;
            });

            return delta;
        });
        // --clear text formatting on paste

        // 이벤트 설정
        quill.on('text-change', onChange);
        quill.root.addEventListener('paste', onPaste);
        onChange();

        // 처음 에디터 로드 시 초기값으로 설정된 에디터 이름이 있을경우 해당 에디터에 포커스합니다.
        if(props.name === props.targetEditorName) {
            quill.focus();
        }
    }, [quill]);

    useEffect(() => {
        if(quill) {
            if(props.targetEditorName === props.name) {
                quill.format('color', props.textColor);
            }
        }
    }, [props.textColor]);

    return (
        <>
            <Editor
                className={`Editor ${props.isDisp ? 'disabled' : ''}`} 
                onDragOver={preventDefault} 
                onDragEnter={preventDefault}
                line={props.line}
                ref={contentsRef}
                data-name={props.name}
                style={props.style}
                tabindex="0"
            >
                <EditorContents 
                    id={`Editor-content-${props.name}`} 
                    fontSize={props.fontSize} 
                    style={{backgroundColor: props.backgroundColor}}
                    bold={props.bold}
                />
                <EditorToolBar className='Editor-toolbar' id="Editor-toolbar">
                    <div className="ql-formats" style={{width: '55%'}}></div>
                </EditorToolBar>
            </Editor>
        </>
    );
});