import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePickerInput, KeyboardTimePickerInput } from '../components/input/Pickers';
import './DateSetting.scss'

export default React.memo(function DateSetting({
    startDate,
    setStartDate,
    endDate,
    setEndDate
}) {
    const { t } = useTranslation();

    const onChangeTime = (time, setDate) => {
        if (time && !isNaN(time.getHours()) && !isNaN(time.getMinutes())) {
            setDate((date) => {
                const prevDate = date || new Date();
                prevDate.setHours(time.getHours(), time.getMinutes());
                return new Date(prevDate);
            });
        }
    };

    return (
        <div className="datepickerInput">
            <div className="datepickerContainer">
                <label>{t('Banner.writer.startDate')}</label>
                <KeyboardDatePickerInput
                    className="pickers date"
                    onChange={setStartDate}
                    value={startDate}
                    variant="inline"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    disablePast={false}
                    emptyLabel={t('Popup.startDatePlaceholder')}
                    style={{ marginRight: '10px' }} />
                <KeyboardTimePickerInput
                    className="pickers time"
                    onChange={(time) => { onChangeTime(time, setStartDate) }}
                    value={startDate}
                    variant="inline"
                    inputVariant="outlined"
                    format="HH:mm"
                    disablePast={false}
                    emptyLabel="00:00" />
            </div>
            <div className="datepickerContainer">
                <label>{t('Banner.writer.endDate')}</label>
                <KeyboardDatePickerInput
                    className="pickers date"
                    onChange={setEndDate}
                    value={endDate}
                    variant="inline"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    disablePast={true}
                    emptyLabel={t('Popup.endDatePlaceholder')}
                    style={{ marginRight: '10px' }} />
                <KeyboardTimePickerInput
                    className="pickers time"
                    onChange={(time) => { onChangeTime(time, setEndDate) }}
                    value={endDate}
                    variant="inline"
                    inputVariant="outlined"
                    format="HH:mm"
                    disablePast={true}
                    emptyLabel="00:00" />
            </div>
        </div>
    )
});