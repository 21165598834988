import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BlackButton } from '../../components/button';
import styled from 'styled-components';
import './index.scss';
import { Input } from 'rsuite';
import { AppContext } from '../../app';

// 확인 버튼 
const ConfirmButton = styled(BlackButton)`
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 100%;
    height: auto;
`;

export default (({ setSentence, isShow, onHide }) => {
    // 변경전 
    const [beforeSentence, setBeforeSentence] = useState('');
    // 변경후
    const [afterSentence, setAfterSentence] = useState('');
    // 메세지 표시하기 
    const { showAlertMessage } = useContext(AppContext);

    // 확인 버튼 클릭 이벤트 처리기 메소드 
    const onClickConfirmButtonTag = async () => {
        // 문장 일괄 변경 
        await setSentence({
            variables: {
                beforeSentence,
                afterSentence
            }
        });

        showAlertMessage(
            '변경이 완료되었습니다.',
            '본문 내용들이 일괄 변경되었습니다.',
            { callback: onHide }
        );
    };
    return (
        <Modal className="sentenceModal" show={isShow} onHide={onHide} >
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <div className="sentenceModal__title">변경전</div>
                <div className="sentenceModal__contents">
                    <Input value={beforeSentence} onChange={(value) => { setBeforeSentence(value); }} />
                </div>
                <div className="sentenceModal__title">변경후</div>
                <div className="sentenceModal__contents">
                    <Input value={afterSentence} onChange={(value) => { setAfterSentence(value); }} />
                </div>
                <ConfirmButton onClick={onClickConfirmButtonTag}>변경하기</ConfirmButton>
            </Modal.Body>
        </Modal>
    );
});