import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Background } from './styled';
import Modal from './Modal';
import { useQuery } from '@apollo/client';
import { GET_POPUPS_HERE } from 'api/quries';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function ModalGroup() {
  const [cookie, setCookie] = useCookies();
  const { i18n } = useTranslation('common');
  const history = useHistory();

  // Modal 데이터 호출
  const { data } = useQuery(GET_POPUPS_HERE, {
    variables: {
      url: `/${i18n.language}${history.location.pathname === '/' ? '' : history.location.pathname}`,
      origin: 'https://hub.acon3d.com',
    },
  });
  const [modals, setModals] = useState();

  const getCookieIds = () => {
    const idStr = cookie['ALLOW_HIDE'];
    const cookieIds = idStr ? idStr.split('_').map((x) => Number(x)) : [];
    return cookieIds;
  };

  const setCookieIds = (id) => {
    const cookieIds = getCookieIds();
    cookieIds.push(id);

    const todayDate = new Date();
    todayDate.setFullYear(2099);

    const cookieStr = cookieIds.join('_');
    setCookie('ALLOW_HIDE', cookieStr, { path: '/', expires: todayDate });
  };

  useEffect(() => {
    if (!data) return;

    const idStr = cookie['ALLOW_HIDE'];
    const cookieIds = idStr ? idStr.split('_').map((x) => Number(x)) : [];

    const displayPopups = data.getPopupsHere.filter((x) => !cookieIds.includes(x.id));
    // 팝업이 존재하지 않을경우, 종료
    if (displayPopups.length === 0) return;
    setModals(displayPopups);
  }, [data]);

  useEffect(() => {
    if (!modals) return;

    if (modals.length === 0) {
      // 모달창이 표시되지 않은 상태일 경우, body 태그에 적용된 스타일 제거
      document.body.removeAttribute('style');
    } else {
      // body 태그에 hidden을 줌으로 써 모달에 대한 스크롤만 적용되도록 함
      document.body.style.overflowY = 'hidden';
    }
  }, [modals]);

  if (!modals || (modals && modals.length === 0)) return <></>;

  const getAllowHideAction = (modalTypeId) => {
    return () => {
      getHideAction(modalTypeId)();
      setCookieIds(modalTypeId);
    };
  };

  const getHideAction = (modalTypeId) => {
    return () => {
      setModals((prev) => prev.filter((x) => x.id !== modalTypeId));
    };
  };


  return (
    <Background>
      {modals.map((x, i) => (
        <Modal
          hide={i !== 0}
          key={`modal_${i}`}
          onClickAllowHide={getAllowHideAction(x.id)}
          onClickHide={getHideAction(x.id)}
          modalType={x}
        />
      ))}
    </Background>
  );
}
