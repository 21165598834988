import styled from 'styled-components';

export const Wrap = styled.div`
    width:100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top:5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`;
export const ItemWrap = styled.div`
    width: 48%;
    padding: 10px 0;
`;
export const SkeletonTitle = styled.div`
    width: 200px;
    height: 15px;
    background-color: #d8d8d8;
    border-radius: 3px;
`;
export const SkeletonContents = styled.div`
    margin-top: 10px;
    width: 400px;
    height: 15px;
    border-radius: 3px;
    background-color: #d8d8d8;
`;

export const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 8px;
    color: #212B36;

    & a {
        padding: 4px 10px;
        color: #212B36;
        font-weight: 700;
        font-size: 13px;
        font-weight: 22px;
    }
`
export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`