import { AppContext } from 'app';
import QueryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useSearch(startDate, endDate, dateSearchType) {
  const history = useHistory();
  // 배경색 변경하기 메소드, 사용자 정보
  const { setBackgroundColor, userInfo } = useContext(AppContext);

  // 브랜드 ID
  const brandId = userInfo && userInfo.brandId ? userInfo.brandId : 0;
  // 전체 읽기 권한 여부
  const isReadAll = userInfo.isAdmin;

  // url 매개변수 객체
  let urlParamObj = (() => {
    let obj: {
      brandId: string;
      page: number;
      limit: number;
      goodsNm: string | string[];
      penNm: string | string[];
      startDate: string | string[];
      endDate: string | string[];
      paymentStartDate: string | string[];
      paymentEndDate: string | string[];
      settleStartDate: string | string[];
      settleEndDate: string | string[];
      settle: string;
    } = {
      brandId: brandId.toString(),
      // 상품명
      goodsNm: '',
      // 필명
      penNm: '',
      startDate: dateSearchType === 'order' ? startDate : '',
      endDate: dateSearchType === 'order' ? endDate : '',
      paymentStartDate: dateSearchType === 'payment' ? startDate : '',
      paymentEndDate: dateSearchType === 'payment' ? endDate : '',
      settleStartDate: dateSearchType === 'settle' ? startDate : '',
      settleEndDate: dateSearchType === 'settle' ? endDate : '',
      settle: 'general',
      // 페이지
      page: 1,
      // 개수제한
      limit: sessionStorage.getItem('settleview') ? Number(sessionStorage.getItem('settleview')) : 20,
    };
    // search 값이 존재할 경우
    if (history?.location?.search) {
      // url 파라메터 객체
      const urlParamObj = QueryString.parse(history.location.search);

      obj.brandId = urlParamObj.brandId ? urlParamObj.brandId : brandId.toString();
      obj.page = Number(urlParamObj.page) ? Number(urlParamObj.page) : obj.page;
      obj.limit = Number(urlParamObj.limit) ? Number(urlParamObj.limit) : obj.limit;
      obj.goodsNm = urlParamObj.goodsNm ? urlParamObj.goodsNm : obj.goodsNm;
      obj.penNm = urlParamObj.penNm ? urlParamObj.penNm : obj.penNm;
      obj.settle = String(urlParamObj.settle ? urlParamObj.settle : obj.settle);

      switch (dateSearchType) {
        case 'settle':
          obj.settleStartDate = urlParamObj.settleStartDate ? urlParamObj.settleStartDate : obj.settleStartDate;
          obj.settleEndDate = urlParamObj.settleEndDate ? urlParamObj.settleEndDate : obj.settleEndDate;
          break;
        case 'payment':
          obj.paymentStartDate = urlParamObj.paymentStartDate ? urlParamObj.paymentStartDate : obj.paymentStartDate;
          obj.paymentEndDate = urlParamObj.paymentEndDate ? urlParamObj.paymentEndDate : obj.paymentEndDate;
          break;
        case 'order':
          obj.startDate = urlParamObj.startDate ? urlParamObj.startDate : obj.startDate;
          obj.endDate = urlParamObj.endDate ? urlParamObj.endDate : obj.endDate;
          break;
      }
    }
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined) delete obj[key];
    }
    // 종료함
    return obj;
  })();

  // 색상변경
  useEffect(() => {
    // 배경 흰색으로 설정
    setBackgroundColor('white');

    return () => {
      setBackgroundColor('');
    };
  }, [setBackgroundColor]);

  return { urlParamObj, isReadAll };
}
