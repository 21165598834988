import React from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import './Message.scss'

// 메세지
export default React.memo(
    function Message({ show, close, title = '', message }) {
        const { t } = useTranslation();

        return (
            <Modal className="message" show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {parse(title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {parse(message)}
                </Modal.Body>
                <Modal.Footer>
                    <button className="closeBtn" onClick={close}>
                        {t('close')}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    })