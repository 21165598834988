import styled from 'styled-components';

const Button = styled.button`
    font-size: 14px;
    margin: 1px 2.5px;
    border-radius: 3px;
    min-width: 65px;
    height: 38px;
    color: white;
    &[disabled] {
        opacity: 0.7;
    }
`;

const UnderButton = styled(Button)`
    background-color: inherit;
    font-size: 12px;
    color: #333;
    text-decoration: underline; 
    font-weight: 400;
    line-height: 100%;
    height: auto;
`;
const BlackButton = styled(Button)`
    background: #333;
`;
const GrayButton = styled(Button)`
    background: #666;
`;

const WhiteButton = styled(Button)`
    background: white;
    ${(props: { height?: string|number }) => props.height ? `height: ${props.height}px;` : ``}
    border: 1px solid #7c7c7c;
    font-weight: 500;
    color: #333;
    font-size: 12px;
`;

export default Button;
export {
    BlackButton,
    WhiteButton,
    GrayButton,
    UnderButton
}