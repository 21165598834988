import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormInput } from "signup/style";
import { TextFieldProps } from "@mui/material";

type IProps = {
  name: string;
  maxLength?: number;
};

type Props = IProps & TextFieldProps;
export default function OldRHFTextField({
  name,
  maxLength,
  onChange,
  type,
  placeholder,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormInput
          {...field}
          fullWidth
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          isValid={!error}
          helperText={error?.message}
          {...(onChange && {
            onChange,
          })}
          {...other}
        />
      )}
    />
  );
}
