import { Box, MenuItem, Select, Switch, Typography, alpha } from '@mui/material';
import { CircleCheckbox, Container } from 'acon-mui/components';
import { useDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { numberWithCommas } from 'lib/formatNumber';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import palette from 'theme/palette';
import { scaleList } from './Enterprise.constants';
import { FormControl } from '@mui/material';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useGetLicenseTypesQuery } from 'generated/graphql';

export default function Enterprise({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation();
  const { lang } = useParams<{ lang?: string }>();
  const { isDisp, price, licenseScales, setLicenseScales, licenseAgree, setLicenseAgree } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    price: state.price,
    licenseScales: state.licenseScales,
    setLicenseScales: state.setLicenseScales,
    licenseAgree: state.licenseAgree,
    setLicenseAgree: state.setLicenseAgree,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isEnterprise, setIsEnterprise] = React.useState(false);

  const { data } = useGetLicenseTypesQuery();
  const singleLicense = data?.licenseTypes.find((x) => x.usableCount === 1);
  const fiveLicense = data?.licenseTypes.find((x) => x.usableCount === 5);
  const permanentLicense = data?.licenseTypes.find((x) => x.usableCount === 0);

  const handleClickToggle = (e, val) => {
    if (e.target.checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
    if (data && data.licenseTypes) {
      if (e.target.checked) {
        setLicenseScales([
          { licenseTypeId: singleLicense?.id, scale: 1 },
          { licenseTypeId: fiveLicense?.id, scale: 4 },
          { licenseTypeId: permanentLicense?.id, scale: 10 },
        ]);
      } else {
        setLicenseScales([]);
        setLicenseAgree(false);
      }
    }
  };

  useEffect(() => {
    setIsEnterprise(licenseScales.length > 0);
  }, [licenseScales]);

  useEffect(() => {
    if (price === 0) {
      setLicenseScales([]);
      setLicenseAgree(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  const NonEnterpriseContent = () => (
    <>
      <Box mt={5} display="flex" flexDirection="column" alignItems="center">
        <Box component="img" alt="enterprise" src="/assets/detail-board/img_enterprise.svg" />
        <Typography mt={1.5} textAlign="center">
          {parse(t('document.price.enterprisePlaceholder'))}
        </Typography>
      </Box>
    </>
  );

  const EnterpriseContent = () => {
    const currency = lang === 'ko' ? '원' : '$';
    // const singleLicensePrice = price
    //   ? `${numberWithCommas((price * (licenseScales.find((x) => x.licenseTypeId === singleLicense?.id)?.scale || 1)) / (lang === 'ko' ? 1 : 1000))}`
    //   : '- ';
    const fiveLicensePrice = price
      ? `${numberWithCommas((price * (licenseScales.find((x) => x.licenseTypeId === fiveLicense?.id)?.scale || 4)) / (lang === 'ko' ? 1 : 1000))}`
      : '- ';
    const permanentLicensePrice = price
      ? `${numberWithCommas((price * (licenseScales.find((x) => x.licenseTypeId === permanentLicense?.id)?.scale || 5)) / (lang === 'ko' ? 1 : 1000))}`
      : '- ';

    const scale = licenseScales.find((x) => x.licenseTypeId === permanentLicense?.id)?.scale || 5;

    const handleSelectScale = (e) => {
      setLicenseScales([
        { licenseTypeId: singleLicense.id, scale: 1 },
        { licenseTypeId: fiveLicense.id, scale: 4 },
        { licenseTypeId: permanentLicense.id, scale: e.target.value as number },
      ]);
    };

    return (
      <>
        <Box mt={2} mb="auto" display="flex" flexDirection="column" gap={2} fontSize="14px">
          <Box display="flex">
            <Typography flex={1}>{t('document.price.fiveLicenses')}</Typography>
            <Typography>
              {fiveLicensePrice}
              {currency}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography flex={1}>{t('document.price.permanentLicense')}</Typography>
            <FormControl
              size="small"
              sx={{
                width: '106px',
                '& label.Mui-focused': { display: 'none' },
              }}
            >
              <Select value={scale} disabled={isDisp || disabled} onChange={handleSelectScale}>
                <MenuItem value={''} disabled sx={{ display: 'none' }} />
                {scaleList?.map((x) => (
                  <MenuItem key={`enterprise_scale_${x}`} value={x}>
                    {x}
                    {lang === 'ko' ? '배' : 'x'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography>
              {permanentLicensePrice}
              {currency}
            </Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex">
          <CircleCheckbox checked={licenseAgree} disabled={disabled} onChange={(e) => setLicenseAgree(e.target.checked)} sx={{ ml: -1, height: 'fit-content' }} />
          <Typography mt={1} color={palette.dark.error.main} component="span">
            *&nbsp;
          </Typography>
          <Typography mt={1}>{t('document.price.enterpriseAgreement')}</Typography>
        </Box>
      </>
    );
  };

  return (
    <Container color={alpha('#000', 0.87)} paddingTop="10px">
      <Box display="flex" alignItems="end" pb={1} borderBottom="1px solid #919EAB3D">
        <Typography flex={1}>{t('document.price.enterprise')}</Typography>
        <Switch
          checked={isEnterprise}
          onChange={(e) => handleClickToggle(e, t('document.price.enterprise'))}
          disabled={disabled}
          sx={{
            '&:not(:.Mui-checked)': {
              backgroundColor: '#000',
            },
          }}
        />
      </Box>
      {isEnterprise ? <EnterpriseContent /> : <NonEnterpriseContent />}
    </Container>
  );
}
