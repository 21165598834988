import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface ISelectedFieldsState {
  selectedFields: string[];
  setSelectedFields: (selectedFields: string[]) => void;
  resetSelectedFields: () => void;
}

export const useSelectedFieldsStore = createWithEqualityFn<ISelectedFieldsState>(
  (set) => ({
    selectedFields: [],
    setSelectedFields: (selectedFields) => set(() => ({ selectedFields })),
    resetSelectedFields: () => set(() => ({ selectedFields: [] })),
  }),
  shallow,
);
