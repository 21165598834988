import React from 'react';
import { Box, Slide } from '@mui/material';
import {
  Avatar,
  Button,
  CategoryIcon,
  ChevronRightIcon,
  CloseIcon,
  Divider,
  IconButton,
  NewIcon,
  PartnerhubLogo,
  Typography,
  useDesignSystemTheme,
} from 'carpenstreet-designsystem';
import { Link } from 'react-router-dom';
import { PATH_USER } from '../../routes/paths';
import { TABS } from '../Header.constants';
import useAuth from '../../hooks/useAuth';
import { MobileGNBProps } from './MobileGNB.types';
import { useTranslation } from 'react-i18next';
import mixpanel from '../../lib/mixpanel/mixpanel';
import { EVENT_NAME } from '../../lib/mixpanel/mixpanel.constants';

export default function MobileGNB(props: MobileGNBProps) {
  const { showMobileMenu, onToggleMobileMenu, profileImg, brandName, onLogoutClick } = props;

  const theme = useDesignSystemTheme();

  const { isAuthenticated } = useAuth();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const brandNameToRender = language === 'ko' ? `${brandName} 파트너님` : `Partner ${brandName}`;

  function makeClickGNBLogger(value: string) {
    return () => {
      mixpanel.event(EVENT_NAME.CLICK_GNB, { menu: value });
    };
  }

  function handleProductUploadButtonClick() {
    makeClickGNBLogger('Product Upload')();
    onToggleMobileMenu();
    window.location.href = `/goods/write/${language}`;
  }

  function makeTabClickHandler(value: string) {
    return () => {
      makeClickGNBLogger(value)();
      onToggleMobileMenu();
    };
  }

  function handleLogoutClick() {
    mixpanel.event(EVENT_NAME.SIGN_OUT);
    onLogoutClick();
  }

  return (
    <>
      <Box
        component={'nav'}
        sx={{
          display: 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '56px',
          padding: '0 16px',
          backgroundColor: theme.palette['color/white'],
          borderBottom: `1px solid ${theme.palette['color/gray/dim/200']}`,
          zIndex: 1000,
          [theme.breakpoints.down('largeTablet')]: {
            display: 'flex',
          },
        }}
      >
        {isAuthenticated ? (
          <>
            <IconButton size={'M'} onClick={onToggleMobileMenu}>
              <CategoryIcon />
            </IconButton>
            <Link to={'/'}>
              <PartnerhubLogo width={58} height={28} />
            </Link>
            <Link to={PATH_USER.root} onClick={makeClickGNBLogger('My Page')}>
              <Avatar
                {...(profileImg &&
                  brandName && {
                    src: profileImg,
                    alt: brandNameToRender,
                  })}
              />
            </Link>
          </>
        ) : (
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Link to={'/'}>
              <PartnerhubLogo width={58} height={28} />
            </Link>
          </Box>
        )}
      </Box>
      <Slide direction={'right'} in={showMobileMenu} mountOnEnter unmountOnExit>
        <Box
          sx={{
            zIndex: 1500,
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette['color/white'],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component={'nav'}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '56px',
              padding: '8px 16px',
              backgroundColor: theme.palette['color/white'],
              borderBottom: `1px solid ${theme.palette['color/gray/dim/200']}`,
            }}
          >
            <Typography variant={'typography/body/medium/bold'}>{t('menu')}</Typography>
            <IconButton size={'M'} onClick={onToggleMobileMenu}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Link to={PATH_USER.root} onClick={makeClickGNBLogger('My Page')}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px',
              }}
              onClick={onToggleMobileMenu}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <Avatar
                  {...(profileImg &&
                    brandName && {
                      src: profileImg,
                      alt: brandNameToRender,
                    })}
                  sx={{
                    width: '48px',
                    height: '48px',
                  }}
                />
                <Typography variant={'typography/body/medium/bold'}>{brandNameToRender}</Typography>
              </Box>
              <ChevronRightIcon />
            </Box>
          </Link>
          <Box
            sx={{
              padding: '16px',
            }}
          >
            <Button variant={'contained'} color={'primary'} size={'L'} fullWidth onClick={handleProductUploadButtonClick}>
              {t('productUpload')}
            </Button>
          </Box>
          <Box sx={{ marginTop: '24px', padding: '16px 16px 40px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {TABS.filter(({ label }) => (language === 'ko' ? true : label !== '공지사항')).map(({ label, href, eventValue }) => (
              <Box
                key={label}
                component={Link}
                /*
                  // @ts-ignore */
                to={href}
                sx={{
                  padding: '8px 0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '4px',
                }}
                onClick={makeTabClickHandler(eventValue)}
              >
                <Typography variant={'typography/body/medium/regular'}>{t(label)}</Typography>
                {eventValue === 'Settlement Records' ? (
                  <Box mr="auto" display="flex">
                    <NewIcon />
                  </Box>
                ) : null}
                <ChevronRightIcon />
              </Box>
            ))}
            <Divider />
            <Box
              component={'button'}
              sx={{
                padding: '8px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'unset',
              }}
              onClick={handleLogoutClick}
            >
              <Typography variant={'typography/body/medium/regular'}>{t('logout')}</Typography>
            </Box>
          </Box>
        </Box>
      </Slide>
    </>
  );
}
