import { AppContext } from 'app';
import React, { useContext } from 'react';
import { Box, GlobalStyles, Stack } from '@mui/material';
import { Typography } from 'carpenstreet-designsystem';
import Benefits from './components/Benefits';
import { ReactComponent as IconChevron } from './components/chevron.svg';
import Partnerships from './components/Partnerships';
import { useCreatePartnerPlanLazyQuery } from 'generated/graphql';
import { AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default (props) => {
  const { t } = useTranslation();
  const { refresh } = useContext(AuthContext);
  const { showConfirmMessage } = useContext(AppContext);

  const [createPartnerPlan] = useCreatePartnerPlanLazyQuery({
    onCompleted: () => refresh(),
    onError: () => alert('오류'),
  });

  async function onSubmit(val) {
    await createPartnerPlan({
      variables: { type: 3, isExclusive: val },
    });
  }

  const handleOnOpenConfirmModal = (val) => {
    const modalTitle = '';
    const modalContent = `
    ${t('partnership.modal.select01')}
    <span style="font-weight: 700;">
      ${val ? t('partnership.modal.exclusive') : t('partnership.modal.nonExclusive')}
    </span>
    ${t('partnership.modal.select02')}`;

    const modalCallback = () => onSubmit(val);
    const modalOptions = {
      confirmText: t('partnership.modal.confirm'),
    };

    showConfirmMessage(modalTitle, modalContent, modalCallback, modalOptions);
  };

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            '& .wrap': { paddingLeft: 0, paddingRight: 0, paddingTop: 64 },
            '& .wrap > div': { maxWidth: '100% !important' },
          },
        }}
      />
      <Stack p="60px" direction="column" justifyContent="center" alignItems="center" textAlign="center" gap="12px">
        <Typography variant="typography/headline/h3/black" color="color/gray/800">
          {t('partnership.section01.title')}
        </Typography>
        <Typography variant="typography/body/small/regular" color="color/gray/800">
          {parse(t('partnership.section01.content'))}
        </Typography>
      </Stack>
      <Partnerships onClick={handleOnOpenConfirmModal} />
      <Benefits />
      <Box
        component="footer"
        p="48px 60px 140px 60px"
        bgcolor="#F5F5F6"
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="12px"
      >
        <Box
          component="a"
          href="https://partnerguide.acon3d.com/"
          target="_blank"
          rel="noopener noreferrer"
          p="12px"
          border="1px solid #BCBCBE80"
          borderRadius={1}
          width="fit-content"
          sx={{ '&:hover': { color: '#313135' } }}
        >
          <Typography variant="typography/body/medium/bold" color="color/gray/800">
            {t('partnership.section04.guideLink')}
          </Typography>
          <IconChevron />
        </Box>

        <Typography variant="typography/body/small/regular " color="color/gray/800">
          {t('partnership.section04.inquiry01')}
          <Typography variant="typography/body/small/bold" color="color/gray/800">
            {t('partnership.section04.email')}
          </Typography>
          {t('partnership.section04.inquiry02')}
        </Typography>
      </Box>
    </>
  );
};
