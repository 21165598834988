import { Card } from '@mui/material';
import { Tooltip } from 'rsuite';
import styled from 'styled-components';
import palette from 'theme/palette';

export const TwinkleDiscountListContainer = styled(Card)``;

export const ListTooltip = styled(Tooltip)`
  font-weight: bold;
  z-index: 1030;
  & .rs-tooltip-inner {
    background-color: ${palette.light.orange[0]};
  }
  & .rs-tooltip-arrow {
    border-bottom-color: ${palette.light.orange[0]} !important;
    border-width: 0 6px 7px !important;
  }
`;
