import { deleteCategory, moveCategory } from '../../ajax'
import { useContext } from 'react'
import { AppContext } from '../../../../app'

// 노드 정보 
function copyCategory(node) {
    var type = node.type
    if (type) {
        type = [...type]
    }
    var i18n = node.i18n
    if (i18n) {
        i18n = JSON.parse(JSON.stringify(i18n))
    }
    return {
        id: node.id,
        parent: node.parent,
        memo: node.memo,
        state: node.state,
        godoCateCd: node.godoCateCd,
        godoSno: node.godoSno,
        type: node.type,
        i18n
    }
}

function isChangeOriginCategory(origin, category) {
    var field = ["godoCateCd", "godoSno", "memo", "state", "i18n", "type"]
    var flag = false
    if (category.id) {
        console.log(origin, category)
        field.forEach(d => {
            if (d === 'i18n' || d === 'type') {
                if (JSON.stringify(origin[d]) !== JSON.stringify(category[d])) {
                    flag = true
                }
            } else if (origin[d] !== category[d]) {
                flag = true
            }
        })
    }
    return flag
}

export default ((t, resultData, categoryData, setCategoryData, setExpandedKeys, setTooltipObj, setIsLoading) => {

    const { showConfirmMessage } = useContext(AppContext)

    function categoryChange(selected, node, callback) {
        var tempCategory = {}
        var flag = true
        if (selected) {
            tempCategory = copyCategory(node)
        } else {
            tempCategory = {}
        }
        if (categoryData.id) {
            var origin
            if (resultData.keyMap && resultData.keyMap[categoryData.id]) {
                origin = copyCategory(resultData.keyMap[categoryData.id])
            }
            if (isChangeOriginCategory(origin, categoryData)) {
                if (!window.confirm(t('doYouChangeNode'))) {
                    
                    flag = false
                }
            }
        }
        if (flag) {
            setCategoryData(tempCategory)
            if (callback) {
                callback()
            }
        }
    }

    function onExpand(expandedKeys) {
        setExpandedKeys([...expandedKeys])
    }
    // 노드 선택 이벤트 처리기 메소드
    function onSelect(selectedKeys, { selected, selectedNodes, node, event, nativeEvent }) {
        categoryChange(selected, node)
    }

    // 노드 우측 클릭 이벤트 처리기 메소드 
    const onRightClick = ({ event, node }) => {
        categoryChange(true, copyCategory(node), () => {
            setTooltipObj({
                left: `${event.pageX}px`,
                top: `${event.pageY}px`,
                isShow: true
            })
        })
    }
    // 드래그 진입 이벤트 처리기 메소드 
    const onDragEnter = info => {
        // 해당 진입된 노드 확장 
        setExpandedKeys(info.expandedKeys);
    };
    // 드래그 드롭 이벤트 처리기 메소드 
    const onDrop = async ({ event, node, dragNode, dragNodesKeys, dropPosition }) => {

        const dropPos = node.props.pos.split('-');
        dropPosition = dropPosition - Number(dropPos[dropPos.length - 1]);
        var parent = ''
        var sortNo = 1
        if (dropPosition === 0) {
            parent = node.id
        } else {
            parent = node.parent
            sortNo = node.sortNo + dropPosition
            if (!sortNo) {
                sortNo = 1
            }
        }
        const confirmMoveCallback = async () => {
            var resultData = await moveCategory(dragNode.id, parent, sortNo)
            if (resultData.resultCode === 1) {
                setIsLoading(false)
            }
        }

        let confirmTitle;
        if (!parent) {
            confirmTitle = t('categoryManager.confirm.move.titleNotParent');
        } else {
            confirmTitle = t('categoryManager.confirm.move.titleHasParent', { category: dragNode.name, parent: resultData.keyMap[parent].name});
        }

        showConfirmMessage(
            confirmTitle,
            t("categoryManager.confirm.move.content"),
            confirmMoveCallback
        )
    }

    // 삭제 이벤트 처리기 메소드 
    const onDelete = async () => {
        setTooltipObj(prev => {
            return { ...prev, isShow: false }
        })
        const confirmDeleteCallback = async () => {
            var resultData = await deleteCategory(categoryData.id)
            // 카테고리 데이터 초기화 
            setCategoryData({});
            setIsLoading(false);
            console.log(resultData)
        }
        showConfirmMessage(
            t("categoryManager.confirm.delete.title"),
            t("categoryManager.confirm.delete.content"),
            confirmDeleteCallback
        )
    }

    // 카테고리 추가하기 메소드 
    const onAddCategory = (onAddRootCategory, selectCategoryType) => {
        return async () => {
            setTooltipObj(prev => {
                return { ...prev, isShow: false }
            });
            if (onAddRootCategory) {
                const confirmAddCallback = async () => {
                    await (onAddRootCategory(categoryData, setCategoryData, selectCategoryType))()
                    setExpandedKeys(prev => {
                        return [...prev, categoryData.id]
                    })
                }
                showConfirmMessage(
                    t("categoryManager.confirm.add.title"),
                    t("categoryManager.confirm.add.content"),
                    confirmAddCallback
                )
            }
        }
    };
    return {
        onExpand,
        onSelect,
        onRightClick,
        onDragEnter,
        onDrop,
        onDelete,
        onAddCategory
    }

})