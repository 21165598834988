import React, { createContext, useEffect, useState } from 'react'

export const CategoryDataContext = createContext()

export default (props) => {
	function arraySort(arr) {
		return arr.sort((a, b) => (a.sortNo > b.sortNo ? -1 : a.sortNo < b.sortNo ? 1 : 0))
	}

	// 로딩 상태
	const [isLoading, setIsLoading] = useState(false)

	const [categoryTypeList, setCategoryTypeList] = useState([])

	// 카테고리에 사용되는 언어 배열
	const [langList, setLangList] = useState([])

	// 수정상태인지 비교 하기위해서 컨텍스트로 잡음
	const [categoryData, setCategoryData] = useState({})

	const [categoryStateList] = useState([{ id: 'standby', text: 'stand-by' }, { id: 'liveon', text: 'live-on' }, { id: 'dummy', text: 'dummy' }])

	return (
		<CategoryDataContext.Provider
			value={{
				categoryStateList,
				isLoading,
				setIsLoading,
				categoryTypeList,
				setCategoryTypeList: (args) => {
					if (typeof args === 'function') {
						setCategoryTypeList(prev => {
							return args(prev)
						})
					} else {
						setCategoryTypeList(arraySort(args))
					}
				},
				langList,
				setLangList: (args) => {
					if (typeof args === 'function') {
						setLangList(prev => {
							return args(prev)
						})
					} else {
						setLangList(arraySort(args))
					}
				},
				categoryData,
				setCategoryData
			}}
		>
			{props.children}
		</CategoryDataContext.Provider>
	)
}
