import { styled, Typography, Stack } from "@mui/material";

export const UserFormBox = styled(Stack)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow:
    "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
  borderRadius: "16px",
  padding: theme.spacing(3),
}));

export const FieldsBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const FieldGroup = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

export const FieldName = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
  color: "#212B36",
}));

export const FieldValue = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  minHeight: "22px",
  lineHeight: "22px",
  color: "#637381",
}));
