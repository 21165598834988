import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_FAQ_CREATOR_PAGE } from 'api/quries';
import { useUrl } from '../hook/useUrl';
import { List } from './list';
import { REORDER_FAQ_CREATOR } from 'api/mutations';

export const FaqCreatorList = ((props) => {
    const { data: urlObj } = useUrl();
    const { data, refetch } = useQuery(GET_FAQ_CREATOR_PAGE, {
        fetchPolicy: "no-cache",
        variables: urlObj
    });

    const [reorder] = useMutation(REORDER_FAQ_CREATOR)

    return (
        <List
            reorder={reorder}
            refetch={refetch}
            totalPage={data && Math.ceil(+data.faqCreatorCount / urlObj.limit)}
            items={data && data.faqCreators}
        />
    );
});