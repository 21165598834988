import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { NEW_STATUS_DRAFT } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores/document.store';
import { EOpenrunOptions } from 'boards/DetailBoardWrite/stores/document.types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MINIMUN_PRICE } from './openrun.constans';
import { OpenrunContainer } from './openrun.styles';

export default (): React.ReactElement => {
  const { t } = useTranslation();
  const { status, price, openrun, book, setOpenrun } = useDocumentStore((state) => ({
    status: state.status,
    price: state.price,
    openrun: state.openrun,
    book: state.book,
    setOpenrun: state.setOpenrun,
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as EOpenrunOptions;
    setOpenrun(value);
  };

  const isDisabled = !Boolean(status === NEW_STATUS_DRAFT && (price || 0) >= MINIMUN_PRICE && !book);

  useEffect(() => {
    if (isDisabled) setOpenrun(EOpenrunOptions.NON_APPLICATION);
  }, [isDisabled, setOpenrun]);

  return (
    <>
      <OpenrunContainer disabled={isDisabled}>
        <Typography variant="body1" fontWeight={700} color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#4E4EFF'}>
          {t('openrunPage.applyOpenrun.title')}
        </Typography>
        <RadioGroup row onChange={handleChange} value={openrun}>
          <FormControlLabel value={EOpenrunOptions.APPLICATION} disabled={isDisabled} control={<Radio />} label={t('openrunPage.applyOpenrun.apply')} />
          <FormControlLabel value={EOpenrunOptions.NON_APPLICATION} disabled={isDisabled} control={<Radio />} label={t('openrunPage.applyOpenrun.doNotApply')} />
        </RadioGroup>
      </OpenrunContainer>
    </>
  );
};
