import React from 'react';

export function Resizer({ resize }: { resize(diff: number): void }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const prevClientY = React.useRef<number | undefined>(undefined);

  const onMouseDown = React.useCallback((e: MouseEvent) => {
    prevClientY.current = e.clientY;
  }, []);
  const onDrag = React.useCallback(
    (e: MouseEvent) => {
      if (!prevClientY.current) {
        return;
      }

      const diff = e.clientY - prevClientY.current;

      if (diff !== 0) {
        resize(diff);
      }

      prevClientY.current = e.clientY;
    },
    [resize],
  );
  const onMouseUp = React.useCallback(() => {
    prevClientY.current = undefined;
  }, []);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', onMouseUp);

      return () => {
        ref.current?.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mousemove', onDrag);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [onDrag, onMouseDown, onMouseUp]);

  return <div ref={ref} className="Editor-resize-handler" />;
}
