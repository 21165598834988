import styled from 'styled-components';

export const Wrap = styled.div`
    background-color: white;
`;
export const SearchBox = styled.div`
`;
export const Text = styled.div``;
export const Trigger = styled.div`
    width: 15px;
    height: 15px;
    background-color: white;
    margin-left: 3px;
    line-height: 100%;
    margin-top: 1px;
    i {
        font-size: 10px;
        font-weight: bold;
    }
`;

export const DropdownBox = styled.div`
    padding: 12px 16px;
    border: 1px solid #CDCDCD;
    border-radius: 3px;
    width: 428px;
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    background-color: white;
`;

export const SelectBox = styled.div`
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #808080;
    background-color: hsl(0,0%,100%);
    border-color: ${props => props.active ? "#2684FF" : "hsl(0,0%,80%)"};
    ${props => props.active ? "box-shadow: 0 0 0 1px #2684ff;" : ""}
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    padding: 2px 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
`;
export const ListBox = styled.div`
    width: 100%;
    margin-top: 8px;
    height: 192px;
    overflow-y: auto;
`;
export const CategoryItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 2px 8px;
`;
