import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Notice from './notice';
import Product from './product';
import { AppContext } from '../app';
import { Wrap } from './style';
import { isKorean } from '../lib/multiLocale';
import { Helmet } from 'react-helmet';

export default (function (props) {
  const { i18n } = useTranslation();
  const {
    // 배경색 변경하기 메소드
    setBackgroundColor,
    // 사용자 정보
    userInfo,
  } = useContext(AppContext);

  useEffect(() => {
    // 배경 흰색으로 설정
    setBackgroundColor('white');
    return () => {
      setBackgroundColor('');
    };
  }, []);

  return (
    <>
      {/* 첫 방문자의 대한 메세지 표시, 사용자 정보가 넘어온 경우에만 동작 */}
      {props.isEn ? (
        <Helmet>
          <title>ACON3D Partner hub</title>
          <meta content="ACON3D Partner page" name="description" />
        </Helmet>
      ) : (
        <></>
      )}
      <Wrap isAdmin={userInfo.isAdmin}>
        {isKorean(i18n.language) && <Notice {...props} itemType="view" />}
        <Product {...props} />
      </Wrap>
    </>
  );
});
