import { LanguageCodeEnum } from 'generated/graphql';
import { atom, DefaultValue, selector } from 'recoil';
import { PartnerTypeEnum } from 'signup/type';

const KEY = (key: string) => `user_edit_${key}`;

export const pageState = atom<number>({
  key: KEY('page'),
  default: 1,
});

export const companyNameState = atom({
  key: KEY('companyName'),
  default: '',
});

export const userTypeState = atom<PartnerTypeEnum>({
  key: KEY('type'),
  default: PartnerTypeEnum.Personal,
});

export const termLanguageState = atom<LanguageCodeEnum>({
  key: KEY('termLang'),
  default: LanguageCodeEnum.Ko,
});

export const nameState = atom({
  key: KEY('name'),
  default: '',
});

export const rrnFrontState = selector<string>({
  key: KEY('rrnFront'),
  get: ({ get }) => {
    return get(nationalIdNumberState)?.split('-')[0];
  },
  set: ({ get, reset, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(nationalIdNumberState);
    } else {
      const back = get(rrnBackState);

      set(nationalIdNumberState, `${newValue}-${back || ''}`);
    }
  },
});

export const rrnBackState = selector<string>({
  key: KEY('rrnBack'),
  get: ({ get }) => {
    return get(nationalIdNumberState)?.toString()?.split('-')[1];
  },
  set: ({ get, reset, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(nationalIdNumberState);
    } else {
      const front = get(rrnFrontState)?.toString();

      set(nationalIdNumberState, `${(front || '').toString()}-${(newValue || '')?.toString()}`);
    }
  },
});

export const emailState = atom({
  key: KEY('email'),
  default: '',
});

export const brandNameState = atom({
  key: KEY('brandName'),
  default: {
    ko: '',
    en: '',
    zh: '',
    ja: '',
  },
});
export const originalBrandNameState = atom({
  key: KEY('originalBrandName'),
  default: {
    ko: '',
    en: '',
    zh: '',
    ja: '',
  },
});
export const brandNameDuplicateState = atom({
  key: KEY('brandNameDuplicate'),
  default: {
    ko: false,
    en: false,
    zh: false,
    ja: false,
  },
});

export const contactState = atom({
  key: KEY('contact'),
  default: '',
});

export const countryState = atom({
  key: KEY('country'),
  default: '',
});

export const documentsState = atom({
  key: KEY('documents'),
  default: [],
});

export const nationalIdNumberState = atom({
  key: KEY('nationalIdNumber'),
  default: '',
});

export const isAgreeState = atom({
  key: KEY('isAgree'),
  default: false,
});

export const signFileState = atom<File>({
  key: KEY('signFile'),
  default: undefined,
});

export const isDrawnState = atom<boolean>({
  key: KEY('isDrawn'),
  default: false,
});

export const userStoreState = selector<{
  signFile?: File;
  email: string;
  termLanguage: LanguageCodeEnum;
  userType: PartnerTypeEnum;
  country?: string;
  companyName?: string;
  name?: string;
  brandNames: { [key in LanguageCodeEnum]?: string };
  contact?: string;
  documents?: string[];
  nationalIdNumber?: string;
}>({
  key: KEY('userStore'),
  get: ({ get }) => {
    return {
      email: get(emailState),
      companyName: get(companyNameState),
      signFile: get(signFileState),
      country: get(countryState),
      termLanguage: get(termLanguageState),
      userType: get(userTypeState),
      name: get(nameState),
      brandNames: get(brandNameState),
      contact: get(contactState),
      documents: get(documentsState),
      nationalIdNumber: get(nationalIdNumberState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(emailState);
      reset(companyNameState);
      reset(signFileState);
      reset(countryState);
      reset(termLanguageState);
      reset(userTypeState);
      reset(nameState);
      reset(brandNameState);
      reset(originalBrandNameState);
      reset(contactState);
      reset(documentsState);
      reset(nationalIdNumberState);
    } else {
      set(emailState, newValue.email);
      set(companyNameState, newValue.companyName);
      set(signFileState, newValue.signFile);
      set(countryState, newValue.country);
      set(termLanguageState, newValue.termLanguage);
      set(userTypeState, newValue.userType);
      set(nameState, newValue.name);
      set(brandNameState, newValue.brandNames);
      set(originalBrandNameState, newValue.brandNames);
      set(contactState, newValue.contact);
      set(documentsState, newValue.documents);
      set(nationalIdNumberState, newValue.nationalIdNumber);
    }
  },
});
