import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import palette from 'theme/palette';

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 400px !important;
  }
  .modal-content {
    border-radius: 16px;
    border: none;
  }
`;
export const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;
