import React from 'react';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';
import { extensions, fileSize, limit } from 'boards/DetailBoardWrite/component/imageUpload/constants';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';

export default ({
  cropperTag,
  image,
  setImage,
  setCroppedImageBlob,
  setCroppedImageDataUrl,
  disabled,
  isShow,
  isComplete,
  setComplete,
  setCallback,
  setCompleteCallback,
  setFileCallback,
  message,
  showMessage,
  onDeleteImage,
}) => {
  const { mainImage, setMainImage } = useDocumentStore((state) => ({
    mainImage: state.mainImage,
    setMainImage: state.setMainImage,
  }));
  return (
    <ImageUpload
      showMessage={showMessage}
      setCallback={setCallback}
      cropperTag={cropperTag}
      image={image}
      setImage={setImage}
      setCroppedImageBlob={setCroppedImageBlob}
      setCroppedImageDataUrl={setCroppedImageDataUrl}
      isShow={isShow}
      disabled={disabled}
      isComplete={isComplete}
      setComplete={setComplete}
      minWidth={fileSize[0]}
      minHeight={fileSize[1]}
      extensions={extensions}
      sizeLimit={10}
      targetWidth={fileSize[0] / 2}
      targetHeight={fileSize[1] / 2}
      limit={limit}
      cropRatio={fileSize[0] / fileSize[1]}
      setCompleteCallback={setCompleteCallback}
      left={mainImage?.cropInfo?.imageOriginX}
      top={mainImage?.cropInfo?.imageOriginY}
      width={mainImage?.cropInfo?.imageWidth}
      height={mainImage?.cropInfo?.imageHeight}
      url={mainImage?.origin || mainImage?.path}
      name={mainImage?.name}
      setCropInfo={(value) => {
        setMainImage({
          cropInfo: value,
        });
      }}
      setImageUrl={(key) => {
        setMainImage({
          path: key,
        });
      }}
      setImageOrigin={(key, name) => {
        setMainImage({
          origin: key,
          name,
        });
      }}
      setFileCallback={setFileCallback}
      onDeleteImage={onDeleteImage}
    />
  );
};
