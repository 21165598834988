import { STATUS_DRAFT } from 'boards/DetailBoardWrite/constants';
import ACON from 'lib/global';
import React, { createContext, useReducer } from 'react'

const initCopyright = {
    // 본 모델의 소스는 직접 제작하였습니다. (On / Off)
    'diy': 'off',

    // 3D웨어하우스 소스를 사용하였습니다 (On / Off)
    '3dWarehouse': 'on',
    // 3D웨어하우스 소스 목록 
    '3dWarehouseList': '',

    // 상업적으로 이용 가능한 타 무료 외부소스를 사용하였습니다 (On / Off)
    'freeExtraSource': 'on',
    // 타 무료 외부소스 목록 
    'freeExtraSourceList': '',

    // 상업적으로 이용 가능한 타 유로 외부소스를 사용하였습니다 (On / Off)
    'payExtraSource': 'on',
    // 타 유료 외부소스 목록 
    'payExtraSourceList': '',

    // 동적 구성 요소가 포함되어 있습니다 (On / Off)
    'dynamicElement': 'on',

    // 동의
    'agreement': 'off'
};
// 초기 상태 
const initState = {
    // 표시여부 (사용자의 경우, SAVED 이외에는 전부 수정 불가 )
    isDisp: false,
    lang: '',
    // 수정모드 여부 
    isEdit: false,
    // 문서 ID 
    docId: '',
    // 제목 
    title: '',
    // 본문
    contents: '',
    // 문서 상태 
    status: STATUS_DRAFT,
    // 저작권 
    copyright: initCopyright,

    isErrorFreeCopyright: false,
    isErrorPayCopyright: false,
    isErrorWarehouseCopyright: false,
    isErrorAgree: false,

    languages: [],

    // 반려 사유 
    rejectComment: '',
    // 수정 사항
    editMessage: '',
    // 수정 의견
    editComment: '',

    // 브랜드 ID
    brandId: '',
    // 관리자 메모 
    description: '',

    // 저장된 메인 이미지 URL 
    mainImageUrl: '',
    // 메인 이미지 key
    mainImageKey: '',
    // 메인 이미지 명 
    mainImageName: '',

    // 크롭된 메인 이미지
    croppedMainImage: '',
    // 크롭된 메인 이미지 dataurl
    croppedMainImageDataUrl: '',

    // 크롭된 썸네일 이미지
    croppedThumbnailImage: '',
    // 크롭된 썸네일 이미지 dataurl
    croppedThumbnailImageDataUrl: '',

    // 메인 이미지 크롭정보
    mainImageCropInfo: '',
    // 썸네일 이미지 크롭정보
    thumbnailImageCropInfo: '',

    // 크롭된 서브 이미지1
    croppedSubImage1: '',
    // 크롭된 서브 이미지1 dataurl
    croppedSubImage1DataUrl: '',
    // 서브 이미지1 url
    subImage1Url: '',
    // 서브 이미지1 KEY
    subImage1Key: '',
    // 서브 이미지1 이름 
    subImage1Name: '',
    // 서브 이미지1 크롭정보
    subImage1CropInfo: '',

    // 크롭된 서브 이미지2
    croppedSubImage2: '',
    // 크롭된 서브 이미지2 dataurl
    croppedSubImage2DataUrl: '',
    // 서브 이미지2 url
    subImage2Url: '',
    // 서브 이미지2 KEY
    subImage2Key: '',
    // 서브 이미지2 이름 
    subImage2Name: '',
    // 서브 이미지2 크롭정보
    subImage2CropInfo: '',

    // 크롭된 서브 이미지3
    croppedSubImage3: '',
    // 크롭된 서브 이미지3 dataurl
    croppedSubImage3DataUrl: '',
    // 서브 이미지3 url
    subImage3Url: '',
    // 서브 이미지3 KEY
    subImage3Key: '',
    // 서브 이미지3 이름 
    subImage3Name: '',
    // 서브 이미지3 크롭정보
    subImage3CropInfo: '',

    // 모델 id
    modelFileId: '',
    // 모델 파일 명
    modelFileName: '',
    // 모델 key 
    modelKey: '',
    // 모델 이름 
    modelName: '',

    // 원본 고도몰 상품 번호
    originGodoGoodsNo: '',
    // 고도몰 상품 번호
    godoGoodsNo: '',

    versions: '',

    // 무료여부 
    isFree: false,
    // 단가 
    salePrice: '',
    // 판매가 
    price: 0,
    // 수수료 
    commission: '',
    // 카테고리 
    category: [],
    // 모델 구성 정보들 
    modelConfig: [],
    // 동적 항목들 
    dynamicItem: '',
    // 확장자들 
    extension: [],
    // 파일 구성들 
    fileConfig: [],
    // 업데이트 내역 
    updateHistory: [],
    // 키워드 
    keywords: '',
    // 연관상품
    related: [],
};
const store = createContext();
const { Provider } = store;

// 리듀서 
const reducer = (state, action) => {
    switch (action.type) {
        case 'set': {
            return {
                ...state,
                ...action.value
            }
        }
        case 'copyright': {
            return {
                ...state,
                copyright: {
                    ...state.copyright,
                    [action.key]: action.value
                }
            }
        }
        case 'input': {
            return {
                ...state,
                [action.key]: action.value
            }
        }
        case 'blur.price': {
            return {
                ...state,
                [action.key]: ACON.AddCommas(ACON.OnlyNumber(action.value))
            }
        }
        case 'blur.salesPrice': {
            return {
                ...state,
                [action.key]: action.value === '' ? '' : ACON.AddCommas(ACON.OnlyNumber(action.value))
            }
        }
        case 'input.number': {
            return {
                ...state,
                [action.key]: ACON.OnlyNumber(action.value)
            }
        }
    }
};

const ProductProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initState);

    return (
        <Provider value={{
            state,
            dispatch
        }}>
            {children}
        </Provider>
    );
};

export default store;
export { store, ProductProvider }