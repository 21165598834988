import React, { useState } from 'react';
import Spinner from '../spinner/Spinner';
import axios from 'axios'
import './SearchPw.scss';
import SearchPwInput from './SearchPwInput';
import Message from '../common/Message';
import { useMutation } from '@apollo/client';
import { PASSWORD_CHANGE_FOR_ANON } from 'api/mutations';

// 패스워드 변경하기 페이지 
export default ((props) => {
    // 번역사전 
    const { t } = props;

    // 이메일 
    const [email, setEmail] = useState();
    // 필명 
    const [penname, setPenname] = useState();
    // 로딩중 여부 
    const [isLoading, setIsLoading] = useState(false);
    // 모달 상태
    const [modalState, setModalState] = useState({
        isOpen: false,
        title: '',
        message: ''
    });

    const [ passwordChangeForAnon ] = useMutation(PASSWORD_CHANGE_FOR_ANON);

    // 이메일 변경 이벤트 처리기 메소드
    const onChangeEmailInputTag = (e) => {
        setEmail(e.currentTarget.value);
    };
    // 필명 변경 이벤트 처리기 메소드
    const onChangePennameInputTag = (e) => {
        setPenname(e.currentTarget.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // 로딩 표시 
        setIsLoading(true);

        try {
            await passwordChangeForAnon({ variables: { account: email, brandName: penname } });
            
            setModalState({
                ...modalState,
                isOpen: true,
                message: t('searchPassword.sendMail'),
                title: ''
            });
        } catch (err) {
            if (err.message === 'not found') {
                setModalState({
                    ...modalState,
                    isOpen: true,
                    message: t('searchPassword.wrongInput'),
                    title: ''
                });
                return;
            }

            setModalState({
                ...modalState,
                isOpen: true,
                message: 'error',
                title: ''
            });
        } finally {
            setIsLoading(false);
        }
    }

    // 모달창 총료하기 메소드 
    const closeMessageModal = (e) => {
        // 모달 상태 변경 
        setModalState({
            ...modalState,
            isOpen: false,
            message: ''
        });
    }

    // 요청 텍스트 
    const requestBtnText = isLoading ?
        <Spinner color="#FFFFFF" size="24" /> : t("Request.label");

    // 활성화 여부 
    const isEnabled = email && penname && !isLoading;

    return (
        <>
            {/* 안내메세지 모달  */}
            <Message
                show={modalState.isOpen}
                close={closeMessageModal}
                title={modalState.title}
                message={modalState.message}
            />

            <div className="searchPw block-container">
                <form onSubmit={handleSubmit}>
                    <div className="searchPw__line">
                        <label className="title">
                            {t("SearchPassword.label")}
                        </label>
                    </div>

                    {/* 이메일 input */}
                    <SearchPwInput
                        label={t("E-mail")}
                        value={email}
                        name="email"
                        onChange={onChangeEmailInputTag}
                    />

                    {/* 승인된 필명 / 닉네임 */}
                    <SearchPwInput
                        label={t("Penname.label")}
                        value={penname}
                        name="penname"
                        onChange={onChangePennameInputTag}
                    />

                    <div className="searchPw__line btn-line">
                        <button
                            type="submit"
                            className="vivid-button"
                            disabled={!isEnabled}>
                            {requestBtnText}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
});