import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../../../../app'
import { GET_POINT_BENEFIT_WRITING_MATERIALS } from '../../../../api/quries';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePickerInput, KeyboardTimePickerInput } from '../../../../components/input/Pickers';
import Category from '../../editor/category';
import Brand from '../../editor/brand';
import GoodsSearch from '../../editor/goodsSearch';
import {
    APPLY_BOARD_CODE,
    PAY_UNIT_FIXED_CODE,
    PAY_UNIT_PERCENT_CODE,
    UNIT_BOARD_REVIEW_CODE,
    UNIT_COMPLETE_CODE,
    UNIT_DEFAULT_COMPLETE_CODE,
    UNIT_DEFAULT_REVIEW_CODE,
    UNIT_PHOTO_REVIEW_CODE,
    UNIT_SETTLE_CODE,
    UNIT_TEXT_REVIEW_CODE
} from '../../constants';
import { CREATE_POINT_BENEFIT, DELETE_POINT_BENEFIT, UPDATE_POINT_BENEFIT } from '../../../../api/mutations';
import {
    CommonButton,
    CommonInputCheckbox,
    CommonInputRadioBox,
    CommonInputTextBox,
    CommonSelectBox,
    DefaultInitText,
    DefaultReviewContainer,
    DefaultReviewItem,
    DefaultReviewText,
    ExchangeRateInput,
    ExchangeRateText,
    Flex,
    FormContainer,
    Label,
    PointHeader,
    PointInputContainer,
    PointWriteContainer,
    Row,
    RowTitle,
    SubTitle
} from '../../style';
import { convertPrice } from "../../../../lib/multiLocale";

export default ((props) => {
    // 번역도구
    const { t } = useTranslation();
    // 사용자 정보
    const { userInfo } = useContext(AppContext);
    // 유형 설정
    const unitType = useRef(null);
    // 기본 설정이 적용되는 유형 코드들
    const defaultApplyCodeArr = [UNIT_COMPLETE_CODE, UNIT_TEXT_REVIEW_CODE, UNIT_PHOTO_REVIEW_CODE];
    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    if (!isAdmin) {
        props.history.push("/");
        return <></>;
    }

    /* State 설정 - 시작 */
    // 포인트 혜택 적용할 몰(국가)
    const [languageValue, setLanguageValue] = useState(1);
    // 포인트 혜택 -> 유형
    const [typeValue, setTypeValue] = useState(1);
    // 포인트 혜택 -> 포인트 설정 -> 지급 포인트 단위
    const [payUnitValue, setPayUnitValue] = useState(PAY_UNIT_FIXED_CODE);
    // 포인트 혜택 -> 포인트 설정 -> 지급 포인트
    const [payPointValue, setPayPointValue] = useState('');
    // 포인트 혜택 -> 적용 설정 -> 설정 타입
    const [applyTypeValue, setApplyTypeValue] = useState(1);
    // 포인트 혜택 -> 적용 설정 -> 설정 타입 코드
    const [applyTypeCode, setApplyTypeCode] = useState('category');
    // 포인트 혜택 -> 적용 설정 -> 선택 설정 타입
    const [applySelectedValue, setApplySelectedValue] = useState([]);
    // 포인트 혜택 -> 제한 설정 -> 시작일 yyyy-mm-dd
    const [startDate, setStartDate] = useState(null);
    // 포인트 혜택 -> 제한 설정 -> 종료일 yyyy-mm-dd
    const [endDate, setEndDate] = useState(null);
    // 포인트 혜택 -> 수량
    const [quantityValue, setQuantityValue] = useState('');
    // 포인트 혜택 -> 기본 설정
    const [defaultApplyValue, setDefaultApplyValue] = useState(false);
    // 포인트 혜택 -> 지급 내용
    const [descriptionValue, setDescriptionValue] = useState('');
    // 기본 설정 여부
    const [isDefaultApply, setIsDefaultApply] = useState(false);
    // 기본 설정 - 국문몰 리뷰 작성 포인트 지급 값
    const [koPointValue, setKoPointValue] = useState(0);
    // 기본 설정 - 영문몰 리뷰 작성 포인트 지급 값
    const [enPointValue, setEnPointValue] = useState(0);
    // 기본 설정 - 중문몰 리뷰 작성 포인트 지급 값
    const [cnPointValue, setCnPointValue] = useState(0);
    // 기본 설정 - 일본몰 리뷰 작성 포인트 지급 값
    const [jpPointValue, setJpPointValue] = useState(0);
    // 저장 진행 중 여부 상태 값
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    // 자동 생성 여부
    const [isAutoBenefit, setIsAutoBenefit] = useState(false);
    /* State 설정 - 끝 */

    // 포인트 혜택 id
    const [docId] = useState(+props.match.params.docId);
    // 언어 배열
    const langArr = ['ko', 'en', 'cn', 'jp'];
    const {
        showAlertMessage
    } = useContext(AppContext)

    // 포인트 혜택 정보 가져오기 API
    const { data } = useQuery(GET_POINT_BENEFIT_WRITING_MATERIALS, {
        fetchPolicy: "no-cache",
        variables: { id: docId || 0 }
    });

    // 포인트 헤택 정보
    let pointBenefitData = data?.getPointBenefit,
        pointBenefitType = data?.getPointBenefitTypes,
        pointBenefitApply = data?.getPointBenefitApply,
        languages = data?.getLanguages,
        reviewPointItems = data?.getReviewPointItems;
    // 언어별 ID
    const koLangId = languages?.find(x => x.code === 'ko').id;
    const enLangId = languages?.find(x => x.code === 'en').id;
    const cnLangId = languages?.find(x => x.code === 'cn').id;
    const jpLangId = languages?.find(x => x.code === 'jp').id;

    const languageIdToCodeMap = new Map();
    for (let key in languages) {
        const language = languages[key];
        languageIdToCodeMap.set(language.id, language.code);
    }

    useEffect(() => {
        if (pointBenefitData) {
            setLanguageValue(pointBenefitData.language.id);
            setTypeValue(pointBenefitData.type.id);
            setPayUnitValue(pointBenefitData.pointUnit);
            setPayPointValue(pointBenefitData.payPoint);
            setApplyTypeValue(pointBenefitData.applyType.id);
            setApplyTypeCode(pointBenefitData.applyType.code);
            setStartDate(new Date(pointBenefitData.startDate));
            setEndDate(new Date(pointBenefitData.endDate));
            setQuantityValue(pointBenefitData.quantity);
            setDescriptionValue(pointBenefitData.description);
            setDefaultApplyValue(pointBenefitData.defaultApply);
            setIsAutoBenefit(pointBenefitData.isAutoCreatedByPromotion);

            // 기본 설정일경우 기본 설정 여부 true로 변경
            if (pointBenefitData.applyType.code === 'default') {
                setIsDefaultApply(true);
            }

            reviewPointItems = reviewPointItems.filter(x => x.typeId === pointBenefitData.type.id);

            // 기본 설정 - 리뷰 작성일 경우 국가별로 값 세팅
            if (reviewPointItems && (pointBenefitData.type.id === UNIT_TEXT_REVIEW_CODE || pointBenefitData.type.id === UNIT_PHOTO_REVIEW_CODE)) {
                reviewPointItems.forEach(x => {
                    if (x.language.code === 'ko') {
                        setKoPointValue(x.payPoint);
                    } else if (x.language.code === 'en') {
                        setEnPointValue(x.payPoint);
                    } else if (x.language.code === 'cn') {
                        setCnPointValue(x.payPoint);
                    } else if (x.language.code === 'jp') {
                        setJpPointValue(x.payPoint);
                    }
                });
            }

            // 적용 설정이 게시글 일 경우 값을 문자열로 변환하여 넣어줌
            let applyValue = pointBenefitData.applyList.map(x => Number(x.value));
            if (pointBenefitData.applyType.code === APPLY_BOARD_CODE) {
                applyValue = pointBenefitData.applyList.map(x => x.value);
            }
            setApplySelectedValue(applyValue);
        }
    }, [pointBenefitData, reviewPointItems]);

    // lang 설정 UI
    const LanguageSelectTag = () => {
        const options = languages?.map((e, i) => {
            return (
                <option key={i} value={e.id}>{e.code.toUpperCase()}</option>
            );
        });
        return (
            <CommonSelectBox disabled={isAutoBenefit} textAlign={'center'} padding={'0px 40px'} onChange={onChangeLanguage} value={languageValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // lang 설정 변경시 작동하는 로직
    const onChangeLanguage = (e) => {
        setLanguageValue(Number(e.target.value));
    };

    // 유형 설정 UI
    const TypeSelectTag = () => {
        // 기본 설정 지급 체크여부를 표시할 유형 코드
        const defaultApplyArr = [UNIT_DEFAULT_COMPLETE_CODE, UNIT_DEFAULT_REVIEW_CODE];
        // 만약 기본 설정일 경우 기본설정 유형만 표시하고
        // 기본설정이 아닐경우 일반적인 유형들을 표시합니다.
        const options = [];
        (pointBenefitType || []).forEach((e, i) => {
            if ((isDefaultApply && defaultApplyArr.includes(e.id)) || (!defaultApplyArr.includes(e.id))) {
                // 자동 생성된 혜택일 경우 적용설정이 바뀌는 게시글을 표시하지 않습니다.
                // 자동 생성이 아닐경우에만 게시글을 포함하여 전부 보여줍니다.
                if (!isAutoBenefit || (isAutoBenefit && e.id !== UNIT_BOARD_REVIEW_CODE)) {
                    options.push(<option key={i} data-code={e.code} value={e.id}>{e.name.toUpperCase()}</option>);
                }
            }
        });
        return (
            <CommonSelectBox disabled={isDefaultApply} ref={unitType} radius={'3px'} width={'100%'} padding={'0px 0px 0px 10px'} margin={'10px 0px 0px 0px'} onChange={onChangeType} value={typeValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // 유형 설정 변경시 작동하는 로직
    const onChangeType = (e) => {
        const typeCode = e.target.options[e.target.selectedIndex].dataset.code;
        const changetypeValue = Number(e.target.value);

        if (typeCode === 'writeComment') {
            // 적용 설정 게시글 ID
            const boardId = pointBenefitApply.find(x => x.code === 'board').id;
            setApplyTypeValue(boardId);
            setApplyTypeCode('board');
            onChangeApplyValue([]);
        } else if (typeValue === UNIT_BOARD_REVIEW_CODE) {
            // 댓글 작성에서 변경하는 경우 적용 설정 카테고리로 변경
            setApplyTypeValue(1);
            setApplyTypeCode('category');
            onChangeApplyValue([]);
        } else if (defaultApplyCodeArr.includes(typeValue)) {
            // 구매완료, 일반 리뷰만 기본 설정 여부를 표시하도록 설정
            setDefaultApplyValue(false);
        }

        // 유형이 일반 리뷰, 포토 리뷰, 댓글 작성일 경우 포인트 설정 정액으로 변경
        if (typeCode === 'textReview' || typeCode === 'photoReview' || typeCode === 'writeComment') {
            setPayUnitValue(PAY_UNIT_FIXED_CODE);
        }

        setTypeValue(changetypeValue);
    };

    // 적용 설정 UI
    const ApplySelectTag = () => {
        const options = pointBenefitApply?.filter((e) => ((typeValue !== UNIT_BOARD_REVIEW_CODE && e.code !== 'board') || (typeValue === UNIT_BOARD_REVIEW_CODE && e.code === 'board')) && e.code !== 'default')?.map((e, i) => {
            return (
                <option key={i} data-code={e.code} value={e.id}>{e.name.toUpperCase()}</option>
            )
        });

        return (
            <CommonSelectBox disabled={isAutoBenefit} radius={'3px'} width={'100%'} padding={'0px 0px 0px 10px'} margin={'10px 0px 0px 0px'} onChange={onChangeApply} value={applyTypeValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // 적용 설정 변경시 작동하는 로직
    const onChangeApply = (e) => {
        const selectedCode = e.target.options[e.target.selectedIndex].dataset.code;
        setApplyTypeValue(Number(e.target.value));
        setApplyTypeCode(selectedCode);
        setApplySelectedValue([]);
    };
    // 적용 설정 카테고리 박스
    const onChangeApplyValue = (value) => {
        setApplySelectedValue(value);
    };

    // 포인트 설정 라디오 버튼 클릭 시
    const onChangePayUnit = (e) => {
        setPayUnitValue(e.target.value);
    };

    const [createPointBenefitMutation] = useMutation(CREATE_POINT_BENEFIT);
    const [updatePointBenefitMutation] = useMutation(UPDATE_POINT_BENEFIT);
    const [deletePointBenefitMutation] = useMutation(DELETE_POINT_BENEFIT);

    // 포인트 혜택 저장
    const onClickSave = async () => {
        if (!payPointValue) {
            showAlertMessage(t('burden.point.validation.title'), t('burden.point.validation.payPoint'));
        } else if (applySelectedValue.length === 0 && !isDefaultApply) {
            showAlertMessage(t('burden.point.validation.title'), t('burden.point.validation.applySelectedValue'));
        } else if (!descriptionValue && !isDefaultApply) {
            showAlertMessage(t('burden.point.validation.title'), t('burden.point.validation.description'));
        } else if (startDate >= endDate && endDate) {
            showAlertMessage(t('burden.point.validation.title'), t('burden.point.validation.date'));
        } else if (!isLoadingSave) {
            // 저장 진행 중으로 상태 변경
            setIsLoadingSave(true);

            const queryVariables = {
                langId: languageValue,
                typeId: typeValue,
                pointUnit: payUnitValue,
                payPoint: payPointValue,
                applyTypeId: applyTypeValue,
                applyList: applySelectedValue.map(x => x.toString()),
                startDate: startDate || new Date(),
                endDate: endDate || new Date("2099-01-01T12:00Z"),
                quantity: quantityValue,
                description: descriptionValue,
                defaultApply: defaultApplyValue,
                koPointValue: koPointValue.toString(),
                enPointValue: enPointValue.toString(),
                cnPointValue: cnPointValue.toString(),
                jpPointValue: jpPointValue.toString()
            }
            if (docId) queryVariables.id = docId;

            let mutation = docId ? updatePointBenefitMutation : createPointBenefitMutation;
            const { data } = await mutation({
                variables: queryVariables
            });

            const isSucceed = data?.createPointBenefit || data?.updatePointBenefit;

            if (isSucceed) {
                showAlertMessage(t('saved'), t('saved'));
                props.history.goBack();
            } else {
                // 오류 발생 시 저장 진행 전으로 변경
                setIsLoadingSave(false);
                throw new Error("graphql query failed");
            }
        }
    };

    // 포인트 혜택 삭제
    const onClickDelete = async () => {
        const { data } = await deletePointBenefitMutation({
            variables: { id: docId }
        });

        if (data?.deletePointBenefit) {
            showAlertMessage(t("Boards.delete.title"), t("Boards.delete.content"))
        } else {
            showAlertMessage(t("Boards.delete.title"), t("Boards.deleteFail.content"))
        }

        props.history.goBack();
    };

    const onChangeTime = (time, setDate) => {
        if (time && !isNaN(time.getHours()) && !isNaN(time.getMinutes())) {
            setDate((date) => {
                const prevDate = date || new Date();
                prevDate.setHours(time.getHours(), time.getMinutes());
                return new Date(prevDate);
            });
        }
    };

    return (
        <PointWriteContainer>
            <PointHeader>
                {
                    /* 기본 설정이 아닐경우 몰 설정을 표시합니다. */
                    !isDefaultApply ? <LanguageSelectTag /> : <div></div>
                }
                { /* 버튼 */}
                <div>
                    {(pointBenefitData && !isDefaultApply && !isAutoBenefit) &&
                        <CommonButton onClick={onClickDelete} padding={'0px 50px'} color={'#333333'} bgColor={'white'}>{t('Remove.label')}</CommonButton>
                    }
                    <CommonButton onClick={onClickSave} padding={'0px 50px'} margin={'0px 0px 0px 10px;'}>{t('Save.label')}</CommonButton>
                </div>
            </PointHeader>
            <FormContainer>
                { /* 유형 */}
                <Row>
                    <RowTitle>{t('burden.point.unit')}</RowTitle>
                    <TypeSelectTag />
                </Row>

                { /* 포인트 설정 */}
                <Row>
                    <RowTitle>{t('burden.point.init.title')}</RowTitle>
                    {typeValue === UNIT_DEFAULT_REVIEW_CODE || typeValue === UNIT_PHOTO_REVIEW_CODE &&
                        <SubTitle>{t('burden.point.init.subTitle')}</SubTitle>
                    }
                    <Flex>
                        <div style={{ marginRight: '30px' }}>
                            <CommonInputRadioBox onChange={onChangePayUnit} checked={payUnitValue === PAY_UNIT_FIXED_CODE} id={'payUnit1'} name={'payUnit'} value={PAY_UNIT_FIXED_CODE} />
                            <Label htmlFor={'payUnit1'}>{t('burden.point.init.radio1')}</Label>
                        </div>
                        {
                            // 구매 완료 및 구매 확정일 경우만 정률 표시
                            (typeValue === UNIT_COMPLETE_CODE || typeValue === UNIT_SETTLE_CODE || typeValue === UNIT_DEFAULT_COMPLETE_CODE) &&
                            <div>
                                <CommonInputRadioBox onChange={onChangePayUnit} checked={payUnitValue !== PAY_UNIT_FIXED_CODE} id={'payUnit2'} name={'payUnit'} value={PAY_UNIT_PERCENT_CODE} />
                                <Label htmlFor={'payUnit2'}>{t('burden.point.init.radio2')}</Label>
                            </div>
                        }
                    </Flex>
                    {
                        // 리뷰 작성(기본 설정)이 아닌 경우에만 기본 입력 박스를 표시합니다.
                        // 리뷰 작성(기본 설정)일 경우 국가별로 입력할 수 있는 박스를 표시합니다.
                        typeValue !== UNIT_TEXT_REVIEW_CODE && typeValue !== UNIT_PHOTO_REVIEW_CODE
                        || ((typeValue === UNIT_PHOTO_REVIEW_CODE || typeValue === UNIT_TEXT_REVIEW_CODE) && ! isDefaultApply) ?
                            <>
                                {
                                    payUnitValue === PAY_UNIT_FIXED_CODE ?
                                        <PointInputContainer>
                                            <CommonInputTextBox style={{ width: '55%' }} value={payPointValue}
                                                onChange={(e) => {
                                                    setPayPointValue(e.target.value)
                                                }} placeholder={t('burden.point.init.placeholder')} />
                                            <CommonInputTextBox style={{ width: '43%' }}
                                                value={convertPrice(payPointValue, languageIdToCodeMap.get(koLangId), languageIdToCodeMap.get(languageValue))}
                                                disabled={true}
                                                placeholder={t('burden.point.exchange.placeholder')} />
                                        </PointInputContainer>
                                        :
                                        <CommonInputTextBox value={payPointValue} onChange={(e) => { setPayPointValue(e.target.value) }} placeholder={t('burden.point.init.placeholder2')} />
                                }
                            </>
                            :
                            <DefaultReviewContainer>
                                <DefaultReviewItem>
                                    <DefaultReviewText isFirst={true}>KO</DefaultReviewText>
                                    <CommonInputTextBox style={{ textAlignLast: 'center', padding: '0px' }}
                                        value={koPointValue} onChange={(e) => {
                                            setKoPointValue(e.target.value);
                                        }} />
                                </DefaultReviewItem>
                                <DefaultReviewItem>
                                    <DefaultReviewText>EN</DefaultReviewText>
                                    <ExchangeRateText>(1USD=1,000KRW)</ExchangeRateText>
                                    <CommonInputTextBox style={{ textAlignLast: 'center', padding: '0px' }}
                                        value={enPointValue} onChange={(e) => {
                                            setEnPointValue(e.target.value);
                                        }} />
                                    <ExchangeRateInput
                                        value={convertPrice(enPointValue, languageIdToCodeMap.get(koLangId), languageIdToCodeMap.get(enLangId))} />
                                </DefaultReviewItem>
                                <DefaultReviewItem>
                                    <DefaultReviewText>ZH</DefaultReviewText>
                                    <ExchangeRateText>(1CNY=160KRW)</ExchangeRateText>
                                    <CommonInputTextBox style={{ textAlignLast: 'center', padding: '0px' }}
                                        value={cnPointValue} onChange={(e) => {
                                            setCnPointValue(e.target.value);
                                        }} />
                                    <ExchangeRateInput
                                        value={convertPrice(cnPointValue, languageIdToCodeMap.get(koLangId), languageIdToCodeMap.get(cnLangId))} />
                                </DefaultReviewItem>
                                <DefaultReviewItem>
                                    <DefaultReviewText>JA</DefaultReviewText>
                                    <ExchangeRateText>(1JPY=10KRW)</ExchangeRateText>
                                    <CommonInputTextBox style={{ textAlignLast: 'center', padding: '0px' }}
                                        value={jpPointValue} onChange={(e) => {
                                            setJpPointValue(e.target.value);
                                        }} />
                                    <ExchangeRateInput
                                        value={convertPrice(jpPointValue, languageIdToCodeMap.get(koLangId), languageIdToCodeMap.get(jpLangId))} />
                                </DefaultReviewItem>
                            </DefaultReviewContainer>
                    }
                </Row>

                {!isDefaultApply &&
                    <>
                        { /* 적용 설정 */}
                        <Row>
                            <RowTitle>
                                {t('burden.point.apply.title')}
                                {
                                    // 구매 완료 및 일반 리뷰, 포토 리뷰일 경우에만 기본 설정 여부 표시
                                    defaultApplyCodeArr.includes(typeValue) &&
                                    <>
                                        <CommonInputCheckbox checked={defaultApplyValue} onChange={(e) => { setDefaultApplyValue(e.target.checked); }} id="defaultInit" />
                                        <DefaultInitText for="defaultInit">기본설정 포인트 혜택을 지급하지 않음</DefaultInitText>
                                    </>
                                }
                            </RowTitle>
                            <ApplySelectTag />
                            {
                                applyTypeCode === 'category' &&
                                <Category disabled={isAutoBenefit} onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} />
                            }
                            {
                                applyTypeCode === 'brand' &&
                                <Brand disabled={isAutoBenefit} onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} />
                            }
                            {
                                applyTypeCode === 'goodsNo' &&
                                <GoodsSearch disabled={isAutoBenefit} onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} lang={langArr[languageValue - 1]} />
                            }
                            {
                                applyTypeCode === 'board' &&
                                <CommonInputTextBox disabled={isAutoBenefit} onChange={(e) => { onChangeApplyValue([e.target.value]) }} value={applySelectedValue.toString()} placeholder={'게시글 주소를 입력하세요.'} />
                            }
                        </Row>
                        { /* 기간 */}
                        <Row>
                            <RowTitle>{t('burden.point.limit.title')}</RowTitle>
                            <RowTitle margin={'16px 0px 0px 0px'} size={'15px'}>{t('burden.point.limit.subTitle')}</RowTitle>
                            <Flex justify={'space-between'}>
                                <label>{t('burden.point.startDate')}</label>
                                <KeyboardDatePickerInput
                                    className="pickers date"
                                    onChange={setStartDate}
                                    value={startDate}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy-MM-dd"
                                    disablePast={false}
                                    emptyLabel={t('Popup.startDatePlaceholder')}
                                    disabled={isAutoBenefit} />
                                <KeyboardTimePickerInput
                                    className="pickers time"
                                    onChange={(time) => { onChangeTime(time, setStartDate) }}
                                    value={startDate}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="HH:mm"
                                    disablePast={false}
                                    emptyLabel="00:00"
                                    disabled={isAutoBenefit} />
                            </Flex>
                            <Flex justify={'space-between'}>
                                <label>{t('burden.point.endDate')}</label>
                                <KeyboardDatePickerInput
                                    className="pickers date"
                                    onChange={setEndDate}
                                    value={endDate}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy-MM-dd"
                                    disablePast={true}
                                    emptyLabel={t('Popup.endDatePlaceholder')}
                                    disabled={isAutoBenefit} />
                                <KeyboardTimePickerInput
                                    className="pickers time"
                                    onChange={(time) => { onChangeTime(time, setEndDate) }}
                                    value={endDate}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="HH:mm"
                                    disablePast={true}
                                    emptyLabel="00:00"
                                    disabled={isAutoBenefit} />
                            </Flex>
                        </Row>
                        { /* 수량 */}
                        <Row>
                            <RowTitle>{t('burden.point.quantity.title')}</RowTitle>
                            <CommonInputTextBox disabled={isAutoBenefit} value={quantityValue} onChange={(e) => { setQuantityValue(e.target.value) }} placeholder={t('burden.point.quantity.placeholder')} />
                        </Row>
                        { /* 지급 내용 */}
                        <Row>
                            <RowTitle>{t('burden.point.description.title')}</RowTitle>
                            <CommonInputTextBox value={descriptionValue} onChange={(e) => { setDescriptionValue(e.target.value) }} placeholder={t('burden.point.description.placeholder')} />
                        </Row>
                    </>
                }
            </FormContainer>
        </PointWriteContainer>
    );
});