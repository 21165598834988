import { Switch } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { BrandSaveWrap, BrandTitleWrap, Title } from '../../index.styles';
import { BrandPickContainer, BrandPickList, BrandPickListItem, BrandPickListItemCloseButton, BrandPickListItemText } from './BrandPick.styles';
import { Button } from 'acon-mui/components';
import BrandPickModal from './components/BrandPickModal/BrandPickModal';
import BrandPickConfirmModal from './components/BrandPickConfirmModal/BrandPickConfirmModal';
import { useTranslation } from 'react-i18next';
import { useBrandPickQuery, useUpdateBrandPickMutation } from 'generated/graphql';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

const BrandPick = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [isShowPickListModal, setIsShowPickListModal] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [brandPick, setBrandPick] = useState([]);
  const [isBrandPickEnabled, setIsBrandPickEnabled] = useState(false);
  const lang = useMemo(() => {
    let lang = localStorage.getItem('i18nextLng');
    if (!lang) lang = i18n.language;
    return lang;
  }, [i18n.language]);

  const { data: picks, refetch: refetchPick } = useBrandPickQuery();
  const brandPickData = picks?.brandPick;

  const [updateBrandPickMutation] = useUpdateBrandPickMutation({ onError: (error) => alert(error.message) });

  const handleOnSaveProduct = async () => {
    try {
      if (brandPick.length > 4) {
        alert(t('BrandPage.brandPick.maximumDescription'));
        return;
      }
      if (brandPick.length === 0) {
        await updateBrandPickMutation({ variables: { assetIds: _.map(brandPick, (list) => list.id), isOn: false } });
        refetchPick();
        setIsBrandPickEnabled(false);
        setIsShowConfirmModal(false);
        return;
      }
      await updateBrandPickMutation({ variables: { assetIds: _.map(brandPick, (list) => list.id), isOn: isBrandPickEnabled } });
      refetchPick();
      setIsShowConfirmModal(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleOnRemoveProduct = (id) => {
    setBrandPick(_.filter(brandPick, (item) => item.id !== id));
  };

  const handleOnApllyProduct = (pick) => {
    setBrandPick(pick);
    setIsShowPickListModal(false);
  };

  const handleOnToggleBrandPick = (e) => setIsBrandPickEnabled(e.target.checked);

  useEffect(() => {
    if (brandPickData) {
      setBrandPick(brandPickData.assets);
      setIsBrandPickEnabled(brandPickData.isOn);
    }
  }, [brandPickData]);

  if (!brandPickData) return <></>;
  return (
    <>
      <BrandPickModal originBrandPick={brandPick} isShow={isShowPickListModal} onClick={(pick) => handleOnApllyProduct(pick)} onClose={() => setIsShowPickListModal(false)} />
      <BrandPickConfirmModal isShow={isShowConfirmModal} onClick={handleOnSaveProduct} onClose={() => setIsShowConfirmModal(false)} />
      <BrandPickContainer>
        <BrandTitleWrap>
          <Title>{t('BrandPage.brandPick.title', { lng: lang })}</Title>
          <Switch checked={isBrandPickEnabled} onChange={handleOnToggleBrandPick} />
        </BrandTitleWrap>
        <Button disabled={!isBrandPickEnabled} minWidth="100px" width="fit-content" borderRadius="4px" onClick={() => setIsShowPickListModal(true)}>
          {t('BrandPage.brandPick.addProduct', { lng: lang })}
        </Button>
        <BrandPickList>
          {brandPick.map((asset) => {
            const title = _.find(asset.i18ns, (item) => item.language === lang.toUpperCase())?.title;
            return (
              <BrandPickListItem key={asset.id}>
                <BrandPickListItemText>{asset.id}</BrandPickListItemText>
                <BrandPickListItemText>{title}</BrandPickListItemText>
                <BrandPickListItemCloseButton disabled={!isBrandPickEnabled} onClick={() => handleOnRemoveProduct(asset.id)}>
                  <CloseIcon />
                </BrandPickListItemCloseButton>
              </BrandPickListItem>
            );
          })}
        </BrandPickList>
        <BrandSaveWrap>
          <Button colorTheme="primary" onClick={() => setIsShowConfirmModal(true)} minWidth="71px" width="fit-content" marginLeft="12px" borderRadius="4px">
            {t('BrandPage.brandPick.save', { lng: lang })}
          </Button>
        </BrandSaveWrap>
      </BrandPickContainer>
    </>
  );
};

export default BrandPick;
