import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap, AuthContextType, AuthState } from 'types/auth';
import Auth, { AuthUser } from 'api/Auth';
import { ValidationError } from 'yup';
import { useApolloClient } from '@apollo/client';
import mixpanel from '../lib/mixpanel/mixpanel';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Refresh = 'REFRESH',
}

type AuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Refresh]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'REFRESH':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const client = useApolloClient();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.isLogin();

        if (!user) {
          throw new ValidationError('로그인이 되어있지 않음.');
        }

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } catch (err) {
        if (!(err instanceof ValidationError)) {
          // 로그 기록
          console.error(err);
        }

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const refresh = async () => {
    const userId = await Auth.refresh();

    if (!userId) {
      throw new ValidationError('유저 정보가 없습니다.');
    }

    const user = await Auth.isLogin();
    mixpanel.setUserProfile(user);

    dispatch({
      type: Types.Refresh,
      payload: {
        user,
      },
    });

    window.location.reload();
  };
  const login = async (id: string, password: string) => {
    const userId = await Auth.login(id, password);

    if (!userId) {
      throw new ValidationError('로그인에 실패하였습니다.');
    }

    const user = await Auth.isLogin();
    mixpanel.setUserProfile(user);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    client.clearStore();
    await Auth.logout();
    mixpanel.reset();
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        refresh,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
