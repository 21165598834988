import styled from 'styled-components';

export const Wrap = styled.div`
  margin: 0 auto;
  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : 'width: 592px;')}
  padding-top: 10px;
  padding-bottom: 120px;
  min-height: 707px;

  line-height: 20px;
  font-size: 14px;
  font-weight: 400;

  ${(props: { isDisp?: boolean }) =>
    props.isDisp
      ? `
        input[type=text] {
            background-color: #ccc;
        }
    `
      : ``}
`;

/* 제목 컴포넌트  */
type TitleType = { admin?: boolean; warning?: boolean };
export const Title = styled.div`
  font-weight: bold;
  position: relative;
  & > span {
    margin-top: 2px;
  }
  ${(props: TitleType) => (props.admin ? `color: #0A2173;` : ``)}
  ${(props: TitleType) => (props.warning ? `color: red;` : ``)}
`;

export const BrandTitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  position: relative;
`;

export const BrandSaveWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
