import { Box } from '@mui/material';
import React from 'react';
import { BrandPickModalLoadingContainer, pulse, alpha } from './BrandPickModalLoading.styles';
import { motion } from 'framer-motion/dist/framer-motion';

const BrandPickModalLoading = (): React.ReactElement => {
  return (
    <BrandPickModalLoadingContainer>
      <Box
        sx={{
          width: 120,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
        }}
      >
        <Box
          component={motion.div}
          sx={{
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: (theme) => `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease 0s infinite alternate`,
          }}
        />
        <Box
          component={motion.div}
          animate={{
            scale: [1, 0.75],
            opacity: [1, 0.25],
          }}
          transition={{
            duration: 0.4,
            repeatDelay: 0.2,
            repeat: Infinity,
          }}
          sx={{
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: (theme) => `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease .2s infinite alternate`,
          }}
        />
        <Box
          component={motion.div}
          animate={{
            scale: [1, 0.75],
            opacity: [1, 0.25],
          }}
          transition={{
            duration: 0.4,
            repeatDelay: 0.4,
            repeat: Infinity,
          }}
          sx={{
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: (theme) => `${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `${pulse} .4s ease .4s infinite alternate`,
          }}
        />
      </Box>
    </BrandPickModalLoadingContainer>
  );
};

export default BrandPickModalLoading;
