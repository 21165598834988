import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalContainer } from './FileSizeDialog.styles';

const FileSizeDialog = ({ isShow, close }): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <ModalContainer centered show={isShow}>
      <ModalBody>
        <Typography variant="body2" fontWeight={700} textAlign="center" fontSize={18}>
          {t('fileSizeLimit.falil.title')}
        </Typography>
        <Typography variant="body2" maxWidth="250px" textAlign="center" sx={{ wordBreak: 'keep-all' }}>
          {t('fileSizeLimit.falil.content')}
        </Typography>
        <Button color="primary" size="large" variant="contained" fullWidth sx={{ mt: 1 }} onClick={close}>
          {t('fileSizeLimit.falil.button')}
        </Button>
      </ModalBody>
    </ModalContainer>
  );
};

export default FileSizeDialog;
