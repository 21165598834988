import React from 'react';
import styled from 'styled-components';

export const TwinkleTicketContainer = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  padding: 32px 48px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: var(--background-light-paper, #fff);
  box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
`;
