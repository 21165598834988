import React from 'react';
import { Box } from '@mui/material';
import DesktopSettlementItem from '../SettlementItem/DesktopSettlementItem';
import { Expansion } from '../../sales-records/SalesRecordsList/SalesRecordsList.types';
import { useDesignSystemTheme, Typography } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import MobileSettlementItem from '../SettlementItem/MobileSettlementItem';
import { NoItemImage } from './SettlementList.styles';
import { GetBrandSettlementOrderExcelDocument, LanguageCodeEnum } from '../../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { asiaSeoulDayjs } from '../../../utils/timezone';
import dayjs from 'dayjs';
import { SettlementListProps } from './SettlementList.type';
import { checkIsExpectation } from './SettlementList.util';
import parse from 'html-react-parser';

export default function SettlementList({ list, country }: SettlementListProps) {
  const { current: today } = React.useRef(asiaSeoulDayjs(dayjs()));

  const [expansion, setExpansion] = React.useState<Expansion>({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false });
  const [exelDownloadLoading, setExelDownloadLoading] = React.useState(false);

  const theme = useDesignSystemTheme();

  const client = useApolloClient();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  function makeItemExpandHandler(idx: number) {
    return () => {
      if (expansion[idx]) setExpansion({ ...expansion, [idx]: false });
      else setExpansion({ ...expansion, [idx]: true });
    };
  }

  function makeExelButtonHandler(month: number, year: number) {
    return async () => {
      setExelDownloadLoading(true);
      try {
        const { data } = await client.query({
          query: GetBrandSettlementOrderExcelDocument,
          variables: {
            lang: language as LanguageCodeEnum,
            month,
            year,
          },
        });
        const url = data.getBrandSettlementOrderExcel;
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (e) {
        console.error(e, '정산 내역 엑셀 데이터 다운로드 중 오류가 발생하였습니다');
      }
      setExelDownloadLoading(false);
    };
  }

  return (
    <Box
      sx={{
        minHeight: '670px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        [theme.breakpoints.down('largeTablet')]: {
          backgroundColor: theme.palette['color/white'],
          padding: '16px 0',
          gap: '0px',
          minHeight: 'unset',
        },
      }}
    >
      {list.length === 0 ? (
        // no item
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            width: '100%',
            height: '738px',
            [theme.breakpoints.down('largeTablet')]: {
              gap: '16px',
              height: '193px',
            },
          }}
        >
          <NoItemImage src={'https://acon3d.blob.core.windows.net/public-images/partnerhub-sales-records-no-item.png'} alt={'partnerhub-settlement-no-item'} loading={'eager'} />
          <Typography variant={'typography/body/small/regular'} color={'color/gray/600'} sx={{ textAlign: 'center' }}>
            {parse(t('settlementPage.list.noItemMessage'))}
          </Typography>
        </Box>
      ) : (
        list.map((item, idx, { length }) => {
          const isExpectation = checkIsExpectation(country, today, item.year, item.month);

          return (
            <React.Fragment key={`${item.year}-${item.month}`}>
              <DesktopSettlementItem
                item={item}
                isExpectation={isExpectation}
                isExpanded={expansion[idx]}
                onItemExpand={makeItemExpandHandler(idx)}
                exelDownloadLoading={exelDownloadLoading}
                makeExelButtonHandler={makeExelButtonHandler}
              />
              <MobileSettlementItem
                item={item}
                isExpectation={isExpectation}
                isExpanded={expansion[idx]}
                onItemExpand={makeItemExpandHandler(idx)}
                isLastItem={idx === length - 1}
                exelDownloadLoading={exelDownloadLoading}
                makeExelButtonHandler={makeExelButtonHandler}
              />
            </React.Fragment>
          );
        })
      )}
    </Box>
  );
}
