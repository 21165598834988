import React from 'react';

export default function CloseIcon({ className }) {
  return (
    <div className={className}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6667 3.33334L3.33337 12.6667" stroke="white" stroke-width="1.33333" />
        <path d="M12.6667 12.6667L3.33337 3.33334" stroke="white" stroke-width="1.33333" />
      </svg>
    </div>

  );
}
