import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { LanguageCodeEnum } from 'generated/graphql';
import i18n from 'i18n/i18n';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PartnerTypeEnum } from 'signup/type';
import { companyNameState, userTypeState } from './atom';

export default function CompanyNameField() {
  const userType = useRecoilValue(userTypeState);
  const [companyName, setCompanyName] = useRecoilState(companyNameState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };

  if (i18n.language !== LanguageCodeEnum.Ko || userType === PartnerTypeEnum.Personal) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{'상호'}</Typography>
      <TextField size="small" sx={{ maxWidth: '240px' }} value={companyName} onChange={handleChange} />
    </Stack>
  );
}
