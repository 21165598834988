import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import RcSelect from 'react-select';

// 노드 정보 
export default props => {
    // const { t, i18n } = useTranslation()
    const { state, onChangeState, categoryStateList } = props

    // const userLang = i18n.language;
    const stateEl = useMemo(() => {
        var tempStateList = categoryStateList.map(d => {
            return {
                label: d.text,
                value: d.id
            }
        })
        var temp = {
            onChange: onChangeState,
            value: tempStateList.find(x => x.value === state),
            options: tempStateList
        }
        return < RcSelect
            {...temp}
        />
    }, [categoryStateList, state, onChangeState])
    return stateEl

}