import styled from 'styled-components';

export const DisplayInput = styled.div`
    width: 100%;
    min-height: 41px;
    padding: 9.5px 16px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    white-space:pre-wrap;
`;