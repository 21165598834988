import mixpanel from 'mixpanel-browser';
import { AuthUser } from 'api/Auth';
import { EventName } from './mixpanel.types';

mixpanel.init('3679f1a85abbb8392b3e14a70eba4459', { persistence: 'localStorage' });

export const event = (event_name: EventName, props: { [key: string]: any } = {}) => {
  try {
    if (mixpanel) mixpanel.track(event_name, props);
  } catch (e) {
    console.log(e);
  }
};

export const reset = () => {
  try {
    if (mixpanel) mixpanel.reset();
  } catch (e) {
    console.log(e);
  }
};

export const setSuperProperty = (props) => {
  try {
    if (mixpanel) mixpanel.register(props);
  } catch (e) {
    console.log(e);
  }
};

export const setUserProfile = (userInfo: AuthUser) => {
  try {
    if (mixpanel && userInfo && userInfo?.id) {
      mixpanel.identify(userInfo.id.toString());
      mixpanel.people.set({
        userId: userInfo.id,
        name: userInfo?.name || '',
        email: userInfo?.account || '',
        group: userInfo?.groupName || '',
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const increaseUserProperty = (propName: string, by: number) => {
  try {
    if (mixpanel) mixpanel.people.increment(propName, by);
  } catch (e) {
    console.log(e);
  }
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  event,
  setSuperProperty,
  setUserProfile,
  increaseUserProperty,
  reset,
};
