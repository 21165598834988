import React from 'react';
import EditorToolbarStyle from './EditorToolbarStyle';

// ----------------------------------------------------------------------

const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5'];

const TEXT_COLOR_LIST = ['#3376C5', '#EA3329'];
const TEXT_BG_LIST = ['#F8D548', '#C1DE9F', '#E2E2E2'];

export const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
];

type EditorToolbarProps = {
  id: string;
  isSimple?: boolean;
  isProductWrite?: boolean;
};

export default function EditorToolbar({ id, isSimple, isProductWrite, ...other }: EditorToolbarProps) {
  return (
    <EditorToolbarStyle {...other}>
      <div id={id}>
        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-header" defaultValue="">
              {HEADINGS.map((heading, index) => (
                <option key={heading} value={index + 1}>
                  {heading}
                </option>
              ))}
              <option value="">Normal</option>
            </select>
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button type="button" className="ql-bold" />
            <button type="button" className="ql-italic" />
            <button type="button" className="ql-underline" />
            <button type="button" className="ql-strike" />
          </div>
        )}

        {!isSimple && !isProductWrite && (
          <div className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </div>
        )}

        <div className="ql-formats">
          <button type="button" className="ql-list" value="ordered" />
          <button type="button" className="ql-list" value="bullet" />
          {!isSimple && !isProductWrite && (
            <>
              <button type="button" className="ql-indent" value="-1" />
              <button type="button" className="ql-indent" value="+1" />
            </>
          )}
        </div>

        {!isSimple && !isProductWrite && (
          <div className="ql-formats">
            <button type="button" className="ql-script" value="super" />
            <button type="button" className="ql-script" value="sub" />
          </div>
        )}

        {!isSimple && !isProductWrite && (
          <div className="ql-formats">
            <button type="button" className="ql-code-block" />
            <button type="button" className="ql-blockquote" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button type="button" className="ql-direction" value="rtl" />
            <select className="ql-align" defaultValue="">
              <option value="" />
              <option value="center" />
              <option value="right" />
            </select>
          </div>
        )}

        <div className="ql-formats">
          <button type="button" className="ql-link" />
          <button type="button" className="ql-image" />
          {!isSimple && <button type="button" className="ql-video" />}
        </div>

        {!isSimple && !isProductWrite && (
          <div className="ql-formats">
            <button type="button" className="ql-formula" />
            <button type="button" className="ql-clean" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <select
              className="ql-color"
              defaultValue=""
              style={{
                width: 'fit-content',
              }}
            >
              <option value="" />
              {TEXT_COLOR_LIST.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
            <select
              className="ql-background"
              defaultValue="transparent"
              style={{
                width: 'fit-content',
              }}
            >
              <option value="" />
              {TEXT_BG_LIST.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </EditorToolbarStyle>
  );
}
