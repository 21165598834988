import React, { useState, useEffect } from 'react';
import TableRow from './tableRow'
import Event from './event'

export default (function (props) {
    var { keyField, data, loading, Row, initFilter } = props

    const [filter, setFilter] = useState(initFilter ? initFilter : '')
    const [render, setRender] = useState(<></>)

    if (!Row) {
        Row = TableRow
    }

    useEffect(() => {
        if (data) {
            const event = Event(loading)
            const onChange = event.onChagne(setFilter)
            console.log(onChange)
            var temp = data.map(d => {
                var key = keyField ? d[keyField] : d
                return (<Row {
                    ...{ data: d, key, filter }
                }></Row>)
            })
            var input = <input onChange={onChange} value={filter}></input>
            setRender(<>
                <div className="inputArea">
                    {input}
                </div>
                <div className="listArea">
                    {temp}
                </div>
            </>)
        }
    }, [data, filter, setFilter, loading, keyField])

    return (
        <div className={'ddarkchuTable list'}>
            {render}
        </div>
    )
});

