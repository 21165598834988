import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app';
import { useTranslation } from 'react-i18next';
import { Page } from './style';
import { Tab, Tabs, Typography } from '@mui/material';
import { color } from 'acon-mui/style';
import General from './general';
import Enterprise from './enterprise';
import { useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { Information, Typography as DesignSystemTypo, useDesignSystemTheme } from 'carpenstreet-designsystem';
import parse from 'html-react-parser';

//msSaveBlob deprecate 된 문제 해결
declare global {
  interface Navigator {
    msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}
enum ESettleValue {
  GENERAL = 'general',
  ENTERPRISE = 'enterprise',
}

export default () => {
  const history = useHistory();
  const query = QueryString.parse(history?.location?.search);
  const { setBackgroundColor, userInfo } = useContext(AppContext);
  const { t } = useTranslation();
  const [settleValue, setSettleValue] = useState<ESettleValue>(query.settle === ESettleValue.ENTERPRISE ? ESettleValue.ENTERPRISE : ESettleValue.GENERAL);

  const theme = useDesignSystemTheme();

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: ESettleValue) => {
    query.settle = newValue;
    setSettleValue(newValue);
    history.push({ search: QueryString.stringify(query) });
  };

  useEffect(() => {
    // 배경 흰색으로 설정
    setBackgroundColor('white');

    return () => {
      setBackgroundColor('');
    };
  }, [setBackgroundColor]);

  return (
    <Page className="settle_page" isAdmin={userInfo.isAdmin}>
      <Information
        variant={'attention'}
        sx={{
          '& span': {
            color: theme.palette['color/gray/800'],
          },
        }}
        contents={
          <DesignSystemTypo
            variant={'typography/body/small/regular'}
            sx={{
              '& strong': {
                fontWeight: '700',
              },
            }}
          >
            {parse(t('oldSalesRecordsInfo'))}
          </DesignSystemTypo>
        }
      />

      <Typography fontSize="24px" lineHeight="1.5" fontWeight="700" color={color.text.primary} sx={{ marginTop: '40px' }}>
        {t('oldSalesRecords')}
      </Typography>

      <Tabs
        value={settleValue}
        onChange={handleOnChangeTab}
        sx={{
          mt: 2,
          mb: 5,
          minHeight: '42px',
          '& .Mui-selected': {
            color: `${color.primary} !important`,
          },
          '& .MuiTab-root': {
            px: 2,
            fontWeight: 500,
            letterSpacing: '0.4px',
            minHeight: '42px',
            '&:not(:last-of-type)': {
              marginRight: 0,
            },
          },
        }}
      >
        <Tab label={t('document.price.general')} value={ESettleValue.GENERAL} />
        <Tab label={t('document.price.enterpriseSub')} value={ESettleValue.ENTERPRISE} />
      </Tabs>

      {settleValue === ESettleValue.GENERAL && <General />}
      {settleValue === ESettleValue.ENTERPRISE && <Enterprise />}
    </Page>
  );
};
