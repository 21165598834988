import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import palette from 'theme/palette';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import TwinkleSale from './twinkle-sale';
import OpenRun from './openrun';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

enum EPromotionTitles {
  twinkleSale = 'twinkleSale',
  openrun = 'openrun',
}
const Promotion = (props): React.ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const parsedQuery = queryString.parse(props.history.location.search);
  const typeValue = parsedQuery.type as EPromotionTitles;
  const isValidType = Object.values(EPromotionTitles).includes(typeValue);

  const [value, setValue] = useState<EPromotionTitles>(isValidType ? typeValue : EPromotionTitles.twinkleSale);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: EPromotionTitles) => {
    setValue(newValue);
    if (newValue === EPromotionTitles.openrun) history.push(`/manager/promotion?type=${newValue}`);
    else history.push(`/manager/promotion`);
  };

  return (
    <div>
      <Typography variant="h4" color={palette.light.text.primary} mt={5} mb={2}>
        {t('twinkleSalePage.promotion')}
      </Typography>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('twinkleSalePage.title')} value={EPromotionTitles.twinkleSale} />
        <Tab label={t('openrunPage.title')} value={EPromotionTitles.openrun} />
      </Tabs>
      <TabContext value={value}>
        <Box width={'100%'}>
          <TabPanel value={EPromotionTitles.twinkleSale}>
            <Typography variant="body2" color="#FF4842" mt={1}>
              * {t('openrunPage.applyOpenrun.warning')}
            </Typography>
            <TwinkleSale />
          </TabPanel>
          <TabPanel value={EPromotionTitles.openrun}>
            <OpenRun />
          </TabPanel>
        </Box>
      </TabContext>
    </div>
  );
};
export default Promotion;
