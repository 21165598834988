import React, { Component, Fragment } from 'react';
import '../lib/global';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import './HTMLViewer.scss';


// title, body 가 지속적으로 변경 됨.
class HTMLViewer extends Component {
    constructor(props) {
        super(props);

        this.closeEditorModal = this.closeEditorModal.bind(this);
    }

    /// 모달창 
    closeEditorModal() {
        // 모달 닫기 
        this.props.close();
    }

    render() {
        const { show, title, contents } = this.props;
        let contentsJSX;
        try {
            contentsJSX = parse(contents ? contents : "");
        } catch (e) {
            console.log(e);
        }
        return (
            <Fragment>
                {/* 안내메세지 모달  */}
                <Modal show={show} onHide={this.closeEditorModal} className="modal__htmlViewer">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalText" >
                            {contentsJSX}
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default React.memo(HTMLViewer)