import { UnderButton } from '../../component/form';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileController } from 'api';
import Loading from 'common/editor/Loading';
import { useQuery } from '@apollo/client';
import { GET_APPLICATIONS, GET_EXTENSION_CONFIGS } from '../../../../api/quries';
import { AppContext } from 'app';
import { Box, Card, Stack } from '@mui/material';
import { File, FileUpload, InformationCard, Title } from 'acon-mui/components/Board';
import { APPLICATION_LIST, STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useHubDownloadQuery } from 'generated/graphql';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { FileStatus } from 'boards/DetailBoardWrite/stores/document.types';
import { FileConfigsList } from 'boards/DetailBoardWrite/style';
import parse from 'html-react-parser';
import { unzip } from 'unzipit';
import { Button, Divider, Typography, CheckMarkIcon, useDesignSystemTheme } from 'carpenstreet-designsystem';

type Props = {
  setIsInProgress?: (val: boolean) => void;
  isAccept: boolean;
  isAdmin?: boolean;
};

export default ({ setIsInProgress = (val: boolean) => {}, isAccept, isAdmin = false }: Props) => {
  // 번역도구
  const { t } = useTranslation();
  const theme = useDesignSystemTheme();

  const { showAlertMessage } = useContext(AppContext);
  const { isDisp, documentStatus, file, fileComponents, extensions, applications, setFile, setFileComponents, setApplications, setExtensions } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    documentStatus: state.status,
    file: state.file,
    fileComponents: state.fileComponents,
    applications: state.applications,
    extensions: state.extensions,
    setFile: state.setFile,
    setFileComponents: state.setFileComponents,
    setApplications: state.setApplications,
    setExtensions: state.setExtensions,
  }));

  const originalData = useOriginalDocumentStore((state) => ({
    id: state.id,
    file: state.file,
  }));

  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [status, setStatus] = useState<FileStatus>(null);
  const [fileSize, setFileSize] = useState<number>(0);
  const [progress, setProgress] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [cloneApplications, setCloneApplications] = useState<number[]>([]);
  const [cloneExtensions, setCloneExtensions] = useState<number[]>([]);

  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(documentStatus), [documentStatus]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isChecked, isDisp]);

  const [isShowLoading, setIsShowLoading] = useState(false);
  const { data: extensionConfigData, loading: extensionLoading } = useQuery(GET_EXTENSION_CONFIGS);
  const { data: applicationConfigData, loading: applicationLoading } = useQuery(GET_APPLICATIONS);

  // 확장자 리스트 정보 로딩여부 판단.
  const isLoading = !(
    !extensionLoading &&
    !applicationLoading &&
    extensionConfigData &&
    extensionConfigData.getExtensionConfigs &&
    applicationConfigData &&
    applicationConfigData.applications
  );

  const extensionsData = useMemo(() => {
    if (isLoading) return [];
    return extensionConfigData.getExtensionConfigs.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  }, [extensionConfigData, isLoading]);

  const applicationsData = useMemo(() => {
    if (isLoading) return [];
    return applicationConfigData.applications.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  }, [applicationConfigData, isLoading]);

  // 업로더
  const uploader = new fileController();
  // 파일 태그
  const fileTag = useRef<HTMLInputElement>(null);

  const onClick = () => {
    // 파일 태그 클릭
    fileTag.current.click();
  };
  // 파일 변경 이벤트 처리기 메소드
  const onChangeFile = async () => {
    // 파일
    const inputFile = fileTag.current?.files[0];

    if (inputFile.type.indexOf('zip') === -1) {
      showAlertMessage('', t('goods.modelDescription'), { confirmText: t('cancelConfirm') });
      return;
    }

    setFile({
      ...file,
      fileName: inputFile.name,
    });
    // 진행중
    setStatus('inprogress');
    setIsInProgress(true);
    setFileSize(inputFile?.size);

    const { key } = await uploader.upload(inputFile, (count) => {
      setProgress(count);

      // 완료된 경우
      if (count === 100) {
        // 진행 종료
        setStatus('success');
        setIsInProgress(false);
      }
    });
    const fileName = inputFile.name.replace(/\.[a-z0-9]{3,}$/, '');

    setFile({
      ...file,
      filePath: key,
      fileName: fileName.replace('.zip', '') + '.zip',
    });

    if (isAdmin) {
      await setModelFilesInZip(file, extensionsData);
    } else {
      await setModelFilesInZip(inputFile, extensionsData, applicationsData);
    }
  };

  const setModelFilesInZip = async (newFile, extensionList, applicationList = []) => {
    try {
      if (!newFile) return;
      else if (newFile.type.indexOf('zip') === -1) return;

      setIsShowLoading(true);

      const newFileConfigs = [];
      const newExtensions = [];
      const newApplications = [];

      const zipFile = await unzip(newFile);
      for (const file in zipFile.entries) {
        const target = zipFile.entries[file];
        if (target.isDirectory) continue;
        if (target.name.match(/^__MACOSX\//)) continue;

        let fileName = target.name;
        // 한글 깨지는 문제 해결 for windows
        if (fileName.includes('�')) {
          const decoder = new TextDecoder('euc-kr');
          fileName = decoder.decode(target.nameBytes);
        }

        const extension = '.' + target.name.split('.').pop()?.toLowerCase();
        const extensionId = extensionList.find((x: any) => x.label === extension)?.value;

        if (!extensionId) continue;

        const application = APPLICATION_LIST[extension.replace('.', '')] || [];
        const applicationId = application.map((app) => applicationList.find((item: any) => item.label === app)?.value);

        const fileNameWithoutExtension = fileName.split('/').pop()?.split('.').slice(0, -1).join('.');
        const fileSize = Math.round((zipFile.entries[file].size / 1024 / 1000) * 10) / 10;

        newFileConfigs.push({
          name: fileNameWithoutExtension + extension,
          size: fileSize < 1 ? 1 : fileSize,
        });

        newExtensions.push(extensionId);
        newApplications.push(...applicationId);
      }
      const filterApplications = [...(new Set(newApplications.filter((x) => x)) as any)];

      setFileComponents(newFileConfigs.map((x) => ({ name: x.name, size: x.size })));
      setExtensions(newExtensions);
      setApplications(filterApplications);

      setIsShowLoading(false);
    } catch (err) {
      showAlertMessage('', t('goods.autoFillExtentionAlert'), {
        confirmText: t('cancelConfirm'),
      });
      setStatus('fail');
      setIsShowLoading(false);
    }
  };

  const { data: hubDownload } = useHubDownloadQuery({
    variables: {
      hubDownloadId: originalData?.id,
    },
    skip: !originalData.id,
    fetchPolicy: 'no-cache',
  });

  // 다운로드 버튼 클릭 이벤트 처리기 메소드
  const onClickDownloadButtonTag = useCallback(() => {
    if (file.filePath.includes('tmp/')) {
      const fileInput = fileTag.current?.files[0];
      if (fileInput) {
        const blob = new Blob([fileInput], { type: 'application/zip' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } else {
      window.open(hubDownload?.hubDownload, '_blank');
    }
  }, [file.filePath, file.fileName, hubDownload]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setFile(originalData?.file);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  const onClearFileState = () => {
    setFile({ fileName: null, filePath: null });
    setFileComponents([]);
    setApplications([]);
    setExtensions([]);
  };

  const handleOnToggleFileConfigLabel = (id: number, type: 'extensions' | 'applications') => {
    // 확장자 라벨 클릭
    const set = new Set(type === 'extensions' ? extensions : applications);

    if (set.has(id)) set.delete(id);
    else set.add(id);

    if (type === 'extensions') setExtensions(Array.from(set));
    else setApplications(Array.from(set));
  };

  useEffect(() => {
    if (!isLoading && extensionsData.length && applicationsData.length) {
      /** extensions */
      // 파일 컴포넌트에서 확장자 추출, 기존 확장자와 합쳐 중복 및 undefined 제거
      const extensionNames = [
        ...new Set([
          ...extensions.map((item) => extensionsData.find((x) => x.value === item)?.label),
          ...fileComponents.map((item) => '.' + item.name.split('.').pop()?.toLowerCase()),
        ]),
      ].filter((x) => x);

      // 확장자 이름에서 확장자 ID로 변환하여 중복 제거 및 undefined 제거
      const extensionIds = extensionNames.map((item) => extensionsData.find((x) => x.label === item)?.value).filter((x) => x);

      /** applications */
      // 추출된 확장자이름을 기반으로 어플리케이션 추출, 기존 어플리케이션과 합쳐 중복 및 undefined 제거
      const applicationNames = [
        ...new Set([
          ...applications.map((app) => applicationsData.find((x) => x === app)?.label),
          ...extensionNames
            .map((ex) => APPLICATION_LIST[ex.replace('.', '')])
            .flat()
            .filter((x) => x),
        ]),
      ].filter((x) => x);

      // 어플리케이션 이름에서 어플리케이션 ID로 변환하여 중복 및 undefined 제거
      const applicationIds = applicationNames.map((item) => applicationsData.find((x) => x.label === item)?.value).filter((x) => x);

      // 모델의 확장자 및 사용프로그램 토글기능을 위한 세팅
      setCloneExtensions(extensionIds);
      setCloneApplications(applicationIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileComponents, extensionsData, applicationsData, isLoading]);

  return (
    <>
      {isShowLoading && <Loading />}
      <Box>
        <Box display="flex" alignItems="center" marginBottom="12px">
          <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('goods.model'))}>
            {t('goods.model')}
          </Title>
          {file.filePath ? (
            <UnderButton style={{ pointerEvents: 'all' }} onClick={onClickDownloadButtonTag}>
              {t('download')}
            </UnderButton>
          ) : (
            <></>
          )}
        </Box>

        <Card sx={{ ...(disabled && { pointerEvents: 'none' }), padding: 3 }}>
          {!fileComponents.length && !file.fileName && <FileUpload description={t('document.file.uploadDescription')} onChange={onChangeFile} onClick={onClick} ref={fileTag} />}
          {file.fileName && (
            <File
              status={status}
              fileName={`${file.fileName.replace('.zip', '')}.zip`}
              progress={status === 'success' && file.fileName ? 100 : progress}
              fileSize={fileSize}
              onDelete={onClearFileState}
            />
          )}
          <Stack pt={3} pl={2} direction="column" gap="12px">
            {fileComponents.length && file.fileName ? (
              <Stack gap="4px">
                <Title fontSize={14}>{t('document.file.fileContentsAndSize')}</Title>
                <FileConfigsList>
                  {fileComponents.map((file) => (
                    <li key={`${file.name}/${file.size}`}>
                      {file.name}
                      <span>{file.size}MB</span>
                    </li>
                  ))}
                </FileConfigsList>
              </Stack>
            ) : null}
            <Divider sx={{ paddingTop: 3, paddingBottom: 1 }} />
            {cloneExtensions.length ? (
              <Stack gap="4px">
                <Title fontSize={14}>{t('document.file.extensions')}</Title>
                <FileConfigsList isFlex={true} gap={8}>
                  {cloneExtensions.map((ex) => {
                    const result = extensionsData.find((x) => x.value === ex);
                    if (!result) return null;
                    return (
                      <li key={ex}>
                        <Button
                          size="S"
                          variant="outlined"
                          color={extensions.includes(ex) ? 'primary' : 'gray'}
                          startIcon={extensions.includes(ex) ? <CheckMarkIcon color="color/gray/800" height={16} width={16} /> : null}
                          sx={{ background: theme.palette['color/white'] }}
                          onClick={() => handleOnToggleFileConfigLabel(ex, 'extensions')}
                        >
                          <Typography variant="typography/label/large/bold" color={`${extensions.includes(ex) ? 'color/primary/600' : 'color/gray/600'}`}>
                            {result.label}
                          </Typography>
                        </Button>
                      </li>
                    );
                  })}
                </FileConfigsList>
              </Stack>
            ) : null}
            {cloneApplications.length ? (
              <Stack gap="4px">
                <Title fontSize={14}>{t('document.file.compatibleSoftware')}</Title>
                <FileConfigsList isFlex={true} gap={8}>
                  {cloneApplications.map((app) => {
                    const result = applicationsData.find((x) => x.value === app);
                    if (!result) return null;
                    return (
                      <li key={app}>
                        <Button
                          size="S"
                          variant="outlined"
                          color={applications.includes(app) ? 'primary' : 'gray'}
                          startIcon={applications.includes(app) ? <CheckMarkIcon color="color/gray/800" height={16} width={16} /> : null}
                          sx={{ background: theme.palette['color/white'] }}
                          onClick={() => handleOnToggleFileConfigLabel(app, 'applications')}
                        >
                          <Typography variant="typography/label/large/bold" color={`${applications.includes(app) ? 'color/primary/600' : 'color/gray/600'}`}>
                            {result.label}
                          </Typography>
                        </Button>
                      </li>
                    );
                  })}
                </FileConfigsList>
              </Stack>
            ) : null}
          </Stack>
          <InformationCard type="warning" marginTop={'24px'}>
            {parse(t('document.file.notice.fileName'))}
          </InformationCard>
        </Card>
      </Box>
    </>
  );
};
