import styled from 'styled-components';
import { fileSize } from './constants';

export const CroppperBox = styled.div`
  ${(props: { image?: boolean | string; drag?: boolean | string; crop?: any }) =>
    props.image
      ? `
        & > div {
            width: 100%;
            height: 100%;
        }
    `
      : ``}
  ${(props: { image?: boolean | string; drag?: boolean | string; crop?: any }) => (props.drag ? `border: 2px solid #0094FF;` : `border: 1px solid #CDCDCD;`)}
    img {
    ${(props: { image?: boolean | string; drag?: boolean | string; crop?: any }) => (props.drag ? `opacity: 0.1;` : ``)}
  }

  position: relative;
  overflow: hidden;
  padding-top: calc(100% / (${fileSize[0] / fileSize[1]}));
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  .cropper-center {
    display: none;
  }

  .cropper-point.point-nw {
    left: -6px;
    top: -6px;
  }
  .cropper-point.point-ne {
    right: -6px;
    top: -6px;
  }
  .cropper-point.point-sw {
    bottom: -6px;
    left: -6px;
  }
  .cropper-point.point-se {
    bottom: -6px;
    right: -6px;
  }
  .cropper-point {
    &.point-nw,
    &.point-ne,
    &.point-sw,
    &.point-se {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      opacity: 1;
      display: block !important;
    }
  }

  .cropper-line,
  .cropper-point {
    background-color: #c7c7c7;
    display: none;
  }

  .cropper-view-box {
    outline: 3px solid #c7c7c7;
    outline-color: #c7c7c7;
  }
  .cropper-disabled {
    user-select: none;
    .cropper-view-box {
      outline: 3px solid #19b400;
      outline-color: #19b400;
    }
    .cropper-line,
    .cropper-point {
      background-color: #19b400;
    }
  }
`;
export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 3.5px;
  padding-right: 3.5px;
  ${(props: { active?: boolean }) => (!props.active ? 'display: none;' : '')}
`;

export const DragAndDrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
`;
