import React, { useMemo, useRef } from 'react';
import parse from 'html-react-parser';
import './ConfirmAlertModal.scss';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { color } from 'acon-mui/style';
import { Button } from 'acon-mui/components';

export default React.memo(function (props) {
  let {
    cancelText,
    confirmText,
    title,
    contents,
    onClose,
    onConfirm,
    show,
    btnType,
    alignCenter,
    textType,
    customBody,
    popupId,
    showButtons,
    showTextField,
    textFieldPlaceholder,
    textFieldRows,
    textFieldRequired,
  } = props;

  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [input, setInput] = React.useState('');
  const isConfirmButtonDisabled = useMemo(() => showTextField && textFieldRequired && !input, [showTextField, textFieldRequired, input]);

  const Body =
    customBody ||
    (() => {
      return <>{parse(contents)}</>;
    });

  const cancelBtn = cancelText ? (
    <Button onClick={onClose} width="170px" height="48px" marginRight="12px">
      {t(cancelText)}
    </Button>
  ) : null;

  const onClickConfirmButton = () => {
    if (inputElement) inputElement.checked = false;
    if (showTextField && inputRef && inputRef.current) {
      onConfirm(inputRef.current.value);
    } else {
      onConfirm();
    }
  };

  const confirmBtn = confirmText ? (
    <Button
      colorTheme="primary"
      variant="contained"
      onClick={onClickConfirmButton}
      disabled={isConfirmButtonDisabled}
      width={!cancelText ? '100%' : '170px'}
      height="48px"
      padding="6px 8px"
    >
      {t(confirmText)}
    </Button>
  ) : null;

  alignCenter = alignCenter ? 'alignCenter' : '';

  textType = textType || '';

  const className = 'confirmAlertModal ' + alignCenter + ' ' + textType;

  const onClickHidePopup = (event) => {
    if (popupId) {
      const popupCache = JSON.parse(localStorage.getItem('popups_hide_cache')) || {};
      if (event.target.checked) {
        popupCache[popupId] = new Date().getTime();
        localStorage.setItem('popups_hide_cache', JSON.stringify(popupCache));
      } else {
        popupCache[popupId] = null;
        localStorage.setItem('popups_hide_cache', JSON.stringify(popupCache));
      }
    }
  };

  let inputElement;

  const allowHide = popupId ? (
    <div className="allowHide-container">
      <span>{t('allowHide')}</span>
      <input
        type="checkbox"
        onClick={onClickHidePopup}
        ref={(e) => {
          inputElement = e;
        }}
      />
    </div>
  ) : null;

  return (
    <Dialog
      className={className}
      open={show}
      onClose={onClose}
      transitionDuration={0}
      componentsProps={{
        backdrop: {
          style: {
            background: 'rgba(0, 0, 0, 0.4)',
          },
          onClick: onClose,
        },
      }}
    >
      <Box p={3} minWidth="400px" bgcolor="#fff" borderRadius="16px" boxShadow="-40px 40px 80px -8px rgba(145, 158, 171, 0.24)" textAlign="center" color={color.text.primary}>
        <Typography fontWeight="700" fontSize="18px" lineHeight="28px">
          {title}
        </Typography>
        <Typography mt={2} fontSize="14px" lineHeight="20px" letterSpacing="0.17px">
          <Body />
        </Typography>
        {showTextField && (
          <TextField
            inputRef={inputRef}
            placeholder={textFieldPlaceholder}
            fullWidth
            {...(textFieldRows && {
              multiline: true,
              rows: textFieldRows,
              onKeyPress: (e) => {
                const numberOfLines = e.target.value.split('\n').length + 1;
                if (numberOfLines > textFieldRows) {
                  const newVal = e.target.value.split('\n').slice(0, textFieldRows);
                  e.target.value = newVal.join('\n');
                }
              },
            })}
            onChange={(e) => setInput(e.target.value)}
            sx={{ mt: 2 }}
            inputProps={{ maxLength: 1000 }}
          />
        )}
        {showButtons && (
          <Box className={btnType} mt={3} display="flex" justifyContent="center" alignItems="center">
            {cancelBtn}
            {confirmBtn}
            {allowHide}
          </Box>
        )}
      </Box>
    </Dialog>
  );
});
