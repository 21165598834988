import React, { useMemo, useState, createContext } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ConfirmModal.scss';

// 확인 및 취소가 존재하는 confirm 모달입니다.
export const ConfirmContext = createContext()
export default function (props) {
    const { t } = useTranslation()
    const { confirmModalStatus } = props
    const [status, setStatus] = useState(confirmModalStatus)
    function openConfirm({
        title,
        body,
        confirmCallback,
        closeCallback
    }) {
        setStatus({
            title,
            body,
            confirmCallback,
            closeCallback,
            isShow: true
        })
    }
    return useMemo(() => {
        function close() {
            setStatus(prevStatus => ({
                ...prevStatus,
                isShow: false
            }))
        }
        function onConfirm() {
            if (status.confirmCallback && typeof status.confirmCallback === 'function') {
                status.confirmCallback()
            }
            close()
        }
        function onClose() {
            close()
            if (status.closeCallback && typeof status.closeCallback === 'function') {
                status.closeCallback()
            }
        }
        const show = status && status.isShow ? status.isShow : false
        const title = status && status.title ? status.title : ''
        const body = status && status.body ? status.body : ''
        return (
            <ConfirmContext.Provider value={openConfirm}>
                <Modal className="confirmModal" show={show} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>
                            {t('close')}
                        </Button>
                        <Button variant="primary" onClick={onConfirm}>
                            {t('confirm')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {props.children}
            </ConfirmContext.Provider>
        )
    }, [status, setStatus, t, props.children])
}

