import { Box, SelectChangeEvent } from '@mui/material';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';
import { Categories_Input_Sort_Criterion, Categories_Input_Sort_Key, LanguageCodeEnum, Language_Code, useCategoriesV2Query } from 'generated/graphql';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CategorySelect from './CategorySelect';
import { useParams } from 'react-router-dom';

const primaryCategoryCodes = ['334', '335', '336', '337', '338', '339'];

export default function CategoriesRow({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum }>();
  const { categories, setCategories } = useDocumentStore((state) => ({
    categories: state.categories,
    setCategories: state.setCategories,
  }));

  const { data, loading, refetch } = useCategoriesV2Query({
    variables: {
      language: lang.toLocaleUpperCase() as Language_Code,
      condition: { codes: primaryCategoryCodes },
      sort: {
        criterion: Categories_Input_Sort_Criterion.Asc,
        key: Categories_Input_Sort_Key.Code,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const primaryVal = useMemo(
    () => (categories && categories[0]?.code.length >= 3 && primaryCategoryCodes.find((x) => x === categories[0]?.code.slice(0, 3)) ? categories[0]?.code.slice(0, 3) : undefined),
    [categories],
  );
  const secondaryVal = useMemo(() => (primaryVal && categories && categories[0]?.code.length >= 6 ? categories[0]?.code.slice(0, 6) : undefined), [categories, primaryVal]);
  const tertiaryVal = useMemo(() => (secondaryVal && categories && categories[0]?.code.length === 9 ? categories[0]?.code.slice(0, 9) : undefined), [categories, secondaryVal]);

  const primaryOptions = useMemo(() => data?.categoriesV2 || undefined, [data]);
  const secondaryOptions = useMemo(() => (primaryOptions ? primaryOptions.find((x) => x.code === primaryVal)?.children : undefined), [primaryOptions, primaryVal]);
  const tertiaryOptions = useMemo(() => (secondaryOptions ? secondaryOptions.find((x) => x.code === secondaryVal)?.children : undefined), [secondaryOptions, secondaryVal]);

  const handleChangeCategory = (event: SelectChangeEvent<string>, order: 'primary' | 'secondary' | 'tertiary') => {
    if (order === 'primary') {
      const selected = primaryOptions?.find((x) => x.code === event.target.value);
      selected && setCategories([{ code: selected?.code, children: selected?.children }]);
    } else if (order === 'secondary') {
      const selected = secondaryOptions?.find((x) => x.code === event.target.value);
      selected && setCategories([{ code: selected?.code, children: selected?.children }]);
    } else if (order === 'tertiary') {
      const selected = tertiaryOptions?.find((x) => x.code === event.target.value);
      selected && setCategories([{ code: selected?.code }]);
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Box mt={1.5} display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="12px" alignItems="center">
      <CategorySelect
        label={t('document.categories.primary')}
        value={primaryVal}
        options={primaryOptions}
        disabled={disabled}
        loading={loading}
        onChange={handleChangeCategory}
        order="primary"
      />
      <CategorySelect
        label={t('document.categories.secondary')}
        value={secondaryVal}
        options={secondaryOptions}
        disabled={disabled}
        loading={loading}
        onChange={handleChangeCategory}
        order="secondary"
      />
      <CategorySelect
        label={t('document.categories.tertiary')}
        value={tertiaryVal}
        options={tertiaryOptions}
        disabled={disabled}
        loading={loading}
        onChange={handleChangeCategory}
        order="tertiary"
      />
    </Box>
  );
}
