import { useContext } from 'react'
import { updateCategory } from '../ajax'
import { AppContext } from '../../../app'

// 노드 정보 
export default ((t, setCategoryData, setIsLoading) => {
    const { showConfirmMessage, categoryData } = useContext(AppContext)

    function onChangeType(selectedOption) {
        if (selectedOption && selectedOption?.value) {
            const value = selectedOption.value;
            
            setCategoryData(prev => {
                return {
                    ...prev,
                    type: value,
                }
            })
        }
    }
    function onChangeState(selectedOption) {
        if (selectedOption) {
            var temp = selectedOption.value
            setCategoryData(prev => {
                return { ...prev, state: temp }
            })
        }
    }
    function onSave(categoryData) {
        return e => {
            const confirmCallback = async () => {
                var { id, memo, parent, state, type, i18n } = JSON.parse(JSON.stringify(categoryData))
                i18n = i18n.map(d => {
                    if (d.map) {
                        d.map = d.map.replaceAll("“", "\"").replaceAll("”", "\"");
                        if (!d.map.includes("name=")) {
                            d.map = d.map.replace("<map", "<map name=\"eventBanner\"");
                        }
                    }
                    if (d?.file) {
                        d.file = {
                            key: d.file?.key
                        }
                    }
                    return {
                        name: d.name,
                        anchor: d.anchor,
                        categoryId: d.categoryId,
                        color: d.color === 0? null : d.color,
                        file: d.file,
                        isBlankAnchor: d.isBlankAnchor,
                        isShowResultCount: d.isShowResultCount,
                        lang: d.language.code,
                        map: d.map,
                        creator: null,
                        updater: null,
                    };
                });

                await updateCategory({ id, memo, parent, state, type, i18n });
                setIsLoading(false);
            }
            showConfirmMessage(
                t("categoryManager.confirm.save.title"),
                t("categoryManager.confirm.save.content"),
                confirmCallback
            )
        }
    }
    function onChangeName(lang) {
        return (e) => {
            e.preventDefault()
            const name = e.currentTarget.value
            setCategoryData(prev => {
                var temp = prev.trans.find(d => {
                    return d.lang === lang ? d : null
                })
                temp.name = name
                console.log(prev)
                return { ...prev, trans: [...prev.trans] }
            })
        }
    }
    function onChangeMemo(e) {
        e.preventDefault()
        const memo = e.currentTarget.value
        setCategoryData(prev => {
            prev.memo = memo
            return { ...prev }
        })
    }
    return {
        onChangeType,
        onChangeState,
        onSave,
        onChangeName,
        onChangeMemo
    }

})