import React from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { LanguageCodeEnum, useGetIsDuplicateBrandNameQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { brandNameDuplicateState, brandNameState, originalBrandNameState } from './atom';

const penNameGroupKo = [
  {
    language: LanguageCodeEnum.Ko,
    label: 'korean',
  },
  {
    language: LanguageCodeEnum.En,
    label: 'english',
  },
  {
    language: LanguageCodeEnum.Zh,
    label: 'chinese',
  },
  {
    language: LanguageCodeEnum.Ja,
    label: 'japanese',
  },
];

const penNameGroupOversea = [
  {
    language: LanguageCodeEnum.En,
    label: 'english',
  },
  {
    language: LanguageCodeEnum.Zh,
    label: 'chinese',
  },
  {
    language: LanguageCodeEnum.Ko,
    label: 'korean',
  },
  {
    language: LanguageCodeEnum.Ja,
    label: 'japanese',
  },
];

export default function PenNameField() {
  const { t, i18n } = useTranslation();
  const [brandNames, setBrandNames] = useRecoilState(brandNameState);
  const originalBrandNames = useRecoilValue(originalBrandNameState);
  const [isDuplicatedBrandName, setIsDuplicatedBrandName] = useRecoilState(brandNameDuplicateState);
  const { refetch } = useGetIsDuplicateBrandNameQuery({
    variables: {
      lang: i18n.language === 'ko' ? 'ko' : 'en',
      name: i18n.language === 'ko' ? brandNames['ko'] : brandNames['en'],
    },
    skip: true,
  });

  const penNameGroup = i18n.language === LanguageCodeEnum.Ko ? penNameGroupKo : penNameGroupOversea;

  const getHandleChange = (language: LanguageCodeEnum) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setBrandNames((prev) => {
        return {
          ...prev,
          [language]: e.target.value,
        };
      });
    };
  };
  const onBlur = async (lang, name) => {
    if (!name) {
      setIsDuplicatedBrandName({
        ko: false,
        en: false,
        zh: false,
        ja: false,
      });
    } else {
      if (originalBrandNames[lang] === name) {
        setIsDuplicatedBrandName((prev) => {
          return {
            ...prev,
            [lang]: false,
          };
        });
        return;
      }
      const { data, error } = await refetch({ lang, name });
      const isDuplicated = data.isDuplicateBrandName;
      setIsDuplicatedBrandName((prev) => {
        return {
          ...prev,
          [lang]: isDuplicated,
        };
      });
      if (error) {
        console.error(error);
      }
    }
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle2">{t('signupPage.creatorName')}</Typography>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="8px" width="100%">
        {penNameGroup.map((x, i) => (
          <Stack
            spacing={0.5}
            key={`penname_${x.label}}`}
            sx={{
              maxWidth: '240px',
              width: '100%',
              ...(i % 2 === 1 && {
                justifySelf: 'end',
              }),
            }}
          >
            <Typography fontWeight={700} color="text.secondary" fontSize="14px">
              {t(`signupPage.label.${x.label}`)}{' '}
              <Typography component="span" color="#7c7c7c" fontWeight={400} fontSize="14px">{`(${i18n.language === x.language ? t('require') : t('optional')})`}</Typography>
            </Typography>
            <TextField
              size="small"
              value={brandNames[x.language]}
              onChange={getHandleChange(x.language)}
              fullWidth
              sx={{ maxWidth: '240px' }}
              onBlur={() => onBlur(x.language, brandNames[x.language])}
              error={isDuplicatedBrandName[x.language]}
              helperText={isDuplicatedBrandName[x.language] ? t('signupPage.isExistsPenName') : ''}
              FormHelperTextProps={{
                sx: {
                  margin: '2px 0 0 0',
                },
              }}
            />
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}
