import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomScrollDiv from 'components/scroll';

function getImageSize(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();
    img.src = imageUrl;
  });
}

const Styled = {
  ImageWrapper: styled.div`
    margin-bottom: -1px;
    background-color: #fff;
    width: 420px;
    max-height: 420px;
  `,
};

export default function ModalImage({ src, borderRadius }) {
  const [imageHeight, setImageHeight] = useState(0);

  const setModalImageHeight = async () => {
    const { width, height } = await getImageSize(`https://storage.acon3d.com/${src}`);

    setImageHeight(height / (width / 420));
  };

  useEffect(() => {
    setModalImageHeight();
  }, [src]);

  const borderRadiusStyle = {
    borderTopLeftRadius: `8px`,
    borderTopRightRadius: `8px`,
    ...(borderRadius === 'all' && {
      borderBottomLeftRadius: `8px`,
      borderBottomRightRadius: `8px`,
    }),
  };

  return (
    <Styled.ImageWrapper style={borderRadiusStyle}>
      {imageHeight > 420 && (
        <CustomScrollDiv
          trigger={imageHeight}
          style={{
            ...borderRadiusStyle,
            height: imageHeight > 420 ? 420 : imageHeight,
          }}
        >
          <img style={{ width: '100%' }} src={`https://storage.acon3d.com/${src}`} />
        </CustomScrollDiv>
      )}
      {imageHeight <= 420 && (
        <img style={{
          ...borderRadiusStyle,
          width: '100%'
        }} src={`https://storage.acon3d.com/${src}`} />
      )}
    </Styled.ImageWrapper>
  );
}
