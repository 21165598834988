import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed; 
  top: 0; 
  left: 0;    
  z-index: 99999; 
  transition: opacity 1s; 
  opacity: 0;
  
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  color: #333;
  text-align: center;
  background: #fdfad8;


  ${(props) =>
    props.show &&
    `
      opacity: 1;
    `}

  a {
    margin-left: 10px;
    font-size: 10px;
  }

  div {
    position: absolute;
    top: 0;
    right: 18px;
    height: 35px;
    line-height: 35px;
    font-size: 10px;
  }

  div img {
    margin-left: 15px;
    vertical-align: baseline;
    cursor: pointer;
  }
`;

export default Wrapper;