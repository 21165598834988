import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'acon-mui/components/Board';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import TagsSearch from './TagsSearch';
import { LanguageCodeEnum, Language_Code, Tags_Input_Sort_Criterion, Tags_Input_Sort_Key, useDocumentTagsQuery } from 'generated/graphql';
import { throttle } from 'lodash';
import { TDocumentTag } from 'boards/DetailBoardWrite/stores/document.types';
import { useParams } from 'react-router-dom';

export default () => {
  // 번역도구
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum }>();
  const { id, isDisp, status, tags, setTags } = useDocumentStore((state) => ({
    id: state.id,
    isDisp: state.isDisp,
    status: state.status,
    tags: state.tags,
    setTags: state.setTags,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    tags: state.tags,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [input, setInput] = useState(null);
  const [queryVariableInput, setQueryVariableInput] = useState(null); // 검색어
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const [skipGetTranslatedTag, setSkipGetTranslatedTag] = useState(false);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const { data } = useDocumentTagsQuery({
    variables: {
      language: lang.toLocaleUpperCase() as Language_Code,
      pagination: {
        limit: 10,
        page: 1,
      },
      condition: {
        name: queryVariableInput,
      },
      sort: {
        criterion: Tags_Input_Sort_Criterion.Asc,
        key: Tags_Input_Sort_Key.Id,
      },
    },
    // input 태그가 비어있지 않을 때만 호출
    skip: !queryVariableInput || queryVariableInput === '',
  });
  // 언어 바꿀 때 태그 다시 설정하도록 변경
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const translatedTag = useDocumentTagsQuery({
    variables: {
      language: lang.toLocaleUpperCase() as Language_Code,
      pagination: {
        limit: 10,
        page: 1,
      },
      condition: {
        ids: tags.map((item) => item.id),
      },
      sort: {
        criterion: Tags_Input_Sort_Criterion.Asc,
        key: Tags_Input_Sort_Key.Id,
      },
    },
    fetchPolicy: 'no-cache',
    skip: skipGetTranslatedTag,
    onCompleted: (res) => {
      setTags(res.tags.data.map((x) => ({ id: x.id, name: x.name })));
    },
  });

  const tagsData = data?.tags.data.map((x) => ({ id: x.id, name: x.name })) || [];

  const throttledSetQueryVariableInput = useRef(throttle(setQueryVariableInput, 500)).current;
  const updateQueryVariableInput = (val: string) => {
    setInput(val);
    throttledSetQueryVariableInput(val);
  };

  // input 태그 변경 이벤트 처리기 메소드
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf('\n') > -1 || e.target.value.includes(' ')) return false;
    updateQueryVariableInput(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent, val: string) => {
    if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
      const target = tagsData.find((x) => x.name === val);
      if (input === null || input === '' || input === ' ' || !target) {
        updateQueryVariableInput('');
        return false;
      }
      handleAddTag(target);
      return false;
    }
  };
  const handleFocus = () => {
    setErrorMessage(null);
  };

  const handleAddTag = (val: TDocumentTag) => {
    setSkipGetTranslatedTag(true); // 언어 바꿀 때 태그 다시 설정하도록 변경하는 쿼리 skip하게 하는 코드
    if (tags.find((x) => x.name === (val.name || input))) {
      setErrorMessage(t('document.tags.duplicateError'));
      updateQueryVariableInput('');
      return;
    }
    if (tags.length >= 10) {
      setErrorMessage(t('document.tags.limitError'));
      updateQueryVariableInput('');
      return;
    }
    setErrorMessage(null);
    updateQueryVariableInput('');
    setTags([...tags, val].sort((a, b) => a.id - b.id));
  };

  const handleChecked = (checked, val) => {
    setErrorMessage(null);
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setTags(originalData?.tags);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  useEffect(() => {
    setSkipGetTranslatedTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Box>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.tags.title'))}>
        {t('document.tags.setTags')}
      </Title>
      <TagsSearch input={input} disabled={disabled} tagsData={tagsData} onAddTag={handleAddTag} onKeyDown={onKeyDown} onFocus={handleFocus} onChangeInput={onChangeInput} />
      {errorMessage && (
        <Box px={1.5} mt={1}>
          <Typography fontSize="12px" lineHeight="18px" color="#FF5630">
            {errorMessage}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" pt={0.5} px={2}>
        {tags.map((tag, i) => (
          <Box key={`tag_${tag.id}`} mt={1} mr={1} p="3px 5px 3px 10px" display="flex" alignItems="center" borderRadius="99px" bgcolor="#00000014">
            <Typography mr={1} color="#000000DE" fontSize="13px" lineHeight="18px" letterSpacing="0.16px">
              {tag.name}
            </Typography>
            <Box
              {...(!disabled && {
                onClick: () => {
                  const newTags = tags.filter((x) => x.id !== tag.id);
                  setTags(newTags);
                  setErrorMessage(null);
                },
                sx: { cursor: 'pointer' },
              })}
              display="inherit"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.99998 0.333252C3.31331 0.333252 0.333313 3.31325 0.333313 6.99992C0.333313 10.6866 3.31331 13.6666 6.99998 13.6666C10.6866 13.6666 13.6666 10.6866 13.6666 6.99992C13.6666 3.31325 10.6866 0.333252 6.99998 0.333252ZM10.3333 9.39325L9.39331 10.3333L6.99998 7.93992L4.60665 10.3333L3.66665 9.39325L6.05998 6.99992L3.66665 4.60659L4.60665 3.66659L6.99998 6.05992L9.39331 3.66659L10.3333 4.60659L7.93998 6.99992L10.3333 9.39325Z"
                  fill="black"
                  fillOpacity="0.23"
                />
              </svg>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
