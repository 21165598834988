import React from 'react';
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Divider,
  DownloadIcon,
  IconButton,
  InformationOutlineIcon,
  Label,
  Tooltip,
  Typography,
  useDesignSystemTheme,
} from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import { Box, Collapse } from '@mui/material';
import { SettlementItemProps } from './SettlementItem.types';
import parse from 'html-react-parser';
import { asiaSeoulDayjs } from 'utils/timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PLAN_TYPE } from '../../../user/value/PlanType';

dayjs.extend(customParseFormat);

export default function MobileSettlementItem({ item, isExpectation, isExpanded, onItemExpand, isLastItem, exelDownloadLoading, makeExelButtonHandler }: SettlementItemProps) {
  const theme = useDesignSystemTheme();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      sx={{
        display: 'none',
        [theme.breakpoints.down('largeTablet')]: {
          display: 'block',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '4px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              variant={'typography/body/medium/bold'}
              sx={{
                padding: '4px 0',
              }}
            >
              {asiaSeoulDayjs(dayjs(`${item.year}-${item.month}`, 'YYYY-M')).format(language === 'ko' ? 'YYYY년 M월' : 'YYYY MMM')}
            </Typography>
            {isExpectation && (
              <Label variant={'soft'} color={'primary'}>
                {t('settlementPage.list.expectation.label')}
              </Label>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Typography variant={'typography/title/medium/bold'} color={isExpectation ? 'color/primary/600' : 'color/gray/800'}>
              {language === 'ko' ? `${item.resultAmount.toLocaleString()}원` : `$${Number((item.resultAmount / item.exchangeRate).toFixed(2)).toLocaleString()}`}
            </Typography>
            <IconButton size={'M'} onClick={onItemExpand}>
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          </Box>
        </Box>
        {isExpectation && (
          <Box>
            <Typography variant={'typography/label/large/bold'}>{parse(t('settlementPage.list.expectation.description'))}</Typography>
          </Box>
        )}
      </Box>

      {!isLastItem && !isExpanded && <Divider sx={{ margin: '8px 0' }} />}

      <Collapse in={isExpanded}>
        <Box
          sx={{
            padding: '16px',
            backgroundColor: theme.palette['color/gray/50'],
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              width: '100%',
            }}
          >
            <Typography variant={'typography/body/small/bold'}>{t('settlementPage.list.detail.info.title')}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                width: '100%',
              }}
            >
              {/*  판매 기간  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.sellingPeriod')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{`${asiaSeoulDayjs(item.since).format('YYYY-MM-DD')} ~ ${asiaSeoulDayjs(item.until).format(
                  'YYYY-MM-DD',
                )}`}</Typography>
              </Box>
              {/*  정산 확정 기간  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.settlementConfirmationPeriod.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.info.settlementConfirmationPeriod.tooltip')} direction={'bottom-left'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>{`${asiaSeoulDayjs(item.settleSince).format('YYYY-MM-DD')} ~ ${asiaSeoulDayjs(item.settleUntil).format(
                  'YYYY-MM-DD',
                )}`}</Typography>
              </Box>
              {/*  입점 정보  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.contract')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{`${PLAN_TYPE({ type: item.planType, isExclusive: item.isExclusive, t })} ${
                  item.aconCommission * 100
                }%`}</Typography>
              </Box>
              {/*  판매  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.soldAmount')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{language === 'ko' ? item.saleCount.toLocaleString() + '건' : item.saleCount.toLocaleString()}</Typography>
              </Box>
              {/*  Exchange rate for selling KRW  */}
              {language !== 'ko' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.exchangeRate.krw')}</Typography>
                  <Typography variant={'typography/label/large/bold'}>$1=₩1,000</Typography>
                </Box>
              )}
              {/*  달러 판매 적용 환율  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.info.exchangeRate.dollar.label')}</Typography>
                  {language === 'ko' && (
                    <Tooltip title={t('settlementPage.list.detail.info.exchangeRate.dollar.tooltip')} direction={'bottom-left'}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InformationOutlineIcon width={12} height={12} />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Typography variant={'typography/label/large/bold'}>{`$1 = ₩${item.exchangeRate.toLocaleString()}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <Typography variant={'typography/body/small/bold'}>{t('settlementPage.list.detail.amount.title.label')}</Typography>
              <Tooltip title={t('settlementPage.list.detail.amount.title.tooltip')} direction={'bottom'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InformationOutlineIcon width={12} height={12} />
                </Box>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                width: '100%',
              }}
            >
              {/*  판매 정가 (A)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.totalRetailPrice')}</Typography>
                <Typography variant={'typography/label/large/bold'}>{t('settlementPage.list.amountUnit', { amount: item.totalSaleAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  파트너 부담 혜택 (B-1)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.partnerBenefitDiscount')}</Typography>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.totalBenefitBurdenAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  파트너 부담 쿠폰 (B-2)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.partnerCouponDiscount')}</Typography>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.totalCouponBurdenAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  부가세 (C)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.vat.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.amount.vat.tooltip')} direction={'bottom'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.totalVatAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  에이콘 수수료 (D-1)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.aconServiceFee.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.amount.aconServiceFee.tooltip')} direction={'bottom'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.aconCommissionAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  PG 수수료 (D-2)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.pgServiceFee.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.amount.pgServiceFee.tooltip')} direction={'bottom-left'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.pgCommissionAmount.toLocaleString() })}</Typography>
              </Box>
              {/*  추차감정산 (E)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.additionalSettlementAssessment.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.amount.additionalSettlementAssessment.tooltip')} direction={'bottom-left'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>
                  {item.correctionAmount > 0 ? '+' : '-'}
                  {t('settlementPage.list.amountUnit', { amount: Math.abs(item.correctionAmount).toLocaleString() })}
                </Typography>
              </Box>
              {/*  소득세 (F)  */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant={'typography/label/large/regular'}>{t('settlementPage.list.detail.amount.incomeTax.label')}</Typography>
                  <Tooltip title={t('settlementPage.list.detail.amount.incomeTax.tooltip')} direction={'bottom-left'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationOutlineIcon width={12} height={12} />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant={'typography/label/large/bold'}>-{t('settlementPage.list.amountUnit', { amount: item.taxAmount.toLocaleString() })}</Typography>
              </Box>
            </Box>
          </Box>
          {/*  엑셀 다운로드 버튼  */}
          <Button
            variant={'outlined'}
            size={'M'}
            startIcon={<DownloadIcon />}
            fullWidth={true}
            disabled={exelDownloadLoading}
            onClick={makeExelButtonHandler(item.month, item.year)}
          >
            {t('settlementPage.list.detail.excelDownload')}
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
}
