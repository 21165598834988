
export default (ajax, alert, loading, setLoading, setListUpdate, setData, setAddList, setSelectId) => {
    function onChange(select) {
        return async (e) => {
            if (loading) {
                return
            } else {
                var el = e.currentTarget
                var data = ''
                var temp = {
                    ...select
                }
                switch (el.name) {
                    case 'auth':
                    case 'defaultValue':
                        data = el.getAttribute('auth').trim()
                        if (select) {
                            if (el.checked) {
                                if (select[el.name] === null) {
                                    data = [data]
                                } else if (!select[el.name].includes(data)) {
                                    data = [...select[el.name], data]
                                }
                            } else {
                                if (select[el.name] === null) {
                                    data = []
                                } else if (select[el.name].includes(data)) {
                                    data = select[el.name].filter(d => d !== data)
                                }
                            }
                        }
                        break
                    case 'defaultAuth':
                        data = el.checked
                        if (!data) temp.defaultValue = []
                        break
                    default:
                        data = el.value.trim()
                }
                temp[el.name] = data
                setData({ ...temp })
            }
        }
    }
    function onClick(data, refId) {
        // var {}
        return async (e) => {
            // debugger
            if (loading) {
                return
            } else {
                if (!data._id.trim()) {
                    refId.current.focus()
                    alert('apiId가 없습니다.')
                } else {
                    var el = e.target
                    var name = el.name
                    setLoading(true)
                    switch (name) {
                        case 'update':
                            await ajax.updateAuth(data)
                            setListUpdate(false)
                            break
                        case 'delete':
                            await ajax.deleteAuth(data._id)
                            setSelectId('')
                            setData((prevState) => ({ ...prevState, isUpdate: undefined, isLoad: undefined }))
                            setListUpdate(false)
                            break
                        case 'save':
                            var temp = await ajax.createAuth(data)
                            if (temp.resultCode === 1) {
                                setSelectId(data._id)
                                setData((prevState) => ({ ...prevState, isUpdate: true }))
                                setListUpdate(false)
                            } else {
                                refId.current.focus()
                            }
                            break
                        default:
                    }
                    setLoading(false)
                }
            }
        }
    }
    function onAddClick(data) {

        return async (e) => {
            if (loading) {
                return
            } else {
                setAddList(data._id)
            }
        }
    }
    var result = { onChange, onClick, onAddClick }
    return result
}

