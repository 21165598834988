import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Label, PageNavigation, SelectBox } from 'acon-mui/components';
import React from 'react';
import { OpenrunListContainer } from './OpenrunList.styles';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TABLE_STYLE, VIEW_COUNT_LIST } from './OpenrunList.constants';
import { IOpenrunListProps } from './OpenrunList.types';
import { OpenrunStatusEnum } from 'generated/graphql';
import { TLabelColor } from 'acon-mui/components/Label';

const OpenrunList = ({ promotions, total }: IOpenrunListProps): React.ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();
  const urlParams = QueryString.parse(history?.location?.search);
  const { limit = 10 } = urlParams;

  const handleOnViewCountChange = (e: React.ChangeEvent<{ value: number }>) => {
    const value = VIEW_COUNT_LIST.find((x) => x.id === e.target.value).label;
    const query = QueryString.stringify({ ...urlParams, limit: value });
    history.push({ pathname: history.location.pathname, search: query });
  };

  return (
    <OpenrunListContainer>
      <Table sx={TABLE_STYLE.table}>
        <TableHead sx={TABLE_STYLE.tableHead}>
          <TableRow>
            <TableCell variant="head">
              <Box sx={{ pl: 7, py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre">
                  {t('openrunPage.list.head.appliedProduct')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="240px">
              <Box sx={{ py: 2 }}>
                <Typography fontSize="13px" lineHeight="24px" fontWeight={600} whiteSpace="pre" textAlign="left">
                  {t('openrunPage.list.head.status')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell variant="head" width="40px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.map((promotion) => {
            const { id, assetName, status, rejectReason, since, until } = promotion;
            let labelColor: TLabelColor = 'black';
            if (status === OpenrunStatusEnum.Completed) labelColor = 'yellow';
            else if (status === OpenrunStatusEnum.Opened) labelColor = 'green';
            else if (status === OpenrunStatusEnum.InReview) labelColor = 'blue';
            else if (status === OpenrunStatusEnum.Rejected) labelColor = 'red';
            else if (status === OpenrunStatusEnum.Closed) labelColor = 'black';

            return (
              <TableRow key={id} sx={TABLE_STYLE.tableRow}>
                <TableCell>
                  <Box sx={{ pl: 7, py: 6 }}>
                    <Typography variant="subtitle2">{assetName}</Typography>
                  </Box>
                </TableCell>
                <TableCell width="240px">
                  <Box maxWidth={208} display="flex" flexDirection="column" gap={1} pt={2} pb={2}>
                    <Box display="flex" justifyContent="flex-start">
                      <Label color={labelColor} text={t(`openrunPage.statusLabel.${status}`)} />
                    </Box>
                    {status !== 'REJECTED' && status !== 'CLOSED' && status !== 'IN_REVIEW' && (
                      <Typography variant="caption" sx={{ wordBreak: 'break-all' }}>
                        {t('openrunPage.openSchedule')}:{dayjs(since).format('YYYY.MM.DD')}-{dayjs(until).format('YYYY.MM.DD')}
                      </Typography>
                    )}
                    {status === 'REJECTED' && rejectReason && (
                      <Typography variant="caption" sx={{ wordBreak: 'break-all' }}>
                        {t('openrunPage.rejectReason')}:{rejectReason}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
                <TableCell width="40px" />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!promotions.length && (
        <Box p={2} borderBottom="1px solid #ddd">
          <Typography variant="subtitle2" textAlign="center">
            {t('twinkleSalePage.noApplicationDetails')}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" px="24px">
        <Typography ml="auto" mr="20px">
          {t('twinkleSalePage.countPerPage')}
        </Typography>
        <SelectBox
          defaultValue={VIEW_COUNT_LIST.find((x) => x.label === limit)?.id || 1}
          options={VIEW_COUNT_LIST}
          onSelect={handleOnViewCountChange}
          isBorder={false}
          width="80px"
          padding="0"
          marginRight="16px"
        />
        {promotions.length ? <PageNavigation totalCnt={total} limit={limit} pathName={'/manager/promotion'} history={history} /> : null}
      </Box>
    </OpenrunListContainer>
  );
};

export default OpenrunList;
