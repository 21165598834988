import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Tr, Td, Field } from '../../../../components/table';
import { SET_COUPON_BURDEN_ITEM } from '../../../../api/mutations';
import RcSelect from 'react-select';
import { AppContext } from '../../../../app';
import { Whisper, Tooltip } from 'rsuite';

export default (({ sno, name, burden, refetch }) => {
    const { showConfirmMessage } = useContext(AppContext);
    // 쿠폰 부담도 항목 설정하기 
    const [setCouponBurdenItem] = useMutation(SET_COUPON_BURDEN_ITEM);
    const burdenItems = [
        { value: '1.00', label: '0%' },
        { value: '50.00', label: '50%' },
        { value: '100.00', label: '100%' }
    ];
    // 툴팁 가져오기 메소드 
    const getTooltipWrap = (components, text) => {
        const tooltip = (<Tooltip>{text}</Tooltip>);

        return (
            <Whisper speaker={tooltip} placement="top" trigger="hover">
                {components}
            </Whisper>
        );
    };


    const onChange = async selectedItemObj => {
        showConfirmMessage(
            '브랜드 부담도를 변경합니다.',
            '브랜드 부담도를 변경하시겠습니까?',
            async () => {
                await setCouponBurdenItem({
                    variables: {
                        sno: Number(sno),
                        burden: Math.ceil(selectedItemObj.value)
                    }
                });
                refetch();
            }
        )
    };
    const selectedValue = burdenItems.find(x => x.value === burden)
    return (
        <Tr key={sno} >
            <Td>
                <Field align="center" width="55">
                    {sno}
                </Field>
            </Td>
            <Td>
                <Field width="248">
                    {getTooltipWrap(<span>{name}</span>, name)}
                </Field>
            </Td>
            <Td>
                <RcSelect
                    {...{
                        placeholder: '선택',
                        value: selectedValue ? selectedValue : null,
                        isMulti: false,
                        options: burdenItems,
                        onChange: onChange
                    }}
                />
            </Td>
        </Tr>
    );
});