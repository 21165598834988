import React, { useContext } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { CANCEL_REQUEST_STATUS } from '../../../../api/mutations';
import { AppContext } from '../../../../app';
import dayjs from 'dayjs';
import { PARTNER_STATUS } from 'boards/DetailBoardWrite/constants';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Field } from 'components/table';
import { color } from 'acon-mui/style';
import { Label } from 'acon-mui/components';
import { LinkIcon } from './LinkIcon';
import { DeleteButton } from './DeleteButton';
import { DocumentsQuery, Exact, Language_Code, Partner_Document_Status, useDeleteDocumentMutation } from 'generated/graphql';

interface Props {
  id: string;
  assetId?: number;
  created: Date;
  language: Language_Code;
  mainImage?: string;
  requested?: Date;
  status?: Partner_Document_Status;
  title?: string;
  isAdultOnly?: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        title?: string;
        content?: string;
        assetId?: number;
        createdInterval?: any;
        requestedInterval?: any;
        status?: Partner_Document_Status | Partner_Document_Status[];
        offset?: number;
        limit?: number;
        brandIds?: number | number[];
      }>
    >,
  ) => Promise<ApolloQueryResult<DocumentsQuery>>;

  [key: string]: unknown;
}

export default ({ id, assetId, created, language, mainImage, requested, status, title, isAdultOnly, refetch, ...rest }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    // 모달창 표시하기 메소드
    showConfirmMessage,
  } = useContext(AppContext);

  // 상태 라벨
  const label = () => {
    let label;
    switch (status as unknown as Partner_Document_Status) {
      // 작성중
      case PARTNER_STATUS.DRAFT:
        label = <Label color="yellow" text={t('partnerStatus.draft')} mx="auto" />;
        break;
      // 수정중
      case PARTNER_STATUS.DRAFT_ON_OPEN:
        label = <Label color="yellow" text={t('partnerStatus.draftOnOpen')} mx="auto" />;
        break;
      // 검토진행
      case PARTNER_STATUS.IN_REVIEW:
        label = <Label color="yellow" text={t('partnerStatus.inReview')} mx="auto" />;
        break;
      case PARTNER_STATUS.IN_REVIEW_ON_OPEN:
        label = <Label color="yellow" text={t('partnerStatus.inReviewOnOpen')} mx="auto" />;
        break;
      // 반려
      case PARTNER_STATUS.REJECTED:
        label = <Label color="red" text={t('partnerStatus.rejected')} mx="auto" />;
        break;
      case PARTNER_STATUS.REJECTED_ON_OPEN:
        label = <Label color="red" text={t('partnerStatus.rejectedOnOpen')} mx="auto" />;
        break;
      // 거절됨
      case PARTNER_STATUS.DENIED:
        label = <Label color="red" text={t(`partnerStatus.denied`)} mx="auto" />;
        break;
      // 판매 중지
      case PARTNER_STATUS.SUSPENDED:
        label = <Label color="red" text={t('partnerStatus.suspended')} mx="auto" />;
        break;
      // 오픈됨
      case PARTNER_STATUS.ON_SALE:
        label = <Label color="green" text={t('partnerStatus.onSale')} mx="auto" />;
        break;
      // 이외
      default:
        label = <Label color="yellow" text={t(`reviewStatus.${status}`)} mx="auto" />;
    }
    return label;
  };
  // 항목 삭제하기
  const [deleteDocument] = useDeleteDocumentMutation({
    onCompleted: () => {
      refetch();
    },
  });
  // 항목 요청취소하기
  const [cancelRequestProdItem] = useMutation(CANCEL_REQUEST_STATUS, {
    onCompleted() {
      refetch();
    },
  });

  // 툴팁 가져오기 메소드
  const getTooltipWrap = (components, text) => {
    const tooltip = <Tooltip>{text}</Tooltip>;

    return (
      <Whisper speaker={tooltip} placement="top" trigger="hover">
        {components}
      </Whisper>
    );
  };
  // 동작 버튼 클릭 이벤트 처리기 메소드
  const onClickActionButton = (docId, status) => {
    return () => {
      try {
        const isRequested = status === PARTNER_STATUS.IN_REVIEW || status === PARTNER_STATUS.IN_REVIEW_ON_OPEN;
        showConfirmMessage(
          status === isRequested ? t('isCancelRequest') : t('isDelete'),
          status === isRequested ? t('isCancelRequestConfirmButton') : t('toDeleteConfirmButton'),
          () => {
            // 상태가 요청된 것 일 경우
            if (isRequested) {
              // 요청상태 취소하기
              cancelRequestProdItem({ variables: { docId } });
              // 종료
              return;
            }
            // 항목 삭제하기
            deleteDocument({ variables: { documentId: docId } });
          },
          (() => {
            if (isRequested) {
              return {
                cancelText: t('goBack'),
                confirmText: t('cancelConfirm'),
              };
            }
            return undefined;
          })(),
        );
      } catch (errObj) {
        // 실패 메세지 조립
        errObj.message = `상품 삭제버튼 이벤트 실패(${errObj.message})`;
        // 로그 기록(TODO)
        console.log(errObj);
        // 사용자 안내 메세지 표시
        alert('관리자에게 문의해주세요.');
      }
    };
  };

  return (
    <TableRow key={`detail table ${id}`}>
      <TableCell>
        <Box
          width="80px"
          height="80px"
          borderRadius="6px"
          sx={{
            backgroundImage: `url(${
              mainImage ? (mainImage.includes(process.env.STORAGE_ORIGIN) ? mainImage : `${process.env.STORAGE_ORIGIN}/${mainImage}`) : '/assets/detail-board/default_img.png'
            })`,
            backgroundSize: 'cover',
          }}
        />
      </TableCell>
      <TableCell>
        <Field style={{ float: 'left', width: 'fit-content', maxWidth: '250px' }}>
          <Box
            sx={{
              py: 0.5,
              display: 'flex',
              '& a': {
                color: color.text.primary,
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '22px',
              },
            }}
          >
            <Typography
              mr={2}
              p="2px 8px"
              width="32px"
              color={color.text.primary}
              fontWeight="700"
              fontSize="12px"
              lineHeight="20px"
              border={`1px solid ${color.gray.border}`}
              borderRadius="6px"
            >
              {language}
            </Typography>
            {title && id ? getTooltipWrap(<NavLink to={`/goods/write/${language.toLowerCase()}/${id}`}>{title}</NavLink>, title) : '-'}
          </Box>
        </Field>
        {isAdultOnly && <img alt="adult-badge" src="/assets/icon/adult.svg" width={24} height={24} style={{ marginLeft: '8px' }} />}
      </TableCell>
      <TableCell>
        <Typography fontSize="12px" lineHeight="18px" color={color.text.secondary}>
          {requested ? dayjs(requested).format('YY-MM-DD\u00a0\u00a0HH:mm') : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontSize="12px" lineHeight="18px" color={color.text.secondary}>
          {created ? dayjs(created).format('YY-MM-DD\u00a0\u00a0HH:mm') : '-'}
        </Typography>
      </TableCell>
      <TableCell>{label()}</TableCell>
      <TableCell>
        {assetId && [PARTNER_STATUS.ON_SALE, PARTNER_STATUS.DRAFT_ON_OPEN, PARTNER_STATUS.IN_REVIEW_ON_OPEN].includes(status as unknown as Partner_Document_Status) ? (
          getTooltipWrap(
            <span>
              <LinkIcon href={`https://www.acon3d.com/toon/product/${assetId}`} disabled={!assetId} />
            </span>,
            `${t('productNumber')} ${assetId}`,
          )
        ) : (
          <>
            <LinkIcon href={`https://www.acon3d.com/toon/product/${assetId}`} disabled />
          </>
        )}
      </TableCell>
      <TableCell>
        {(status as unknown as Partner_Document_Status) === PARTNER_STATUS.DRAFT &&
          getTooltipWrap(
            <span>
              <DeleteButton onClick={onClickActionButton(id, status)} type="delete" disabled={(status as unknown as Partner_Document_Status) !== PARTNER_STATUS.DRAFT} />
            </span>,
            <>
              {(status as unknown as Partner_Document_Status) === PARTNER_STATUS.IN_REVIEW && `${t('project.cancelReviewRequest')}`}
              {(status as unknown as Partner_Document_Status) === PARTNER_STATUS.IN_REVIEW_ON_OPEN && `${t('project.cancelEditRequest')}`}
              {(status as unknown as Partner_Document_Status) !== PARTNER_STATUS.IN_REVIEW &&
                (status as unknown as Partner_Document_Status) === PARTNER_STATUS.IN_REVIEW_ON_OPEN &&
                `${t('Remove.label')}`}
            </>,
          )}
      </TableCell>
    </TableRow>
  );
};
