import React from 'react';
import { useMutation } from "@apollo/client";
import { Input, Item, Title, Value } from "boards/DetailBoardWrite/style";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Flex, Wrap, List } from "./style";
import { BlackButton } from "boards/DetailBoardWrite/component/form";
import { UPDATE_EXTENSION_CONFIG, CREATE_EXTENSION_CONFIG } from 'api/mutations';
import ExtensionItem from './extensionItem';

// 요청하기 위한 모달입니다.
export default ({ onClose, items, show, refetch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [isError, setIsError] = useState(false);

  const [createExtensionConfig] = useMutation(CREATE_EXTENSION_CONFIG);
  const [updateExtensionConfig] = useMutation(UPDATE_EXTENSION_CONFIG);

  // 추가 버튼 클릭 이벤트 처리기 메소드
  const handleAddButtonClick = async () => {
    // 이름이 중복되는 항목이 존재할 경우
    if (items.filter((x) => x.name === name).length > 0) {
      // 에러 표시
      setIsError(true);
      // 종료
      return;
    }

    // 모델구성 생성
    await createExtensionConfig({
      variables: { CreateExtensionConfigInput: { name: name } },
    });

    // 데이터 재확보
    refetch();
    // 입력내역 초기화
    setName("");
    // 에러 숨김
    setIsError(false);
  };

  // 항목 업데이트 버튼태그 클릭 이벤트 처리기 메소드
  const handleUpdateButtonClick = async (id, name) => {
    await updateExtensionConfig({
      variables: { UpdateExtensionConfigInput: { id: id, name: name } }
    });

    refetch();
  };
  
  return (
    <Wrap show={show} onHide={onClose}>
      <Modal.Body>
        <Item>
          <Title>{t("goods.addExtensionConfig")}</Title>
          <Value>
            <Flex>
              <Input
                value={name}
                placeholder={"확장자"}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAddButtonClick();
                  }
                }}
              />
              <BlackButton disabled={!name} onClick={handleAddButtonClick}>
                {t("goods.add")}
              </BlackButton>
            </Flex>
            
            <Flex>
              <ErrorMessage show={isError}>
                중복되는 항목이 존재합니다.
              </ErrorMessage>
            </Flex>
          </Value>
        </Item>

        <List>
          {items && (items || []).map((item, i) => (
            <ExtensionItem key={i} item={item} onUpdate={handleUpdateButtonClick} />
          ))}
        </List>
      </Modal.Body>
    </Wrap>
  );
};
