import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bankAccountNumberState, bankNameState, isEmailPaymentAccountSelector } from 'user/User.atom';
import { useTranslation } from 'react-i18next';
import { Summary } from 'signup/style';

export default function AccountField() {
  const { t } = useTranslation();

  const [dirty, setDirty] = useState<boolean>(false);
  const [bankName, setBankName] = useRecoilState(bankNameState);
  const isEmailPaymentAccount = useRecoilValue(isEmailPaymentAccountSelector);
  const [bankAccountNumber, setBankAccountNumber] = useRecoilState(bankAccountNumberState);

  const handleBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(e.target.value);
  };

  const handleBankAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setBankAccountNumber(e.target.value);
  };

  return (
    <Stack direction={'column'} spacing={0.5}>
      <Typography variant={'body2'} color={'grey.800'} fontWeight={600}>
        Account
      </Typography>

      <RadioGroup value={bankName} onChange={handleBankNameChange}>
        <Stack direction={'row'} spacing={2}>
          <FormControlLabel value={'paypal'} control={<Radio sx={{ width: 36, height: 36 }} />} label={'Paypal'} sx={{ m: 0 }} />

          <FormControlLabel value={'payoneer'} control={<Radio sx={{ width: 36, height: 36 }} />} label={'Payoneer'} sx={{ m: 0 }} />
        </Stack>
      </RadioGroup>

      <Stack spacing={1}>
        <TextField
          value={bankAccountNumber}
          placeholder="Please enter your Paypal or Payoneer account."
          onChange={handleBankAccountNumberChange}
          error={dirty && !isEmailPaymentAccount}
        />
        {dirty && !isEmailPaymentAccount && <Summary fail>{t('signupPage.emailIncorrect')}</Summary>}
      </Stack>
    </Stack>
  );
}
