import React, { useState, useMemo } from 'react'
import AlertModal from '../../common/AlertModal2'
import ConfirmModal from '../../common/ConfirmModal2'

import AuthManager from './authManager'
import TemplateManager from './templateManager'
import Header from './header'
import './index.scss';
import AuthData from './authManager/authData'
import TemplateData from './templateManager/templateData'
function getSubMenuDom(subMenu) {
    var result = (<AuthData>
        <AuthManager></AuthManager>
    </AuthData>)
    switch (subMenu) {
        case 'template':
            result = (<TemplateData>
                <TemplateManager></TemplateManager>
            </TemplateData>)
            break
        default:
    }
    return result
}

export default function (props) {

    // 'auth','user','template'
    const [subMenu, setSubMenu] = useState("auth")
    const alertStatus = {
        // alert 모달 콜백 함수 
        alertModalCallback: null,
        // alert modal 내용 
        alertModalContents: ''
    }
    const header = useMemo(() => (<Header {...{ subMenu, setSubMenu }}></Header>), [subMenu, setSubMenu])
    const dom = useMemo(() => getSubMenuDom(subMenu), [subMenu])
    console.log('auth')
    return <>
        <div>
            <ConfirmModal>
                <AlertModal {...{ alertModalStatus: alertStatus }}>
                    <div>
                        {header}
                    </div>
                    <div>
                        {dom}
                    </div>
                </AlertModal>
            </ConfirmModal>
        </div>
    </>

}



