import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { Box, Table } from '@mui/material';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { BoardLabel, Button } from 'acon-mui/components';
import { isMobile } from 'react-device-detect';
import { useGetFaqQuery, useGetNoticeQuery } from 'generated/graphql';

const BoardContent = React.memo(Content);

function translateType(type) {
  switch (type) {
    case '공지': return 'notice';
    case '이벤트': return 'event';
    case '가이드': return 'guide';
    case '노하우': return 'knowhow';
    case '일반': return 'normal';
    case '유형': return 'placeholder';
    default: return type;
  }
};

function Content(props) {
  const { t, history, match } = props;
  const { params: { boardName, id } } = match;

  const useContentQuery = boardName === 'faq' ? useGetFaqQuery : useGetNoticeQuery;
  const { error, data } = useContentQuery({
    variables: {
      id: Number(id) || 1
    }
  });

  const item = useMemo(() => {
    if (data && data[boardName]) {
      return data[boardName];
    }
    return null;
  }, [boardName, data]);

  const labelName = useMemo(() => translateType(item?.typeName || boardName), [item, boardName]);

  if (error) { console.log(error); return; }

  function renderPageNavigateRow(data, type) {
    return (
      <Table className='short' sx={{ borderTop: '1px solid rgba(145, 158, 171, 0.24)' }}>
        <colgroup>
          <col width="64px" />
          <col width="80px" />
          <col />
          <col width="110px" />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <Typography
                px={2}
                fontWeight="400"
                fontSize="12px"
                lineHeight="20px"
                color="#00000099"
              >
                {type}
              </Typography>
            </td>
            <td>
              <div className='category-container'>
                <div className={`category ${translateType(data?.typeName)}`}>
                  {t(`NoticeCategory.${translateType(data?.typeName)}`)}
                </div> 
              </div>
            </td>
            <td>
              <div className='title'>
                <div className='title__text__container'>
                  <NavLink className="title__text" to={`/board/${boardName}/detail/${data?.id}`}>{data?.title}</NavLink>
                </div>
              </div>
            </td>
            <td>
              <div className="date_text">
                {new Date(data?.created).toLocaleDateString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' })}
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <Box py={5} className="notice-board">
      <Typography
        paddingX="15px"
        fontWeight="700"
        fontSize="24px"
        lineHeight="36px"
        color="#212B36"
      >
        {t(`BoardTitles.${boardName}`)}
      </Typography>
      {item && (
        <>
          <Box mt={3} paddingX="15px">
            <Typography
              fontWeight="500"
              fontSize="20px"
              lineHeight="32px"
              letterSpacing="0.15px"
              color="#212B36"
            >
              {item.title}
            </Typography>
            <Box 
              mt={3}
              display="flex" 
              alignItems="center"
            >
              <BoardLabel type={translateType(labelName)}>
                {t(`NoticeCategory.${labelName}`)}
              </BoardLabel>
              <Box className="date_text" ml={2}>
                {new Date(item.created).toLocaleDateString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' })}
              </Box>
            </Box>
          </Box>
          <Box mt={1.5} color="#212B36" sx={{ '& tr': { background: 'transparent !important' }, '& td': { border: 'none !important' } }}>
            <table>
              <tbody>
                <tr className="content">
                  <td>
                    <div className="Editor">
                      <div className="body ql-editor">
                        {parse(item.body)}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box sx={{ '& td': { padding: '16px 0 !important' } }}>
            {item.next && renderPageNavigateRow(item.next, '다음')}
            {item.prev && renderPageNavigateRow(item.prev, '이전')}
          </Box>
        </>
      )}
      <Button
        variant='contained'
        onClick={() => {
          history.push({
            pathname: `/board/${boardName}`
          });
        }}
        marginTop={3}
        width={isMobile ? '100%' : '152px'}
        height="48px"
        fontWeight="700"
      >
        목록으로 돌아가기
      </Button>
    </Box>
  );
};

export default BoardContent;
