import styled from 'styled-components';

export const TwinkleSaleContainer = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 120px;
  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : 'width: 592px;')}
`;

export const TwinkleSaleToast = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  padding: 6px 16px;
  border-radius: 4px;
  background: var(--color-gray-800, #313135);
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
`;
