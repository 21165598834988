import { createCategory, syncCategory } from '../ajax'
// 노드 정보 
export default ((t, setSelectCategoryType, setSelectLang, setCategoryState, setSearchKeyword, setIsLoading) => {
    // 대분류 카테고리 select 변경 이벤트 처리기 메소드
    const onChangeCategoryType = (e) => {
        // 대분류 카테고리 설정
        setSelectCategoryType(e.currentTarget.value)
    }

    // 언어 select 태그 변경 이벤트 처리기 메소드
    const onChangeLang = (e) => {
        setSelectLang(e.currentTarget.value)
    }

    // 카테고리 종류 checkbox 변경 이벤트 처리기 메소드
    const onChangeState = (e) => {
        // 선택한 카테고리 상태
        const el = e.currentTarget
        const checkState = el.value
        const checked = el.checked

        // 카테고리 설정
        setCategoryState((prev) => {
            if (checked) {
                return [...prev, checkState]
            } else {
                if (prev.length > 1) {
                    return [
                        ...prev.filter((d) => {
                            return !(d === checkState)
                        })
                    ]
                } else {
                    return prev
                }
            }
        })
    }

    // 검색 input 태그 변경 이벤트 처리기 메소드
    const onChangeSearchInputTag = (e) => {
        // 검색 키워드 설정하기
        setSearchKeyword(e.currentTarget.value)
    }

    // 동기화 버튼 클릭 이벤트 처리기 메소드
    const onClickSyncBtn = async () => {
        if (window.confirm(t('categorySyncModalTitle') + '\n' + t('categorySyncModalContents'))) {
            // 동기화 함수
            var resultData = await syncCategory()
            if (resultData.resultCode === 1) {
                setIsLoading(false)
            }
        }
    }

    // 루트 카테고리 추가하기 이벤트 처리기 메소드
    const onAddRootCategory = (categoryData, setCategoryData, selectCategoryType) => {
        return async () => {
            var data = {
                "name": "새로운 카테고리",
                "memo": "",
                "state": "standby",
                "type": selectCategoryType,
                "parentCategoryId": null,
                "creator": null,
                "updater": null
              }
            if (categoryData) {
                data.parentCategoryId = categoryData.id
            }
            
            var resultData = await createCategory(data);
            console.log(resultData);
            setCategoryData(resultData)
            setIsLoading(false)
        }
    }
    return {
        onChangeCategoryType,
        onChangeLang,
        onChangeState,
        onChangeSearchInputTag,
        onClickSyncBtn,
        onAddRootCategory
    }

})