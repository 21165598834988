import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from 'rsuite';
import { ListItem, Name, NameInput, Size, SizeInput, Button } from '../style';
import AconHelper from '../../../../../../lib/global';
import context from '../../../../provider/product';

export default (({ item, setItem, onClick }) => {
    const nameTag = useRef(null);

    const { state } = useContext(context);
    // 이름 
    const [name, setName] = useState('');
    // 사이즈
    const [size, setSize] = useState('');

    // 편집모드 여부 
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (isEdit) {
            // 아이템 명 name 으로 지정 
            setName(item.name);
            setSize(item.size);

            // 이름 태그 포커싱 
            nameTag && nameTag.current && nameTag.current.focus();
        }
    }, [isEdit]);

    // 수정 버튼 클릭 이벤트 처리기 메소드 
    const onClickEdit = () => {
        // 편집모드 on 
        setIsEdit(true);
    };

    // 저장 버튼 클릭 이벤트 처리기 메소드 
    const onClickSave = () => {
        // 항목 설정하기 
        setItem(item, name, size);
        // 편집모드 on 
        setIsEdit(false);
    };
    const onChangeSizeInput = e => {
        let { value } = e.target;
        value = AconHelper.OnlyNumber(value)
        setSize(value);
    };

    return (
        <ListItem key={item.name}>
            {!isEdit &&
                <>
                    <Name>{item.name}</Name>
                    <Size>{AconHelper.AddCommas(item.size)}</Size>
                </>
            }

            {isEdit &&
                <>
                    <NameInput ref={nameTag} value={name} onChange={e => setName(e.target.value)} />
                    <SizeInput
                        value={size}
                        onChange={onChangeSizeInput}
                        maxLength={5}
                    />
                </>
            }

            {!state.isDisp && <>
                {!isEdit && <Button onClick={onClickEdit}>
                    <Icon icon="edit" />
                </Button>}

                {isEdit && <Button
                    disabled={!name || !size}
                    onClick={onClickSave}>
                    <Icon icon="save" />
                </Button>}

                <Button onClick={() => {
                    isEdit ? setIsEdit(false) : onClick(item);
                }}>
                    <Icon icon="close" />
                </Button>
            </>}
        </ListItem>
    );
});