import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../../provider/product';
import { Item, Title, Input, Value } from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);

    return useMemo(() => {
        return (
            <Item>
                <Title>{t("goods.dynamics")}</Title>
                <Value>
                    <Input
                        disabled={state.isDisp}
                        placeholder={t('goods.dynamicsPlaceholder')}
                        value={state.dynamicItem}
                        onChange={(e) => {
                            dispatch({
                                key: 'dynamicItem',
                                type: 'input',
                                value: e.target.value
                            })
                        }}
                    />
                </Value>
            </Item>
        );
    }, [state.dynamicItem, state.isDisp]);
});