import { Card } from '@mui/material';
import styled from 'styled-components';

export const OpenRunContainer = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 120px;
  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : 'width: 592px;')}
`;

export const OpenRunStatusCard = styled(Card)`
  padding: 24px 48px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
