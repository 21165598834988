import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_DETAIL_BANNER_WRITING_MATERIALS } from '../api/quries';
import { CREATE_BANNER, UPDATE_BANNER, DELETE_BANNER } from '../api/mutations';
import './BannerWrite.scss';
import { AppContext } from '../app';
import CreatableSelect from 'react-select/creatable';
import { uploadFile } from '../api';
import DateSetting from '../common/DateSetting';

const hiddenFileInput = document.createElement('input');
hiddenFileInput.setAttribute('type', 'file');

export default (props) => {
  const { userInfo } = useContext(AppContext);
  // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
  const { isAdmin } = userInfo;
  if (!isAdmin) {
    props.history.push('/');
    return <></>;
  }
  // 배너 DB id
  const [docId, setDocId] = useState(+props.match.params.docId);

  // 번역기 불러오기
  const { t, match } = props;
  // 배너 종류: 상세페이지(detailPage) || 메인페이지(mainPage)
  const bannerDisplayLocation = match.params.displayLocation || 'detailPage';
  // 모달 표시 메소드 불러오기
  const { showAlertMessage, showNoButtonMessage, setBackgroundColor } = useContext(AppContext);

  /* State 설정 */

  // 배너 안에 표시할 텍스트
  const [textValue, setTextValue] = useState('');
  const [fileInputName, setFileInputName] = useState(t('uploadPlaceholder'));
  const [imageUrl, setImageUrl] = useState('');
  // 배너 클릭시 이동 링크
  const [linkValue, setLinkValue] = useState('');
  // 배너 코드(일련번호)
  const [bannerCodeValue, setBannerCodeValue] = useState(docId || t('Banner.writer.bannerCodePlaceholder'));
  // 배너타입
  const [typeValue, setTypeValue] = useState(1);
  // 배너표시할 몰(국가)
  const [languageValue, setLanguageValue] = useState(1);

  // 배너 표시 시작일 yyyy-mm-dd
  const [startDate, setStartDate] = useState(null);
  // 배너 표시 종료일 yyyy-mm-dd
  const [endDate, setEndDate] = useState(null);
  // 배너 표시 위치 url
  const [products, setProductsValue] = useState([]);
  const [productsValueInput, setProductsValueInput] = useState('');
  // 선택한 상품만 배너 표시 또는 선택한 상품을 제외하고 배너 표시
  const [showSelected, setShowSelected] = useState(false);

  // 띠 배너 배경색
  const [bannerBgColor, setBannerBgColor] = useState('#333333');
  // 띠 배너 닫기색
  const [bannerCloseColor, setBannerCloseColor] = useState('#ffffff');
  // 링크 설정 새창 띄우기 여부
  const [isBlankHref, setIsBlankHref] = useState(false);
  // 띠 배너 종료 노출
  const [closedShow, setClosedShow] = useState(1);

  const queryVariables = {
    text: textValue,
    href: linkValue,
    type: typeValue,
    languageCode: (() => {
      if (languageValue === 1) return 'ko';
      if (languageValue === 2) return 'en';
      if (languageValue === 3) return 'zh';
      if (languageValue === 4) return 'ja';
      return null;
    })(),
    language: languageValue,
    startDate: startDate || new Date(),
    endDate: endDate || new Date('2099-01-01T12:00Z'),
    selectedProductsId: products?.map((e) => Number(e.value)).filter((e) => e),
    showOnlyForSelectedProducts: showSelected,
    backgroundColor: bannerBgColor,
    closeColor: bannerCloseColor,
    isBlankHref: isBlankHref,
    isClosedShowId: closedShow,
  };

  if (docId) queryVariables.id = docId;

  // 초기 설정
  useEffect(() => {
    // componentDidMount에 해당하는 부분
    // 배경화면 흰색으로 변경
    setBackgroundColor('white');

    hiddenFileInput.value = '';
    hiddenFileInput.type = '';
    hiddenFileInput.type = 'file';

    return () => {
      // componentWillUnMount에 해당하는 부분
      // 배경화면 색 초기화
      setBackgroundColor('inherit');
    };
  }, []);

  const [createBannerMutation] = useMutation(CREATE_BANNER);
  const [updateBannerMutation] = useMutation(UPDATE_BANNER);
  const [deleteBannerMutation] = useMutation(DELETE_BANNER);

  // 저장 버튼 클릭 이벤트
  const onClickSaveButton = async () => {
    try {
      let imageKeys = null;

      if (bannerDisplayLocation === 'detailPage') {
        const bannerDisplayType = bannerTypes.find((e) => {
          return e.id === typeValue;
        }).displayType;

        if (bannerDisplayType === 'image' && hiddenFileInput.files.length) {
          showNoButtonMessage(t('Banner.writer.imageUploadMessage.title'), t('Banner.writer.imageUploadMessage.content'));
          await uploadFile(hiddenFileInput.files[0], true)
            .then((r) => {
              console.log('complete image upload :)');
              imageKeys = {
                key: r.key,
                name: r.key.split('/').pop(),
              };
              return null;
            })
            .catch((err) => {
              throw new Error(`image upload failed(${err.message})`);
            });
        }

        showNoButtonMessage(t('Banner.writer.bannerDataUploadMessage.title'), t('Banner.writer.bannerDataUploadMessage.content'));
      }

      let mutation;
      if (bannerDisplayLocation === 'detailPage') {
        // 상세 페이지 쿼리 설정
        mutation = docId ? updateBannerMutation : createBannerMutation;
      }

      const { data } = await mutation({
        variables: { ...queryVariables, imageKeys },
      });

      const isSucceed = data?.updateBanner || data?.createBanner || data?.createBandBanner || data?.updateBandBanner;

      if (isSucceed) {
        showAlertMessage(t('saved'), t('saved'));
        props.history.goBack();
      } else {
        throw new Error('graphql query failed');
      }
    } catch (err) {
      // (TODO: 에러 로깅)
      console.log('error is', err);
      showAlertMessage(t('serverError'), t('serverError'));
    }
  };

  // 게시글 삭제하는 함수
  const deleteDoc = async () => {
    if (docId) {
      let mutation;
      // 페이지 별로 각각의 쿼리들을 세팅합니다.
      if (bannerDisplayLocation === 'detailPage') {
        // 상세 페이지 삭제 쿼리 설정
        mutation = deleteBannerMutation;
      }

      const { data } = await mutation({
        variables: { id: docId },
      });

      if (data?.deleteBanner || data?.deleteBandBanner) {
        showAlertMessage(t('Boards.delete.title'), t('Boards.delete.content'));
      } else {
        showAlertMessage(t('Boards.delete.title'), t('Boards.deleteFail.content'));
      }
      props.history.goBack();
    }
  };

  // 버튼 그룹 (미리보기, 저장, 삭제)
  const btnGroup = (
    <div className="banner-write_buttonGroup">
      <button className="black-button" type="button" onClick={onClickSaveButton}>
        {t('Save.label')}
      </button>
      {!!docId && (
        <button className="white-button" type="button" onClick={deleteDoc}>
          {t('Remove.label')}
        </button>
      )}
    </div>
  );

  // text 타입 input 객체들 값 입력시 작동하는 로직
  const onChangeTextInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    if (inputName === 'text') {
      setTextValue(inputValue);
    } else if (inputName === 'link') {
      setLinkValue(inputValue);
    }
  };

  // 페이지별 배너 정보 쿼리
  let bannerWritingQuery;
  if (bannerDisplayLocation === 'detailPage') {
    bannerWritingQuery = GET_DETAIL_BANNER_WRITING_MATERIALS;
  }

  // 배너타입, 몰(국가) 설정을 위한 선택지 불러오기, 배너 아이디가 있을 경우 내용 가져오기
  const { data } = useQuery(bannerWritingQuery, {
    fetchPolicy: 'no-cache',
    variables: { id: docId || 0 },
  });

  // 페이지 별로 배너 데이터 구분
  let bannerTypes,
    bannerData,
    languages = data?.getLanguages;
  if (bannerDisplayLocation === 'detailPage') {
    bannerTypes = data?.getBannerTypes;
    bannerData = data?.getBanner;
  }

  useEffect(() => {
    // 해당 배너 ID에 대한 데이터가 있을 경우 값 세팅
    if (bannerData) {
      const langId = (() => {
        if (bannerData?.language?.id) return bannerData.language.id;
        const langCode = bannerData?.languageCode;
        if (langCode === 'ko') return 1;
        if (langCode === 'en') return 2;
        if (langCode === 'zh' || langCode === 'cn') return 3;
        if (langCode === 'ja' || langCode === 'jp') return 4;
        return null;
      })();

      // 공통 항목 세팅
      setBannerCodeValue(bannerData.id);
      setLanguageValue(langId);
      setTextValue(bannerData.text);
      setLinkValue(bannerData.href);
      setTypeValue(bannerData.type?.id);
      setStartDate(new Date(bannerData.startDate));
      setEndDate(new Date(bannerData.endDate));

      if (bannerDisplayLocation === 'detailPage') {
        setImageUrl(bannerData.image?.fullName || bannerData.image?.azureStoragePath);
        if (bannerData?.selectedProducts) {
          setProductsValue(
            bannerData.selectedProducts.map((e) => {
              const godoGoodsNo = e.godoGoodsNo;
              return { label: godoGoodsNo, value: godoGoodsNo };
            }),
          );
        } else if (bannerData?.assets) {
          setProductsValue(
            (bannerData?.assets || []).map((e) => {
              const godoGoodsNo = e.id;
              return { label: godoGoodsNo, value: godoGoodsNo };
            }),
          );
        }
        setShowSelected(bannerData.showOnlyForSelectedProducts);
      }
    }
  }, [bannerData]);

  // type 설정 변경시 작동하는 로직
  const onChangeType = (e) => {
    setTypeValue(Number(e.target.value));
  };

  // type 설정 UI
  const TypeSelectTag = () => {
    const options = bannerTypes?.map((e, i) => {
      return (
        <option key={i} value={e.id}>
          {e.name}
        </option>
      );
    });
    return (
      <select onChange={onChangeType} value={typeValue}>
        {options}
      </select>
    );
  };

  // lang 설정 변경시 작동하는 로직
  const onChangeLanguage = (e) => {
    setLanguageValue(Number(e.target.value));
  };

  // lang 설정 UI
  const LanguageSelectTag = () => {
    const options = languages?.map((e, i) => {
      return (
        <option key={i} value={e.id}>
          {e.code.toUpperCase()}
        </option>
      );
    });
    return (
      <select onChange={onChangeLanguage} value={languageValue}>
        {options}
      </select>
    );
  };

  // 표시위치 입력시 리턴되는 array 값을 productsValue 변수에 지정해줍니다.
  // products 변수는 <CreatableSelect />의 prop으로 사용됩니다.
  const onChangeProducts = (value) => {
    setProductsValue(value);
  };

  // 표시위치 입력시 리턴되는 string 값을 productsValueInput 변수에 지정해줍니다.
  // productsValueInput 변수는 <CreatableSelect />의 prop으로 사용됩니다.
  const onChangeProductsInput = (inputValue) => {
    setProductsValueInput(inputValue);
  };

  // productsValueInput 변수 값(string)으로부터 productsValue 변수 array의 값으로 formatting해주는 함수입니다.
  const createOption = (label) => ({ label, value: label });
  const changeOptionFormat = (input) => {
    return input
      .split(' ')
      .filter((item) => item)
      .map((item) => createOption(item));
  };

  // 표시위치 입력 중 Enter, Tab 키를 누를 때 작동하는 함수입니다.
  // productsValueInput(string)으로부터 products(array)에 들어갈 객체를 오브젝트 포맷으로 만들어 입력합니다.
  // products 값 입력 후에는 productsValueInput 값을 빈 string으로 바꿔줍니다.
  // input DOM 객체의 값이 사라지고 기존에 입력했던 값이 태그 모양으로 바뀌어 추가되는 로직에 해당합니다.
  const onKeyDownProducts = (event) => {
    if (!productsValueInput) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setProductsValue([...(products || []), ...changeOptionFormat(productsValueInput)]);
        setProductsValueInput('');
        event.preventDefault();
    }
  };

  // "입력한 상품에만 노출" 또는 "입력한 상품을 제외하고 노출" 여부 설정시 작동 함수
  const onChangeShowSelected = (e) => {
    if (e.target.value === 'show') setShowSelected(true);
    if (e.target.value === 'hide') setShowSelected(false);
  };

  // 링크 설정 - 페이지 이동 및 새창 띄우기 여부 설정시 작동 함수 (띠 배너)

  // 이미지 업로드
  const onClickUpload = () => {
    hiddenFileInput.addEventListener('change', () => {
      if (!hiddenFileInput.files[0]) return;
      setFileInputName(hiddenFileInput.files[0].name);

      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImageUrl(fileReader.result);
      };
      fileReader.readAsDataURL(hiddenFileInput.files[0]);
    });
    hiddenFileInput.click();
  };

  // 게시판 셀렉터 버튼 클릭시 이벤트 함수
  const bannerImageHeight = typeValue === 2 ? '116px' : typeValue === 3 ? '120px' : null;
  const onClickBannerSelector = (e) => {
    const bannerDisplayLocation = e.target.dataset.name;

    // 메인페이지 배너관리 기능은 기획 준비중입니다.
    if (bannerDisplayLocation === 'mainPage') return;

    props.history.push(`/banner/${bannerDisplayLocation}`);
    setDocId(undefined);
  };

  // 색상 변경

  // 하위 컴포넌트 조립 후 최종으로 내보내는 컴포넌트
  return (
    <div className="banner-write">
      <div className="board-selector">
        <button className={bannerDisplayLocation === 'detailPage' ? 'selected' : ''} onClick={onClickBannerSelector} data-name="detailPage">
          {t('BannerSelectors.detailPage')}
        </button>
        <button className={bannerDisplayLocation === 'eventPage' ? 'selected' : ''} onClick={onClickBannerSelector} data-name="eventPage">
          {t('BannerSelectors.eventPage')}
        </button>
      </div>

      {/* 버튼 그룹 */}
      {btnGroup}

      {/* 배너 설정 UI */}
      <div className="setting_area">
        <div className="field_container">
          <div className="fieldName">{t('Banner.writer.type')}</div>
          <div className="wideInput">
            <TypeSelectTag />
          </div>
        </div>

        <div className="field_container">
          <div className="fieldName">{t('Banner.writer.language')}</div>
          <div className="wideInput">
            <LanguageSelectTag />
          </div>
        </div>

        <div className="field_container">
          <div className="fieldName">{t('Banner.writer.text')}</div>
          {typeValue === 1 ? (
            <div className="wideInput">
              <input type="text" name="text" placeholder={t('Banner.writer.textPlaceholder')} value={textValue} onChange={onChangeTextInput} />
            </div>
          ) : (
            <>
              <div className="fileInput">
                <div className="fakeFileInput">{fileInputName}</div>
                <button className="fakeFileInputButton black-button" onClick={onClickUpload}>
                  {t('upload')}
                </button>
              </div>
              <div className="bannerImagePreview">
                <img src={`${imageUrl}`} height={bannerImageHeight}></img>
              </div>
            </>
          )}
        </div>

        <div className="field_container">
          <div className="fieldName">{t('Banner.writer.link')}</div>
          <div className="wideInput">
            <input type="text" name="link" placeholder={t('Banner.writer.linkPlaceholder')} value={linkValue} onChange={onChangeTextInput} />
          </div>
        </div>

        <div className="field_container">
          <div>
            <div className="fieldName">{t('Banner.writer.period')}</div>
          </div>

          <div className="multipleInput">
            <DateSetting startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
          </div>
        </div>

        {bannerDisplayLocation === 'detailPage' && (
          <div className="field_container">
            <div>
              <div className="fieldName">{t('Banner.writer.products.fieldName')}</div>
            </div>
            <div className="radioInput">
              <div>
                <input onChange={onChangeShowSelected} id="showAllowHide" type="radio" name="showSelected" value="show" checked={showSelected ? true : false} />
                <label htmlFor="showAllowHide">{t('Banner.writer.products.showSelected')}</label>
              </div>
              <div>
                <input onChange={onChangeShowSelected} id="hideAllowHide" type="radio" name="showSelected" value="hide" checked={showSelected ? false : true} />
                <label htmlFor="hideAllowHide">{t('Banner.writer.products.hideSelected')}</label>
              </div>
            </div>
            <div className="creatableInput">
              <CreatableSelect
                components={{ DropdownIndicator: null }}
                inputValue={productsValueInput}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={onChangeProducts}
                onInputChange={onChangeProductsInput}
                onKeyDown={onKeyDownProducts}
                placeholder={t('Banner.writer.productsPlaceholder')}
                value={products}
              />
              <div className="inputGuideText">{t('Banner.writer.productsInputGuide')}</div>
            </div>
          </div>
        )}

        <div className="field_container">
          <div className="fieldName">{t('Banner.writer.bannerCode')}</div>
          <div className="wideInput">
            <input type="text" name="bannerCode" value={bannerCodeValue} onChange={onChangeTextInput} />
          </div>
        </div>
      </div>
    </div>
  );
};
