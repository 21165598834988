import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { AttachFileIcon2 } from "assets";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { documentsState } from "./atom";

export default function DocumentsField() {
  const { t } = useTranslation();
  const [documents, setDocuments] = useRecoilState(documentsState);
  const [inputDocument, setInputDocument] = useState<string>("");

  const displayDocuments = documents.map((x, i) => {
    return {
      id: i,
      name: x,
    };
  });

  const handleUploadButton = () => {
    const target = [...documents];

    target.push(inputDocument);

    setInputDocument("");
    setDocuments(target);
  };

  const getHandleDeleteButton = (id: number) => {
    return () => {
      const documents = displayDocuments.filter((x) => x.id !== id);

      setDocuments(documents.map((x) => x.name));
    };
  };

  const handleInputDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDocument(e.target.value);
  };

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{t("store.referenceLink")}</Typography>
      <Stack spacing={1.5}>
        <Stack direction="row" spacing={1.5}>
          <TextField
            size="small"
            inputProps={{ type: "search" }}
            sx={{ flex: 1 }}
            placeholder={t("store.referenceLinkPlaceholder")}
            onChange={handleInputDocument}
            value={inputDocument}
          />
          <Button
            size="medium"
            variant="contained"
            disabled={!inputDocument}
            onClick={handleUploadButton}
          >
            {t("store.upload")}
          </Button>
        </Stack>
        <Stack spacing={1.5}>
          {displayDocuments.map((x, i) => (
            <Stack key={`document_${i}`} direction="row" spacing={1.5}>
              <Stack direction="row" spacing={1} flex={1}>
                <Box sx={{ padding: "5px" }}>
                  <AttachFileIcon2 size="small" />
                </Box>
                <Box style={{ width: "calc(100% - 18px)" }}>
                  <a href={x.name}>
                    <Typography
                      color={"grey.800"}
                      variant={"body2"}
                      sx={{ textDecoration: "underline" }}
                    >
                      {x.name}
                    </Typography>
                  </a>
                </Box>
              </Stack>
              <Button
                size="medium"
                variant="outlined"
                onClick={getHandleDeleteButton(x.id)}
              >
                {t("store.delete")}
              </Button>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
