import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
    width: 100%;
    min-height: 41px;
    resize: none;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 400;
    padding-top: 2px;
    padding-left: 5px;

    & .rs-picker-toggle-value {
        color: #575757 !important;
        font-size: 12px;
    }
`;