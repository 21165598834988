import React from 'react';
import { Box } from '@mui/material';
import { Progress, useDesignSystemTheme } from 'carpenstreet-designsystem';

export default function SalesRecordsLoading() {
  const theme = useDesignSystemTheme();

  return (
    <Box
      sx={{
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '896px',
        [theme.breakpoints.down('largeTablet')]: {
          marginTop: '8px',
          padding: '16px',
          height: '320px',
          backgroundColor: theme.palette['color/white'],
        },
      }}
    >
      <Progress />
    </Box>
  );
}
