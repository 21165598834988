import React from 'react';
import './SearchPw.scss';

// 패스워드 변경하기 페이지 
export default (({ name, label, value, onChange }) => {
    return (
        <>
            <div className="searchPw__line">
                <div className="searchPw__input">
                    <div className={"placeholder" + (value ? " hide" : '')}>
                        <label htmlFor={name}>{label}</label>
                    </div>
                    <input id={name} name={name} type="text" onChange={onChange} />
                </div>
            </div>
        </>
    );
});