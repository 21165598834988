import React from 'react';
import styled from 'styled-components';

export default styled.div`
    ul {
        display: flex;
        border-bottom: 1px solid #000;
        
        li {
            flex: 1;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 60px;
            cursor: pointer;

            &.selected::after {
                content: '';
                display: block;
                width: 100%;
                height: 7px;
                margin-bottom: -1px;
                background: #FF007B;
            }
        }
    }
`;

export const LocaleSettingStyle = styled.div`
    padding: 20px 0;
    border-bottom : 1px solid #000;

    .child-box {
        font-size: 0.8em;
        padding-left: 50px;

        > div {
            &:first-of-type {
                margin-top: 15px;
            }
        }
        
        #fileSelector {
            display: none;
        }
        
        [for="fileSelector"] {
            span {
                padding: 5px 10px;
                border-radius: 3px;
                border: 1px solid #777;
                background: white;
                cursor: pointer;
            }
            input {
                height: 100%;
            }
        }

        input + div label {
            margin-left: 0;
        }

        [name="isBlankAnchor"] {
            display: none;
            
            & + span {
                display: inline-block;
                padding: 4px 10px;
                margin-top : 10px;
                background: white;
                color: black;
                border: 1px solid #333;
            }
            
            &:checked + span {
                padding: 5px 10px;
                background: #FF007B;
                color: white;
                font-size: 1em;
                border: 0;
            }
        }
    }

    label {
        margin-bottom: 0;
        font-weight: inherit;

        input {
            margin-right: 5px;
        }

        & + label {
            margin-left: 30px;
        }
    }
`;