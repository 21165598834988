import React, { useState, useContext, useEffect, useRef } from 'react';
import { Flex, Field, Title, BalloonDescription, Item, Value } from '../../style';
import { useTranslation } from 'react-i18next';
import { Icon } from 'rsuite';
import { ImageBox, ImageType, TypeDivision, Message } from './style';
import SubImage from './image';
import context from '../../provider/product';
import { WhiteButton } from 'boards/DetailBoardWrite/component/form';
import { AppContext } from 'app';

export default (() => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const { state, dispatch } = useContext(context);

    // 현재 이미지 유형 (기본 1)
    const [imageType, setImageType] = useState(1);

    // 메세지 
    const [message, setMessage] = useState({
        type: false,
        txt: ''
    });

    // 이미지1 
    const [image1, setImage1] = useState('');
    // 이미지2
    const [image2, setImage2] = useState('');
    // 이미지3
    const [image3, setImage3] = useState('');

    // 서브이미지1 완료여부 
    const [isComplete1, setIsComplete1] = useState(false);
    // 서브이미지2 완료여부 
    const [isComplete2, setIsComplete2] = useState(false);
    // 서브이미지3 완료여부 
    const [isComplete3, setIsComplete3] = useState(false);

    // 서브이미지1 크로퍼
    const subImage1CropperTag = useRef(null);
    // 서브이미지2 크로퍼
    const subImage2CropperTag = useRef(null);
    // 서브이미지3 크로퍼
    const subImage3CropperTag = useRef(null);

    useEffect(() => {
        if (!isComplete1 && state.subImage1CropInfo)
            setIsComplete1(true);

        if (!isComplete2 && state.subImage2CropInfo)
            setIsComplete2(true);

        if (!isComplete3 && state.subImage3CropInfo)
            setIsComplete3(true);
    }, [state.subImage1CropInfo, state.subImage2CropInfo, state.subImage3CropInfo]);

    const allImageEventHandler = [
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage1CropperTag,
            setCropInfo: value => {
                dispatch({
                    key: `subImage1CropInfo`,
                    type: 'input',
                    value
                })
            },
            setImageUrl: (key, name) => {
                dispatch({
                    key: `subImage1Key`,
                    type: 'input',
                    value: key
                });
                dispatch({
                    key: `subImage1Name`,
                    type: 'input',
                    value: name
                });
            },
            setImage: setImage1,
            setComplete: setIsComplete1
        },
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage2CropperTag,
            setCropInfo: value => {
                dispatch({
                    key: `subImage2CropInfo`,
                    type: 'input',
                    value
                })
            },
            setImageUrl: (key, name) => {
                dispatch({
                    key: `subImage2Key`,
                    type: 'input',
                    value: key
                });
                dispatch({
                    key: `subImage2Name`,
                    type: 'input',
                    value: name
                });
            },
            setImage: setImage2,
            setComplete: setIsComplete2
        },
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage3CropperTag,
            setCropInfo: value => {
                dispatch({
                    key: `subImage3CropInfo`,
                    type: 'input',
                    value
                })
            },
            setImageUrl: (key, name) => {
                dispatch({
                    key: `subImage3Key`,
                    type: 'input',
                    value: key
                });
                dispatch({
                    key: `subImage3Name`,
                    type: 'input',
                    value: name
                });
            },
            setImage: setImage3,
            setComplete: setIsComplete3
        }
    ];
    // 메세지 표시하기 메소드
    const showMessage = (txt, type) => {
        // type이 정의되지 않았을 경우 
        if (type === undefined)
            type = false;

        // 메세지 표시 
        setMessage({
            type,
            txt
        });

        setTimeout(() => {
            // 2초 뒤 메세지 초기화 
            setMessage({
                ...message,
                txt: ''
            });
        }, 2000);
    };
    // 이미지 다운로드 버튼 클릭 이벤트 처리기 메소드 
    const onClickImageDownload = async () => {
        // 크롭된 이미지들 
        const { croppedSubImage1DataUrl, croppedSubImage2DataUrl, croppedSubImage3DataUrl } = state;

        let a = document.createElement('a');
        a.href = croppedSubImage1DataUrl;
        a.download = '서브이미지1';
        a.click();

        a.href = croppedSubImage2DataUrl;
        a.download = '서브이미지2';
        a.click();

        a.href = croppedSubImage3DataUrl;
        a.download = '서브이미지3';
        a.click();
    };

    const setCompleteCallback = () => {
        if (imageType === 1) {
            if (!isComplete2) {
                setImageType(2);
                return;
            }
            if (!isComplete3) {
                setImageType(3);
                return;
            }
        }

        if (imageType === 2) {
            if (!isComplete3) {
                setImageType(3);
                return;
            }
            if (!isComplete1) {
                setImageType(1);
                return;
            }
        }

        if (imageType === 3) {
            if (!isComplete1) {
                setImageType(1);
                return;
            }
            if (!isComplete2) {
                setImageType(2);
                return;
            }
        }
    };

    const setCallback = () => { };
    const setFileCallback = () => { };

    // 크롭된 이미지들 
    const { croppedSubImage1, croppedSubImage2, croppedSubImage3 } = state;
    return (
        <Field>
            <Title>
                <span>{t("subImageTitle")}</span>
                <BalloonDescription>{t('goods.subImageBallonDescription')}</BalloonDescription>
            </Title>
            <Flex align="center" type="space-between">
                <Flex align="center" >
                    <ImageType active={imageType === 1} completed={isComplete1} onClick={() => { setImageType(1); }}>
                        {t('subThumbnail')}1
                        {!state.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                    <TypeDivision />
                    <ImageType active={imageType === 2} completed={isComplete2} onClick={() => { setImageType(2); }}>
                        {t('subThumbnail')}2
                        {!state.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                    <TypeDivision />
                    <ImageType active={imageType === 3} completed={isComplete3} onClick={() => { setImageType(3); }}>
                        {t('subThumbnail')}3
                        {!state.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                </Flex>
                <Flex>
                    {userInfo.isAdmin &&
                        <WhiteButton height="28"
                            onClick={onClickImageDownload}
                            disabled={!croppedSubImage1 || !croppedSubImage2 || !croppedSubImage3}
                        >
                            {t('downloadImage')}
                        </WhiteButton>
                    }
                </Flex>
            </Flex>
            <Item>
                <Value>
                    {message.txt && <Message type={message.type}>{message.txt}</Message>}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        croppedImageBlob={state.croppedSubImage1}
                        setCroppedImageBlob={value => {
                            dispatch({
                                key: 'croppedSubImage1',
                                type: 'input',
                                value
                            })
                        }}
                        croppedImageDataUrl={state.croppedSubImage1DataUrl}
                        setCroppedImageDataUrl={value => {
                            dispatch({
                                key: 'croppedSubImage1DataUrl',
                                type: 'input',
                                value
                            })
                        }}
                        imgType={'subImage1'}
                        cropperTag={subImage1CropperTag}
                        disabled={state.isDisp}
                        image={image1}
                        setImage={setImage1}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete1}
                        setComplete={setIsComplete1}
                        isShow={imageType === 1}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        croppedImageBlob={state.croppedSubImage2}
                        setCroppedImageBlob={value => {
                            dispatch({
                                key: 'croppedSubImage2',
                                type: 'input',
                                value
                            })
                        }}
                        croppedImageDataUrl={state.croppedSubImage2DataUrl}
                        setCroppedImageDataUrl={value => {
                            dispatch({
                                key: 'croppedSubImage2DataUrl',
                                type: 'input',
                                value
                            })
                        }}
                        imgType={'subImage2'}
                        cropperTag={subImage2CropperTag}
                        disabled={state.isDisp}
                        image={image2}
                        setImage={setImage2}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete2}
                        setComplete={setIsComplete2}
                        isShow={imageType === 2}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        croppedImageBlob={state.croppedSubImage3}
                        setCroppedImageBlob={value => {
                            dispatch({
                                key: 'croppedSubImage3',
                                type: 'input',
                                value
                            })
                        }}
                        croppedImageDataUrl={state.croppedSubImage3DataUrl}
                        setCroppedImageDataUrl={value => {
                            dispatch({
                                key: 'croppedSubImage3DataUrl',
                                type: 'input',
                                value
                            })
                        }}
                        imgType={'subImage3'}
                        cropperTag={subImage3CropperTag}
                        disabled={state.isDisp}
                        image={image3}
                        setImage={setImage3}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete3}
                        setComplete={setIsComplete3}
                        isShow={imageType === 3}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                </Value>
            </Item>
            {
                state.croppedSubImage1DataUrl && state.croppedSubImage2DataUrl && state.croppedSubImage3DataUrl && (
                    <ImageBox id="thumbnailImage">
                        <div>
                            <img src={state.croppedSubImage1DataUrl} />
                        </div>
                        <div>
                            <div>
                                <img src={state.croppedSubImage2DataUrl} />
                            </div>
                            <div>
                                <img src={state.croppedSubImage3DataUrl} />
                            </div>
                        </div>
                    </ImageBox>
                )
            }
        </Field>
    );
});