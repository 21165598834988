import React from 'react';
import { ButtonWrapper, ImageModalContainer } from './ImageModal.styles';
import { IImageModalProps } from './ImageModal.types';
import { Modal } from 'react-bootstrap';
import { Button } from 'acon-mui/components';
import { useTranslation } from 'react-i18next';

const ImageModal = ({ src, show, maxWidth, close }: IImageModalProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <ImageModalContainer show={show} maxWidth={maxWidth} onClick={() => console.log('예???;;')}>
      <Modal.Body>
        <img src={src} alt="modal" />
      </Modal.Body>
      <ButtonWrapper onClick={close} />
    </ImageModalContainer>
  );
};

export default ImageModal;
