import React from "react";
import { Box, Typography, styled } from '@mui/material';
import { color } from "acon-mui/style";

interface Props {
  text: React.ReactNode;
  buttons?: {
    text: string|null|undefined;
    isSelected: boolean;
    disabled: boolean;
    onClick: () => void;
  }[];
}

const Button = styled('button')((props: { marginLeft?: string; checked: boolean }) => ({
  width: '86px',
  height: '36px',
  borderRadius: '4px',
  marginLeft: props.marginLeft || '0',
  ...props.checked ?
    {
      color: color.primary,
      border: `1px solid ${color.primary}`,
      background: `${color.primary}1F`
    } : {
      color: '#000000DE',
      border: '1px solid #000000DE',
      background: '#fff'
    }
}));
  

export default function Question({
  text,
  buttons,
}: Props) {
  return (
    <>
      <Typography
        color="#000000DE"
        fontWeight="500"
        fontSize="14px"
        lineHeight="22px"
        letterSpacing="0.1px"
        sx={{
          '& span': { fontWeight: '700' }
        }}
      >
        {text}
      </Typography>
      <Box mt="12px" display="flex">
        {buttons.map((button, i) => (
          <Button
            type="button"
            key={`${text}_${button.text}_${i}`}
            disabled={button.disabled}
            onClick={button.onClick}
            checked={button.isSelected}
            {...i > 0 && { marginLeft: '12px' }}
          >
            {button.text}
          </Button>
        ))}
      </Box>
    </>
  );
};
