import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GuideContainer, Circle, Tooltip } from './style';
import { CustomText } from 'components/style';

export default function Guide() {
  // 번역 도구
  const { t, i18n } = useTranslation();
  const [viewTooltip, setViewTooltip] = useState(false);

  return (
    <GuideContainer>
      <Tooltip show={viewTooltip}>
        <CustomText bold size={12}>
          {t('signup.signupGuide')}
        </CustomText>
      </Tooltip>
      <Circle
        onMouseOver={() => setViewTooltip(true)}
        onMouseOut={() => setViewTooltip(false)}
        onClick={() => {
          const href = 'https://partnerguide.acon3d.com/';
          window.open(href, '_blank');
        }}
      >
        <img src={'/assets/icon/signin/guide.svg'}></img>
      </Circle>
    </GuideContainer>
  );
}
