import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Quill, EDITOR_ALLOW_IMAGE_PATH_LIST } from './constants';
import Delta from 'quill-delta';
import { sendFiles } from './ImageUploader';
import LinkDialog from './dialog/LinkDialog';
import ImageDialog from './dialog/ImageDialog';
import VideoDialog from './dialog/VideoDialog';
import FileSizeDialog from './dialog/FileSizeDialog';
import Loading from './Loading';
import parse from 'html-react-parser';

import { Icon } from 'rsuite';
import { InformationCard } from 'acon-mui/components/Board';
import { Box } from '@mui/material';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import './index.scss';

export default (props) => {
  const { isWarningVisible = true } = props;
  const { t } = useTranslation();
  const quillRef = useRef();
  const [height, setHeight] = useState(props.defaultHeight || +sessionStorage.getItem('editorHeight') || 500);
  const [isShowLoading, loading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [linkText, setLinkText] = useState('');
  const [isShowLinkDialog, showLinkDialog] = useState(false);
  const [isShowImageDialog, showImageDialog] = useState(false);
  const [isShowVideoDialog, showVideoDialog] = useState(false);
  const [isShowFileSizeDialog, showFileSizeDialog] = useState(false);

  const modules = {
    toolbar: {
      container: `#${`Editor-toolbar${props.docId && props.docId.length > 0 ? `-${props.docId}` : ''}`}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        'list autofill': {
          shiftKey: true,
        },
      },
    },
  };

  const preventDefault = (e) => e?.dataTransfer.files.length && e.preventDefault();
  const undo = () => quillRef.current.editor.history.undo();
  const redo = () => quillRef.current.editor.history.redo();
  const resize = (adjustHeight) =>
    setHeight((prev) => {
      let newHeight = prev + adjustHeight;

      if (!newHeight || typeof newHeight !== 'number') {
        newHeight = 500;
      }

      sessionStorage.setItem('editorHeight', newHeight);

      return newHeight;
    });

  const onDrop = async (e) => {
    e.persist();

    const fileList = e.dataTransfer.files;

    if (!fileList || !fileList.length) {
      return;
    }
    e.preventDefault();

    await uploadImages(fileList);
  };

  const onChange = () => {
    const quill = quillRef.current?.editor || null;
    if (props.onChange && quill) {
      props.onChange(quill.root.innerHTML);
    }
  };

  const onPaste = (e) => {
    // 어드민계정은 붙여넣기에 제약을 받지 않음
    if (props.isAdmin) return true;

    const clipboardData = (e.originalEvent || e).clipboardData || window.clipboardData;
    const clipboardText = (clipboardData?.getData('text/html') || clipboardData?.getData('text/plain'))?.trim();

    if (!clipboardText) return false;

    const tempDom = document.createElement('div');
    tempDom.innerHTML = clipboardText;

    const hasExceptImage = Array.prototype.some.call(tempDom.getElementsByTagName('img'), (img) => {
      return EDITOR_ALLOW_IMAGE_PATH_LIST.every((path) => {
        return !img.src.startsWith(path);
      });
    });

    if (hasExceptImage) {
      props.showAlertMessage(' ', t('incorrectInformation'));
      setTimeout(() => {
        props.closeConfirmMessage();
      }, 2000);
      return false;
    }
  };

  const insertImage = (url) => {
    const quill = quillRef.current?.editor || null;
    if (!quill) return;

    url = url.trim();

    if (url?.length <= 0) {
      props.showAlertMessage(t('imageDialog.title'), t('imageDialog.alert'));
      return false;
    }
    const range = quill.getSelection(true) || quill.selection.savedRange;
    const { index } = range;

    quill.insertEmbed(index, 'image', url);
    return true;
  };

  const uploadImages = async (files) => {
    // url 들
    try {
      const urls = (await sendFiles(files)).reverse();
      for (let i = 0; i < urls.length; i++) {
        insertImage(urls[i]);
      }
      loading(false);
      return urls;
    } catch (e) {
      if (e.message === 'common.fileUploadLimit') {
        showFileSizeDialog(true);
      }
      loading(false);
    }
  };

  const insertVideo = (url) => {
    if (!url) {
      props.showAlertMessage(t('videoDialog.title'), t('videoDialog.alert'));
      return false;
    }
    const quill = quillRef.current?.editor || null;
    const range = quill.getSelection(true) || quill.selection.savedRange;
    const { index } = range;

    quill.insertEmbed(index, 'video', url);
    quill.formatText(index, 2, 'width', '100%');
    quill.formatText(index, 2, 'height', '306px');
    return true;
  };

  const onLinkOpenClick = () => {
    const quill = quillRef.current?.editor || null;
    if (!quill) return;

    let text = '';

    if (quill.hasFocus() && window.getSelection().toString().trim().length) {
      text = window.getSelection().toString().trim();
    }

    setLinkText(text);
    showLinkDialog(true);
  };

  const insertLink = (text, url) => {
    const quill = quillRef.current?.editor || null;
    if (!quill) return;
    if (text.length <= 0 || url.length <= 0) {
      props.showAlertMessage(t('linkDialog.title'), t('linkDialog.alert'));
      return false;
    }

    const range = quill.getSelection(true) || quill.selectionㅉ.savedRange;
    const { index, length } = range;

    quill.updateContents(new Delta().retain(index).delete(length).insert(text, { link: url }));
    return true;
  };

  useEffect(() => {
    // quill 클래스 확장
    const OriginalImage = Quill.import('formats/image');
    const OriginalVideo = Quill.import('formats/video');
    class Image extends OriginalImage {
      static create(value) {
        let node = super.create(value);
        console.log(node, 'image');
        node.style.maxWidth = '100%';
        node.style.marginTop = '5px';
        node.style.marginBottom = '5px';
        return node;
      }
    }
    class Video extends OriginalVideo {
      static create(value) {
        let node = super.create(value);
        console.log(node, 'video');
        node.setAttribute('src', value);
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        node.setAttribute('width', '100%');
        node.setAttribute('height', '306px');
        return node;
      }
      static formats(node) {
        return {
          src: node.getAttribute('src'),
          width: node.getAttribute('width'),
          height: node.getAttribute('height'),
        };
      }
    }

    Quill.register('formats/image', Image, true);
    Quill.register('formats/video', Video);
    Quill.debug('error');
    setIsLoad(true);
  }, []);

  // quill initialization
  useEffect(() => {
    const quill = quillRef.current?.editor || null;
    if (!quill) return;
    const toolbar = quill.getModule('toolbar');

    if (props.isDisp) {
      quill.disable();
      toolbar.addHandler('link', () => false);
      toolbar.addHandler('image', () => false);
      toolbar.addHandler('video', () => false);
    } else {
      toolbar.addHandler('link', onLinkOpenClick);
      toolbar.addHandler('image', () => showImageDialog(true));
      toolbar.addHandler('video', () => showVideoDialog(true));
    }

    if (isLoad) {
      // clear text formatting on paste
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        const range = quill.getSelection() || quill.selection.savedRange;
        const targetFormat = quill.getFormat(range);

        delta.ops = delta.ops.map((op) => {
          const result = props.isAdmin ? op : { insert: op.insert };

          if (targetFormat?.header) {
            result.attributes = { bold: true };
          }

          return result;
        });

        return delta;
      });

      quill.on('text-change', onChange);
      quill.container.addEventListener('paste', onPaste);
      return () => {
        quill.off('text-change', onChange);
        quill.container.removeEventListener('paste', onPaste);
      };
    }
  }, [quillRef.current, isLoad]);

  return (
    <>
      <LinkDialog isShow={isShowLinkDialog} close={() => showLinkDialog(false)} insertLink={insertLink} defaultText={linkText} />
      <ImageDialog isShow={isShowImageDialog} close={() => showImageDialog(false)} uploadImages={uploadImages} showUrlInput={props.isAdmin} loading={loading} />
      <VideoDialog isShow={isShowVideoDialog} close={() => showVideoDialog(false)} insertVideo={insertVideo} />
      <FileSizeDialog isShow={isShowFileSizeDialog} close={() => showFileSizeDialog(false)} />
      <Box
        className={`Editor ${props.isDisp ? 'disabled' : ''}`}
        onDrop={onDrop}
        onDragOver={preventDefault}
        onDragEnter={preventDefault}
        sx={{
          '& .Editor-resize-handler': {
            borderColor: '#919EAB52 !important',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          },
        }}
      >
        {isShowLoading && <Loading />}
        <Box
          className="Editor-toolbar"
          id={`Editor-toolbar${props.docId && props.docId.length > 0 ? `-${props.docId}` : ''}`}
          sx={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderColor: '#919EAB52 !important',
          }}
        >
          <span className="ql-formats">
            <select className="ql-header">
              <option value="">{t('edit.body')}</option>
              <option value="4">{t('edit.title')}</option>
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
          </span>
          {props.isAdmin && (
            <span className="ql-formats">
              <button className="ql-blockquote"></button>
              <button className="ql-code-block"></button>
            </span>
          )}
          <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
          </span>
          <span className="ql-formats">
            <button onClick={undo}>
              <i className="note-icon-undo"></i>
              <Icon icon="undo" />
            </button>
            <button onClick={redo}>
              <Icon icon="repeat" />
            </button>
          </span>
          <span className="ql-formats">
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            <button className="ql-video"></button>
          </span>
        </Box>
        <ReactQuill ref={quillRef} value={props.contents} onChange={props.onChange} placeholder={props.placeholder} style={{ height }} modules={modules} theme={'snow'} />
        <Resizer resize={resize} />
      </Box>
      {isWarningVisible && (
        <InformationCard type="warning" marginTop="24px">
          {parse(t('editor.description'))}
        </InformationCard>
      )}
    </>
  );
};

function Resizer(props) {
  const refProps = {};
  let prev = 0;

  const resize = (diff) => props.resize && props.resize(diff);

  const onDrag = (e) => {
    const current = e.clientY;
    const diff = current - prev;

    if (diff !== 0) {
      resize(diff);
    }

    prev = current;
  };

  const disable = () => {
    document.body.style.userSelect = '';

    window.removeEventListener('mouseup', disable);
    window.removeEventListener('mousemove', onDrag);
  };

  refProps.className = 'Editor-resize-handler';
  refProps.onMouseDown = (e) => {
    e.target.focus();

    document.body.style.userSelect = 'none';

    prev = e.clientY;

    window.addEventListener('mouseup', disable);
    window.addEventListener('mousemove', onDrag);
  };

  return React.createElement('div', refProps);
}
