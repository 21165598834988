import React, { useEffect, useState } from 'react';
import { GetPartnerQuery, LanguageCodeEnum, PartnerTypeEnum, useGetPartnerQuery, useRequestPartnerSettleMutation } from 'generated/graphql';
import { Skeleton, Stack, Typography, Button, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserFormType } from './type';
import ConfirmRequestDialog from './Dialog/ConfirmRequestDialog';
import LabelField from './field/LabelField';
import LabelInputField from './field/LabelInputField';
import { findCountryName } from 'signup/assets/iso_3166';
import IdCardField from './field/IdCardField';
import BankSelectField from './field/BankSelectField';
import BankBookField from './field/BankBookField';
import AccountField from './field/AccountField';
import PaymentDocument from './field/PaymentDocument';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  bankAccountNumberState,
  bankAccountOwnerState,
  bankBookState,
  bankIdState,
  bankNameState,
  idCardState,
  isEmailPaymentAccountSelector,
  limitedTaxAplState,
  userSettleModifyState,
} from './User.atom';
import { useHistory } from 'react-router-dom';

export default function SettleModify({ userId }: UserFormType) {
  const { t } = useTranslation();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [bankId, setBankId] = useRecoilState(bankIdState);
  const limitedTaxApl = useRecoilValue(limitedTaxAplState);
  const isEmailPaymentAccount = useRecoilValue(isEmailPaymentAccountSelector);
  const idCard = useRecoilValue(idCardState);
  const bankBook = useRecoilValue(bankBookState);
  const bankName = useRecoilValue(bankNameState);

  const [userSettleModifyForm, setUserSettleModifyForm] = useRecoilState(userSettleModifyState);
  const [bankAccountNumber, setBankAccountNumber] = useRecoilState(bankAccountNumberState);
  const [bankAccountOwner, setBankAccountOwner] = useRecoilState(bankAccountOwnerState);

  const [requestPartnerSettleMutation] = useRequestPartnerSettleMutation();
  const { data, loading, refetch } = useGetPartnerQuery({
    fetchPolicy: 'no-cache',
  });
  const userData = data?.me;
  const isCompany = userData && userData.type === PartnerTypeEnum.Business;
  const isGlobal = userData && userData.joinLanguage !== LanguageCodeEnum.Ko;

  const initUserSettleForm = (settleData: GetPartnerQuery['me']['settle']) => {
    setUserSettleModifyForm({
      bankName: userData.joinLanguage === LanguageCodeEnum.Ko ? settleData.bankName : settleData.bankName ? settleData.bankName : 'paypal',
      bankAccountOwner: settleData.bankAccountOwner || userData.name,
      ...(settleData.bankId !== undefined &&
        settleData.bankId !== null && {
          bankId: settleData.bankId,
        }),
      ...(isGlobal && {
        bankId: 22,
      }),
      ...(settleData.bankAccountNumber && {
        bankAccountNumber: settleData.bankAccountNumber.replace('-', ''),
      }),
      ...(settleData.idCard && {
        idCard: {
          key: '',
          name: settleData.idCard.fileName,
        },
      }),
      ...(settleData.bankBook && {
        bankBook: {
          key: '',
          name: settleData.bankBook.fileName,
        },
      }),
      ...(settleData.limitedTaxApl && {
        limitedTaxApl: {
          key: '',
          name: settleData.limitedTaxApl.fileName,
        },
      }),
    });
  };

  useEffect(() => {
    if (userData) {
      initUserSettleForm(userData.settle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleOpenDialog = () => setIsShowDialog(true);
  const handleCloseDialog = () => setIsShowDialog(false);

  const handleAccountNumberChange = (accountNumber: string) => {
    setBankAccountNumber(accountNumber.replace(/\D/g, ''));
  };

  const handleAccountOwnerChange = (accountOwner: string) => {
    setBankAccountOwner(accountOwner);
  };

  const handleBankChange = (bankCode: number) => {
    setBankId(bankCode);
  };

  const handleSaveButtonClick = async () => {
    setIsLoading(true);

    try {
      const { idCard, bankBook, limitedTaxApl } = userSettleModifyForm;

      const result = await requestPartnerSettleMutation({
        variables: {
          ...userSettleModifyForm,
          ...(!idCard?.key && idCard?.key != null ? { idCard: undefined } : { idCard: idCard }),
          ...(!bankBook?.key && bankBook?.key != null ? { bankBook: undefined } : { bankBook: bankBook }),
          ...(!limitedTaxApl?.key && limitedTaxApl?.key != null ? { limitedTaxApl: undefined } : { limitedTaxApl: limitedTaxApl }),
        },
      });

      if (!result) throw new Error();

      const refetchResult = await refetch();
      initUserSettleForm(refetchResult.data.me.settle);
      handleCloseDialog();
      history.push('/user');
      window.location.reload();
    } catch (err) {
      console.log(err.message);
      alert('수정 처리 중 오류 발생');
    }

    setIsLoading(false);
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <Box maxWidth={'576px'} width={'100%'} sx={{ mt: '57px' }}>
      <Stack direction={'column'} alignItems={'center'}>
        <Typography fontWeight={700} fontSize={24} lineHeight={'36px'} color={'grey.800'}>
          {t('user.settle.modify.information')}
        </Typography>

        <Box sx={{ mt: 1 }} />

        <Typography fontWeight={500} fontSize={14} lineHeight={'22px'} color={'grey.800'}>
          {t('user.settle.modify.description')}
        </Typography>
      </Stack>

      <Box sx={{ mt: 3 }} />

      <Card sx={{ p: 3 }}>
        <LabelField label={isCompany ? t('user.settle.modify.company') : t('user.settle.modify.name')} value={isCompany ? userData.companyName : userData.name} />

        <Box sx={{ mt: 2 }} />

        {!isGlobal && (
          <>
            <LabelField label={isCompany ? '사업자등록번호' : '주민등록번호'} value={userData.settle.nationalIdNumber} />

            <Box sx={{ mt: 2 }} />

            {isCompany && (
              <>
                <LabelField label={'대표자'} value={userData.name} />
                <Box sx={{ mt: 2 }} />
              </>
            )}

            <IdCardField isCompany={isCompany} />

            <Box sx={{ mt: 2 }} />

            {!isCompany && <LabelField label={'예금주'} value={userData.name} />}
            {isCompany && (
              <LabelInputField
                label={'예금주'}
                value={bankAccountOwner}
                onChange={(e) => {
                  handleAccountOwnerChange(e.target.value);
                }}
              />
            )}

            <Box sx={{ mt: 2 }} />

            <BankSelectField
              value={bankId}
              onChange={(e) => {
                handleBankChange(Number(e.target.value));
              }}
            />

            <Box sx={{ mt: 2 }} />

            <LabelInputField
              label={'계좌번호'}
              value={bankAccountNumber}
              onChange={(e) => {
                handleAccountNumberChange(e.target.value);
              }}
              placeholder={'숫자만 입력해주세요.'}
            />

            <Box sx={{ mt: 2 }} />

            <BankBookField />
          </>
        )}

        {isGlobal && (
          <>
            <LabelField label={'Country of Residence'} value={findCountryName(userData.settle.country)} />

            <Box sx={{ mt: 2 }} />

            <AccountField />

            <Box sx={{ mt: 2 }} />

            <PaymentDocument />
          </>
        )}

        <Box sx={{ mt: 3 }} />

        <Button
          variant="contained"
          size={'large'}
          sx={{ width: '100%' }}
          onClick={handleOpenDialog}
          disabled={
            isGlobal
              ? // 계정 / 세금제한 / ID card
                !bankAccountNumber || !limitedTaxApl || !idCard || !isEmailPaymentAccount
              : // 신분증 사본 / 은행 / 계좌번호 / 통장사본
                !idCard || !bankId || !bankAccountNumber || !bankBook || (bankId === 22 && !bankName)
          }
        >
          {t('user.settle.modify.button')}
        </Button>
      </Card>

      <ConfirmRequestDialog isOpen={isShowDialog} loading={isLoading} onClose={handleCloseDialog} onSubmit={handleSaveButtonClick} />
    </Box>
  );
}
