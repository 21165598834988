import React from 'react';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation()
    const { data, event, refId } = props
    const onClick = event.onClick(data, refId)

    // debugger
    var dom = (<></>)
    if (data.isUpdate !== undefined) {
        if (data.isUpdate) {

            const onAddClick = event.onAddClick(data)
            dom = (<div>
                <button name='update' onClick={onClick}>{t('authManager.modify')}</button>
                <button name='add' onClick={onAddClick}>{t('authManager.add')}</button>
                <button name='delete' onClick={onClick}>{t('authManager.delete')}</button>
            </div>)
        } else {
            dom = (<div>
                <button name="save" onClick={onClick}>{t('authManager.save')}</button>
            </div>)
        }
    }
    return dom
}
