import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import parse from 'html-react-parser';
import { ReactComponent as IconArchive } from './archive.svg';
import { ReactComponent as IconBox } from './box-seam.svg';
import { ReactComponent as IconCash } from './cash-stack.svg';
import { ReactComponent as IconDownload } from './cloud-download.svg';
import { ReactComponent as IconUpload } from './cloud-upload.svg';
import { ReactComponent as IconGraph } from './graph-up.svg';
import { ReactComponent as IconPen } from './pen.svg';
import { ReactComponent as IconProtect } from './protect.svg';
import { ReactComponent as IconTelephone } from './telephone-inbound.svg';
import { ReactComponent as IconTwitter } from './twitter.svg';
import { useTranslation } from 'react-i18next';
import { Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';

const benefitContents = (t) => [
  { icon: <IconPen />, title: t('partnership.section03.description01') },
  { icon: <IconCash />, title: t('partnership.section03.description02') },
  { icon: <IconUpload />, title: t('partnership.section03.description03') },
  { icon: <IconBox />, title: t('partnership.section03.description04') },
  { icon: <IconDownload />, title: t('partnership.section03.description05') },
  { icon: <IconProtect />, title: t('partnership.section03.description06') },
  { icon: <IconGraph />, title: t('partnership.section03.description07') },
  { icon: <IconArchive />, title: t('partnership.section03.description08') },
  { icon: <IconTelephone />, title: t('partnership.section03.description09') },
  { icon: <IconTwitter />, title: t('partnership.section03.description10') },
];

export default function Benefits() {
  const { t } = useTranslation();
  const theme = useDesignSystemTheme();
  const benefits = useMemo(() => benefitContents(t), [t]);
  return (
    <Box pt="60px" pb="70px" bgcolor={theme.palette['color/primary/600']} textAlign="center">
      <Box mx="auto" width="fit-content">
        <Typography variant="typography/headline/h3/black" color="color/white">
          {parse(t('partnership.section03.title'))}
        </Typography>
        <Grid mt={5} mx="auto" display="grid" gridTemplateColumns="repeat(5, 2fr)" gap="20px 16px">
          {benefits.map((item) => (
            <Grid
              item
              key={item.title}
              p="20px"
              pb="22px"
              width="148px"
              height="160px"
              bgcolor="#3E3ECD99"
              borderRadius="8px"
              textAlign="left"
              display="flex"
              direction="column"
              gap="8px"
            >
              {item.icon}
              <Typography variant="typography/body/small/medium" color="color/white">
                {parse(item.title)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
