import React, { useEffect, useMemo, useState } from 'react';
import { Box, styled, Switch } from '@mui/material';
import { BrandSaveWrap, BrandTitleWrap, Title } from '../../index.styles';
import { Button } from 'acon-mui/components';
import { useTranslation } from 'react-i18next';
import { BrandReviewContainer } from './BrandReview.styles';
import { Tooltip, Whisper } from 'rsuite';
import { useBrandReviewsQuery, useMyBrandQuery, useUpdateBrandReviewMutation } from 'generated/graphql';

const CustomTooltip = styled(Tooltip)`
  & .rs-tooltip-inner {
    max-width: none !important;
  }
`;

const BrandReview = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [isBrandReviewEnabled, setIsBrandReviewEnabled] = useState(false);
  const lang = useMemo(() => {
    let lang = localStorage.getItem('i18nextLng');
    if (!lang) lang = i18n.language;
    return lang;
  }, [i18n.language]);
  const { data, loading, refetch } = useMyBrandQuery();
  const brand = data?.myBrand;

  const { data: brandReviewCount, loading: brandReviewCountLoading } = useBrandReviewsQuery({
    variables: { brandId: brand?.id ? brand.id : 0 },
    skip: !brand?.id,
  });
  const brandReviewCountData = brandReviewCount?.brandReviews;

  const [updateBrandReview] = useUpdateBrandReviewMutation();

  const handleOnToggleBrandReview = (e) => setIsBrandReviewEnabled(e.target.checked);
  const handleClickSave = async () => {
    try {
      await updateBrandReview({
        variables: { isOn: isBrandReviewEnabled },
      });
      refetch();
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    if (brand && brand?.showBrandReview) {
      setIsBrandReviewEnabled(true);
    }
  }, [brand]);

  if (loading || brandReviewCountLoading) return <div>Loading...</div>;
  return (
    <BrandReviewContainer>
      <BrandTitleWrap>
        {Boolean(brandReviewCountData?.count) ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Title>{t('BrandPage.review.title', { lng: lang })}</Title>
            <Switch checked={isBrandReviewEnabled} onChange={handleOnToggleBrandReview} />
          </Box>
        ) : (
          <Whisper placement="right" open speaker={<CustomTooltip>{t('BrandPage.review.helper', { lng: lang })}</CustomTooltip>}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Title>{t('BrandPage.review.title', { lng: lang })}</Title>
              <Switch checked={false} disabled />
            </Box>
          </Whisper>
        )}
      </BrandTitleWrap>
      <BrandSaveWrap>
        <Button colorTheme="primary" onClick={handleClickSave} minWidth="71px" width="fit-content" marginLeft="12px" borderRadius="4px">
          {t('BrandPage.brandPick.save', { lng: lang })}
        </Button>
      </BrandSaveWrap>
    </BrandReviewContainer>
  );
};

export default BrandReview;
