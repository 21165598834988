import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PartnerTypeEnum } from 'signup/type';
import { nameState, userTypeState } from './atom';

export default function NameField() {
  const { t } = useTranslation();
  const userType = useRecoilValue(userTypeState);
  const [name, setName] = useRecoilState(nameState);
  const displayName = userType === PartnerTypeEnum.Business ? '대표자' : t('user.name');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{displayName}</Typography>
      <TextField size="small" sx={{ maxWidth: '240px' }} value={name} onChange={handleChange} />
    </Stack>
  );
}
