import styled from 'styled-components';

export const TwinkleSaleWriteContainer = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 120px;
  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : 'width: 592px;')}
`;

export const TwinkleSaleWriteFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.2);
`;
