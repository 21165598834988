import { Card, Dialog, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Button } from 'acon-mui/components';
import palette from 'theme/palette';
import { patterns } from './VideoDialog.constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  insertVideo: (url: string | boolean) => boolean;
}

export default function VideoDialog({ isOpen, onClose, insertVideo }: Props) {
  const [url, setUrl] = useState('');

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };
  const createVideoUrl = (url: string) => {
    for (const pattern of patterns) {
      const match = url.match(pattern.re);
      if (match) {
        if (pattern.name === 'mp4|m4v|ogg|ogv|webm') return url;
        return pattern.process(match);
      }
    }
    return false;
  };

  const onClickConfirmButton = () => {
    const data = createVideoUrl(url);

    const result = insertVideo(data);
    if (result) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} BackdropProps={{ sx: { background: '#00000080' } }}>
      <Card sx={{ p: 3, color: '#212B36', maxWidth: '368px' }}>
        <Typography fontWeight={700} fontSize="18px" lineHeight="28px">
          동영상 추가
        </Typography>
        <Typography mt={1} color={palette.dark.text.secondary} fontSize="14px">
          YouTube, Vimeo, Vine, Instagram, DailyMotion, Youku, SoundCloud만 사용이 가능합니다.
        </Typography>
        <TextField fullWidth placeholder="동영상 URL을 입력해주세요." onChange={handleInput} sx={{ mt: 2 }} />
        <Button colorTheme="primary" variant="contained" onClick={onClickConfirmButton} marginTop="24px" height="48px" fontWeight={700}>
          동영상 추가
        </Button>
      </Card>
    </Dialog>
  );
}
