import React from 'react';
import * as Styled from '../styled';
import PageNaviMemo from '../PageNavi';
import SearchForm from '../search';
import BoardsInfoMemo from './board';

export const List = (({ totalPage, items, refetch, reorder }) => {
    
    return (
        <div>
            <div className="aconfaq-board">
                <table>
                    <colgroup>
                        <col width="100px" />
                        <col width="250px" />
                        <col />
                        <col width="110px" />
                    </colgroup>
                    <BoardsInfoMemo
                        refetch={refetch}
                        init={false}
                        reorder={reorder}
                        items={items}
                        type="full"
                    />
                </table>
            </div>
            <Styled.Div>
                <SearchForm />
            </Styled.Div>
            <Styled.Div>
                <PageNaviMemo totalPage={totalPage} />
            </Styled.Div>
        </div>
    );
});