import React, { useContext, useState, useEffect, useRef } from 'react';
import Event from './event'
import { AlertContext } from '../../../../common/AlertModal2'
import ControlArea from './controlArea'
import InputArea from './inputArea'
import { AuthDataContext } from '../authData'
export default (function (props) {

    const { ajax } = props
    const { select, setSelect, loading, setLoading, setListUpdate, setAddList, setSelectId } = useContext(AuthDataContext)
    const [data, setData] = useState(select)
    const refId = useRef(null);
    const alert = useContext(AlertContext)
    const event = Event(ajax, alert, loading, setLoading, setListUpdate, setData, setAddList, setSelectId)
    const onChange = event.onChange(data)
    useEffect(() => {
        // isUpdate는 수정 또는 추가 여부
        if (select) {
            if (select.isUpdate !== undefined) {
                // isLoad 상태에 로드 됬는지 체크 여부
                if (select.isLoad !== undefined && !select.isLoad) {
                    setData({ ...select })
                    setSelect((prevState) => ({ ...prevState, isLoad: true }))
                }
            } else if (select._id !== data._id) {
                setData({ ...select })
            }
        }
    }, [data, select, setSelect, setData])
    var divOpt = {
        className: ''
    }
    if (data && data.isUpdate === undefined) {
        divOpt.className = 'hide'
    }
    return (
        <div {...divOpt}>
            <InputArea {...{ data, onChange, loading, refId }}></InputArea>
            <ControlArea {...{ data, event, refId }}></ControlArea>
        </div>
    )
});
