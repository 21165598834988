import React from 'react';
import { useParams } from "react-router-dom";
import { Form } from "./form";
import { GET_FAQ_CREATOR_TYPES, GET_FAQ_CREATOR_WRITE } from "api/quries";
import { CREATE_FAQ_CREATOR, DELETE_FAQ_CREATOR, UPDATE_FAQ_CREATOR } from "api/mutations";
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export const FaqCreatorWrite = () => {
    const { t } = useTranslation();
    const { docId } = useParams();
    const { data: typeData } = useQuery(GET_FAQ_CREATOR_TYPES);
    const { data } = useQuery(GET_FAQ_CREATOR_WRITE, {
        fetchPolicy: "no-cache",
        variables: { id: Number(docId) },
        skip: !docId
    })
    // 글 저장시 실행하는 mutation 쿼리
    const [update] = useMutation(UPDATE_FAQ_CREATOR);
    const [create] = useMutation(CREATE_FAQ_CREATOR);
    const [remove] = useMutation(DELETE_FAQ_CREATOR);

    const save = docId ? update : create;

    // 제목 placeholder 
    const titlePlaceholder = t("Aconfaq.write.title2.placeholder");

    // 질문형 제목 placeholder 
    const subtitlePlaceholder = t("Aconfaq.write.subtitle2.placeholder");

    return (<Form
        titlePlaceholder={titlePlaceholder}
        subtitlePlaceholder={subtitlePlaceholder}
        item={data && data.faqCreator}
        types={typeData && typeData.faqCreatorTypes}
        save={save}
        remove={remove} />)
};