import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ImageDialog.scss';

// 요청하기 위한 모달입니다.
export default function (props) {
    const { close, isShow, insertLink, defaultText } = props;
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setText(defaultText);
        setUrl(defaultText);
    }, [defaultText]);


    const onClickConfirmButton = e => {
        const result = insertLink(text, url);

        if (result) {
            close();
        }
    }

    return (
        <Modal className="requestDialog" show={isShow} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('linkDialog.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="requestDialog__description">
                    {t('linkDialog.contents')}
                </div>
                <input type="text" value={text} onChange={(e) => setText(e.target.value)}/>
                <div className="requestDialog__description">
                    {t('linkDialog.url')}
                </div>
                <input type="text" value={url} onChange={(e) => setUrl(e.target.value)}/>
            </Modal.Body>
            <Modal.Footer>
                <button className="requestBtn" onClick={onClickConfirmButton}>
                    {t('linkDialog.button')}
                </button>
            </Modal.Footer>
        </Modal>
    )
};