import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const Wrap = styled(Modal)`
  div.modal-dialog {
    line-height: 20px !important;
    position: absolute;
    top: calc(50% - 240px);
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const Flex = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.div<{ show?: boolean; }>`
  color: red;
  padding-left: 5px;
  padding-top: 5px;
  ${props => (props.show ? "" : "display: none;")}
`;
