import React from 'react';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation()
    const { data, onChange, loading, refId } = props
    var { _id, describe, auth, defaultAuth, defaultValue, isUpdate } = data

    var render = <div>{data._id}</div>
    if (data && data.isUpdate !== undefined) {
        var defaultValueList = []
        var authList = ['write', 'writeAll', 'read', 'readAll', 'delete', 'deleteAll'].map((d) => {
            var opt = {
                checked: false,
                onChange,
                auth: d
            }
            if (loading) {
                delete opt.onChange
                opt.readOnly = true
            }
            if (auth && auth.length > 0) {
                opt.checked = auth.includes(d)
                if (opt.checked) defaultValueList.push(d)
            }

            return <label key={d}><input name='auth' type="checkbox" {...opt}></input>{d}</label>
        })
        if (defaultAuth) {
            defaultValueList = defaultValueList.map((d) => {
                var opt = {
                    checked: false,
                    onChange,
                    auth: d
                }
                if (loading) {
                    delete opt.onChange
                    opt.readOnly = true
                }
                if (defaultValue && defaultValue.length > 0) {
                    opt.checked = defaultValue.includes(d)
                    defaultValueList.push(d)
                }

                return <label key={d}><input name='defaultValue' type="checkbox" {...opt}></input>{d}</label>
            })

            defaultValueList = (
                <div className='row'>
                    <label>{t('authManager.anonymous')}</label>
                    {defaultValueList}
                </div>
            )
        } else {
            defaultValueList = null
        }
        var _idOpt = {
            onChange,
            ref: refId,
            value: _id || ''
        }

        var describeOpt = {
            onChange,
            value: describe || ''
        }
        var defaultAuthOpt = {
            onChange,
            // checked: defaultAuth
            value: defaultAuth || false
        }
        if (isUpdate | loading) {
            _idOpt.readOnly = isUpdate | loading
            delete _idOpt.onChange
            if (loading) {
                describeOpt.readOnly = loading
                delete describeOpt.onChange
                defaultAuthOpt.readOnly = loading
                delete defaultAuthOpt.onChange
            }
        }
        render = (
            <div>
                <div className='row'>
                    <label>api Id</label>
                    <input name='_id' type='text' maxLength="50" {..._idOpt}></input>
                </div>
                <div className='row'>
                    <label>{t('authManager.describe')}</label>
                    <input name='describe' type='text' maxLength="50" {...describeOpt}></input>
                </div>
                <div className='row'>
                    <label>{t('authManager.setAuth')}</label>
                    {authList}
                </div>
                <div className='row'>
                    <label>{t('authManager.useAnonymous')}</label>
                    <label><input name='defaultAuth' type="checkbox" {...defaultAuthOpt}></input>{t('authManager.use')}</label>
                </div>
                {defaultValueList}
            </div>
        )
    }
    return render

}
