import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import UploadField from './UploadField';
import { bankBookState } from 'user/User.atom';
import { useRecoilState } from 'recoil';
import AttachFileIcon from 'assets/icon_attach_file';

export default function BankBookField() {
  const [bankBook, setBankBook] = useRecoilState(bankBookState);

  const handleUploadFile = (fileName: string, key: string) => {
    setBankBook({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveUploadFile = () => {
    setBankBook(null);
  };

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body2'} color={'grey.800'} fontWeight={600}>
        통장사본
      </Typography>
      <Typography marginTop="2px !important" fontSize={12} lineHeight={'16px'} color={'grey.600'}>
        최대 업로드 파일 크기 : 10MB
      </Typography>

      {!bankBook && <UploadField onChange={handleUploadFile} />}

      {bankBook && (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={1} maxWidth="80%">
            <AttachFileIcon sx={{ flexShrink: 0 }} />
            <Typography fontSize={14} lineHeight={'22px'} textOverflow="ellipsis" overflow="hidden" flexShrink={1}>
              {bankBook.name}
            </Typography>
          </Stack>

          <Button variant={'outlined'} onClick={handleRemoveUploadFile}>
            삭제하기
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
