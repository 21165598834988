import React from 'react';
import { Box } from '@mui/material';
import {
  BottomSheet,
  Button,
  ChevronDownIcon,
  DateRangePicker,
  DateSelect,
  IconButton,
  MenuItem,
  RemoveIcon,
  SearchIcon,
  Select,
  TextField,
  Typography,
  useDesignSystemTheme,
} from 'carpenstreet-designsystem';
import { MobileSalesRecordsFilterProps } from './SalesRecordsFilter.types';
import { useTranslation } from 'react-i18next';
import { GetOrderLicenseNameField, GetOrderProductPeriodField, OrderLicenseType } from '../../../generated/graphql';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function MobileSalesRecordsFilter(props: MobileSalesRecordsFilterProps) {
  const {
    // state
    keyword,
    asset,
    currentAssetIdx,
    periodField,
    selectedPeriodButton,
    startDay,
    endDay,
    isRefunded,
    isFree,
    licenseType,
    condition,
    conditionKeyword,
    loading,
    // query result
    myAssets,
    // event handler
    onKeywordChange,
    onKeywordReset,
    onPeriodTypeChange,
    onPeriodTypeReset,
    onSelectStartDay,
    onSelectEndDay,
    onConditionKeywordChange,
    onConditionKeywordReset,
    onApplyButtonClick,
    // event handler factory
    makeAssetClickHandler,
    makePeriodButtonClickHandler,
    makeSellingStatusButtonClickHandler,
    makePaymentTypeButtonClickHandler,
    makeLicenseTypeButtonClickHandler,
    makeConditionButtonClickHandler,
    // etc
    isPeriodChanged,
  } = props;

  const { i18n, t } = useTranslation();
  const datePickerLocale = i18n.language !== 'ko' ? 'en' : 'ko';

  const theme = useDesignSystemTheme();

  const isNotDesktop = useMediaQuery(theme.breakpoints.down('largeTablet'));

  const [showKeywordBottomSheet, setShowKeywordBottomSheet] = React.useState(false);
  const [showPeriodBottomSheet, setShowPeriodBottomSheet] = React.useState(false);
  const [showDatePickerBottomSheet, setShowDatePickerBottomSheet] = React.useState(false);
  const [showSellingStatusBottomSheet, setShowSellingStatusBottomSheet] = React.useState(false);
  const [showPaymentTypeBottomSheet, setShowPaymentTypeBottomSheet] = React.useState(false);
  const [showLicenseTypeBottomSheet, setShowLicenseTypeBottomSheet] = React.useState(false);
  const [showConditionBottomSheet, setShowConditionBottomSheet] = React.useState(false);

  function handleToggleKeywordBottomSheet() {
    setShowKeywordBottomSheet((p) => !p);
  }

  function handleTogglePeriodBottomSheet() {
    setShowPeriodBottomSheet((p) => !p);
  }

  function handleToggleDatePickerBottomSheet() {
    if (!startDay || !endDay) return; // startDay, endDay 둘 중 하나라도 없으면 닫히지 않음
    setShowDatePickerBottomSheet((p) => !p);
  }

  function handleToggleSellingStatusBottomSheet() {
    setShowSellingStatusBottomSheet((p) => !p);
  }

  function handleTogglePaymentTypeBottomSheet() {
    setShowPaymentTypeBottomSheet((p) => !p);
  }

  function handleToggleLicenseTypeBottomSheet() {
    setShowLicenseTypeBottomSheet((p) => !p);
  }

  function handleToggleConditionBottomSheet() {
    setShowConditionBottomSheet((p) => !p);
  }

  function makeApplyButtonClickHandler(toggle: Function) {
    return () => {
      onApplyButtonClick();
      toggle();
    };
  }

  function makeResetButtonClickHandler(reset: Function, toggle: Function) {
    return () => {
      reset();
      onApplyButtonClick();
      toggle();
    };
  }

  // useMediaQuery로 해상도를 감지하고 있다가
  // 모바일 기기에서 데스크탑 기기로 해상도가 변경될 때,
  // 만약 켜져 있는 바텀시트가 존재한다면 바텀시트를 끈다
  React.useEffect(() => {
    if (!isNotDesktop) {
      setShowKeywordBottomSheet(false);
      setShowPeriodBottomSheet(false);
      setShowDatePickerBottomSheet(false);
      setShowSellingStatusBottomSheet(false);
      setShowPaymentTypeBottomSheet(false);
      setShowLicenseTypeBottomSheet(false);
      setShowConditionBottomSheet(false);
    }
  }, [isNotDesktop]);

  return (
    <>
      {/* 필터 목록 */}
      <Box
        sx={{
          width: '100%',
          display: 'none',
          gap: '8px',
          padding: '16px',
          overflow: 'scroll',
          backgroundColor: theme.palette['color/white'],
          [theme.breakpoints.down('largeTablet')]: {
            display: 'flex',
          },
        }}
      >
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleToggleKeywordBottomSheet}
          color={asset ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.keyword.title')}
        </Button>
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleTogglePeriodBottomSheet}
          color={isPeriodChanged ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.period.title')}
        </Button>
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleToggleSellingStatusBottomSheet}
          color={isRefunded !== null ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.status.title')}
        </Button>
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleTogglePaymentTypeBottomSheet}
          color={isFree !== null ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.payment.title')}
        </Button>
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleToggleLicenseTypeBottomSheet}
          color={licenseType !== null ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.license.title')}
        </Button>
        <Button
          variant={'outlined'}
          size={'M'}
          endIcon={<ChevronDownIcon />}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleToggleConditionBottomSheet}
          color={conditionKeyword ? 'primary' : 'default'}
          disabled={loading}
        >
          {t('salesRecordsPage.filter.condition.title')}
        </Button>
      </Box>
      {/* 상품 검색 바텀시트 */}
      <BottomSheet
        height={'full'}
        open={showKeywordBottomSheet}
        onClose={makeApplyButtonClickHandler(handleToggleKeywordBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.keyword.title')}</Typography>}
        renderContent={
          <Box>
            <TextField
              variant={'outlined'}
              size={'M'}
              fullWidth={true}
              placeholder={t('salesRecordsPage.filter.keyword.placeholder')}
              autoComplete={'off'}
              InputProps={{
                startAdornment: <SearchIcon />,
                ...(asset && {
                  endAdornment: (
                    <IconButton size={'S'} onClick={onKeywordReset}>
                      <RemoveIcon color={'color/gray/600'} />
                    </IconButton>
                  ),
                }),
                sx: {
                  '&.Mui-disabled': {
                    color: theme.palette['color/gray/800'],
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette['color/gray/600'],
                    },

                    '& path:not([fill="white"]), & circle:not([fill="white"])': {
                      fill: theme.palette['color/gray/800'],
                    },
                    '& rect:not([fill="white"])': {
                      stroke: theme.palette['color/gray/800'],
                    },
                  },
                },
              }}
              value={keyword}
              onChange={onKeywordChange}
              disabled={Boolean(asset)}
            />
            <Box
              sx={{
                marginTop: '8px',
                width: '100%',
                overflow: 'scroll',
              }}
            >
              {keyword &&
                !asset && // 검색어가 있고 선택된 상품이 없을 때만 보여줌
                (myAssets.length === 0 ? (
                  <Box sx={{ padding: '8px 16px' }}>
                    <Typography variant={'typography/body/small/regular'}>{t('salesRecordsPage.filter.keyword.noItemMessage')}</Typography>
                  </Box>
                ) : (
                  myAssets.slice(0, 5).map((asset, idx) => (
                    <MenuItem key={asset.id} onClick={makeAssetClickHandler(asset)} selected={idx === currentAssetIdx}>
                      {asset.title}
                    </MenuItem>
                  ))
                ))}
            </Box>
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button variant={'outlined'} size={'L'} sx={{ whiteSpace: 'nowrap' }} onClick={makeResetButtonClickHandler(onKeywordReset, handleToggleKeywordBottomSheet)}>
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleToggleKeywordBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
      {/* 판매 기간 바텀 시트 */}
      <BottomSheet
        height={'hug'}
        open={showPeriodBottomSheet}
        onClose={makeApplyButtonClickHandler(handleTogglePeriodBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.period.title')}</Typography>}
        renderContent={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
            <Select size={'M'} value={periodField} onChange={onPeriodTypeChange}>
              <MenuItem value={GetOrderProductPeriodField.CreatedAt}>{t('salesRecordsPage.filter.period.periodType.payment')}</MenuItem>
              <MenuItem value={GetOrderProductPeriodField.SettledAt}>{t('salesRecordsPage.filter.period.periodType.settle')}</MenuItem>
            </Select>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
              <Button
                variant={'outlined'}
                size={'L'}
                color={selectedPeriodButton === 'manual' ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makePeriodButtonClickHandler('manual')}
              >
                {t('salesRecordsPage.filter.period.periodButton.manual')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={selectedPeriodButton === 'one-month' ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makePeriodButtonClickHandler('one-month')}
              >
                {t('salesRecordsPage.filter.period.periodButton.oneMonth')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={selectedPeriodButton === 'three-months' ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makePeriodButtonClickHandler('three-months')}
              >
                {t('salesRecordsPage.filter.period.periodButton.threeMonth')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
              <Button
                variant={'outlined'}
                size={'L'}
                color={selectedPeriodButton === 'six-months' ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makePeriodButtonClickHandler('six-months')}
              >
                {t('salesRecordsPage.filter.period.periodButton.sixMonth')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={selectedPeriodButton === 'entire' ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makePeriodButtonClickHandler('entire')}
              >
                {t('salesRecordsPage.filter.period.periodButton.entire')}
              </Button>
            </Box>
            <DateSelect
              locale={datePickerLocale}
              value={{ startDay, endDay }}
              isRange={true}
              onClick={handleToggleDatePickerBottomSheet}
              disabled={selectedPeriodButton !== 'manual'}
            />
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={makeResetButtonClickHandler(() => {
                makePeriodButtonClickHandler('one-month')();
                onPeriodTypeReset();
              }, handleTogglePeriodBottomSheet)}
            >
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleTogglePeriodBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
      {/* DatePicker 바텀 시트 */}
      <BottomSheet
        height={'hug'}
        open={showDatePickerBottomSheet}
        onClose={handleToggleDatePickerBottomSheet}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.period.datePicker.title')}</Typography>}
        renderContent={
          <DateRangePicker
            startDay={startDay}
            onSelectStartDay={onSelectStartDay}
            endDay={endDay}
            onSelectEndDay={onSelectEndDay}
            locale={datePickerLocale}
            sx={{ boxShadow: 'unset', width: '100%' }}
          />
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                makePeriodButtonClickHandler('one-month')();
                handleToggleDatePickerBottomSheet();
              }}
            >
              {t('salesRecordsPage.filter.period.datePicker.reset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={handleToggleDatePickerBottomSheet} disabled={!startDay || !endDay}>
              {t('salesRecordsPage.filter.period.datePicker.apply')}
            </Button>
          </Box>
        }
      />
      {/* 판매 상태 바텀 시트 */}
      <BottomSheet
        height={'hug'}
        open={showSellingStatusBottomSheet}
        onClose={makeApplyButtonClickHandler(handleToggleSellingStatusBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.status.title')}</Typography>}
        renderContent={
          <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isRefunded === null ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makeSellingStatusButtonClickHandler(null)}
            >
              {t('salesRecordsPage.filter.status.all')}
            </Button>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isRefunded === false ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makeSellingStatusButtonClickHandler(false)}
            >
              {t('salesRecordsPage.filter.status.sold')}
            </Button>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isRefunded === true ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makeSellingStatusButtonClickHandler(true)}
            >
              {t('salesRecordsPage.filter.status.cancelled')}
            </Button>
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={makeResetButtonClickHandler(makeSellingStatusButtonClickHandler(null), handleToggleSellingStatusBottomSheet)}
            >
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleToggleSellingStatusBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
      {/* 유/무료 상품 바텀 시트 */}
      <BottomSheet
        height={'hug'}
        open={showPaymentTypeBottomSheet}
        onClose={makeApplyButtonClickHandler(handleTogglePaymentTypeBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.payment.title')}</Typography>}
        renderContent={
          <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isFree === null ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makePaymentTypeButtonClickHandler(null)}
            >
              {t('salesRecordsPage.filter.payment.all')}
            </Button>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isFree === false ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makePaymentTypeButtonClickHandler(false)}
            >
              {t('salesRecordsPage.filter.payment.paid')}
            </Button>
            <Button
              variant={'outlined'}
              size={'L'}
              color={isFree === true ? 'primary' : 'default'}
              sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
              onClick={makePaymentTypeButtonClickHandler(true)}
            >
              {t('salesRecordsPage.filter.payment.free')}
            </Button>
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={makeResetButtonClickHandler(makePaymentTypeButtonClickHandler(null), handleTogglePaymentTypeBottomSheet)}
            >
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleTogglePaymentTypeBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
      {/* 사용권 유형 바텀 시트 */}
      <BottomSheet
        height={'hug'}
        open={showLicenseTypeBottomSheet}
        onClose={makeApplyButtonClickHandler(handleToggleLicenseTypeBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.license.title')}</Typography>}
        renderContent={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
              <Button
                variant={'outlined'}
                size={'L'}
                color={licenseType === null ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makeLicenseTypeButtonClickHandler(null)}
              >
                {t('salesRecordsPage.filter.license.all')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={licenseType === OrderLicenseType.Personal ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Personal)}
              >
                {t('salesRecordsPage.filter.license.personal')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={licenseType === OrderLicenseType.Company ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', paddingLeft: 0, paddingRight: 0 }}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Company)}
              >
                {t('salesRecordsPage.filter.license.company')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%', ...(datePickerLocale === 'en' && { flexDirection: 'column' }) }}>
              <Button
                variant={'outlined'}
                size={'L'}
                color={licenseType === OrderLicenseType.Enterprise_5 ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', padding: '16px 0' }}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Enterprise_5)}
              >
                {t('salesRecordsPage.filter.license.enterprise_5')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={licenseType === OrderLicenseType.Enterprise_0 ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', padding: '16px 0' }}
                onClick={makeLicenseTypeButtonClickHandler(OrderLicenseType.Enterprise_0)}
              >
                {t('salesRecordsPage.filter.license.enterprise_0')}
              </Button>
            </Box>
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={makeResetButtonClickHandler(makeLicenseTypeButtonClickHandler(null), handleToggleLicenseTypeBottomSheet)}
            >
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleToggleLicenseTypeBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
      {/* 조건 검색 바텀시트 */}
      <BottomSheet
        height={'full'}
        open={showConditionBottomSheet}
        onClose={makeApplyButtonClickHandler(handleToggleConditionBottomSheet)}
        renderTitle={<Typography variant={'typography/body/medium/bold'}>{t('salesRecordsPage.filter.condition.title')}</Typography>}
        renderContent={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%', ...(datePickerLocale === 'en' && { flexDirection: 'column' }) }}>
              <Button
                variant={'outlined'}
                size={'L'}
                color={condition === GetOrderLicenseNameField.Author ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', padding: '16px 0' }}
                onClick={makeConditionButtonClickHandler(GetOrderLicenseNameField.Author)}
              >
                {t('salesRecordsPage.filter.condition.type.mobileCreator')}
              </Button>
              <Button
                variant={'outlined'}
                size={'L'}
                color={condition === GetOrderLicenseNameField.Project ? 'primary' : 'default'}
                sx={{ flex: '1 1 0px', padding: '16px 0' }}
                onClick={makeConditionButtonClickHandler(GetOrderLicenseNameField.Project)}
              >
                {t('salesRecordsPage.filter.condition.type.creation')}
              </Button>
            </Box>
            <TextField
              variant={'outlined'}
              size={'M'}
              fullWidth={true}
              placeholder={t('salesRecordsPage.filter.condition.placeholder')}
              autoComplete={'off'}
              InputProps={{
                startAdornment: <SearchIcon />,
                ...(conditionKeyword && {
                  endAdornment: (
                    <IconButton size={'S'} onClick={onConditionKeywordReset}>
                      <RemoveIcon color={'color/gray/600'} />
                    </IconButton>
                  ),
                }),
              }}
              value={conditionKeyword}
              onChange={onConditionKeywordChange}
            />
          </Box>
        }
        renderActions={
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              variant={'outlined'}
              size={'L'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={makeResetButtonClickHandler(() => {
                makeConditionButtonClickHandler(GetOrderLicenseNameField.Author)();
                onConditionKeywordReset();
              }, handleToggleConditionBottomSheet)}
            >
              {t('salesRecordsPage.filter.mobileReset')}
            </Button>
            <Button variant={'contained'} color={'primary'} size={'L'} sx={{ flexGrow: 1 }} onClick={makeApplyButtonClickHandler(handleToggleConditionBottomSheet)}>
              {t('salesRecordsPage.filter.apply')}
            </Button>
          </Box>
        }
      />
    </>
  );
}
