import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useMeQuery } from '../generated/graphql';
import { PATH_PAGE } from '../routes/paths';
import AdminGnb from './AdminGnb';
import { DesktopGNBProps } from './DesktopGNB/DesktopGNB.types';
import DesktopGNB from './DesktopGNB/DesktopGNB';
import MobileGNB from './MobileGNB/MobileGNB';
import { MobileGNBProps } from './MobileGNB/MobileGNB.types';

export default function Header() {
  const { push } = useHistory();
  const { isAuthenticated, logout, user } = useAuth();
  const {
    i18n: { language },
  } = useTranslation();

  const avatarRef = React.useRef(null);

  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const { data } = useMeQuery({
    skip: !isAuthenticated,
    fetchPolicy: 'no-cache',
  });

  function handleToggleUserMenu() {
    setShowUserMenu((p) => !p);
  }

  function handleToggleMobileMenu() {
    setShowMobileMenu((p) => !p);
  }

  async function handleLogoutClick() {
    await logout();
    handleToggleUserMenu();
    push(PATH_PAGE.root);
  }

  async function handleMobileLogoutClick() {
    await logout();
    handleToggleMobileMenu();
    push(PATH_PAGE.root);
  }

  const profileImg = data?.me?.brand?.photoInfo?.path;
  const brandName = data?.me?.brand?.i18ns.find((i18n) => (language === 'ko' ? i18n.languageCode === 'ko' : i18n.languageCode === 'en'))?.name;

  const desktopGNBProps = {
    showUserMenu,
    onToggleUserMenu: handleToggleUserMenu,
    avatarRef,
    profileImg,
    brandName,
    onLogoutClick: handleLogoutClick,
  } satisfies DesktopGNBProps;

  const mobileGNBProps = {
    showMobileMenu,
    onToggleMobileMenu: handleToggleMobileMenu,
    profileImg,
    brandName,
    onLogoutClick: handleMobileLogoutClick,
  } satisfies MobileGNBProps;

  return (
    <>
      <DesktopGNB {...desktopGNBProps} />
      <MobileGNB {...mobileGNBProps} />
      {isAuthenticated && user.isAdmin && <AdminGnb />}
    </>
  );
}
