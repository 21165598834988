import React, { useEffect, useContext, useMemo, useState } from 'react';
import {
	Wrap,
	Row,
	HalfCol,
	LayoutBox
} from './style';
import TreeView from './treeView'
import TreeInfo from './treeInfo';
import { CategoryDataContext } from './categoryData'
import { getCategoryInfo } from './ajax'
export default ((props) => {
	const { isLoading, setIsLoading, categoryData, setCategoryTypeList, setLangList } = useContext(CategoryDataContext)
	const [categories, setCategories] = useState([])

	useEffect(() => {
		if (categories.length) {
			const data = categories.filter(category => category.i18n.some(i18n => i18n.file?.uploadUrl !== null));
		}
	}, [categories]);

	useEffect(() => {
		if (!isLoading) {
			(async (setCategories) => {
				var data = await getCategoryInfo()
				setCategories(data.getCategoryList)
				setLangList(data.langCode)
				setCategoryTypeList(data.categoryType)
			})(setCategories)
			setIsLoading(true)
		}
	}, [isLoading, setIsLoading, setCategoryTypeList, setLangList])

	return (
		<Wrap>
			<Row>
				<HalfCol>
					<LayoutBox>
						<TreeView {...{ categories }}></TreeView>
					</LayoutBox>
				</HalfCol>
				<HalfCol>
					{categoryData.id &&
						<LayoutBox>
							<TreeInfo/>
						</LayoutBox>
					}
				</HalfCol>
			</Row>
		</Wrap>
	);
});