export default (loading, setSelect, setAddList) => {
    var onChange = function (authName, auth) {
        return (e) => {
            if (loading) {
                return
            } else {
                var checked = e.currentTarget.checked
                setSelect((prevStatus) => {
                    prevStatus[authName].auth[auth] = checked
                    return { ...prevStatus }
                })
            }
        }
    }
    var onClick = function (authName) {
        return () => {
            if (loading) {
                return
            } else {
                setAddList(authName, true)
            }
        }
    }

    return { onChange, onClick }
}