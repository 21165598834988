import React, { createContext, useState } from 'react'

// const initSelect = { _id: '', describe: '', auth: [], defaultAuth: false, defaultValue: [], isUpdate: undefined, isLoad: undefined }
export const TemplateDataContext = createContext()
export default (props) => {
    //     const [addList, setAddList] = useState([])
    const [selectId, setSelectId] = useState('5e6a4a47debe651d7f6bf7eb')
    // const [selectId, setSelectId] = useState()
    // const [selectData, setSelectData] = useState({
    //     _id: '',
    //     templateName: '',
    //     welcomeUrl: '',
    //     description: '',
    //     defaultTemplate: false,
    //     _auth: {}
    // })
    const [selectData, setSelectData] = useState()
    const [templateList, setTemplateList] = useState(null)
    const [authList, setAuthList] = useState({})
    const [loading, setLoading] = useState(false)

    return <TemplateDataContext.Provider value={{
        selectId,
        setSelectId,
        selectData,
        setSelectData,
        authList,
        setAuthList,
        loading,
        setLoading,
        templateList,
        setTemplateList,
    }} >
        {props.children}
    </TemplateDataContext.Provider >
}