import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { AuthDataContext } from '../../../authData'
import Event from './event'

export default (function (props) {
    const { addList, setAddList, authList, loading } = useContext(AuthDataContext)
    const { setSaveData } = props
    const { t } = useTranslation()
    const [data, setData] = useState({})
    // {
    //    xxx authId:{
    //         auth:{
    //             write:true,
    //             ...
    //         }
    //     }
    // }
    const [render, setRender] = useState(<></>)

    // const render = useMemo(() => {
    useEffect(() => {
        const event = Event(loading, setData, setAddList)
        const onChange = event.onChange
        const onClick = event.onClick
        if (authList.length > 0) {
            var authMap = {}
            authList.forEach(d => {
                authMap[d._id] = d
            })
            var tempData = {}
            var tempSaveData = []
            const tempRender = addList.map(d => {
                tempData[d] = { auth: {} }
                var checkBox = []
                var tempAuth = []
                for (var auth of authMap[d]['auth']) {
                    if (data[d] && data[d]['auth'][auth]) {
                        tempData[d]['auth'][auth] = data[d]['auth'][auth]
                        if (tempData[d]['auth'][auth]) {
                            tempAuth.push(auth)
                        }
                    } else {
                        tempData[d]['auth'][auth] = false
                    }
                    var checkOpt = {
                        checked: tempData[d]['auth'][auth],
                        onChange: onChange(d, auth)
                    }
                    if (loading) {
                        checkOpt.readOnly = true
                        delete checkOpt.onchange
                    }
                    checkBox.push(<label key={auth}>
                        <input name={auth} {...checkOpt} type='checkbox'></input>{auth}
                    </label >)
                }
                
                tempSaveData.push({ authName: d, auth: tempAuth })
                return (<div key={d}>
                    <label>{d}</label>
                    {checkBox}
                    <div><button onClick={onClick(d)}>{t('authManager.delete')}</button></div>
                </div>)
            })
            if (JSON.stringify(tempData) !== JSON.stringify(data)) {
                setData(tempData)
            }
            setSaveData(prevState => {
                return {
                    templateId: prevState.templateId,
                    templateAuthList: tempSaveData
                }
            })
            setRender(tempRender)
        }
    }, [authList, addList, setAddList, data, setData, loading, setSaveData,t])
    return <div>
        {render}
    </div>
})