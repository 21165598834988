import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    ${props => props.width ? `width: ${props.width}%;` : ``}
    ${props => props.direction ? `flex-direction: ${props.direction};` : ``}
    ${props => props.bottom ? `margin-bottom: ${props.bottom}px;` : `margin-bottom: 0px;`}
`;

export const MenuBar = styled(Flex)`
    align-items: baseline;
    justify-content: flex-start;
    column-gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
`;