import React from 'react';
import { color, TooltipWrapper } from 'acon-mui/style';
import { Box, Typography } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = React.CSSProperties & {
  children: React.ReactNode;
  checkboxVisible?: boolean;
  isChecked?: boolean;
  tooltip?: string | React.ReactNode;
  onClick?: (value, checked) => void;
};

export default function Title({ children, checkboxVisible = false, isChecked, tooltip, onClick, ...rest }: Props) {
  const { t } = useTranslation();

  if (!checkboxVisible)
    return (
      <Typography
        color={color.text.primary}
        fontWeight="700"
        fontSize={rest?.fontSize || '16px'}
        lineHeight="24px"
        position="relative"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="14px"
      >
        {children}
        {tooltip && <TooltipWrapper>{tooltip}</TooltipWrapper>}
      </Typography>
    );
  return (
    <Box sx={rest} display="flex" alignItems="center">
      <Typography
        color={color.text.primary}
        fontWeight="700"
        fontSize={rest?.fontSize || '16px'}
        lineHeight="24px"
        position="relative"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="14px"
      >
        {children}
        {tooltip && <TooltipWrapper>{tooltip}</TooltipWrapper>}
      </Typography>
      <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
        <FormControlLabel control={<Checkbox checked={isChecked} onChange={onClick} />} label={t('document.update')} />
      </FormGroup>
    </Box>
  );
}
