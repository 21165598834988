import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Event from './event'
import Ajax from './ajax'

import AuthList from './authList/'
import AuthInput from './authInput/'
import AuthTemplate from './authTemplate'

import { AlertContext } from '../../../common/AlertModal2'
import { AuthDataContext } from './authData'
RegExp.escape = function (s) {
    return s.replace(/[-\\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
export default (function (props) {

    const { t } = useTranslation()
    const { setSelectId,
        setAuthList,
        loading,
        setLoading,
        listUpdate,
        setListUpdate,
        setTemplateList,
        setSelect } = useContext(AuthDataContext)

    const alert = useContext(AlertContext)
    const event = Event(loading)

    const onClick = event.createClick(setSelect, setSelectId)
    const ajax = Ajax(t, alert, setLoading, setListUpdate)

    useEffect(() => {
        if (listUpdate === false) {
            async function getAuthList() {
                const { apiAuthList,
                    templateAuthList } = await ajax.getAuthList()
                setTemplateList(templateAuthList)
                setAuthList(apiAuthList)
            }
            setLoading(true)
            getAuthList()
            setListUpdate(true)
            setLoading(false)
        }
    }, [listUpdate, setLoading, ajax, setListUpdate, setTemplateList, setAuthList])

    var createOption = {
        onClick,
    }
    console.log('authManager')
    return <div>
        <div>
            <button value='create' {...createOption}>{t('authManager.new')}</button>
        </div>
        <AuthList></AuthList>
        <AuthInput {...{ ajax }}></AuthInput>
        <AuthTemplate></AuthTemplate>
    </div>
})

