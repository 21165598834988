import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
`;
export const Item = styled.div`
    width: 100px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 10px solid #${props => props.active ? `EB357B` : `c4c4c4`};
    padding: 5px;
    background-color: inherit;
`;