import styled from 'styled-components';

export const Ul = styled.ul`
  font-size: 14px;
`;

export const Li = styled.li``;

export const Question = styled.div`
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    background-color: white;
    border: 1px solid black;
  }
  .rs-radio-wrapper::before {
    border: 1px solid black;
  }
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 8px;
    height: 8px;
    background: black;
    margin-top: 4px;
    margin-left: 4px;
  }
`;

export const Div = styled.div`
  margin-top: 21px;
  margin-left: -9px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${(props) => (props.isDisp ? `pointer-events: none;` : ``)}
`;

export const CheckboxText = styled.div`
  font-size: 14px;
  width: calc(100% - 30px);
  line-height: 100%;
  margin-top: 8px;
`;

export const RequireText = styled.div`
  display: flex;
  align-items: center;
  line-height: 100%;
  color: #808080;
  font-size: 12px;
  padding: 1px 0;
`;
