

export default (loading) => {

    function createClick(setSelect, setSelectId) {
        return (e) => {
            if (loading) {
                return
            } else {
                setSelectId('')
                setSelect({ _id: '', describe: '', auth: [], defaultAuth: false, defaultValue: [], isUpdate: false, isLoad: false })
            }
        }
    }

    function openAlert(setAlertModalStatus) {
        return (contents, callback) => {
            setAlertModalStatus({
                // alert 모달 콜백 함수 
                alertModalCallback: callback,
                // alert modal 표시 여부
                isShowAlertModal: true,
                // alert modal 내용 
                alertModalContents: contents
            })

        }
    }
    var result = { createClick, openAlert }
    return result
}