
import { graphqlUrl } from 'api'
import axios from 'axios'
function getAlert(result, alert, deafultMessage = '', messageList = [], callbackList = []) {
    var callback
    var message = deafultMessage
    if (callbackList && callbackList[result.resultCode]) {
        callbackList = callbackList[result.resultCode]
        callback = () => { callbackList(result.data) }
    }
    if (result && messageList && messageList[result.resultCode]) {
        message = messageList[result.resultCode]
    }
    alert(message, callback)
}
export default (t, alert) => {
    async function updateTemplateAuth(input) {
        var result
        try {
            var { templateId, templateAuthList } = input
            var query = `mutation ($input: InputUpdateTemplateAuth!) {
                        updateTemplateAuth(input: $input) {
                              resultCode
                              message
                              data
                              error
                            }
                        }`
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: {
                    "input": { templateId, templateAuthList }
                }
            })
            result = data.data.data.updateTemplateAuth
        } catch (err) {
            console.log(err)
        }
        // console.log(result)
        getAlert(result, alert, t('authManager.unkownError'), [t('authManager.unkownError'), t('authManager.updateTemplateAuth_1'), t('authManager.apiNotgrant'), t('authManager.updateTemplateAuth_2')])
        return result
    }

    return {
        updateTemplateAuth
    }
}