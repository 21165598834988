import React, { Fragment, useMemo } from 'react';
import { Wrap } from './index.styles';
import { color } from 'acon-mui/style';
import { Box as MuiBox, Typography } from '@mui/material';
import { RecoilRoot } from 'recoil';
import BrandPick from './components/BrandPick/BrandPick';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { Field } from 'acon-mui/components/Board';
import { useTranslation } from 'react-i18next';
import BrandProfile from './components/BrandProfile/BrandProfile';
import BrandIntroduce from './components/BrandIntroduce/BrandIntroduce';
import BrandReview from './components/BrandReview/BrandReview';
import LangaugeSelector from './components/LangaugeSelector/LangaugeSelector';

const BrandPage = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const lang = useMemo(() => {
    let lang = localStorage.getItem('i18nextLng');
    if (!lang) lang = i18n.language;
    return lang;
  }, [i18n.language]);

  return (
    <RecoilRoot>
      <Wrap fullWidth>
        <Field sx={{ marginTop: '42px' }}>
          <MuiBox display="flex" alignItems="center">
            <Typography mr="auto" color={color.text.primary} fontWeight="700" fontSize="24px" lineHeight="36px">
              {t('BrandPage.title', { lng: lang })}
            </Typography>
            <LangaugeSelector />
          </MuiBox>
        </Field>

        <Field sx={{ minHeight: 225, marginTop: 3 }}>
          <BrandProfile />
        </Field>

        <Field
          sx={{ minHeight: 229 }}
          informationCards={
            <Fragment>
              <InformationCard type="notice">{t('BrandPage.introduce.description', { lng: lang })}</InformationCard>
            </Fragment>
          }
        >
          <BrandIntroduce />
        </Field>

        <Field
          sx={{ minHeight: 255 }}
          informationCards={
            <Fragment>
              <InformationCard>{t('BrandPage.brandPick.maximumDescription', { lng: lang })}</InformationCard>
              <InformationCard marginTop="20px">{t('BrandPage.brandPick.sortDescription', { lng: lang })}</InformationCard>
            </Fragment>
          }
        >
          <BrandPick />
        </Field>
        <Field
          sx={{ minHeight: 255 }}
          informationCards={
            <Fragment>
              <InformationCard>{t('BrandPage.review.description', { lng: lang })}</InformationCard>
            </Fragment>
          }
        >
          <BrandReview />
        </Field>
      </Wrap>
    </RecoilRoot>
  );
};

export default BrandPage;
