import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    GET_NOTICE_WRITING_MATERIALS,
    GET_FAQ_WRITING_MATERIALS,
    GET_POPUP_WRITING_MATERIALS
} from '../api/quries';
import {
    CREATE_NOTICE,
    UPDATE_NOTICE,
    DELETE_NOTICE,
    CREATE_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ,
    CREATE_POPUP,
    UPDATE_POPUP,
    DELETE_POPUP
} from '../api/mutations';
import ACON from '../lib/global';
import './BoardsWrite.scss';
import Editor from '../common/editor';
import HTMLViewer from './HTMLViewer';
import { AppContext } from '../app'
import { KeyboardDatePickerInput, KeyboardTimePickerInput } from '../components/input/Pickers';
import CreatableSelect from 'react-select/creatable';


export default ((props) => {
    const { userInfo } = useContext(AppContext)
    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo
    if (!isAdmin) {
        props.history.push("/")
        return <></>
    }

    // 번역기 불러오기
    const { t, match } = props;
    // 게시판 종류: 공지(notice) / FAQ(faq) / 팝업(popup)
    const boardName = match.params.boardName || "notice"
    // 모달 표시 메소드 불러오기
    const { showAlertMessage, setBackgroundColor } = useContext(AppContext)

    /* State 설정 */

    // 카테고리
    const [category, setCategory] = useState('')
    // 제목
    const [title, setTitle] = useState('')
    // 내용
    const [body, setBody] = useState('')
    // (팝업전용) 표시 시작일 yyyy-mm-dd
    const [startDate, setStartDate] = useState(null)
    // (팝업전용) 표시 종료일 yyyy-mm-dd
    const [endDate, setEndDate] = useState(null)
    // (팝업전용) 표시 위치 url
    const [location, setLocation] = useState([])
    const [locationInput, setLocationInput] = useState('')
    // (팝업전용) 오늘하루숨김 표시여부
    const [allowHide, setAllowHide] = useState(true)
    // 미리보기 modal 표시여부
    const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)

    const [isDiabled, setIsDisabled] = useState(false)

    const docId = +props.match.params.docId

    useEffect(() => {
        // componentDidMount에 해당하는 부분
        // 배경화면 흰색으로 변경 
        setBackgroundColor('white');
        // componentWillUnMount에 해당하는 부분
        // 배경화면 색 초기화
        return () => { setBackgroundColor('none') }
    }, [])

    // 게시판 이름에 따라 쿼리문 조립하는 로직
    let writeQuery, saveMutation, deleteMutation, requestFunctionName

    // 글작성시 필요한 정보(글 타입 선택지, 기존 저장된 글 내용) 불러오는 쿼리
    if (boardName === "notice") {
        writeQuery = GET_NOTICE_WRITING_MATERIALS
    } else if (boardName === "faq") {
        writeQuery = GET_FAQ_WRITING_MATERIALS
    } else if (boardName === "popup") {
        writeQuery = GET_POPUP_WRITING_MATERIALS
    }

    const [updateNoticeMutation] = useMutation(UPDATE_NOTICE)
    const [createNoticeMutataion] = useMutation(CREATE_NOTICE)
    const [updateFaqMutation] = useMutation(UPDATE_FAQ)
    const [createFaqMutation] = useMutation(CREATE_FAQ)
    const [updatePopupMutation] = useMutation(UPDATE_POPUP)
    const [createPopupMutation] = useMutation(CREATE_POPUP)

    // 글 저장시 실행하는 mutation 쿼리
    if (boardName === "notice") {
        saveMutation = docId ? updateNoticeMutation : createNoticeMutataion
    } else if (boardName === "faq") {
        saveMutation = docId ? updateFaqMutation : createFaqMutation;
    } else if (boardName === "popup") {
        saveMutation = docId ? updatePopupMutation : createPopupMutation;
    }

    const [deleteNoticeMutation] = useMutation(DELETE_NOTICE)
    const [deleteFaqMutation] = useMutation(DELETE_FAQ)
    const [deletePopupMutation] = useMutation(DELETE_POPUP)

    // 글 삭제시 실행하는 mutation 쿼리
    if (boardName === "notice") {
        deleteMutation = deleteNoticeMutation
        requestFunctionName = "deleteNotice"
    } else if (boardName === "faq") {
        deleteMutation = deleteFaqMutation
        requestFunctionName = "deleteFaq"
    } else if (boardName === "popup") {
        deleteMutation = deletePopupMutation
        requestFunctionName = "deletePopup"
    }

    // 공지, FAQ, 팝업 글 작성에 모두 공통적으로 들어가는 변수들을 먼저 설정해줍니다.
    const queryVariables = {
        title,
        body,
        language: 1
    }
    if (docId) queryVariables.id = docId

    if (boardName === "notice") {
        // category와 visible 필드는 공지와 FAQ에만 있으므로 팝업 글 작성시에는 쿼리변수에 추가하지 않습니다.
        queryVariables.type = category
    } else if (boardName === "faq") {
        // category와 visible 필드는 공지와 FAQ에만 있으므로 팝업 글 작성시에는 쿼리변수에 추가하지 않습니다.
        queryVariables.type = category
    } else if (boardName === "popup") {
        // 시작일이 설정되지 않았을 경우 현재 시간을 쿼리변수에 입력합니다.
        queryVariables.startDate = startDate || new Date()
        // 종료일이 설정되지 않았을 경우 2099년 12월 31일을 입력합니다.
        queryVariables.endDate = endDate || new Date("2099-01-01T12:00Z")
        // 팝업 표시위치(url)입니다.
        // state 변수인 location은 다음과 같은 형식입니다 -> [ { label, value }, ... ]
        // 위와 같은 형식은 location 변수를 입력하는 UI를 위해 react-select 라이브러리를 사용하기 때문에 정해진 형식입니다.
        queryVariables.location = location?.map((e) => e.value)
        // 오늘하루 숨김 버튼 표시여부 (boolean)
        queryVariables.allowHide = allowHide
    }

    // 게시판 타입설정을 위한 선택지 불러오기, 게시물 아이디가 있을 경우 내용 가져오기
    const { data, error, loading } = useQuery(writeQuery, {
        fetchPolicy: "no-cache",
        variables: { id: docId || 0 }
    })
    const typesData = data?.getNoticeTypes || data?.getFaqTypes
    const types = typesData && [
        {
            id: "placeholder",
            code: "placeholder"
        },
        ...typesData
    ]
    // 한국어 외의 게시판 작성 기능을 추가할 경우 아래 데이터를 사용할 수 있습니다.
    // const languages = data?.getLanguages
    const docData = data?.getNotice || data?.getFaq || data?.getPopup

    useEffect(() => {
        if (docData) {
            setBody(docData.body)
            setTitle(docData.title)
            setCategory(docData.type?.id)
            if (boardName === "popup") {
                setStartDate(new Date(docData.startDate))
                setEndDate(new Date(docData.endDate))
                setLocation(docData.location?.map((e) => {
                    return { label: e.url, value: e.url }
                }))
                setAllowHide(docData.allowHide)
            }
        }
    }, [docData])

    // 제목 input에 적용할 change 이벤트
    const onChangeTitle = (e) => {
        setTitle(e.target.value)
    }

    // 미리보기 버튼 클릭 이벤트
    const onClickPreviewButton = (e) => {
        if (isValidCheckForm()) {
            // 폼 유효성이 통과된 경우 미리보기 모달 표시
            setIsShowPreviewModal(true);
        }
    }

    // 저장 버튼 클릭 이벤트
    const onClickSaveButton = async () => {
        setIsDisabled(true)
        // 폼 유효성이 통과된 경우 저장 액션 수행 
        if (isValidCheckForm()) await save()
        setIsDisabled(false)
    }

    // 게시글 저장하는 함수
    const save = async () => {
        try {
            const { data } = await saveMutation({ variables: queryVariables })

            const isSucceed = data?.updateNotice ||
                data?.createNotice ||
                data?.updateFaq ||
                data?.createFaq ||
                data?.updatePopup ||
                data?.createPopup

            if (isSucceed) {
                showAlertMessage(t('saved'), t('saved'))
                props.history.goBack()
            } else {
                throw new Error("graphql query failed")
            }
        } catch (err) {
            // (TODO: 에러 로깅)
            console.log("error is", err)
            showAlertMessage(t('serverError'), t('serverError'))
        }
    }

    // HTML 에디터 모달 닫기 
    const closeEditorModal = () => {
        setIsShowPreviewModal(false);
    }

    // 태그의 ref 속성을 통해 변수에 객체를 지정해줌
    let categorySelect, titleInput
    // 폼유효성검사하기 메소드 
    const isValidCheckForm = () => {
        // 포커스 대상 
        let focusTarget;
        try {
            // 제목이 입력되지 않은경우
            if (!title) {
                // 포커스 타겟 지정
                focusTarget = titleInput;
                // 에러 호출
                throw new ACON.ValidationError(t("PleaseEnterATitle.label"));
            }
            // 유형이 선택되지 않은경우
            if (boardName !== "popup" && !category || category === "placeholder") {
                // 포커스 타겟 지정
                focusTarget = categorySelect;
                // 에러 호출
                throw new ACON.ValidationError(t("PleaseSelectCategory.label"));
            }
            return true;
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                showAlertMessage(
                    t("UnableToCompleteSave.label"),
                    errObj.message
                );
                // 포커스 타겟 포커싱
                focusTarget && focusTarget.focus();
                // 종료
                return;
            }
        }
        return false;
    }

    // 게시글 삭제하는 함수
    const deleteDoc = async () => {
        if (docId) {
            const { data } = await deleteMutation({ variables: { id: docId } })

            if (data[requestFunctionName]) {
                showAlertMessage(t("Boards.delete.title"), t("Boards.delete.content"))
            } else {
                showAlertMessage(t("Boards.delete.title"), t("Boards.deleteFail.content"))
            }
            props.history.goBack()
        }
    }

    // 버튼 그룹 (미리보기, 저장, 삭제) 
    const btnGroup = (
        <div className="notice-write__line">
            <button
                className="black-button"
                type="button"
                onClick={onClickPreviewButton}
            >
                {t("Preview.label")}
            </button>
            <button
                className="vivid-button"
                type="button"
                disabled={isDiabled}
                onClick={onClickSaveButton}
            >
                {t("Save.label")}
            </button>
            <button
                className="white-button"
                type="button"
                disabled={isDiabled}
                onClick={deleteDoc}
            >
                {t("Remove.label")}
            </button>
        </div>
    );

    // 유형(category) 설정 변경시 작동하는 로직
    const onChangeType = (e) => {
        setCategory(+e.target.value)
    }

    const TypeSelectTag = () => {
        if (boardName === "popup") {
            return null
        } else {
            const options = types?.map((e, i) => {
                return (
                    <option key={i} value={e.id}>{t(`NoticeCategory.${e.code}`)}</option>
                )
            })
            return (
                <select onChange={onChangeType} value={category}>
                    {options}
                </select>
            )
        }
    }

    // 시작일 설정 중 지우기 버튼 클릭시
    const onClickDeleteStartDate = () => {
        setStartDate(null)
    }
    // 종료일 설정 중 지우기 버튼 클릭시
    const onClickDeleteEndDate = () => {
        setEndDate(null)
    }

    // 표시위치 입력시 리턴되는 array 값을 lcoation 변수에 지정해줍니다.
    // location 변수는 <CreatableSelect />의 prop으로 사용됩니다.
    const onChangeLocation = (value) => {
        setLocation(value)
    }

    // 표시위치 입력시 리턴되는 string 값을 lcoationInput 변수에 지정해줍니다.
    // locationInput 변수는 <CreatableSelect />의 prop으로 사용됩니다.
    const onChangeLocationInput = (inputValue) => {
        setLocationInput(inputValue);
    }

    // locationInput 변수 값(string)으로부터 location 변수 array의 값으로 formatting해주는 함수입니다.
    const createOption = (label) => ({ label, value: label })

    // 표시위치 입력 중 Enter, Tab 키를 누를 때 작동하는 함수입니다.
    // locationInput(string)으로부터 location(array)에 들어갈 객체를 오브젝트 포맷으로 만들어 입력합니다.
    // location 값 입력 후에는 locationInput 값을 빈 string으로 바꿔줍니다.
    // input DOM 객체의 값이 사라지고 기존에 입력했던 값이 태그 모양으로 바뀌어 추가되는 로직에 해당합니다.
    const onKeyDownLocation = (event) => {
        if (!locationInput) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (location) {
                    setLocation([...location, createOption(locationInput)])
                } else {
                    setLocation([createOption(locationInput)])
                }
                setLocationInput('')
                event.preventDefault();
        }
    };

    // location array에 들어온 값들이 "/"으로 시작하는 url 형식이 맞는지 검사하여 boolean으로 리턴합니다.
    // locationValid 값은 validation 메세지를 표시하는 DOM 객체의 visibility를 결정하는 데에 사용됩니다.
    const locationValid = !location?.filter((f) => (f.value[0] !== "/"))?.length

    // 오늘하루숨김 표시여부 설정시 작동 함수
    const onChangeAllowHide = (e) => {
        if (e.target.value === "show") setAllowHide(true)
        if (e.target.value === "hide") setAllowHide(false)
    }

    const onChangeTime = (time, setDate) => {
        if (time && !isNaN(time.getHours()) && !isNaN(time.getMinutes())) {
            setDate((date) => {
                const prevDate = date || new Date();
                prevDate.setHours(time.getHours(), time.getMinutes());
                return new Date(prevDate);
            });
        }
    };

    // 팝업 관련 설정 인터페이스
    const popupSettingArea = (
        <table className="popup__setting">
            <colgroup>
                <col width="20%" />
                <col width="80%" />
            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <div className="fieldName">{t('Popup.period')}</div>
                    </td>
                    <td className="period__value">
                        <div className="pickers-container">
                            <label>{t('Popup.startDate')}</label>
                            <KeyboardDatePickerInput
                                className="pickers date"
                                onChange={setStartDate}
                                value={startDate}
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy-MM-dd"
                                disablePast={false}
                                emptyLabel={t('Popup.startDatePlaceholder')} />
                            <KeyboardTimePickerInput
                                className="pickers time"
                                onChange={(time) => { onChangeTime(time, setStartDate) }}
                                value={startDate}
                                variant="inline"
                                inputVariant="outlined"
                                format="HH:mm"
                                disablePast={false}
                                emptyLabel="00:00" />
                            <button className="delete" onClick={onClickDeleteStartDate}>{t('Popup.delete')}</button>
                        </div>
                        <div className="pickers-container">
                            <label>{t('Popup.endDate')}</label>
                            <KeyboardDatePickerInput
                                className="pickers date"
                                onChange={setEndDate}
                                value={endDate}
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy-MM-dd"
                                disablePast={true}
                                emptyLabel={t('Popup.endDatePlaceholder')} />
                            <KeyboardTimePickerInput
                                className="pickers time"
                                onChange={(time) => { onChangeTime(time, setEndDate) }}
                                value={endDate}
                                variant="inline"
                                inputVariant="outlined"
                                format="HH:mm"
                                disablePast={true}
                                emptyLabel="00:00" />
                            <button className="delete" onClick={onClickDeleteEndDate}>{t('Popup.delete')}</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="fieldName">{t('Popup.location')}</div>
                    </td>
                    <td className="location__value">
                        <CreatableSelect
                            className={locationValid ? null : "invalid-border"}
                            components={{ DropdownIndicator: null }}
                            inputValue={locationInput}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={onChangeLocation}
                            onInputChange={onChangeLocationInput}
                            onKeyDown={onKeyDownLocation}
                            placeholder={t('Popup.locationPlaceholder')}
                            value={location}
                        />
                        <div className={locationValid ? "hide" : "invalid-message"}>{t('Popup.locationInvalid')}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="fieldName">{t('Popup.allowHide')}</div>
                    </td>
                    <td className="allowHide__value">
                        <div>
                            <input onChange={onChangeAllowHide} id="showAllowHide" type="radio" name="allowHide" value="show" checked={allowHide ? true : false} />
                            <label htmlFor="showAllowHide">{t('Popup.showAllowHide')}</label>
                        </div>
                        <div>
                            <input onChange={onChangeAllowHide} id="hideAllowHide" type="radio" name="allowHide" value="hide" checked={allowHide ? false : true} />
                            <label htmlFor="hideAllowHide">{t('Popup.hideAllowHide')}</label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )

    let pageTitleText
    if (boardName === "notice") {
        pageTitleText = t("CreateNotice.label")
    } else if (boardName === "faq") {
        pageTitleText = t("CreateFaq.label")
    } else if (boardName === "popup") {
        pageTitleText = t("CreatePopup.label")
    }

    if (loading || error) {
        return <></>
    }

    // 하위 컴포넌트 조립 후 최종으로 내보내는 컴포넌트
    return (
        <div className={"notice-write"}>
            {/* HTML Viewer 모달 */}
            <HTMLViewer
                show={isShowPreviewModal}
                title={title}
                contents={body}
                close={closeEditorModal}
            />

            {/* 페이지 타이틀 */}
            <div className="notice-write__title">{pageTitleText}</div>

            {/* 공지사항 - 제목 input */}
            <div className="notice-write__flex">
                <TypeSelectTag />
                <input type="text"
                    name="title"
                    placeholder={t("PleaseEnterATitle.label")}
                    value={title}
                    onChange={onChangeTitle}
                    ref={(target) => { titleInput = target; }} />
            </div>

            {/* 버튼 그룹 */}
            {btnGroup}

            {/* 본문 에디터 */}
            {<Editor
                key={'ko'}
                isAdmin={isAdmin}
                contents={body}
                onChange={setBody}
                docId={docId}
                showAlertMessage={showAlertMessage}
            />}

            {/* 팝업게시판 전용 설정 인터페이스 */}
            {boardName === "popup" ? popupSettingArea : null}

            {/* 버튼 그룹 */}
            {btnGroup}
        </div>
    );
})