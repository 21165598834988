import React, { useEffect } from 'react';
import SelectUserTypeForm from './SelectUserTypeForm';
import { useRecoilState } from 'recoil';
import { statusState } from 'signup/SignUp.atoms';
import { SignupChapterEnum } from 'signup/type';
import AccountForm from './AccountForm';
import PartnerInformationForm from './PartnerInformationForm';
import ContractInformationForm from './ContractInformationForm';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageCodeEnum } from 'generated/graphql';

export default function Form() {
  const { i18n } = useTranslation();
  const [status, setStatus] = useRecoilState(statusState);

  useEffect(() => {
    if (status === SignupChapterEnum.UserType && i18n.language !== LanguageCodeEnum.Ko) {
      setStatus(SignupChapterEnum.Account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, status]);

  return (
    <Stack maxWidth={`576px`} width={'100%'}>
      <Stack spacing={10} style={status !== SignupChapterEnum.UserType ? { display: 'none' } : undefined}>
        <SelectUserTypeForm />
      </Stack>
      <Stack spacing={10} style={status !== SignupChapterEnum.Account ? { display: 'none' } : undefined}>
        <AccountForm />
      </Stack>
      <Stack spacing={10} style={status !== SignupChapterEnum.Partner ? { display: 'none' } : undefined}>
        <PartnerInformationForm />
      </Stack>
      <Stack spacing={10} style={status !== SignupChapterEnum.Payment ? { display: 'none' } : undefined}>
        <ContractInformationForm />
      </Stack>
    </Stack>
  );
}
