import React from 'react';
import { Progress } from 'carpenstreet-designsystem';
import { Box } from '@mui/material';

export default function SettlementLoading() {
  return (
    <Box
      sx={{
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '670px',
      }}
    >
      <Progress />
    </Box>
  );
}
