import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error("AuthContext는 AuthProvider 내에서 사용되어야 합니다.");

  return context;
};

export default useAuth;
