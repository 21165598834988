import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const UnableAlertModalContainer = styled(Modal)``;
export const UnableAlertModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;
export const UnableAlertModalConfirmWrap = styled.div`
  display: flex;
  justify-content: center;
`;
