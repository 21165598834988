import React, { useEffect, useContext } from 'react';
import { AppContext } from '../app'
import { useUrl } from './hook/useUrl';
import Tab from './tab';
import { FaqAconList, FaqCreatorList } from './list';
import { useHistory, useParams } from 'react-router-dom';
import { FAQ_ACON_PATHNAME, FAQ_CREATOR_PATHNAME } from './constants';
import { FaqAconTypes, FaqCreatorTypes } from './types';
import './Boards.scss';

export default ((props) => {
    const { setBackgroundColor, userInfo } = useContext(AppContext)
    const { name } = useParams();
    const history = useHistory();

    const { t } = props;
    const { data: urlObj, push } = useUrl();

    useEffect(() => {
        setBackgroundColor('white')
    }, [])

    const onClickWriteButton = () => {
        history.push(`/aconfaq/${name}/write`);
    };
    const LanguageSelectTag = () => {
        return (
            <select onChange={(e) => { push({ lang: e.target.value }) }} value={urlObj.lang}>
                <option value="">
                    {t("Aconfaq.language.all")}
                </option>
                <option value="ko">KO</option>
                <option value="en">EN</option>
                <option value="cn">CN</option>
                <option value="jp">JP</option>
            </select>
        )
    }

    const LimitSelectTag = () => {
        return (
            <select onChange={(e) => { push({ limit: e.target.value }) }} value={urlObj.limit}>
                <option value={20}>
                    {t("Aconfaq.limit.20")}
                </option>
                <option value={40}>
                    {t("Aconfaq.limit.40")}
                </option>
                <option value={60}>
                    {t("Aconfaq.limit.60")}
                </option>v
                <option value={100}>
                    {t("Aconfaq.limit.100")}
                </option>
            </select>
        )
    }
    return (
        <div className="aconfaq">
            <div>
                <Tab />
            </div>
            <div className="aconfaq__flex">
                <div>
                    <LanguageSelectTag />
                    {name === FAQ_ACON_PATHNAME && <FaqAconTypes value={urlObj.typeId} />}
                    {name === FAQ_CREATOR_PATHNAME && <FaqCreatorTypes value={urlObj.typeId} />}
                    <LimitSelectTag />
                </div>
                <div className="write-aconfaq">
                    <button type="button"
                        onClick={onClickWriteButton}
                        className="max-btn black-button">
                        {t("Boards.write")}
                    </button>
                </div>
            </div>
            {name === FAQ_ACON_PATHNAME && <FaqAconList />}
            {name === FAQ_CREATOR_PATHNAME && <FaqCreatorList />}
        </div>
    );
});