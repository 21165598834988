import { Box } from '@mui/material';
import React from 'react';

export default function Container({
  children,
  isPadding = true,
  ...rest
}: {
  children: JSX.Element | JSX.Element[];
  isPadding?: boolean;
} & React.CSSProperties) {
  return (
    <Box
      borderRadius="16px"
      boxShadow="0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)"
      padding={isPadding ? '24px' : '0'}
      display="flex"
      flexDirection="column"
      sx={{ ...rest }}
    >
      {children}
    </Box>
  );
}
