import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrap, Item } from './style';

export default (({ isShow, setIsShow, setFilterValue, filterValue, left=null }) => {
    const { t } = useTranslation();

    let filterRef = useRef(null);

    // 버튼 이외의 영역 클릭 이벤트 처리기 메소드 
    const onClickOutside = e => {
        if (filterRef && filterRef.current && !filterRef.current.contains(e.target))
            setIsShow(false);
    };
    // dom ref 설정하기 메소드 
    const setRef = target => {
        filterRef.current = target;
    };

    useEffect(() => {
        // document 클릭 이벤트 
        document.addEventListener('click', onClickOutside, true);
        return () => {
            document.removeEventListener('click', onClickOutside, true);
        };
    });

    const onChange = (id) => {
        // push 
        setFilterValue(id);
        localStorage.setItem('settle_filter_payment', id);
    };

    const langItemArr = [
        { value: 'payment', label: t('settleTable.paymentDate') },
        { value: 'order', label: t('settleTable.orderDate') },
    ];
    return (
        <>
            { isShow && (
                <Wrap ref={setRef} {...left && { left }} zIndex={1}>
                    {langItemArr.map(x =>
                        <Item
                            key={x.value}
                            value={x.value}
                            label={x.label}
                            setIds={onChange}
                            isChecked={filterValue === x.value}
                        />
                    )}
                </Wrap>)}
        </>
    );
});