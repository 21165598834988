import React, { useEffect } from "react";
import Layout from "components/layout";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { PATH_PAGE, PATH_USER } from "routes/paths";
import useAuth from "hooks/useAuth";
import SettleModify from "user/SettleModify";
import { RecoilRoot } from "recoil";

interface MatchParams {
  no: string;
}

export default function UserSettlePage({
  match,
}: RouteComponentProps<MatchParams>) {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    // 관리자는 의도적으로 루트 페이지 접근을 막음
    if (user.isAdmin && !match.params.no) {
      history.push(PATH_PAGE.root);
      return;
    }

    // 사용자의 경우, url param을 받지않음.
    if (!user.isAdmin && match.params.no) {
      history.push(PATH_USER.root);
      return;
    }
  }, [user.isAdmin, match.params.no]);

  return (
    <Layout>
      <RecoilRoot>
        <SettleModify userId={user.id} />
      </RecoilRoot>
    </Layout>
  );
}
