import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrayButton, Button } from './input';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountFormState, chapterState, signState, signinState, statusState } from './SignUp.atoms';
import { SignupChapterEnum, SignupChapters } from './type';
import useTerm from './useTerm';
import { Signin } from 'lib/user';
import { LanguageCodeEnum } from 'generated/graphql';
import { Stack } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { SIZE } from '../constants';
import { AppContext } from 'app';

type Props = {
  onNext?: () => Promise<string>;
  onPrev?: () => void;
};

export default function Navigator({ onNext, onPrev }: Props) {
  const { t, i18n } = useTranslation();
  const { completeCreateUser } = useTerm();
  const { showConfirmMessage } = useContext(AppContext);

  const isDesktop = useResponsive('up', SIZE.DESKTOP);
  const [status, setStatus] = useRecoilState(statusState);
  const account = useRecoilValue(accountFormState);
  const [chapterValid] = useRecoilState(chapterState);
  const state = useRecoilValue(signinState);

  const [forceUpdate, setForceUpdate] = useState(false);

  const isLast = status === SignupChapterEnum.Payment;

  const prevStatus = () => {
    const index = SignupChapters.findIndex((x) => x === status);
    const prev = SignupChapters[index - 1];
    if (prev < 0) return;

    setStatus(prev);
  };

  const nextStatus = () => {
    const index = SignupChapters.findIndex((x) => x === status);
    const prev = SignupChapters[index + 1];
    if (!prev) return;

    setStatus(prev);
  };

  const handlePrev = () => {
    onPrev && onPrev();
    prevStatus();
  };

  const handleNext = async () => {
    onNext && (await onNext());

    if (isLast) {
      // 20240729 - forceUpdate를 활용해서 onNext() <-- recoil의 상태와 싱크를 맞춰준다. (다른 방법이 생각안남)
      // recoil상태를 업데이트시키는 onNext()와 completeCreateUser() 뮤테이션이 동일한 스코프에 위치해서
      // completeCreateUser()뮤테이션이 전송될때 이전값을 참조하는게 문제.
      setForceUpdate(true);
      return;
    }

    nextStatus();
  };

  const isDisabled = chapterValid[status];

  useEffect(() => {
    if (forceUpdate) {
      showConfirmMessage(
        t('alert'),
        t('signupPage.onFinishConfirmMessage'),
        async () => {
          await completeCreateUser();
          await Signin(account.userEmail, account.password);
          setForceUpdate(false);
          window.location.href = '/welcome';
        },
        {}, // options
        () => setForceUpdate(false),
      );
    }
  }, [forceUpdate, state]);
  return (
    <Stack spacing={1.25} direction={isDesktop ? 'row' : 'column'} alignItems={'center'} justifyContent={'center'}>
      {status > 0 && (
        <div>
          <Button style={{ margin: 0 }} disabled={i18n.language !== 'ko' && status === 1} onClick={handlePrev}>
            {t('signup.prev')}
          </Button>
        </div>
      )}
      <div>
        <GrayButton onClick={handleNext} disabled={!isDisabled} style={{ margin: 0 }}>
          {isLast ? t('signup.confirm') : t('signup.next')}
        </GrayButton>
      </div>
    </Stack>
  );
}
