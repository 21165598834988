import React, { useMemo } from 'react';
import { CalendarContainer, CalendarTooltip } from './Calendar.styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Typography } from '@mui/material';
import palette from 'theme/palette';
import dayjs from 'dayjs';
import CalendarLabel from './components/CalendarLabel/CalendarLabel';
import { Whisper } from 'rsuite';
import { EPromotionStatus, LanguageCodeEnum } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';

const Calendar = ({ since, until, status, isShowTooltip }): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);

  return (
    <CalendarContainer>
      <FullCalendar
        locale={language}
        contentHeight="502px"
        initialView="dayGridMonth"
        initialDate={dayjs(since).format('YYYY-MM-DD')}
        plugins={[dayGridPlugin]}
        headerToolbar={{ start: '', center: 'title', end: '' }}
        dayCellContent={(props) => {
          if (props.isToday)
            return (
              <Whisper placement="top" open={isShowTooltip} speaker={<CalendarTooltip>{t('twinkleSalePage.changedDiscountPeriod')}</CalendarTooltip>}>
                <Typography
                  variant="body2"
                  color={'white'}
                  sx={{
                    display: 'flex',
                    width: 22,
                    height: 22,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    background: palette.light.orange[0],
                  }}
                >
                  {props.date.getDate()}
                </Typography>
              </Whisper>
            );
          return (
            <Typography variant="body2" color={palette.light.normal.main}>
              {props.date.getDate()}
            </Typography>
          );
        }}
        events={[
          {
            id: status,
            start: dayjs(since).format('YYYY-MM-DD'),
            end: dayjs(until).add(1, 'day').format('YYYY-MM-DD'),
            extendedProps: {
              labelColor: status,
              realStart: dayjs(since).format('YYYY-MM-DD HH:mm:ss'),
              realEnd: dayjs(until).format('YYYY-MM-DD HH:mm:ss'),
            },
          },
        ]}
        eventContent={(eventInfo) => {
          const { event, isStart, isEnd } = eventInfo;
          const { extendedProps } = event;
          const text = [];
          if (isStart && isEnd) {
            text.push(`${dayjs(extendedProps?.realStart).locale(language).format('M/D(dd) A HH:mm')} ${t('twinkleSalePage.start')}`);
            text.push(`${dayjs(extendedProps?.realEnd).locale(language).format('M/D(dd) A HH:mm')} ${t('twinkleSalePage.end')}`);
          } else {
            text.push(
              `${dayjs(isStart ? extendedProps?.realStart : extendedProps?.realEnd)
                .locale(language)
                .format('M/D(dd) A HH:mm')} ${isStart ? t('twinkleSalePage.start') : t('twinkleSalePage.end')}`,
            );
          }
          return <CalendarLabel isStart={isStart} isEnd={isEnd} color={extendedProps?.labelColor} text={text} />;
        }}
      />
    </CalendarContainer>
  );
};
export default Calendar;
