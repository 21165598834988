import React from 'react';
import { Button, Snackbar, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import useAuth from '../../hooks/useAuth';

export default function SettlementRecordsSnackbar() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const theme = useDesignSystemTheme();

  const { isAuthenticated } = useAuth();

  const [showSnackbar, setShowSnackbar] = useLocalStorage('show-settlement-records-snackbar', 'y');

  function handleCloseIconClick() {
    setShowSnackbar('n');
  }

  return (
    <>
      {/* Desktop Snackbar */}
      <Snackbar
        variant={'default'}
        message={t('settlementPage.snackbar.text1')}
        open={isAuthenticated && showSnackbar === 'y'}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        showCloseIcon={true}
        onCloseIconClick={handleCloseIconClick}
        action={
          <a
            href={
              language === 'ko'
                ? 'https://acon3d.notion.site/56eb8a47a9f54c44961212c91c439984?pvs=4'
                : 'https://acon3d.notion.site/Explore-New-Settlement-Records-Menu-in-Detail-23599b98ccf14ba28a74267c4ce1c978'
            }
            target={'_blank'}
            onClick={handleCloseIconClick}
          >
            <Button variant={'text'} size={'S'} weight={'bold'} sx={{ '& span': { color: `${theme.palette['color/white']} !important` } }}>
              {t('settlementPage.snackbar.text2')}
            </Button>
          </a>
        }
        sx={{
          [theme.breakpoints.down('largeTablet')]: {
            display: 'none',
          },
          top: '103px',
          left: '40px',
        }}
      />
      {/* Mobile Snackbar */}
      <Snackbar
        variant={'default'}
        message={t('settlementPage.snackbar.text1')}
        open={isAuthenticated && showSnackbar === 'y'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        align={'vertical'}
        showCloseIcon={true}
        onCloseIconClick={handleCloseIconClick}
        action={
          <a
            href={
              language === 'ko'
                ? 'https://acon3d.notion.site/56eb8a47a9f54c44961212c91c439984?pvs=4'
                : 'https://acon3d.notion.site/Explore-New-Settlement-Records-Menu-in-Detail-23599b98ccf14ba28a74267c4ce1c978'
            }
            target={'_blank'}
            onClick={handleCloseIconClick}
          >
            <Button variant={'text'} size={'S'} weight={'bold'} sx={{ '& span': { color: `${theme.palette['color/white']} !important` } }}>
              {t('settlementPage.snackbar.text2')}
            </Button>
          </a>
        }
        sx={{
          [theme.breakpoints.up('largeTablet')]: {
            display: 'none',
          },
          width: 'calc(100vw - 32px)',
        }}
      />
    </>
  );
}
