import React, { useEffect } from "react";
import Layout from "components/layout";
import { useHistory } from "react-router-dom";
import { PATH_PAGE } from "routes/paths";
import useAuth from "hooks/useAuth";
import { RecoilRoot } from "recoil";
import StoreModify from "user/storeModify/StoreModify";

export default function UserStorePage() {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    // 관리자는 의도적으로 루트 페이지 접근을 막음
    if (user.isAdmin) {
      history.push(PATH_PAGE.root);
      return;
    }
  }, [user.isAdmin]);

  return (
    <Layout>
      <RecoilRoot>
        <StoreModify />
      </RecoilRoot>
    </Layout>
  );
}
