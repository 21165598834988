import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { ReactComponent as Warning } from 'acon-mui/icons/icon-warning.svg';
import { ReactComponent as Notice } from 'acon-mui/icons/icon-notice.svg';
import { ReactComponent as Info } from 'acon-mui/icons/icon-info.svg';

type CardType = 'information' | 'notice' | 'warning';

interface Props {
  children: React.ReactNode;
  type?: CardType;
  marginTop?: string;
  marginBottom?: string;
}

function getStyle(type: CardType) {
  switch (type) {
    case 'warning':
      return {
        bgcolor: 'rgb(248, 235, 234)',
        color: 'rgb(77, 24, 22)',
      };
    case 'notice':
      return {
        bgcolor: 'rgb(251, 241, 231)',
        color: 'rgb(88, 46, 13)',
      };
    default:
      return {
        bgcolor: 'rgb(232, 243, 249)',
        color: 'rgb(20, 53, 81)',
      };
  }
}

export default function InformationCard({ children, type = 'information', marginTop, marginBottom, ...rest }: Props & BoxProps) {
  return (
    <Box {...(marginTop && { marginTop })} {...(marginBottom && { marginBottom })} padding="15px 17px" display="flex" gap="14px" borderRadius="4px" {...getStyle(type)} {...rest}>
      <Box width="20px">
        {type === 'information' && <Info />}
        {type === 'notice' && <Notice />}
        {type === 'warning' && <Warning />}
      </Box>
      <Typography fontWeight="400" fontSize="14px" lineHeight="20px" letterSpacing="0.17px" sx={{ wordBreak: 'keep-all', '& span': { fontWeight: '700' } }}>
        {children}
      </Typography>
    </Box>
  );
}
