import React from 'react';
import { MenuItem, Select, SelectProps, Stack, TextField, Typography } from '@mui/material';
import { useBanksQuery } from 'generated/graphql';
import { bankIdState, bankNameState } from 'user/User.atom';
import { useRecoilState, useRecoilValue } from 'recoil';

export default function BankSelectField({ value, onChange }: SelectProps) {
  const bankDataResult = useBanksQuery();
  const bankId = useRecoilValue(bankIdState);
  const [bankName, setBankName] = useRecoilState(bankNameState);

  const banks = bankDataResult.data?.banks ? [...bankDataResult.data?.banks] : [];

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setBankName(e.target.value);
  };

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body2'} color={'grey.800'} fontWeight={600}>
        은행
      </Typography>
      <Stack direction="row" spacing={1}>
        <Select
          fullWidth
          value={value}
          placeholder="은행"
          onChange={onChange}
          size={'small'}
          displayEmpty
          renderValue={(v: number) => (v > 0 ? banks.find((x) => x.id === v)?.name : `은행`)}
        >
          {banks.map((option, optionIdx) => (
            <MenuItem
              key={optionIdx}
              value={option.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {bankId === 22 && <TextField fullWidth value={bankName} onChange={handleChange} />}
      </Stack>
    </Stack>
  );
}
