import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrap = styled.div`
    margin: 0 auto;
`;
export const PageMove = styled(NavLink)`
    color: #aaa;
`;
export const Page = styled(NavLink)`
    color: #aaa;
    &.active {
        color:#ff007b;
    }
    &:before {
        content: ' [';
    }
    &:after {
        content: '] ';
    }
`;