import React from 'react';
import PointWrite from '../point/write';
import CouponWrite from '../coupon/write';
import BenefitRestricWrite from '../benefitRestric/write';

export default ((props) => {
    const { match } = props;
    // 현재 페이지 타입을 가져옵니다.
    const burdenDisplayLocation = match.params.displayLocation || "point";

    return (
        <>
            {
                burdenDisplayLocation === 'point' &&
                <PointWrite {...props} />
            }
            {
                burdenDisplayLocation === 'coupon' &&
                <CouponWrite {...props} />
            }
            {
                burdenDisplayLocation === 'benefitRestric' &&
                <BenefitRestricWrite {...props} />
            }
        </>
    );
});