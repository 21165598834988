import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { patterns } from './VideoDialog.constants';
import './VideoDialog.scss';

// 요청하기 위한 모달입니다.
export default function (props) {
  const { close, isShow, insertVideo } = props;
  const [url, setUrl] = useState('');
  const { t } = useTranslation();

  const handleInput = (e) => {
    setUrl(e.target.value);
  };
  const createVideoUrl = (url) => {
    for (const pattern of patterns) {
      const match = url.match(pattern.re);
      if (match) {
        if (pattern.name === 'mp4|m4v|ogg|ogv|webm') return url;
        return pattern.process(match);
      }
    }
    return false;
  };

  const onClickConfirmButton = (e) => {
    const data = createVideoUrl(url);

    const result = insertVideo(data);
    if (result) {
      close();
    }
  };

  return (
    <Modal className="requestDialog" show={isShow} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{t('addVideo')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="text" onChange={handleInput} placeholder={t('videoDialogDescription')} />
        <div className="requestDialog__description">{t('videoDialogDescription2')}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className="requestBtn" onClick={onClickConfirmButton}>
          {t('addVideo')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
