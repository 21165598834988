import { create } from 'zustand';
import { IDocumentState } from './document.types';
import { initialDocumentState, languageList } from './document.constants';
import {
  NEW_STATUS_DRAFT,
  STATUS_COMPLETE,
  STATUS_COMPLETE_ON_OPEN,
  STATUS_DENIED,
  STATUS_DRAFT_ON_OPEN,
  STATUS_IN_REVIEW,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_ON_SALE,
  STATUS_ON_SALE_ON_OPEN,
  STATUS_REJECTED,
  STATUS_REJECTED_ON_OPEN,
  STATUS_REQUESTED,
  STATUS_REQUESTED_ON_OPEN,
} from 'boards/DetailBoardWrite/constants';

export const useOriginalDocumentStore = create<IDocumentState & { setOriginalDocumentData: (documentData: IDocumentState) => void; resetOriginalDocumentData: () => void }>(
  (set) => ({
    ...initialDocumentState,
    setOriginalDocumentData: (documentData) =>
      set(() => {
        const {
          id,
          assetId,
          brand,
          language,
          originalLanguage,
          title,
          contentHead,
          contentBody,
          file,
          extensions,
          applications,
          mainImage,
          subImage,
          categories,
          price,
          tags,
          copyright,
          status,
          version,
          isAdultOnly,
        } = documentData;

        return {
          ...(typeof id === 'string' && { id }),
          ...(typeof assetId === 'number' && { assetId }),
          ...(typeof brand === 'number' && { brand }),
          ...(languageList.includes(language) && {
            language,
          }),
          ...(languageList.includes(originalLanguage) && {
            originalLanguage,
          }),
          ...(typeof title === 'string' && { title }),
          ...(typeof contentHead === 'string' && { contentHead }),
          ...(typeof contentBody === 'string' && { contentBody }),
          ...(typeof file === 'object' && { file }),
          ...(Array.isArray(extensions) && { extensions }),
          ...(Array.isArray(applications) && { applications }),
          ...(typeof mainImage === 'object' && { mainImage }),
          ...(typeof subImage === 'object' && { subImage }),
          ...(typeof categories === 'object' && { categories }),
          ...(typeof price === 'number' && { price }),
          ...(Array.isArray(tags) && { tags }),
          ...(typeof copyright?.isOriginal === 'boolean' && { copyright }),
          ...([
            NEW_STATUS_DRAFT,
            STATUS_REQUESTED,
            STATUS_IN_REVIEW,
            STATUS_DENIED,
            STATUS_REJECTED,
            STATUS_REJECTED_ON_OPEN,
            STATUS_COMPLETE,
            STATUS_DRAFT_ON_OPEN,
            STATUS_REQUESTED_ON_OPEN,
            STATUS_IN_REVIEW_ON_OPEN,
            STATUS_COMPLETE_ON_OPEN,
            STATUS_ON_SALE,
            STATUS_ON_SALE_ON_OPEN,
          ].includes(status) && { status }),
          ...(typeof version === 'number' && { version }),
          ...(typeof isAdultOnly === 'boolean' && { isAdultOnly }),
        };
      }),
    resetOriginalDocumentData: () => set(() => initialDocumentState),
  }),
);
