import React from 'react';
export default (function (props) {
    const { data, selected, event, filter } = props
    const onClick = event.onClick(data, selected)
    var rowClass = ['row']
    if (selected) {
        rowClass.push('select')
    }
    if (filter) {
        const tempFilter = RegExp.escape(filter)
        if (!data._id.match(new RegExp(`.*${tempFilter}.*`))) {
            rowClass.push('hide')
        }
    }
    return (<div _id={data._id} onClick={onClick} className={rowClass.join(' ')}>
        <label>{data._id}</label>
        <label>{data.describe ? `(${data.describe})` : ''}</label>
    </div >)
});

