import React, { useState, useContext, useMemo } from "react";
import { BlackButton } from "boards/DetailBoardWrite/component/form";
import { useTranslation } from "react-i18next";
import { Input, Item, Title, Value } from "boards/DetailBoardWrite/style";
import List from "./list";
import { Modal } from "react-bootstrap";
import { Wrap, ErrorMessage, Flex } from "./style";
import {
  CREATE_MODEL_CONFIG,
  DELETE_MODEL_CONFIG,
  UPDATE_MODEL_CONFIG,
} from "api/mutations";
import { useMutation } from "@apollo/client";

// 요청하기 위한 모달입니다.
export default ({ onClose, items, show, refetch }) => {
  const { t } = useTranslation();

  // 업데이트 내역
  const [name, setName] = useState({
    ko: "",
    en: "",
    zh: "",
    ja: "",
  });
  // 에러 여부
  const [isError, setIsError] = useState(false);

  // 모델설정항목 생성하기
  const [createModelConfig] = useMutation(CREATE_MODEL_CONFIG);
  // 모델설정항목 삭제하기
  const [deleteModelConfig] = useMutation(DELETE_MODEL_CONFIG);
  // 모델설정항목 업데이트하기
  const [updateModelConfig] = useMutation(UPDATE_MODEL_CONFIG);

  // 추가 버튼 클릭 이벤트 처리기 메소드
  const onClick = async () => {
    // 이름이 중복되는 항목이 존재할 경우
    if (items.filter((x) => x.name === name.ko).length > 0) {
      // 에러 표시
      setIsError(true);
      // 종료
      return;
    }

    // 모델구성 생성
    await createModelConfig({
      variables: {
        koName: name.ko,
        enName: name.en,
        cnName: name.zh,
        jpName: name.ja,
      },
    });

    // 데이터 재확보
    await refetch();
    // 입력내역 초기화
    setName({ ko: "", en: "", zh: "", ja: "" });
    // 에러 숨김
    setIsError(false);
  };

  // 항목 업데이트 버튼태그 클릭 이벤트 처리기 메소드
  const onUpdate = async (id, koName, enName, cnName, jpName) => {
    await updateModelConfig({
      variables: {
        id,
        koName,
        enName,
        cnName,
        jpName,
      },
    });

    refetch();
  };
  // 항목 제거 버튼태그 클릭 이벤트 처리기 메소드
  const onDelete = async (id) => {
    // 무델구성 항목 삭제하기
    await deleteModelConfig({
      variables: {
        id,
      },
    });
    // 항목 재확보
    refetch();
  };
  return (
    <Wrap show={show} onHide={onClose}>
      <Modal.Body>
        <Item>
          <Title>{t("goods.addModelConfig")}</Title>
          <Value>
            <Flex>
              <Input
                value={name.ko}
                placeholder={"KO"}
                onChange={(e) => {
                  setName({ ...name, ko: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClick();
                  }
                }}
              />
            </Flex>
            <Flex>
              <Input
                value={name.en}
                placeholder={"EN"}
                onChange={(e) => {
                  setName({ ...name, en: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClick();
                  }
                }}
              />
            </Flex>
            <Flex>
              <Input
                value={name.zh}
                placeholder={"ZH"}
                onChange={(e) => {
                  setName({ ...name, zh: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClick();
                  }
                }}
              />
            </Flex>
            <Flex>
              <Input
                value={name.ja}
                placeholder={"JA"}
                onChange={(e) => {
                  setName({ ...name, ja: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClick();
                  }
                }}
              />
            </Flex>
            <Flex>
              <BlackButton disabled={!name} onClick={onClick}>
                {t("goods.add")}
              </BlackButton>
            </Flex>
            <Flex>
              <ErrorMessage show={isError}>
                중복되는 항목이 존재합니다.
              </ErrorMessage>
            </Flex>
          </Value>
        </Item>
        <List items={items} onDelete={onDelete} onUpdate={onUpdate} />
      </Modal.Body>
    </Wrap>
  );
};
