import React, { useState, useContext, useEffect } from 'react';
import dateFormat from 'dateformat';
import { useQuery, useMutation } from '@apollo/client';
import { AppContext } from '../../../../app'
import { GET_COUPON_BENEFIT_WRITING_MATERIALS, GET_COUPON_ITEMS } from '../../../../api/quries';
import { useTranslation } from 'react-i18next';
import Category from '../../editor/category';
import Brand from '../../editor/brand';
import GoodsSearch from '../../editor/goodsSearch';
import DatePicker from '../../../../components/datepicker';
import { 
    APPLY_BOARD_CODE,
    UNIT_DEFAULT_COMPLETE_CODE, 
    UNIT_DEFAULT_REVIEW_CODE,
    UNIT_BOARD_REVIEW_CODE,
} from '../../constants';
import {
    CREATE_COUPON_BENEFIT,
    UPDATE_COUPON_BENEFIT,
    DELETE_COUPON_BENEFIT
} from '../../../../api/mutations';
import {
    PointWriteContainer,
    PointHeader,
    CommonSelectBox,
    CommonButton,
    FormContainer,
    Row,
    RowTitle,
    CommonInputTextBox,
    CommonInputRadioBox,
    Flex,
    Label
} from '../../style';

export default ((props) => {
    // 번역도구
    const { t } = useTranslation();
    // 사용자 정보
    const { userInfo } = useContext(AppContext);
    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    if (!isAdmin) {
        props.history.push("/");
        return <></>;
    }

    /* State 설정 - 시작 */
    // 쿠폰 혜택 적용할 몰(국가)
    const [languageValue, setLanguageValue] = useState(1);
    // 쿠폰 혜택 -> 유형
    const [typeValue, setTypeValue] = useState(1);
    // 쿠폰 혜택 -> 쿠폰 설정 -> 고도몰 쿠폰 고유 ID
    const [godoCouponNoValue, setGodoCouponNoValue] = useState('');
    // 쿠폰 혜택 -> 쿠폰 설정 -> 고도몰 쿠폰 명
    const [godoCouponNameValue, setGodoCouponNameValue] = useState('');
    // 쿠폰 혜택 -> 쿠폰 설정 -> 고도몰 쿠폰 등록 일
    const [godoCouponRegDtValue, setGodoCouponRegDtValue] = useState('');
    // 쿠폰 혜택 -> 적용 설정 -> 설정 타입
    const [applyTypeValue, setApplyTypeValue] = useState(1);
    // 쿠폰 혜택 -> 적용 설정 -> 설정 타입 코드
    const [applyTypeCode, setApplyTypeCode] = useState('category');
    // 쿠폰 혜택 -> 적용 설정 -> 선택 설정 타입
    const [applySelectedValue, setApplySelectedValue] = useState([]);
    // 쿠폰 유효성 검사 진행 여부
    const [isCouponSkipQuery, setIsCouponSkipQuery] = useState(true);
    // 저장 진행 중 여부 상태 값
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    /* State 설정 - 끝 */

    // 쿠폰 혜택 id
    const [docId] = useState(+props.match.params.docId);
    // 언어 배열
    const langArr = ['ko', 'en', 'cn', 'jp'];
    const {
        showAlertMessage
    } = useContext(AppContext)

    // 포인트 혜택 정보 가져오기 API
    const { data } = useQuery(GET_COUPON_BENEFIT_WRITING_MATERIALS, {
        fetchPolicy: "no-cache",
        variables: { id: docId || 0 }
    });

    // 포인트 혜택 정보 가져오기 API
    const { data: couponItems, loading, error } = useQuery(GET_COUPON_ITEMS, {
        fetchPolicy: "no-cache",
        variables: {
            couponName: godoCouponNameValue,
            couponRegDt: (() => {
                const regDate = new Date(godoCouponRegDtValue);
                const month = regDate.getMonth() + 1;
                const date = regDate.getDate();
                return `${regDate.getFullYear()}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
            })()
        },
        skip: isCouponSkipQuery || ! godoCouponRegDtValue
    });

    // 포인트 헤택 정보
    let couponBenefitData = data?.getCouponBenefit,
        pointBenefitType = data?.getPointBenefitTypes,
        pointBenefitApply = data?.getPointBenefitApply,
        languages = data?.getLanguages;

    useEffect(() => {
        if(couponBenefitData) {
            setLanguageValue(couponBenefitData.language.id);
            setTypeValue(couponBenefitData.type.id);
            setGodoCouponNoValue(couponBenefitData.godoCouponNo);
            setGodoCouponNameValue(couponBenefitData.godoCouponName);
            setGodoCouponRegDtValue(couponBenefitData.godoCouponRegDt);
            setApplyTypeValue(couponBenefitData.applyType.id);
            setApplyTypeCode(couponBenefitData.applyType.code);
            setApplySelectedValue(couponBenefitData.applyList.map(x => Number(x.value)));

            // 적용 설정이 게시글 일 경우 값을 문자열로 변환하여 넣어줌
            let applyValue = couponBenefitData.applyList.map(x => Number(x.value));
            if(couponBenefitData.applyType.code === APPLY_BOARD_CODE) {
                applyValue = couponBenefitData.applyList.map(x => x.value);
            }
            setApplySelectedValue(applyValue);
        }
    }, [couponBenefitData]);

    // lang 설정 UI
    const LanguageSelectTag = () => {
        const options = languages?.map((e, i) => {
            return (
                <option key={i} value={e.id}>{e.code.toUpperCase()}</option>
            );
        });
        return (
            <CommonSelectBox textAlign={'center'} padding={'0px 40px'} onChange={onChangeLanguage} value={languageValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // lang 설정 변경시 작동하는 로직
    const onChangeLanguage = (e) => {
        setLanguageValue(Number(e.target.value));
    };

    // 유형 설정 UI
    const TypeSelectTag = () => {
        const defaultApplyArr = [UNIT_DEFAULT_COMPLETE_CODE, UNIT_DEFAULT_REVIEW_CODE];
        // 쿠폰 혜택은 기본 설정이 없으므로 기본설정 유형을 제외한 일반적인 유형들만 표시합니다.
        const options = [];
        (pointBenefitType || []).forEach((e, i) => {
            if(! defaultApplyArr.includes(e.id)) {
                options.push(<option key={i} data-code={e.code} value={e.id}>{e.name.toUpperCase()}</option>);
            }
        });
        return (
            <CommonSelectBox radius={'3px'} width={'100%'} padding={'0px 0px 0px 10px'} margin={'10px 0px 0px 0px'} onChange={onChangeType} value={typeValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // 유형 설정 변경시 작동하는 로직
    const onChangeType = (e) => {
        const typeCode = e.target.options[e.target.selectedIndex].dataset.code;
        const changetypeValue = Number(e.target.value);

        if(typeCode === 'writeComment') {
            // 적용 설정 게시글 ID
            const boardId = pointBenefitApply.find(x => x.code === 'board').id;
            setApplyTypeValue(boardId);
            setApplyTypeCode('board');
            onChangeApplyValue([]);
        } else if(typeValue === 5) {
            // 댓글 작성에서 변경하는 경우 적용 설정 카테고리로 변경
            setApplyTypeValue(1);
            setApplyTypeCode('category');
            onChangeApplyValue([]);
        }

        setTypeValue(changetypeValue);
    };

    // 적용 설정 UI
    const ApplySelectTag = () => {
        const options = pointBenefitApply?.filter((e) => ((typeValue !== UNIT_BOARD_REVIEW_CODE && e.code !== 'board') || (typeValue === UNIT_BOARD_REVIEW_CODE && e.code === 'board')) && e.code !== 'default')?.map((e, i) => {
            return (
                <option key={i} data-code={e.code} value={e.id}>{e.name.toUpperCase()}</option>
            )
        });

        return (
            <CommonSelectBox radius={'3px'} width={'100%'} padding={'0px 0px 0px 10px'} margin={'10px 0px 0px 0px'} onChange={onChangeApply} value={applyTypeValue}>
                {options}
            </CommonSelectBox>
        );
    };
    // 적용 설정 변경시 작동하는 로직
    const onChangeApply = (e) => {
        const selectedCode = e.target.options[e.target.selectedIndex].dataset.code;
        setApplyTypeValue(Number(e.target.value));
        setApplyTypeCode(selectedCode);
        setApplySelectedValue([]);
    };
    // 적용 설정 카테고리 박스
    const onChangeApplyValue = (value) => {
        setApplySelectedValue(value);
    };

    const [createCouponBenefitMutation] = useMutation(CREATE_COUPON_BENEFIT);
    const [updateCouponBenefitMutation] = useMutation(UPDATE_COUPON_BENEFIT);
    const [deleteCouponBenefitMutation] = useMutation(DELETE_COUPON_BENEFIT);

    // 쿠폰 혜택 저장
    const onClickSave = async () => {
        if(! godoCouponNoValue) {
            showAlertMessage(t('burden.coupon.validate.title'), t('burden.coupon.validate.message3'));
            return;
        } else if(applySelectedValue.length === 0) {
            showAlertMessage(t('burden.coupon.validate.title'), t('burden.coupon.validate.message4'));
            return;
        } else if(isLoadingSave) {
            return;
        }

        // 저장 진행 중으로 상태 변경
        setIsLoadingSave(true);

        const queryVariables = {
            langId: languageValue,
            typeId: typeValue,
            godoCouponNo: godoCouponNoValue,
            applyTypeId: applyTypeValue,
            applyList: applySelectedValue.map(x => x.toString()),
        }
        if (docId) queryVariables.id = docId;

        let mutation = docId ? updateCouponBenefitMutation : createCouponBenefitMutation;
        const { data } = await mutation({
            variables: queryVariables
        });

        const isSucceed = data?.createCouponBenefit || data?.updateCouponBenefit;

        if (isSucceed) {
            showAlertMessage(t('saved'), t('saved'));
            props.history.goBack();
        } else {
            // 오류 발생 시 저장 진행 전으로 변경
            setIsLoadingSave(false);
            throw new Error("graphql query failed");
        }
    };

    // 쿠폰 혜택 삭제
    const onClickDelete = async () => {
        const { data } = await deleteCouponBenefitMutation({
            variables: { id: docId }
        });

        if (data?.deleteCouponBenefit) {
            showAlertMessage(t("Boards.delete.title"), t("Boards.delete.content"))
        } else {
            showAlertMessage(t("Boards.delete.title"), t("Boards.deleteFail.content"))
        }

        props.history.goBack();
    };

    // 쿠폰 유효성 검사
    const validateCoupon = (value) => {
        if(value) {
            const formatDate = dateFormat(value, 'yyyy-mm-dd');

            // 쿠폰 명이 존재하는지 확인
            if(! godoCouponNameValue) {
                showAlertMessage(t('burden.coupon.validate.title'), t('burden.coupon.validate.message1'));
                return;
            }
            // 쿠폰 유효성 검사
            setIsCouponSkipQuery(false);
            setGodoCouponRegDtValue(formatDate);
        } else {
            setGodoCouponRegDtValue(value);
        }
    }

    // 쿠폰 유효성을 검사하여 쿠폰이 있을경우 쿠폰 고유 번호를 세팅합니다.
    useEffect(() => {
        if(! loading && ! error && couponItems) {
            const resultCouponItems = couponItems?.getCouponItems;

            if(resultCouponItems && resultCouponItems.length > 0) {
                setGodoCouponNoValue(resultCouponItems[0].godoCouponNo);
            } else {
                showAlertMessage(t('burden.coupon.validate.title'), t('burden.coupon.validate.message2'));
                setGodoCouponRegDtValue('');
            }

            setIsCouponSkipQuery(true);
        } else if(! loading && error) {
            // 조회는 완료되었지만 오류일경우
            showAlertMessage(t('burden.coupon.validate.title'), t('burden.coupon.validate.message2'));
            setGodoCouponRegDtValue('');
            setIsCouponSkipQuery(true);
        }
    }, [couponItems, loading, error]);
    
    return (
        <PointWriteContainer>
            <PointHeader>
                { /* 몰 설정 */ }
                <LanguageSelectTag />
                { /* 버튼 */ }
                <div>
                    <CommonButton onClick={onClickDelete} padding={'0px 50px'} color={'#333333'} bgColor={'white'}>{t('Remove.label')}</CommonButton>
                    <CommonButton onClick={onClickSave} padding={'0px 50px'} margin={'0px 0px 0px 10px;'}>{t('Save.label')}</CommonButton>
                </div>
            </PointHeader>
            <FormContainer>
                { /* 유형 */ }
                <Row>
                    <RowTitle>{t('burden.point.unit')}</RowTitle>
                    <TypeSelectTag />
                </Row>

                { /* 쿠폰 설정 */ }
                <Row>
                    <RowTitle>{t('burden.coupon.option')}</RowTitle>
                    <CommonInputTextBox onChange={(e) => {setGodoCouponNameValue(e.target.value)}} value={godoCouponNameValue.toString()} placeholder={'고도몰 쿠폰명을 입력해주세요.'} />
                    <DatePicker
                        date={godoCouponRegDtValue}
                        placeholder={t('burden.coupon.godoDate')}
                        format={'YYYY-MM-DD'}
                        setDate={validateCoupon}
                    />
                </Row>

                { /* 적용 설정 */ }
                <Row>
                    <RowTitle>{t('burden.point.apply.title')}</RowTitle>
                    <ApplySelectTag/>
                    {
                        applyTypeCode === 'category' &&
                        <Category onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} />
                    }
                    {
                        applyTypeCode === 'brand' &&
                        <Brand onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} />
                    }
                    {
                        applyTypeCode === 'goodsNo' &&
                        <GoodsSearch onChangeApplyValue={onChangeApplyValue} applySelectedValue={applySelectedValue} lang={langArr[languageValue - 1]} />
                    }
                    {
                        applyTypeCode === 'board' &&
                        <CommonInputTextBox onChange={(e) => {onChangeApplyValue([e.target.value])}} value={applySelectedValue.toString()} placeholder={'게시글 주소를 입력하세요.'} />
                    }
                </Row>
            </FormContainer>
        </PointWriteContainer>
    );
});