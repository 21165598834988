import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateDataContext } from './templateData'
import Ajax from './ajax'
import TableList from './tableList'
import TemplateForm from './templateForm'
import Row from './tableList/customRow'
import { AlertContext } from '../../../common/AlertModal2'
export default (function (props) {
    const { t } = useTranslation()
    // 사용자 정보 
    const { 
        // selectId,
        loading,
        setLoading,
        setAuthList,
        templateList,
        setTemplateList,
        listUpdate,
        setListUpdate,
        setSelectId,
        setSelectData
    } = useContext(TemplateDataContext)
    const alert = useContext(AlertContext)
    const ajax = Ajax(t, alert)
    useEffect(() => {
        if (templateList === null && !loading) {
            console.log('update')
            async function getAuthList() {
                const { apiAuthList, templateAuthList } = await ajax.getAuthList()
                var authList = {}
                if (apiAuthList.forEach) {
                    apiAuthList.forEach(d => {
                        authList[d._id] = d.auth
                    })
                    setAuthList(authList)
                    setTemplateList(templateAuthList)
                }
                setLoading(false)
            }
            setLoading(true)
            getAuthList()
        }
    }, [ajax, loading, setLoading, setTemplateList, setAuthList, listUpdate, setListUpdate, templateList])
    var btnOpt = {
        readOnly: false
    }
    if (!loading) {
        btnOpt.onClick = onClick(setSelectId, setSelectData)
    }

    return (
        <>
            <button {...btnOpt}>새로추가</button>
            <TableList {...{ data: templateList, Row, loading, keyField: '_id' }}></TableList>
            <TemplateForm></TemplateForm>
        </>
    )
})
function onClick(setSelectId, setSelectData) {
    return (e) => {
        setSelectId(null)
        setSelectData(
            {
                _id: '',
                templateName: '',
                welcomeUrl: '',
                description: '',
                defaultTemplate: false,
                _auth: {}
            }
        )
    }
}