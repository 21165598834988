import React from 'react';
import { EditRequestDialogType } from './type';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { LanguageCodeEnum } from 'generated/graphql';
import palette from 'theme/palette';

export default function EditRequestDialog({ isOpen, onClose, description, linkText, link }: EditRequestDialogType) {
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <Stack>
        <Stack sx={{ pl: 3, pr: 3, pt: 5, textAlign: 'center' }} maxWidth={'400px'} spacing={3}>
          <Typography variant="body1" sx={{ wordBreak: 'keep-all' }}>
            {parse(description)}
          </Typography>
        </Stack>

        {i18n.language === LanguageCodeEnum.Ko && (
          <Stack alignItems={'center'} pt={3}>
            <Button variant="text" sx={{ width: 'fit-content', padding: 0, margin: 0, textDecoration: 'underline' }}>
              <Box
                component="a"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                color={palette.dark.primary.main}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  '&:hover, &:active': {
                    color: palette.dark.primary.main,
                    textDecoration: 'underline',
                  },
                }}
              >
                {linkText}
              </Box>
            </Button>
          </Stack>
        )}
        <Stack direction="row" spacing={1.5} p={3}>
          <Button onClick={onClose} variant={'contained'} size="large" sx={{ flex: 1 }}>
            {t('dialog.confirm')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
