import styled from 'styled-components';

export const CategoryItem = styled.div`
    min-width: 100px;
    margin: 2px;
    border: 1px solid #7C7C7C;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    height: 28px;
    align-items: center;
`;

export const Title = styled.div`
    font-weight: bold;
    color: #333333;
    margin-right: 10px;
    line-height: 100%;
`;

export const RemoveButton = styled.div`
    display: flex;
    align-items: center;
    i {
        font-weight: bold; 
        color: #333;
        font-size: 6px;
        line-height: 99%;
    }
`;