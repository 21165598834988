import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const OpenrunContainer = styled<{ disabled: boolean }>(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  background: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.04);' : 'rgba(78, 78, 255, 0.04);')};
`;
