import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from 'api/quries';
import { useTranslation } from 'react-i18next';
import { Icon } from 'rsuite';
import Tree from 'rc-tree';
import { Item, Text, Input, Value, EditorContainer, SelectBox, SearchBox, ListBox, DropdownBox, CategoryItems, Trigger } from '../../style';
import Arrow from './arrow';
import CategoryItem from '../item';

export default ({ onChangeApplyValue, applySelectedValue, disabled = false }) => {
    // 번역도구 
    const { t } = useTranslation();
    // 리스트 표현 여부 
    const [isShowList, setIsShowList] = useState(false);
    // 검색 키워드
    const [searchKeyword, setSearchKeyword] = useState('');
    // 확장된 ID들
    const [expandedIds, setExpandedIds] = useState([]);

    // 카테고리 정보 조회
    const { data, loading } = useQuery(GET_CATEGORIES, {
        variables: {
            lang: "ko"
        }
    });

    // 검색키워드 input 태그 변경 이벤트 처리기 메소드
    const onChangeSearchKeywordInputTag = (e) => {
        // 검색 키워드 설정 
        setSearchKeyword(e.target.value);
    };

    // select box 클릭 이벤트 처리기 메소드
    const onClickSelectBox = () => {
        setIsShowList(!isShowList);
    };

    const onExpand = (expandedKeys) => {
        setExpandedIds([...expandedKeys])
    };

    // 카테고리 저장
    const onSelect = (selectedKeys) => {
        onChangeApplyValue(selectedKeys);
    };

    // 카테고리 로딩 여부
    const isLoading = !(!loading && data && data.getCategoriesByLang);

    return (
        <Item style={{ marginTop: '10px' }}>
            <Value>
                {!isLoading && (() => {
                    let renderCategories = data.getCategoriesByLang.map(x => {
                        return { key: x.id, parent: x.parent, title: x.i18n[0].name, children: [] }
                    });

                    // 렌더링 할 카테고리 조립 
                    renderCategories = renderCategories.filter(x => {
                        // 검색키워드에 걸리지 않은경우 
                        if (x.title.indexOf(searchKeyword) === -1)
                            // 렌더링하지 않음 
                            return false;

                        // 해당 항목이 부모가 존재할 경우 
                        if (x.parent) {
                            // 부모 항목 객체 
                            let parentItemObj = renderCategories.find(y => y.key === x.parent);
                            // push 
                            parentItemObj && parentItemObj.children && parentItemObj.children.push(x);
                        }
                        return !x.parent;
                    });

                    return (
                        <>
                            <EditorContainer>
                                <SelectBox active={isShowList} onClick={onClickSelectBox}>
                                    <CategoryItems>
                                        {/* 카테고리 항목이 존재하지 않을 경우 placeholder / 존재할 경우 항목 리스트 표시  */}
                                        {applySelectedValue.length === 0 ?
                                            <Text>{t('goods.category')}</Text> :
                                            (
                                                applySelectedValue.map((x, i) =>
                                                    <CategoryItem
                                                        key={i}
                                                        id={x}
                                                        title={data?.getCategoriesByLang.find(y => y.id === x).i18n[0].name}
                                                        onChangeApplyValue={onChangeApplyValue}
                                                        applySelectedValue={applySelectedValue}
                                                    />
                                                )
                                            )
                                        }
                                    </CategoryItems>
                                    <Arrow active={isShowList} />
                                </SelectBox>
                                {(isShowList && !disabled) && (
                                    <DropdownBox>
                                        <SearchBox>
                                            <Input
                                                onChange={onChangeSearchKeywordInputTag}
                                                value={searchKeyword}
                                                placeholder={t('inputSearchKeyword')}
                                            />
                                        </SearchBox>
                                        <ListBox>
                                            <Tree
                                                multiple={true}
                                                treeData={renderCategories}
                                                showIcon={false}
                                                switcherIcon={(obj) => {
                                                    if (!obj.isLeaf) {
                                                        return <Trigger>
                                                            <Icon icon={obj.expanded ? "minus" : "plus"} />
                                                        </Trigger>
                                                    }
                                                }}
                                                onSelect={onSelect}
                                                onExpand={onExpand}
                                                autoExpandParent={false}
                                                expandedKeys={expandedIds}
                                                selectedKeys={applySelectedValue}
                                            />
                                        </ListBox>
                                    </DropdownBox>
                                )}
                            </EditorContainer>
                        </>
                    )
                })()}
            </Value>
        </Item >
    );
}