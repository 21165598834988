import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CommentModal, Title, Contents, ConfirmButton } from './styled';

// 가입인사 메세지 모달 
export default (({ isShow, onClose, onClick }) => {
    // 메세지 
    const [message, setMessage] = useState('');

    // 메세지 input 태그 변경 이벤트 처리기 메소드 
    const onChangeMessageInput = (e) => {

        // 메세지 변경 
        setMessage(e.target.value);
    };

    // 확인 버튼 클릭 이벤트 처리기 메소드 
    const onClickConfirmButton = () => {
        onClick(message);
        onClose();
    };

    return (
        <CommentModal className="commentModal" show={isShow} onHide={onClose} >
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <Title>반려사유</Title>
                <Contents>
                    <textarea onChange={onChangeMessageInput}/>
                </Contents>
                <ConfirmButton onClick={onClickConfirmButton}>확인</ConfirmButton>
            </Modal.Body>
        </CommentModal>
    )
})