import React, { useEffect, useState } from 'react';
import { LanguageCodeEnum, useGetPartnerStoreQuery } from 'generated/graphql';
import { Skeleton, Stack, Typography, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import NameField from './NameField';
import ContactField from './ContactField';
import PenNameField from './PenNameField';
import DocumentsField from './DocumentsField';
import NationalNumberIdField from './NationalNumberField';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isAgreeState, pageState, userStoreState, userTypeState } from './atom';
import CountryField from './CountryField';
import { PartnerTypeEnum } from 'signup/type';
import Navigator from './Navigator';
import CompanyNameField from './CompanyNameField';
import { Divider, Typography as TypographyCarpen, Checkbox } from 'carpenstreet-designsystem';

export default function StoreModify() {
  const page = useRecoilValue(pageState);
  const userType = useRecoilValue(userTypeState);
  const { t, i18n } = useTranslation();
  const [isAgree, setIsAgree] = useRecoilState(isAgreeState);
  const setState = useSetRecoilState(userStoreState);

  const { data, loading } = useGetPartnerStoreQuery({
    fetchPolicy: 'no-cache',
  });
  const userData = data?.me;

  const init = async () => {
    setState({
      email: userData.account,
      companyName: userData.companyName,
      termLanguage: userData.settle.signLang as LanguageCodeEnum,
      userType: userData.type as PartnerTypeEnum,
      name: userData.name,
      brandNames: {
        ko: userData.brand.i18ns.find((x) => x.languageCode === LanguageCodeEnum.Ko)?.name,
        en: userData.brand.i18ns.find((x) => x.languageCode === LanguageCodeEnum.En)?.name,
        zh: userData.brand.i18ns.find((x) => x.languageCode === LanguageCodeEnum.Zh)?.name,
        ja: userData.brand.i18ns.find((x) => x.languageCode === LanguageCodeEnum.Ja)?.name,
      },
      contact: userData.contact,
      country: userData.settle.country,
      documents: userData.reference.map((x) => x.url),
      nationalIdNumber: userData.settle.nationalIdNumber,
    });
  };

  useEffect(() => {
    if (!userData) return;

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <Box maxWidth={page === 1 ? '576px' : '776px'} width={'100%'}>
      <Stack direction={'column'} alignItems={'center'} textAlign={'center'}>
        <Typography fontWeight={700} fontSize={24} lineHeight={'36px'} color={'grey.800'}>
          {t('user.store.modifyTitle')}
        </Typography>

        <Box sx={{ mt: 1 }} />

        <Typography fontWeight={500} fontSize={14} lineHeight={'22px'} color={'grey.800'}>
          {parse(t('user.store.modify.description'))}
        </Typography>
      </Stack>

      <Box sx={{ mt: 3 }} />

      {page === 1 && (
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            {userType === PartnerTypeEnum.Business && <CompanyNameField />}
            <NameField />
            <PenNameField />
            {i18n.language === LanguageCodeEnum.Ko && <ContactField />}
            {i18n.language !== LanguageCodeEnum.Ko && <CountryField />}
            <DocumentsField />
            <NationalNumberIdField />
            <Divider sx={{ mt: '48px', mb: '40px' }} />
            <Stack justifyContent="space-between" direction="row">
              <TypographyCarpen variant="typography/body/small/medium" color="color/gray/800">
                {t('signupPage.policy.agreement')}
              </TypographyCarpen>
              <a href="/policy/agreement" target="_blank">
                <TypographyCarpen variant="typography/label/large/regular" color="color/gray/400" sx={{ textDecoration: 'underline' }}>
                  {t('signupPage.policy.detail')}
                </TypographyCarpen>
              </a>
            </Stack>
            <Stack justifyContent="space-between" direction="row" mt={1}>
              <TypographyCarpen variant="typography/body/small/medium" color="color/gray/800">
                {t('signupPage.policy.privacy')}
              </TypographyCarpen>
              <a href="/policy/privacy" target="_blank">
                <TypographyCarpen variant="typography/label/large/regular" color="color/gray/400" sx={{ textDecoration: 'underline' }}>
                  {t('signupPage.policy.detail')}
                </TypographyCarpen>
              </a>
            </Stack>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap="8px" mt={2}>
              <Checkbox size="M" color="primary" checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
              <TypographyCarpen variant="typography/body/small/regular" color="color/gray/800">
                {t('signupPage.policy.policyDescription02')}
              </TypographyCarpen>
              <TypographyCarpen variant="typography/body/small/regular" color="color/primary/600" whiteSpace="pre">
                {t('signupPage.policy.required')}
              </TypographyCarpen>
            </Stack>
          </Stack>
          <Box mt={4}>
            <Navigator />
          </Box>
        </Card>
      )}
    </Box>
  );
}
