import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageNavi.scss';

function PageNavi(props) {
    let tag = []
    let { totalPage, pathName, history, secPathName } = props
    if (pathName) {
        pathName = pathName.replace(/\/$/, '')
    } else {
        pathName = '/'
    }

    // 현재 페이지 
    let currentPage =
        (props.match &&
            props.match.params &&
            props.match.params.page) ? Number(props.match.params.page) : 1;

    // 검색 쿼리 
    let query = ''
    if (history.location.search) {
        query = history.location.search
    }
    // 현재 페이지가 1일 경우 
    if (currentPage === 1) {
        tag.push((<NavLink key='1' to={pathName + "/1" + query}
            className="page-num"
            isActive={(_, { pathname }, ) => {
                return [pathName, pathName + "/", pathName + "/1", pathName + "/1/",secPathName]
                .includes(pathname)}
                }>
            &nbsp;1&nbsp;
            </NavLink>)
        );
    }
    if (totalPage && totalPage > 1) {
        // 시작 값 
        const startValue = (() => {
            var value = 1;

            if (currentPage > 5) {
                value = currentPage - 4;
            }

            if (currentPage + 5 > totalPage) {
                value = totalPage - 9;
            }

            if (value < 1)
                value = 1;

            return value;
        })();

        const endValue = (startValue + 9) > totalPage ?
            totalPage : (startValue + 9);


        for (let i = startValue; i <= endValue; i++) {
            if (currentPage === 1 && i === 1)
                continue;
            // 네비게이션 조립 
            tag.push(
                <NavLink key={i} to={pathName + "/" + i + query}
                    className="page-num">
                    &nbsp;{i}&nbsp;
                </NavLink>
            );
        }
    }

    const prevBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 1이 아닐경우 
        if (currentPage !== 1) {
            btn = (<NavLink to={pathName + "/" + (currentPage - 1) + query}>{"<"}</NavLink>);
        }

        return btn;
    })();

    const nextBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 최대 페이지가 아닐경우 
        if (currentPage !== totalPage) {
            btn = (<NavLink to={pathName + "/" + (currentPage + 1) + query}>{">"}</NavLink>);
        }

        return btn;
    })();

    return (<>
        {prevBtn}
        {tag}
        {nextBtn}
    </>)
}
export default PageNavi