import React from 'react';
import { Box } from '@mui/material';
import { Button, DownloadIcon, Pagination, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { SalesRecordsFooterProps } from './SalesRecordsFooter.types';
import { useTranslation } from 'react-i18next';

export default function SalesRecordsFooter({ itemsLoading, data, page, onPageChange, onExcelDownloadButtonClick, excelLoading }: SalesRecordsFooterProps) {
  const theme = useDesignSystemTheme();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const lastPage = itemsLoading ? 0 : data.getBrandOrderProductItems.pagination.lastPage;
  const totalCount = itemsLoading ? 0 : data.getBrandOrderProductItems.pagination.totalCount;
  const isPaginationDisabled = itemsLoading || totalCount === 0;
  const isExcelDownloadButtonDisabled = itemsLoading || totalCount === 0 || excelLoading;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '12px',
        [theme.breakpoints.down('largeTablet')]: {
          margin: '8px 0 40px',
          gap: '8px',
        },
      }}
    >
      {!isPaginationDisabled && (
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('largeTablet')]: {
              backgroundColor: theme.palette['color/white'],
              padding: '16px 0',
              width: '100%',
              marginTop: 0,
            },
          }}
        >
          <Pagination
            size={'M'}
            count={lastPage}
            page={page}
            onChange={onPageChange}
            sx={{
              [theme.breakpoints.down('largeTablet')]: {
                display: 'none',
              },
            }}
          />
          <Pagination
            size={'L'}
            count={lastPage}
            page={page}
            onChange={onPageChange}
            siblingCount={0}
            sx={{
              display: 'none',
              [theme.breakpoints.down('largeTablet')]: {
                display: 'block',
              },
            }}
          />
        </Box>
      )}
      {/*  데스크탑 엑셀 다운로드 버튼  */}
      <Button
        variant={'outlined'}
        size={'M'}
        startIcon={<DownloadIcon />}
        disabled={isExcelDownloadButtonDisabled}
        onClick={onExcelDownloadButtonClick}
        sx={{
          alignSelf: 'end',
          marginTop: '24px',
          [theme.breakpoints.down('largeTablet')]: {
            display: 'none',
          },
        }}
      >
        {t('salesRecordsPage.footer.excelButton')}
      </Button>
      {/*  모바일 엑셀 다운로드 버튼  */}
      <Box
        sx={{
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
          backgroundColor: theme.palette['color/white'],
          width: '100%',
          [theme.breakpoints.down('largeTablet')]: {
            display: 'flex',
          },
        }}
      >
        <Button variant={'outlined'} size={'M'} startIcon={<DownloadIcon />} disabled={isExcelDownloadButtonDisabled} onClick={onExcelDownloadButtonClick} fullWidth={true}>
          {t('salesRecordsPage.footer.excelButton')}
        </Button>
      </Box>
      {/*  모바일 안내문  */}
      <Box
        sx={{
          display: 'none',
          padding: '16px',
          flexDirection: 'column',
          gap: '8px',
          [theme.breakpoints.down('largeTablet')]: {
            display: 'flex',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/bold'}>{t('salesRecordsPage.footer.notice.line1')}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line2')}</Typography>
        </Box>{' '}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line3')}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line4')}</Typography>
        </Box>
        {language !== 'ko' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: '4px',
            }}
          >
            <Typography variant={'typography/label/large/regular'}>•</Typography>
            <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line5')}</Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography variant={'typography/label/large/regular'}>•</Typography>
          <Typography variant={'typography/label/large/regular'}>{t('salesRecordsPage.footer.notice.line6')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
