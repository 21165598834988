import styled from 'styled-components';

export const List = styled.div`
    border-top: 1px solid #E0E0E0;
    margin-top: 16px;
    font-weight: 400;
    margin-bottom: 33px;
`;

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px; 
    border-bottom: 1px solid #E0E0E0;
    min-height: 40px;
    align-items: center;
`;

export const Name = styled.div`
    font-size: 12px;
    width: 60%;
    padding-right: 10px;
`;
export const NameInput = styled.input.attrs(props => ({
    type: 'input'
}))`
    font-size: 12px;
    width: 60%;
    padding-right: 10px;
    border: none;
`;

export const Size = styled.div`
    font-size: 12px;
    width: 20%;
    text-align: right;
    &:after {
        content: ' MB';
    }
`;
export const SizeInput = styled.input.attrs(props => ({
    type: 'text'
}))`
    font-size: 12px;
    width: 20%;
    text-align: right;
    border: none;
`;
export const Button = styled.button`
    background-color: inherit;
`;