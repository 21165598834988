import React, { useEffect } from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePasswordPartnerMutation, useGetIsEqualPasswordQuery } from 'generated/graphql';
import { FormProvider } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { DialogType } from './type';
import RHFTextField from 'components/hook-form/RHFTextField';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { authOrigin } from 'api/index';

type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export default function ChangePasswordDialog({ isOpen, onClose }: DialogType) {
  const client = useApolloClient();
  const getIsEqualPassword = useGetIsEqualPasswordQuery({
    skip: true,
  });
  const { t } = useTranslation();

  const [changePasswordPartner] = useChangePasswordPartnerMutation();
  const schema = Yup.object().shape({
    oldPassword: Yup.string().required(t('user.currentPasswordValidation1')),
    newPassword: Yup.string().matches(/^(?=.*[\w ])(?=.*[\d])(?=.*[^ \w\d\t\r\n\v\f]).{8,}$/i, t('user.newPasswordValidation1')),
    newPasswordConfirmation: Yup.string().oneOf([Yup.ref('newPassword')], t('user.confirmNewPasswordValidation1')),
  });

  const defaultValues: FormValuesProps = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, setError, watch } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // 패스워드 변경
      await changePasswordPartner({
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      });

      // 안내 메세지 표시
      alert(t('user.completeChangePassword'));

      // 로그아웃
      client.clearStore();
      await axios.post(authOrigin + '/signout');

      // 루트 페이지로 이동
      window.location.href = '/';
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors[0].extensions.exception.name === 'HttpException') {
        setError('oldPassword', {
          message: t('user.currentPasswordValidation1'),
        });
        return;
      }
      // 로그 기록
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!values.oldPassword) return;

    const getData = setTimeout(() => {
      getIsEqualPassword
        .refetch({
          password: values.oldPassword,
        })
        .then(({ data }) => {
          if (data.isEqualPassword) {
            return;
          }

          setError('oldPassword', {
            message: t('user.currentPasswordValidation2'),
          });
        });
    }, 500);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.oldPassword]);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: '480px',
          width: '100%',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Stack sx={{ pl: 3, pr: 3, pt: 5 }} maxWidth={'480px'} spacing={3}>
            <Typography variant="h6" fontSize={18} fontWeight={700}>
              {t('user.changePasswordTitle')}
            </Typography>
            <RHFTextField name="oldPassword" type="password" placeholder={t('user.currentPassword')} />
            <RHFTextField name="newPassword" type="password" placeholder={t('user.newPassword')} />
            <RHFTextField name="newPasswordConfirmation" type="password" placeholder={t('user.confirmNewPassword')} />
          </Stack>
          <Stack direction="row" spacing={1.5} p={3}>
            <Button variant={'outlined'} size="large" onClick={onClose} sx={{ flex: 1 }}>
              {t('cancel')}
            </Button>
            <LoadingButton type="submit" variant={'contained'} size="large" sx={{ flex: 1 }}>
              {t('user.changePassword')}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}
