import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
  informationCards?: React.ReactNode;
  className?: string;
  mt?: number | string;
}

export default function Field({ children, informationCards = <></>, className, mt = 6, sx }: Props) {
  return (
    <Box {...(className && { className })} mt={mt} display="grid" gap={3} gridTemplateColumns={'calc(100% - 300px) 276px'} sx={sx}>
      <Box flex="1">{children}</Box>
      <Box width="276px" position="relative" display="flex" flexDirection="column">
        {informationCards}
      </Box>
    </Box>
  );
}
