export const PLAN_TYPE = ({ type, isExclusive, t }) => {
  if (type === undefined) {
    return t('plan.notSelected');
  }
  let before = '';
  let after = t('plan.basc');

  if (type === 1) after = t('plan.plus');
  if (type === 2) after = t('plan.pro');
  if (type === 3) {
    if (isExclusive) return t('plan.exclusive');
    return t('plan.nonExclusive');
  }

  if (isExclusive) before = t('plan.exclusivePrev');

  return before + after;
};

export const strToPlanType = (str) => {
  if (typeof str !== 'string') {
    return undefined;
  }

  if (str.includes('BASIC')) {
    return { type: 0, isExclusive: false };
  }

  if (str.includes('PLUS')) {
    return { type: 1, isExclusive: str.includes('전속') };
  }

  if (str.includes('PRO')) {
    return { type: 2, isExclusive: str.includes('전속') };
  }

  return undefined;
};
