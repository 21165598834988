import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import palette from 'theme/palette';

export const TwinkleProductModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 556px !important;
  }
`;
export const TwinkleProductModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  min-height: 389px;
  gap: 16px;
  padding: 24px;
`;
export const TwinkleProductModalList = styled.ul`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--components-input-outlined, rgba(145, 158, 171, 0.32));
  overflow: hidden;
`;
export const TwinkleProductModalDescription = styled.div`
  display: flex;
  flex-direction: column;
  & > dl {
    & > dt,
    dd {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

export const TwinkleProductModalListItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  height: 272px;
  overflow-y: auto;
  gap: 8px;
  padding: 12px;
`;

export const TwinkleProductModalListItem = styled.li<{ isHeader?: boolean; isNotFound?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  ${(props) => props.isHeader && 'padding: 8px 12px;'}
  ${(props) => props.isHeader && `background: ${palette.light.grey[200]}`}
  ${(props) => props.isNotFound && 'justify-content: center;'}
  ${(props) => props.isNotFound && 'align-items: center;'}
  ${(props) => props.isNotFound && 'height: 100%;'}
`;

export const TwinkleProductModalSaveWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
