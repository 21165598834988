import React, { useState } from 'react';
import { Typography, Box, Stack, Skeleton } from '@mui/material';
import { UserFormBox } from './styled';
import { ChangePasswordDialog, EditRequestDialog, RequestCheckedDialog, RequestDialog } from './Dialog';
import { useTranslation } from 'react-i18next';
import { useGetAdminQuery } from 'generated/graphql';
import AdminOriginalForm from './AdminOriginatorForm';

enum InfoTypeEnum {
  Originiator = 'originator',
  Store = 'store',
  Settle = 'settle',
}

type Props = {
  userId?: number;
};

export default function AdminForm({ userId }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useGetAdminQuery();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false);
  const [openRequestCheckedDialog, setOpenRequestCheckedDialog] = useState<boolean>(false);

  const [value, setValue] = useState<InfoTypeEnum>(InfoTypeEnum.Originiator);

  const handleShowEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <>
      <RequestDialog
        isOpen={openRequestDialog}
        onClose={() => {
          setOpenRequestDialog(false);
        }}
      />
      <RequestCheckedDialog
        isOpen={openRequestCheckedDialog}
        onClose={() => {
          setOpenRequestCheckedDialog(false);
        }}
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Originiator && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.creatorEditRequest')}
        link="https://forms.gle/RLBfvXjjsn1pGrULA"
        linkText="창작자 정보 변경 신청 폼"
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Store && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.storeEditRequest')}
        link="https://forms.gle/RLBfvXjjsn1pGrULA"
        linkText="판매 입점 정보 변경 신청 폼"
      />
      <EditRequestDialog
        isOpen={value === InfoTypeEnum.Settle && openEditDialog}
        onClose={handleCloseEditDialog}
        description={t('user.dialog.settleEditRequest')}
        link="https://docs.google.com/forms/d/e/1FAIpQLScMAPGdU3yp3SMKh4RUMQNRIHf3KetixcGG7y6AIM_PTOzMOg/viewform?fbzx=6279660984734325168"
        linkText="정산 정보 변경 신청폼"
      />
      <ChangePasswordDialog isOpen={openChangePasswordDialog} onClose={handleCloseChangePasswordDialog} />
      <Box maxWidth={'576px'} width={'100%'}>
        <Stack alignItems={'center'}>
          <Box mb={5}>
            <Typography variant={'h4'} fontSize={24} fontWeight={700} lineHeight={'36px'} color={'#212B36'}>
              {t('user.mypageTitle')}
            </Typography>
          </Box>

          <Box width={'100%'}>
            <UserFormBox>
              <AdminOriginalForm
                userId={userId}
                onShowChangePasswordDialog={() => {
                  setOpenChangePasswordDialog(true);
                }}
                onClickShowDialog={handleShowEditDialog}
              />
            </UserFormBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
