import React from 'react';
import { DatePicker } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { DatePickerWrapper } from './style';
import 'rsuite/dist/styles/rsuite-default.css';

export default (({ placeholder, date, setDate, format = 'YYYY-MM-DD HH:mm:ss' }) => {
    const { t } = useTranslation();

    const onChangeDateRangePicker = value => {
        // 변경할 일자 
        setDate && setDate(value);
    };

    return (
        <DatePickerWrapper>
            <DatePicker
                format={format}
                placeholder={placeholder}
                block
                appearance="subtle"
                value={date}
                onChange={onChangeDateRangePicker}
                locale={{
                    sunday: t('sunday'),
                    monday: t('monday'),
                    tuesday: t('tuesday'),
                    wednesday: t('wednesday'),
                    thursday: t('thursday'),
                    friday: t('friday'),
                    saturday: t('saturday'),
                    ok: t('confirm'),
                    today: t('today'),
                    yesterday: t('yesterday'),
                    last7Days: t('last7Days'),
                }}
            />
        </DatePickerWrapper>
    );
});