import { Box, Popover, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SearchIcon } from './tags.styles';
import { useTranslation } from 'react-i18next';
import palette from 'theme/palette';
import parse from 'html-react-parser';
import { TDocumentTag } from 'boards/DetailBoardWrite/stores/document.types';

interface ITagsDropdown {
  input: string;
  disabled: boolean;
  tagsData: TDocumentTag[];
  onAddTag: (val: TDocumentTag) => void;
  onKeyDown: (e: React.KeyboardEvent, val: string) => void;
  onFocus: () => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function generateCaseInsensitiveRegex(pattern: string) {
  return new RegExp(
    Array.from(pattern)
      .map((char) => `[${char.toLowerCase()}${char.toUpperCase()}]`)
      .join(''),
  );
}
function extractMatchedSubstring(targetString: string, patternString: string) {
  const pattern = generateCaseInsensitiveRegex(patternString);
  const matched = targetString.match(pattern);
  return matched ? matched[0] : null;
}

export default function TagsSearch({ input, disabled, tagsData, onAddTag, onKeyDown, onFocus, onChangeInput }: ITagsDropdown) {
  const { t } = useTranslation();
  const textfieldRef = React.useRef<HTMLInputElement>(null);
  const popoverListRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [current, setCurrent] = useState(0);
  const [filteredTagItemClicked, setFilteredTagItemClicked] = useState(false);

  const isPopoverOpen = Boolean(anchorEl);

  const id = isPopoverOpen ? 'tags-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
    setCurrent(0);
  };
  const handleKeydown = (e: any) => {
    onKeyDown(e, tagsData[current]?.name || '');
    e.stopPropagation();
    setAnchorEl(e.currentTarget);

    // 최초 arrow down 눌렀을 때 2회 내려가는 현상 방지
    if (e.isComposing || e.keyCode === 229) return;
    if (e.key === 'ArrowDown' && tagsData.length > 0 && tagsData.length - 1 > current) {
      const val = current + 1;
      setCurrent(val);
      if (val > 4 && popoverListRef.current.scrollTop <= 34 * (val - 4)) popoverListRef.current.scrollTop = 34 * (val - 4);
    }
    if (e.key === 'ArrowUp' && tagsData.length > 0 && current > 0) {
      const val = current - 1;
      setCurrent(val);
      if (popoverListRef.current.scrollTop >= 34 * val) popoverListRef.current.scrollTop = 34 * val;
    }
    if (e.key === 'Enter') {
      textfieldRef.current.value = '';
      setAnchorEl(null);
      setCurrent(0);
    }
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // click으로 태그 선택했을 때 focus 이벤트 발생 방지
    if (filteredTagItemClicked) {
      e.preventDefault();
      setFilteredTagItemClicked(false);
      return;
    }
    if (e.currentTarget.value) setAnchorEl(textfieldRef.current);
    onFocus();
  };
  const handleBlur = (e: any) => {
    if (!isPopoverOpen) {
      setAnchorEl(null);
      setCurrent(0);
    }
  };

  return (
    <Box
      position="relative"
      display="flex"
      mt="12px"
      {...(isPopoverOpen && {
        zIndex: -1,
      })}
    >
      <TextField
        ref={textfieldRef}
        aria-describedby={id}
        onKeyDown={handleKeydown}
        disabled={disabled}
        placeholder={t('document.tags.placeholder')}
        onChange={onChangeInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={input || ''}
        inputProps={{ maxLength: 255 }}
        InputProps={{
          startAdornment: <SearchIcon />,
          autoComplete: 'off',
        }}
        fullWidth
      />

      <Popover
        id={id}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        disableAutoFocus // 열릴 때 Popover 내부로 포커스가 이동하지 않도록 설정
        disableEnforceFocus // Popover 내부로 포커스가 이동하지 않도록 설정
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 0,
        }}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              padding: tagsData.length > 0 ? '8px 4px 16px 8px' : '8px 8px 16px 8px',
              width: textfieldRef?.current?.clientWidth || 0,
              boxShadow: '-20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)',
            },
          },
        }}
      >
        <Box
          ref={popoverListRef}
          sx={{
            ...(tagsData.length > 0
              ? {
                  height: `${34 * 5}px`,
                }
              : {
                  padding: '8px 8px 16px 8px',
                }),
            overflowX: 'hidden',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `${palette.dark.grey[600]}7a`,
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-button': {
              width: 0,
              height: 0,
            },
          }}
        >
          {tagsData.length > 0 ? (
            tagsData.map((tag, idx) => (
              <Box
                key={tag.id}
                sx={{
                  p: '6px 8px',
                  borderRadius: '6px',
                  ...(current === idx && {
                    background: `${palette.dark.grey[500]}14`,
                  }),
                }}
                onClick={() => {
                  onAddTag(tag);
                  setCurrent(0);
                  setAnchorEl(null);
                  setFilteredTagItemClicked(true);
                }}
              >
                <Typography sx={{ '& span': { color: palette.dark.primary.light } }} fontSize="14px !important" lineHeight="22px !important">
                  {tag.name && parse(tag.name.replace(generateCaseInsensitiveRegex(input), `<span>${extractMatchedSubstring(tag.name, input)}</span>`))}
                </Typography>
              </Box>
            ))
          ) : (
            <>
              <Typography>{t('document.tags.notFound')}</Typography>
              <Box mt={1.5} color={palette.dark.text.secondary}>
                <Typography fontWeight="700">{t('document.tags.tipSectionTitle')}</Typography>
                <Box mt="4px" display="grid" gridTemplateColumns="auto 1fr">
                  <Typography padding="0 8px">•</Typography>
                  <Typography>{t('document.tags.tip1')}</Typography>
                  <Typography padding="0 8px">•</Typography>
                  <Typography>{t('document.tags.tip2')}</Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
}
