import React from 'react';

export default function header({ subMenu, setSubMenu }) {

    function onChange(e) {
        setSubMenu(e.target.value)
    }
    console.log('HEADER')

    var button = ['auth', 'template'].map(d => {
        const checked = d === subMenu ? true : false
        return (

            <label className='radioButton' key={d}>
                {d}
                <input className="radioButtonInput" type='radio' onChange={onChange} value={d} checked={checked}></input>
                <span className="radioButtonSpan"></span>
            </label>
        )
    })
    button = <div>{button}</div>
    return <>
        <div>
            {button}
        </div>
    </>
}





