
import { graphqlUrl } from 'api'
import axios from 'axios'

export default (t, alert, setLoading, setListUpdate) => {
    function getAlert(result, message = [], callback = []) {
        if (callback && callback[result.resultCode]) {
            callback = callback[result.resultCode]
            callback = () => { callback(result.data) }
        }
        if (result && message && message[result.resultCode]) {
            message = message[result.resultCode]
            if (result.resultCode !== 1) {
                console.log(result)
            }
        } else {
            message = t('authManager.unkownError')
        }
        alert(message, callback)
    }
    async function getAuthList() {
        var result = []
        try {

            var query = `query ($input: AuthQuery!) {
                apiAuthList(authQuery: $input) {
                  message
                  resultCode
                  total
                  data {
                    _id
                    describe
                    auth
                    defaultAuth
                    defaultValue
                  }
                  message
                  error
                }
                templateAuthList{
                    data{
                      _id
                      templateName
                    }
                    total
                    message
                    resultCode
                    error
                  }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: { input: {} }
            })
            result = { ...data.data.data }

            if (result.apiAuthList.resultCode === 1
                && result.templateAuthList.resultCode === 1) {
                result = {
                    apiAuthList: result.apiAuthList.data,
                    templateAuthList: result.templateAuthList.data
                }
            } else {
                getAlert(result.apiAuthList, [t('authManager.unkownError'), null, t('authManager.apiAuthList_2')])
            }
        } catch (err) {
            console.log(err)
        }

        return result
    }
    async function createAuth(input) {
        var result
        try {
            var { _id, describe, auth, defaultAuth, defaultValue } = input
            var query = `mutation ($input: InputAuth!) {
                    createAuth(input: $input) {
                      resultCode
                      message
                      data
                      error
                    }
                }`
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: {
                    "input": { _id, describe, auth, defaultAuth, defaultValue }
                }
            })
            result = data.data.data.createAuth
        } catch (err) {
            console.log(err)
        }
        if (result) {
            switch (result.resultCode) {
                case 1:
                    alert(t('authManager.createAuth_1'))
                    break
                case 2:
                    alert(t('authManager.createAuth_2'))
                    break
                case 3:
                    alert(t('authManager.createAuth_3'))
                    break
                default:
                    alert(t('authManager.unkownError'))
            }
        } else {
            alert(t('authManager.unkownError'))
        }
        return result
    }
    async function updateAuth(input) {
        var result
        try {
            var { _id, describe, auth, defaultAuth, defaultValue } = input
            var query = `mutation ($input: InputAuth!) {
                updateAuth(input: $input) {
                  resultCode
                  message
                  data
                }
              }
              `
            let data = await axios.post(graphqlUrl, {
                query: query,
                variables: {
                    "input": { _id, describe, auth, defaultAuth, defaultValue }
                }
            })
            result = data.data.data.updateAuth
            if (result) {
                switch (result.resultCode) {
                    case 1:
                        alert(t('authManager.updateAuth_1'))
                        break
                    case 2:
                        alert(t('authManager.updateAuth_2'))
                        break
                    case 3:
                        alert(t('authManager.updateAuth_3'))
                        break
                    default:
                        alert(t('authManager.unkownError'))
                }
            } else {
                alert(t('authManager.unkownError'))
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function deleteAuth(_id) {
        var result
        try {
            var query = `mutation  {
            deleteAuth(_id:"${_id}") {
              resultCode
              message
              data
            }
          }      
          `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: {

                }
            })
            result = data.data.data.deleteAuth
        } catch (err) {
            console.log(err)
        }
        if (result) {
            switch (result.resultCode) {
                case 1:
                    alert(t('authManager.deleteAuth_1'))
                    break
                case 2:
                    alert(t('authManager.deleteAuth_2'))
                    break
                case 3:
                    alert(t('authManager.deleteAuth_3'))
                    break
                default:
                    alert(t('authManager.unkownError'))
            }
        } else {
            alert(t('authManager.unkownError'))
        }
        return result
    }
    return {
        getAuthList
        , createAuth
        , updateAuth
        , deleteAuth
    }
}