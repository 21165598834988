import React from 'react';
import styled from 'styled-components';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import './PageNavi.scss';

const Styled = {
    Div: styled.div`
        display: flex;
        justify-content: center;
    `
}
function PageNavi(props) {
    const history = useHistory();
    const { name, page } = useParams();
    const pathname = `/aconfaq/${name}`
    let tag = []
    let { totalPage, secPathName } = props

    // 현재 페이지 
    let currentPage = page ? Number(page) : 1;

    // 검색 쿼리 
    let query = ''
    if (history.location.search) {
        query = history.location.search
    }
    // 현재 페이지가 1일 경우 
    if (currentPage === 1) {
        tag.push((<NavLink key='1' to={pathname + "/1" + query}
            className="page-num"
            isActive={(_, { pathname },) => {
                return [pathname, pathname + "/", pathname + "/1", pathname + "/1/", secPathName]
                    .includes(pathname)
            }
            }>
            &nbsp;1&nbsp;
        </NavLink>)
        );
    }
    if (totalPage && totalPage > 1) {
        // 시작 값 
        const startValue = (() => {
            var value = 1;

            if (currentPage > 5) {
                value = currentPage - 4;
            }

            if (currentPage + 5 > totalPage) {
                value = totalPage - 9;
            }

            if (value < 1)
                value = 1;

            return value;
        })();

        const endValue = (startValue + 9) > totalPage ?
            totalPage : (startValue + 9);


        for (let i = startValue; i <= endValue; i++) {
            if (currentPage === 1 && i === 1)
                continue;
            // 네비게이션 조립 
            tag.push(
                <NavLink key={i} to={pathname + "/" + i + query}
                    className="page-num">
                    &nbsp;{i}&nbsp;
                </NavLink>
            );
        }
    }

    const prevBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 1이 아닐경우 
        if (currentPage !== 1) {
            btn = (<NavLink to={pathname + "/" + (currentPage - 1) + query}>{"<"}</NavLink>);
        }

        return btn;
    })();

    const nextBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 최대 페이지가 아닐경우 
        if (currentPage !== totalPage) {
            btn = (<NavLink to={pathname + "/" + (currentPage + 1) + query}>{">"}</NavLink>);
        }

        return btn;
    })();

    return (<Styled.Div>
        {prevBtn}
        {tag}
        {nextBtn}
    </Styled.Div>)
}
export default PageNavi