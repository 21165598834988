import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, FlexItem, Summary } from '../style';
import { Typography } from '@mui/material';

const Button = styled.button`
  padding: 5px 15px;
  margin-left: 15px;
  background: white;
  border-radius: 5px;
  border: 1px solid #a4a4a4;
`;

const Label = styled.label`
  width: 100%;
  min-width: 200px;
  height: 36px;
  padding-left: 15px;
  line-height: 36px;
  background: #eeeeee;
  border: 0.25px solid #4a4a4a;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  * {
    display: none !important;
  }
`;

const Component = (props) => {
  const id = Math.random().toString(36).substr(2, 11);
  const [text, setText] = useState('');

  const onChange = (e) => {
    setText(e.target.value);
    props.onChange(e);
  };

  return (
    <Flex className={props.className} style={{ width: '100%' }}>
      <FlexItem>
        <Label>
          {text}
          <input type="file" id={id} value={props.value} onChange={onChange} />
        </Label>
        {props.helperText && (
          <Typography color="#7C7C7C" fontSize="12px" lineHeight="130%">
            {props.helperText}
          </Typography>
        )}
        <Summary>{props.children}</Summary>
      </FlexItem>
      <Button>
        <label htmlFor={id}>찾아보기</label>
      </Button>
    </Flex>
  );
};

const InputFile = styled(Component)``;

export default InputFile;
