import styled from "styled-components";
import { BlackButton } from "components/button";
import { Modal } from "react-bootstrap";

// 확인 버튼
export const ConfirmButton = styled(BlackButton)`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 100%;
  height: auto;
`;

export const Title = styled.div`
  display: flex;
  align-items: left;
  margin-bottom: 15px;
`;

export const Contents = styled.div`
  display: flex;
  margin-bottom: 40px;

  textarea {
    resize: none;
    width: 100%;
    border: 1px solid #ccc;
    height: 150px;
    padding: 10px;
    font-size: 14px;
  }
`;

export const CommentModal = styled(Modal)`
  font-size: 16px;
  line-height: 20px;

  color: black;
  &__title {
    text-align: left;
    width: 100%;
    float: left;
    margin-bottom: 15px;
  }
  &__loading {
    padding-top: 56px;
    width: 100%;
    height: 100%;
    margin-bottom: 28px;
    position: relative;
    img {
      padding: 20px;
    }

    &__text {
      font-size: 12px;
      margin-bottom: 100px;
    }
  }
  &__contents {
    text-align: left;
    width: 100%;
    float: left;
    margin-bottom: 40px;
  }
  .modal-dialog {
    width: 360px;
    height: 301px;
    text-align: center;
    top: calc(50% - 230px);
    border-radius: 0;
  }
  .confirm {
    width: 200px;
    height: 36px;
    background-color: $color-vivid;
    color: white;
    border-radius: 3px;
    border: 0;
    margin: 0 auto;
  }
  button.close {
    display: none;
  }
  .modal-header {
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 0;
  }
  .modal-title {
    font-weight: bold;
  }
  .modal-body {
    padding-top: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 46px;
  }
  .modal-footer {
    padding-top: 25px;
    padding-bottom: 34px;
    text-align: center;
    border-top: 0;
  }
`;
