import React from 'react';
import { Wrap, Page, PageMove } from './style';
import queryString from 'query-string';

export default (({ pageName='page', totalPage, pathName, history, match, secPathName }) => {
    let tag = []
    if (pathName) {
        pathName = pathName.replace(/\/$/, '')
    } else {
        pathName = '/'
    }

    // 현재 페이지 
    let currentPage = (() => {
        const page = queryString.parse(history.location.search)[pageName];
        return page ? Number(page) : 1;
    })();

    const getUrlQuery = (page) => {
        const urlParamObj = queryString.parse(history.location.search);
        urlParamObj[pageName] = page;
        return queryString.stringify(urlParamObj);
    }
    // 검색 쿼리 
    let query = getUrlQuery(currentPage);
    // 현재 페이지가 1일 경우 
    if (currentPage === 1) {
        tag.push((<Page key='1' to={`${pathName}?${query}`}
            className="page-num"
            isActive={(_, args,) => {
                return true
            }
            }>
            &nbsp;1&nbsp;
        </Page>)
        );
    }
    if (totalPage && totalPage > 1) {
        // 시작 값 
        const startValue = (() => {
            var value = 1;

            if (currentPage > 5) {
                value = currentPage - 4;
            }

            if (currentPage + 5 > totalPage) {
                value = totalPage - 9;
            }

            if (value < 1)
                value = 1;

            return value;
        })();

        const endValue = (startValue + 9) > totalPage ?
            totalPage : (startValue + 9);


        for (let i = startValue; i <= endValue; i++) {
            if (currentPage === 1 && i === 1)
                continue;


            // 네비게이션 조립 
            tag.push(
                <Page key={i} to={pathName + "/?" + getUrlQuery(i)}
                    isActive={(_, args,) => {
                        return i === currentPage
                    }}
                    className="page-num">
                    &nbsp;{i}&nbsp;
                </Page>
            );
        }
    }

    const prevBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 1이 아닐경우 
        if (currentPage !== 1) {
            btn = (<PageMove to={pathName + "/?" + getUrlQuery(currentPage - 1)}>{"<"}</PageMove>);
        }

        return btn;
    })();

    const nextBtn = (function () {
        var btn = (<></>);
        // 현재 페이지가 최대 페이지가 아닐경우 
        if (currentPage !== totalPage) {
            btn = (<PageMove to={pathName + "/?" + getUrlQuery(currentPage + 1)}>{">"}</PageMove>);
        }

        return btn;
    })();

    return (
        <>
            <Wrap>
                {prevBtn}
                {tag}
                {nextBtn}
            </Wrap>
        </>
    )
});