import React, { Fragment, useMemo } from 'react';
import { Box, Stack } from '@mui/material';

import { useDesignSystemTheme, Typography, Divider, Tooltip, Button } from 'carpenstreet-designsystem';
import { useTranslation } from 'react-i18next';

const partnershipContents = (t) => [
  {
    title: t('partnership.section02.exclusive.title'),
    descriptions: [
      t('partnership.section02.exclusive.description01'),
      t('partnership.section02.exclusive.description02'),
      t('partnership.section02.exclusive.description03'),
      t('partnership.section02.exclusive.description04'),
    ],
    commission: 15,
    isExclusive: true,
    footerDescription: t('partnership.section02.exclusive.description06'),
  },
  {
    title: t('partnership.section02.nonExclusive.title'),
    descriptions: [t('partnership.section02.nonExclusive.description01'), t('partnership.section02.nonExclusive.description02')],
    commission: 20,
    isExclusive: false,
  },
];

export default function Partnerships({ onClick }: { onClick: (value: boolean) => void }) {
  const theme = useDesignSystemTheme();
  const { t } = useTranslation();

  const partnerships = useMemo(() => partnershipContents(t), [t]);

  return (
    <Box p="60px" bgcolor={theme.palette['color/gray/50']} textAlign="center">
      <Typography variant="typography/headline/h3/black" color="color/gray/800">
        {t('partnership.section02.title')}
      </Typography>
      <Box mt={5} mx="auto" width="fit-content" display="grid" textAlign="left" gridTemplateColumns="repeat(2, 1fr)" gap="32px">
        {partnerships.map((partnership) => (
          <Box
            key={partnership.title}
            display="flex"
            flexDirection="column"
            border={`4px solid ${partnership.isExclusive ? theme.palette['color/primary/600'] : theme.palette['color/gray/100']}`}
            width={380}
            px={5}
            py={6}
            borderRadius={2}
            bgcolor={theme.palette['color/white']}
          >
            <Stack direction="column" height="138px">
              <Typography variant="typography/title/medium/black" color="color/gray/800" mb="10px">
                {partnership.title}
              </Typography>
              {partnership.descriptions?.map((description) => (
                <Typography variant="typography/body/small/regular" color="color/gray/800" whiteSpace="pre" mt="4px" letterSpacing="-0.1px">
                  · {description}
                </Typography>
              ))}
            </Stack>
            <Divider sx={{ my: '32px', borderStyle: 'dashed' }} />
            <Stack direction="column" gap="15px" pt="12px" pb="7px">
              {partnership.isExclusive ? (
                <Tooltip color="sale" direction="right" open title={`5% ${t('partnership.section02.cut')}`}>
                  <Box sx={{ width: 'fit-content' }}>
                    <Typography variant="typography/label/large/medium" color="color/gray/800">
                      {t('partnership.section02.charge')}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Typography variant="typography/label/large/medium" color="color/gray/800" height="24px" lineHeight="24px">
                  {t('partnership.section02.charge')}
                </Typography>
              )}
              <Stack direction="row" alignItems="flex-end">
                <Typography variant="typography/headline/h1/black" color="color/gray/800" lineHeight="42px">
                  {partnership.commission}
                </Typography>
                <Typography variant="typography/title/medium/black" color="color/gray/800" ml="2px">
                  %
                </Typography>
                {partnership.isExclusive && (
                  <Typography variant="typography/title/medium/regular" color="color/gray/200" ml="6px" sx={{ textDecorationLine: 'line-through' }}>
                    20%
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Divider sx={{ my: '32px', borderStyle: 'dashed' }} />
            <Button variant={partnership.isExclusive ? 'contained' : 'outlined'} color="primary" size="L" onClick={() => onClick(partnership.isExclusive)}>
              <Typography variant="typography/body/medium/bold" color={partnership.isExclusive ? 'color/white' : 'color/primary/600'}>
                {t('partnership.section02.selectButton')}
              </Typography>
            </Button>

            {partnership.isExclusive && (
              <Typography variant="typography/label/small/regular" color="color/gray/400" lineHeight="14px" mt="12px">
                {t('partnership.section02.exclusive.description05')}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
