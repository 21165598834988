import React, { useEffect } from 'react';
import { Radio } from '../input';
import { BusinessTypeEnum, SignupChapterEnum, PartnerTypeEnum } from '../type';
import { useRecoilState } from 'recoil';
import { chapterState, userTypeFormState } from 'signup/SignUp.atoms';
import { Box, Stack, Typography } from '@mui/material';
import Navigator from 'signup/Navigator';
import { InformationCard } from 'acon-mui/components/Board';

const UserTypes = [
  {
    label: '개인',
    value: PartnerTypeEnum.Personal,
  },
  {
    label: '사업자',
    value: PartnerTypeEnum.Business,
  },
];

const BusinessTypes = [
  {
    label: '과세 사업자',
    value: BusinessTypeEnum.Taxable,
  },
  {
    label: '면세 사업자',
    value: BusinessTypeEnum.DutyFree,
  },
];

export default function UserTypeForm() {
  const [userTypeForm, setUserTypeForm] = useRecoilState(userTypeFormState);

  const [, setChapterValid] = useRecoilState(chapterState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypeForm({
      userType: e.target.value as PartnerTypeEnum,
      businessType: undefined,
    });
  };

  const handleBusinessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypeForm({
      ...userTypeForm,
      businessType: e.target.value as BusinessTypeEnum,
    });
  };

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.UserType]: valid,
      };
    });
  };

  useEffect(() => {
    if (!userTypeForm.userType && !userTypeForm.businessType) return;

    if (userTypeForm.userType === PartnerTypeEnum.Personal && !userTypeForm.businessType) {
      setCurrentChapterValid(false);
    }

    setCurrentChapterValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeForm.userType, userTypeForm.businessType]);

  useEffect(() => {
    if (userTypeForm.userType === PartnerTypeEnum.Business && !userTypeForm.businessType) {
      setUserTypeForm({
        ...userTypeForm,
        businessType: BusinessTypeEnum.Taxable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeForm]);

  const handleNext: () => Promise<string> = () => {
    return new Promise((resolve) => {
      resolve('');
    });
  };

  return (
    <>
      <Box>
        <Stack spacing={10}>
          <Stack alignItems={'center'} textAlign="center">
            <Typography fontWeight={700} fontSize={'22px'} lineHeight={'150%'} color={'#333'}>
              에이콘 입점을 환영합니다! <br /> 입점 유형을 알려주세요.
            </Typography>
            <InformationCard type="warning" mt={1} p="10px 16px" gap={1} textAlign="left" color="#D32F2F" sx={{ '& p': { fontWeight: 600 } }}>
              간이사업자는 [개인]으로 가입해주세요.
              <br />
              에이콘 정책상, 간이사업자는 개인의 정산 정책을 따릅니다.
            </InformationCard>
            <Stack mt={5} spacing={3}>
              {UserTypes.map((x, i) => (
                <Radio key={`user_${i}`} name="type" value={x.value} checkValue={userTypeForm.userType} onChange={handleChange}>
                  {x.label}
                </Radio>
              ))}
            </Stack>
          </Stack>
          {userTypeForm.userType === PartnerTypeEnum.Business && (
            <Stack spacing={7.5} alignItems={'center'}>
              <Typography fontWeight={700} fontSize="22px">
                사업자 유형을 선택해주세요.
              </Typography>
              <Stack spacing={3}>
                {BusinessTypes.map((x, i) => (
                  <Radio key={`business_${i}`} name="subType" value={x.value} checkValue={userTypeForm.businessType} onChange={handleBusinessChange}>
                    {x.label}
                  </Radio>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
      <Navigator onNext={handleNext} />
    </>
  );
}
