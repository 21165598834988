import { Document_Status, Language_Code, Partner_Document_Status } from 'generated/graphql';

// 상태 - 임시저장됨
export const STATUS_DRAFT = Document_Status.Draft;
// 상태 - 요청됨
export const STATUS_REQUEST = Document_Status.Requested;
// 상태 - 진행중
export const STATUS_INPROGRESS = Document_Status.InReview;
// 상태 - 반려됨
export const STATUS_REJECT = Document_Status.Rejected;
// 상태 - 검토완료됨
export const STATUS_APPROVED = Document_Status.Complete;
// 상태 - 오픈
export const STATUS_OPEN = Document_Status.Complete;

// 상태 - 임시저장됨
export const NEW_STATUS_DRAFT = Document_Status.Draft;
// 상태 - 요청됨
export const STATUS_REQUESTED = Document_Status.Requested;
// 상태 - 진행중
export const STATUS_IN_REVIEW = Document_Status.InReview;
// 상태 - 거절됨
export const STATUS_DENIED = Document_Status.Denied;
// 상태 - 반려됨
export const STATUS_REJECTED = Document_Status.Rejected;
export const STATUS_REJECTED_ON_OPEN = Document_Status.RejectedOnOpen;
// 상태 - 검토완료됨
export const STATUS_COMPLETE = Document_Status.Complete;
// 상태 - 수정 임시저장됨
export const STATUS_DRAFT_ON_OPEN = Document_Status.DraftOnOpen;
// 상태 - 수정요청
export const STATUS_REQUESTED_ON_OPEN = Document_Status.RequestedOnOpen;
// 상태 - 수정검토중
export const STATUS_IN_REVIEW_ON_OPEN = Document_Status.InReviewOnOpen;
// 상태 - 수정완료
export const STATUS_COMPLETE_ON_OPEN = Document_Status.CompleteOnOpen;
// 상태 - 판매중
export const STATUS_ON_SALE = Document_Status.OnSale;
export const STATUS_ON_SALE_ON_OPEN = Document_Status.OnSaleOnOpen;

// 파트너허브 리스트 - 상태
export const PARTNER_STATUS = {
  DENIED: Partner_Document_Status.Denied,
  DRAFT: Partner_Document_Status.Draft,
  DRAFT_ON_OPEN: Partner_Document_Status.DraftOnOpen,
  IN_REVIEW: Partner_Document_Status.InReview,
  IN_REVIEW_ON_OPEN: Partner_Document_Status.InReviewOnOpen,
  ON_SALE: Partner_Document_Status.OnSale,
  REJECTED: Partner_Document_Status.Rejected,
  REJECTED_ON_OPEN: Partner_Document_Status.RejectedOnOpen,
  SUSPENDED: Partner_Document_Status.Suspended,
};

export const GET_GOODS_VIEW_PREVIEW_PAGE_URL = (godoGoodsNo, lang) => {
  return `https://www.acon3d.com/goods/goods_view.php?goodsNo=${godoGoodsNo}&review=true&lang=${lang}`;
};

export enum TranslateModalType {
  CONTENT = 'content',
  FILE = 'file',
}

export enum LANG_CODE {
  KO = Language_Code.Ko,
  EN = Language_Code.En,
  ZH = Language_Code.Zh,
  JA = Language_Code.Ja,
}
export const SKETCHUP_EXTENSION_CODE = 77;
export const APPLICATION_LIST = {
  exe: ['Snaptoon'],
  psd: ['Photoshop'],
  ai: ['Illustrator'],
  skp: ['Ablur2.0', 'SketchUp'],
  blend: ['Blender'],
  abr: ['Photoshop'],
  clip: ['CLIP STUDIO PAINT'],
  cmc: ['CLIP STUDIO PAINT'],
  cs3c: ['CLIP STUDIO PAINT', 'CLIP STUDIO MODELER'],
  cs3o: ['CLIP STUDIO PAINT', 'CLIP STUDIO MODELER'],
  csmc: ['CLIP STUDIO MODELER'],
  csmo: ['CLIP STUDIO MODELER'],
  unitypackage: ['Unity'],
  afbrushes: ['Affinity Designer', 'Affinity Photo'],
  brush: ['Procreate'],
  brushset: ['Procreate'],
  asl: ['Photoshop'],
  atn: ['Photoshop'],
  abc: ['Unity', 'Unreal Engine'],
  '3dm': ['Rhino'],
  dae: ['3ds Max', 'Blender', 'Maya', 'SketchUp'],
  fbx: ['3ds Max', 'Blender', 'CLIP STUDIO PAINT', 'CLIP STUDIO MODELER', 'Maya', 'Photoshop', 'Unity', 'Unreal Engine'],
  glb: ['Blender'],
  grd: ['Photoshop'],
  ksp: ['KeyShot'],
  laf: ['CLIP STUDIO PAINT'],
  max: ['3ds Max'],
  ma: ['Maya'],
  md: ['Maya'],
  obj: ['3ds Max', 'Blender', 'CLIP STUDIO PAINT', 'CLIP STUDIO MODELER', 'CINEMA 4D', 'Maya', 'Unity', 'Unreal Engine'],
  mtl: ['3ds Max', 'Blender', 'CLIP STUDIO PAINT', 'CLIP STUDIO MODELER', 'CINEMA 4D', 'Maya', 'Unity', 'Unreal Engine'],
  pak: ['Unreal Engine'],
  pat: ['Photoshop'],
  rbz: ['SketchUp'],
  sbsar: ['3ds Max', 'Blender', 'CINEMA 4D', 'Illustrator', 'Photoshop', 'Rhino', 'Unity', 'Unreal Engine'],
  skm: ['SketchUp'],
  step: ['SketchUp'],
  stl: ['3ds Max', 'Blender', 'CINEMA 4D', 'Maya', 'Rhino', 'SketchUp'],
  style: ['SketchUp'],
  sut: ['CLIP STUDIO PAINT'],
  swatches: ['Photoshop', 'Procreate'],
  uasset: ['Unreal Engine'],
  umap: ['Unreal Engine'],
  uproject: ['Unreal Engine'],
  vrm: ['Unity', 'Unreal Engine', 'VRoid Studio'],
  vroid: ['Unity', 'Unreal Engine', 'VRoid Studio'],
  vroidcustomitem: ['VRoid Studio'],
  zpr: ['ZBrush'],
};

export const EXCEPTION_EXTENSIONS = ['.txt'];
