import { paymentInstance } from 'api/payment/instances';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export enum ENTERPRISE_QUERY_KEYS {
  ENTERPRISE_LIST = 'ENTERPRISE_LIST',
  ENTERPRISE_DETAIL = 'ENTERPRISE_DETAIL',
  ENTERPRISE_EXCEL_DOWNLOAD = 'ENTERPRISE_EXCEL_DOWNLOAD',
}

export const endDateObj = new Date();
export const startDateObj = dayjs(endDateObj).subtract(1, 'month').add(1, 'day');

export const viewCountList = [
  { id: 1, label: '20' },
  { id: 2, label: '50' },
  { id: 3, label: '100' },
  { id: 4, label: '200' },
];

export const INITIAL_SEARCH_PERIOD_LIST = (t: TFunction) => [
  { id: 1, label: t('paymentDate'), value: 'payment' },
  { id: 2, label: t('orderDate'), value: 'order' },
  { id: 3, label: t('settle.fix_date'), value: 'settle' },
];

export const getSettleEnterPriseList = async (payload) => {
  try {
    const { data } = await paymentInstance().post('/product/hub/enterprise/settleList', payload);
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const getSettleEnterPriseDetail = async (payload) => {
  try {
    const { data } = await paymentInstance().get('/product/hub/enterprise/settle', { params: payload });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getSettleEnterPriseExcelDownload = async (payload) => {
  try {
    const { data } = await paymentInstance().post('/product/hub/enterprise/settleList/download', payload, { responseType: 'blob' });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getBuyerChannel = (mallCode) => {
  switch (mallCode) {
    case 'ko':
      return '한국어';
    case 'en':
      return 'English';
    case 'zh':
      return '中文';
    case 'ja':
      return '日本語';
  }
};
