export default (t, loading, initAddList, openConfirm, saveData, ajax) => {
    // debugger

    var onClick = function (type) {
        return () => {
            if (loading) {
                return
            } else {
                console.log(saveData)
                switch (type) {
                    case 'save':
                        openConfirm({
                            title: t('authManager.save'),
                            body: t('authManager.saveMessage'),
                            confirmCallback: () => {
                                // debugger
                                ajax.updateTemplateAuth(saveData)
                            }
                        })
                        break
                    case 'cancle':
                        openConfirm({
                            title: t('authManager.cancle'),
                            body: t('authManager.cancleMessage'),
                            confirmCallback: initAddList
                        })
                        break
                    default:
                        break
                }
            }
        }
    }

    return { onClick }
    //db.users.update({id:'acon3d'},{$set:{"_auth.auth":["readAll","writeAll","deleteAll"]}})
}