import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    visibility: hidden;
    justify-content: space-between;
`;
export const Wrap = styled.div`
    border-radius: 5px;
    width: 100%;
    height: 3px;
    margin-top: 9px;
    margin-bottom: 5px;
    background-color: RGB(229, 229, 234);
`;
export const Inner = styled.div`
    border-radius: 5px;
    ${props => props.percent ? `width: ${props.percent}%;` : ''}
    background-color: #2275d7;
    box-shadow: 0 0 10px rgb(34 117 215 / 70%);
    height: 100%;
    width: 0;
    transition: width .4s ease;
`;

export const Button = styled.button`
    padding: 0;
    margin: 0;
    background: none;
    margin-right: 5px;
    margin-left: 5px;
    border: 0;
`;