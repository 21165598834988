import styled from 'styled-components';

// 모바일로 인식하는 디바이스 너비 제약
const mobileQuery = 'Only screen and (max-width : 480px)';

export const Wrap = styled.div`
  ${(props) =>
    props.isAdmin &&
    `
    @media ${mobileQuery} {
      padding-top: 48px; 
    }
  `}
`;
