import jquery from 'jquery';
import hljs from 'highlight.js';

window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;

/** @see https://github.com/carpenstreet/acon-admin/blob/main/src/utils/highlight.ts */
hljs.configure({
  languages: ['javascript', 'jsx', 'sh', 'bash', 'html', 'scss', 'css', 'json'],
});
window.hljs = hljs;

const ACON = (function () {
  String.format = () => {
    var theString = arguments[0];

    for (var i = 1; i < arguments.length; i++) {
      var regEx = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
      theString = theString.replace(regEx, arguments[i]);
    }

    return theString;
  };

  return {
    emojiConvert: (data) => {
      data = data.replace(/&#x/g, '0x');
      const matchArr = data.match(/(0x[0-9|a-f|A-F]+)/);

      matchArr &&
        matchArr.forEach((data2) => {
          data = data.replace(data2, String.fromCodePoint(data2));
        });
      return data;
    },
    // 이메일 사용가능 여부 가져오기 메소드
    GetEmailEnabled: (email) => {
      /// <param name="email" type="String">이메일 </param>

      var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return regex.test(email);
    },
    EmailRegex: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    PasswordRegex: /^(?=.*[\w ])(?=.*[\d])(?=.*[^ \w\d\t\r\n\v\f]).{8,}$/i,
    // 패스워드 사용가능 여부 가져오기 메소드
    GetPasswordEnabled: (password) => {
      /// <param name="email" type="String">패스워드</param>

      // 최소 8자, 하나 이상의 대문자, 하나의 소문자, 하나의 숫자 및 하나의 특수 문자
      var regex = /^(?=.*[\w ])(?=.*[\d])(?=.*[^ \w\d\t\r\n\v\f]).{8,}$/i;

      return regex.test(password);
    },
    // 휴대폰번호 사용가능 여부 가져오기 메소드
    GetPhoneEnabled: (phone) => {
      /// <param name="phone" type="String">휴대폰번호</param>

      var regex = /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4}$/i;

      return regex.test(phone);
    },
    // 문자열에서 숫자만 가져오기 메소드
    OnlyNumber: (str) => {
      /// <summary>지정한 문자열에서 숫자만 반환합니다.</summary>
      /// <param name="str" type="String">대상 문자열</param>
      /// <returns type="String">숫자만 반환됩니다.</returns>

      str = str.toString();
      // 숫자만 반환
      return Number(str.replace(/[^0-9.]/g, ''));
    },
    // 문자열 여부 가져오기 메소드
    GetIsStr: (str) => {
      /// <summary>지정된 문자열에 문자 소속여부를 반환합니다.</summary>
      /// <param name="str" type="String">대상 문자열</param>
      /// <returns type="Boolean">지정된 문자열 문자 소속여부</returns>

      return /[^0-9]/.test(str);
    },
    // 숫자 여부 가져오기 메소드
    GetIsNumber: (str) => {
      /// <summary>지정된 문자열에 숫자의 소속여부를 반환합니다.</summary>
      /// <param name="str" type="String">대상 문자열</param>
      /// <returns type="Boolean">지정된 문자열 숫자 소속여부</returns>

      return /[0-9]/.test(str);
    },
    // 지정한 숫자를 문자열로 변환 및 콤마(,)를 추가해서 가져오기 메소드
    AddCommas: (number) => {
      /// <summary>지정한 숫자를 문자열로 변환 및 콤마(,)를 추가합니다.</summary>
      /// <param name="number" type="String">문자열로 변환 및 콤마를 추가 할 대상 숫자</param>
      /// <returns type="String">문자열로 변환 및 콤마가 추가 된 값 입니다.</returns>

      var parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    // 유효성검사 에러 클래스
    ValidationError: (function (parentClass) {
      function thisClass(message) {
        this.name = 'ValidationFailed';
        this.message = message || '작성되기에 적합하지 않은 데이터입니다.';
      }
      thisClass.prototype = new parentClass();
      thisClass.prototype.constructor = thisClass;

      return thisClass;
    })(Error),
  };
})();
export default ACON;
