import { graphqlUrl } from 'api'
import axios from 'axios'

export default (t, alert) => {
    function getAlert(result, message = [], callback = []) {
        if (callback && callback[result.resultCode]) {
            callback = callback[result.resultCode]
            callback = () => { callback(result.data) }
        }
        if (result && message && message[result.resultCode]) {
            message = message[result.resultCode]
            if (result.resultCode !== 1) {
                console.log(result)
            }
        } else {
            message = t('authManager.unkownError')
        }
        alert(message, callback)
    }
    async function getAuthList() {
        var result = []
        try {

            var query = `query ($input: AuthQuery!) {
                apiAuthList(authQuery: $input) {
                  message
                  resultCode
                  total
                  data {
                    _id
                    describe
                    auth
                    defaultAuth
                    defaultValue
                  }
                  message
                  error
                }
                templateAuthList{
                    data{
                      _id
                      templateName
                      _auth
                      welcomeUrl
                      description
                      date
                      updateDate
                      defaultTemplate
                    }
                    total
                    message
                    resultCode
                    error
                  }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: { input: {} }
            })
            result = { ...data.data.data }

            if (result.apiAuthList.resultCode === 1
                && result.templateAuthList.resultCode === 1) {
                result = {
                    apiAuthList: result.apiAuthList.data,
                    templateAuthList: result.templateAuthList.data
                }
            } else {
                getAlert(result.apiAuthList, [t('authManager.unkownError'), null, t('authManager.apiAuthList_2')])
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function getUserList() {
        var result = []
        try {

            var query = `query ($input: AuthQuery!) {
                apiAuthList(authQuery: $input) {
                  data {
                    _id
                    auth
                  }
                  message
                  resultCode
                  error
                }
                templateAuthList{
                    data {
                      _id
                      templateName
                      _auth
                      welcomeUrl
                      description
                    }
                    message
                    resultCode
                    error
                }
                users {
                    _id
                    id
                    templateId
                    welcomeUrl
                }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: { input: {} }
            })
            result = { ...data.data.data }

            if (result.apiAuthList.resultCode === 1
                && result.templateAuthList.resultCode === 1) {
                result = {
                    apiAuthList: result.apiAuthList.data,
                    templateAuthList: result.templateAuthList.data,
                    userList: result.users
                }
            } else {
                getAlert(result.apiAuthList, [t('authManager.unkownError'), null, t('authManager.apiAuthList_2')])
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function copyTemplate(_id) {
        var result
        try {
            var query = `mutation ($input: InputTemplateId!) {
                copyTemplate(input: $input) {
                    data
                    message
                    resultCode
                    error
                }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: { input: { templateId: _id } }
            })
            result = { ...data.data.data }
            if (result.copyTemplate.resultCode === 1) {
                result = result.copyTemplate
            } else {
                console.log(result)
                result = null
                alert(t('authManager.unkownError'))
            }

        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function deleteTemplate(_id) {
        var result
        try {
            var query = `mutation ($input: InputTemplateId!) {
                deleteTemplate(input: $input) {
                    message
                    resultCode
                    error
                }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: { input: { templateId: _id } }
            })
            result = { ...data.data.data }
            if (result.deleteTemplate.resultCode === 1) {
                result = result.deleteTemplate
            } else {
                console.log(result)
                result = null
                alert(t('authManager.unkownError'))
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function updateTemplate(data) {
        var result
        try {
            var query = `mutation ($input: InputTemplate!) {
                updateTemplate(input: $input) {
                    data
                    message
                    resultCode
                    error
                }
              }      
              `
            data = await axios.post(graphqlUrl, {
                query: query,
                variables: {
                    input: {
                        _id: data._id,
                        templateName: data.templateName,
                        defaultTemplate: data.defaultTemplate,
                        description: data.description,
                        welcomeUrl: data.welcomeUrl,
                        _auth: data._auth
                    }
                }
            })
            result = { ...data.data.data }
            if (result.updateTemplate.resultCode === 1) {
                result = result.updateTemplate
            } else {
                console.log(result)
                result = null
                alert(t('authManager.unkownError'))
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    async function applyTemplate(_id) {
        var result
        try {
            var query = `mutation ($input: InputTemplateId!) {
                applyTemplate(input: $input) {
                    data
                    message
                    resultCode
                    error
                }
              }      
              `
            var data = await axios.post(graphqlUrl, {
                query: query,
                variables: {
                    input: {
                        templateId: _id
                    }
                }
            })
            result = { ...data.data.data }
            if (result.applyTemplate && result.applyTemplate.resultCode === 1) {
                console.log(result)
                result = result.applyTemplate
            } else {
                console.log(result)
                result = null
                alert(t('authManager.unkownError'))
            }
        } catch (err) {
            console.log(err)
        }
        return result
    }
    return {
        applyTemplate,
        getAuthList,
        copyTemplate,
        deleteTemplate,
        updateTemplate,
        getUserList
    }
}