import styled from 'styled-components';

/* 공통 스타일 - 시작 */
export const Page = styled.div`
  margin-top: 50px;
  td {
    border-left: 0;
    border-right: 0;
  }
`;
export const Wrap = styled.div`
  margin-top: 23px;
  padding: 0 0 0 20px;
`;
export const Half = styled.div`
  width: 50%;
  padding-right: 20px;
  float: left;
`;

export const Title = styled.div`
  width: 100%;

  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  padding: 0 20px;
`;

export const Tab = styled.div`
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
  color: #575757;
  width: 125px;
  text-align: center;
  cursor: pointer;
  ${(props) => `border-bottom: 10px ${props.isActive ? '#EB357B' : '#C4C4C4'} solid;`}
`;

export const CommonButton = styled.button`
  height: 36px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  ${(props) => `background-color: ${props.bgColor ? `${props.bgColor}` : '#333333'};`}
  ${(props) => `color: ${props.color ? `${props.color}` : 'white'};`}
    ${(props) => `padding: ${props.padding ? `${props.padding}` : '0px 40px'};`}
    ${(props) => `margin: ${props.margin ? `${props.margin}` : '0px'};`}
`;

export const CommonSelectBox = styled.select`
  height: 36px;
  border: 1px solid #cdcdcd;
  ${(props) => `padding: ${props.padding ? `${props.padding}` : '0px 30px'};`}
  ${(props) => `margin: ${props.margin ? `${props.margin}` : '0px'};`}
    ${(props) => `width: ${props.width ? `${props.width}` : 'auto'};`}
    ${(props) => `border-radius: ${props.radius ? `${props.radius}` : '0px'};`}
    ${(props) => `text-align-last: ${props.textAlign ? `${props.textAlign}` : 'left'};`}
`;

export const FormContainer = styled.div`
  margin-top: 60px;
`;

export const Row = styled.div`
  margin-bottom: 50px;
`;

export const RowTitle = styled.div`
  font-weight: bold;
  line-height: 100%;
  color: #333333;
  ${(props) => `font-size: ${props.size ? `${props.size}` : '14px'};`}
  ${(props) => `margin: ${props.margin ? `${props.margin}` : '0px'};`}
`;

export const SubTitle = styled.div`
  font-size: 12px;
  color: #f300ba;
  line-height: 100%;
  margin-top: 16px;
`;

export const CommonInputTextBox = styled.input.attrs({
  type: 'text',
})`
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  height: 38px;
  width: 100%;
  ${(props) => `margin: ${props.margin ? `${props.margin}` : '10px 0px 0px 0px'};`}
  ${(props) => `padding: ${props.padding ? `${props.padding}` : '0px 0px 0px 10px'};`}
`;

export const CommonInputRadioBox = styled.input.attrs({
  type: 'radio',
})`
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  margin-right: 5px;
  vertical-align: middle;
`;

export const CommonInputCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  margin-left: 20px;
  margin-right: 5px;
`;

export const Flex = styled.div`
  display: flex;
  ${(props) => `margin: ${props.margin ? `${props.margin}` : '15px 0px 0px 0px'};`}
  ${(props) => `justify-content: ${props.justify ? `${props.justify}` : 'normal'};`}
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Table = styled.table`
  margin-top: 10px;
  width: 100%;
  table-layout: fixed;
`;

export const Thead = styled.thead`
  border-bottom: 2px #333 solid;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;

  &:first-child {
    text-align: center;
  }
`;

export const Td = styled.td`
  padding: 20px 5px 20px 0;
  border-bottom: 1px #b9b9b9 solid;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    text-align: center;
  }
`;
/* 공통 스타일 - 끝 */

/* 공통 Input, Select 에디터 스타일 - 시작 */
export const Value = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
`;

export const Item = styled.div`
  & > ${Title} {
    font-size: 14px;
    padding-bottom: 16px;
  }
  width: 100%;
  margin-bottom: 36px;
`;

export const Input = styled.input.attrs((props) => ({
  type: 'text',
}))`
  width: 100%;
  padding: 9.5px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;

  & ~ input,
  & ~ button {
    margin-left: 12px;
  }
`;

export const EditorContainer = styled.div`
  background-color: white;
`;

export const SelectBox = styled.div`
  width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #808080;
  background-color: hsl(0, 0%, 100%);
  border-color: ${(props) => (props.active ? '#2684FF' : 'hsl(0,0%,80%)')};
  ${(props) => (props.active ? 'box-shadow: 0 0 0 1px #2684ff;' : '')}
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  padding: 2px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
`;

export const SearchBox = styled.div``;

export const ListBox = styled.div`
  width: 100%;
  margin-top: 8px;
  height: 192px;
  overflow-y: auto;
`;

export const DropdownBox = styled.div`
  padding: 12px 16px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  width: 428px;
  position: absolute;
  margin-top: 10px;
  z-index: 1;
  background-color: white;
`;

export const Text = styled.div``;

export const CategoryItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 2px 8px;
`;

export const Trigger = styled.div`
  width: 15px;
  height: 15px;
  background-color: white;
  margin-left: 3px;
  line-height: 100%;
  margin-top: 1px;
  i {
    font-size: 10px;
    font-weight: bold;
  }
`;

export const InputContainer = styled.div`
  margin-top: 10px;
`;

export const NaviWrapper = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`;
/* 공통 Input, Select 에디터 스타일 - 끝 */

/* 포인트 컴포넌트 - 시작 */
export const PointHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PointWriteContainer = styled.div`
  margin-top: 55px;
  padding: 0 250px;
`;

export const DefaultInitText = styled.label`
  font-weight: 500;
`;

export const DefaultReviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const DefaultReviewItem = styled.div`
  width: 23%;
  text-align: center;
`;

export const DefaultReviewText = styled.div`
  margin-bottom: ${(props) => (props.isFirst ? '21' : '0')}px;
`;

export const ExchangeRateText = styled.div`
  color: #7c7c7c;
  margin-top: 3px;
`;

export const ExchangeRateInput = styled(CommonInputTextBox).attrs({
  disabled: true,
})`
  color: #7c7c7c;
  text-align-last: center;
  padding: 0;
`;

export const PointInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
/* 포인트 컴포넌트 - 끝 */

/* 쿠폰 컴포넌트 - 시작 */
export const CouponHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
/* 쿠폰 컴포넌트 - 끝 */
