

export default (loading, setData) => {
    function onChange(e) {
        if (loading) {
            return
        } else {
            var el = e.currentTarget
            var name = el.name
            var data = el.value.trim()
            setData(prev => {
                prev[name] = data
                return {
                    ...prev
                }
            })
        }
    }
    function onCheck(e) {
        if (loading) {
            return
        } else {
            var el = e.currentTarget
            var name = el.name
            var data = el.checked
            setData(prev => {
                prev[name] = data
                return {
                    ...prev
                }
            })
        }
    }

    var result = { onCheck, onChange }
    return result
}