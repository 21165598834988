import Typography from '@mui/material/Typography';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const BrandPickConfirmModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 400px !important;
  }
`;
export const BrandPickConfirmModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const BrandPickConfirmModalSaveWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 9px;
  gap: 12px;
`;
