import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../app';
import { Page, Wrap, TabContainer, Tab } from './style';
import BrandBurden from './brandBurden';
import Point from './point';
import Coupon from './coupon';
import BenefitRestric from './benefitRestric';

export default (props) => {
  const { match } = props;
  // 배경색 변경하기 메소드
  const { setBackgroundColor } = useContext(AppContext);
  // 현재 페이지 타입을 가져옵니다.
  const burdenDisplayLocation = match.params.displayLocation || 'brandBurden';

  // 색상변경
  useEffect(() => {
    // 배경 흰색으로 설정
    setBackgroundColor('white');

    return () => {
      setBackgroundColor('');
    };
  }, [setBackgroundColor]);

  // 페이지 탭 이동
  const onClickTabSelector = (e) => {
    const selectorLocation = e.target.dataset.name;

    props.history.push(`/manager/benefit/${selectorLocation}`);
  };

  // 페이지 탭
  const BurdenTab = ({ children, pageType }) => {
    const isActive = burdenDisplayLocation === pageType;
    return (
      <Tab isActive={isActive} onClick={onClickTabSelector} data-name={pageType}>
        {children}
      </Tab>
    );
  };

  return (
    <>
      <Page>
        <TabContainer>
          <BurdenTab pageType={'brandBurden'}>브랜드 부담</BurdenTab>
          <BurdenTab pageType={'point'}>포인트</BurdenTab>
          <BurdenTab pageType={'coupon'}>쿠폰</BurdenTab>
          <BurdenTab pageType={'benefitRestric'}>혜택 제한</BurdenTab>
        </TabContainer>
        <Wrap>
          {burdenDisplayLocation === 'brandBurden' && <BrandBurden {...props} />}
          {burdenDisplayLocation === 'point' && <Point {...props} />}
          {burdenDisplayLocation === 'coupon' && <Coupon {...props} />}
          {burdenDisplayLocation === 'benefitRestric' && <BenefitRestric {...props} />}
        </Wrap>
      </Page>
    </>
  );
};
