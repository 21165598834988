import React, { useEffect, useState } from 'react';
import { TwinkleSaleContainer } from './index.styles';
import { Snackbar, Stack, Typography } from '@mui/material';
import TwinkleTicket from './components/TwinkleTicket/TwinkleTicket';
import TwinkleList from './components/TwinkleList/TwinkleList';
import palette from 'theme/palette';
import { useTranslation } from 'react-i18next';
import { ESearchPromotionKey, useFetchPromotionsQuery } from 'generated/graphql';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';
import dayjs from 'dayjs';

const TwinkleSale = (): React.ReactElement => {
  const { t } = useTranslation();
  const { toastStatus, setToastStatus } = useTwinkleSaleStore((state) => ({
    toastStatus: state.toastStatus,
    setToastStatus: state.setToastStatus,
  }));
  const [isToastOpen, setIsToastOpen] = useState(true);

  const { data: feachPromotionsData, loading: fetchPromotionsLoading } = useFetchPromotionsQuery({
    variables: {
      search: {
        conceptId: 2,
        since: null,
        until: null,
        key: ESearchPromotionKey.PromotionTitle,
        status: null,
      },
      page: 1,
      limit: 100,
    },
    fetchPolicy: 'no-cache',
  });

  const promotions =
    filter(sortBy(feachPromotionsData?.fetchPromotions?.data, ['since']), (promotion) => {
      // 프로모션의 status가 진행중이 아니면 리스트 노출 안함.
      if (promotion.status !== 'DRAFT') {
        return false;
      }
      // 프로모션의 종료 날짜가 현재시간보다 이전이면 리스트 노출 안함.
      if (dayjs(promotion.until).isBefore(dayjs())) {
        return false;
      }
      return true;
    }) || [];

  useEffect(() => {
    return () => {
      setToastStatus(null);
    };
  }, [setToastStatus]);

  if (fetchPromotionsLoading) return <></>;

  return (
    <TwinkleSaleContainer fullWidth>
      <Stack direction="column" spacing={2}>
        {promotions.map((promotion) => (
          <TwinkleTicket key={promotion.id} promotion={promotion} />
        ))}
      </Stack>
      <Typography variant="h5" color={palette.light.text.primary} pt={8} pb={2}>
        {t('twinkleSalePage.applicationDetails')}
      </Typography>
      <TwinkleList />
      {toastStatus && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isToastOpen}
          onClose={() => setIsToastOpen(false)}
          message={t(`twinkleSalePage.toast.${toastStatus}`)}
          sx={{ '& > .MuiSnackbarContent-root': { minWidth: 'auto' } }}
          autoHideDuration={3000}
        />
      )}
    </TwinkleSaleContainer>
  );
};
export default TwinkleSale;
