import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';

type Props = {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  return (
    <Stack pt={6} pb={12.5} alignItems={'center'}>
      {children}
    </Stack>
  );
}