import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const Link = styled(NavLink)`
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;

  &:hover {
    color: white;
  }
`;
export default function AdminGnb() {
  return (
    <Box
      component={'nav'}
      sx={{
        width: '100%',
        height: '50px',
        backgroundColor: '#222',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        position: 'fixed',
        top: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          width: '1092px',
          margin: '0 auto',
          paddingLeft: '16px',
          paddingRight: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        {/* 카테고리 다국어 메뉴 설정 */}
        <Link to="/manager/categoryLang" key="/manager/categoryLang">
          카테고리
        </Link>
        {/* 혜택관리 메뉴 설정 */}
        <Link to="/manager/benefit/brandBurden" key="/manager/benefit/brandBurden">
          혜택
        </Link>
        {/* 배너관리 메뉴 설정 */}
        <Link to="/banner/detailPage" key="/banner/detailPage">
          배너
        </Link>
        {/* 에이콘FAQ 메뉴 설정 */}
        <Link to="/aconfaq/faq-acon" key="/aconfaq">
          FAQ
        </Link>
        {/* 공지관리 메뉴 설정 */}
        <Link to="/board/notice" key="/board/notice">
          게시판
        </Link>
        {/* 다운로드 관리 메뉴 설정 */}
        <Link to="/download" key="/download">
          다운로드
        </Link>
      </Box>
    </Box>
  );
}
