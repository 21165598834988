import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useApolloClient } from "@apollo/client";
import { authOrigin } from "api";

export default function Logout(props) {
  const client = useApolloClient();

  useEffect(() => {
    (async () => {
      client.clearStore();
      await axios.post(authOrigin + "/signout");
      window.location.href = "/";
    })();
  }, []);
  return <div></div>;
}
