import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { REORDER_EVENT_BANNER } from '../api/mutations';
import queryString from 'query-string';
import './BannerInfo.scss';

const NoticeInfo = (props) => {
  const { t, match, items, type } = props;
  // bannerDisplayLocation 가져오기
  const bannerDisplayLocation = match.params.displayLocation || 'detailPage';

  const [isDrag, setIsDrag] = useState(false);
  const [draggedRow, setDraggedRow] = useState(null);
  const [dragTargetRow, setDragTargetRow] = useState(null);
  const [reorderMutation] = useMutation(REORDER_EVENT_BANNER);

  let query = {};
  if (props.history.location.search) {
    query = queryString.parse(props.history.location.search);
  }
  const langKo = !query?.lang || query?.lang === 'ko';

  // 드래그 앤 드랍 페이지 여부
  const isDraggable = bannerDisplayLocation === 'eventPage';

  // db에서 가져온 게시판 정보 object를 DOM으로 렌더해주는 함수
  const renderBannerItems = (e, k) => {
    const titleHref = `/banner/${bannerDisplayLocation}/write/` + e.id;
    // 배너 종료 여부
    const isEndBanner = new Date(e.endDate) < new Date();

    // 배너 노출 기간을 표시할 때 날짜를 formatting해주는 옵션입니다.
    const localeDateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

    // 배너 노출 종료일이 설정되지 않은 경우 2099년 12월 31일이 입력되어 있습니다.
    // 이에 해당하는 경우에는 날짜를 숫자로 표시하지 않고 '종료하지 않음'이라고 표시합니다.
    let endDateText;
    if (new Date(e.endDate) > 4000000000000) {
      endDateText = t('Boards.popupNeverEnd');
    } else {
      endDateText = new Date(e.endDate).toLocaleDateString('ko-KR', localeDateOptions);
    }

    // 배너 노출 종료일이 현재보다 나중일 경우 날짜를 표시하고 이미 종료되었을 경우 '종료'라고 표시합니다.
    const displayPeriod = !isEndBanner ? (
      <div className="period">
        <div className="date">{new Date(e.startDate).toLocaleDateString('ko-KR', localeDateOptions)}</div>
        <div className="tilde">~</div>
        <div className="date">{endDateText}</div>
      </div>
    ) : (
      <div className="period">
        <div>{t('Boards.popupFinished')}</div>
      </div>
    );

    const title = (() => {
      const bannerImage = e.image?.fullName || e.image?.azureStoragePath;
      if (bannerDisplayLocation === 'detailPage' && e.type.displayType === 'image') {
        return (
          <div className="bannerImagePreivew">
            <img src={bannerImage} height="120px"></img>
          </div>
        );
      } else if (bannerDisplayLocation === 'eventPage') {
        return (
          <div className="bannerImagePreivew">
            <img style={{ objectFit: 'scale-down' }} src={bannerImage} width="440px" height="160px"></img>
          </div>
        );
      } else {
        return e.text;
      }
    })();

    const onDrageStart = (element) => {
      setDraggedRow({ ...e, element });
    };

    const onDragOver = (element) => {
      element.preventDefault();
    };

    const onDragEnter = (element) => {
      element.preventDefault();
      setDragTargetRow({ ...e, element });
    };

    const onDragEnd = async () => {
      setIsDrag(false);
      if (!(draggedRow?.order - dragTargetRow?.order)) return;

      await reorderMutation({
        variables: {
          id: draggedRow.id,
          oldOrder: draggedRow.order,
          newOrder: dragTargetRow.order,
        },
      });

      props.refetch();
      setDraggedRow(null);
      setDragTargetRow(null);
    };

    let dragAttribute = {};
    if (isDraggable && !isEndBanner) {
      dragAttribute = {
        onDragStart: onDrageStart,
        onDragOver: onDragOver,
        onDragEnter: onDragEnter,
        onDragEnd: onDragEnd,
        draggable: 'true',
      };
    }

    let className = '';
    if (draggedRow?.id !== e.id && dragTargetRow?.id === e.id && new Date(draggedRow?.endDate) > new Date()) {
      if (draggedRow?.order > dragTargetRow?.order) {
        className = 'highlight-bottom';
      } else {
        className = 'highlight-top';
      }
    }

    const TableHead = () => {
      let returnThead = [];
      let returnClass = [];

      if (bannerDisplayLocation === 'benefitPage') {
        returnThead = ['쿠폰', '진행 기간'];
      }
      if (bannerDisplayLocation === 'eventPage') {
        returnThead = ['이벤트', '진행 기간'];
      }
      if (bannerDisplayLocation === 'productBadgePage') {
        returnThead = ['번호', '상태', '진행 기간', '배지 내용', '노출몰'];
        returnClass.push('badgeTableRow');
      }

      if (returnThead.length === 0) {
        return <></>;
      } else {
        return (
          <thead>
            <tr className={returnClass.join(' ')}>
              {returnThead.map((x) => (
                <th>{x}</th>
              ))}
            </tr>
          </thead>
        );
      }
    };

    /* 전체 페이지 및 상품 페이지 리스트 표시 */
    const DefaultBannerTable = () => {
      const languageCode = e?.language?.code || e?.languageCode;
      return (
        <>
          <colgroup>
            <col width="15%" />
            <col width="10%" />
            <col width="50%" />
            <col width="25%" />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div className="type-container">
                  <div className={`type ${e.type.name}`}>{e.type.name}</div>
                </div>
              </td>
              <td>
                <div className="language-container">
                  <div className={`type ${languageCode}`}>{languageCode.toUpperCase()}</div>
                </div>
              </td>
              <td>
                <div className="title">
                  <div className="title__text__container">
                    <NavLink className="title__text" to={titleHref}>
                      {title}
                    </NavLink>
                  </div>
                </div>
              </td>
              <td className="period">{displayPeriod}</td>
            </tr>
          </tbody>
        </>
      );
    };

    /* 상품 배지 리스트 표시 */

    // 위의 로직에 따른 결과를 조립하여 최종적으로 게시물 item을 DOM으로 내보내기합니다.
    return (
      <table id={'dragItem_' + e.id} key={e.id} className={`${type} ${className} ${isDraggable && !isEndBanner && 'draggable'}`} {...dragAttribute}>
        {k === 0 && <TableHead />}
        {/* 상품 페이지 리스트 */ bannerDisplayLocation === 'detailPage' && <DefaultBannerTable />}
        {
          /* 이벤트 리스트 */
          bannerDisplayLocation === 'eventPage' && (
            <>
              <colgroup>
                <col width="80%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <NavLink className="title__text" to={titleHref}>
                      <div className={'fieldEventBanner'}>
                        <div className={'fieldEventBannerLeft'}>
                          <div className={`fieldEventbannerSmallTitle fieldEventBannerText`}>{parse(e.bannerTextFirst)}</div>
                          <div className={`fieldEventbannerBigTitle fieldEventBannerText`} style={{ letterSpacing: langKo ? '-0.01em' : '0' }}>
                            {parse(e.bannerTextSecond)}
                          </div>
                          <div className={`fieldEventbannerSmallTitle fieldEventBannerText`}>{parse(e.bannerTextThird)}</div>
                        </div>
                        <div className={'fieldEventBannerRight'}>
                          <div className={'benefitBannerPreview'}>{title}</div>
                        </div>
                      </div>
                    </NavLink>
                  </td>
                  <td>{displayPeriod}</td>
                </tr>
              </tbody>
            </>
          )
        }
      </table>
    );
  };

  const itemTags = items?.map(renderBannerItems);

  // 내보내기
  return <div className="banner-board">{itemTags}</div>;
};

export default NoticeInfo;
