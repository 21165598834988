import styled from '@emotion/styled';

export const color = {
  primary: '#4E4EFF',
  text: {
    primary: '#212B36',
    secondary: '#637381',
  },
  gray: {
    border: 'rgba(145, 158, 171, 0.32)',
  },
};

export const inputStyle = {
  '& .MuiInput-underline:after': {
    borderBottomColor: color.primary,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: color.primary,
    },
  },
};

export const TooltipWrapper = styled.div`
  position: relative;
  background: #4e4eff;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -7px; /* 화살표 너비 */
    transform: translateY(-50%);
    border-width: 8px 10px 8px 0;
    border-style: solid;
    border-color: transparent #4e4eff transparent transparent;
  }
`;
