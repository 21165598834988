export enum MODAL_REFUSE_STATUS {
  APPLICATION_TIME = 'APPLICATION_TIME',
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  CHANGE_QUANTITY = 'CHANGE_QUANTITY',
  RUNNING_OUT_QUANTITY = 'RUNNING_OUT_QUANTITY',
  PROMOTION_CONFLICT = 'PROMOTION_CONFLICT',
  ETC = 'ETC',
}
export interface ITwinkleModalProps {
  status: MODAL_REFUSE_STATUS | 'cancel' | null;
  isShow: boolean;
  message?: string;
  onClose: () => void;
  onSuccess: () => void;
}
