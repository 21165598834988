import { Quill } from 'react-quill-new';

// 허용 이미지 경로
const EDITOR_ALLOW_IMAGE_PATH_LIST = [
  'https://carpenstreet.blob.core.windows.net',
  'https://dgs8fvgymeofx.cloudfront.net',
  'https://dofaplktgw3ef.cloudfront.net',
  'https://d3aj4qxknj6i24.cloudfront.net',
];

Quill.debug('error');

export { Quill, EDITOR_ALLOW_IMAGE_PATH_LIST };
