import React from 'react';
import { Box } from '@mui/material';
import { InformationOutlineIcon, Tooltip, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { SalesRecordsSummaryProps } from './SalesRecordsSummary.types';
import { useTranslation } from 'react-i18next';
import { exchangeCurrency } from 'utils/timezone';
import parse from 'html-react-parser';

export default function SalesRecordsSummary({ loading, sellingStatus, data }: SalesRecordsSummaryProps) {
  const theme = useDesignSystemTheme();

  const { t, i18n } = useTranslation();

  const totalCount = loading ? 0 : data.getBrandOrderProductItems.pagination.totalCount;
  const refundedCount = loading ? 0 : data.getBrandOrderProductItems.aggregation.refundedCount;
  const totalAmount = loading ? 0 : data.getBrandOrderProductItems.aggregation.amount;

  function renderSummary() {
    switch (sellingStatus) {
      case 'all':
        return `${t('salesRecordsPage.summary.count.total', { count: totalCount })} (${t('salesRecordsPage.summary.count.sold', { count: totalCount - refundedCount })} | ${t(
          'salesRecordsPage.summary.count.cancelled',
          { count: refundedCount },
        )})`;
      case 'sold':
        return t('salesRecordsPage.summary.count.sold', { count: totalCount });
      case 'refunded':
        return t('salesRecordsPage.summary.count.cancelled', { count: totalCount });
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('largeTablet')]: {
          backgroundColor: theme.palette['color/white'],
          marginTop: '8px',
          padding: '16px',
        },
      }}
    >
      <Typography
        variant={'typography/body/small/bold'}
        sx={{
          [theme.breakpoints.down('largeTablet')]: {
            fontSize: '12px',
            lineHeight: '16px',
          },
        }}
      >
        {renderSummary()}
      </Typography>
      <Typography
        variant={'typography/body/small/bold'}
        color={'color/primary/600'}
        sx={{
          [theme.breakpoints.down('largeTablet')]: {
            fontSize: '12px',
            lineHeight: '16px',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Tooltip title={<>{parse(t('salesRecordsPage.summary.amountTooltip'))}</>} direction="bottom-left">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InformationOutlineIcon width={16} height={16} color="color/gray/300" />
            </Box>
          </Tooltip>
          {t('salesRecordsPage.summary.amount', { amount: exchangeCurrency(totalAmount, i18n.language).toLocaleString() })}
        </Box>
      </Typography>
    </Box>
  );
}
