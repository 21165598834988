import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import { INITIAL_LANG_ITEMS } from './LangaugeSelector.constants';
import { useHistory } from 'react-router-dom';

const LangaugeSelector = (): React.ReactElement => {
  const { i18n } = useTranslation();

  const history = useHistory();

  const handleOnChangeLanguage = (e) => {
    localStorage.setItem('i18nextLng', e.target.value);
    i18n.changeLanguage(e.target.value);
    history.go(0);
  };

  return (
    <Select
      value={
        INITIAL_LANG_ITEMS.find((x) => {
          const lang = localStorage.getItem('i18nextLng');
          return lang ? x.value === lang : x.value === i18n.language;
        })?.value || null
      }
      onChange={handleOnChangeLanguage}
      size="small"
      sx={{ width: '120px', borderRadius: 0.5 }}
    >
      {INITIAL_LANG_ITEMS.map((x) => (
        <MenuItem key={x.id} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LangaugeSelector;
