import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Event from "./event";
import {
  Flex,
  FlexChild,
  FieldTitle,
  FieldInput,
  TreeInfoView,
} from "../style";
import CategoryType from "./categoryType";
import LocaleSettings from "./LocaleSettings";
import State from "./state";
import { CategoryDataContext } from "../categoryData";
import { WhiteButton } from "components/button";
import Excel from "exceljs";
import { AppContext } from "app";
import { SET_CATEGORY_GOODS } from "api/mutations";
import { useMutation } from "@apollo/client";

// 노드 정보

function normalData(data) {
  if (!data.memo) {
    data.memo = "";
  }
  return data;
}

export default (props) => {
  const {
    t,
    // i18n
  } = useTranslation();
  const {
    categoryData,
    setCategoryData,
    setIsLoading,
    categoryTypeList,
    langList,
    categoryStateList,
  } = useContext(CategoryDataContext);
  const { onChangeType, onChangeState, onSave, onChangeName, onChangeMemo } =
    Event(t, setCategoryData, setIsLoading);
  const { showAlertMessage } = useContext(AppContext);
  // 카테고리 진열하기 액션
  const [setCategoryGoods] = useMutation(SET_CATEGORY_GOODS);

  // 속해 있는 모든 고도몰 카테고리 코드 가져오기 메소드
  const getAllParentGodoCategoryCd = (cateCd, cateCdArr = [String(cateCd)]) => {
    cateCd = String(cateCd);
    const parentCateCd = cateCd.substring(0, cateCd.length - 3);

    if (parentCateCd.length >= 3) {
      cateCdArr.push(parentCateCd);
      return getAllParentGodoCategoryCd(parentCateCd, cateCdArr);
    }
    return cateCdArr;
  };
  // 엑셀 데이터 처리 메소드
  async function excelToArray(file) {
    try {
      const cateCdArr = getAllParentGodoCategoryCd(categoryData.godoCateCd);
      const isEmptyOrNonNumber = (value) => {
        if (!value) return true;
        if (typeof value !== "number") return true;
        return false;
      };
      const rows = [];

      const workbook = new Excel.Workbook();
      const data = await workbook.xlsx.load(file);
      data.worksheets[0].eachRow((row, rowIndex) => {
        // 왜 row.values가 0부터 시작이 아닌가요? (https://github.com/exceljs/exceljs/issues/698)
        cateCdArr.forEach((cateCd) => {
          rows.push({
            cateCd: cateCd,
            goodsNo: row.values[1],
          });
        });
      });
      // 첫 row는 제목 열이므로 삭제
      rows.shift();
      // cateCd 별로 goodsNo 취합
      const goodsNoByCateCdArr = rows.reduce((acc, row) => {
        const cateCd = row.cateCd;
        const goodsNo = row.goodsNo;
        const cateCdRow = acc.find((item) => item.cateCd === cateCd);

        if (isEmptyOrNonNumber(goodsNo)) {
          return acc;
        }
        if (cateCdRow) {
          cateCdRow.goodsNo.push(goodsNo);
        } else {
          acc.push({
            cateCd: cateCd,
            goodsNo: [goodsNo],
          });
        }

        return acc;
      }, []);

      return goodsNoByCateCdArr;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }
  // 파일 이벤트 처리기 메소드
  async function onChangeFileInput(e) {
    try {
      const file = e.target.files[0];
      switch (file.type) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.ms-excel":
          break;
        default:
          showAlertMessage(t("alert"), t("isNotExcelFile"));
          return;
      }
      if (file.size > 1048576) {
        showAlertMessage(t("alert"), t("fileUpTo1MB"));
        return;
      }

      // cateCd 별 GoodsNo 배열
      const goodsNoByCateCdArr = await excelToArray(file);
      // 메인그룹에 상품 업로드
      const { errors } = await setCategoryGoods({
        variables: {
          data: goodsNoByCateCdArr,
        },
      });
      // 완료 메시지
      if (!errors) {
        showAlertMessage(t("alert"), t("setGoodsSuccess"));
      }
    } catch (errObj) {
      const errorMessage = errObj.message;
      showAlertMessage(t("alert"), errorMessage);
    }
  }
  // 상품 엑셀 업로드 이벤트 처리기 메소드
  const onClickExcelUpload = () => {
    try {
      // 파일 input 생성 후 열기
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = ".xls, .xlsx";
      fileInput.addEventListener("change", onChangeFileInput, false);

      fileInput.click();
    } catch (ex) {
      throw ex;
    }
  };

  var info = useMemo(() => {
    var result = <></>;
    if (categoryData.id) {
      let data = normalData(categoryData);
      let initOnSave = onSave(data);

      result = (
        <TreeInfoView>
          <Flex justify="space-between" align="center">
            <FieldTitle required>{t("mainCate")}</FieldTitle>
            <FlexChild flex="1" maxWidth="270px">
              <CategoryType
                {...{
                  type: data.type,
                  categoryTypeList,
                  onChangeType,
                }}
              />
            </FlexChild>
          </Flex>
          <Flex justify="space-between" align="center">
            <FieldTitle required>{t("displayScreen")}</FieldTitle>
            <FlexChild flex="1" maxWidth="270px">
              <State
                {...{ state: data.state, onChangeState, categoryStateList }}
              ></State>
            </FlexChild>
          </Flex>
          <LocaleSettings
            {...{
              i18n: data.i18n,
              langList,
              setCategoryData,
              initOnSave,
              id: categoryData.id,
              godoCateCd: categoryData.godoCateCd,
            }}
          />
          <Flex justify="space-between" align="center">
            <FieldTitle>{t("categoryManager.memo")}</FieldTitle>
            <FlexChild flex="1" maxWidth="270px">
              <FieldInput
                type="text"
                value={data.memo}
                onChange={onChangeMemo}
              />
            </FlexChild>
          </Flex>
          <Flex justify="space-between" align="center">
            <FieldTitle>{t("categoryManager.godoCateCd")}</FieldTitle>
            <FlexChild flex="1" maxWidth="270px">
              <FieldInput
                type="text"
                readOnly={true}
                value={categoryData.godoCateCd}
              />
            </FlexChild>
          </Flex>
          <Flex justify="space-between" align="center">
            <FieldTitle>{t("categoryManager.godoSno")}</FieldTitle>
            <FlexChild flex="1" maxWidth="270px">
              <FieldInput type="text" readOnly={true} value={data.godoSno} />
            </FlexChild>
          </Flex>
          {/* <NameList {...{ trans: data.trans, langList, onChangeName }}></NameList> */}
          <Flex justify="flex-end" align="center">
            <WhiteButton onClick={onClickExcelUpload}>
              <img src="/assets/icon/excel.svg" /> &nbsp;
              {t("batchUpload")}
            </WhiteButton>
            <WhiteButton margin="0px 0px 0px 10px" onClick={initOnSave}>
              {t("save")}
            </WhiteButton>
          </Flex>
        </TreeInfoView>
      );
    }
    return result;
  }, [
    categoryData,
    categoryTypeList,
    langList,
    categoryStateList,
    t,
    setCategoryData,
    onChangeMemo,
    onChangeName,
    onChangeState,
    onChangeType,
    onSave,
  ]);
  return <>{info}</>;
};
