import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BlackButton } from "../../components/button";
import RcSelect from "react-select";
import styled from "styled-components";
import { AppContext } from "../../app";
import LoadingImg from "../../img/loading.gif";
import "./index.scss";

// 확인 버튼
const ConfirmButton = styled(BlackButton)`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 100%;
  height: auto;
`;

// 가입인사 메세지 모달
export default ({
  setCoverLang,
  isShow,
  onHide,
  selectedIds,
  completeCount,
}) => {
  // 언어 배열
  const langArr = [
    { value: "ko", label: "KR" },
    { value: "en", label: "EN" },
    { value: "cn", label: "CN" },
    { value: "jp", label: "JP" },
  ];
  const { showConfirmMessage, showAlertMessage } = useContext(AppContext);
  const [isOverwrite, setIsOverwrite] = useState(true);
  const [standardLang, setStandardLang] = useState({ ...langArr[0] });
  const [targetLangs, setTargetLangs] = useState([]);
  const { t } = useTranslation();

  // 확인 버튼 클릭 이벤트 처리기 메소드
  const onClickConfirmButtonTag = () => {
    // 덮어쓸 언어가 지정되지 않았을 경우
    if (targetLangs.length === 0) {
      // 안내 메세지 표시
      showAlertMessage(t("canNotCompletedCoverLang"), t("enterCoverLang"));
      return;
    }

    // 덮어쓰기 여부 확인 컨펌 메세지
    showConfirmMessage(t("reallyCover"), t("loseData"), () => {
      setCoverLang(
        standardLang.value,
        targetLangs.map((x) => x.value),
        isOverwrite
      );
    });
  };

  const onChangeCheckbox = (e) => {
    setIsOverwrite(e.target.value === "on");
  };

  const onChangeSingle = (itemObj) => {
    setStandardLang(itemObj);
    setTargetLangs([...targetLangs.filter((x) => x.value !== itemObj.value)]);
  };

  const onChangeMulti = (itemObjArr) => {
    setTargetLangs(itemObjArr);
  };

  return (
    <Modal className="coverLangModal" show={isShow} onHide={onHide}>
      <Modal.Header></Modal.Header>
      {completeCount > -1 ? (
        <>
          <Modal.Body>
            <div className="coverLangModal__loading">
              <img src={LoadingImg} />
            </div>
            <div className="coverLangModal__loading__text">
              <strong>
                <span id="currentCount">0</span>/{selectedIds.length} 진행중
              </strong>
            </div>
          </Modal.Body>
        </>
      ) : (
        <Modal.Body>
          <div className="coverLangModal__title">기준언어</div>
          <div className="coverLangModal__contents">
            <RcSelect
              {...{
                placeholder: "언어를 선택해주세요.",
                value: standardLang,
                isMulti: false,
                options: langArr,
                onChange: onChangeSingle,
              }}
            />
          </div>
          <div className="coverLangModal__title">덮어 쓸 언어</div>
          <div className="coverLangModal__contents">
            <RcSelect
              {...{
                placeholder: "복수 선택 가능",
                value: targetLangs,
                isMulti: true,
                options: langArr.filter((x) => x.value !== standardLang.value),
                onChange: onChangeMulti,
                isClearable: true,
                noOptionsMessage: () => {
                  return "제공되는 언어를 모두 선택하였습니다.";
                },
              }}
            />
          </div>
          <div className="coverLangModal__title">
            해당 언어로 번역하여 덮어쓰기
          </div>
          <div className="coverLangModal__contents">
            <label className="checkboxItem">
              <div>{t("Yes.label")}</div>
              <input
                name="freeExtraSource"
                onChange={onChangeCheckbox}
                checked={isOverwrite}
                type="radio"
                value="on"
              />
              <span className="checkboxItem__checkbox"></span>
            </label>
            <label className="checkboxItem">
              <div>{t("No.label")}</div>
              <input
                name="freeExtraSource"
                onChange={onChangeCheckbox}
                checked={!isOverwrite}
                type="radio"
                value="off"
              />
              <span className="checkboxItem__checkbox"></span>
            </label>
          </div>
          <ConfirmButton onClick={onClickConfirmButtonTag}>확인</ConfirmButton>
        </Modal.Body>
      )}
    </Modal>
  );
};
