import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";

interface BubbleProps {
  children: React.ReactNode;
  width: string;
  top?: string;
  bottom?: string;
};

export default function Bubble({
  children,
  width,
  top,
  bottom,
}: BubbleProps) {
  return (
    <Box
      p="8px 12px"
      position="absolute"
      {...top && { top }}
      {...bottom && { bottom }}
      left="50%"
      minWidth={width}
      width="fit-content"
      bgcolor="#616161E5"
      borderRadius="4px"
      sx={{
        transform: "translateX(-50%)",
      }}
    >
      <Typography
        color="white"
        fontWeight="500"
        fontSize="14px"
        lineHeight="24px"
        letterSpacing="0.17px"
        textAlign="center"
        sx={{ wordBreak: "keep-all" }}
      >
        {children}
      </Typography>
      <Box
        position="absolute"
        left="50%"
        bottom="-6px"
        borderTop="6px solid #616161E5"
        borderLeft="6px solid transparent"
        borderRight="6px solid transparent"
        width="12px"
        height="6px"
        sx={{ transform: "translateX(-50%)" }}
      />
    </Box>
  );
};
