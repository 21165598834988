import React, { useState, useContext, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import ColorInputEditor from '../common/editor/colorInput';
import UploadIcon from '../img/upload-icon.png';
import { GET_EVENT_BANNER_WRITING_MATERIALS } from '../api/quries';
import DateSetting from '../common/DateSetting';
import { AppContext } from '../app';
import ACON from 'lib/global';
import { uploadFile } from 'api';
import { CREATE_EVENT_BANNER, UPDATE_EVENT_BANNER, DELETE_EVENT_BANNER } from 'api/mutations';

const hiddenFileInput = document.createElement('input');
hiddenFileInput.setAttribute('type', 'file');

export default (props) => {
  const { userInfo, showAlertMessage, showNoButtonMessage } = useContext(AppContext);
  // 번역기 불러오기
  const { t, match } = props;
  // 배너 DB id
  const docId = +props.match.params.docId;
  // HEX 선택 박스
  const HexColorBox = useRef(null);
  // 현재 페이지 타입
  const bannerDisplayLocation = match.params.displayLocation || 'detailPage';

  /* State 설정 */
  // 배너 텍스트 색
  const [textColor, setTextColor] = useState('#545454');
  // 배너 내용 1
  const [bannerTextFirst, setBannerTextFirst] = useState('');
  // 배너 내용 2
  const [bannerTextSecond, setBannerTextSecond] = useState('');
  // 배너 내용 3
  const [bannerTextThird, setBannerTextThird] = useState('');
  // 업로드 이미지 이름
  const [fileInputName, setFileInputName] = useState(null);
  // 업로드 이미지 경로
  const [imageUrl, setImageUrl] = useState('');

  // 언어
  const [languageValue, setLanguageValue] = useState(1);
  // 링크 설정
  const [linkHref, setLinkHref] = useState('');
  // 배너 표시 시작일 yyyy-mm-dd
  const [startDate, setStartDate] = useState(null);
  // 배너 표시 종료일 yyyy-mm-dd
  const [endDate, setEndDate] = useState(null);
  // 현재 포커스 상태인 에디터
  const [targetEditorName, setTargetEditorName] = useState('subTitleFirst');
  /* State 설정 끝 */

  // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
  const { isAdmin } = userInfo;
  if (!isAdmin) {
    props.history.push('/');
    return <></>;
  }

  let query = {};
  if (props.history.location.search) {
    query = queryString.parse(props.history.location.search);
  }

  // 초기 설정
  useEffect(() => {
    hiddenFileInput.value = '';
    hiddenFileInput.type = '';
    hiddenFileInput.type = 'file';
  }, []);

  // 배너타입, 몰(국가) 설정을 위한 선택지 불러오기, 배너 아이디가 있을 경우 내용 가져오기
  const { data } = useQuery(GET_EVENT_BANNER_WRITING_MATERIALS, {
    fetchPolicy: 'no-cache',
    variables: { id: docId || 0 },
  });
  const languages = data?.getLanguages;
  const bannerData = data?.getEventBannerItem;

  useEffect(() => {
    // 생성 페이지 면서 쿼리스트링에 언어가 넘어올경우 해당 언어로 몰 선택 값 지정
    if (languages && !docId && query?.lang) {
      const langId = languages.find((x) => x.code === query?.lang);
      setLanguageValue(langId.id);
    }
  }, [languages]);

  useEffect(() => {
    // 해당 배너 ID에 대한 데이터가 있을 경우 값 세팅
    if (bannerData) {
      // 공통 항목 세팅
      setBannerTextFirst(bannerData.bannerTextFirst);
      setBannerTextSecond(bannerData.bannerTextSecond);
      setBannerTextThird(bannerData.bannerTextThird);
      setLinkHref(bannerData.settingLink);
      setImageUrl(bannerData.image?.fullName);
      setLanguageValue(bannerData.language.id);
      setStartDate(new Date(bannerData.startDate));
      setEndDate(new Date(bannerData.endDate));
    }
  }, [bannerData]);

  /* 배너 텍스트 컬러 영역 - 시작 */
  // 배너 제목 텍스트 색상 변경 함수
  let textColorTimer = null;
  const onChangeTextColor = (e) => {
    e.persist();
    clearTimeout(textColorTimer);
    let color = e.target.value;
    let type = e.target.type;

    textColorTimer = setTimeout(() => {
      if (type !== 'text' || color.length >= 7) {
        textColorTimer = null;
        setTextColor(color);
      }
    }, 100);
  };
  /* 배너 텍스트 컬러 영역 - 끝 */

  /* 이미지 업로드 영역 - 시작 */
  // 이미지 업로드
  const onClickUpload = () => {
    hiddenFileInput.addEventListener('change', () => {
      if (!hiddenFileInput.files[0]) return;
      setFileInputName(hiddenFileInput.files[0].name);

      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImageUrl(fileReader.result);
      };

      fileReader.readAsDataURL(hiddenFileInput.files[0]);
    });

    hiddenFileInput.click();
  };

  // 이미지 업로드 드랍 처리
  const onDropUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // 파일들
    const files = e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files;

    // 파일이 존재하지 않을경우
    if (!files)
      // 종료
      return;

    // 파일 이름 설정
    setFileInputName(files[0].name);

    // 드랍된 파일 설정
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageUrl(fileReader.result);
    };

    hiddenFileInput.files = files;
    fileReader.readAsDataURL(files[0]);
  };

  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  /* 이미지 업로드 영역 - 끝 */

  // lang 설정 UI
  const LanguageSelectTag = () => {
    const options = languages?.map((e, i) => {
      return (
        <option key={i} value={e.id}>
          {e.code.toUpperCase()}
        </option>
      );
    });
    return (
      <select className={'commonSelectBox'} onChange={onChangeLanguage} value={languageValue}>
        {options}
      </select>
    );
  };
  // lang 설정 변경시 작동하는 로직
  const onChangeLanguage = (e) => {
    setLanguageValue(Number(e.target.value));
  };

  // 게시판 셀럭터 버튼 클릭 시 이벤트 함수
  const onClickBannerSelector = (e) => {
    const bannerDisplayLocation = e.target.dataset.name;

    // 메인페이지 배너관리 기능은 기획 준비중입니다.
    if (bannerDisplayLocation === 'mainPage') return;
    props.history.push(`/banner/${bannerDisplayLocation}`);
  };

  // mutation
  const [createEventBannerMutation] = useMutation(CREATE_EVENT_BANNER);
  const [updateEventBannerMutation] = useMutation(UPDATE_EVENT_BANNER);
  const [deleteEventBannerMutation] = useMutation(DELETE_EVENT_BANNER);

  // 저장 버튼 클릭 이벤트
  const onClickSaveButton = async () => {
    try {
      // 첫번째 배너 내용 텍스트가 입력되지 않은경우
      if (!bannerTextFirst) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseBannerFirstText'));
      }

      // 두번째 배너 내용 텍스트가 입력되지 않은경우
      if (!bannerTextSecond) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseBannerSecondText'));
      }

      // 세번째 배너 내용 텍스트가 입력되지 않은경우
      if (!bannerTextThird) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseBannerThirdText'));
      }

      // 이미지가 업로드 되지 않았을 경우
      if (!imageUrl) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseBannerImage'));
      }

      // 몰 선택이 입력되지 않은경우
      if (!languageValue) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseLanguage'));
      }

      // 링크 설정이 입력되지 않은경우
      if (!linkHref) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseLinkHref'));
      }

      // 최초 생성 시 종료 시간이 현재 시간보다 이전일 경우
      if (!docId && endDate && endDate < new Date()) {
        throw new ACON.ValidationError(t('Banner.writer.event.pleaseCheckTime'));
      }

      // 저장 쿼리 파라미터
      let imageKey = null;
      const queryVariables = {
        id: null,
        langId: languageValue,
        bannerTextFirst: bannerTextFirst,
        bannerTextSecond: bannerTextSecond,
        bannerTextThird: bannerTextThird,
        settingLink: linkHref,
        startDate: startDate || new Date(),
        endDate: endDate || new Date('2099-01-01T12:00Z'),
      };
      if (docId) queryVariables.id = docId;

      if (imageUrl && fileInputName) {
        showNoButtonMessage(t('Banner.writer.imageUploadMessage.title'), t('Banner.writer.imageUploadMessage.content'));
        await uploadFile(hiddenFileInput.files[0], true)
          .then((r) => {
            console.log('complete image upload :)');
            imageKey = {
              key: r.key,
              name: r.key.split('/').pop(),
            };
            return null;
          })
          .catch((err) => {
            throw new ACON.ValidationError('이미지 업로드에 실패하였습니다.');
          });
      }

      const mutation = docId ? updateEventBannerMutation : createEventBannerMutation;

      // 이벤트 배너 생성 요청
      const { data } = await mutation({
        variables: { ...queryVariables, imageKey },
      });
      const isSucceed = data?.createEventBanner || data?.updateEventBanner;

      if (isSucceed) {
        showAlertMessage(t('saved'), t('saved'));
        const langCode = languages?.find((x) => x.id === languageValue).code;
        props.history.push({
          pathname: '/banner/eventPage',
          search: queryString.stringify({ lang: langCode }),
        });
      } else {
        throw new ACON.ValidationError('서버 오류');
      }
    } catch (errObj) {
      // 유효성검사 실패 에러일 경우
      if (errObj instanceof ACON.ValidationError) {
        showAlertMessage(t('Banner.writer.event.validate'), errObj.message);
      }
    }
  };

  // 게시글 삭제하는 함수
  const deleteDoc = async (e) => {
    if (docId) {
      const { data } = await deleteEventBannerMutation({
        variables: { id: docId },
      });

      if (data?.deleteEventBanner) {
        showAlertMessage(t('Boards.delete.title'), t('Boards.delete.content'));
      } else {
        showAlertMessage(t('Boards.delete.title'), t('Boards.deleteFail.content'));
      }

      props.history.goBack();
    }
  };

  // 버튼 그룹 (미리보기, 저장, 삭제)
  const btnGroup = (
    <div style={{ display: 'flex', 'justify-content': 'space-between', 'margin-top': '30px' }} className="banner-write_buttonGroup">
      <div></div>
      <div>
        <button className="black-button" type="button" onClick={onClickSaveButton}>
          {t('Save.label')}
        </button>
        {!!docId && (
          <button className="white-button" type="button" onClick={deleteDoc}>
            {t('Remove.label')}
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className={'banner-write event-banner-write'}>
      <div className="board-selector">
        <button className={bannerDisplayLocation === 'detailPage' ? 'selected' : ''} onClick={onClickBannerSelector} data-name="detailPage">
          {t('BannerSelectors.detailPage')}
        </button>
        <button className={bannerDisplayLocation === 'eventPage' ? 'selected' : ''} onClick={onClickBannerSelector} data-name="eventPage">
          {t('BannerSelectors.eventPage')}
        </button>
      </div>

      {/* 버튼 영역 */}
      {btnGroup}

      {/* 배너 등록 */}
      <div className={'fieldContainer'}>
        <div className={'fieldTitle'}>{t('Banner.writer.create')}</div>
        <div className={'fieldEventBanner'}>
          <div className={'fieldEventBannerLeft'}>
            {(!docId || bannerTextFirst) && (
              <ColorInputEditor
                name={'subTitleFirst'}
                contents={bannerTextFirst}
                placeholder={'내용을 입력하세요'}
                fontSize={15}
                line={1}
                textColor={textColor}
                onChange={(val) => {
                  setBannerTextFirst(val);
                }}
                targetEditorName={targetEditorName}
                setTargetEditorName={setTargetEditorName}
              />
            )}
            {(!docId || bannerTextSecond) && (
              <ColorInputEditor
                name={'title'}
                contents={bannerTextSecond}
                placeholder={'내용을 최대 두줄 \u000D\u000A까지 입력하세요'}
                fontSize={21}
                bold={true}
                line={2}
                onChange={(val) => {
                  setBannerTextSecond(val);
                }}
                style={{ marginBottom: '6px' }}
                targetEditorName={targetEditorName}
                setTargetEditorName={setTargetEditorName}
              />
            )}
            {(!docId || bannerTextThird) && (
              <ColorInputEditor
                name={'subTitleSecond'}
                contents={bannerTextThird}
                placeholder={'내용을 입력하세요'}
                fontSize={15}
                line={1}
                textColor={textColor}
                onChange={(val) => {
                  setBannerTextThird(val);
                }}
                targetEditorName={targetEditorName}
                setTargetEditorName={setTargetEditorName}
              />
            )}
          </div>
          <div
            className={'fieldEventBannerRight'}
            onClick={onClickUpload}
            onDragEnter={preventDefault}
            onDragOver={preventDefault}
            onDragLeave={preventDefault}
            onDrop={onDropUpload}
          >
            <img src={UploadIcon} alt={'eventImageUpload'} title={'eventImageUpload'} width={60} height={60} />
            <div className={'EventBannerUploadText'}>Drag and drop or Browse</div>
            {imageUrl && (
              <div className={'benefitBannerPreview'}>
                <img style={{ objectFit: 'scale-down', width: '100%', height: '100%' }} src={imageUrl} alt={'couponBanner'} title={'couponBanner'} />
              </div>
            )}
          </div>
        </div>
        <div className={'fieldEventBannerOption'}>
          <div className={'fieldEventBannerOptionText'}>글자색</div>
          <input className={'bannerTextColor'} type="color" ref={HexColorBox} value={textColor} onChange={onChangeTextColor} />
        </div>
      </div>

      {/* 몰 선택 */}
      <div className={'fieldContainer'}>
        <div className={'fieldTitle'}>{t('Banner.writer.langauge')}</div>
        <LanguageSelectTag />
      </div>

      {/* 링크 설정 */}
      <div className={'fieldContainer'}>
        <div className={'fieldTitle'}>{t('Banner.writer.event.linkTitle')}</div>
        <input
          type="text"
          placeholder={t('Banner.writer.event.setLink')}
          className={'commonInputBox'}
          value={linkHref}
          onChange={(e) => {
            setLinkHref(e.target.value);
          }}
        />
      </div>

      {/* 노출 기간 */}
      <div className={'fieldContainer'}>
        <div className={'fieldTitle'}>{t('Banner.writer.date')}</div>

        <DateSetting startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
      </div>
    </div>
  );
};
