import React from "react";
import styled from "styled-components";

export const RadioComponent = (props) => {
  return (
    <label className={props.className} style={{ marginBottom: 0 }}>
      <input
        type="radio"
        name={props.name}
        value={props.value || props.children}
        onChange={props.onChange}
        checked={props.checkValue === props.value}
      />
      <span className="button"></span>
      {props.children}
    </label>
  );
};

export default styled(RadioComponent)`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #333;

  input {
    display: none;
  }

  .button {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    border-radius: 50%;
    border: 2px solid #ff007b;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  }

  input:checked ~ .button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff007b;
  }
`;
