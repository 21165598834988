import React, { useContext } from 'react';
import { TemplateDataContext } from '../../templateData'
import Row from './row'

export default (function (props) {
    // let render = []
    const { setData, auth } = props
    const { authList, loading } = useContext(TemplateDataContext)
    const render = []
    const keys = Object.keys(auth)
    if (keys && keys.length > 0) {

        keys.forEach(d => {
            if (authList[d]) {
                if (d === 'mainGroup') {
                    console.log(auth[d])
                }
                render.push(<Row key={d} {...{ name: d, defaultAuth: authList[d], auth: auth[d], setData, loading }}></Row>)
            }
        })
        return (<>
            <label>권한</label>
            <div>
                {render}
            </div>
        </>)
    } else {
        return (<></>)
    }
});

