import React from "react";
import { RecoilRoot } from "recoil";
import Guide from "../signup/guide/guide";
import SignupForm from "../signup/SignupForm";
import PromptModal from "signup/components/PromptModal";

export default function Signup() {
  return (
    <RecoilRoot>
      <SignupForm />
      <Guide />
      <PromptModal />
    </RecoilRoot>
  );
}
