import React from 'react';
import { useParams } from "react-router-dom";
import { Form } from "./form";
import { GET_FAQ_ACON_TYPES, GET_FAQ_ACON_WRITE } from "api/quries";
import { CREATE_FAQ_ACON, DELETE_FAQ_ACON, UPDATE_FAQ_ACON } from "api/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';

export const FaqAconWrite = () => {
    const { t } = useTranslation();
    const { docId } = useParams();
    const { data: typeData } = useQuery(GET_FAQ_ACON_TYPES);
    const { data } = useQuery(GET_FAQ_ACON_WRITE, {
        fetchPolicy: "no-cache",
        variables: { id: Number(docId) },
        skip: !docId
    })
    // 글 저장시 실행하는 mutation 쿼리
    const [update] = useMutation(UPDATE_FAQ_ACON);
    const [create] = useMutation(CREATE_FAQ_ACON);
    const [remove] = useMutation(DELETE_FAQ_ACON);

    const save = docId ? update : create;

    // 제목 placeholder 
    const titlePlaceholder = t("Aconfaq.write.title.placeholder");

    // 질문형 제목 placeholder 
    const subtitlePlaceholder = t("Aconfaq.write.subtitle.placeholder");

    return (<Form
        titlePlaceholder={titlePlaceholder}
        subtitlePlaceholder={subtitlePlaceholder}
        item={data && data.faqAcon}
        types={typeData && typeData.faqAconTypes}
        save={save}
        remove={remove} />)
};