import React, { useContext, useEffect, useState } from 'react'
import { TemplateDataContext } from '../templateData'
// import { useTranslation } from 'react-i18next';
import Event from './event'
import AuthList from './authList'
import Input from './input'
import './index.scss'

export default (function (props) {
    // const { t } = useTranslation()
    const { loading, setLoading, selectData, setTemplateList } = useContext(TemplateDataContext)
    const [render, setRender] = useState(<></>)
    const [data, setData] = useState()
    useEffect(() => {
        console.log(selectData, data)
        if (selectData) {

            if ((!data && selectData) || (data._id !== selectData._id) || (data.updateDate !== selectData.updateDate)) {
                setData(JSON.parse(JSON.stringify(selectData)))
            } else if (data) {
                const { onChange, onCheck } = Event(loading, setData)

                console.log('test', data.defaultTemplate)
                setRender(
                    <>
                        <div className="row">
                            <Input {...{ selectData, data, loading, setTemplateList, setLoading }}></Input>
                        </div>
                        <div className="row">
                            <label>templateName</label>
                            <input name="templateName" type="text" maxLength="50"
                                onChange={onChange}
                                value={data.templateName}></input>
                        </div>
                        <div className="row">
                            <label>첫페이지URL</label>
                            <input name="welcomeUrl" type="text" maxLength="50"
                                onChange={onChange}
                                value={data.welcomeUrl ? data.welcomeUrl : ''}></input>
                        </div>
                        <div className="row">
                            <label>설명</label>
                            <input name="description" type="text" maxLength="50"
                                onChange={onChange}
                                value={data.description ? data.description : ''}></input>
                        </div>
                        <div className="row">
                            <label>일반사용자권한</label>
                            <input name="defaultTemplate" type="checkbox" maxLength="50"
                                onChange={onCheck}
                                // defaultChecked=""
                                // ch
                                checked={data.defaultTemplate}
                                ></input>
                        </div>
                        <div className="row">
                            <AuthList {...{ setData, auth: data._auth }}></AuthList>
                        </div>
                    </>
                )
            }
        } else {
            setData(null)
            setRender(<></>)
        }
    }, [data, selectData, loading, setTemplateList, setLoading])
    return (
        <div className="templateForm">
            {render}
        </div>
    )
});

